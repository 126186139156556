import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";

export const editSchoolCalendarStyles= makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    },
    textwraplable: {
      display: 'inline-block',
      width: '320px',
      'white-space': 'nowrap',
      overflow: 'hidden !important',
      'text-overflow': 'ellipsis',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: 0.13,
      color: colors.primaryDarkGrey,
      marginTop: 20,
      float: 'left'
    },
    paper: {
      padding: theme.spacing(1),
      display: 'flex',
      // marginBottom: '16px',
      borderBottom: '1px solid #f4f5f7',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
      },
      '&:active': {
        boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.75)'
      },
      position: 'relative'
    },
    borderClass: {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        borderTop: '2px solid #e0e0e0',
        width: `calc(100% - 14px)`
      }
    },
    calendarEntryWrapper: {
      overflowY: 'auto',
      height: `calc(100vh - 379px)`
    },
    box: {
      padding: '8px'
    },
    iconButton: {
      padding: 0
    },
    image: {
      alignSelf: 'flex-end',
      display: 'flex'
    },
    tabNavigations: {
      padding: '0 20px 0',
      marginBottom: 15,
      position: 'relative'
    },
    navigationButton: {
      width: 120,
      textTransform: 'none'
    },
    actionButton: {
      margin: '15px 0 20px 0',
      textTransform: 'none'
    },
    groupButtons: {
      margin: '15px 0 20px 24px',
      textTransform: 'none'
    },
    activeTab: {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 0
    },
    bredthcrumbTitle: {
      color: '#536e7b',
      fontSize: 16,
      marginRight: 24,
      display: 'inline-block',
      cursor: 'pointer'
    },
    calenderDayButton: {
      width: 40,
      height: 40,
      borderRadius: 20,
      minWidth: 40
    },
    dayWithDotContainer: {
      position: 'relative'
    },
    dayWithWeekColor: {
      position: 'relative',
      '& button': {
        color: `${colors.tertiaryRed} !important`
      }
    },
    dayWithDot: {
      height: 5,
      width: 5,
      borderRadius: 4,
      top: '80%',
      margin: '-7px 1px 1px 1px',
      zIndex: 1
    },
    headerDot: {
      height: 10,
      width: 10,
      borderRadius: 5,
      marginRight: 5
    },
    dot: {
      height: 15,
      width: 15,
      borderRadius: 10,
      marginLeft: 5
    },
    dotContainer: { width: 40, display: 'flex', justifyContent: 'center' },
    datePaperContainer: {
      '& .MuiPickersStaticWrapper-staticWrapperRoot': {
        alignItems: 'center'
      }
    },
    alignFlexCenter: {
      alignItems: 'center',
      display: 'flex',
      marginLeft: 'auto'
    },
    formLabelControl: {
      width: 109,
      backgroundColor: '#ff9b00',
      // marginTop: 24,
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 9px) scale(1)'
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)'
      },
      '& .MuiOutlinedInput-input': {
        padding: '1.5px 14px',
        marginTop: 7,
        color: '#fff !important',
        backgroundColor: 'transparent !important'
      },
      '& .MuiSelect-icon': {
        color: '#fff !important'
      }
    },
    muiSelectIconWhite: {
      color: '#fff !important'
    },
    muiSelectIcon: {
      color: colors.primaryDark
    },
    chipLabel: {
      height: 18,
      fontfamily: 'Roboto',
      fontweight: 500,
      fontstretch: 'normal',
      fontstyle: 'normal',
      lineheight: 'normal',
      letterspacing: 'normal',
      color: colors.white,
      marginTop: -5
    },
    chipRoot: {
      width: 100,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
      height: 20,
      top: 10
    },
    loadingEntries: {
      textAlign: 'center',
      marginTop: '100px'
    },
    loadingEntriesDots: {
      textAlign: 'center',
      marginTop: '100px',
      color: 'transparent'
    },
    monthName: {
      fontSize: 24,
      //marginBottom: 10,
      marginTop: 20
    },
    monthNameTop: {
      fontSize: 24,
      marginBottom: 10,
      marginTop: 0
    },
    withButton: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 0,
      paddingTop: 0
    },
    withoutButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: 15,
      paddingTop: 15
    },
    labelText: {
      fontSize: 20,
      fontWeight: 500,
      color: colors.primaryDark
    },
    fromDateText: {
      margin: 0,
      fontSize: 18,
      fontWeight: 500,
      color: colors.primaryDarkGrey
    },
    toDateText: {
      margin: 0,
      fontSize: 18,
      color: colors.primaryDarkGrey
    },
    repeatIconClass: {
      opacity: 0.5,
      width: 22.3,
      height: 24,
      marginRight: 10
    },
    checkboxClass: { padding: 0, height: 17, width: 17 },
    mainTitleContainer: {
      fontSize: 18,
      color: colors.primaryDark
    },
    titleTextContainer: {
      margin: '0 0 10px 0',
      width: '90%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    todayButton: {
      margin: '0 0 20px 20px',
      backgroundColor: colors.primary,
      textTransform: 'none',
      color: colors.white,
      '&:hover': {
        backgroundColor: '#618910'
      }
    },
    textTruncate: {
      margin: 0,
      fontSize: 16,
      letterSpacing: 0.1,
      color: 'rgba(75, 91, 105, 0.5)',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden'
    },
    drawerLeftContent: {
      width: `calc(60% - 20px)`,
      height: `calc(100vh - 30px)`,
      borderRadius: 5,
      backgroundColor: 'rgb(255, 245, 224,1)',
      float: 'left',
      position: 'relative',
      margin: 10
    },
    drawerRightContent: {
      width: '40%',
      height: `calc(100vh - 10px)`,
      backgroundColor: '#f4f5f7',
      float: 'right',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    leftContentHeader: {
      textAlign: 'right'
    },
    label: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: 0.13,
      color: colors.primaryDarkGrey,
      marginLeft: 14,
      marginTop: 20,
      float: 'left'
    },
    siderAvatar: {
      marginTop: 12,
      width: 40,
      float: 'left',
      color: colors.primaryDarkGrey,
      fontSize: 14,
      backgroundColor: colors.primaryLightGrey,
      fontWeight: 500
    },
    header: {
      width: '100%',
      flexDirection: 'row',
      height: 71
    },
    heading: {
      fontSize: 14,
      fontWeight: 'bold',
      color: colors.primaryDarkGrey
    },
    siderDetail: {
      fontSize: 14,
      color: colors.primaryDarkGrey,
      marginBottom: 8
    },
    icon: {
      marginRight: 17,
      height: 24,
      width: 24
    },
    siderEdit: {
      height: 40,
      marginTop: 19
    },
    textArea: {
      width: '96%',
      height: '25%',
      borderRadius: 5,
      backgroundColor: '#fff5e0',
      border: 0,
      color: colors.primaryDarkGrey
    },
    leftContentMargin: {
      marginLeft: 40,
      marginRight: 40
    },
    greyButton: {
      width: 136,
      height: 50,
      borderRadius: 3,
      backgroundColor: colors.secondaryText,
      color: colors.white,
      textTransform: 'none'
    },
    toolbarHidden: {
      display: 'none'
    },
    toolbar: {
      backgroundColor: 'transparent',
      border: 0,
      '& .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-target-option': {
        display: 'none'
      }
    },
    textEditor: {
      maxHeight: `calc(100vh - 150px)`
    },
    textEditorOnEdit: {
      maxHeight: `calc(100vh - 230px)`
    },
    studentReportPopUp: {
      width: '240px',
      padding: '20px',
      borderRadius: '8px',
      backgroundColor: '#4c5b68',
      color: '#ffffff',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 5
    },
    reportImage: {
      position: 'absolute',
      right: 0,
      top: -35,
      cursor: 'pointer',
      zIndex: 1
    },
    flexBoxBetween: { display: 'flex', justifyContent: 'space-between' },
    handBookContainer: { position: 'absolute', bottom: -20, right: -10 },
    handBookClass: { width: 360, height: 297, opacity: 0.32 }
  }));

