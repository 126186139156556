import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  FormHelperText,
  TextField,
  FormControl,
  Checkbox,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Input
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Loop, Add } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { colors, year as DefaultYear } from 'src/utils/constant';
import { parentSchema, childSchema } from 'src/utils/schemas';
import { generateFileHash, generatePatternPassword } from 'src/utils/functions';
import { addMessage } from 'src/redux/actions/appActions';
import { withFirebase } from 'src/utils/firebase';
import { loaderStart, loaderStop } from 'src/redux/actions/appActions';
import { setSchool } from 'src/redux/actions/schoolActions';
import PhoneInput from 'src/components/PhoneInput';
import { getSchools } from 'src/services/schools';
import { getGroupBySchoolId, getGroups } from 'src/services/groups';
import { addChildrenStyles } from 'src/styles/membersStyle/addChildrenStyles';
import { MESSAGE_TYPES } from 'src/constants/common';



const parentObject = {
  firstName: '',
  lastName: '',
  initials: '',
  emailAddress: '',
  phoneNo: ''
};
const childObject = {
  firstName: '',
  lastName: '',
  initials: '',
  userName: '',
  password: '',
  school: '',
  year: '',
  group: '',
  groups: [],
  schoolPortal: '',
  schoolPortalLogin: '',
  schoolPortalPassword: ''
};

function AddChildren({
  firebase,
  submitForm,
  accountFlag,
  isEdit,
  handleCancelDisable,
  setHandleCancelClick,
  handleCancelClick
}) {
  const classes = addChildrenStyles();
  const dispatch = useDispatch();
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [formKey, setFormKey] = useState(Math.random());
  const prefillFamilyData = useSelector((state) => state.family.currentFamily);
  const [initialValues, setInitialValue] = useState({
    parents: prefillFamilyData.parents.length ? prefillFamilyData.parents : [],
    childs: [],
    freeHost:
      prefillFamilyData.planType === 'Free'
        ? 'true'
        : prefillFamilyData.freeHost || 'false',
    schools: (prefillFamilyData.schools &&
      prefillFamilyData.schools.length &&
      prefillFamilyData.schools) || [
      { schoolName: '', year: '', color: '', years: [] }
    ],
    schoolIds: prefillFamilyData.schoolIds || []
  });
  const [status, setStatus] = useState('');

  const onTogglePasswordFieldType = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
    } else {
      setPasswordFieldType('password');
    }
  };
  const [schools, setSchools] = React.useState([]);
  const [fields, setFields] = useState([
    { ...parentObject, id: generateFileHash(20) }
  ]);
  const [fieldsChild, setFieldsChild] = useState([
    { ...childObject, id: generateFileHash(20) }
  ]);
  const [isRotate, setRotate] = useState(false);
  const [isSave, setSave] = useState(false);
  const [Groups, setGroupData] = useState([]);

  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const childVisiility = useSelector((state) => state?.auth?.user?.country);
  // Existing Email check
  const [isPrimaryEmailExistCheck, setIsPrimaryEmailExistCheck] =
    useState(false);
  const [isUserExistPrimary, setIsUserExistPrimary] = useState('');

  useEffect(() => {
    // PHASE2/CHANGES/SCHOOL

    getSchools()
      .then((result) => {
        const schools = [];
        result.forEach((school) => {
          schools.push({
            id: school.id,
            name: school.schoolName,
            isFree: school.freeCalendars.length ? true : false,
            years: school.freeCalendars,
            categories: school.categories
          });
        });
        setSchools(schools);
      })
      .catch((error) => {
        console.error(error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
      });
  }, []);

  useEffect(() => {
    setInitialValue({
      parents: prefillFamilyData.parents.length
        ? prefillFamilyData.parents
        : [{ ...parentObject, id: generateFileHash(20) }],
      childs: prefillFamilyData.childs.length
        ? prefillFamilyData.childs
        : prefillFamilyData.planType !== 'FreeVersion'
        ? [{ ...childObject, id: generateFileHash(20) }]
        : []
    });
    const parentsValues = (prefillFamilyData.parents || []).map((val) => {
      return val;
    });
    if (parentsValues.length) setFields(parentsValues);

    const childsValues = (prefillFamilyData.childs || []).map((val) => {
      return val;
    });

    const updatedValue = childsValues?.map((value, index) => {
      const findYears = _.find(
        schools,
        (school) => school.id === value?.school
      );
      const yearValue = findYears?.years?.map((data) => {
        const getCurrentYear = _.find(
          DefaultYear,
          (elem) => elem.value === data
        );
        return {
          label: getCurrentYear?.label,
          value: data
        };
      });
      const allCategory = findYears?.categories?.map((data) => {
        return {
          key: data?.name,
          value: data?.id
        };
      });
      const sortYear = yearValue?.sort(function (a, b) {
        return parseInt(a.value) - parseInt(b.value);
      });
      return {
        ...value,
        school: value.school,
        year: value.year,
        years: yearValue ? yearValue : []
      };
    });

    if (updatedValue.length) {
      setFieldsChild([...updatedValue]);
    }
  }, [prefillFamilyData, schools]);

  useEffect(() => {
    if (handleCancelClick) {
      setInitialValue({
        parents: prefillFamilyData.parents.length
          ? prefillFamilyData.parents
          : [{ ...parentObject, id: generateFileHash(20) }],
        childs: prefillFamilyData.childs.length
          ? prefillFamilyData.childs
          : prefillFamilyData.planType !== 'FreeVersion'
          ? [{ ...childObject, id: generateFileHash(20) }]
          : []
      });
      const parentsValues = (prefillFamilyData.parents || []).map((val) => {
        return val;
      });
      if (parentsValues.length) setFields(parentsValues);

      const childsValues = (prefillFamilyData.childs || []).map((val) => {
        return val;
      });
      if (childsValues.length) setFieldsChild(childsValues);
      setFormKey(Math.random());
      setHandleCancelClick(false);
    }
  }, [handleCancelClick, setHandleCancelClick]);

  useEffect(() => {
    const schoolId = selectedSchool?.id || '';
    if (schoolId) {
      getGroupBySchoolId(schoolId)
        .then(function (result) {
          const checkDuplicateSchool = Groups.some((elem) =>
            Object.keys(elem).includes(schoolId)
          );
          let GroupData = checkDuplicateSchool
            ? Groups
            : [...Groups, { [schoolId]: result?.data }];
          setGroupData(GroupData);
          dispatch(loaderStop());
        })
        .catch((error) => {
          console.log('Error getting documents: ', error);
          // dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
          dispatch(addMessage(error.message, MESSAGE_TYPES.ERROR));
        });
    }
  }, [selectedSchool]);
  function filterByKey(array, key) {
    const filteredObj = array.find((obj) => obj[key]);
    return filteredObj
      ? filteredObj[key]?.sort((a, b) => {
          return a?.groupName?.toLowerCase() > b?.groupName?.toLowerCase()
            ? 1
            : -1;
        })
      : [];
  }
  const handleParentAdd = (formValue) => {
    setInitialValue({
      ...formValue,
      parents: [
        ...formValue.parents,
        { ...parentObject, id: generateFileHash(20) }
      ]
    });
    const updateValues = [...fields];
    updateValues.push({ ...parentSchema, id: generateFileHash(20) });
    setFields(updateValues);
  };

  const handleParentRemove = (i) => {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  };

  const handleChildAdd = (formValue) => {
    setInitialValue({
      ...formValue,
      childs: [
        ...formValue.childs,
        { ...childObject, id: generateFileHash(20) }
      ]
    });
    const values = [...fieldsChild];
    values.push({ ...childSchema, id: generateFileHash(20) });
    setFieldsChild(values);
  };

  const handleChildRemove = (i) => {
    const values = [...fieldsChild];
    values.splice(i, 1);
    setFieldsChild(values);
  };

  // SCHEMA VALIDATOR FOR CREATE NEW PASSWORD FORM
  const validationSchema = Yup.object().shape({
    childs: Yup.array().of(
      Yup.object().shape({
        userId: Yup.string().trim(),
        firstName: Yup.string().trim().required('First name is required'),
        lastName: Yup.string().trim().required('Account name is required'),
        school: Yup.string().trim().required('School is required'),
        year: Yup.string().trim().required('Year is required')
      })
    )
  });

  const generatePassword = (idx, setFieldValue) => {
    setRotate(true);
    setFieldValue(`childs[${idx}].password`, generatePatternPassword(8));
    dispatch(addMessage('Password generated and set'));
    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };

  const getYearFromSchoolId = (
    schoolID,
    selectedIndex,
    values,
    setFieldValue
  ) => {
    const updatedValue = values?.childs?.map((value, index) => {
      if (index === selectedIndex) {
        const findYears = _.find(schools, (school) => school.id === schoolID);
        const yearValue = findYears.years.map((data) => {
          const getCurrentYear = _.find(
            DefaultYear,
            (elem) => elem.value === data
          );
          return {
            label: getCurrentYear?.label,
            value: data
          };
        });
        const allCategory = findYears?.categories?.map((data) => {
          return {
            key: data?.name,
            value: data?.id
          };
        });
        const sortYear = yearValue?.sort(function (a, b) {
          return parseInt(a.value) - parseInt(b.value);
        });
        return {
          ...value,
          school: schoolID,
          year: '',
          years: yearValue ? yearValue : []
          //  categories: allCategory?.length ? allCategory : []
        };
      } else {
        return { ...value };
      }
    });
    setFieldValue(`childs[${selectedIndex}].school`, schoolID);
    setFieldValue(`childs[${selectedIndex}].groups`, '');
    setFieldsChild([...updatedValue]);
    setInitialValue({
      ...values,
      childs: [...updatedValue]
    });
  };

  const checkUserExistOrNot = (userName, setErrors, index, values) => {
    const email = `${userName}@mywhanau-user.com`;
    firebase
      .isUserExists(email)
      .then((results) => {
        if (results.size > 0) {
          const errorArray = values.childs.map((child) => {
            return null;
          });
          errorArray[index] = {
            userName: `Username is already in use by another account.`
          };
          const child = { childs: [...errorArray] };
          setErrors(child);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onUserNameChangeTextDelayed = _.debounce(checkUserExistOrNot, 500);

  const handleGroupChange = (
    event,
    selectedIndex,
    categoryIndex,
    childs,
    setFieldValue
  ) => {
    const updatedGroups = Groups.map((groupData, index) => {
      let selectValue = event.target.value;

      if (selectValue.includes(groupData.id)) {
        return {
          ...groupData,
          selected: true
        };
      } else {
        return {
          ...groupData,
          selected: false
        };
      }
    });
    setGroupData(updatedGroups);
  };

  return (
    <div>
      <Formik
        key={formKey}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors }) => {
          let hasError = [];
          let subscriptionData = [];

          if (values.childs.length) {
            values.schoolIds = _.uniq(
              values.childs.map((schools) => schools.school)
            );
          } else {
            values.schoolIds = [];
          }

          for await (const [index, child] of values.childs.entries()) {
            subscriptionData.push({
              schoolName: child?.school,
              year: child?.year,
              color: '',
              years: child?.years
            });
            if (
              (prefillFamilyData.childs[index] &&
                !prefillFamilyData.childs[index].userName) ||
              !prefillFamilyData.childs[index] ||
              child.userName !== prefillFamilyData.childs[index].userName
            ) {
              const email = `${child.userName}@mywhanau-user.com`;
              await firebase
                .isUserExists(email)
                .then((results) => {
                  if (results.size > 0) {
                    const errorArray = values.childs.map((childData) => {
                      return null;
                    });
                    errorArray[index] = {
                      userName: `Username is already in use by another account.`
                    };
                    const child = { childs: [...errorArray] };
                    setErrors(child);
                    hasError.push(values.childs[index].userName);
                  }
                })
                .catch((error) => {
                  hasError.push(values.childs[index].userName);
                  console.log(error);
                });
            } else {
              hasError = false;
            }
          }
          let allFormData = { ...values, schools: subscriptionData };
          if (!hasError.length) {
            if (accountFlag && isSave) {
              submitForm(allFormData, true);
            } else {
              submitForm(allFormData, status);
              handleCancelDisable(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          setErrors
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <div>
                {/* <div className={classes.label}>Child 1 </div> */}
                {fieldsChild?.map((fieldChild, idx) => {
                  const ID = idx;
                  const selectedSchool = schools?.find(
                    (s) => s?.id === values?.childs[idx]?.school
                  );
                  selectedSchool && setSelectedSchool(selectedSchool);
                  return (
                    <div
                      key={`${fieldChild}-${idx}`}
                      style={{ marginBottom: '2rem' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          width: 480,
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography className={classes.title}>
                          Child {idx + 1}
                        </Typography>
                      </div>

                      <FormControl component="fieldset">
                        <TextField
                          label="First Name"
                          variant="outlined"
                          onBlur={handleBlur}
                          name={`childs[${idx}].firstName`}
                          onChange={(e) => {
                            accountFlag && isEdit && handleCancelDisable(true);
                            setFieldValue(
                              `childs[${idx}].firstName`,
                              e.target.value
                            );
                            setFieldValue(
                              `childs[${idx}].initials`,
                              `${e.target.value.charAt(0)}${values.childs[
                                idx
                              ]?.lastName?.charAt(0)}`
                            );
                          }}
                          value={
                            prefillFamilyData.planType === 'Free' &&
                            childVisiility !== 'Australia'
                              ? 'Redacted'
                              : (values?.childs?.[idx] &&
                                  values?.childs?.[idx].firstName) ||
                                ''
                          }
                          error={Boolean(
                            touched.childs &&
                              errors.childs &&
                              touched.childs[idx] &&
                              touched.childs[idx].firstName &&
                              errors.childs[idx] &&
                              errors.childs[idx].firstName
                          )}
                          helperText={
                            touched.childs &&
                            errors.childs &&
                            touched.childs[idx] &&
                            touched.childs[idx].firstName &&
                            errors.childs[idx] &&
                            errors.childs[idx].firstName
                          }
                          className={classes.inputControlBox}
                          style={{ width: 273, marginRight: 25 }}
                          inputProps={{
                            className:
                              prefillFamilyData.planType === 'Free' &&
                              childVisiility !== 'Australia' &&
                              prefillFamilyData?.childs?.length !== 0
                                ? classes.NonAUchildBox
                                : classes.AuChildBox,
                            readOnly:
                              prefillFamilyData.planType === 'Free' &&
                              childVisiility !== 'Australia' &&
                              prefillFamilyData?.childs?.length !== 0
                                ? true
                                : false
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                      </FormControl>
                      <FormControl component="fieldset">
                        <TextField
                          label="Account name"
                          variant="outlined"
                          onBlur={handleBlur}
                          name={`childs[${idx}].lastName`}
                          onChange={(e) => {
                            accountFlag && isEdit && handleCancelDisable(true);
                            setFieldValue(
                              `childs[${idx}].lastName`,
                              e.target.value
                            );
                            setFieldValue(
                              `childs[${idx}].initials`,
                              `${values.childs[idx].firstName.charAt(
                                0
                              )}${e.target.value.charAt(0)}`
                            );
                          }}
                          value={
                            prefillFamilyData.planType === 'Free' &&
                            childVisiility !== 'Australia'
                              ? 'Redacted'
                              : (values.childs[idx] &&
                                  values.childs[idx].lastName) ||
                                ''
                          }
                          error={Boolean(
                            touched.childs &&
                              errors.childs &&
                              touched.childs[idx] &&
                              touched.childs[idx].lastName &&
                              errors.childs[idx] &&
                              errors.childs[idx].lastName
                          )}
                          helperText={
                            touched.childs &&
                            errors.childs &&
                            touched.childs[idx] &&
                            touched.childs[idx].lastName &&
                            errors.childs[idx] &&
                            errors.childs[idx].lastName
                          }
                          className={classes.inputControlBox}
                          style={{ width: 273 }}
                          inputProps={{
                            className:
                              prefillFamilyData.planType === 'Free' &&
                              childVisiility !== 'Australia' &&
                              prefillFamilyData?.childs?.length !== 0
                                ? classes.NonAUchildBox
                                : classes.AuChildBox,
                            readOnly:
                              prefillFamilyData.planType === 'Free' &&
                              childVisiility !== 'Australia' &&
                              prefillFamilyData?.childs?.length !== 0
                                ? true
                                : false
                          }}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                      </FormControl>

                      <div
                        style={{
                          width: '100%',
                          marginTop: 24
                        }}
                      >
                        <FormControl variant="outlined">
                          <InputLabel id="country-select-outlined-label">
                            School Name
                          </InputLabel>

                          <Select
                            labelId="schoolName-select-outlined-label"
                            className={classes.inputControlBox}
                            style={{
                              width: 273,
                              marginRight: 25,
                              marginTop: 0
                            }}
                            name={`childs[${idx}].school`}
                            onChange={(e) => {
                              accountFlag &&
                                isEdit &&
                                handleCancelDisable(true);
                              getYearFromSchoolId(
                                e.target.value,
                                idx,
                                values,
                                setFieldValue
                              );
                            }}
                            value={
                              (values.childs[idx] &&
                                values.childs[idx].school) ||
                              ''
                            }
                            error={Boolean(
                              touched.childs &&
                                errors.childs &&
                                touched.childs[idx] &&
                                touched.childs[idx].school &&
                                errors.childs[idx] &&
                                errors.childs[idx].school
                            )}
                            id="school-name-select-outlined"
                            onBlur={handleBlur}
                            label="School Name"
                          >
                            <MenuItem value="">
                              <em>School Name</em>
                            </MenuItem>

                            {schools
                              .filter((e) => {
                                if (
                                  e.isFree === true ||
                                  prefillFamilyData.planType !== 'Free'
                                ) {
                                  return e;
                                }
                              })
                              .map(function (object) {
                                return (
                                  <MenuItem key={object.id} value={object.id}>
                                    {object.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {Boolean(
                            touched.childs &&
                              errors.childs &&
                              touched.childs[idx] &&
                              touched.childs[idx].school &&
                              errors.childs[idx] &&
                              errors.childs[idx].school
                          ) ? (
                            <FormHelperText
                              error={Boolean(
                                touched.childs &&
                                  errors.childs &&
                                  touched.childs[idx] &&
                                  touched.childs[idx].school &&
                                  errors.childs[idx] &&
                                  errors.childs[idx].school
                              )}
                            >
                              {errors.childs[idx].school}
                            </FormHelperText>
                          ) : (
                            ''
                          )}
                        </FormControl>
                        <FormControl variant="outlined">
                          <InputLabel id="year-select-outlined-label">
                            Current Year
                          </InputLabel>
                          <Select
                            labelId="year-select-outlined-label"
                            className={classes.inputControlBox}
                            name={`childs[${idx}].year`}
                            onChange={(e) => {
                              accountFlag &&
                                isEdit &&
                                handleCancelDisable(true);
                              setFieldValue(
                                `childs[${idx}].year`,
                                e.target.value
                              );
                              setFieldValue(`childs[${idx}].groups`, '');
                            }}
                            value={
                              (values.childs[idx] && values.childs[idx].year) ||
                              ''
                            }
                            error={Boolean(
                              touched.childs &&
                                errors.childs &&
                                touched.childs[idx] &&
                                touched.childs[idx].year &&
                                errors.childs[idx] &&
                                errors.childs[idx].year
                            )}
                            style={{ width: 273, marginTop: 0 }}
                            id="year-select-outlined"
                            onBlur={handleBlur}
                            label="Current Year"
                          >
                            <MenuItem value="">
                              <em>Current Year</em>
                            </MenuItem>
                            {fieldChild?.years?.length &&
                            prefillFamilyData?.planType === 'Free'
                              ? fieldChild &&
                                fieldChild?.years?.length &&
                                fieldChild?.years?.map(function (object) {
                                  return (
                                    <MenuItem
                                      key={object.value}
                                      value={object.value}
                                    >
                                      {object.label}
                                    </MenuItem>
                                  );
                                })
                              : DefaultYear &&
                                DefaultYear.map((object) => {
                                  return (
                                    <MenuItem
                                      key={object.value}
                                      value={object.value}
                                    >
                                      {object.label}
                                    </MenuItem>
                                  );
                                })}
                          </Select>
                          {Boolean(
                            touched.childs &&
                              errors.childs &&
                              touched.childs[idx] &&
                              touched.childs[idx].year &&
                              errors.childs[idx] &&
                              errors.childs[idx].year
                          ) ? (
                            <FormHelperText
                              error={Boolean(
                                touched.childs &&
                                  errors.childs &&
                                  touched.childs[idx] &&
                                  touched.childs[idx].year &&
                                  errors.childs[idx] &&
                                  errors.childs[idx].year
                              )}
                            >
                              {errors.childs[idx].year}
                            </FormHelperText>
                          ) : (
                            ''
                          )}
                        </FormControl>
                        <br />

                        <div
                          style={{
                            marginTop: 12,
                            display:
                              selectedSchool?.categories?.length > 0
                                ? 'inline-grid'
                                : 'none',
                            gridTemplateRows: '80px 60px',
                            gridTemplateColumns: '295px 100px',
                            gridGap: '3px'
                          }}
                        >
                          {selectedSchool?.categories?.map(
                            (Category, categoryIndex) => {
                              return (
                                <div style={{ marginTop: 10 }}>
                                  <FormControl variant="outlined">
                                    <InputLabel id="demo-simple-select-outlined-label-Group">
                                      {Category.name}
                                    </InputLabel>
                                    <Select
                                      className={classes.inputControlBox}
                                      style={{
                                        width: 273,
                                        marginRight: 25,
                                        marginTop: 0
                                      }}
                                      labelId="demo-simple-select-outlined-label-Group"
                                      id="demo-simple-select-outlined-Group"
                                      multiple
                                      name={Category.name}
                                      onBlur={handleBlur}
                                      onChange={(e) => {
                                        accountFlag &&
                                          isEdit &&
                                          handleCancelDisable(true);
                                        handleGroupChange(
                                          e,
                                          idx,
                                          categoryIndex,
                                          values.childs,
                                          setFieldValue(
                                            `childs[${idx}].groups`,
                                            e.target.value
                                          )
                                        );
                                      }}
                                      value={
                                        (values?.childs?.[idx] &&
                                          values?.childs?.[idx].groups) ||
                                        []
                                      }
                                      renderValue={(selected) => {
                                        const groupName = filterByKey(
                                          Groups,
                                          values?.childs[idx]?.school
                                        ).map((data) => {                                          
                                          if (
                                            selected.includes(data.id) &&
                                            data.category === Category.id
                                            ) {
                                            if (
                                              data.years.includes(
                                                `Year ${values.childs[idx].year}`
                                              ) ||
                                              (data.years.includes('Kindy') &&
                                                values.childs[idx].year ===
                                                  '0') ||
                                              (data.years.includes('Pre K') &&
                                                values.childs[idx].year ===
                                                  '-1')
                                            ) {
                                              return data.groupName;
                                            }
                                            return null;
                                          } else {
                                            return null;
                                          }
                                        });
                                        const groupNameFilter =
                                          groupName.filter(
                                            (data) => data && data
                                          );
                                        return groupNameFilter.join(', ');
                                      }}
                                      label={Category.name}
                                    >
                                
                                      {filterByKey(
                                        Groups,
                                        values?.childs[idx]?.school
                                      )
                                        ?.filter((g) => {
                                          if (
                                            g.groupType === 'Free' &&
                                            g.school ===
                                              values?.childs[idx]?.school &&
                                            g.category === Category.id
                                          ) {
                                            if (!values.childs[idx].year) {
                                              return g;
                                            }
                                            if (
                                              g.years.includes(
                                                `Year ${values.childs[idx].year}`
                                              ) ||
                                              (g.years.includes('Kindy') &&
                                                values.childs[idx].year ===
                                                  '0') ||
                                              (g.years.includes('Pre K') &&
                                                values.childs[idx].year ===
                                                  '-1')
                                            ) {
                                              return g;
                                            } else return false;
                                          } else {
                                            return false;
                                          }
                                        })
                                        .map((group, forIndex) => {
                                          return (
                                            <MenuItem
                                              key={group.id}
                                              value={group.id}
                                              className={classes.menuItemClass}
                                            >
                                              <div>
                                                <Checkbox
                                                  icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                  }
                                                  checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                  }
                                                  checked={values?.childs?.[
                                                    idx
                                                  ]?.groups?.includes(group.id)}
                                                  name={group.id}
                                                  className={
                                                    classes.controlCheck
                                                  }
                                                  classes={{
                                                    root: classes.checkbox,
                                                    checked: classes.checked
                                                  }}
                                                />
                                                {group.groupName}
                                              </div>
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </FormControl>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div>
                  <Button
                    className={classes.adButton}
                    style={{
                      backgroundColor: '#f4f5f7',
                      marginLeft: 0,
                      height: 50,
                      color: colors.primaryDarkGrey
                    }}
                    onClick={() => handleChildAdd(values)}
                  >
                    <Add />
                    Add Child
                  </Button>
                </div>
              </div>

              <Grid item xs={12}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {prefillFamilyData.userName === '' &&
                    prefillFamilyData.password === '' && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.createButtonTextFree}
                        onClick={() => {
                          setSave(true);
                          handleSubmit();
                          setStatus('Invited');
                          window.scroll(0, 0);
                        }}
                      >
                        Save & Invite Now
                      </Button>
                    )}

                  {prefillFamilyData.userName === '' &&
                    prefillFamilyData.password === '' && (
                      <Typography className={classes.ORtext}> or </Typography>
                    )}

                  {prefillFamilyData.userName === '' &&
                    prefillFamilyData.password === '' && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.createButtonTextFree}
                        onClick={() => {
                          setSave(true);
                          handleSubmit();
                          setStatus('New');
                          window.scroll(0, 0);
                        }}
                      >
                        Save as New
                      </Button>
                    )}

                  {prefillFamilyData.userName !== '' &&
                    prefillFamilyData.password !== '' && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.createButtonTextFree}
                        onClick={() => {
                          setSave(true);
                          handleSubmit();
                          setStatus('Active');
                          window.scroll(0, 0);
                        }}
                      >
                        Save as Active
                      </Button>
                    )}
                </div>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default withFirebase(AddChildren);
