import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
  TextField,
  InputLabel,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import _ from 'lodash';
import Modal from 'src/components/Modal';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { colors, year, yearList } from 'src/utils/constant';
import { withFirebase } from 'src/utils/firebase';
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { resetSchool, setSchool } from 'src/redux/actions/schoolActions';
import { getSchoolList } from 'src/redux/actions/authActions';
import { getSchools } from 'src/services/schools';
import { calendarStyles } from 'src/styles/schoolStyle/calendarStyles';
import { deepEqual } from 'src/utils/deepEqual';
import { MESSAGE_TYPES } from 'src/constants/common';

function Calendar(props) {
  const classes = calendarStyles();
  const dispatch = useDispatch();
  const [fields, setFields] = useState([{ value: null, selected: false }]);
  const [buttonText, setButtonText] = useState('');
  const prefillSchoolData = useSelector((state) => state.school.currentSchool);
  const [initialValues, setInitialValue] = useState({
    freeCalendars: []
  });
  const [formKey, setFormKey] = useState(Math.random());
  const [allSelectValue, setAllSelectValue] = useState({
    value: false,
    indexNum: ''
  });
  const [isFormValuesUpdated, setIsFormValuesUpdated] = useState(false);
  const [formComparisonValues, setFormComparisonValues] = useState({
    freeCalendars: []
  });

  const {
    isEdit = false,
    accountFlag,
    firebase,
    handleCancelDisable,
    setHandleCancelClick,
    handleCancelClick
  } = props;
  // SCHEMA VALIDATOR FOR
  const validationSchema = Yup.object().shape({
    freeCalendars: Yup.array().of(Yup.string().trim().required())
  });

  // SET PREFILL DATA IF IS-EDIT TRUE

  useEffect(() => {
    if (prefillSchoolData?.freeCalendars?.length > 0) {
      setInitialValue({
        freeCalendars:
          [...prefillSchoolData.freeCalendars].sort((a, b) => a - b) || []
      });
      setFormComparisonValues({
        freeCalendars:
          [...prefillSchoolData.freeCalendars].sort((a, b) => a - b) || []
      });
    }
  }, []);

  useEffect(() => {
    setFormComparisonValues({
      freeCalendars:
        [...prefillSchoolData.freeCalendars].sort((a, b) => a - b) || []
    });
  }, [prefillSchoolData]);

  const handleCheckBox = (
    valuesSourceMain,
    event,
    setFieldValue,
    values,
    columnIndex
  ) => {
    const { name, checked } = event.target;

    if (checked) {
      values = {
        ...values,
        freeCalendars: [...values.freeCalendars, name].sort((a, b) => a - b)
      };
    } else {
      const updateYears = values.freeCalendars.filter(
        (years) => years !== name
      );
      values = {
        ...values,
        freeCalendars: [...updateYears].sort((a, b) => a - b)
      };
    }
    setFieldValue(
      'freeCalendars',
      [...values.freeCalendars].sort((a, b) => a - b)
    );
    if (name !== 'all') {
      const isAllSelect = valuesSourceMain.every((mainItem) =>
        values.freeCalendars.includes(mainItem.value)
      );

      if (isAllSelect) {
        setAllSelectValue({ value: true, indexNum: columnIndex });
      } else {
        setAllSelectValue({ value: false, indexNum: columnIndex });
      }
    }
  };
  const handleCheckBoxAll = (
    valuesSourceMain,
    event,
    setFieldValue,
    values,
    columnIndex
  ) => {
    const { name, checked } = event.target;
    if (name === 'all' && checked) {
      valuesSourceMain.forEach((child) => {
        if (!prefillSchoolData?.autoAssignedYears?.includes(child.value)) {
          values.freeCalendars.push(child.value);
        }
      });
    } else {
      const updateCheckBoxes = values.freeCalendars.filter(
        (item) =>
          !valuesSourceMain.some(
            (mainItem) =>
              mainItem.value === item &&
              !prefillSchoolData?.autoAssignedYears?.includes(mainItem.value)
          )
      );
      values = {
        ...values,
        freeCalendars: [...updateCheckBoxes].sort((a, b) => a - b)
      };
    }
    setFieldValue(
      'freeCalendars',
      _.uniq([...values.freeCalendars]).sort((a, b) => a - b)
    );
  };
  const getAllSchools = async () => {
    // PHASE2/CHANGES/SCHOOL

    try {
      const result = await getSchools();
      const schools = [];
      result.forEach((school) => {
        const yearData = school.freeCalendars.map((calendar) => ({
          label: `Year ${calendar}`,
          value: calendar
        }));

        schools.push({
          id: school.id,
          name: school.schoolName,
          years: yearData.length ? yearData : []
        });
      });
      dispatch(getSchoolList(schools));
    } catch (error) {
      console.error(error);
      dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
    }
  };

  return (
    <div>
      <div className={classes.title}>Years</div>
      <div className={classes.subtitle}>
        Add the calendar years to be made available for this school.
      </div>
      <Grid container spacing={0}>
        <Formik
          enableReinitialize={true}
          key={formKey}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            if (props.accountFlag && buttonText === 'Save') {
              await props.submitForm(values, buttonText);
              getAllSchools();
            } else {
              await props.submitForm(values, buttonText);
              getAllSchools();
              handleCancelDisable(false);
            }
          }}
        >
          {({ handleSubmit, values, setFieldValue, initialValues: formikInitialValues  }) => {
            if (deepEqual(formikInitialValues, initialValues)) {
              const isFormUpdated = !deepEqual(formComparisonValues, values);
              setIsFormValuesUpdated(isFormUpdated);
            }

            return (
              <form
                noValidate
                onSubmit={handleSubmit}
                style={{
                  width: '100%',
                  fontFamily: 'Roboto-Regular',
                  letterSpacing: '.3px',
                  fontSize: '1rem'
                }}
              >
                <Grid
                  container
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '3rem',
                    marginBottom: '2rem'
                  }}
                >
                  {yearList.map((yearGroup, columnIndex) => (
                    <Grid item lg={2} md={4} xs={6} key={columnIndex}>
                      {Object.keys(yearGroup).map((groupKey) => (
                        <div
                          key={groupKey}
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <FormControlLabel
                            key={columnIndex}
                            control={
                              <Checkbox
                                color="primary"
                                className={classes.checkboxColor}
                                onChange={(e) =>
                                  handleCheckBoxAll(
                                    yearGroup[groupKey],
                                    e,
                                    setFieldValue,
                                    values,
                                    columnIndex
                                  )
                                }
                                name="all"
                              />
                            }
                            className={classes.formControlLabelColorCustom}
                            label="Select all"
                          />
                          {yearGroup[groupKey].map((year, index) => (
                            <FormControlLabel
                              key={year.value}
                              control={
                                <Checkbox
                                  color="primary"
                                  disabled={
                                    prefillSchoolData?.createdByApiSync &&
                                    prefillSchoolData?.autoAssignedYears?.includes(
                                      year.value
                                    )
                                  }
                                  checked={
                                    values.freeCalendars.includes(year.value) ||
                                    false
                                  }
                                  className={classes.checkboxColor}
                                  onChange={(e) =>
                                    handleCheckBox(
                                      yearGroup[groupKey],
                                      e,
                                      setFieldValue,
                                      values,
                                      columnIndex
                                    )
                                  }
                                  name={year.value}
                                />
                              }
                              label={year.label}
                            />
                          ))}
                        </div>
                      ))}
                    </Grid>
                  ))}
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setButtonText('Back');
                    handleSubmit();
                    window.scroll(0, 0);
                  }}
                  className={classes.disabledButtonText}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setButtonText('Next');
                    handleSubmit();
                    window.scroll(0, 0);
                  }}
                  style={{ marginLeft: '16px' }}
                  className={classes.disabledButtonText}
                >
                  Next
                </Button>
                {isEdit && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setButtonText('Save');
                      handleSubmit();
                      window.scroll(0, 0);
                    }}
                    style={{ marginLeft: '16px', width: 106 }}
                    className={
                      isFormValuesUpdated ||
                      prefillSchoolData?.isSchoolFormUpdated
                        ? classes.buttonText
                        : classes.disabledButtonText
                    }
                    disabled={
                      isFormValuesUpdated ||
                      prefillSchoolData?.isSchoolFormUpdated
                        ? false
                        : true
                    }
                  >
                    Save
                  </Button>
                )}
                {/* {isEdit ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setSave(true);
                      handleSubmit();
                    }}
                    style={{ marginLeft: '2%', width: 106 }}
                    className={
                      isFormValuesUpdated
                        ? classes.buttonText
                        : classes.disabledButtonText
                    }
                    disabled={!isFormValuesUpdated}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setSave(false);
                      handleSubmit();
                    }}
                    style={{ marginLeft: '2%' }}
                    className={classes.buttonText}
                  >
                    Create School Profile
                  </Button>
                )} */}
              </form>
            );
          }}
        </Formik>
      </Grid>
    </div>
  );
}

export default withFirebase(Calendar);
