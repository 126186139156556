import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";


export const editSchoolProfileStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    },
    textwraplabel: {
      display: 'inline-block',
      width: '320px',
      'white-space': 'nowrap',
      overflow: 'hidden !important',
      'text-overflow': 'ellipsis',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: 0.13,
      color: colors.primaryDarkGrey,
      marginTop: 20,
      float: 'left'
    },
    paper: {
      padding: theme.spacing(1),
      display: 'flex',
      marginBottom: '16px'
    },
    calendarEntryWrapper: {
      overflowY: 'scroll',
      height: `calc(100vh - 65px)`
    },
    box: {
      padding: '8px'
    },
    iconButton: {
      padding: 0
    },
    tabNavigations: {
      padding: '0 20px 0'
    },
    navigationButton: {
      width: 120,
      textTransform: 'none'
    },
    actionButton: {
      margin: '15px 0 20px',
      textTransform: 'none'
    },
    activeTab: {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 0
    },
    bredthcrumbTitle: {
      color: '#536e7b',
      fontSize: 16,
      marginRight: 24,
      display: 'inline-block',
      cursor: 'pointer'
    },
    iconStyle: {
      height: 48,
      width: 48,
      position: 'relative',
      borderRadius: 50,
      backgroundColor: '#8bc517'
    },
    translationIconStyle: {
      position: 'relative',
      '&:before': {
        content: "''",
        position: 'absolute',
        top: '2px',
        right: '1px',
        width: '12px',
        height: '12px',
        backgroundColor: colors.primary,
        borderRadius: '50%',
        border: '2px solid white',
        zIndex: '1'
      }
    },
    image: {
      width: 24,
      height: 24,
      position: 'absolute',
      top: '25%',
      left: '25%',
      tintColor: 'white'
    },
    stepperStyle: {
      height: `calc(100% - 64px)`,
      backgroundColor: '#ffffff',
      display: 'flex',
      '& .drawerClass': {
        marginLeft: 'unset !important',
        padding: 'unset !important'
      },
      '& .drawerClass .MuiToolbar-regular': {
        minHeight: 'unset !important'
      },
      '& .drawerClass .MuiPaper-root': {
        boxShadow: 'none !important'
      }
    },
    modalTitle: {
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 1.43,
      letterSpacing: -0.2,
      color: colors.primaryDark
    },
    modalSubHeading: {
      // width: 523,
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 0.1,
      color: colors.primaryDark,
      marginTop: '2%'
    },
    modalContent: {
      minHeight: 75,
      paddingTop: 12
    },
    modalSubTitle: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.1,
      color: colors.primaryDark
    },
    modalBox: {
      width: 450
      // height: 170,
    },
    primaryButton: {
      width: 163,
      height: 50,
      borderRadius: 3,
      backgroundColor: colors.primary,
      marginRight: 20,
      color: colors.white,
      textTransform: 'none'
    },
    greyButton: {
      width: 136,
      height: 50,
      borderRadius: 3,
      backgroundColor: colors.secondaryText,
      color: colors.white,
      textTransform: 'none'
    },
    modalActions: {
      marginTop: '2%'
    },
    inputControlBox: {
      // width: 427,
      backgroundColor: '#ffffff',
      marginTop: 24
    },
    drawerLeftContent: {
      width: `calc(60% - 20px)`,
      height: `calc(100vh - 30px)`,
      borderRadius: 5,
      backgroundColor: 'rgb(255, 245, 224,1)',
      float: 'left',
      position: 'relative',
      margin: 10
    },
    drawerRightContent: {
      width: '40%',
      height: `calc(100vh - 10px)`,
      backgroundColor: '#f4f5f7',
      float: 'right',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    leftContentHeader: {
      textAlign: 'right'
    },
    label: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: 0.13,
      color: colors.primaryDarkGrey,
      marginLeft: 14,
      marginTop: 20,
      float: 'left'
    },
    siderAvatar: {
      marginTop: 12,
      width: 40,
      float: 'left',
      color: colors.primaryDarkGrey,
      fontSize: 14,
      backgroundColor: colors.primaryLightGrey,
      fontWeight: 500
    },
    header: {
      width: '100%',
      flexDirection: 'row',
      height: 71
    },
    heading: {
      fontSize: 14,
      fontWeight: 'bold',
      color: colors.primaryDarkGrey
    },
    siderDetail: {
      fontSize: 14,
      color: colors.primaryDarkGrey,
      marginBottom: 8
    },
    icon: {
      marginRight: 17,
      height: 24,
      width: 24
    },
    siderEdit: {
      height: 40,
      marginTop: 19
    },
    textArea: {
      width: '96%',
      height: '25%',
      borderRadius: 5,
      backgroundColor: '#fff5e0',
      border: 0,
      color: colors.primaryDarkGrey
    },
    leftContentMargin: {
      marginLeft: 40,
      marginRight: 40
    },
    greyButton: {
      width: 136,
      height: 50,
      borderRadius: 3,
      backgroundColor: colors.secondaryText,
      color: colors.white,
      textTransform: 'none'
    },
    toolbarHidden: {
      display: 'none'
    },
    toolbar: {
      backgroundColor: 'transparent',
      border: 0,
      '& .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-target-option': {
        display: 'none'
      }
    },
    textEditor: {
      maxHeight: `calc(100vh - 150px)`
    },
    textEditorOnEdit: {
      maxHeight: `calc(100vh - 230px)`
    },
    handBookContainer: { position: 'absolute', bottom: -20, right: -10 },
    handBookClass: { width: 360, height: 297, opacity: 0.32 },
    studentReportPopUp: {
      width: '240px',
      padding: '20px',
      borderRadius: '8px',
      backgroundColor: '#4c5b68',
      color: '#ffffff',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 5
    },
    reportImage: {
      position: 'absolute',
      right: 0,
      top: -35,
      cursor: 'pointer',
      zIndex: 5
    },
    flexBoxBetween: { display: 'flex', justifyContent: 'space-between' },
  }));