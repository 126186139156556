import { createMuiTheme } from '@material-ui/core';
import moment from 'moment';

const encryptedPasswordSecret = 'mywhanau__secret__key';
const { REACT_APP_PROJECT } = process.env;

const firestoreLimit = {
  inQuery: 10,
  batchQuery: 500
};

const linkUrlRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,9}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

const copyFileUrl =
  REACT_APP_PROJECT === 'mywhanau-admin'
    ? 'https://mywhanau-admin.web.app/'
    : 'https://mywhanau.app/';
const colors = {
  primary: '#8bc517',
  primaryDark: '#474747',
  primaryDarkGrey: '#4c5b68',
  primaryDarkGrey7: 'rgba(76, 91, 104, 0.7)',
  primaryLightGrey: '#bfc5d1',
  secondaryGreyLight: '#f4f5f7',
  secondaryGrey: '#d9dce3',
  secondaryGreyLightSync: '#f0f0f0',
  secondaryGreenLightSync: "#e8f5e9",
  tertiary: '#F4F6F8',
  tertiaryText: '#F1F1F1',
  tertiaryRed: '#bd0000',
  tertiaryDeleteRed: '#f60611',
  tertiaryConfirm: '#FF8A00',
  error: '#C40F0F',
  white: '#FFFFFF'
};

// '#6b747b', // parent
const userColors = ['#ff5200', '#2295a1', '#a81ae9', '#ff9b00', '#164cfe'];

const schoolYearsColors = [
  '#20AE03',
  '#ff5200',
  '#2295a1',
  '#a81ae9',
  '#ff9b00',
  '#164cfe',
  '#f90035',
  '#820ea8',
  '#ffcb00',
  '#aa8049',
  '#0090d1',
  '#a9144a',
  '#ecd801',
  '#34DAD4',
  '#EBD62F'
];

const schoolFileType = {
  LOGO: 'logo',
  SUPPORT_PERSON: 'supportPerson',
  SOCIAL_IMAGE: 'socialImage'
};

const routes = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  setNewPassword: '/set-new-password',
  codeVerify: '/code-verify',
  pageNotFound: '/page-not-found',
  help: 'https://docs.google.com/presentation/d/1_MYFkzlmiZhajq59qBuOOrXLcQ3Dqo_8/edit#slide=id.p1',
  familiesFree: '/families/free',
  familiesFreeNew: '/families/free/new',
  familiesFreePending: '/families/free/pending',
  familiesPaid: '/families/paid',
  consoleTeam: '/console/team',
  consoleSchools: '/console/schools',
  consoleMaintenance: '/console/maintenance',
  agents: '/agents',
  teamCreate: '/console/team/create',
  schools: '/schools',
  membersActive: '/members/active',
  membersNew: '/members/new',
  membersPending: '/members/pending',
  memberCalendar:'/members/calendar',
  schoolCreate: '/schools/create',
  schoolCalendar: '/schools/calendar',
  schoolStudent: '/schools/students',
  schoolGroup: '/schools/groups',
  schoolFiles: '/schools/files',
  schoolAccount: '/schools/account',
  schoolLinks: '/schools/links',
  schoolForm: '/schools/form',
  familyCalendar: '/families/free/calendar',
  familyFiles: '/families/free/files',
  familyNotes: '/families/free/notes',
  familyLinks: '/families/links',
  familyUsers: '/families/free/users',
  membersUsers: '/members/users',
  familyAccount: '/families/free/account',
  memberAccount: '/members/account',
  familyCreate: '/families/free/create',
  memberCreate: '/members/create',
  familyCalendarPaid: '/families/paid/calendar',
  familyFilesPaid: '/families/paid/files',
  familyNotesPaid: '/families/paid/notes',
  familyLinksPaid: '/families/paid/links',
  familyUsersPaid: '/families/paid/users',
  familyAccountPaid: '/families/paid/account',
  familyCreatePaid: '/families/paid/create',
  appResetPassword: '/app-reset-password',
  appPasswordUpdated: '/app-password-updated',
  calendarSubscription:'calendarSubscription'
};

const freeFamilyChildStatus = {
  NEW: 'New',
  INVITED: 'Invited',
  REMIND1: 'Remind 1',
  REMIND2: 'Remind 2',
  OPEN: 'Open',
  OPEN_REMIND: 'Open Remind',
  ACTIVE: 'Active'
};

const emailTypes = {
  MEMBER_APPROVED: {
    type: 'MEMBER_APPROVED',
    customData: {
      firstName: '',
      schoolName: '',
      userEmail: '',
      currentYear: new Date().getFullYear(),
      expiryDate: moment.utc().add(22, 'days').format('DD MMM YYYY')
    }
  }
};

const drawerWidth = 180;

const counties = [
  {
    code: 'AU',
    name: 'Australia'
  },
  {
    code: 'NZ',
    name: 'New Zealand'
  },
  {
    code: 'CN',
    name: 'Hong Kong'
  }
];

const agentCountry = [
  {
    code: 'AU',
    name: 'Australia'
  },
  {
    code: 'PH',
    name: 'Philippines'
  }
];

const states = [
  {
    country: 'AU',
    code: 'NSW',
    name: 'New South Wales'
  },
  {
    country: 'AU',
    code: 'VIC',
    name: 'Victoria'
  },
  {
    country: 'AU',
    code: 'QLD',
    name: 'Queensland'
  },
  {
    country: 'AU',
    code: 'WA',
    name: 'Western Australia'
  },
  {
    country: 'AU',
    code: 'SA',
    name: 'South Australia'
  },
  {
    country: 'AU',
    code: 'TAS',
    name: 'Tasmania'
  },
  {
    country: 'AU',
    code: 'ACT',
    name: 'Australian Capital Territory'
  },
  {
    country: 'AU',
    code: 'NT',
    name: 'Northern Territory'
  },
  {
    country: 'NZ',
    code: 'NZ',
    name: 'New Zealand'
  },
  {
    country: 'UK',
    code: 'OH',
    name: 'Ohio'
  }
];

// If updated, update in firebase funtions timezones too.
const timeZones = [
  {
    value: 8,
    text: '(UTC +8:00) Australian Western Standard Time (AWST)'
  },
  {
    value: 8.01,
    text: '(UTC +8:00) Hong Kong Time (HKT)'
  },
  {
    value: 8.75,
    text: '(UTC +8:45) Australian Central Western Standard Time (ACWST)'
  },
  {
    value: 9.5,
    text: '(UTC +9:30) Australian Central Standard Time (ACST)'
  },
  {
    value: 10,
    text: '(UTC +10:00) Australian Eastern Standard Time (AEST)'
  },
  {
    value: 10.01,
    text: 'Queensland - (UTC +10:00) Australian Eastern Standard Time (AEST)'
  },
  {
    value: 10.5,
    text: '(UTC +10:30) Lord Howe Standard Time (LHST)'
  },
  {
    value: 12,
    text: '(UTC +12:00) New Zealand Standard Time (NZST)'
  },
  {
    value: 12.75,
    text: '(UTC +12:45) Chatham Island Standard Time (CHAST)'
  }
];

const accountTypes = [
  {
    label: 'Family Calendar Assistant: 1 Child',
    value: 'Paid 1-Child'
  },
  {
    label: 'Family Calendar Assistant: 2-3 Child',
    value: 'Paid 2-Child'
  },
  {
    label: 'Family Calendar Assistant: 4 Child',
    value: 'Paid 4-Child'
  },
  { label: 'School Calendar Assistant', value: 'Free' }
];

const hostRadio = [
  {
    label: 'No',
    value: 'false'
  },
  {
    label: 'Yes',
    value: 'true'
  }
];

const groupEntryRadio = [
  {
    label: 'Families',
    value: 'Families'
  },
  {
    label: 'Group',
    value: 'Group'
  },
  {
    label: 'Free',
    value: 'Free'
  }
];
const createWithRadio = [
  {
    label: 'API Sync',
    value: 'APIsync'
  },
  {
    label: 'Manual entry',
    value: 'manualEntry'
  }
];
const gender = [
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

const defaultGroupCategories = ['Class', 'Co-Curricular', 'Staff', 'Subjects'];

const year = [
  {
    label: 'Pre K',
    value: '-1'
  },
  {
    label: 'Kindy',
    value: '0'
  },
  {
    label: 'Year 1',
    value: '1'
  },
  {
    label: 'Year 2',
    value: '2'
  },
  {
    label: 'Year 3',
    value: '3'
  },
  {
    label: 'Year 4',
    value: '4'
  },
  {
    label: 'Year 5',
    value: '5'
  },
  {
    label: 'Year 6',
    value: '6'
  },
  {
    label: 'Year 7',
    value: '7'
  },
  {
    label: 'Year 8',
    value: '8'
  },
  {
    label: 'Year 9',
    value: '9'
  },
  {
    label: 'Year 10',
    value: '10'
  },
  {
    label: 'Year 11',
    value: '11'
  },
  {
    label: 'Year 12',
    value: '12'
  },
  {
    label: 'Year 13',
    value: '13'
  }
];

const status = [
  {
    label: 'Invited',
    value: 'Invited'
  },
  {
    label: 'Remind 1',
    value: 'Remind 1'
  },
  {
    label: 'Remind 2',
    value: 'Remind 2'
  },
  {
    label: 'Open',
    value: 'Open'
  },
  {
    label: 'Open Remind',
    value: 'Open Remind'
  },
  {
    label: 'Remove',
    value: 'Remove'
  }
];

const role = [
  {
    label: 'Agent',
    value: 'Agent'
  },
  {
    label: 'Manager',
    value: 'Manager'
  }
];

const labels = [
  {
    label: 'None',
    value: 'none'
  },
  {
    label: 'Read',
    value: 'Read'
  },
  {
    label: 'Complete',
    value: 'Complete'
  },
  {
    label: 'Complete (?)',
    value: 'Complete (?)'
  },
  {
    label: 'Attend',
    value: 'Attend'
  },
  {
    label: 'Attend (?)',
    value: 'Attend (?)'
  },
  {
    label: 'Register',
    value: 'Register'
  },
  {
    label: 'Register (?)',
    value: 'Register (?)'
  },
  {
    label: 'Buy',
    value: 'Buy'
  },
  {
    label: 'Buy (?)',
    value: 'Buy (?)'
  },
  {
    label: 'Assessment',
    value: 'Assessment'
  },
  {
    label: 'Pay',
    value: 'Pay'
  },
  {
    label: 'Special Deal',
    value: 'Special Deal'
  },
  {
    label: 'Hint',
    value: 'Hint'
  },
  {
    label: 'School Holiday',
    value: 'School Holiday'
  },
  {
    label: 'Alert',
    value: 'Alert'
  }
];

const taskLabels = [
  'Complete',
  'Complete (?)',
  'Register',
  'Register (?)',
  'Buy',
  'Buy (?)',
  'Pay'
];

const weekNames = [
  {
    label: 'M',
    value: 'Mon',
    selected: false
  },
  {
    label: 'T',
    value: 'Tue',
    selected: false
  },
  {
    label: 'W',
    value: 'Wed',
    selected: false
  },
  {
    label: 'T',
    value: 'Thu',
    selected: false
  },
  {
    label: 'F',
    value: 'Fri',
    selected: false
  },
  {
    label: 'S',
    value: 'Sat',
    selected: false
  },
  {
    label: 'S',
    value: 'Sun',
    selected: false
  }
];

const schoolAllYears = {
  all: 'all',
  'Pre K': '-1',
  Kindy: '0',
  'Year 1': '1',
  'Year 2': '2',
  'Year 3': '3',
  'Year 4': '4',
  'Year 5': '5',
  'Year 6': '6',
  'Year 7': '7',
  'Year 8': '8',
  'Year 9': '9',
  'Year 10': '10',
  'Year 11': '11',
  'Year 12': '12',
  'Year 13': '13'
};

const shortYears = {
  'Pre K': 'PK',
  Kindy: 'K',
  'Year 1': 'Y1',
  'Year 2': 'Y2',
  'Year 3': 'Y3',
  'Year 4': 'Y4',
  'Year 5': 'Y5',
  'Year 6': 'Y6',
  'Year 7': 'Y7',
  'Year 8': 'Y8',
  'Year 9': 'Y9',
  'Year 10': 'Y10',
  'Year 11': 'Y11',
  'Year 12': 'Y12',
  'Year 13': 'Y13'
};

const yearsObject = {
  littleChildren: [
    {
      label: 'Pre K',
      value: '-1'
    }
  ],
  middleChildren: [
    {
      label: 'Kindergarten',
      value: '0'
    },
    {
      label: 'Year 1',
      value: '1'
    },
    {
      label: 'Year 2',
      value: '2'
    },
    {
      label: 'Year 3',
      value: '3'
    },
    {
      label: 'Year 4',
      value: '4'
    },
    {
      label: 'Year 5',
      value: '5'
    },
    {
      label: 'Year 6',
      value: '6'
    }
  ],
  highChildren: [
    {
      label: 'Year 7',
      value: '7'
    },
    {
      label: 'Year 8',
      value: '8'
    },
    {
      label: 'Year 9',
      value: '9'
    },
    {
      label: 'Year 10',
      value: '10'
    },
    {
      label: 'Year 11',
      value: '11'
    },
    {
      label: 'Year 12',
      value: '12'
    },
    {
      label: 'Year 13',
      value: '13'
    }
  ]
};
const yearList = [
  {
    littleChildren: [
      {
        label: 'Pre K',
        value: '-1'
      }
    ]
  },

  {
    middleChildren: [
      {
        label: 'Kindergarten',
        value: '0'
      },
      {
        label: 'Year 1',
        value: '1'
      },
      {
        label: 'Year 2',
        value: '2'
      },
      {
        label: 'Year 3',
        value: '3'
      },
      {
        label: 'Year 4',
        value: '4'
      },
      {
        label: 'Year 5',
        value: '5'
      },
      {
        label: 'Year 6',
        value: '6'
      }
    ]
  },
  {
    highChildren: [
      {
        label: 'Year 7',
        value: '7'
      },
      {
        label: 'Year 8',
        value: '8'
      },
      {
        label: 'Year 9',
        value: '9'
      },
      {
        label: 'Year 10',
        value: '10'
      },
      {
        label: 'Year 11',
        value: '11'
      },
      {
        label: 'Year 12',
        value: '12'
      },
      {
        label: 'Year 13',
        value: '13'
      }
    ]
  }
];

const muiTimePickerTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white
    }
  },
  overrides: {
    MuiInputBase: {
      input: {
        paddingLeft: 10,
        alignItems: 'center',
        display: 'flex',
        height: 23,
        fontSize: 14
      }
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: 'unset !important' //when input is focused, Its just for example. Its better to set this one using primary color
        },
        '&:before': {
          borderBottom: 'unset !important' // when input is not touched
        }
      }
    }
  }
});
const defaultTime = new Date();
const default_PN_time = moment(defaultTime.setHours(19, 30, 0)).format('LLLL');
const default_PN_Staff_time = moment(defaultTime.setHours(16, 30, 0)).format(
  'LLLL'
);
// Group/Year type constant
const multiYear = 'year';

const categories = [
  {
    value: 'active',
    label: 'Active'
  },
  {
    value: 'nonActive',
    label: 'Inactive'
  }
]

const schoolTypes = [
  {
    value: 'all',
    label: 'All Types'
  },
  {
    value: 'API',
    label: 'API'
  },
  {
    value: 'Manual',
    label: 'Non-API'
  }
]

export {
  colors,
  firestoreLimit,
  userColors,
  routes,
  drawerWidth,
  schoolYearsColors,
  schoolFileType,
  freeFamilyChildStatus,
  emailTypes,
  counties,
  agentCountry,
  timeZones,
  accountTypes,
  groupEntryRadio,
  hostRadio,
  gender,
  year,
  defaultGroupCategories,
  status,
  role,
  labels,
  weekNames,
  schoolAllYears,
  states,
  copyFileUrl,
  taskLabels,
  muiTimePickerTheme,
  encryptedPasswordSecret,
  default_PN_time,
  default_PN_Staff_time,
  createWithRadio,
  multiYear,
  yearList,
  linkUrlRegex,
  yearsObject,
  shortYears,
  categories,
  schoolTypes
};
