/* eslint-disable react/display-name */
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addMessage,
  loaderStart,
  loaderStop
} from 'src/redux/actions/appActions';
import { colors, yearList, year as DefaultYear } from 'src/utils/constant';
import * as Yup from 'yup';
import _ from 'lodash';
import clsx from 'clsx';
import ToggleSwitchButton from 'src/components/ToggleSwitchButton';
import TabViewWithSearch from 'src/components/TabViewWithSearch';
import { ApiConnectIcon } from 'src/assets/Icons';
import { withFirebase } from 'src/utils/firebase';
import { useMemo } from 'react';
import { useCallback } from 'react';
import api_icon from 'src/assets/apiIcon.svg';
import {
  API_CREATE_GROUP,
  API_IS_GROUP_EXISTS,
  API_UPDATE_GROUP
} from 'src/utils/api';
import makeApiRequest from 'src/utils/axios';
import RightDrawer from 'src/components/RightDrawer';
import Modal from 'src/components/Modal';
import { resetGroup } from 'src/redux/actions/groupActions';
import apiManagedIcon from 'src/assets/api-managed.png';
import apiManagedReadonlyIcon from 'src/assets/api-managed-readonly.png';
import { createGroup, isGroupExists, updateGroup } from 'src/services/groups';
import { createEditGroupStyles } from 'src/styles/schoolStyle/groupStyle/createEditGroupStyles';
import { MESSAGE_TYPES } from 'src/constants/common';



const groupTabs = [
  {
    label: 'Students'
  },
  // {
  //   label: 'Staff',
  //   disabled: true
  // }
];

const defaultGroupCategories = [
  { key: 'Class', id: 1, selected: false },
  { key: 'Co-Curricular', id: 2, selected: false },
  { key: 'Subject', id: 3, selected: false },
  { key: 'Staff', id: 4, selected: false }
];

const sortByPriority = (pA, pB, s1, s2) => {
  if (pA > pB){
    return -1;
  }else if(pA == pB){
      return sortByName(s1, s2);
  }else{
      return 1;
  }
};

const sortByName = (s1, s2) => {
  const str1 = s1?.toLowerCase();
  const str2 = s2?.toLowerCase();
  if(str1 < str2) { return -1; }
    if(str1 > str2) { return 1; }
    return 0;
};

const sortBySelected = (array,family) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const isSelectAllOptionA = a[0].id === 'all';
    const isSelectAllOptionB = b[0].id === 'all';
    const createdByApiSyncA = a[0].createdByApiSync;
    const createdByApiSyncB = b[0].createdByApiSync;
    const pA1 = a[0].fillCreatedByApiSync && createdByApiSyncA;
    const pB1 = b[0].fillCreatedByApiSync && createdByApiSyncB;
    const pA3 = a[0].selected;
    const pB3 = b[0].selected;
    const pA2 = createdByApiSyncA && pA3;
    const pB2 = createdByApiSyncB && pB3;
    const nameA = a[0].name;
    const nameB = b[0].name;

    if(isSelectAllOptionB || isSelectAllOptionA){
      return 1;
    }else{
      if(pA1 || pB1){
        return sortByPriority(pA1, pB1, nameA, nameB);
      }else if(pA2 || pB2){
        return sortByPriority(pA2, pB2, nameA, nameB);
      }else if(pA3 || pB3){
        return sortByPriority(pA3, pB3, nameA, nameB);
      }else{
        return sortByName(nameA, nameB);
      }
    }
    return 0;
  });
  return stabilizedThis.map((el) => el[0]);
};

const RightSidePanel = React.memo(
  ({ family, isYearSelected, handleChangeMember, setFieldValue, currentGroup }) => {
    const classes = createEditGroupStyles();
    const [searchedMembersValue, setSearchedMembersValue] = useState('');

    const handleSearchChange = (searchValue) => {
      setSearchedMembersValue(searchValue);
    };

    const familyData = useMemo(() => {
      const isAllAvailable = family.some((member) => member?.id === 'all');
      const data = family.filter((member) =>
        member?.id === 'all' && !searchedMembersValue
          ? true
          : !!searchedMembersValue.length
          ? member?.name
              ?.toLowerCase()
              ?.includes(searchedMembersValue?.toLowerCase())
          : true
      );
      return (isAllAvailable && data.length > 1) ||
        (isAllAvailable && data.length && searchedMembersValue.length) ||
        (!isAllAvailable && data.length > 1)
        ? data
        : [];
    }, [family, searchedMembersValue]);

    return (
      <div className={classes.rightPanelContainer}>
        <TabViewWithSearch
          tabOptions={groupTabs}
          searchPlacehlder="Search for account name"
          handleSearchChange={(value) => handleSearchChange(value)}
          isDisabled={isYearSelected}
          disabledSearch={!family?.length}
        />
        <div className={classes.membersDataContainer}>
          {familyData.length ? (
            sortBySelected(familyData, family).map((element, index) => {
              const elementNames = element?.name?.split('(');
              const createdByApiSync = element.createdByApiSync;
              const isAutoAssignedByApi = createdByApiSync && element?.fillCreatedByApiSync;
              const isSelectAllOption = index === 0 && element?.id === 'all';
              return (
                <FormControlLabel
                  disabled={isAutoAssignedByApi}
                  control={
                    <Checkbox
                      checked={!!element.selected}
                      onChange={(e) => {
                        handleChangeMember(e, setFieldValue, family);
                      }}
                      name={element.id}
                      className={clsx(classes.checkboxColor, {
                        [classes.disabledFillApiConnectIconData]:
                          isAutoAssignedByApi,
                        [classes.disabledCheckboxColor]: family.length === 1
                      })}
                      classes={{
                        root: classes.checkbox,
                        checked: classes.checked
                      }}
                      disabled={isAutoAssignedByApi}
                    />
                  }
                  className={clsx(classes.labelText, {
                    [classes.memberListLabelText]: !isSelectAllOption,
                    [clsx(
                      classes.formControlLabelColorCustom,
                      classes.formControlLabelColorForSelectAll
                    )]: isSelectAllOption && family.length !== 1
                  })}
                  key={`${element?.id}-${index}`}
                  label={
                    isSelectAllOption ? (
                      element.name
                    ) : (
                      <div className={classes.memebrNameContainer}>
                        <div
                          className={clsx({
                            [classes.disabledFillApiConnectIconData]:
                              isAutoAssignedByApi
                          })}
                        >
                          <span style={{ color: colors.primaryDarkGrey }}>
                            {elementNames[0]}
                          </span>
                          <span
                            style={{ color: colors.primaryLightGrey }}
                          >{`(${elementNames[1]?.toUpperCase()}`}</span>
                        </div>
                        {isAutoAssignedByApi ? <img src={apiManagedReadonlyIcon} /> : createdByApiSync ? <img src={apiManagedIcon} /> : <></>}
                      </div>
                    )
                  }
                />
              );
            })
          ) : !isYearSelected ? (
            <span style={{ color: colors.primaryDarkGrey }}>
              No members found
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
);

const YearListData = React.memo(
  ({ groupYears, handleYearChange, setFieldValue }) => {
    const classes = createEditGroupStyles();
    const { currentGroup} = useSelector(
      (state) => state.group
    );
    return (
      <div className={classes.formControlYear}>
        {yearList.map((yearGroup, columnIndex) => (
          <div key={columnIndex}>
            {Object.keys(yearGroup).map((groupKey) => {
              const currentGroupYears = groupYears.filter(
                (year) => year.parent === groupKey
              );
              const isSelectAllEnabled = !!currentGroupYears.length;
              const shouldSelectAll = !isSelectAllEnabled
                ? false
                : currentGroupYears.every((year) => year.selected);

              const shouldDisabledEnabledSelectAll = shouldSelectAll
                ? currentGroupYears.some(
                    (year) => !!year?.isHasFillApiSyncMembers
                  )
                : false;

              return (
                <div
                  key={groupKey}
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <FormControlLabel
                    key={columnIndex}
                    control={
                      <Checkbox
                        color="primary"
                        className={clsx(
                          classes.checkboxColor,
                          classes.checkboxIconContainer,
                          {
                            [classes.disabledCheckboxColor]:
                              !isSelectAllEnabled,
                            [classes.disabledCheckboxColorForApiConnect]:
                              shouldDisabledEnabledSelectAll
                          }
                        )}
                        onChange={(e) =>
                          isSelectAllEnabled &&
                          handleYearChange(
                            e,
                            setFieldValue,
                            groupYears,
                            groupKey
                          )
                        }
                        name="all"
                        disabled={
                          !isSelectAllEnabled || shouldDisabledEnabledSelectAll
                        }
                        checked={shouldSelectAll}
                      />
                    }
                    className={clsx({
                      [clsx(
                        classes.formControlLabelColorCustom,
                        classes.formControlLabelColorForSelectAll
                      )]: isSelectAllEnabled
                    })}
                    label="Select all"
                  />
                  {yearGroup[groupKey].map((year) => {
                    const currentYear = groupYears.find(
                      (yr) => yr.key === year.value
                    );
                    const isDisabled =
                      !currentYear || !!currentYear?.isHasFillApiSyncMembers;
                    const disableAutoAssignYears = currentGroup?.autoAssignedYears?.includes(year?.label);
                    return (
                      <FormControlLabel
                        key={year.value}
                        control={
                          <Checkbox
                            color="primary"
                            checked={!!currentYear?.selected}
                            className={clsx(
                              classes.checkboxColor,
                              classes.checkboxIconContainer,
                              {
                                [classes.disabledCheckboxColor]: isDisabled,
                                [classes.disabledCheckboxColorForApiConnect]:
                                  !!currentYear?.isHasFillApiSyncMembers
                              }
                            )}
                            onChange={(e) =>
                              currentYear &&
                              handleYearChange(
                                e,
                                setFieldValue,
                                groupYears,
                                groupKey
                              )
                            }
                            disabled={isDisabled || disableAutoAssignYears}
                            name={year.value}
                          />
                        }
                        className={clsx({
                          [classes.formControlLabelColorCustom]: isDisabled
                        })}
                        label={year.label}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }
);

const initialCreateEditGroupValues = {
  groupName: '',
  school: '',
  category: '',
  year: '',
  member: ''
};

const CreateEditGroup = ({
  onClose,
  clearFilters,
  fetchGroup,
  isEditModelOpen,
  onMenuClose,
  isOpen
}) => {
  const classes = createEditGroupStyles();
  const dispatch = useDispatch();
  const [openDeleteMenu, setOpenDeleteMenu] = useState(null);
  const [family, setFamily] = useState([]);
  const [allFilteredFamily, setAllFilteredFamily] = useState([]);
  const [isHide, setIsHide] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [initialValues, setInitialValues] = useState(
    initialCreateEditGroupValues
  );
  const [isOpenModal, setOpenModal] = useState({ open: false, values: null });
  const isLoading = useSelector((state) => state.app.isLoading);
  const { currentGroup, groupAddEditModalData } = useSelector(
    (state) => state.group
  );
  const prefillSchoolData = useSelector((state) => state.school.currentSchool);
  const [groupCategory, setGroupCategory] = useState([]);
  const [groupYears, setGroupYears] = useState([]);

  useEffect(() => {
    if (isOpen && !isEditModelOpen) {
      setGroupCategory(groupAddEditModalData.groupCategory);
      setGroupYears(groupAddEditModalData.groupYears);
      setAllFilteredFamily(groupAddEditModalData.groupMembers);
    }
  }, [isOpen, isEditModelOpen, groupAddEditModalData]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        groupName: Yup.string().trim().required('Group Name is required'),
        year: Yup.string().trim().required('Years are required'),
        category:
          currentGroup?.groupType !== 'Paid'
            ? Yup.string().trim().required('Category is required')
            : Yup.string().trim()
      }),
    [currentGroup?.groupType]
  );

  const handleOnClose = () => {
    setInitialValues(initialCreateEditGroupValues);
    setFamily([]);
    setAllFilteredFamily([]);
    setIsHide(false);
    setFormValues(null);
    setGroupCategory([]);
    setGroupYears([]);
    onClose();
  };

  const handleOnMenuClose = () => {
    setInitialValues(initialCreateEditGroupValues);
    setFamily([]);
    setAllFilteredFamily([]);
    setIsHide(false);
    setFormValues(null);
    setGroupCategory([]);
    setGroupYears([]);
    onMenuClose();
  };

  const checkIsMemberAutoAssigned = (memberId) => {
    return !!currentGroup?.autoAssignedMembers?.some(
      (memId) => memId === memberId
    );
  };

  const selectMembersByCurrentGroupMembers = (
    members = [],
    currentMembers = []
  ) => {
    const groupMembersToString = currentMembers.toString();
    let familyMembers = members.map((filterFamily) => {
      const isMemberAutoAssigned = checkIsMemberAutoAssigned(filterFamily.id);
      return {
        ...filterFamily,
        selected:
          isMemberAutoAssigned ||
          groupMembersToString.includes(filterFamily.id),
        fillCreatedByApiSync: isMemberAutoAssigned
      };
    });
    const checkAllFamilyMembersSelected = familyMembers.every((member) =>
      member.id === 'all' ? true : !!member?.selected
    );
    if (checkAllFamilyMembersSelected) {
      familyMembers = familyMembers.map((filterFamily) => ({
        ...filterFamily,
        selected:
          filterFamily.id === 'all'
            ? checkAllFamilyMembersSelected
            : filterFamily.selected
      }));
    }
    return familyMembers;
  };

  const updateYearsByFillApisync = (members) => {
    const yearKeys = [];
    members.forEach((member) => {
      if (member.fillCreatedByApiSync) {
        const stringYear = member.year.toString();
        if (!yearKeys.includes(stringYear)) {
          yearKeys.push(stringYear);
        }
      }
    });
    return yearKeys;
  };

  useEffect(() => {
    if (isEditModelOpen && currentGroup?.groupName?.length) {
      setIsHide(!!currentGroup.isHide);
      const updatedCategotyArray = groupAddEditModalData.groupCategory.map(
        (item) => {
          return {
            ...item,
            selected: currentGroup.category === item?.id
          };
        }
      );
      setGroupCategory(updatedCategotyArray);
      const selectedYearArray = [];
      let updatedYearArray = groupAddEditModalData.groupYears.map((year) => {
        const selectedYear = currentGroup.years.some(
          (yr) =>
           yr?.toLowerCase() === year?.label?.toLowerCase()
        );
        if (selectedYear) {
          selectedYearArray.push(year.key);
        }
        return {
          ...year,
          selected: selectedYear
        };
      });
      setInitialValues((prev) => ({
        ...prev,
        groupName: currentGroup.groupName,
        category: currentGroup.category,
        member: currentGroup?.groupMembers?.length ? 'selected' : '',
        year: selectedYearArray.length ? 'selected' : ''
      }));
      let filteredFamily = selectMembersByCurrentGroupMembers(
        groupAddEditModalData.groupMembers,
        currentGroup.groupMembers
      );
      setAllFilteredFamily(filteredFamily);
      filteredFamily = filterMembersForSelectedYears(
        updatedYearArray,
        filteredFamily
      );
      setFamily(filteredFamily);
      const yearKeys = updateYearsByFillApisync(filteredFamily);
      updatedYearArray = updatedYearArray.map((year) => {
        return {
          ...year,
          isHasFillApiSyncMembers: yearKeys.some(
            (yearKey) => yearKey?.toLowerCase() === year?.key?.toLowerCase()
          )
        };
      });
      setGroupYears(updatedYearArray);
    }
  }, [dispatch, isEditModelOpen, currentGroup]);

  const handleOpenDeleteMenu = (event) => {
    if (!openDeleteMenu) {
      setOpenDeleteMenu(event.currentTarget);
    }
  };

  const changeSelectAllOptionStateForMembers = useCallback((familyData) => {
    const isAllMembersAreSelected = familyData.every((familyMember) =>
      familyMember.id === 'all' ? true : !!familyMember.selected
    );
    const updatedFamily = familyData.map((familyMember) => ({
      ...familyMember,
      selected:
        familyMember.id === 'all'
          ? isAllMembersAreSelected
          : familyMember.selected
    }));
    return updatedFamily;
  }, []);

  const filterMembersForSelectedYears = useCallback(
    (selectedYears, familyData) => {
      const allFamily = familyData || allFilteredFamily || [];
      let updatableFamily = [];
      if (selectedYears.length) {
        let updatedFamily = allFamily.filter((data) => {
          if (data.id === 'all') return true;
          const stringToNumber = data.year.toString();
          return selectedYears.some(
            (year) => year.selected && year.key === stringToNumber
          );
        });
        if (updatedFamily.length > 1) {
          updatableFamily = changeSelectAllOptionStateForMembers(updatedFamily);
        } else {
          updatableFamily = [];
        }
      } else {
        updatableFamily = [];
      }
      return updatableFamily;
    },
    [allFilteredFamily, changeSelectAllOptionStateForMembers]
  );

  const filterGroupsOnYearChange = useCallback(
    (selectedYears = [], setFieldValue) => {
      const updatedFamily = filterMembersForSelectedYears(selectedYears);
      setFamily(updatedFamily);
      setFieldValue(
        'member',
        updatedFamily.filter((item) => !!item.selected).length ? 'selected' : ''
      );
    },
    [filterMembersForSelectedYears]
  );

  const handleYearChange = useCallback(
    (event, setFieldValue, groupYears, groupKey) => {
      const { name, checked } = event.target;
      const newYears = groupYears.map((year) => {
        return {
          ...year,
          selected: (
            name === 'all' ? year.parent === groupKey : year.key === name
          )
            ? year.isHasFillApiSyncMembers
              ? true
              : checked
            : year.selected
        };
      });
      filterGroupsOnYearChange(newYears, setFieldValue);
      setGroupYears(newYears);
      setFieldValue(
        'year',
        newYears.filter((item) => !!item.selected).length ? 'selected' : ''
      );
    },
    [filterGroupsOnYearChange]
  );

  const handleChangeMember = useCallback((event, setFieldValue, family) => {
    let updatedFamily = [];
    const { name = '' } = event.target;
    let selectAllStatus = false;
    updatedFamily = family.map((familyData) => {
      const isAutoAssignedByApi = familyData.createdByApiSync && familyData.fillCreatedByApiSync;
      if (isAutoAssignedByApi) return familyData;
      if (name === 'all' && familyData.id === 'all') {
        selectAllStatus = !familyData.selected;
      }
      return {
        ...familyData,
        selected:
          familyData.id === name
            ? !familyData.selected
            : name === 'all'
            ? selectAllStatus
            : familyData.selected
      };
    });
    updatedFamily = changeSelectAllOptionStateForMembers(updatedFamily);
    setFamily(updatedFamily);
    setFieldValue(
      'member',
      updatedFamily.filter((item) => !!item.selected).length ? 'selected' : ''
    );
  }, []);

  const submitForm = async (formData) => {

    dispatch(loaderStart());

    let finalData = {...formData};
    let groupMembers = [];
    finalData.groupMembers.forEach((family) => {
      family.familyId.forEach((el) => {
        groupMembers.push({
          childId: family.childId,
          familyId: el
        })
      })
    })
    finalData.groupMembers = [...groupMembers];
   
    // PHASE2/CHANGES/GROUP

    const payload = {
      groupName:finalData.groupName,
      school:finalData.school,
      category:finalData.category,
      years:finalData.years,
      groupMembers:finalData.groupMembers
    }

    try {      
      const result = await createGroup(payload);
      if (result) {
        dispatch(addMessage('New Group Created'));
        handleOnClose();
        fetchGroup();
      }
    } catch(error) {
      dispatch(addMessage(error, MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    } finally {
      dispatch(loaderStop());

    }
  };

  const getComparedAndDiffrece = (data, compArray = []) => {
    return data.filter((el) => !compArray.includes(el));
  };

  const checkGroupNameAlreadyExists = async (formData, setErrors) => {
    // PHASE2/CHANGES/GROUP
    dispatch(loaderStart());

    try {
      const result = await isGroupExists({
        groupName: formData.groupName,
        schoolId: formData.school,
        ...(isEditModelOpen ? { groupId: currentGroup.id } : {})
      })
      setErrors({
        groupName:
          'Please add a unique group name, This group name already used!'
      });
      dispatch(loaderStop());
      return result;
    } catch(error) {
      dispatch(addMessage(error, MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
      return false;
    }
  };

  const formatUpdateData = (values) => {
    const updatabledValues = { ...values };
    // Do not trim group name is apiSync true
    updatabledValues['groupName'] = isCreatedByApiSync ? updatabledValues?.groupName : updatabledValues?.groupName?.trim();

    updatabledValues['groupMembers'] = [];
    updatabledValues['isHide'] = isHide;
    updatabledValues['groupType'] = 'Free';
    updatabledValues['school'] = prefillSchoolData.id;
    if (family?.length) {
      const getSelectedMembers = family
        .map((member) => {
          if (member?.selected === true && member?.id !== 'all') {
            return { childId: member.id, familyId: member.familyId };
          } else {
            return null;
          }
        })
        .filter((member) => member);
      updatabledValues['groupMembers'] = _.compact(getSelectedMembers);
      updatabledValues.member = getSelectedMembers.length ? 'selected' : '';
    }
    if (groupYears?.length) {
      const updatableYears = DefaultYear.filter((year) =>
        groupYears.some(
          (yearVal) => yearVal.key === year.value && yearVal.selected
        )
      ).map((year) => year?.label);
      updatabledValues['years'] = updatableYears;
    }

    let sameData = false;
    if (isEditModelOpen) {
      const propertiesToCompare = Object.keys(updatabledValues);
      const selectedObject1 = _.pick(updatabledValues, propertiesToCompare);
      const selectedObject2 = _.pick(currentGroup, propertiesToCompare);
      sameData = _.isEqual(selectedObject1, selectedObject2);
    }
    if (sameData) {
      handleOnClose();
      return null;
    } else {
      return updatabledValues;
    }
  };

  const handleClose = useCallback(() => {
    setOpenModal({ open: false, values: null });
    dispatch(resetGroup());
  }, []);

  const updateForm = async (updatabledValues) => {
    const onlyMemberIds =
      updatabledValues?.groupMembers?.map((member) => member.childId) || [];
    const getNewMembers = _.uniq(onlyMemberIds);
    const getOldMembers = _.uniq(currentGroup.groupMembers);
    // get old member id which is updated from old selection
    const oldMembers = getComparedAndDiffrece(getOldMembers, getNewMembers);
    // get new member id which is updated from old selection
    const newMembers = getComparedAndDiffrece(getNewMembers, getOldMembers);
    const finalRemovedMembers = [];
    const finalNewMembers = [];
    if (oldMembers.length) {
      oldMembers.forEach((oldMember) => {
        if (oldMember !== 'all') {
          const isExistsInFamily = family.find(
            (famMember) => famMember.id === oldMember
          );
          if (!isExistsInFamily) {
            const isExistsInAllFamily = allFilteredFamily.find(
              (famMember) => famMember.id === oldMember
            );
            if (isExistsInAllFamily) {
              isExistsInAllFamily.familyId.forEach((el) => {
                finalRemovedMembers.push({
                  childId: isExistsInAllFamily.id,
                  familyId: el
                });
              });
            }
          } else {
            isExistsInFamily.familyId.forEach((el) => {
              finalRemovedMembers.push({
                childId: isExistsInFamily.id,
                familyId: el
              });
            });
          }
        }
      });
    }
    if (newMembers.length) {
      newMembers.forEach((newMember) => {
        if (newMember !== 'all') {
          const isExistsInFamily = family.find(
            (famMember) => famMember.id === newMember
          );
          if (!isExistsInFamily) {
            const isExistsInAllFamily = allFilteredFamily.find(
              (famMember) => famMember.id === newMember
            );
            if (isExistsInAllFamily) {
              isExistsInAllFamily.familyId.forEach((el) => {
                finalNewMembers.push({
                  childId: isExistsInAllFamily.id,
                  familyId: el
                });
              });
            }
          } else {
            isExistsInFamily.familyId.forEach((el) => {
              finalNewMembers.push({
                childId: isExistsInFamily.id,
                familyId: el
              });
            });
          }
        }
      });
    }
    const { groupMembers, ...resetUpdatabledValues } = updatabledValues;
    const finalData = {
      ...resetUpdatabledValues,
      ...(finalNewMembers.length ? { addMembers: finalNewMembers } : {}),
      ...(finalRemovedMembers.length
        ? { removeMembers: finalRemovedMembers }
        : {})
    };
    clearFilters();

    const payload = {
      groupName: finalData.groupName,
      school:finalData.school,
      category: finalData.category,
      isHide: finalData.isHide,
      years: finalData.years,
      ...(finalNewMembers.length ? { addMembers: finalNewMembers } : {}),
      ...(finalRemovedMembers.length
        ? { removeMembers: finalRemovedMembers }
        : {})
    }

    try {
      const result = await updateGroup({groupId: currentGroup.id, payload})
      if (result) {
        dispatch(addMessage(result?.message || 'Group updated successfully'));
        handleOnClose();
        dispatch(resetGroup());
        fetchGroup();
      }
    } catch(error) {
      dispatch(addMessage(error, MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  const saveUpdated = async (formData, setErrors) => {
    const isGroupNameExists = await checkGroupNameAlreadyExists(
      formData,
      setErrors
    );
    if (isGroupNameExists) {
      await updateForm(formData);
    }
  };

  const onCloseForm = () => {
    const updatabledValues = formatUpdateData(formValues);
    if (updatabledValues) {
      setOpenModal({
        open: true,
        values: { values: updatabledValues, id: currentGroup.id }
      });
    }
  };

  const isCreatedByApiSync = currentGroup.createdByApiSync && isEditModelOpen;

  return (
    <RightDrawer
      isOpen={isOpen}
      onClose={() => {
        if (isEditModelOpen) {
          onCloseForm();
        } else {
          handleOnClose();
        }
      }}
      width={1005}
    >
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors }) => {
          const updatabledValues = formatUpdateData(values);
          if (updatabledValues) {
            //  PHASE2/CHANGES/GROUP 
            try {
              if (isEditModelOpen) {
                await updateForm(updatabledValues);
              } else if (!isEditModelOpen) {
                const result = await isGroupExists({
                  groupName: currentGroup.groupName || updatabledValues.groupName,
                  schoolId: updatabledValues.school,
                })
                if (!result) {
                  await submitForm(updatabledValues);
                } else {
                  setErrors({
                    groupName:
                      'Please add a unique group name, This group name already used!'
                  });         
                }
              }
            } catch(error) {
              console.log('error', error)
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          setErrors
        }) => {
          setFormValues(values);
          return (
            <React.Fragment>
              <div className={classes.headingContainer}>
                {isCreatedByApiSync ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem'
                    }}
                  >
                    <img src={apiManagedIcon} />
                    <span className={classes.mainHeading}>Group</span>
                  </div>
                ) : (
                  <span className={classes.mainHeading}>
                    {isEditModelOpen ? 'Edit Group' : 'Create Group'}
                  </span>
                )}
                <div>
                  <Button
                    variant="contained"
                    color={'primary'}
                    className={clsx(classes.siderSave, {
                      [classes.editSiderSave]: isEditModelOpen
                    })}
                    onClick={handleSubmit}
                  >
                    {isEditModelOpen ? 'Save' : 'Create Group'}
                  </Button>
                  {!isCreatedByApiSync && (
                    <Button
                      aria-controls="delete-menu"
                      aria-haspopup="true"
                      onClick={handleOpenDeleteMenu}
                      className={classes.moreVertButton}
                    >
                      <MoreVert />
                      <Menu
                        id="delete-menu"
                        anchorEl={openDeleteMenu}
                        open={Boolean(openDeleteMenu)}
                        onClose={() => setOpenDeleteMenu(null)}
                        className={classes.popoverClass}
                      >
                        <MenuItem
                          onClick={() => {
                            setOpenDeleteMenu(null);
                            handleOnMenuClose();
                            if (isEditModelOpen) {
                              onClose();
                            }
                          }}
                        >
                          {isEditModelOpen ? 'Delete' : 'Cancel'}
                        </MenuItem>
                      </Menu>
                    </Button>
                  )}
                </div>
              </div>
              <Divider variant="fullWidth" className={classes.dividerClass} />
              <form
                noValidate
                onSubmit={handleSubmit}
                className={classes.groupSideBarBodyContainer}
              >
                <div className={classes.formControlContainer}>
                  <FormControl
                    component="fieldset"
                    classes={{ root: classes.formControl }}
                    className={clsx(classes.textFieldContainer, {
                      [classes.disabledFillApiConnectIconData]:
                        isCreatedByApiSync
                    })}
                  >
                    <TextField
                      label="Group Name"
                      variant="outlined"
                      name="groupName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(
                        errors.groupName ||
                          (touched.groupName && errors.groupName)
                      )}
                      helperText={
                        errors.groupName ||
                        (touched.groupName && errors.groupName)
                      }
                      value={values.groupName}
                      className={classes.inputControlBox}
                      disabled={isCreatedByApiSync}
                    />
                  </FormControl>

                  <FormControl
                    component="fieldset"
                    variant="outlined"
                    className={clsx(classes.controlBoxRadio, {
                      [classes.disabledFillApiConnectIconData]:
                        isCreatedByApiSync
                    })}
                    classes={{ root: classes.formControlRadio }}
                    error={Boolean(touched.category && errors.category)}
                  >
                    <FormLabel
                      component="legend"
                      className={classes.checkLabel}
                    >
                      Category
                    </FormLabel>
                    <RadioGroup
                      name="category"
                      onChange={handleChange}
                      value={values.category}
                      classes={{ root: classes.radioContainer }}
                    >
                      {defaultGroupCategories?.map((categoryData, index) => {
                        const category = groupCategory.find((ctg) =>
                          ctg?.key
                            ?.toLowerCase()
                            .includes(categoryData?.key?.toLowerCase())
                        );

                        return (
                          <FormControlLabel
                            control={
                              <Radio
                                disabled={!category || isCreatedByApiSync}
                                classes={{
                                  root: classes.radio,
                                  checked: classes.checked
                                }}
                                className={clsx(classes.radioButton, {
                                  [classes.disabledCheckboxColor]: !category
                                })}
                              />
                            }
                            disabled={!category || isCreatedByApiSync}
                            className={classes.labelText}
                            label={categoryData?.key}
                            value={category?.id}
                            key={`${category?.id}-${index}`}
                          />
                        );
                      })}
                    </RadioGroup>
                    {touched.category && errors.category && (
                      <FormHelperText id="category-text">
                        {errors.category}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    component="fieldset"
                    variant="outlined"
                    className={classes.controlBoxRadio}
                    error={Boolean(touched.year && errors.year)}
                  >
                    <FormLabel
                      component="legend"
                      className={classes.checkLabel}
                    >
                      Year(s)
                    </FormLabel>
                    <YearListData
                      groupYears={groupYears}
                      handleYearChange={handleYearChange}
                      setFieldValue={setFieldValue}
                    />
                    {touched.year && errors.year && (
                      <FormHelperText id="year-text">
                        {errors.year}
                      </FormHelperText>
                    )}
                  </FormControl>
                  {!isCreatedByApiSync && <div style={{ display: 'flex', marginTop: 'auto' }}>
                    <ToggleSwitchButton
                      checked={isHide}
                      handleChecked={() => {
                        setIsHide(!isHide);
                      }}
                    />
                    <Typography style={{ margin: '10px 0 0 20px' }}>
                      Do not show on registration form
                    </Typography>
                  </div>}
                </div>
                <Divider
                  orientation="vertical"
                  className={classes.dividerClass}
                  variant="fullWidth"
                />
                <RightSidePanel
                  family={family}
                  setFieldValue={setFieldValue}
                  handleChangeMember={handleChangeMember}
                  isYearSelected={!values.year?.length}
                  currentGroup={currentGroup}
                />
              </form>
              {isOpenModal.open && (
                <Modal isOpen={!!isOpenModal.open} onClose={handleClose}>
                  <div className={classes.modalBox}>
                    <Typography className={classes.modalTitle}>
                      Save your changes?
                    </Typography>
                    <div className={classes.modalContent}>
                      <Typography className={classes.modalSubTitle}>
                        Your changes will be lost if you don't save them.
                      </Typography>
                    </div>
                    <div className={classes.modalActions}>
                      <Button
                        onClick={async () => {
                          setOpenModal({ open: false, values: null });
                          await saveUpdated(
                            isOpenModal.values.values,
                            setErrors
                          );
                        }}
                        color="primary"
                        variant="contained"
                        size="large"
                        startIcon={
                          isLoading && (
                            <CircularProgress color="secondary" size={25} />
                          )
                        }
                        className={classes.primaryButton}
                      >
                        {!isLoading && 'Save Changes'}
                      </Button>
                      <Button
                        onClick={() => {
                          handleClose();
                          handleOnClose();
                        }}
                        variant="contained"
                        size="large"
                        className={classes.greyButton}
                      >
                        Don't Save
                      </Button>
                    </div>
                  </div>
                </Modal>
              )}
            </React.Fragment>
          );
        }}
      </Formik>
    </RightDrawer>
  );
};

export default withFirebase(CreateEditGroup);