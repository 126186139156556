import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  makeStyles,
  Paper,
  Button,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Snackbar,
  SnackbarContent,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Backdrop,
  TableContainer,
  Table,
  TableBody,
  Typography
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import { generateFileHash, isValidFileType } from 'src/utils/functions';
import { withFirebase } from 'src/utils/firebase';
import { colors } from 'src/utils/constant';
import { LinkIcon } from 'src/assets/Icons';
import CloseIcon from '@material-ui/icons/Close';
import { NO_IMAGE, FILE_ICON } from '../../../assets';
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import FileTableRow from 'src/components/FileTableRow';
import clsx from 'clsx';
import ScanningUploadFileModal from 'src/components/ScanningUploadFileModal';
import { formatFilename } from 'src/utils/functions';
import { MESSAGE_TYPES } from 'src/constants/common';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxHeight: 500,
    width: 1000,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  modalHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 25px'
  },
  modalCloseContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '18px',
    cursor: 'pointer',
    marginRight: '5px'
  },
  modalCloseText: {
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: '0.1px',
    color: '#474747'
  },
  actionButton: {
    margin: '15px 0 20px',
    textTransform: 'none'
  },
  tableRow: {
    height: '72px',
    '&:hover': {
      backgroundColor: `rgba(244, 245, 247, 0.51) !important`,
      cursor: 'pointer'
    }
  },
  dataTableCell: {
    color: colors.primaryDark,
    padding: 8,
    '&:first-child': {
      paddingLeft: '2.1%'
    }
  },
  fileImage: {
    width: 56,
    height: 58
  },
  fileStatus: {
    fontSize: 14,
    backgroundColor: '#e8f5e9',
    color: '#348f37',
    padding: '0 5px',
    marginLeft: 36
  },
  fileName: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      color: '#8bc517',
      '& .action-items': {
        opacity: 1
      }
    }
  },
  fileNameContainer:{
    display:'flex',
    alignItems: 'center'
  },
  fileNameFormat: {
    width: 400,
    height: 22,
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.38,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.primaryDarkGrey,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 3
  },
  actionButtons: {
    opacity: 0
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  pagination: {
    '& .MuiInputBase-input': {
      height: 20
    },
    '& .MuiSelect-icon': {
      top: 3
    }
  },
  tableMainContainer: {
    '& .MuiTableContainer-root': {
      boxShadow: 'none'
    }
  },
  filterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 25px'
  },
  filterWrapperBottomBorder: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 134,
    '& .MuiSelect-root': {
      paddingTop: 12,
      paddingBottom: 4
    }
  },
  iconStyle: {
    fontSize: 22,
    verticalAlign: 'middle'
  },
  iconWhite: {
    color: '#fff'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  table: {
    minWidth: 650
  },
  tableContainer: {
    minHeight: 500
  },
  modalTitle: {
    fontSize: 23,
    fontWeight: 500,
    color: colors.primaryDark
  }
}));

const columns = [
  {
    id: 'fileUrl',
    minWidth: 50,
    align: 'left',
    cellAttr: { width: window.location.pathname.match('/paid') ? 126 : 85 }
  },
  { id: 'fileName', minWidth: 170, align: 'left' },
  {
    id: 'uploadDate',
    minWidth: 170,
    align: window.location.pathname.match('/paid') ? 'center' : 'left',
    cellAttr: { width: 200 }
  }
];

const generateMonthOptionsForFilter = (files) => {
  const monthView = [];
  files.map((fileItem) => {
    const uploadedDate = dayjs.unix(fileItem.uploadDate).format('MMMM YYYY');
    const foundMonthIndex = _.findIndex(
      monthView,
      (month) => month.value === uploadedDate
    );
    if (foundMonthIndex > -1) {
      monthView[foundMonthIndex] = {
        label: uploadedDate,
        value: uploadedDate
      };
    } else {
      monthView.push({
        label: uploadedDate,
        value: uploadedDate
      });
    }
    return null;
  });
  return monthView;
};

const AttachFilesModal = ({
  firebase,
  handleInsertClick,
  data,
  onClose,
  isSchoolFiles = false
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentTimestamp = dayjs().unix();
  const [files, setFiles] = useState([]);
  const monthOptionsForFilter = generateMonthOptionsForFilter(files);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [isFileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [isDeleteFileSuccess, setDeleteFileSuccess] = useState(false);
  const [month, setMonth] = useState('');
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [fileUploadModal, setFileUploadModal] = useState({
    isModalOpen: false,
    fileUploadState: 0
  });

  const isLoading = useSelector((state) => state.app.isLoading);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowPerPage(+event.target.value);
    setPage(0);
  };

  const idToGetFiles = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  const getUploadedFiles = useCallback(() => {
    dispatch(loaderStart());
    (isSchoolFiles
      ? firebase.getSchoolEntriesFiles(idToGetFiles)
      : firebase.getFiles(idToGetFiles)
    )
      .then((querySnapshot) => {
        let FilesData = [];
        querySnapshot.forEach((doc) => {
          FilesData.push({ ...doc.data(), ...{ id: doc.id } });
        });
        if (isSchoolFiles) {
          firebase
            .getFiles(idToGetFiles)
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                FilesData.push({ ...doc.data(), ...{ id: doc.id } });
              });
              setFiles(FilesData);
              setFilteredFiles(FilesData);
              dispatch(loaderStop());
              setDeleteFileSuccess(false);
              setFileUploadSuccess(false);
            })
            .catch((error) => {
              console.log('Error getting documents: ', error);
              dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
              dispatch(loaderStop());
            });
        } else {
          setFiles(FilesData);
          setFilteredFiles(FilesData);
          dispatch(loaderStop());
          setDeleteFileSuccess(false);
          setFileUploadSuccess(false);
        }
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  }, [
    firebase,
    setFiles,
    dispatch,
    isFileUploadSuccess,
    isDeleteFileSuccess,
    idToGetFiles
  ]);

  useEffect(() => {
    getUploadedFiles();
  }, [getUploadedFiles]);

  const handleOnClose = () => {
    setFileUploadModal((prev) => ({ ...prev, isModalOpen: false }));
  };

  const onFileChange = (event) => {
    const fileLength = event.target.files.length;
    if (fileLength > 0) {
      const file = event.target.files[0];
      const fileType = file.name.split('.').pop().toLowerCase();
      event.target.value = ""
      if (isValidFileType(fileType)) {
        const name =  file.name.substring(0, file.name.lastIndexOf('.'))
        const fileName = name + '_mywhanau_' + Date.now() + "." + fileType;
        setFileUploadModal({ isModalOpen: true, fileUploadState: 0 });
        (isSchoolFiles
          ? firebase.uploadSchoolEntriesFile(file, idToGetFiles, fileName)
          : firebase.uploadFile(file, idToGetFiles, fileName)
        )
          .then((snapShot) => {
            //takes a snap shot of the process as it is happening
            if (snapShot && snapShot.state === 'success') {
              (isSchoolFiles
                ? firebase.getDownloadURLForSchoolEntriesFile(
                    idToGetFiles,
                    fileName
                  )
                : firebase.getDownloadURL(file, idToGetFiles, fileName)
              )
                .then((fireBaseUrl) => {
                  const fileData = {
                    [isSchoolFiles ? 'schoolId' : 'familyId']: idToGetFiles,
                    fileHash: generateFileHash(),
                    fileName: fileName,
                    fileType: file.type,
                    fileUrl: fireBaseUrl,
                    uploadDate: currentTimestamp
                  };
                  firebase.addFile(fileData);
                  setFileUploadSuccess(true);
                  handleOnClose();
                })
                .catch((err) => {
                  //catches the errors
                  console.log('Error while Getting Download URL', err);
                  handleOnClose();
                });
            } else {
              setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
            }
          })
          .catch((err) => {
            //catches the errors
            console.log('Error while Uploading URL', err);
            setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
          });
      } else {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 1 });
      }
    }
  };

  const handleMonthChange = (event) => {
    const selectedValue = event.target.value;
    setMonth(selectedValue);
    const selectedMonth = dayjs(selectedValue).format('MM');
    const selectedYear = dayjs(selectedValue).format('YYYY');
    let filteredFiles = files;
    if (!_.isEmpty(selectedValue)) {
      filteredFiles = filteredFiles.filter((e) => {
        const uploadedDate = e.uploadDate;
        const uploadedMonth = dayjs.unix(uploadedDate).format('MM');
        const uploadedYear = dayjs.unix(uploadedDate).format('YYYY');
        return selectedMonth === uploadedMonth && selectedYear === uploadedYear;
      });
      setFilteredFiles(filteredFiles);
    } else {
      setFilteredFiles(filteredFiles);
    }
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setFileUploadSuccess(false);
  };

  const CellItem = ({ row, column }) => {
    const value = row[column.id];
    const classes = useStyles();

    if (column.id === 'fileName') {
      const currentData = dayjs.unix(currentTimestamp).format('YYYY/MM/DD');
      const uploadedDate = dayjs.unix(row['uploadDate']).format('YYYY/MM/DD');
      const fileUrl = row['fileHash'];
      return (
        <div className={classes.fileName}>
          <div className={classes.fileNameContainer}>
            <span className={classes.fileNameFormat}>
              {formatFilename(value)}{' '}
            </span>
            {currentData === uploadedDate && (
                <span className={classes.fileStatus}>NEW</span>
              )}
          </div>
          <div className={[classes.actionButtons + ' action-items']}>
            <Button
              variant="contained"
              component="span"
              size="small"
              color="primary"
              startIcon={
                <LinkIcon
                  viewBox="0 0 24 24"
                  height={24}
                  width={24}
                  color="#FFF"
                  className={classes.iconStyle}
                />
              }
              onClick={() => {
                handleInsertClick(fileUrl, data);
              }}
            >
              Insert File URL
            </Button>
          </div>
        </div>
      );
    }
    if (column.id === 'fileUrl') {
      let imageSource = '';
      if (!_.isEmpty(value)) {
        if (row['fileType'].indexOf('image/') !== -1) {
          imageSource = value;
        } else {
          imageSource = FILE_ICON;
        }
      } else {
        imageSource = NO_IMAGE;
      }

      return (
        <Avatar
          alt={row['fileName']}
          src={imageSource}
          className={classes.fileImage}
        />
      );
    }

    if (column.id === 'uploadDate') {
      return <span>{dayjs.unix(value).format('YYYY/MM/DD')}</span>;
    }
    return '';
  };

  const TableRowItem = ({ row, columns }) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        className={classes.tableRow}
      >
        {columns.map((column) => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              className={classes.dataTableCell}
              {...column.cellAttr}
            >
              <CellItem row={row} column={column} />
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <>
      <div className={classes.modalHeaderContainer}>
        <Typography className={classes.modalTitle}> Select a file</Typography>
        <div className={classes.modalCloseContainer} onClick={onClose}>
          <span className={classes.modalCloseText}>Close</span>
          <CloseIcon />
        </div>
      </div>
      <div
        className={clsx(classes.filterWrapper, {
          [classes.filterWrapperBottomBorder]: !_.isEmpty(filteredFiles)
        })}
      >
        <label htmlFor="upload-new-file">
          <TextField
            style={{ display: 'none' }}
            id="upload-new-file"
            name="upload-new-file"
            type="file"
            onChange={(e) => onFileChange(e)}
          />
          <Button
            variant="contained"
            component="span"
            size="small"
            color="primary"
            className={classes.actionButton}
            startIcon={<AddIcon />}
          >
            Upload New File
          </Button>
        </label>
        <div className={classes.flex}>
          {/* <span>Month</span> */}
          <FormControl
            variant="outlined"
            color="secondary"
            className={classes.formControl}
          >
            <Select
              labelId="filter"
              id="filter"
              displayEmpty
              notched={false}
              inputProps={{ 'aria-label': 'Without label' }}
              value={month}
              onChange={handleMonthChange}
              label="FilterMonth"
            >
              <MenuItem value="">Select Month</MenuItem>
              {!_.isEmpty(monthOptionsForFilter) &&
                monthOptionsForFilter.map((monthValues, key) => {
                  return (
                    <MenuItem key={key} value={monthValues.value}>
                      {monthValues.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.root}>
        <div className={classes.tableMainContainer}>
          <>
            {
              <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            }
            {!_.isEmpty(filteredFiles) && (
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    {filteredFiles
                      .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                      .map((row, index) =>
                        window.location.pathname.match('/paid') ? (
                          <TableRowItem
                            key={index}
                            columns={columns}
                            row={row}
                          />
                        ) : (
                          <FileTableRow
                            key={index}
                            columns={columns}
                            row={row}
                            isEntryModal={true}
                            entryModalData={data}
                            handleInsertClick={handleInsertClick}
                            getFiles={getUploadedFiles}
                          />
                        )
                      )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={filteredFiles.length}
                  rowsPerPage={rowPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  classes={{ selectRoot: classes.pagination }}
                />
              </TableContainer>
            )}
          </>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={isFileUploadSuccess}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: colors.primaryDarkGrey
          }}
          message="File Uploaded Successfully"
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                onClick={handleSnackBarClose}
              >
                <CloseIcon fontSize="small" className={classes.iconWhite} />
              </IconButton>
            </>
          }
        />
      </Snackbar>
      <ScanningUploadFileModal
        handleOnClose={handleOnClose}
        isOpen={fileUploadModal.isModalOpen}
        fileUploadState={fileUploadModal.fileUploadState}
      />
    </>
  );
};

export default withFirebase(AttachFilesModal);
