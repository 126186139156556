import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Paper,
  Typography,
  Button,
  Divider,
  FormControl,
  TextField,
  CircularProgress,
  Link,
  Backdrop
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'src/components/Modal';
import Dashboard from '../layout/Dashboard';
import { routes } from 'src/utils/constant';
import { RightArrow } from 'src/assets/Icons';
import { setSchool } from 'src/redux/actions/schoolActions';
import {
  loaderStop,
  addMessage,
  loaderStart
} from 'src/redux/actions/appActions';
import { withFirebase } from 'src/utils/firebase';
import RightDrawer from 'src/components/RightDrawer';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { HandBook, Edit } from 'src/assets';
import api_icon from 'src/assets/apiIcon.svg';
import schoolBytes from 'src/assets/schoolByte2.webp';
import NavTab from './NavTab';
import studentReportingIcon from 'src/assets/icn-student-reporting.svg';
import { addSchoolFormDetailsOrUpdateSchool, addSchoolLinks, deleteSchool, deleteSchoolLinks, getSchoolByID, updateSchoolLinks } from 'src/services/schools';
import { useLinkStyles } from 'src/styles/linkStyles';
import { AddLinkForm } from './Links/AddLinkForm';
import { LinkListComponent } from './Links/LinkList';
import { MESSAGE_TYPES } from 'src/constants/common';

function SchoolLinksView({ firebase }) {
  const classes = useLinkStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [deleteText, setDeleteText] = useState('');
  const currentSchool = useSelector((state) => state.school.currentSchool);
  const isLoading = useSelector((state) => state.app.isLoading);
  const schoolID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);
  const [isEditMode, setEditMode] = useState(false);
  const [memberData, setMemberData] = useState(false);
  const [defaultNote, setDefaultNote] = useState('');
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [initialValues, setInitialValues] = useState({ title: '', url: '' });
  const [openStudentReportPopUp, setOpenStudentReportPopUp] = useState(false);
  const onCloseForm = () => {
    const defaultData = draftToHtml(
      convertToRaw(defaultNote.getCurrentContent())
    );
    const memberNotes = memberData.notes;
    if (defaultData === memberNotes) {
      onClose();
    } else if (
      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
      !memberNotes
    ) {
      onClose();
    } else if (memberNotes === '') {
      onClose();
    }
  };

  const onClose = useCallback(
    () => {
      setEditMode(false);
      setOpenDrawer(false);
    },
    [setOpenDrawer]
  );

  const openRightDrawer = useCallback(
    (data) => {
      setMemberData(data);
      const contentBlock = htmlToDraft(data.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      setOpenDrawer(true);
    },
    [setOpenDrawer, currentSchool]
  );

  const handleClose = useCallback(() => {
    setEditMode(false);
  }, [setEditMode]);

  const onEditorChange = (value) => {
    const editorData = draftToHtml(convertToRaw(value.getCurrentContent()));
    setMemberData({ ...memberData, notes: editorData });
  };

  const onSaveChanges = async () => {
    // PHASE2/CHANGES/SCHOOL
    dispatch(loaderStart());

    const payload = {
      notes: memberData?.notes,
    }

    try {
      await addSchoolFormDetailsOrUpdateSchool(currentSchool.id, payload);
      dispatch(loaderStop());
      dispatch(setSchool(payload));
      dispatch(addMessage('Changes saved'));
      const contentBlock = htmlToDraft(payload.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      handleClose();
    } catch (error) {
      console.error(error);
      dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  useEffect(() => {
    if (!currentSchool.schoolName && schoolID) {
      dispatch(loaderStart());
      // PHASE2/CHANGES/SCHOOL

      getSchoolByID(schoolID)
        .then((school) => {
          if (school) {
            dispatch(setSchool(school));
          }
          dispatch(loaderStop());
        })
        .catch((error) => {
          console.error(error);
          dispatch(addMessage('Error on getting school', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    }
  }, [currentSchool, schoolID, dispatch]);

  const { schoolName } = useMemo(() => {
    if (currentSchool) {
      return {
        schoolName: currentSchool.schoolName,
        id: currentSchool.id
      };
    }
    return {
      schoolName: '',
      id: ''
    };
  }, [currentSchool]);

  const deleteSchoolEntries = async (id) => {
    await firebase.deleteSchoolEntries(id);
  };

  const deleteSchoolHandle = () => {
    dispatch(loaderStart());
    firebase
      .getFamilyBySchoolId(currentSchool.id)
      .then(function (querySnapshot) {
        const SchoolsData = querySnapshot.size;
        if (!SchoolsData) {
          // PHASE2/CHANGES/SCHOOL
          deleteSchool(currentSchool.id)
            .then(
              () => {
                deleteSchoolEntries(currentSchool.id);
                dispatch(loaderStop());
                dispatch(addMessage('School Deleted!'));
                setOpenDeleteModal(false);
                setOpenConfirmDeleteModal(true);
              }
            )
            .catch(function (error) {
              console.log('Error: ', error);
              dispatch(addMessage('School was not deleted. Please try again', MESSAGE_TYPES.ERROR));
              dispatch(loaderStop());
            });
        } else {
          dispatch(loaderStop());
          setOpenDeleteModal(false);
        }
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  };

  const saveLink = async (links) => {
    dispatch(loaderStart());

    try {
      const result = await addSchoolLinks(links)
      if (result) {
        dispatch(loaderStop());
        dispatch(setSchool({links: result}));
        dispatch(addMessage('Link has been added.'));
      }
    } catch(error) {
      console.error(error);
      setInitialValues({ title: '', url: '' });
      dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  const updateLink = async (row) => {
    const links = currentSchool.links.map((item) => item.id === row.id ? row : item);

    try {
      const payload = {
        schoolId: currentSchool.id,
        linkId: row.id,
        title: row.title,
        url: row.url
      }
      const result = await updateSchoolLinks(payload)
      if (result) {
        dispatch(loaderStop());
        dispatch(setSchool({ links }));
        dispatch(addMessage('Link has been updated.'));
      }
    } catch(error) {
      console.error(error);
      setInitialValues({ title: '', url: '' });
      dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }

  };

  const deleteLink = async (row) => {
    const filteredLinks = currentSchool.links.filter((item) => item.id !== row.id);

    try {
      const result = await deleteSchoolLinks({schoolId: currentSchool.id, linkId: row.id})
      if (result) {
        dispatch(loaderStop());
        dispatch(setSchool({links: filteredLinks}));
        dispatch(addMessage('Link has been deleted.'));
      }
    } catch(error) {
      console.error(error);
      setInitialValues({ title: '', url: '' });
      dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  const navTabsOptions = useMemo(() => {
    return [
      ...(currentSchool?.freeCalendars?.length
        ? [
            {
              label: 'Calendar',
              link: `${routes.schoolCalendar}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Groups',
        link: `${routes.schoolGroup}/${schoolID}`
      },
      {
        label: 'Students',
        link: `${routes.schoolStudent}/${schoolID}`
      },
      {
        divider: true
      },
      ...(currentSchool?.freeCalendars?.length
        ? [
            {
              label: 'Links',
              link: `${routes.schoolLinks}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Notes',
        handleTabClick: () => openRightDrawer(currentSchool)
      },
      {
        label: 'Files',
        link: `${routes.schoolFiles}/${schoolID}`
      },
      ...(currentSchool?.freeCalendars?.length
        ? [
            {
              label: 'Form',
              link: `${routes.schoolForm}/${schoolID}`
            }
          ]
        : []),
      {
        divider: true
      },
      {
        label: 'Account',
        link: `${routes.schoolAccount}/${schoolID}`
      }
    ];
  }, [currentSchool]);

  return (
    <React.Fragment>
      <Dashboard>
        <div className={classes.root}>
          <div>
            <Typography
              variant="body1"
              className={classes.bredthcrumbTitle}
              onClick={() => history && history.push(routes.schools)}
            >
              Schools
            </Typography>
            <RightArrow
              viewBox="0 0 24 24"
              height={24}
              width={24}
              style={{ fontSize: 22, verticalAlign: 'middle' }}
            />
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ marginBottom: 20, marginTop: 22 }}
          >
            {schoolName}
          </Typography>
          <Paper className={classes.tabNavigations}>
          <img
              className={classes.reportImage}
              src={studentReportingIcon}
              alt="Student report"
              onClick={() => setOpenStudentReportPopUp(!openStudentReportPopUp)}
            />
            {openStudentReportPopUp ? (
              <article className={classes.studentReportPopUp}>
                <div style={{ fontWeight: 500, marginBottom: '10px' }}>
                  Student reporting
                </div>
                {currentSchool?.createdByApiSync ? (
                  <div className={classes.flexBoxBetween}>
                    <span>Population</span>
                    <span>{currentSchool?.studentReport?.apiStudent || 0}</span>
                  </div>
                ) : null}
                <div className={classes.flexBoxBetween}>
                  <span>Active</span>
                  <span>{currentSchool?.studentReport?.active || 0}</span>
                </div>
                <div className={classes.flexBoxBetween}>
                  <span>Inactive</span>
                  <span>{currentSchool?.studentReport?.inActive || 0}</span>
                </div>
                {currentSchool?.createdByApiSync ? (
                  <div className={classes.flexBoxBetween}>
                    <span>Opportunity</span>
                    <span>{currentSchool?.studentReport?.nonRegistered || 0}</span>
                  </div>
                ) : null}
              </article>
            ) : null}
            <section
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div>
                <NavTab
                  tabs={navTabsOptions}
                  selectedTab={isOpenDrawer ? 'Notes' : 'Links'}
                />
              </div>
              {currentSchool && currentSchool?.createdByApiSync && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '1rem',
                   // padding: '0 2rem'
                  }}
                >
                  <img src={schoolBytes} height="38px" />
                  <img src={api_icon} />
                </div>
              )}
            </section>
            <Divider />
            <div className={classes.tableContainer}>
              <AddLinkForm initialValues={initialValues} saveLink={saveLink} />
              <LinkListComponent
                currentSchool={currentSchool}
                updateLink={updateLink}
                deleteLink={deleteLink}
                submitSchoolData={(payload) => addSchoolFormDetailsOrUpdateSchool(currentSchool.id, payload)}
              />
            </div>
          </Paper>
        </div>
        {isOpenDeleteModal && (
          <Modal
            isOpen={isOpenDeleteModal}
            onClose={() => {
              setOpenDeleteModal(false);
            }}
          >
            <div className={classes.modalBox}>
              <Typography className={classes.modalTitle}>
                Delete School Profile?
              </Typography>
              <div className={classes.modalSubHeading}>
                <Typography className={classes.modalSubTitle}>
                  <span style={{ fontWeight: 'bold' }}>
                    Warning: This action cannot be reversed.
                  </span>
                  <br />
                  The following data will be permanently removed from the
                  database.
                </Typography>
              </div>
              <div className={classes.modalContent}>
                <Typography className={classes.modalSubTitle}>
                  - School profile (admin & app)
                  <br />
                  - Existing calendar entries for free and paid members (admin &
                  App)
                  <br />
                  - All Groups created for this school
                  <br />
                </Typography>
              </div>
              <div className={classes.modalSubHeading}>
                <span
                  className={classes.modalSubTitle}
                  style={{ fontWeight: 500, paddingBottom: 14 }}
                >
                  Type “DELETE SCHOOL” to confirm
                </span>
                <FormControl component="fieldset" style={{ width: '100%' }}>
                  <TextField
                    label=""
                    variant="outlined"
                    className={classes.inputControlBox}
                    style={{ marginTop: 0 }}
                    value={deleteText}
                    onChange={(e) => setDeleteText(e.target.value)}
                  />
                </FormControl>
              </div>
              <div className={classes.modalActions}>
                <Button
                  onClick={deleteSchoolHandle}
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={deleteText !== 'DELETE SCHOOL'}
                  className={classes.primaryButton}
                  startIcon={
                    isLoading && (
                      <CircularProgress color="secondary" size={25} />
                    )
                  }
                >
                  {!isLoading && 'Delete'}
                </Button>
                <Button
                  onClick={() => {
                    setOpenDeleteModal(false);
                  }}
                  variant="contained"
                  size="large"
                  className={classes.greyButton}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {isOpenConfirmDeleteModal && (
          <Modal
            isOpen={isOpenConfirmDeleteModal}
            onClose={() => history && history.push(routes.schools)}
          >
            <div className={classes.modalBox}>
              <Typography
                className={classes.modalTitle}
                style={{ marginBottom: 18 }}
              >
                School Deleted
              </Typography>
              <Typography
                className={classes.modalSubTitle}
                style={{ marginBottom: 26 }}
              >
                All data associated with {currentSchool.schoolName} has now been
                removed from the database.
              </Typography>
              <div className={classes.modalActions}>
                <Button
                  onClick={() => {
                    setOpenConfirmDeleteModal(false);
                    history && history.push(routes.schools);
                  }}
                  color="primary"
                  variant="contained"
                  size="large"
                  className={classes.primaryButton}
                >
                  Continue
                </Button>
              </div>
            </div>
          </Modal>
        )}
        <RightDrawer
          isOpen={isOpenDrawer}
          onClose={() => {
            onClose();
            onCloseForm();
          }}
          width={900}
          style={{ overflowX: 'hidden' }}
        >
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="primary" />
          </Backdrop>
          <div className={classes.drawerLeftContent}>
            <div className={classes.leftContentMargin}>
              <div className={classes.leftContentHeader}>
                {isEditMode ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.siderEdit}
                    onClick={() => {
                      const defaultData = draftToHtml(
                        convertToRaw(defaultNote.getCurrentContent())
                      );
                      const memberNotes = memberData.notes;
                      if (defaultData === memberNotes) {
                        handleClose();
                      } else if (
                        defaultData.replace(/(\r\n|\n|\r)/gm, '') ===
                          '<p></p>' &&
                        !memberNotes
                      ) {
                        handleClose();
                      } else {
                        onSaveChanges();
                      }
                    }}
                    style={{ width: 165 }}
                  >
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.siderEdit}
                    onClick={() => setEditMode(true)}
                    startIcon={<img src={Edit} />}
                  >
                    Edit
                  </Button>
                )}
              </div>
              <Divider
                variant="fullWidth"
                style={{ marginBottom: 20, marginTop: 22 }}
              />
              {!isEditMode && !memberData.notes && (
                <Typography className={classes.siderDetail}>
                  Click Edit to add a note...
                </Typography>
              )}
              {!isEditMode &&
                memberData.notes &&
                memberData.notes.replace(/(\r\n|\n|\r)/gm, '') ===
                  '<p></p>' && (
                  <Typography className={classes.siderDetail}>
                    Click Edit to add a note...
                  </Typography>
                )}
              <Editor
                stripPastedStyles={true}
                readOnly={!isEditMode}
                defaultEditorState={defaultNote}
                toolbarClassName={
                  !isEditMode ? classes.toolbarHidden : classes.toolbar
                }
                onEditorStateChange={onEditorChange}
                editorClassName={
                  !isEditMode ? classes.textEditor : classes.textEditorOnEdit
                }
                toolbar={{
                  options: ['inline', 'list', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough']
                  },
                  list: {
                    options: ['unordered']
                  },
                  link: {
                    defaultTargetOption: '_blank',
                    options: ['link']
                  }
                }}
              />
            </div>
          </div>
          <div className={classes.drawerRightContent}>
            <div style={{ padding: 18, zIndex: 1 }}>
              <div className={classes.header}>
                {memberData.schoolName && (
                  <Typography
                    className={classes.textwraplabel}
                    title={memberData.schoolName}
                  >
                    {memberData.schoolName}
                  </Typography>
                )}
              </div>
              <Divider style={{ marginBottom: 20 }} />
              {memberData.country && (
                <>
                  <Typography className={classes.heading}>COUNTRY</Typography>
                  <Typography className={classes.siderDetail}>
                    {memberData.country}
                  </Typography>
                </>
              )}
              {memberData.websiteUrl && (
                <>
                  <Typography className={classes.heading}>WEBSITE</Typography>
                  <Link
                    color="secondary"
                    component={Link}
                    onClick={() => window.open(memberData.websiteUrl, '_blank')}
                    className={classes.siderDetail}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    Click Here
                  </Link>
                </>
              )}
            </div>
            <div className={classes.handBookContainer}>
              <img src={HandBook} alt="" className={classes.handBookClass} />
            </div>
          </div>
        </RightDrawer>
      </Dashboard>
    </React.Fragment>
  );
}

export default withFirebase(SchoolLinksView);
