import React, { useState } from 'react';
import {
  TextField,
  Grid,
  FormControl,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Box,
  FormHelperText
} from '@material-ui/core';
import { colors, routes } from 'src/utils/constant';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LoadingBar from 'src/components/schoolAutomationComp/LoadingBarStatus';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import useApiCalls from 'src/customHooks/useApiCalls';
import { useEffect } from 'react';
import axios from 'src/services/axios';
import { useDispatch } from 'react-redux';
import { setSchool } from 'src/redux/actions/schoolActions';
import { useHistory } from 'react-router-dom';
import { addSchoolAPISyncFormStyles } from 'src/styles/schoolStyle/schoolAutomationStyle/addSchoolAPISyncFormStyles';

// import LoadingButton from "@mui/lab/LoadingButton";

// import  useApiPost from 'src/api/AxiosCalls';



const AddSchoolAPISyncForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    isEdit = false,
    setOpenDeleteModal,
    accountFlag,
    handleCancelDisable,
    setHandleCancelClick,
    handleCancelClick,
    setIsValidationSuccessFull
  } = props;
  const classes = addSchoolAPISyncFormStyles();
  const [formKey, setFormKey] = useState(Math.random());
  const [isConnecting, setIsConnecting] = useState(false);
  const [apiFormInitialValues, setApiFormInitialValues] = useState({
    apiSource: 'schoolBytes',
    userName: '',
    password: '',
    schoolName: ''
  });
  const [newPasswordFieldType, setNewPasswordFieldType] = useState('password');
  const [getAPISyncFormData, setGetAPISyncFormData] = useState({});

  // API calls code
  const [isLoading, setIsLoading] = useState(false);
  const [responseCode, setResponseCode] = useState(null);
  const [resultData, setResultData] = useState(null);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [getFormData, setGetFormData] = useState(null);
  const [errorFromFirebase, setErrorFromFirebase] = useState('');

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Axios function
  const axiosCalls = async (getFormData) => {
    const { method, apiSource, endPointState, auth } = getFormData;
    const url = `${BASE_URL}/${apiSource}/school/${endPointState}`;
    try {
      setIsLoading(true);
      const result = await axios({
        method,
        url,
        data: { auth },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentageProgress = Math.round((loaded / total) * 100);
          setProgress(percentageProgress);
        }
      });
      setResponseCode(result.status);
      if (result.status === 200) {
        setResultData(result.data);
        setIsLoading(false);
        setIsValidationSuccessFull(true);
        setIsConnecting(true);
      }
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };
  const testAPICall = (getFormData) => {
    const { method, apiSource, endPointState, auth, data } = getFormData;
    const url = `${BASE_URL}/${apiSource}/school/${endPointState}`;
    const requestOptions = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        auth: auth,
        data: data
      })
    };
    fetch(url, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result
      })
      .then((result) => {
        if (result?.isSchoolExist) {
          setErrorFromFirebase(
            `School already exists "${result?.data?.schoolName}".`
          );
        } else if (result?.message) {
          setErrorFromFirebase(result?.message);
        }
      })
      .catch((error) => console.error('Error:', error));
  };

  // SCHEMA VALIDATOR FOR
  const validationSchema = Yup.object().shape({
    schoolName: Yup.string().trim().required('School name is required'),
    userName: Yup.string().trim().required('Username is required'),
    password: Yup.string().trim().required('Password is required')
  });
  const onToggleNewPasswordFieldType = () => {
    if (newPasswordFieldType === 'password') {
      setNewPasswordFieldType('text');
    } else {
      setNewPasswordFieldType('password');
    }
  };
  const handleFormData = (values) => {
    const { apiSource, userName, password, schoolName } = values;
    const formValues = {
      auth: {
        userName,
        password
      },
      apiSource,
      method: 'POST',
      endPointState: 'validate',
      data: {
        schoolName
      }
    };
    axiosCalls(formValues);
    testAPICall(formValues);
    setGetAPISyncFormData(formValues);
  };
  useEffect(() => {
    dispatch(setSchool(getAPISyncFormData));
  }, [getAPISyncFormData]);

  return (
    <article>
      <Formik
        key={formKey}
        initialValues={apiFormInitialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleFormData(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue
        }) => {
          return (
            <form
              noValidate
              onSubmit={handleSubmit}
              className={classes.contentWrapper}
            >
              <section style={{ marginBottom: '1rem' }}>
                <div className={classes.label}>API Source</div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={values.apiSource}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  >
                    <FormControlLabel
                      value="schoolBytes"
                      control={<Radio color="primary" />}
                      label="School Bytes"
                    />
                    {/* <FormControlLabel
                      value="compass"
                      control={<Radio color="primary" />}
                      label="Compass"
                      disabled
                    />
                    <FormControlLabel
                      value="sentral"
                      control={<Radio color="primary" />}
                      label="Sentral"
                      disabled
                    /> */}
                  </RadioGroup>
                </FormControl>
              </section>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    classes={{ root: classes.formControl }}
                  >
                    <TextField
                      label="School Name"
                      variant="outlined"
                      name="schoolName"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('schoolName', e.target.value);
                        accountFlag && isEdit && handleCancelDisable(true);
                      }}
                      error={Boolean(touched.schoolName && errors.schoolName)}
                      helperText={touched.schoolName && errors.schoolName}
                      value={values.schoolName}
                      className={classes.controlBox}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    classes={{ root: classes.formControl }}
                  >
                    <TextField
                      label="Username"
                      variant="outlined"
                      name="userName"
                      onBlur={(e) => handleBlur(e)}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('userName', e.target.value);

                        accountFlag && isEdit && handleCancelDisable(true);
                      }}
                      error={Boolean(touched.userName && errors.userName)}
                      helperText={touched.userName && errors.userName}
                      value={values.userName}
                      className={classes.controlBox}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    classes={{ root: classes.formControl }}
                  >
                    <TextField
                      label="Password"
                      variant="outlined"
                      onBlur={handleBlur}
                      name="password"
                      value={values.password}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue('password', e.target.value);
                      }}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      className={classes.inputControlBox}
                      type={newPasswordFieldType}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle newPassword visibility"
                              onClick={() => onToggleNewPasswordFieldType()}
                              tabIndex="-1"
                            >
                              <img
                                alt="Password Requirement"
                                src={
                                  newPasswordFieldType === 'text'
                                    ? '/static/images/password-hide.png'
                                    : '/static/images/password-see.png'
                                }
                                width="22"
                                height="22"
                              />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </FormControl>
                </Grid>
                {errorFromFirebase && errorFromFirebase !== "" && (
                  <Box ml={2} mb={2}>
                    <FormHelperText error>{errorFromFirebase}</FormHelperText>
                  </Box>
                )}
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleSubmit()
                    }}
                    className={classes.buttonText}
                  >
                    {isLoading ? 'Connecting' : 'Connect'}

                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.cancelButton}
                    onClick={()=>(history.push(routes?.schools))}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </article>
  );
};

export default AddSchoolAPISyncForm;
