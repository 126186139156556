import React, { useState, useEffect } from 'react';
import { withFirebase, FIREBASE_API_URL } from 'src/utils/firebase';
import _ from 'lodash';
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import {
  colors,
  accountTypes,
  counties,
  timeZones,
  routes
} from 'src/utils/constant';
import Radio from '@material-ui/core/Radio';
import Modal from 'src/components/Modal';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Loop } from '@material-ui/icons';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import { useSelector, useDispatch } from 'react-redux';
import { UserMinusIcon } from 'src/assets/Icons';
import { generateFileHash, generatePatternPassword } from 'src/utils/functions';
import { setFamily } from 'src/redux/actions/familyActions';
import { MESSAGE_TYPES } from 'src/constants/common';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%'
  },
  contentWrapper: {
    maxWidth: 572,
    width: '100%'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
  },
  cancelButton: {
    width: 106,
    height: 50,
    borderRadius: 3,
    border: 'solid 1 ',
    backgroundColor: colors.primaryLightGrey,
    borderColor: colors.primaryLightGrey,
    float: 'left'
  },
  title: {
    width: 303,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  subtitle: {
    width: 400,
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    marginTop: 24
  },
  controlBox: {
    width: 572,
    height: 60,
    borderRadius: 4,
    borderStyle: 'solid',
    border: 1,
    borderColor: colors.primaryLightGrey,
    backgroundColor: '#ffffff',
    marginTop: 24,
    // marginLeft: 0,
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    },
    color: colors.primaryDarkGrey7
  },
  inputControlBox: {
    color: colors.primaryDarkGrey7,
    width: 572,
    backgroundColor: '#ffffff',
    marginTop: 24
  },
  radio: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  checked: {},
  rightAd: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 260,
    height: 260,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: '#f3f4f6',
    padding: '19px 19px',
    background: 'url(/static/images/school-book.png) bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  },
  adTitle: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.2,
    color: colors.primaryDark
  },
  adSubTitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    marginTop: 14
  },
  adButton: {
    height: 30,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    marginTop: 14,
    color: colors.primary
  },

  generate: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: colors.primary,
    float: 'right',
    display: 'flex',
    height: '30px',
    lineHeight: '30px',
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center'
  },
  error: {
    color: '#f44336',
    marginLeft: 14,
    margin: 0,
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 1.66
  },
  buttonText: {
    width: 94,
    height: 50,
    borderRadius: 3,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.white,
    marginTop: '4.3%'
  },
  pauseContainer: {
    position: 'absolute',
    top: 0,
    right: 80,
    display: 'flex',
    flexDirection: 'column'
  },
  pauseText: { textDecoration: 'underline' },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    // width: 523,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  modalContent: {
    minHeight: 75,
    paddingTop: 12
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark
  },
  modalBox: {
    width: 450
    // height: 170,
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  modalActions: {
    marginTop: '2%'
  },
  inputModalControlBox: {
    backgroundColor: '#ffffff',
    marginTop: 24
  },
  buttonTextSave: {
    height: 24,
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center'
  },
  boldFont: {
    fontWeight: 'bold',
    color: 'rgb(71,71,71,1)'
  },
  closeIconRotate: {
    transform: 'rotate(-180deg)',
    transition: 'transform 999ms ease',
    fill: colors.primary
  }
}));

function AddFamily({
  submitForm,
  isEdit = false,
  handlePause,
  handleResume,
  firebase,
  accountFlag,
  handleCancelDisable,
  setHandleCancelClick,
  handleCancelClick
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.app.isLoading);
  const prefillFamilyData = useSelector((state) => state.family.currentFamily);
  const [initialValues, setInitialValue] = useState({
    planType: prefillFamilyData.planType || '',
    familySurname: prefillFamilyData.familySurname,
    userName: prefillFamilyData.userName,
    password: prefillFamilyData.password,
    country: prefillFamilyData.country,
    timezone: prefillFamilyData.timezone
  });
  const [isPauseAccountModal, setPauseAccountModal] = useState(false);
  const [formKey, setFormKey] = useState(Math.random());
  const [isResumeAccountModal, setResumeAccountModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [resumeText, setResumeText] = useState('');
  const [pauseText, setPauseText] = useState('');
  const [deleteText, setDeleteText] = useState('');
  const [isSave, setSave] = useState(false);
  const [isOpenConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [isRotate, setRotate] = useState(false);
  const onTogglePasswordFieldType = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
    } else {
      setPasswordFieldType('password');
    }
  };
  // SCHEMA VALIDATOR FOR CREATE NEW PASSWORD FORM
  const validationSchema = Yup.object().shape({
    familySurname: Yup.string().trim().required('Family Surname is required'),
    userName: prefillFamilyData.planType !== 'Free' ? Yup.string()
      .trim()
      .required('User Name is required')
      .matches(/^[a-zA-Z0-9]+$/, 'Invalid User Name') : null,
    password: !!isEdit
      ? Yup.string()
          .trim()
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
            'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.',
            { excludeEmptyString: true }
          )
      : prefillFamilyData.planType !== 'Free' ? Yup.string()
          .trim()
          .required('Password is required')
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
            'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.'
          ) : null,
    planType: Yup.string().trim().required('Plan Type is required'),
    country: Yup.string().trim().required('Country is required'),
    timezone: Yup.string().trim().required('Timezone is required')
  });
  let { id } = useParams();

  useEffect(() => {
    if (handleCancelClick) {
      setInitialValue({
        planType: prefillFamilyData.planType || '',
        familySurname: prefillFamilyData.familySurname,
        userName: prefillFamilyData.userName,
        password: prefillFamilyData.password,
        country: prefillFamilyData.country,
        timezone: prefillFamilyData.timezone
      });
      setFormKey(Math.random());
      setHandleCancelClick(false);
      handleCancelDisable(false);
    }
  }, [handleCancelClick, setHandleCancelClick]);
  useEffect(() => {
    if (isEdit) {
      setInitialValue({
        planType: prefillFamilyData.planType || '',
        familySurname: prefillFamilyData.familySurname,
        userName: prefillFamilyData.userName,
        password: prefillFamilyData.password,
        country: prefillFamilyData.country,
        timezone: prefillFamilyData.timezone
      });
    } else {
      setInitialValue({
        planType: '',
        familySurname: '',
        userName: '',
        password: '',
        country: '',
        timezone: ''
      });
    }
  }, []);
  const deleteFamilyEntries = async (familyID) => {
    await firebase.deleteEntries(familyID);
  };

  const deleteAuthUsercaller = async (userId) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: userId
      })
    };
    fetch(`${FIREBASE_API_URL}/deleteUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result
      })
      .then(
        (result) => {
          if (result.message === 'Successfully deleted user') {
          } else {
          }
        },
        (error) => {
          const errorMessage = _.get(error, 'message', '');
          console.log(errorMessage);
          dispatch(addMessage(errorMessage, MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      );
  };

  const deleteUserHandle = async () => {
    setDeleteText('');
    dispatch(loaderStart());
    try {
      if (prefillFamilyData.userId) {
        await deleteAuthUsercaller(prefillFamilyData.userId);
        await firebase.deleteUser(prefillFamilyData.userId);

        Array.isArray(prefillFamilyData.childs) &&
          prefillFamilyData.childs.map((childData) => {
            deleteAuthUsercaller(childData.id);
            return firebase.deleteUser(childData.id);
          });
      }

      await deleteFamilyEntries(prefillFamilyData.id);
      firebase
        .deleteFamily(prefillFamilyData.id)
        .then(
          () => {
            dispatch(loaderStop());
            setOpenDeleteModal(false);
            setOpenConfirmDeleteModal(true);
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(addMessage('Family was not deleted. Please try again', MESSAGE_TYPES.ERROR));
            dispatch(loaderStop());
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Family was not deleted. ooo Please try again', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    } catch (error) {
      console.log(error, 'error')
      dispatch(addMessage('Family was not deleted. kkk Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  const generatePassword = (setFieldValue) => {
    setRotate(true);
    setFieldValue('password', generatePatternPassword(8));
    dispatch(addMessage('Password generated and set'));
    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };

  const checkUserExistOrNot = (userName, setErrors) => {
    const email = `${userName}@mywhanau-user.com`;
    firebase
      .isUserExists(email)
      .then((results) => {
        if (results.size > 0) {
          setErrors({
            userName: `Username is already in use by another account.`
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className={classes.headerContainer}>
        {!accountFlag && (
          <div style={{ marginLeft: -10 }}>
            <div className={classes.title}>Create a New Family Account</div>
            <div className={classes.subtitle}>
              All fields are required. Start by selecting the account type.
            </div>
          </div>
        )}
        {isEdit && (
          <div className={classes.pauseContainer}>
            {prefillFamilyData && prefillFamilyData.planStatus === '' && (
              <Button
                onClick={() => setPauseAccountModal(true)}
                startIcon={<PauseCircleOutlineIcon />}
              >
                <div className={classes.pauseText}>Pause Account</div>
              </Button>
            )}
            {prefillFamilyData && prefillFamilyData.planStatus === 'Pause' && (
              <Button
                onClick={() => {
                  setPauseAccountModal(false);
                  setPauseText('');
                  setResumeAccountModal(true);
                }}
                startIcon={
                  <PauseCircleOutlineIcon style={{ color: '#bd0000' }} />
                }
              >
                <div className={classes.pauseText} style={{ color: '#bd0000' }}>
                  Account Paused
                </div>
              </Button>
            )}
            <Button
              onClick={() => setOpenDeleteModal(true)}
              startIcon={<UserMinusIcon />}
            >
              <div className={classes.pauseText}>Delete Family</div>
            </Button>
          </div>
        )}
        {!isEdit && (
          <div className={classes.rightAd}>
            <div className={classes.adTitle}>New School?</div>
            <div className={classes.adSubTitle}>
              The school must first exist before you can create an account and
              add a family to it.
            </div>
            <div>
              <Button
                onClick={() => {
                  history.push(routes.schoolCreate);
                }}
                className={classes.adButton}
              >
                Add a School
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className={classes.contentWrapper}>
        <Formik
          enableReinitialize={true}
          key={formKey}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors }) => {
            const email = `${values.userName}@mywhanau-user.com`;
            if (
              !prefillFamilyData.userName ||
              prefillFamilyData.userName !== values.userName
            ) {
              firebase
                .isUserExists(email)
                .then((results) => {
                  if (results.size > 0) {
                    setErrors({
                      userName: `${values.userName} username is already in use by another account.`
                    });
                  } else {
                    if (accountFlag && isSave) {
                      submitForm(values, true);
                    } else {
                      submitForm(values);
                      // handleCancelDisable(false);
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              if (accountFlag && isSave) {
                submitForm(values, true);
              } else {
                submitForm(values);
                handleCancelDisable(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            setErrors
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <div
                  className={classes.label}
                  style={{ marginTop: accountFlag ? 0 : 47, marginLeft: -10 }}
                >
                  Account Type
                </div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="planType"
                    name="planType"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(
                        setFamily({
                          ...prefillFamilyData,
                          planType: e.target.value
                        })
                      );
                      accountFlag && isEdit && handleCancelDisable(true);
                    }}
                    onBlur={handleBlur}
                    value={values.planType}
                  >
                    {accountTypes.map(function (object) {
                      return (
                        <FormControlLabel
                          key={object.value}
                          value={object.value}
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                            />
                          }
                          label={object.label}
                          className={classes.controlBox}
                          classes={{
                            label:
                              values.planType === object.value
                                ? classes.boldFont
                                : ''
                          }}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                {values.planType ? (
                  <div style={{ marginLeft: '-2.5%' }}>
                    <div className={classes.label}>Account Details</div>
                    <FormControl component="fieldset">
                      <TextField
                        label="Family Surname"
                        variant="outlined"
                        name="familySurname"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          accountFlag && isEdit && handleCancelDisable(true);
                        }}
                        error={Boolean(
                          touched.familySurname && errors.familySurname
                        )}
                        value={values.familySurname}
                        className={classes.inputControlBox}
                        helperText={
                          touched.familySurname && errors.familySurname
                        }
                      />
                    </FormControl>
                    <FormControl component="fieldset">
                      <TextField
                        label="Login Username"
                        variant="outlined"
                        className={classes.inputControlBox}
                        name="userName"
                        onBlur={(e) => {
                          handleBlur(e);
                          if (
                            (!prefillFamilyData.userName ||
                              prefillFamilyData.userName !== values.userName) &&
                            e.target.value
                          ) {
                            checkUserExistOrNot(e.target.value, setErrors);
                          }
                        }}
                        onChange={(e) => {
                          setFieldValue(
                            'userName',
                            e.target.value
                              ? e.target.value.replace(/\s/g, '')
                              : ''
                          );
                          accountFlag && isEdit && handleCancelDisable(true);
                        }}
                        error={Boolean(touched.userName && errors.userName)}
                        value={values.userName}
                        helperText={touched.userName && errors.userName}
                      />
                    </FormControl>
                    <FormControl component="fieldset">
                      <div style={{ width: 680 }}>
                        <TextField
                          label="Password"
                          variant="outlined"
                          className={classes.inputControlBox}
                          name="password"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            accountFlag && isEdit && handleCancelDisable(true);
                          }}
                          error={Boolean(touched.password && errors.password)}
                          value={values.password}
                          helperText={touched.password && errors.password}
                          type={passwordFieldType}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => onTogglePasswordFieldType()}
                                  tabIndex="-1"
                                >
                                  <img
                                    alt="Password Requirement"
                                    src={
                                      passwordFieldType === 'text'
                                        ? '/static/images/password-hide.png'
                                        : '/static/images/password-see.png'
                                    }
                                    width="22"
                                    height="22"
                                  />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        <span className={classes.generate}>
                          Generate
                          <Loop
                            height={24}
                            width={24}
                            style={{ cursor: 'pointer' }}
                            onClick={() => generatePassword(setFieldValue)}
                            className={isRotate && classes.closeIconRotate}
                          />
                        </span>
                      </div>
                    </FormControl>
                    <FormControl variant="outlined" style={{ marginTop: 24 }}>
                      <InputLabel id="country-select-outlined-label">
                        Country
                      </InputLabel>
                      <Select
                        labelId="country-select-outlined-label"
                        id="country-select-outlined"
                        value={values.country}
                        onChange={(e) => {
                          handleChange(e);
                          accountFlag && isEdit && handleCancelDisable(true);
                        }}
                        className={classes.inputControlBox}
                        style={{ width: 273, marginRight: 25, marginTop: 0 }}
                        onBlur={handleBlur}
                        error={Boolean(touched.country && errors.country)}
                        name="country"
                        label="Country"
                      >
                        <MenuItem value="">
                          <em>Country</em>
                        </MenuItem>
                        {counties.map(function (object) {
                          return (
                            <MenuItem key={object.code} value={object.name}>
                              {object.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {Boolean(touched.country && errors.country) ? (
                        <span style={{ height: 20 }} className={classes.error}>
                          {errors.country}
                        </span>
                      ) : (
                        ''
                      )}
                    </FormControl>
                    <FormControl variant="outlined" style={{ marginTop: 24 }}>
                      <InputLabel id="timezone-select-outlined-label">
                        Timezone
                      </InputLabel>
                      <Select
                        labelId="timezone-select-outlined-label"
                        id="timezone-select-outlined"
                        value={values.timezone}
                        onChange={(e) => {
                          handleChange(e);
                          accountFlag && isEdit && handleCancelDisable(true);
                        }}
                        className={classes.inputControlBox}
                        style={{ width: 273, marginTop: 0 }}
                        onBlur={handleBlur}
                        error={Boolean(touched.timezone && errors.timezone)}
                        name="timezone"
                        label="Timezone"
                      >
                        <MenuItem value="">
                          <em>TimeZone</em>
                        </MenuItem>
                        {timeZones.map(function (object) {
                          return (
                            <MenuItem key={object.value} value={object.value}>
                              {object.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {Boolean(touched.timezone && errors.timezone) ? (
                        <span style={{ height: 20 }} className={classes.error}>
                          {errors.timezone}
                        </span>
                      ) : (
                        ''
                      )}
                    </FormControl>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleSubmit();
                          setSave(false);
                          window.scroll(0, 0);
                        }}
                        className={classes.buttonText}
                      >
                        Next
                      </Button>
                      {accountFlag && isEdit && (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonText}
                          onClick={() => {
                            setSave(true);
                            handleSubmit();
                          }}
                          style={{ marginLeft: '2%' }}
                        >
                          Save
                        </Button>
                      )}
                    </Grid>
                  </div>
                ) : (
                  <></>
                )}
              </form>
            );
          }}
        </Formik>
      </div>

      {/* Modals */}
      {isPauseAccountModal && (
        <Modal
          isOpen={isPauseAccountModal}
          onClose={() => {
            setPauseAccountModal(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Account Pause?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                Pausing this account will revoke access for all users associated
                with this family, until account pause is disabled. You can
                continue to manage the account from within the admin.
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “PAUSE ACCOUNT” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={pauseText}
                  onChange={(e) => setPauseText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  handlePause && handlePause();
                  setPauseAccountModal(false);
                }}
                color="primary"
                variant="contained"
                size="large"
                disabled={pauseText !== 'PAUSE ACCOUNT'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Pause'}
              </Button>
              <Button
                onClick={() => {
                  setPauseAccountModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isResumeAccountModal && (
        <Modal
          isOpen={isResumeAccountModal}
          onClose={() => {
            setPauseAccountModal(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Account Resume?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                Resume account will activate access for all users associated
                with this family. You can continue to manage the account from
                within the admin.
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “RESUME ACCOUNT” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={resumeText}
                  onChange={(e) => setResumeText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  handleResume && handleResume();
                  setResumeText('');
                  setResumeAccountModal(false);
                }}
                color="primary"
                variant="contained"
                size="large"
                disabled={resumeText !== 'RESUME ACCOUNT'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Resume'}
              </Button>
              <Button
                onClick={() => {
                  setPauseAccountModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenDeleteModal && (
        <Modal
          isOpen={isOpenDeleteModal}
          onClose={() => {
            setOpenDeleteModal(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Delete Family Account?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                <span style={{ fontWeight: 'bold' }}>
                  Warning: This action cannot be reversed.
                </span>
                <br />
                The following data will be permanently removed from the
                database.
              </Typography>
            </div>
            <div className={classes.modalContent}>
              <Typography className={classes.modalSubTitle}>
                - Family account (admin)
                <br />
                - All associated calendar entries
                <br />
                - Family account and login credentials (app)
                <br />
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “DELETE ACCOUNT” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={deleteText}
                  onChange={(e) => setDeleteText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={deleteUserHandle}
                color="primary"
                variant="contained"
                size="large"
                disabled={deleteText !== 'DELETE ACCOUNT'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Delete'}
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenConfirmDeleteModal && (
        <Modal
          isOpen={isOpenConfirmDeleteModal}
          onClose={() => setOpenConfirmDeleteModal(false)}
        >
          <div className={classes.modalBox}>
            <Typography
              className={classes.modalTitle}
              style={{ marginBottom: 18 }}
            >
              Family Account Deleted
            </Typography>
            <Typography
              className={classes.modalSubTitle}
              style={{ marginBottom: 26 }}
            >
              All data associated with {prefillFamilyData.userName} family has
              now been removed from the database.
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  setOpenConfirmDeleteModal(false);
                  history.push(routes.families);
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
              >
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default withFirebase(AddFamily);
