import React, { useState, Fragment, useEffect, useRef } from 'react';
import {
  makeStyles,
  Typography,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Chip,
  FormHelperText,
  ThemeProvider,
  Radio,
  RadioGroup,
  FormLabel,
  Switch,
  Box
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import clsx from 'clsx';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import { EditorState } from 'draft-js';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import Modal from 'src/components/Modal';
import {
  colors,
  labels,
  taskLabels,
  muiTimePickerTheme,
  year as DefaultYear,
  multiYear
} from 'src/utils/constant';
import { FamiliesIcon, CornerDownRight } from 'src/assets/Icons';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import { Editor } from 'react-draft-wysiwyg';
import CustomDateModal from './components/CustomeDateModal';
import { useSelector, useDispatch } from 'react-redux';
import { withFirebase } from 'src/utils/firebase';
import { EditPencil, Calendar, LocationIcon } from 'src/assets';
import {
  loaderStart,
  loaderStop,
  addMessage,
  setFamilyOrSchoolPreserveEntryData
} from 'src/redux/actions/appActions';
import { generateFileHash, sortLinkedEntries, toGetContentFromHTML, toGetHTMLContent } from 'src/utils/functions';
import GroupEditSection from './components/GroupEditSection';
import { getGroupBySchoolId } from 'src/services/groups';
import EditorComponent from 'src/components/EditorComponent';
import { deepEqual } from 'src/utils/deepEqual';
import { setFromToTimeFormat, virtualEntries } from 'src/utils/virtualEntryJSON';
import { MESSAGE_TYPES } from 'src/constants/common';
import { manageSchoolEntries } from 'src/services/entries';

const useStyles = makeStyles((theme) => ({
  updatedLabel: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 0.09
  },
  updatedDesc: {
    fontSize: 16,
    height: 19,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  chipLabel: {
    height: 18,
    fontfamily: 'Roboto',
    fontweight: 500,
    fontstretch: 'normal',
    fontstyle: 'normal',
    lineheight: 'normal',
    letterspacing: 'normal',
    color: colors.white,
    marginTop: -5
  },
  chipRoot: {
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    height: 20,
    position: 'absolute',
    top: -8,
    left: 10,
    opacity: 1,
    zIndex: 1
  },
  drawerLeftContent: {
    width: `calc(70%)`,
    height: `calc(100vh - 95px)`,
    opacity: 1,
    backgroundColor: colors.white,
    float: 'left',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  drawerRightContent: {
    width: '30%',
    height: `calc(100vh - 95px)`,
    backgroundColor: colors.tertiary,
    float: 'right'
  },
  siderEdit: {
    height: 40,
    marginTop: 19
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 20
  },
  headerTitleContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center'
  },
  publishButton: { marginLeft: 10 },
  familyIconStyle: { height: '40px', width: '40px' },
  titleText: { marginLeft: 10, fontWeight: '600', fontSize: 24 },
  titleAccordionText: {
    marginLeft: 10,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    opacity: 1
  },
  whiteText: { color: colors.white },
  darkPrimaryText: {
    color: colors.primaryDark,
    fontSize: 23,
    fontWeight: '500',
    lineHeight: 1.74
  },
  initialText: {
    color: colors.primaryLightGrey,
    fontSize: 13,
    marginTop: 5,
    marginLeft: 5
  },
  leftContentMargin: { marginTop: 10 },
  inputControlBox: {
    backgroundColor: colors.white,
    marginTop: 16
  },
  inputControlBoxTime: {
    backgroundColor: colors.white,
    marginTop: 0
    //height: 23,
    // width: 45,
    // padding: 0
  },
  formLabelControl: {
    width: 250,
    backgroundColor: colors.white,
    marginTop: 16,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '7.5px 30px 7.5px 14px',
      marginTop: 7
    },
    '& .MuiOutlinedInput-input .inputControlBoxTime': {
      marginTop: 0
    }
  },
  formLabelControlTime: {
    width: 45,
    backgroundColor: colors.white,
    marginTop: 16,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 7.5,
      paddingBottom: 7.5,
      paddingLeft: 4,
      paddingRight: 4,
      marginTop: 0,
      textAlign: 'center'
    }
  },
  formDayLabelControl: {
    // width: '30%',
    // marginTop:2,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '2.5px 14px',
      margin: '4px 0'
    }
  },
  formLabelControlBordered: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: 3,
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10
    }
  },
  formDateControlFromBordered: {
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: 3,
    padding: 0,
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-input': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10
    }
  },
  editorClassName: {
    padding: 10,
    backgroundColor: colors.white
  },
  wrapperClassName: {
    width: 570,
    borderTop: `1px solid ${colors.primaryLightGrey}`,
    borderLeft: `1px solid ${colors.primaryLightGrey}`,
    borderRight: `1px solid ${colors.primaryLightGrey}`,
    borderTopRadius: '5px',
    borderLeftRadius: '5px',
    borderRightRadius: '5px',
    marginTop: '20px'
  },
  wrapperClassNameWithBottomBorder: {
    width: 570,
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: '5px',
    marginTop: '20px'
  },
  wrapperSecondEditorClassName: {
    width: 570,
    // borderTop: `1px solid ${colors.primaryLightGrey}`,
    borderLeft: `1px solid ${colors.primaryLightGrey}`,
    borderRight: `1px solid ${colors.primaryLightGrey}`,
    borderBottom: `1px solid ${colors.primaryLightGrey}`,
    borderTopRadius: '5px',
    borderLeftRadius: '5px',
    borderRightRadius: '5px'
    // marginTop: '20px'
  },
  toolbarClassName: {
    border: 'none',
    marginBottom: 0,
    '& .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-target-option': {
      display: 'none'
    }
  },
  linkedButton: {
    height: 40,
    width: 191,
    borderRadius: 3,
    backgroundColor: colors.secondaryGreyLight,
    marginLeft: 15,
    marginBottom: 15,
    color: colors.primaryDarkGrey,
    fontSize: 16,
    boxShadow: 'none'
  },
  includeText: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey
  },
  iconTrashStyle: {
    fontSize: 22,
    verticalAlign: 'middle',
    color: colors.primaryLightGrey
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modalBox: {
    width: 480
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  modalActions: {
    marginTop: '10%'
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  actionButton: {
    float: 'right',
    marginRight: '10%'
  },
  chipClass: {
    position: 'relative',
    top: 12,
    zIndex: 1,
    left: 30
  },
  checkbox: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  checked: {
    fontWeight: 900
  },
  menuItemClass: {
    padding: '2px 16px'
  },
  descriptionContainer: {
    width: 572,
    borderLeft: `1px solid ${colors.primaryLightGrey}`,
    borderRight: `1px solid ${colors.primaryLightGrey}`
  },
  descriptionMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    backgroundColor: colors.white
  },
  descriptionImageContainer: {
    height: 18,
    width: 18,
    opacity: 0.4
  },
  descriptionTextContainer: {
    margin: 0,
    fontSize: 14,
    color: colors.primaryLightGrey,
    padding: 10
  },
  repeatEveryText: {
    fontSize: 16,
    fontWeight: 500,
    color: colors.primaryDark
  },
  switchBase: {
    // color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    },
    '&$checked + $track': {
      backgroundColor: colors.primary
    }
  },
  orDivider: {
    marginTop: 24,
    borderBottom: `1px solid ${colors.primaryLightGrey}`,
    position: 'relative',
    '& span': {
      position: 'absolute',
      transform: 'translateY(-50%)',
      padding: 5,
      background: colors.tertiary,
      left: 0,
      right: 0,
      maxWidth: 50,
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center'
    }
  },
  deletedBadge: {
    width: 'fit-content',
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 12,
    height: 22,
    marginRight: 10,
    position: 'absolute',
    left: 24,
    zIndex: 1,
    top: -10,
    display: 'flex',
    alignItems: 'center',
    '& span': {
      textTransform: 'uppercase'
    }
  },
  linkDeletedBadge: {
    fontSize: 12,
    backgroundColor: colors.tertiaryRed,
    color: colors.white,
    padding: '3px 6px',
    textTransform: 'uppercase'
  },
  noteText: {
    color: colors.tertiaryRed
  },
  attachModalWrapper: {
    padding: '25px 0'
  }
}));

const entryObject = {
  entries: [
    {
      id: generateFileHash(20),
      isLowPriority: false,
      isPrimary: true,
      linkedArray: [],
      label: '',
      title: '',
      location: '',
      description: '',
      expand: true,
      checkDescription: false,
      for: '',
      primary_description: {
        description: '',
        from: {
          date: moment().format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        }
      },
      assign: [],
      assignDropDownData: [],
      school: '',
      from: {
        date: moment().format('MMM DD,YYYY'),
        time: moment().format('h:mma')
      },
      to: {
        date: moment().format('MMM DD,YYYY'),
        time: moment().format('h:mma')
      },
      allDay: true,
      actions: {
        value: '',
        repeat: [],
        notification: [
          {
            days: -1,
            date: moment().subtract(1, 'days').format('MMM DD,YYYY'),
            time: '7:30pm'
          },
          { days: '', date: moment().format('MMM DD,YYYY'), time: '7:30pm' }
        ],
        deadline: [],
        reminder: [],
        isCancelled: false,
        isPostponed: false,
        isChanged: false,
        isComplete: false,
        reminderNotification: [],
        deadlineNotification: []
      },
      newFromTimeFormate: { hh: '', mm: '', ap: '' },
      newToTimeFormate: { hh: '', mm: '', ap: '' }
    }
  ]
};

function CreateCalenderEntry({
  firebase,
  setRightOpen,
  hasData,
  isOpen,
  getEntries,
  date,
  allCalenderData,
  setHasEntryData,
  goSourceEntry
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const [initialEntry, setInitialEntry] = useState({
    entries: [
      {
        ...entryObject.entries[0],
        id: generateFileHash(20),
        agentName: currentUser.firstName,
        primaryID: '',
        actionParent: { parentID: '', type: '' },
        from: {
          date: date.format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        },
        to: {
          date: date.format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        },
        actions: {
          ...entryObject.entries[0].actions,
          notification: [
            {
              days: -1,
              date: moment(date.format('MMM DD,YYYY'))
                .subtract(1, 'days')
                .format('MMM DD,YYYY'),
              time: '7:30pm'
            },
            {
              days: '',
              date: moment(date.format('MMM DD,YYYY')).format('MMM DD,YYYY'),
              time: '7:30pm'
            }
          ]
        }
      }
    ]
  });
  const [isOpenModal, setOpenModal] = useState({ open: false, value: [] });
  const [schools, setSchools] = useState([]);
  const [isRepeatEntryModal, setRepeatEntryModal] = useState(false);
  const [repeatEntryRadioData, setRepeatEntryRadioData] =
    useState('This Event');
  const [childOptions, setChildOptions] = useState(null);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenDeleteLinkedModal, setOpenDeleteLinkedModal] = useState({
    open: false,
    id: null
  });
  const [defaultNote, setDefaultNote] = useState(EditorState.createEmpty());
  const [customValue, setCustomValue] = useState({});
  const [updatedEntry, setUpdatedEntry] = useState({});
  const [isEditEntry, setisEditEntry] = useState(false);
  const [editForWholeGroup, setEditForWholeGroup] = useState(false);
  const [freeCalendarsOptions, setFreeCalendarsOptions] = useState(null);
  const currentFamily = useSelector((state) => state.family.currentFamily);
  const schoolsData = useSelector((state) => state.auth.schools);
  const isLoading = useSelector((state) => state.app.isLoading);
  const [groups, setGroups] = useState([]);
  const [groupYears, setGroupYears] = useState([]);
  const [isPrimaryDeleted, setIsPrimaryDeleted] = useState({});
  const [isKeysDeleted, setIsKeysDeleted] = useState([]);
  const [isRepeatChange, setIsRepeatChange] = useState(false);
  const [changePrimaryDay, setChangePrimaryDay] = useState('');

  useEffect(() => {
    const childArray = currentFamily.childs.map((child) => {
      return { id: child.id, value: child.firstName };
    });
    setChildOptions(childArray);
  }, [currentFamily, setChildOptions]);

  useEffect(
    () => {
      if (!isOpen) {
        if (isEditEntry) {
          dispatch(
            setFamilyOrSchoolPreserveEntryData({
              entries: [...entryObject.entries]
            })
          );
          setisEditEntry(false);
        } else {
          dispatch(setFamilyOrSchoolPreserveEntryData(updatedEntry));
        }
      }
    },
    // eslint-disable-next-line
    [isOpen, hasData]
  );

  useEffect(
    () => {
      const updatedSchool = schoolsData.filter((school) =>
        currentFamily.schoolIds.includes(school.id)
      );
      setSchools(updatedSchool);
      if (hasData && hasData.length) {
        let schoolId = hasData[0].school || hasData[0].schoolId;
         // Setting default Note for description check
         if (hasData[0].description) {
          setDefaultNote(toGetContentFromHTML(hasData[0].description));
        }
        if (currentFamily.planType === 'Free' && hasData && schoolId) {
          getGroupBySchoolId(schoolId).then(result => {
            let GroupData = [];
            let groupMemberIds = [];
            let years = [];
            let selectedYear = [];
            const findYears = _.find(schoolsData, (school) => !!school && school.id === schoolId);
            years = findYears?.years;
            if (
              ((hasData[0].groups &&
              hasData[0].groups.length)  ||
              hasData[0].for === multiYear) && !!hasData[0]?.forYears
            ) {
              selectedYear = findYears?.years?.map((item) => {
                if (hasData[0].forYears.includes('all')) {
                  return item.value;
                }
                if (hasData[0].forYears.includes('Kindy')) {
                  return '0';
                }
                if (hasData[0].forYears.includes('Pre K')) {
                  return '-1';
                }
                if (hasData[0].forYears.includes(item.value)) {
                  return item.value;
                }
              }).filter(x => x !== undefined);
            }

            if(!!result.data){
              result.data.forEach(function (item) {
                if (item.groupType === 'Free') {
                  groupMemberIds = groupMemberIds.concat(
                    item.groupMembers
                  );

                  GroupData.push({
                    key: item.groupName,
                    value: item.id,
                    selected:
                      hasData[0].groups &&
                      hasData[0].groups.length &&
                      hasData[0].groups.includes(item.id)
                        ? true
                        : false,
                    years: item.years,
                    groupMembers: item.groupMembers
                  });
                }
              });
            }

            years = _.uniq(years);
            selectedYear = _.uniq(selectedYear);
            let updatedYears = [
              {
                key: 'All Years',
                value: 'all',
                selected: years.length === selectedYear.length ? true : false
              }
            ];
            years.forEach((item) => {

              if (item !== 'all') {
                let yearValue =
                  item?.label === 'Pre K'
                    ? '-1'
                    : item?.label?.split('Year ').reverse()[0];
                const getCurrentYear = _.find(
                  DefaultYear,
                  (elem) => elem.value === yearValue
                );
                updatedYears.push({
                  key: getCurrentYear?.label,
                  value: yearValue,
                  selected: selectedYear?.includes(item?.value)
                });
              }
              return null;
            });
            setGroupYears(updatedYears);
            setGroups(GroupData);
          }).catch(error => console.log(error));

          let entryDeleted = [];
          const updateValue = hasData.map((entry, dataIndex) => {
            if (currentFamily.planType === 'Free') {
              let school = entry.school || entry.schoolId;
              if (school) {
                onSchoolNameChange(school, updatedSchool);
              }
            }
            // Entry is deleted start
            if (entry.isDeleted) {
              if (Array.isArray(entry.isDeleted)) {
                let entryDateExist = entry.isDeleted.find(
                  (item) => item.date === entry.from.date
                );
                if (entryDateExist !== undefined) entryDeleted.push(dataIndex);
              } else if (entry.isDeleted !== undefined) {
                if (entry.isDeleted.date === entry.from.date) {
                  entryDeleted.push(dataIndex);
                }
              }
              setIsKeysDeleted(entryDeleted);
              setIsPrimaryDeleted({});
            } else {
              setIsPrimaryDeleted({});
              setIsKeysDeleted(entryDeleted);
            }
            // Entry is deleted end

            const childArray = currentFamily.childs.map((child) => {
              return {
                id: child.id,
                value: child.firstName,
                selected:
                  entry.assign && entry.assign.length
                    ? entry.assign.includes(child.id)
                    : false
              };
            });
            const parentArray = currentFamily.parents.map((parent) => {
              return {
                id: parent.id,
                value: parent.firstName,
                selected:
                  entry.assign && entry.assign.length
                    ? entry.assign.includes(parent.id)
                    : false
              };
            });
            entry.assignDropDownData = [...parentArray, ...childArray];
            return {...entry, school: entry.school || entry.schoolId };
          });
          setisEditEntry(true);
          // short linked entries incorrect order issue - fix
          const sortUpdatedArray = sortLinkedEntries(updateValue);

          setInitialEntry({ entries: [...sortUpdatedArray] });
        } else {
          const updateValue = hasData.map((entry) => {
            const childArray = currentFamily.childs.map((child) => {
              return {
                id: child.id,
                value: child.firstName,
                selected:
                  entry.assign && entry.assign.length
                    ? entry.assign.includes(child.id)
                    : false
              };
            });
            const parentArray = currentFamily.parents.map((parent) => {
              return {
                id: parent.id,
                value: parent.firstName,
                selected:
                  entry.assign && entry.assign.length
                    ? entry.assign.includes(parent.id)
                    : false
              };
            });
            entry.assignDropDownData = [...parentArray, ...childArray];

            // entry.from.date = date.format('MMM DD,YYYY');
            // entry.to.date = date.format('MMM DD,YYYY');

            return entry;
          });
            // short linked entries incorrect order issue - fix
            const sortUpdatedArray = sortLinkedEntries(updateValue);
          setInitialEntry({ entries: [...sortUpdatedArray] });
        }
      }else{
        const updateValue = initialEntry.entries.map((entry) => {
          const childArray = currentFamily.childs.map((child) => {
            return {
              id: child.id,
              value: child.firstName,
              selected:
                entry.assign && entry.assign.length
                  ? entry.assign.includes(child.id)
                  : false
            };
          });
          const parentArray = currentFamily.parents.map((parent) => {
            return {
              id: parent.id,
              value: parent.firstName,
              selected:
                entry.assign && entry.assign.length
                  ? entry.assign.includes(parent.id)
                  : false
            };
          });
          entry.assignDropDownData = [...parentArray, ...childArray];

          entry.from.date = date.format('MMM DD,YYYY');
          entry.to.date = date.format('MMM DD,YYYY');

          return entry;
        });
        setInitialEntry({ entries: [...updateValue] });
      }
  },
  // eslint-disable-next-line
    [
      hasData,
      setInitialEntry,
      schoolsData,
      setSchools,
      currentFamily.schoolIds,
      currentFamily.planType
    ]
  );
  const getInitials = () => {
    const parentArray = currentFamily.parents.map((parent) => parent.initials);
    return parentArray.join(', ');
  };

  const addLinkedEntry = (values) => {
    const newLinkedId = generateFileHash(20);
    let groupsInitValues = {};
    const updateInitialValue = values.entries.map((data) => {
      if (data.isPrimary) {
        if (data.groups && data.groups.length && data.groupType === 'Free') {
          groupsInitValues = {
            for: data.for,
            forYears: data.forYears ? data.forYears : [],
            groups: data.groups,
            groupMembers: data.groupMembers,
            groupType: data.groupType
          };
        }
        return {
          ...data,
          expand: false,
          linkedArray: data.linkedArray
            ? [...data.linkedArray, newLinkedId]
            : [newLinkedId]
        };
      } else {
        delete data.linkedArray;
        return { ...data, expand: false, isPrimary: false };
      }
    });
    delete entryObject.entries[0].linkedArray;
    const childArray = currentFamily.childs.map((child) => {
      return { id: child.id, value: child.firstName, selected: false };
    });
    const parentArray = currentFamily.parents.map((parent) => {
      return { id: parent.id, value: parent.firstName, selected: false };
    });
    setInitialEntry({
      entries: [
        ...updateInitialValue,
        {
          ...entryObject.entries[0],
          ...groupsInitValues,
          id: newLinkedId,
          isPrimary: false,
          assignDropDownData: [...parentArray, ...childArray],
          agentName: currentUser.firstName,
          from: {
            date:
              moment().format('MMM DD,YYYY') ||
              entryObject.entries[0].from.date,
            time: entryObject.entries[0].from.time
          },
          to: {
            date:
              moment().format('MMM DD,YYYY') || entryObject.entries[0].to.date,
            time: entryObject.entries[0].to.time
          },
          actions: {
            ...entryObject.entries[0].actions,
            notification: [
              {
                days: -1,
                date: moment().subtract(1, 'days').format('MMM DD,YYYY'),
                time: '7:30pm'
              },
              { days: '', date: moment().format('MMM DD,YYYY'), time: '7:30pm' }
            ]
          }
        }
      ]
    });
  };

  const removeLinkedEntry = () => {
    if (isOpenDeleteLinkedModal.id) {
      const values = [...initialEntry.entries];

      const removeLinkedEntryId = _.findIndex(
        values[0].linkedArray,
        (item) => item === values[isOpenDeleteLinkedModal.id].id
      );
      const removeLinkedEntryFromCalendar = _.findIndex(
        allCalenderData[0].entries,
        (item) => item.id === values[isOpenDeleteLinkedModal.id].id
      );
      if (removeLinkedEntryFromCalendar > -1) {
        allCalenderData[0].entries.splice(removeLinkedEntryFromCalendar, 1);
      }
      // })
      if (removeLinkedEntryId > -1) {
        values[0].linkedArray.splice(removeLinkedEntryId, 1);
      }

      values.splice(isOpenDeleteLinkedModal.id, 1);
      // Entry key deleted start
      let removeEntryKeys = [...isKeysDeleted];
      removeEntryKeys = removeEntryKeys.filter(
        (item) => item !== isOpenDeleteLinkedModal.id
      );
      setIsKeysDeleted(removeEntryKeys);
      // Entry key deleted end

      setInitialEntry({ entries: [...values] });
    }
    setOpenDeleteLinkedModal({ open: false, id: null });
  };

  const handleExpandChange = (id, values) => {
    let updateInitialValue = [];
    updateInitialValue = values.entries.map((data, index) => {
      if (index === id) {
        return { ...data, expand: data.expand ? false : true };
      } else {
        return { ...data, expand: false };
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const validationSchema = Yup.object().shape({
    entries: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().trim(),
        label: Yup.string()
          .trim()
          .default('none')
          .required('Label is required'),
        title: Yup.string().trim().required('Title is required'),
        location: Yup.string().trim(),
        description: Yup.string().trim(),
        assign: Yup.array(),
        from: Yup.object().required('From is required'),
        school: Yup.string()
          .trim()
          .test('school test', 'School is required', function (val) {
            if (currentFamily.planType === 'Free') {
              return this.parent.school ? true : false;
            } else {
              return true;
            }
          }),
        to: Yup.object()
          .required('To is required')
          .test(
            'date test',
            'From date needs to be before the To date',
            function (val) {
              const { from, to } = this.parent;
              return moment(to.date, 'MMM DD,YYYY').isSameOrAfter(
                moment(from.date, 'MMM DD,YYYY')
              );
            }
          ),
        allDay: Yup.boolean(),
        checkDescription: Yup.boolean(),
        actions: Yup.object().shape({
          repeat: Yup.array().of(
            Yup.object().shape({ type: Yup.string().trim() })
          ),
          notification: Yup.array().of(
            Yup.object().shape({
              days: Yup.string().trim(),
              time: Yup.string().trim()
            })
          ),
          deadline: Yup.array(),
          reminder: Yup.array()
        }),
        newFromTimeFormate: Yup.object().when('allDay', {
          is: false,
          then: Yup.object({
            hh: Yup.string().trim().required('time is required'),
            mm: Yup.string().trim().required('time is required'),
            ap: Yup.string().trim().required('time is required')
          })
        }),
        newToTimeFormate: Yup.object().when('allDay', {
          is: false,
          then: Yup.object({
            hh: Yup.string().trim().required('time is required'),
            mm: Yup.string().trim().required('time is required'),
            ap: Yup.string().trim().required('time is required')
          })
        })
      })
    )
  });

  const handleActionChange = (e, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      let updatedData;
      if (index === selectedIndex) {
        if (e.target.value === 'deadline' || e.target.value === 'reminder') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              [e.target.value]: [
                ...data.actions[e.target.value],
                moment().format('MMM DD,YYYY')
              ],
              [e.target.value + 'Notification']:
                e.target.value === 'reminder'
                  ? [
                      ...(data.actions.reminderNotification || []),
                      {
                        days: '',
                        date: moment().format('MMM DD,YYYY'),
                        time: '7:30pm'
                      }
                    ]
                  : [
                      ...(data.actions.deadlineNotification || []),
                      {
                        days: '',
                        date: moment().format('MMM DD,YYYY'),
                        time: '7:30pm'
                      }
                    ]
            }
          };
        } else if (e.target.value === 'notification') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              notification: [
                ...data.actions.notification,
                {
                  days: '',
                  date: moment(data.from.date, 'MMM DD,YYYY').format(
                    'MMM DD,YYYY'
                  ),
                  time: moment().format('h:mma')
                }
              ]
            }
          };
        } else if (e.target.value === 'repeat') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              repeat: [...data.actions.repeat, { type: 'Does not repeat' }]
            }
          };
        } else if (e.target.value === 'cancelled') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isChanged: false,
              isPostponed: false,
              isCancelled: true
            }
          };
        } else if (e.target.value === 'postponed') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isChanged: false,
              isCancelled: false,
              isPostponed: true
            }
          };
        } else if (e.target.value === 'changed') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isCancelled: false,
              isPostponed: false,
              isChanged: true
            }
          };
        }
      } else {
        updatedData = data;
      }
      return updatedData;
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const onEditorChange = (e, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (data.isPrimary) {
          setDefaultNote(e);
        }
        return { ...data, description: e };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleChangeCheck = (name, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (name === 'checkDescription') {
          setDefaultNote(toGetContentFromHTML(values.entries[0].description));
          return {
            ...data,
            [name]: data[name] ? false : true
          };
        } else {
          return { ...data, [name]: data[name] ? false : true };
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleNotificationDateChange = (
    e,
    selectedIndex,
    innerIndex,
    values,
    value,
    type = ''
  ) => {
    const formateValue = 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (type === 'reminderNotification' && index === selectedIndex) {
        const notificationArray = data.actions.reminderNotification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, reminderNotification: notificationArray }
        };
      } else if (type === 'deadlineNotification' && index === selectedIndex) {
        const notificationArray = data.actions.deadlineNotification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, deadlineNotification: notificationArray }
        };
      } else if (index === selectedIndex) {
        const notificationArray = data.actions.notification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, notification: notificationArray }
        };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleDateChange = (e, selectedIndex, values, key, value) => {
    const formateValue = value === 'time' ? 'h:mma' : 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (key === 'from' && value === 'date') {
          const endDate = moment(data.to.date, 'MMM DD,YYYY').isSameOrAfter(
            moment(e, 'MMM DD,YYYY')
          )
            ? data.to.date
            : moment(e).format(formateValue);
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) },
            to: { ...data['to'], [value]: endDate },
            actions: {
              ...data.actions,
              notification:
                hasData && hasData[0]
                  ? data.actions.notification
                  : [
                      {
                        days: -1,
                        date: moment(e, 'MMM DD,YYYY')
                          .subtract(1, 'days')
                          .format('MMM DD,YYYY'),
                        time: '7:30pm'
                      },
                      {
                        days: '',
                        date: moment(e, 'MMM DD,YYYY').format('MMM DD,YYYY'),
                        time: '7:30pm'
                      }
                    ]
            }
          };
        } else if (key === 'from' && value === 'time') {
          const endTime = moment(e).add(1, 'hour').format(formateValue);
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) },
            to: { ...data['to'], [value]: endTime }
          };
        } else {
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) }
          };
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleRepeatChange = (e, selectedIndex, innerIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        const repeatArray = data.actions.repeat.map(
          (repeatData, repeatIndex) => {
            if (innerIndex === repeatIndex) {
              return { type: e.target.value };
            } else {
              return repeatData;
            }
          }
        );
        return { ...data, actions: { ...data.actions, repeat: repeatArray } };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
    if (hasData && hasData.length && hasData[0].isRepeatEntry) {
      // Default Primary Entry day selected
      if (hasData[0].originalEntryDate) {
        const primaryDay = moment(
          hasData[0].originalEntryDate,
          'MMM DD,YYYY'
        ).format('ddd');
        if (
          hasData[0].actions.repeat[0].values &&
          hasData[0].actions.repeat[0].values.repeatType === 'Week' &&
          hasData[0].actions.repeat[0].values.repeatOn.indexOf(primaryDay) ===
            -1
        ) {
          setChangePrimaryDay(primaryDay);
        } else {
          setChangePrimaryDay('');
        }
      }
      setIsRepeatChange(true);
    }
    if (e.target.value === 'Custom') {
      const data = {
        selectedIndex,
        innerIndex,
        values: { entries: [...updateInitialValue] }
      };
      setCustomValue(data);
      setOpenModal({
        open: true,
        value: JSON.stringify(
          data.values.entries[selectedIndex].actions.repeat[innerIndex]
        )
      });
    }
  };

  const handleDateActionChange = (
    e,
    selectedIndex,
    innerIndex,
    values,
    key,
    value
  ) => {
    const formateValue = value === 'time' ? 'h:mma' : 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (key === 'notification') {
          const notificationArray = data.actions.notification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: { ...data.actions, notification: notificationArray }
          };
        } else if (key === 'reminderNotification') {
          const notificationArray = data.actions.reminderNotification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              reminderNotification: notificationArray
            }
          };
        } else if (key === 'deadlineNotification') {
          const notificationArray = data.actions.deadlineNotification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              deadlineNotification: notificationArray
            }
          };
        } else if (key === 'reminder') {
          const remindNotifyUpdate = data.actions.reminderNotification
            ? [...data.actions.reminderNotification]
            : [];
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                if (
                  remindNotifyUpdate.length &&
                  remindNotifyUpdate[notificationIndex]
                ) {
                  const notificationDate = moment(e, formateValue);
                  const fromDate = moment(data.from.date, formateValue);
                  let dayValue = '';
                  if (moment(notificationDate).isSame(fromDate)) {
                    dayValue = '';
                  } else {
                    dayValue = notificationDate.diff(fromDate, 'days');
                  }
                  remindNotifyUpdate[notificationIndex] = {
                    ...remindNotifyUpdate[notificationIndex],
                    days: dayValue,
                    date: moment(e).format(formateValue)
                  };
                }
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              [key]: actionArray,
              reminderNotification: remindNotifyUpdate
            }
          };
        } else if (key === 'deadline') {
          const deadlineNotifyUpdate = data.actions.deadlineNotification
            ? [...data.actions.deadlineNotification]
            : [];
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                if (
                  deadlineNotifyUpdate.length &&
                  deadlineNotifyUpdate[notificationIndex]
                ) {
                  const notificationDate = moment(e, formateValue);
                  const fromDate = moment(data.from.date, formateValue);
                  let dayValue = '';
                  if (moment(notificationDate).isSame(fromDate)) {
                    dayValue = '';
                  } else {
                    dayValue = notificationDate.diff(fromDate, 'days');
                  }
                  deadlineNotifyUpdate[notificationIndex] = {
                    ...deadlineNotifyUpdate[notificationIndex],
                    days: dayValue,
                    date: moment(e).format(formateValue)
                  };
                }
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              [key]: actionArray,
              deadlineNotification: deadlineNotifyUpdate
            }
          };
        } else if (key) {
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return { ...data, actions: { ...data.actions, [key]: actionArray } };
        } else {
          return data;
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleDateActionRemove = (selectedIndex, innerIndex, values, key) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (
        key === 'isCancelled' ||
        key === 'isPostponed' ||
        key === 'isChanged'
      ) {
        if (index === selectedIndex) {
          const values = data.actions;
          values[key] = false;
          return { ...data, values };
        } else {
          return data;
        }
      } else if (key === 'reminderNotification') {
        if (index === selectedIndex) {
          const values = data.actions.reminderNotification.filter(
            (date, index) => index !== innerIndex
          );
          return {
            ...data,
            actions: { ...data.actions, reminderNotification: values }
          };
        } else {
          return data;
        }
        //reminderNotification
      } else if (key === 'deadlineNotification') {
        if (index === selectedIndex) {
          const values = data.actions.deadlineNotification.filter(
            (date, index) => index !== innerIndex
          );
          return {
            ...data,
            actions: { ...data.actions, deadlineNotification: values }
          };
        } else {
          return data;
        }
        //deadlineNotification
      } else {
        if (index === selectedIndex) {
          const values = data.actions;
          values[key].splice(innerIndex, 1);
          return { ...data, values };
        } else {
          return data;
        }
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleCustomDateSubmit = (dateModalData) => {
    const {
      data: { selectedIndex, innerIndex }
    } = dateModalData;
    const updateInitialValue = dateModalData.data.values.entries.map(
      (data, index) => {
        if (index === selectedIndex) {
          const repeatArray = data.actions.repeat.map(
            (repeatData, repeatIndex) => {
              if (innerIndex === repeatIndex) {
                if (data.isRepeatEntry) {
                  let isEqual = deepEqual(
                    repeatData.values ? repeatData.values : {},
                    dateModalData.values
                  );
                  if (
                    hasData &&
                    hasData.length &&
                    hasData[0].actions.repeat &&
                    hasData[0].actions.repeat.length &&
                    repeatData.type !== hasData[0].actions.repeat[0].type
                  ) {
                    isEqual = false;
                  }
                  setIsRepeatChange(!isEqual);
                }
                return { ...repeatData, values: dateModalData.values };
              } else {
                return repeatData;
              }
            }
          );

          // Default Primary Entry day selected
          if (hasData && hasData.length && hasData[0].originalEntryDate) {
            const primaryDay = moment(
              hasData[0].originalEntryDate,
              'MMM DD,YYYY'
            ).format('ddd');
            if (
              repeatArray[0].values.repeatType === 'Week' &&
              repeatArray[0].values.repeatOn.indexOf(primaryDay) === -1
            ) {
              setChangePrimaryDay(primaryDay);
            } else {
              setChangePrimaryDay('');
            }
          }

          return { ...data, actions: { ...data.actions, repeat: repeatArray } };
        } else {
          return data;
        }
      }
    );
    setInitialEntry({ entries: [...updateInitialValue] });
    setOpenModal({ open: false, value: [] });
    setCustomValue({});
  };

  const handleCustomDateClose = () => {
    const modalValue = JSON.parse(isOpenModal.value);
    const { selectedIndex, innerIndex } = customValue;
    const updateInitialValue = initialEntry.entries.map((data, index) => {
      if (index === selectedIndex) {
        const repeatArray = data.actions.repeat.map(
          (repeatData, repeatIndex) => {
            if (innerIndex === repeatIndex) {
              const fromDate = moment(data.from.date, 'MMM DD,YYYY').format();
              const customDateObject = {
                repeatDay: 1,
                repeatType: 'Day',
                repeatOn: [moment(fromDate).format('ddd')],
                repeatMonth: {
                  type: 'day',
                  value: moment(fromDate).format('MMM DD,YYYY')
                },
                endsType: 'Never',
                endsDate: moment(fromDate).format('MMM DD,YYYY'),
                endsAfter: 1
              };
              return {
                ...repeatData,
                values: modalValue.values ? modalValue.values : customDateObject
              };
            } else {
              return repeatData;
            }
          }
        );
        return { ...data, actions: { ...data.actions, repeat: repeatArray } };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
    setOpenModal({ open: false, value: [] });
    setCustomValue({});
  };

  const submitForm = (formData) => {
    // After change repeat option create new entry logic start
    if (
      isRepeatChange &&
      hasData &&
      hasData.length > 0 &&
      repeatEntryRadioData === 'This and following events'
    ) {
      let newEntries = JSON.parse(JSON.stringify(formData));
      // Delete extra field start
      const deleteFields = [
        'followDates',
        'repeatEntries',
        'repeatDeleted',
        'isRepeatEntry',
        'parentRepeatEntryId',
        'completedDates',
        'isDeleted'
      ];
      deleteFields.map((field) => {
        if (newEntries[0][field]) delete newEntries[0][field];
      });
      // Delete extra field end
      newEntries = [
        {
          ...newEntries[0],
          id: generateFileHash(20),
          isPrimary: true,
          isLinked: false,
          primaryID: ''
        }
      ];

      if (currentFamily.planType === 'Free') {
        submitSchoolForm(newEntries, []);
      } else {
        submitNewFamilyForm(newEntries, []);
      }
    }

    // Update or create existing entry
    if (currentFamily.planType === 'Free') {
      submitSchoolForm(formData, hasData);
    } else {
      submitNewFamilyForm(formData, hasData);
    }
  };

  // Delete entry from schooldataentries collection
  const deleteSchoolDataEntry = async (docId, id) => {
    await firebase.deleteSchoolDataEntries(docId, id);
  };

  const submitNewSchoolForm = async(formData, mainEntries) => {
    const actionDataAppend = [];
    let primary = formData.find((o) => o.isPrimary === true);

    //New time format
    formData = await setFromToTimeFormat(formData);
    //New time format end

    // Create VirtualEntries
    formData = await virtualEntries({ formData, mainEntries, isRepeatChange, allCalenderData, repeatEntryRadioData});

    const groupEntryID = formData[0].groupEntryID
      ? formData[0].groupEntryID
      : null;
    if (
      groupEntryID &&
      formData[0].groupType === 'Free' &&
      mainEntries &&
      mainEntries.length > 0
    ) {
      deleteFreeSchoolFromGroup(groupEntryID, mainEntries);
    }

    // bulk ID created for finding entry into the data entries collections
    const bulkID = primary.bulkID || generateFileHash(20);
    const updateFormData = formData.map((data) => {
      data.bulkID = bulkID;
      data.schoolId = data.school;

      if (data.label === '') {
        //data.label = 'none';
      }
      if (data.actions.reminder.length) {
        data.actions.reminder.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Reminder',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            primaryID: primary.id,
            actions: {
              ...data.actions,
              notification:
                data.actions.reminderNotification &&
                data.actions.reminderNotification[index]
                  ? [data.actions.reminderNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.actions.deadline.length) {
        data.actions.deadline.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Deadline',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            primaryID: primary.id,
            actions: {
              ...data.actions,
              notification:
                data.actions.deadlineNotification &&
                data.actions.deadlineNotification[index]
                  ? [data.actions.deadlineNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.isPrimary === false) {
        if (groupEntryID) {
          // data.groupEntryID = groupEntryID;
          data.school = formData[0].school;
        }
        data.isLinked = true;
      } else {
        data.isLinked = false;
      }
      if (groupEntryID) {
        delete data.groupEntryID;
      }
      //delete data.school;
      return data;
    });

    const final = [...updateFormData, ...actionDataAppend];
    await addSchoolDataEntry(final);
  };

  const deleteFreeSchoolFromGroup = (groupEntryID, hasData) => {
    const oldYear = hasData[0].for;
    firebase
      .getGroupEntries(groupEntryID)
      .then((groupEntries) => {
        if (
          groupEntries &&
          groupEntries.entries &&
          groupEntries.entries.length
        ) {
          let newEntries = JSON.parse(JSON.stringify(groupEntries.entries));
          if (newEntries[0].for.length === 1) {
            //delete primary entry if only 1 for
            firebase
              .deleteGroupEntries(groupEntries.docId)
              .then(() => {})
              .catch((error) =>
                console.log('Error to delete group entries', error)
              );
          } else {
            // Delete group entry if primary "For" all
            if (
              newEntries[0].for.length > 1 &&
              newEntries[0].for.includes('all')
            ) {
              firebase
                .deleteGroupEntries(groupEntries.docId)
                .then(() => {})
                .catch((error) =>
                  console.log('Error to delete group entries', error)
                );
            } else {
              let filteredLinkedEntry = groupEntries.entries.map((entry) => {
                if (
                  entry.isPrimary === false &&
                  entry.for.length === 1 &&
                  entry.for.includes(oldYear)
                ) {
                  return entry.id;
                }
              });
              filteredLinkedEntry = filteredLinkedEntry.filter(
                (entry) => entry
              );
              if (filteredLinkedEntry && filteredLinkedEntry.length) {
                newEntries = groupEntries.entries.filter(
                  (entry) => !filteredLinkedEntry.includes(entry.id)
                );
              }
              // end - delete link entry if entry is same as current year and for length 1
              // reset for & forDropdownData
              let newGroupEntries = newEntries.map((entry) => {
                if (entry.for.length > 1 && !entry.for.includes('all')) {
                  const filteredFor = entry.for.filter((v) => v !== oldYear);
                  entry.for = filteredFor;
                  let yearDropDownData = entry.yearDropDownData.map((year) => {
                    if (year.value === oldYear) {
                      year.selected = false;
                    }
                    return year;
                  });
                  entry.yearDropDownData = yearDropDownData;
                }
                return entry;
              });

              let finalData = {
                entries: newGroupEntries,
                groupEntryID: groupEntries.groupEntryID,
                groupType: groupEntries.groupType
              };
              firebase
                .updateGroupEntries(finalData, groupEntries.docId)
                .then(() => {})
                .catch((error) => console.log('error', error));
            }
          }
        } else {
          dispatch(addMessage('Something went wrong to get group entries', MESSAGE_TYPES.ERROR));
        }
      })
      .catch((error) => {
        console.log('error', error);
        dispatch(addMessage('Something went wrong to get group entries', MESSAGE_TYPES.ERROR));
      });
  };

  const submitSchoolForm = (formData, mainEntries) => {
    if (formData[0].school && formData[0].for) {
      submitNewSchoolForm(formData, mainEntries);
    }
  };

  const _deleteEntryHandleForRepeat = () => {
    let formData = [];
    const parentRepeatEntryId = hasData[0].parentRepeatEntryId;
    const originEntry = allCalenderData[0].entries.find(
      (entry) => entry.id === parentRepeatEntryId
    );
    //const linkedEntries = allCalenderData[0].entries.find(entry => originEntry.linkedArray.includes(entry.id));
    const familyId = currentFamily.id;
    const deleteDate = hasData[0].from.date;
    if (repeatEntryRadioData === 'This Event') {
      originEntry.repeatDeleted = originEntry.repeatDeleted
        ? [...originEntry.repeatDeleted, deleteDate]
        : [deleteDate];
    } else if (repeatEntryRadioData === 'This and following events') {
      if (originEntry.actions.repeat[0].values) {
        originEntry.actions.repeat[0].values.endsDate = moment(
          hasData[0].from.date,
          'MMM DD,YYYY'
        )
          .subtract(1, 'days')
          .format('MMM DD,YYYY');
        originEntry.actions.repeat[0].values.endsType = 'On';
      } else {
        const entryObject = {
          repeatDay: 1,
          repeatType: 'Day',
          repeatOn: [],
          repeatMonth: { type: 'day', value: moment().format('MMM DD,YYYY') },
          endsType: 'On',
          endsDate: moment(hasData[0].from.date, 'MMM DD,YYYY')
            .subtract(1, 'days')
            .format('MMM DD,YYYY'),
          endsAfter: 1
        };
        originEntry.actions.repeat[0] = { type: 'Custom', values: entryObject };
      }
    } else {
      // Do nothing for all
      return;
    }

    dispatch(loaderStart());

    const finalData = {
      docId: allCalenderData[0].id,
      entry: originEntry
    };
    if (currentFamily.planType === 'Free') {
      firebase
        // .updateSpecificEntrySchool(finalData)
        .deleteSchoolRepeatedEntry(finalData.entry)
        .then((result) => {
          dispatch(loaderStop());
          dispatch(addMessage('Calendar Entry Deleted'));

          setInitialEntry({
            entries: [
              {
                ...entryObject.entries[0],
                id: generateFileHash(20),
                agentName: currentUser.firstName,
                from: {
                  date: moment().format('MMM DD,YYYY'),
                  time: moment().format('h:mma')
                },
                to: {
                  date: moment().format('MMM DD,YYYY'),
                  time: moment().format('h:mma')
                }
              }
            ]
          });
          dispatch(setFamilyOrSchoolPreserveEntryData(null));
          setHasEntryData([]);
          setRightOpen(false);
          setTimeout(() => {
            getEntries(date);
          }, 300)
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
          dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    } else {
      firebase
        // .updateSpecificEntry(finalData)
        .deleteRepeatedEntry(finalData.entry)
        .then((result) => {
          dispatch(loaderStop());
          dispatch(addMessage('Calendar Entry Deleted'));

          setInitialEntry({
            entries: [
              {
                ...entryObject.entries[0],
                id: generateFileHash(20),
                agentName: currentUser.firstName,
                from: {
                  date: moment().format('MMM DD,YYYY'),
                  time: moment().format('h:mma')
                },
                to: {
                  date: moment().format('MMM DD,YYYY'),
                  time: moment().format('h:mma')
                }
              }
            ]
          });
          dispatch(setFamilyOrSchoolPreserveEntryData(null));
          setHasEntryData([]);
          setRightOpen(false);
          setTimeout(() => {
            getEntries(date);
          }, 300)
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
          dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    }
  };

  const compareDataAndUpdate = (entry, hasData, formData) => {
    let newFor = entry.for;
    let assign = entry.assign;
    if (formData[0].for !== hasData[0].for) {
      newFor = formData[0].for;
    } else if (
      Array.isArray(formData[0].for) &&
      Array.isArray(hasData[0].for)
    ) {
      if (!_.isEqual(_.sortBy(formData[0].for), _.sortBy(hasData[0].for))) {
        newFor = formData[0].for;
      } else {
        newFor = hasData[0].for;
      }
    } else {
      newFor = hasData[0].for;
    }

    if (formData[0].assign !== hasData[0].assign) {
      assign = formData[0].assign;
    } else if (
      Array.isArray(formData[0].assign) &&
      Array.isArray(hasData[0].assign)
    ) {
      if (
        !_.isEqual(_.sortBy(formData[0].assign), _.sortBy(hasData[0].assign))
      ) {
        assign = formData[0].assign;
      } else {
        assign = hasData[0].assign;
      }
    } else {
      assign = hasData[0].assign;
    }

    let editedFormData = {
      ...formData[0],
      label:
        hasData[0].label === formData[0].label
          ? entry.label
          : formData[0].label,
      title:
        hasData[0].title === formData[0].title
          ? entry.title
          : formData[0].title,
      location:
        hasData[0].location === formData[0].location
          ? entry.location
          : formData[0].location,
      description:
        hasData[0].description === formData[0].description
          ? entry.description
          : formData[0].description,
      for: newFor,
      assign: assign,
      from: {
        date: entry.from.date,
        time:
          hasData[0].from.time === formData[0].from.time
            ? entry.from.time
            : formData[0].from.time
      },
      to: {
        date: entry.to.date,
        time:
          hasData[0].to.time === formData[0].to.time
            ? entry.to.time
            : formData[0].to.time
      },
      allDay:
        hasData[0].allDay === formData[0].allDay
          ? entry.allDay
          : formData[0].allDay,
      actions: {
        ...formData[0].actions,
        notification: entry.actions.notification
      }
    };
    return editedFormData;
  };

  const submitNewFamilyForm = (formData, mainEntries) => {
    //New time format
    formData.forEach(async (entry, index) => {
      if (entry.docId && entry.isPrimary && entry.bulkID) {
        // Remove existing data entries
        await deleteDataEntry(entry.docId, entry.bulkID);
      } else if (entry.docId && entry.isLinked && entry.bulkID) {
        await deleteDataEntry(entry.docId, entry.bulkID);
      }

      if (entry.allDay) {
        formData[index].from = { ...formData[index].from, time: '12:30am' };
        formData[index].to = { ...formData[index].to, time: '12:30am' };
      } else {
        if (formData[index].newFromTimeFormate) {
          formData[index].newFromTimeFormate = {
            hh: formData[index].newFromTimeFormate.hh,
            mm:
              formData[index].newFromTimeFormate.mm.toString().length === 1
                ? '0' + formData[index].newFromTimeFormate.mm
                : formData[index].newFromTimeFormate.mm,
            ap:
              formData[index].newFromTimeFormate.ap.toLowerCase() === 'a' ||
              formData[index].newFromTimeFormate.ap.toLowerCase() === 'p'
                ? formData[index].newFromTimeFormate.ap.toLowerCase()
                : 'a'
          };
          formData[index].newToTimeFormate = {
            hh: formData[index].newToTimeFormate.hh,
            mm:
              formData[index].newToTimeFormate.mm.toString().length === 1
                ? '0' + formData[index].newToTimeFormate.mm
                : formData[index].newToTimeFormate.mm.toString(),
            ap:
              formData[index].newToTimeFormate.ap.toLowerCase() === 'a' ||
              formData[index].newToTimeFormate.ap.toLowerCase() === 'p'
                ? formData[index].newToTimeFormate.ap.toLowerCase()
                : 'p'
          };

          formData[index].from = {
            date: formData[index].from.date,
            time:
              formData[index].newFromTimeFormate.hh +
              ':' +
              formData[index].newFromTimeFormate.mm +
              formData[index].newFromTimeFormate.ap +
              'm'
          };
          formData[index].to = {
            date: formData[index].to.date,
            time:
              formData[index].newToTimeFormate.hh +
              ':' +
              formData[index].newToTimeFormate.mm +
              formData[index].newToTimeFormate.ap +
              'm'
          };

          if (Array.isArray(formData[index].repeatEntries)) {
            formData[index].repeatEntries[0].from = {
              date: formData[index].repeatEntries[0].from.date,
              time:
                formData[index].repeatEntries[0]?.newFromTimeFormate?.hh +
                ':' +
                formData[index].repeatEntries[0]?.newFromTimeFormate?.mm +
                formData[index].repeatEntries[0]?.newFromTimeFormate?.ap +
                'm'
            };
            formData[index].repeatEntries[0].to = {
              date: formData[index].repeatEntries[0].to.date,
              time:
                formData[index].repeatEntries[0]?.newToTimeFormate?.hh +
                ':' +
                formData[index].repeatEntries[0]?.newToTimeFormate?.mm +
                formData[index].repeatEntries[0]?.newToTimeFormate?.ap +
                'm'
            };
          }
        }
      }
    });
    //New time format end

    const storeCoreEntry =
      formData && formData.length
        ? JSON.parse(JSON.stringify(formData[0]))
        : {};
    const actionDataAppend = [];
    let primary = formData.find((o) => o.isPrimary === true);

    //Repeat Logic start
    let editedFormDate = null;
    if (
      mainEntries &&
      mainEntries.length > 0 &&
      mainEntries[0].actions.repeat.length === 0 &&
      !mainEntries[0].isRepeatEntry
    ) {
      // Delete extra field start
      const deleteFields = [
        'followDates',
        'repeatEntries',
        'repeatDeleted',
        'isRepeatEntry',
        'parentRepeatEntryId',
        'completedDates',
        'isDeleted'
      ];
      deleteFields.map((field) => {
        if (formData[0][field]) delete formData[0][field];
      });
      // Delete extra field end
    } else {
      if (
        mainEntries &&
        mainEntries.length > 0 &&
        (primary.isRepeatEntry ||
          (primary.actions.repeat.length > 0 &&
            primary.actions.repeat[0].type !== 'Does not repeat'))
      ) {
        editedFormDate = formData[0].from.date;
        if (!formData[0].isRepeatEntry && primary.actions.repeat.length > 0) {
          // If entry is origin repeat(primary) entry
          const isEqual = deepEqual(
            mainEntries[0].actions.repeat[0],
            formData[0].actions.repeat[0]
          );
          if (isEqual) {
            if (repeatEntryRadioData === 'This Event') {
              // Change specific data
              let originEntry = allCalenderData[0].entries.find(
                (entry) => entry.id === primary.id
              );

              originEntry.linkedArray = primary.linkedArray;
              if (
                originEntry.repeatEntries &&
                originEntry.repeatEntries.length > 0
              ) {
                let filterepeatEntries = originEntry.repeatEntries.filter(
                  (entry) => entry.from.date !== formData[0].from.date
                );
                delete formData[0].repeatEntries;
                const newformData = JSON.parse(
                  JSON.stringify({
                    ...originEntry,
                    repeatEntries: [...filterepeatEntries, formData[0]]
                  })
                );
                formData[0] = newformData;
              } else {
                delete formData[0].repeatEntries;
                const newformData = JSON.parse(
                  JSON.stringify({
                    ...originEntry,
                    repeatEntries: [formData[0]]
                  })
                );
                formData[0] = newformData;
              }
              // completedDates update logic start
              if (
                !formData[0].actions.isComplete &&
                formData[0].completedDates
              ) {
                let isExistCompleteDate = formData[0].completedDates.indexOf(
                  formData[0].from.date
                );
                if (isExistCompleteDate !== -1) {
                  formData[0].completedDates.splice(isExistCompleteDate, 1);
                }
              }
              // completedDates update logic end
            } else {
              let newRepeatEntry = [];
              let originEntry = allCalenderData[0].entries.find(
                (entry) => entry.id === primary.id
              );
              if (
                originEntry.repeatEntries &&
                originEntry.repeatEntries.length > 0
              ) {
                originEntry.repeatEntries.forEach((entry) => {
                  const newEntry = compareDataAndUpdate(
                    entry,
                    mainEntries,
                    formData
                  );
                  let newEntryJson = JSON.parse(JSON.stringify(newEntry));
                  delete newEntryJson.followDates;
                  delete newEntryJson.repeatEntries;
                  delete newEntryJson.repeatDeleted;
                  newRepeatEntry.push(newEntryJson);
                });
              }
              // completedDates update logic start
              let newCompleteDates = formData[0].completedDates
                ? [...formData[0].completedDates]
                : [];
              newRepeatEntry.forEach((entry) => {
                let isExistCompleteDate = newCompleteDates.indexOf(
                  entry.from.date
                );
                if (entry.actions.isComplete && isExistCompleteDate === -1) {
                  newCompleteDates = [...newCompleteDates, entry.from.date];
                }
                if (!entry.actions.isComplete && isExistCompleteDate !== -1) {
                  newCompleteDates.splice(isExistCompleteDate, 1);
                }
              });
              formData[0].completedDates = newCompleteDates;
              // completedDates update logic end

              formData[0].repeatEntries = newRepeatEntry;
              delete formData[0].followDates;
              // delete formData[0].repeatEntries;
              delete formData[0].repeatDeleted;
            }
          } else {
            // If user changes repeat logic then delete all previous edited instance
            delete formData[0].followDates;
            delete formData[0].repeatEntries;
            delete formData[0].repeatDeleted;
          }
        } else {
          //Find origin entry(virtual entry)
          let originEntry = allCalenderData[0].entries.find(
            (entry) => entry.id === primary.parentRepeatEntryId
          );
          //We dont need repeat logic in vertual entries
          formData[0].actions = { ...formData[0].actions, repeat: [] };

          primary = originEntry;
          //Check for completion
          if (originEntry.completedDates) {
            let newCompletedDates = originEntry.completedDates.filter(
              (date) => date !== formData[0].from.date
            );

            if (formData[0].actions.isComplete) {
              originEntry.completedDates = [
                ...newCompletedDates,
                formData[0].from.date
              ];
            } else {
              originEntry.completedDates = newCompletedDates;
            }
          } else {
            if (formData[0].actions.isComplete) {
              originEntry.completedDates = [formData[0].from.date];
            } else {
              originEntry.completedDates = [];
            }
          }
          //Check for completion end

          const linkedEntries = allCalenderData[0].entries.find((entry) =>
            originEntry.linkedArray
              ? originEntry.linkedArray.includes(entry.id)
              : null
          );
          if (linkedEntries) {
            if (Array.isArray(linkedEntries)) {
              formData = [formData[0], ...linkedEntries];
            } else {
              formData = [formData[0], linkedEntries];
            }
          }

          if (repeatEntryRadioData === 'This Event') {
            // Insert new entry
            if (
              originEntry.repeatEntries &&
              originEntry.repeatEntries.length > 0
            ) {
              let filterepeatEntries = originEntry.repeatEntries.filter(
                (entry) => entry.from.date !== formData[0].from.date
              );
              delete formData[0].repeatEntries;
              //formData =[{...originEntry, repeatEntries: [...filterepeatEntries,formData[0]] }];
              const newformData = JSON.parse(
                JSON.stringify({
                  ...originEntry,
                  repeatEntries: [...filterepeatEntries, formData[0]]
                })
              );
              formData[0] = newformData;
            } else {
              delete formData[0].repeatEntries;
              //formData =[{...originEntry, repeatEntries: [formData[0]] }]
              const newformData = JSON.parse(
                JSON.stringify({ ...originEntry, repeatEntries: [formData[0]] })
              );
              formData[0] = newformData;
            }
          } else if (repeatEntryRadioData === 'This and following events') {
            if (isRepeatChange) {
              let dailyToCustomAction = {
                type: 'Custom',
                values: {
                  endsAfter: 1,
                  endsDate: moment(formData[0].from.date)
                    .add(-1, 'days')
                    .format('MMM DD,YYYY'),
                  endsType: 'On',
                  repeatDay: 1,
                  repeatMonth: { type: 'day', value: originEntry.from.date },
                  repeatOn: [],
                  repeatType: 'Day'
                }
              };
              let newActionsValues = {};
              if (originEntry.actions.repeat[0].type === 'Daily') {
                newActionsValues = dailyToCustomAction;
              } else {
                newActionsValues = {
                  ...originEntry.actions.repeat[0],
                  values: {
                    ...originEntry.actions.repeat[0].values,
                    endsDate: moment(formData[0].from.date)
                      .add(-1, 'days')
                      .format('MMM DD,YYYY'),
                    endsType: 'On'
                  }
                };
              }
              const newformData = JSON.parse(
                JSON.stringify({
                  ...originEntry,
                  actions: {
                    ...originEntry.actions,
                    repeat: [newActionsValues]
                  }
                })
              );
              formData[0] = newformData;
            } else {
              // Insert new entry
              if (
                originEntry.repeatEntries &&
                originEntry.repeatEntries.length > 0
              ) {
                /* forward notification to repeat entries */
                let filterepeatEntries = [];
                let newCompleteDates = [...originEntry.completedDates];
                originEntry.repeatEntries.forEach((entry) => {
                  // if current form entry from date is greater then repeat entry date
                  if (
                    moment(formData[0].from.date, 'MMM DD,YYYY').diff(
                      entry.from.date,
                      'days'
                    ) < 0
                  ) {
                    // completedDates update logic start
                    let isExistCompleteDate = newCompleteDates.indexOf(
                      entry.from.date
                    );
                    if (
                      formData[0].actions.isComplete &&
                      isExistCompleteDate === -1
                    ) {
                      newCompleteDates = [...newCompleteDates, entry.from.date];
                    }
                    if (
                      !formData[0].actions.isComplete &&
                      isExistCompleteDate !== -1
                    ) {
                      newCompleteDates.splice(isExistCompleteDate, 1);
                    }
                    // completedDates update logic end

                    const newEntry = compareDataAndUpdate(
                      entry,
                      mainEntries,
                      formData
                    );
                    let newEntryJson = JSON.parse(JSON.stringify(newEntry));
                    delete newEntryJson.followDates;
                    delete newEntryJson.repeatEntries;
                    delete newEntryJson.repeatDeleted;
                    filterepeatEntries.push(newEntryJson);
                  } else {
                    if (formData[0].from.date === entry.from.date) {
                    } else {
                      filterepeatEntries.push(entry);
                    }
                  }
                });
                // CompletedDates update here
                originEntry.completedDates = newCompleteDates;
                const followDates = originEntry.followDates
                  ? originEntry.followDates.filter(
                      (date) =>
                        moment(formData[0].from.date, 'MMM DD,YYYY').diff(
                          date,
                          'days'
                        ) > 0
                    )
                  : [];
                originEntry.followDates = [
                  ...followDates,
                  formData[0].from.date
                ];
                delete formData[0].repeatEntries;
                const newformData = JSON.parse(
                  JSON.stringify({
                    ...originEntry,
                    repeatEntries: [...filterepeatEntries, formData[0]]
                  })
                );
                formData[0] = newformData;
              } else {
                originEntry.followDates = [formData[0].from.date];
                delete formData[0].repeatEntries;

                const newformData = JSON.parse(
                  JSON.stringify({
                    ...originEntry,
                    repeatEntries: [{ ...formData[0], followAfterThis: true }]
                  })
                );
                formData[0] = newformData;
              }
            }
          } else {
            let filterepeatEntries = [];
            if (
              originEntry.repeatEntries &&
              originEntry.repeatEntries.length > 0
            ) {
              let newCompleteDates = [...originEntry.completedDates];
              originEntry.repeatEntries.forEach((entry) => {
                // completedDates update logic start
                let isExistCompleteDate = newCompleteDates.indexOf(
                  entry.from.date
                );
                if (
                  formData[0].actions.isComplete &&
                  isExistCompleteDate === -1
                ) {
                  newCompleteDates = [...newCompleteDates, entry.from.date];
                }
                if (
                  !formData[0].actions.isComplete &&
                  isExistCompleteDate !== -1
                ) {
                  newCompleteDates.splice(isExistCompleteDate, 1);
                }
                // completedDates update logic end

                // if current form entry from date is greater then repeat entry date
                if (formData[0].from.date !== entry.from.date) {
                  const newEntry = compareDataAndUpdate(
                    entry,
                    mainEntries,
                    formData
                  );
                  let newEntryJson = JSON.parse(JSON.stringify(newEntry));
                  delete newEntryJson.followDates;
                  delete newEntryJson.repeatEntries;
                  delete newEntryJson.repeatDeleted;
                  filterepeatEntries.push(newEntryJson);
                }
              });
              // CompletedDates update here
              originEntry.completedDates = newCompleteDates;
            }
            filterepeatEntries = [...filterepeatEntries, formData[0]];

            // originEntry.repeatEntries = [];
            originEntry.followDates = [];
            primary.repeatEntries = [];
            primary.followDates = [];
            delete formData[0].id;
            delete formData[0].parentRepeatEntryId;
            delete formData[0].followDates;
            // delete formData[0].repeatEntries;
            delete formData[0].repeatDeleted;

            // Change Repeat Options then update main options start
            let newActions = { ...originEntry.actions };
            if (isRepeatChange) {
              newActions = {
                ...originEntry.actions,
                repeat: [...storeCoreEntry.actions.repeat]
              };
            }
            // Change Repeat Options then update main options start

            formData[0] = {
              ...originEntry,
              ...formData[0],

              isDynamic: false,
              isRepeatEntry: false,
              // id: generateFileHash(20),
              // parentRepeatEntryId: entry.id,
              linkedArray: originEntry.linkedArray,
              actions: newActions,
              linkedArray: originEntry.linkedArray,
              from: {
                date: originEntry.from.date,
                time: formData[0].from.time
              },
              to: { date: originEntry.to.date, time: formData[0].to.time },
              repeatEntries: filterepeatEntries
            };
          }
        }
      } else {
        //It is Normal entry, if entry is not repeat entry
        // Delete extra field start
        const deleteFields = [
          'followDates',
          'repeatEntries',
          'repeatDeleted',
          'isRepeatEntry',
          'parentRepeatEntryId',
          'completedDates',
          'isDeleted'
        ];
        deleteFields.map((field) => {
          if (formData[0][field]) delete formData[0][field];
        });
        // Delete extra field end
      }
    }
    //Repeat Logic end
    const groupEntryID = formData[0].groupEntryID
      ? formData[0].groupEntryID
      : null;
    if (
      groupEntryID &&
      formData[0].groupType === 'Families' &&
      mainEntries &&
      mainEntries.length > 0
    ) {
      deleteEntryFromGroup(groupEntryID, currentFamily, mainEntries);
    }
    // bulk ID created for finding entry into the data entries collections
    const bulkID = generateFileHash(20);
    const updateFormData = formData.map((data) => {
      data.bulkID = bulkID;
      if (data.label === '') {
        //data.label = 'none';
      }
      if (data.isPrimary === false) {
        data.isLinked = true;
        // if(groupEntryID){
        //   data.groupEntryID = groupEntryID;
        // }
      } else {
        data.isLinked = false;
      }
      if (data.actions.notification.length) {
        // actionDataAppend.push({
        //   ...data,
        //   id: generateFileHash(20),
        //   actionParent: {
        //     parentID: primary.id,
        //     linkedId: data.isPrimary ? 0 : data.id,
        //     type: 'Notification'
        //   },
        //   from: {},
        //   to: {},
        //   allDay: true
        // });
      }
      if (data.actions.reminder.length) {
        data.actions.reminder.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Reminder',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            primaryID: primary.id,
            isLinked: false,
            actions: {
              ...data.actions,
              notification:
                data.actions.reminderNotification &&
                data.actions.reminderNotification[index]
                  ? [data.actions.reminderNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.actions.deadline.length) {
        data.actions.deadline.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Deadline',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            primaryID: primary.id,
            isLinked: false,
            actions: {
              ...data.actions,
              notification:
                data.actions.deadlineNotification &&
                data.actions.deadlineNotification[index]
                  ? [data.actions.deadlineNotification[index]]
                  : []
            }
          });
        });
      }
      if (groupEntryID) {
        delete data.groupEntryID;
      }
      delete data.school;
      return data;
    });
    const final = [...updateFormData, ...actionDataAppend];
    const finalData = {
      familyId: currentFamily.id,
      entries: final,
      docId: allCalenderData.length === 0 ? 'newId' : allCalenderData[0].id,
      parentID: primary.id,
      isUpdate: mainEntries && mainEntries.length > 0 ? true : false,
      editedFormDate: editedFormDate
    };
    finalData.entries.map(async (entry) => {
      addDataEntry(
        { ...entry, familyId: finalData.familyId },
        finalData,
        hasData
      );
    });
    // addEntry(finalData);
  };

  // below function use behalf of "_deleteEntryHandle"
  const deleteAllEntries = async () => {
    const primary = hasData.find((o) => o.isPrimary === true);
    if (primary.docId && primary.isPrimary && primary.bulkID) {
      if (currentFamily.planType === 'Free') {
        // Remove existing data entries
        await deleteSchoolDataEntry(primary.docId, primary.bulkID).then(
          (res) => {
            dispatch(loaderStop());
            dispatch(addMessage('Calendar Entry Deleted'));
            setInitialEntry({
              entries: [
                {
                  ...entryObject.entries[0],
                  id: generateFileHash(20),
                  agentName: currentUser.firstName,
                  from: {
                    date: moment().format('MMM DD,YYYY'),
                    time: moment().format('h:mma')
                  },
                  to: {
                    date: moment().format('MMM DD,YYYY'),
                    time: moment().format('h:mma')
                  }
                }
              ]
            });
            dispatch(setFamilyOrSchoolPreserveEntryData(null));
            setHasEntryData([]);
            setRightOpen(false);
            setTimeout(() => {
              getEntries(date)
            }, 300);
          }
        );
      } else {
        // Remove existing data entries
        await deleteDataEntry(primary.docId, primary.bulkID).then((res) => {
          dispatch(loaderStop());
          dispatch(addMessage('Calendar Entry Deleted'));
          setInitialEntry({
            entries: [
              {
                ...entryObject.entries[0],
                id: generateFileHash(20),
                agentName: currentUser.firstName,
                from: {
                  date: moment().format('MMM DD,YYYY'),
                  time: moment().format('h:mma')
                },
                to: {
                  date: moment().format('MMM DD,YYYY'),
                  time: moment().format('h:mma')
                }
              }
            ]
          });
          dispatch(setFamilyOrSchoolPreserveEntryData(null));
          setHasEntryData([]);
          setRightOpen(false);
          setTimeout(() => {
            getEntries(date)
          }, 300)
        });
      }
    }
  };

  const deleteEntryFromGroup = (groupEntryID, currentFamily, hasData) => {
    const selectedForPrimary = hasData[0].for;
    let selectedForLinked = hasData.map((item) => {
      if (!item.isPrimary) {
        return { for: item.for, title: item.title };
      }
    });
    selectedForLinked = selectedForLinked.filter((item) => item);
    const childArray = currentFamily.childs.map((child) => {
      return child.id;
    });
    const parentArray = currentFamily.parents.map((parent) => {
      return parent.id;
    });
    firebase
      .getGroupEntries(groupEntryID)
      .then((groupEntries) => {
        if (
          groupEntries &&
          groupEntries.entries &&
          groupEntries.entries.length
        ) {
          let newEntries = JSON.parse(JSON.stringify(groupEntries.entries));

          // Get current family member ids start
          let currentFamilyMemberIds = [];
          if (currentFamily.parents.length)
            currentFamily.parents.forEach((item) =>
              currentFamilyMemberIds.push(item.id)
            );
          if (currentFamily.childs.length)
            currentFamily.childs.forEach((item) =>
              currentFamilyMemberIds.push(item.id)
            );
          // Get current family member ids end

          if (newEntries[0].for.length === 1) {
            //delete primary entry if only 1 for
            firebase
              .deleteGroupEntries(groupEntries.docId)
              .then(() => {})
              .catch((error) =>
                console.log('Error to delete group entries', error)
              );
          } else {
            // start - delete link entry if entry is 1 "For" for a current family
            let filteredLinkedEntry = groupEntries.entries.map((entry) => {
              if (
                entry.isPrimary === false &&
                entry.for.length === 1 &&
                currentFamilyMemberIds.includes(entry.for[0])
              ) {
                return entry.id;
              }
            });
            // filteredLinkedEntry = filteredLinkedEntry.filter(entry => entry);
            if (filteredLinkedEntry && filteredLinkedEntry.length) {
              newEntries = groupEntries.entries.filter(
                (entry) => !filteredLinkedEntry.includes(entry.id)
              );
            }
            // end - delete link entry if entry is 1 "For"
            // reset for & forDropdownData
            let newGroupEntries = newEntries.map((entry) => {
              if (entry.isPrimary) {
                if (selectedForPrimary === 'Parents') {
                  entry.for = entry.for.filter((v) => !parentArray.includes(v));
                } else {
                  entry.for = entry.for.filter((v) => v !== selectedForPrimary);
                }
                let filteredForDropdown = entry.forDropDownData.map((item) => {
                  if (
                    selectedForPrimary === 'Parents' &&
                    item.familyId &&
                    item.familyId === currentFamily.id
                  ) {
                    item.selected = false;
                  } else if (item.id === selectedForPrimary) {
                    item.selected = false;
                  }
                  return item;
                });
                entry.forDropDownData = filteredForDropdown;
              } else {
                // more than one child in primary entry and selected parents as linked entry
                const selectedFor = selectedForLinked.find(
                  (item) => item.title === entry.title
                );
                if (selectedFor && selectedFor.for.includes('Parents')) {
                  const multiChild = groupEntries.entries[0].for.filter((v) =>
                    childArray.includes(v)
                  );
                  if (multiChild.length > 1) {
                  } else {
                    entry.for = entry.for.filter(
                      (v) => !parentArray.includes(v)
                    );
                    let filteredForDropdown = entry.forDropDownData.map(
                      (item) => {
                        if (
                          item.familyId &&
                          item.familyId === currentFamily.id
                        ) {
                          item.selected = false;
                        }
                        return item;
                      }
                    );
                    entry.forDropDownData = filteredForDropdown;
                  }
                } else {
                  let removedChild = entry.for.filter(
                    (v) => !childArray.includes(v)
                  );
                  let filteredFor = removedChild.filter(
                    (v) => !parentArray.includes(v)
                  );
                  entry.for = filteredFor;
                  let filteredForDropdown = entry.forDropDownData.map(
                    (item) => {
                      if (item.familyId && item.familyId === currentFamily.id) {
                        item.selected = false;
                      }
                      return item;
                    }
                  );
                  entry.forDropDownData = filteredForDropdown;
                }
              }
              return entry;
            });

            let finalData = {
              entries: newGroupEntries,
              groupEntryID: groupEntries.groupEntryID,
              groupType: groupEntries.groupType
            };
            firebase
              .updateGroupEntries(finalData, groupEntries.docId)
              .then(() => {})
              .catch((error) => console.log('error', error));
          }
        } else {
          dispatch(addMessage('Something went wrong to get group entries', MESSAGE_TYPES.ERROR));
        }
      })
      .catch((error) => {
        console.log('error', error);
        dispatch(addMessage('Something went wrong to get group entries', MESSAGE_TYPES.ERROR));
      });
  };

  // Below function using behalf of "addEntry"
  const addDataEntry = (finalData, fullData, hasData) => {
    dispatch(loaderStart());
    firebase
      .addDataEntries(finalData)
      .then(
        (result) => {
          const primary = finalData.isPrimary === true ? finalData : {};
          const updatedEntry = finalData?.repeatEntries?.length
            ? finalData?.repeatEntries[finalData?.repeatEntries?.length - 1]
            : null;
          if (primary) {
            if (
              (finalData.label === 'Alert' &&
                hasData?.[0]?.label !== 'Alert') ||
              (updatedEntry?.label === 'Alert' &&
                hasData?.[0]?.label !== 'Alert')
            ) {
              const requestOptions = {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  familyId: finalData.familyId,
                  repeatEntryId: finalData?.repeatEntries?.length
                    ? updatedEntry?.id
                    : null,
                  entryId: result.id,
                  isFree: currentFamily.planType === 'Free' ? true : false
                })
              };
              fetch(
                `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/sendInstantPN`,
                requestOptions
              )
                .then((response) => {
                  const result = response.json();
                  if (!response.ok) {
                    throw new Error(result?.message);
                  }
                  return result
                })
                .then((data) => console.log('.'));
            }

            dispatch(addMessage('Calendar Entry Created'));
            dispatch(loaderStop());
            setInitialEntry({
              entries: [
                {
                  ...entryObject.entries[0],
                  id: generateFileHash(20),
                  agentName: currentUser.firstName,
                  from: {
                    date: moment().format('MMM DD,YYYY'),
                    time: moment().format('h:mma')
                  },
                  to: {
                    date: moment().format('MMM DD,YYYY'),
                    time: moment().format('h:mma')
                  }
                }
              ]
            });
            dispatch(setFamilyOrSchoolPreserveEntryData(null));
            setHasEntryData([]);
            setRightOpen(false);
            if (
              (primary.from && primary.from.date) ||
              fullData.editedFormDate
            ) {
              getEntries(
                moment(
                  fullData.editedFormDate || primary.from.date,
                  'MMM DD,YYYY'
                )
              );
            }
          }
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Entry was not created. Please try again.', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Entry was not created. Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  };

  // Delete entry from dataEntries collection
  const deleteDataEntry = async (docId, id) => {
    await firebase.deleteDataEntries(docId, id);
  };

  // Below function using behalf of "addSchoolEntry"
  const addSchoolDataEntry = async(finalData) => {
    dispatch(loaderStart());
    manageSchoolEntries(finalData)

      .then(
        async () => {
          let editedFormDate = null;
          await finalData.forEach(entryItem => {
            const updatedEntry = entryItem?.repeatEntries?.length
              ? entryItem?.repeatEntries[entryItem?.repeatEntries?.length - 1]
              : null;
            const primary = entryItem.isPrimary === true ? entryItem : false;
            if (primary) {
              editedFormDate = primary?.from?.date || null;
              if (
                (entryItem.label === 'Alert' &&
                  hasData?.[0]?.label !== 'Alert') ||
                (updatedEntry?.label === 'Alert' &&
                  hasData?.[0]?.label !== 'Alert')
              ) {
                const requestOptions = {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    schoolId: entryItem.schoolId,
                    entryId: entryItem.id,
                    repeatEntryId: entryItem?.repeatEntries?.length
                      ? updatedEntry.id
                      : null,
                    isFree: currentFamily.planType === 'Free' ? true : false
                  })
                };
                fetch(
                  `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/sendInstantPN`,
                  requestOptions
                )
                  .then((response) => {
                    const result = response.json();
                    if (!response.ok) {
                      throw new Error(result?.message);
                    }
                    return result
                  })
                  .then((data) => console.log('.'));
              }
            }
          });

          dispatch(addMessage('School Entry Created'));
          dispatch(loaderStop());
          setInitialEntry({
            entries: [
              {
                ...entryObject.entries[0],
                id: generateFileHash(20),
                agentName: currentUser.firstName,
                from: {
                  date: moment().format('MMM DD,YYYY'),
                  time: moment().format('h:mma')
                },
                to: {
                  date: moment().format('MMM DD,YYYY'),
                  time: moment().format('h:mma')
                }
              }
            ]
          });
          dispatch(setFamilyOrSchoolPreserveEntryData(null));
          setHasEntryData([]);
          setRightOpen(false);
          if ( editedFormDate ) {
            getEntries(
              moment(
                editedFormDate,
                'MMM DD,YYYY'
              )
            );
          }
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Entry was not created. Please try again.', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Entry was not created. Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  };

  const onSchoolNameChange = (value, schoolsArray) => {
    const freeCalendarsArray =
      schoolsArray.length &&
      schoolsArray.filter((school) => school.id === value);
    if (freeCalendarsArray.length) {
      setFreeCalendarsOptions(freeCalendarsArray[0].years);
    }
  };

  const handleForYearChange = (
    event,
    selectedIndex,
    entries,
    setFieldValue
  ) => {
    const oldAllDropdownSelection = JSON.parse(
      JSON.stringify(groupYears?.find((e) => e.value === 'all'))
    );
    let selectValue = event.target.value;
    const allId = _.map(groupYears, (year) => year.value);

    if (
      selectValue[selectValue.length - 1] === 'all' &&
      selectValue.includes('all')
    ) {
      selectValue = allId;
      setFieldValue(`entries[${selectedIndex}].forYears`, selectValue);
      const updatedYears = groupYears.map((yearData) => {
        return { ...yearData, selected: true };
      });
      setGroupYears(updatedYears);
    } else if (
      oldAllDropdownSelection?.selected &&
      !selectValue.includes('all')
    ) {
      selectValue = [];
      setFieldValue(`entries[${selectedIndex}].forYears`, selectValue);
      const updatedYears = groupYears.map((yearData) => {
        return { ...yearData, selected: false };
      });
      setGroupYears(updatedYears);
    } else {
      const removeAllIndex = _.findIndex(selectValue, (id) => id === 'all');
      if (removeAllIndex > -1) {
        selectValue.splice(removeAllIndex, 1);
      }

      const updatedYears = groupYears.map((yearData) => {
        if (selectValue.includes(yearData.value)) {
          return { ...yearData, selected: true };
        } else {
          return { ...yearData, selected: false };
        }
      });
      setGroupYears(updatedYears);
      setFieldValue(`entries[${selectedIndex}].forYears`, selectValue);
    }
    // If for change for primary entry then change its relevant linked entries value
    if (selectedIndex === 0) {
      entries.map((entry, index) => {
        if (index !== selectedIndex) {
          setFieldValue(`entries[${index}].forYears`, selectValue);
        }
      });
    }
  };

  const handleChangeFreeYear = (e, index, entries, setFieldValue) => {
    // const selectedYear = e.target.value;
    if (index === 0) {
      if (entries.length > 0) {
        entries.map((item, index) => {
          if (index === 0) {
          } else {
            setFieldValue(`entries[${index}].for`, '');
          }
        });
      }
    }
  };

  const handleEditForWholeGroup = () => {
    setEditForWholeGroup(!editForWholeGroup);
  };

  const refreshScreen = () => {
    setTimeout(() => {
      getEntries(date);
    }, 300)
    setRightOpen(false);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialEntry}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const updatedVal = [];
          let primaryDesc = '';
          values.entries.map((entry, index) => {
            if (entry.primaryEventTo) {
              delete entry.primaryEventTo;
            }
            if (entry.primaryEventFrom) {
              delete entry.primaryEventFrom;
            }
            if (entry.customType) {
              delete entry.customType;
            }
            //selection logic start
            const newNotifications = [];
            if (entry.actions.notification.length) {
              const formateValue = 'MMM DD,YYYY';
              entry.actions.notification.forEach((item) => {
                const fromDate = moment(entry.from.date, formateValue);
                const notificationDate = moment(
                  item.date ? item.date : entry.from.date,
                  formateValue
                );
                let dayValue = '';
                if (moment(notificationDate).isSame(fromDate)) {
                  dayValue = '';
                } else {
                  dayValue = notificationDate.diff(fromDate, 'days');
                }
                newNotifications.push({
                  ...item,
                  days: dayValue,
                  date: moment(item.date).format(formateValue)
                });
              });
            }

            // remove deadline and reminder logic start
            if (entry.actions.isComplete) {
              // entry.actions.deadline = []
              // entry.actions.reminder = []
              if (entry.actions.deadlineNotification)
                delete entry.actions.deadlineNotification;
              if (entry.actions.reminderNotification)
                delete entry.actions.reminderNotification;
            }
            // remove deadline and reminder logic end
            // linked entry description logic start
            if (entry.description && index === 0) {
              primaryDesc = toGetHTMLContent(entry.description).replaceAll('</a>&nbsp;<', '</a><')
            }
            // linked entry description logic end
            const newEntry = JSON.parse(JSON.stringify(entry));
            const newEntries = {
              ...newEntry,
              from: {
                date: entry.from.date,
                time:
                  entry.newFromTimeFormate.hh +
                  ':' +
                  entry.newFromTimeFormate.mm +
                  entry.newFromTimeFormate.ap +
                  'm'
              },
              to: {
                date: entry.to.date,
                time:
                  entry.newToTimeFormate.hh +
                  ':' +
                  entry.newToTimeFormate.mm +
                  entry.newToTimeFormate.ap +
                  'm'
              },
              actions: {
                ...entry.actions,
                notification: newNotifications,
                repeat: entry.actions.repeat
                // repeat: entry.isRepeatEntry ? [] : entry.actions.repeat
              },
              agentName: currentUser.firstName,
              expand: index === 0 ? true : false,
              primary_description:
                index !== 0
                  ? {
                      description: primaryDesc ? primaryDesc : '',
                      from: values.entries[0].from
                    }
                  : {},
              primaryID: index !== 0 ? values.entries[0].id : '',
              createdAt: moment().format(),
              description: entry.description
                ? toGetHTMLContent(entry.description).replaceAll('</a>&nbsp;<', '</a><')
                : ''
            };
            //selection logic end
            updatedVal.push(JSON.parse(JSON.stringify(newEntries)));
          });

          const updatedValues = JSON.parse(JSON.stringify(updatedVal));
          let isEqual = true;
          if (
            hasData &&
            hasData.length > 0 &&
            hasData[0].actions.repeat.length > 0 &&
            updatedValues[0].actions.repeat.length > 0
          ) {
            isEqual = deepEqual(
              hasData[0].actions.repeat[0],
              updatedValues[0].actions.repeat[0]
            );
          }
          if (
            updatedValues[0].actions.repeat.length > 0 &&
            updatedValues[0].actions.repeat[0].type === 'Does not repeat'
          ) {
            isEqual = false;
          }

          if (isRepeatChange && !isRepeatEntryModal) {
            setRepeatEntryRadioData('This and following events');
          }

          if (isEqual || isRepeatChange) {
            if (
              hasData &&
              hasData.length > 0 &&
              hasData[0].actions.repeat.length === 0 &&
              !hasData[0].isRepeatEntry
            ) {
              submitForm(updatedValues);
            } else if (
              hasData &&
              hasData.length &&
              updatedValues[0].actions.repeat.length > 0 &&
              !isRepeatEntryModal
            ) {
              setRepeatEntryModal(true);
            } else if (
              hasData &&
              hasData.length &&
              updatedValues[0].isRepeatEntry &&
              !isRepeatEntryModal
            ) {
              setRepeatEntryModal(true);
            } else {
              if (isRepeatEntryModal) {
                setRepeatEntryModal(false);
              }
              submitForm(updatedValues);
            }
          } else {
            submitForm(updatedValues);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue
        }) => {
          setUpdatedEntry(values);

          // Below code for hide "All Event" option for repeated entries
          // Because when repeated entry update then do not update past entries from recurring entries
          const showAllEventOption =
            values.entries &&
            values.entries.length > 0 &&
            values.entries[0].from.date === values.entries[0].originalEntryDate
              ? true
              : false;
          let hideForRepeat = false;
          let isRepeatDelete = false;
          if (
            values.entries &&
            values.entries[0].isDynamic &&
            values.entries[0].isRepeatEntry
          ) {
            hideForRepeat = true;
            isRepeatDelete = true;
          }

          if (values.entries && values.entries[0].isDeleted) {
            let entryDeleted = {};
            if (Array.isArray(values.entries[0].isDeleted)) {
              let entryDateExist = values.entries[0].isDeleted.find(
                (item) => item.date === values.entries[0].from.date
              );
              if (entryDateExist !== undefined) entryDeleted = entryDateExist;
            } else if (values.entries[0].isDeleted !== undefined) {
              if (
                values.entries[0].isDeleted.date === values.entries[0].from.date
              ) {
                entryDeleted = values.entries[0].isDeleted;
              }
            }
            if (Object.keys(entryDeleted).length) {
              entryDeleted.deletedAt = moment(entryDeleted.deletedAt).format(
                'DD MMM YYYY'
              );
              setIsPrimaryDeleted(entryDeleted);
            }
          }

          return (
            <>
              {
                <Backdrop className={classes.backdrop} open={isLoading}>
                  <CircularProgress color="primary" />
                </Backdrop>
              }
              <div>
                {hasData &&
                hasData[0] &&
                values.entries &&
                values.entries.length &&
                values.entries[0].groupEntryID &&
                values.entries[0].groupType &&
                values.entries[0].groupType !== 'Free' &&
                !values.entries[0].isRepeatEntry ? (
                  <FormControl
                    variant="outlined"
                    className={classes.formLabelControl}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={editForWholeGroup}
                          classes={{
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked
                          }}
                          onChange={handleEditForWholeGroup}
                          name="editForWholeGroup"
                        />
                      }
                      style={{
                        marginLeft: 10
                      }}
                      label="Edit for whole group"
                    />
                  </FormControl>
                ) : (
                  <></>
                )}
              </div>
              {editForWholeGroup ? (
                <GroupEditSection
                  isOpen={editForWholeGroup}
                  type={values.entries[0].groupType}
                  editEntryId={
                    (values.entries[0] && values.entries[0].groupEntryID) ||
                    null
                  }
                  closeRightDrawer={refreshScreen}
                />
              ) : (
                <>
                  <form noValidate onSubmit={handleSubmit}>
                    <div className={classes.headerContainer}>
                      <div className={classes.headerTitleContainer}>
                        <FamiliesIcon
                          color={colors.primaryDarkGrey}
                          className={classes.familyIconStyle}
                        />
                        <Typography variant="h6" className={classes.titleText}>
                          {currentFamily.familySurname}
                        </Typography>
                        <span className={classes.initialText}>
                          ({getInitials()})
                        </span>
                      </div>
                      <div className={classes.headerButtonContainer}>
                        <Button
                          className={classes.deleteButton}
                          onClick={() => {
                            if (isRepeatDelete) {
                              setRepeatEntryModal('delete');
                            } else {
                              setOpenDeleteModal(true);
                            }
                          }}
                        >
                          Delete
                        </Button>
                        {!isKeysDeleted.includes(0) && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            className={classes.publishButton}
                          >
                            Publish
                          </Button>
                        )}
                      </div>
                    </div>
                    <div style={{ position: 'relative' }}>
                      {isKeysDeleted.includes(0) && (
                        <Box
                          borderRadius="borderRadius"
                          fontWeight={500}
                          bgcolor={colors.tertiaryDeleteRed}
                          color={'#fff'}
                          className={classes.deletedBadge}
                        >
                          <span>
                            Deleted by member on{' '}
                            {isPrimaryDeleted ? isPrimaryDeleted.deletedAt : ''}
                          </span>
                        </Box>
                      )}
                      {values.entries.length &&
                      values.entries[0] &&
                      values.entries[0].actions &&
                      (values.entries[0].actions.isCancelled ||
                        values.entries[0].actions.isPostponed ||
                        values.entries[0].actions.isChanged) ? (
                        <Chip
                          size="small"
                          label={
                            <span style={{ fontSize: 12 }}>
                              {values.entries[0].actions.isPostponed
                                ? 'POSTPONED'
                                : values.entries[0].actions.isCancelled
                                ? 'CANCELLED'
                                : 'UPDATED'}
                            </span>
                          }
                          classes={{
                            labelSmall: classes.chipLabel,
                            root: classes.chipRoot
                          }}
                          style={{
                            marginRight: 10,
                            backgroundColor:
                              values.entries[0].actions.isCancelled ||
                              values.entries[0].actions.isPostponed
                                ? colors.tertiaryRed
                                : colors.primaryDarkGrey
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <Divider variant="fullWidth" />
                    <div className={classes.drawerLeftContent}>
                      <div className={classes.leftContentMargin}>
                        {values.entries.map((entry, index) => (
                          <Accordion
                            style={{ margin: 10, boxShadow: 'none' }}
                            key={`expand${index}`}
                            expanded={
                              values.entries.length === 1 ? true : entry.expand
                            }
                            onChange={() =>
                              values.entries.length > 1 &&
                              handleExpandChange(index, values)
                            }
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon className={classes.whiteText} />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{
                                backgroundColor:
                                  values.entries.length !== 1
                                    ? entry.expand && index !== 0
                                      ? colors.primaryDarkGrey7
                                      : colors.primaryDarkGrey
                                    : null,
                                height:
                                  values.entries.length !== 1
                                    ? entry.expand
                                      ? 56
                                      : 60
                                    : null
                              }}
                            >
                              {index === 0 ? (
                                <Typography
                                  className={clsx(
                                    classes.titleAccordionText,
                                    values.entries.length > 1
                                      ? classes.whiteText
                                      : classes.darkPrimaryText
                                  )}
                                >
                                  Primary Entry
                                </Typography>
                              ) : (
                                <>
                                  <CornerDownRight
                                    className={classes.whiteText}
                                    style={{ marginRight: 10, marginTop: 5 }}
                                  />
                                  <Typography
                                    className={clsx(
                                      classes.titleAccordionText,
                                      classes.whiteText
                                    )}
                                    style={{
                                      fontSize: 18,
                                      lineHeight: 2.2,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Linked Entry {index}
                                  </Typography>
                                  <Typography
                                    style={{
                                      marginLeft: 'auto',
                                      marginTop: 10
                                    }}
                                  >
                                    {isKeysDeleted.includes(index) ? (
                                      <span
                                        className={classes.linkDeletedBadge}
                                      >
                                        Deleted By Member
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </Typography>
                                </>
                              )}
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                backgroundColor:
                                  values.entries.length > 1
                                    ? colors.secondaryGreyLight
                                    : ''
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                <div
                                  style={{
                                    flexDirection: 'row',
                                    display: 'flex'
                                  }}
                                >
                                  <FormControl
                                    variant="outlined"
                                    className={classes.formLabelControl}
                                    // style={{ height: 40 }}
                                  >
                                    <InputLabel id="select-outlined-label">
                                      Label
                                    </InputLabel>
                                    <Select
                                      labelId="select-outlined-label"
                                      name={`entries[${index}].label`}
                                      onBlur={handleBlur}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `entries[${index}].label`,
                                          e.target.value
                                        );
                                      }}
                                      value={
                                        (values.entries[index] &&
                                          values.entries[index].label) ||
                                        'none'
                                      }
                                      label="Label"
                                      error={Boolean(
                                        touched.entries &&
                                          errors.entries &&
                                          touched.entries[index] &&
                                          touched.entries[index].label &&
                                          errors.entries[index] &&
                                          errors.entries[index].label
                                      )}
                                      disabled={isKeysDeleted.includes(index)}
                                    >
                                      {labels.map((label, index) => (
                                        <MenuItem
                                          key={`${label.value}-${index}`}
                                          value={label.value}
                                        >
                                          {label.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          values.entries[index].isLowPriority
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `entries[${index}].isLowPriority`,
                                            e.target.checked
                                          );
                                        }}
                                        classes={{
                                          switchBase: classes.switchBase,
                                          thumb: classes.thumb,
                                          track: classes.track,
                                          checked: classes.checked
                                        }}
                                      />
                                    }
                                    label="Low Priority"
                                    disabled={isKeysDeleted.includes(index)}
                                    style={{
                                      width: '56%',
                                      marginTop: 13,
                                      display: 'flex',
                                      marginLeft: 10,
                                      marginTop: 5
                                    }}
                                  />
                                  {taskLabels.includes(
                                    values.entries[index].label
                                  ) && (
                                    <div
                                      style={{
                                        width: '56%',
                                        marginTop: 13,
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                      }}
                                    >
                                      <Checkbox
                                        color="primary"
                                        className={classes.iconButton}
                                        checked={
                                          (values.entries[index] &&
                                            values.entries[index].actions
                                              .isComplete) ||
                                          false
                                        }
                                        name={`entries[${index}].actions.isComplete`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `entries[${index}].actions.isComplete`,
                                            e.target.checked
                                          );
                                        }}
                                        disabled={isKeysDeleted.includes(index)}
                                        // onChange={handleChange}
                                      />
                                    </div>
                                  )}
                                </div>
                                <FormControl component="fieldset">
                                  <TextField
                                    label="Add Title"
                                    variant="outlined"
                                    className={classes.inputControlBox}
                                    name={`entries[${index}].title`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].title) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index].title &&
                                        errors.entries[index] &&
                                        errors.entries[index].title
                                    )}
                                    disabled={isKeysDeleted.includes(index)}
                                  />
                                </FormControl>
                                {/* {index === 0 && ( */}
                                <FormControl component="fieldset">
                                  <TextField
                                    label="Add location"
                                    placeholder="Add Location"
                                    variant="outlined"
                                    className={classes.inputControlBox}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <img
                                            src={LocationIcon}
                                            style={{ opacity: 0.7 }}
                                          />
                                        </InputAdornment>
                                      )
                                    }}
                                    name={`entries[${index}].location`}
                                    onBlur={(e) => {
                                      setFieldValue(
                                        `entries[${index}].location`,
                                        e.target.value
                                          .trim()
                                          .split(/ +/)
                                          .join(' ')
                                      );
                                      handleBlur(e);
                                    }}
                                    onChange={handleChange}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].location) ||
                                      ''
                                    }
                                    disabled={isKeysDeleted.includes(index)}
                                  />
                                </FormControl>
                                {/* )} */}
                                <FormControl component="fieldset">
                                <EditorComponent
                                    classes={classes}
                                    preserveData={values.entries[index]?.description}
                                    wrapperClassName={clsx(
                                      values.entries[index] &&
                                        values.entries[index].checkDescription
                                        ? classes.wrapperClassName
                                        : classes.wrapperClassNameWithBottomBorder
                                    )}
                                    index={index}
                                    onChange={(e) => {
                                      if (!isKeysDeleted.includes(index)) {
                                        onEditorChange(e, index, values);
                                      }
                                    }}
                                    isReadOnly={isKeysDeleted.includes(index)}

                                  />
                                  {Boolean(
                                    touched.entries &&
                                      errors.entries &&
                                      touched.entries[index] &&
                                      touched.entries[index].description &&
                                      errors.entries[index] &&
                                      errors.entries[index].description
                                  ) && (
                                    <FormHelperText
                                      style={{ color: colors.error }}
                                    >
                                      {errors.entries[index].description}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                                {values.entries[index] &&
                                values.entries[index].checkDescription ? (
                                  <>
                                    <div
                                      className={classes.descriptionContainer}
                                    >
                                      <div
                                        className={
                                          classes.descriptionMainContainer
                                        }
                                      >
                                        <img
                                          src={EditPencil}
                                          alt=""
                                          className={
                                            classes.descriptionImageContainer
                                          }
                                        />
                                        <p
                                          className={
                                            classes.descriptionTextContainer
                                          }
                                        >
                                          Created on{' '}
                                          {values.entries[index].createdAt
                                            ? moment(
                                                values.entries[index].createdAt
                                              ).format('DD MMM YYYY')
                                            : moment().format(
                                                'DD MMM YYYY'
                                              )}{' '}
                                          by {values.entries[index].agentName}
                                        </p>
                                      </div>
                                      <div>
                                        <hr
                                          style={{
                                            margin: 0,
                                            padding: 0,
                                            borderTop: `dotted 3px ${colors.primaryLightGrey}`,
                                            width: 570
                                          }}
                                        />
                                      </div>
                                      <div
                                        className={
                                          classes.descriptionMainContainer
                                        }
                                      >
                                        <img
                                          src={Calendar}
                                          alt=""
                                          className={
                                            classes.descriptionImageContainer
                                          }
                                        />
                                        <p
                                          className={
                                            classes.descriptionTextContainer
                                          }
                                        >
                                          Relates to:{' '}
                                          {values.entries[0].from.date
                                            ? moment(
                                                values.entries[0].from.date
                                              ).format('DD MMM YYYY')
                                            : moment().format(
                                                'DD MMM YYYY'
                                              )}{' '}
                                          entry
                                        </p>
                                      </div>
                                    </div>
                                    <Editor
                                      readOnly={true}
                                      editorState={defaultNote}
                                      toolbarClassName={
                                        classes.toolbarClassName
                                      }
                                      editorClassName={classes.editorClassName}
                                      wrapperClassName={
                                        classes.wrapperSecondEditorClassName
                                      }
                                      toolbar={{
                                        options: [],
                                        inline: {
                                          options: []
                                        }
                                      }}
                                      // editorState={}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {values.entries[index] &&
                                values.entries[index].isPrimary ? (
                                  <>
                                    <div
                                      className={classes.descriptionContainer}
                                      style={{
                                        borderBottomLeftRadius: 5,
                                        borderBottomRightRadius: 5,
                                        borderBottom: `1px solid ${colors.primaryLightGrey}`
                                      }}
                                    >
                                      <div
                                        className={
                                          classes.descriptionMainContainer
                                        }
                                      >
                                        <img
                                          src={EditPencil}
                                          alt=""
                                          className={
                                            classes.descriptionImageContainer
                                          }
                                        />
                                        <p
                                          className={
                                            classes.descriptionTextContainer
                                          }
                                        >
                                          Created on{' '}
                                          {values.entries[index].createdAt
                                            ? moment(
                                                values.entries[index].createdAt
                                              ).format('DD MMM YYYY')
                                            : moment().format(
                                                'DD MMM YYYY'
                                              )}{' '}
                                          by {values.entries[index].agentName}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {index !== 0 ? (
                                  <div
                                    style={{
                                      marginTop: 20,
                                      marginLeft: 10,
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            (values.entries[index] &&
                                              values.entries[index]
                                                .checkDescription) ||
                                            false
                                          }
                                          onChange={(e) =>
                                            handleChangeCheck(
                                              'checkDescription',
                                              index,
                                              values
                                            )
                                          }
                                          name={`entries[${index}].checkDescription`}
                                        />
                                      }
                                      label="Include main description"
                                      className={classes.includeText}
                                    />
                                    <DeleteOutlineOutlinedIcon
                                      onClick={() =>
                                        setOpenDeleteLinkedModal({
                                          open: true,
                                          id: index
                                        })
                                      }
                                      className={classes.iconTrashStyle}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>
                      <div>
                        <Button
                          className={classes.linkedButton}
                          startIcon={<AddIcon color={colors.primaryDarkGrey} />}
                          variant="contained"
                          onClick={() => addLinkedEntry(values)}
                          color={colors.primaryDarkGrey}
                          disabled={isKeysDeleted.includes(0) || hideForRepeat}
                        >
                          Add Linked Entry
                        </Button>
                      </div>
                    </div>
                    <div className={classes.drawerRightContent}>
                      <Divider
                        variant="fullWidth"
                        orientation="vertical"
                        style={{ float: 'right' }}
                      />
                      {values.entries.map((entry, index) => (
                        <Fragment key={`right-content-${index}`}>
                          {values.entries.length === 1 || entry.expand ? (
                            <div
                              style={{
                                padding: '0 10px',
                                backgroundColor: colors.tertiary
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                                }}
                              >
                                {currentFamily.planType === 'Free' ? (
                                  <>
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formLabelControl}
                                    >
                                      <InputLabel id="demo-simple-select-outlined-school">
                                        School Name
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-outlined-school"
                                        id="demo-simple-select-outlined"
                                        name={`entries[${index}].school`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          handleChange(e);
                                          if (values.entries.length) {
                                            values.entries.map(
                                              (entryData, entryIndex) => {
                                                setFieldValue(
                                                  `entries[${entryIndex}].school`,
                                                  e.target.value
                                                );
                                                setFieldValue(
                                                  `entries[${entryIndex}].for`,
                                                  ''
                                                );
                                                return null;
                                              }
                                            );
                                          }
                                          onSchoolNameChange(
                                            e.target.value,
                                            schools
                                          );
                                        }}
                                        value={
                                          schools &&
                                          schools.length &&
                                          values.entries[index]
                                            ? values.entries[index].school || ''
                                            : values.entries[index].school || ''
                                        }
                                        label="school name"
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index].school &&
                                            errors.entries[index] &&
                                            errors.entries[index].school
                                        )}
                                      >
                                        <MenuItem value="" disabled={true}>
                                          <em>None</em>
                                        </MenuItem>
                                        {/* <MenuItem value={'Parents'}>Parents</MenuItem> */}
                                        {schools &&
                                          schools.length &&
                                          schools.map((school, schoolIndex) => (
                                            <MenuItem
                                              key={`school-select-${schoolIndex}`}
                                              value={school.id}
                                              disabled={
                                                values.entries[0].groups &&
                                                values.entries[0].groups.length
                                              }
                                            >
                                              {school.name}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                    {typeof values.entries[0].for === "object" && values.entries[0].for.length ? (
                                      <FormControl
                                        variant="outlined"
                                        className={classes.formLabelControl}
                                        // disabled={
                                        //   !freeCalendarsOptions ||
                                        //   !freeCalendarsOptions.length
                                        // }
                                      >
                                        <InputLabel id="demo-simple-select-outlined-for">
                                          For
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-for"
                                          id="demo-simple-select-outlined"
                                          name={`entries[${index}].for`}
                                          onBlur={handleBlur}
                                          renderValue={(selected) => {
                                            const allSelectedyear =
                                              selected?.includes('Pre K')
                                                ? '-1'
                                                : selected?.includes('Kindy')
                                                ? '0'
                                                : selected;
                                            const gpYear =
                                              groupYears.length &&
                                              groupYears.map((data) => {
                                                if (
                                                  allSelectedyear.includes(
                                                    data.value
                                                  )
                                                ) {
                                                  return data.key;
                                                }
                                                if (
                                                  allSelectedyear.includes(
                                                    'all'
                                                  )
                                                ) {
                                                  return data.key;
                                                } else {
                                                  return null;
                                                }
                                              });
                                            const groupYearsFilter =
                                              gpYear &&
                                              gpYear.filter(
                                                (data) => data && data
                                              );
                                            return groupYearsFilter
                                              ? groupYearsFilter.join(', ')
                                              : '';
                                          }}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleChangeFreeYear(
                                              e,
                                              index,
                                              values.entries,
                                              setFieldValue
                                            );
                                          }}
                                          value={
                                            freeCalendarsOptions &&
                                            freeCalendarsOptions.length &&
                                            values.entries[index]
                                              ? values.entries[index].forYears || ''
                                              : values.entries[index].forYears || ''
                                          }
                                          label="For"
                                          error={Boolean(
                                            touched.entries &&
                                              errors.entries &&
                                              touched.entries[index] &&
                                              touched.entries[index].forYears &&
                                              errors.entries[index] &&
                                              errors.entries[index].forYears
                                          )}
                                        >
                                          <MenuItem value="" disabled={true}>
                                            <em>None</em>
                                          </MenuItem>
                                          <MenuItem
                                            value="all"
                                            disabled={
                                              index !== 0 &&
                                              'all' !== values.entries[0].forYears
                                            }
                                          >
                                            <em>All Years</em>
                                          </MenuItem>
                                          {freeCalendarsOptions &&
                                            freeCalendarsOptions.length &&
                                            freeCalendarsOptions.map(
                                              (year, forIndex) => {
                                                return (
                                                  <MenuItem
                                                    key={`for-select-${forIndex}`}
                                                    value={year.value}
                                                    selected={freeCalendarsOptions.includes(year.value)}
                                                    disabled={
                                                      index !== 0 &&
                                                      year.value !==
                                                        values.entries[0].for &&
                                                      values.entries[0].for !==
                                                        'all'
                                                    }
                                                  >
                                                    {year.label === 'Year -1'
                                                      ? 'Pre K'
                                                      : year.label === 'Year 0'
                                                      ? 'Kindy'
                                                      : year.label}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                        </Select>
                                      </FormControl>
                                    ) : values.entries[0].groups &&
                                      values.entries[0].groups.length ? (
                                      <FormControl
                                        variant="outlined"
                                        className={classes.formLabelControl}
                                      >
                                        <InputLabel id="demo-simple-select-outlined-for-years">
                                          For
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-for-years"
                                          id="demo-simple-select-outlined"
                                          multiple
                                          name={`entries[${index}].forYears`}
                                          renderValue={(selected) => {
                                            const gpYear =
                                              groupYears.length &&
                                              groupYears.map((data) => {
                                                if (
                                                  selected.includes(data.value)
                                                ) {
                                                  return data.key;
                                                } else {
                                                  return null;
                                                }
                                              });
                                            const groupYearsFilter =
                                              gpYear &&
                                              gpYear.filter(
                                                (data) => data && data
                                              );
                                            return groupYearsFilter
                                              ? groupYearsFilter.join(', ')
                                              : '';
                                          }}
                                          value={
                                            (values.entries[0] &&
                                              values.entries[0].forYears) ||
                                            ''
                                          }
                                          label="ForYears"
                                        >
                                          <MenuItem value="" disabled={true}>
                                            <em>None</em>
                                          </MenuItem>
                                          <MenuItem value="all" disabled={true}>
                                            <em>All Years</em>
                                          </MenuItem>
                                          {groupYears &&
                                            groupYears.length > 0 &&
                                            groupYears
                                              .sort(function (a, b) {
                                                let yearValue1 = a?.value
                                                  ?.split('Year ')
                                                  .reverse()[0];
                                                let yearValue2 = b?.value
                                                  ?.split('Year ')
                                                  .reverse()[0];
                                                return (
                                                  parseInt(yearValue1) -
                                                  parseInt(yearValue2)
                                                );
                                              })
                                              .map((yearData, forIndex) => (
                                                <MenuItem
                                                  key={`years-${forIndex}`}
                                                  value={yearData.value}
                                                  className={
                                                    classes.menuItemClass
                                                  }
                                                  disabled={true}
                                                >
                                                  <div>
                                                    <Checkbox
                                                      icon={
                                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                                      }
                                                      checkedIcon={
                                                        <CheckBoxIcon fontSize="small" />
                                                      }
                                                      checked={
                                                        yearData.selected
                                                      }
                                                      name={yearData.value.toString()}
                                                      className={
                                                        classes.controlCheck
                                                      }
                                                      classes={{
                                                        root: classes.checkbox,
                                                        checked: classes.checked
                                                      }}
                                                    />
                                                    {yearData.key}
                                                  </div>
                                                </MenuItem>
                                              ))}
                                        </Select>
                                      </FormControl>
                                    ) : values.entries[index].for ===
                                    multiYear ? (
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formLabelControl}
                                    >
                                      <InputLabel id="multiyear-for-drop-down">
                                        For
                                      </InputLabel>
                                      <Select
                                        labelId="multiyear-for-drop-down"
                                        id="multi-year-select-outlined"
                                        multiple
                                        name={`entries[${index}].forYears`}
                                        renderValue={(selected) => {
                                          const allSelectedyear =
                                            selected?.includes('Pre K')
                                              ? '-1'
                                              : selected?.includes('Kindy')
                                              ? '0'
                                              : selected;
                                          const gpYear =
                                            groupYears.length &&
                                            groupYears.map((data) => {
                                              if (allSelectedyear.includes(data.value) || allSelectedyear.includes(data.key)
                                              ) {
                                                return data.key;
                                              }
                                              if (
                                                allSelectedyear.includes(
                                                  'all'
                                                ) && allSelectedyear.length > 1
                                              ) {
                                                return data.key;
                                              } else {
                                                return null;
                                              }
                                            });
                                          const groupYearsFilter =
                                            gpYear &&
                                            gpYear.filter(
                                              (data) => data && data
                                            );
                                          return groupYearsFilter
                                            ? groupYearsFilter.join(', ')
                                            : '';
                                        }}
                                        onChange={(e) => {
                                          handleChange(e);
                                          handleForYearChange(
                                            e,
                                            index,
                                            values.entries,
                                            setFieldValue
                                          );
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index].forYears) ||
                                          ''
                                        }
                                        label="ForYears"
                                      >
                                        {groupYears &&
                                          groupYears.length > 0 &&
                                          groupYears
                                            .sort(function (a, b) {
                                              let yearValue1 = a?.value
                                                ?.split('Year ')
                                                .reverse()[0];
                                              let yearValue2 = b?.value
                                                ?.split('Year ')
                                                .reverse()[0];
                                              return (
                                                parseInt(yearValue1) -
                                                parseInt(yearValue2)
                                              );
                                            })
                                            .map((yearData, forIndex) => (
                                              <MenuItem
                                                key={`years-${forIndex}-${yearData.key}`}
                                                value={yearData.value}
                                                className={
                                                  classes.menuItemClass
                                                }
                                                disabled={index !== 0}
                                              >
                                                <div>
                                                  <Checkbox
                                                    icon={
                                                      <CheckBoxOutlineBlankIcon fontSize="small" />
                                                    }
                                                    checkedIcon={
                                                      <CheckBoxIcon fontSize="small" />
                                                    }
                                                    checked={
                                                      yearData.selected
                                                    }
                                                    name={yearData?.value?.toString()}
                                                    className={
                                                      classes.controlCheck
                                                    }
                                                    classes={{
                                                      root: classes.checkbox,
                                                      checked: classes.checked
                                                    }}
                                                  />
                                                  {yearData?.key === 'Year -1'
                                                    ? 'Pre K'
                                                    : yearData?.key}
                                                </div>
                                              </MenuItem>
                                            ))}
                                      </Select>
                                    </FormControl>
                                  ) : null}
                                    {index === 0 &&
                                    values.entries[0].groups &&
                                    values.entries[0].groups.length ? (
                                      <>
                                        <div className={classes.orDivider}>
                                          <span>OR</span>
                                        </div>
                                        <FormControl
                                          variant="outlined"
                                          className={classes.formLabelControl}
                                          disabled={!groups.length}
                                        >
                                          <InputLabel id="demo-simple-select-outlined-label-Group">
                                            Custom Group
                                          </InputLabel>
                                          <Select
                                            labelId="demo-simple-select-outlined-label-Group"
                                            id="demo-simple-select-outlined-Group"
                                            multiple
                                            name={`entries.[${index}].groups`}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                              handleChange(e);
                                              handleChangeFreeYear(
                                                e,
                                                index,
                                                values.entries,
                                                setFieldValue
                                              );
                                              // yearChangeHandle(e);
                                            }}
                                            value={
                                              (values.entries[index] &&
                                                values.entries[0].groups) ||
                                              []
                                            }
                                            renderValue={(selected) => {
                                              const groupName = groups.map(
                                                (data) => {
                                                  if (
                                                    selected.includes(
                                                      data.value
                                                    )
                                                  ) {
                                                    return data.key;
                                                  } else {
                                                    return null;
                                                  }
                                                }
                                              );
                                              const groupNameFilter =
                                                groupName.filter(
                                                  (data) => data && data
                                                );
                                              return groupNameFilter.join(', ');
                                            }}
                                            label="Custom Group"
                                            error={Boolean(
                                              touched.entries &&
                                                errors.entries &&
                                                touched.entries[index] &&
                                                touched.entries[index].groups &&
                                                errors.entries[index] &&
                                                errors.entries[index].groups
                                            )}
                                          >
                                            {/* <MenuItem value="">
                                                <em>None</em>
                                              </MenuItem> */}
                                            {groups &&
                                              groups.length &&
                                              groups.map((group, forIndex) => (
                                                <MenuItem
                                                  key={`group-${forIndex}`}
                                                  value={group.value}
                                                  className={
                                                    classes.menuItemClass
                                                  }
                                                  disabled={true}
                                                >
                                                  <div>
                                                    <Checkbox
                                                      icon={
                                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                                      }
                                                      checkedIcon={
                                                        <CheckBoxIcon fontSize="small" />
                                                      }
                                                      checked={group.selected}
                                                      name={group.value}
                                                      className={
                                                        classes.controlCheck
                                                      }
                                                      classes={{
                                                        root: classes.checkbox,
                                                        checked: classes.checked
                                                      }}
                                                    />
                                                    {group.key}
                                                  </div>
                                                </MenuItem>
                                              ))}
                                          </Select>
                                        </FormControl>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formLabelControl}
                                    >
                                      <InputLabel id="demo-simple-select-outlined-for">
                                        For
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-outlined-for"
                                        id="demo-simple-select-outlined"
                                        name={`entries[${index}].for`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        disabled={isKeysDeleted.includes(index)}
                                        value={
                                          childOptions &&
                                          childOptions.length &&
                                          values.entries[index]
                                            ? values.entries[index].for || ''
                                            : values.entries[index].for || ''
                                        }
                                        label="For"
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index].for &&
                                            errors.entries[index] &&
                                            errors.entries[index].for
                                        )}
                                      >
                                        <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={'Parents'}>
                                          Parents
                                        </MenuItem>
                                        {childOptions &&
                                          childOptions.length &&
                                          childOptions.map(
                                            (child, forIndex) => (
                                              <MenuItem
                                                key={`for-select-${forIndex}`}
                                                value={child.id}
                                              >
                                                {child.value}
                                              </MenuItem>
                                            )
                                          )}
                                      </Select>
                                    </FormControl>
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formLabelControl}
                                    >
                                      <InputLabel id="demo-simple-select-outlined-label-assign">
                                        Assign
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-outlined-label-assign"
                                        id="demo-simple-select-outlined-assign"
                                        multiple
                                        name={`entries.[${index}].assign`}
                                        onBlur={handleBlur}
                                        disabled={isKeysDeleted.includes(index)}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `entries[${index}].assign`,
                                            e.target.value
                                          );
                                          const updateInitialValue =
                                            values.entries.map(
                                              (entryData, entryIndex) => {
                                                let updatedAssignDropDown = [];
                                                if (index === entryIndex) {
                                                  updatedAssignDropDown =
                                                    entryData.assignDropDownData.map(
                                                      (assignData) => {
                                                        return {
                                                          ...assignData,
                                                          selected:
                                                            e.target.value.includes(
                                                              assignData.id
                                                            )
                                                        };
                                                      }
                                                    );
                                                }
                                                return {
                                                  ...entryData,
                                                  assign:
                                                    index === entryIndex
                                                      ? e.target.value
                                                      : entryData.assign,
                                                  assignDropDownData:
                                                    index === entryIndex
                                                      ? updatedAssignDropDown
                                                      : entryData.assignDropDownData
                                                };
                                              }
                                            );
                                          setInitialEntry({
                                            entries: [...updateInitialValue]
                                          });
                                        }}
                                        renderValue={(selected) => {
                                          const familyName = values.entries[
                                            index
                                          ].assignDropDownData.map((data) => {
                                            if (selected.includes(data.id)) {
                                              return data.value;
                                            } else {
                                              return null;
                                            }
                                          });
                                          const familyNameFilter =
                                            familyName.filter(
                                              (data) => data && data
                                            );
                                          return familyNameFilter.join(', ');
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index].assign) ||
                                          []
                                        }
                                        label="Assign"
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index].assign &&
                                            errors.entries[index] &&
                                            errors.entries[index].assign
                                        )}
                                      >
                                        <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem>
                                        {values.entries[index]
                                          .assignDropDownData &&
                                          values.entries[index]
                                            .assignDropDownData.length &&
                                          values.entries[
                                            index
                                          ].assignDropDownData.map(
                                            (assign, assignIndex) => (
                                              <MenuItem
                                                key={`assign-${assignIndex}`}
                                                value={assign.id}
                                                className={
                                                  classes.menuItemClass
                                                }
                                              >
                                                <div>
                                                  <Checkbox
                                                    icon={
                                                      <CheckBoxOutlineBlankIcon fontSize="small" />
                                                    }
                                                    checkedIcon={
                                                      <CheckBoxIcon fontSize="small" />
                                                    }
                                                    checked={assign.selected}
                                                    name={assign.value}
                                                    className={
                                                      classes.controlCheck
                                                    }
                                                    classes={{
                                                      root: classes.checkbox,
                                                      checked: classes.checked
                                                    }}
                                                  />
                                                  {assign.value}
                                                </div>
                                              </MenuItem>
                                            )
                                          )}
                                      </Select>
                                    </FormControl>
                                  </>
                                )}
                                <Divider
                                  variant="fullWidth"
                                  style={{ marginTop: '20px' }}
                                />

                                <div style={{ marginTop: 20 }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          false
                                        }
                                        disabled={isKeysDeleted.includes(index)}
                                        onChange={(e) =>
                                          handleChangeCheck(
                                            'allDay',
                                            index,
                                            values
                                          )
                                        }
                                        name={`entries[${index}].allDay`}
                                      />
                                    }
                                    label="All day"
                                  />
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: 10
                                  }}
                                >
                                  <Typography>From</Typography>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: 5
                                    }}
                                  >
                                    <DatePicker
                                      disabled={
                                        hideForRepeat ||
                                        isKeysDeleted.includes(index)
                                      }
                                      autoOk
                                      okLabel=""
                                      cancelLabel=""
                                      name={`entries[${index}].from.date`}
                                      onBlur={handleBlur}
                                      onChange={(date) =>
                                        handleDateChange(
                                          date,
                                          index,
                                          values,
                                          'from',
                                          'date'
                                        )
                                      }
                                      value={
                                        (values.entries[index] &&
                                          moment(
                                            values.entries[index].from.date
                                          )) ||
                                        ''
                                      }
                                      style={{ width: '40%' }}
                                      format="MMM DD,YYYY"
                                      className={
                                        classes.formDateControlFromBordered
                                      }
                                    />
                                    <FormControl
                                      style={{ marginTop: 0 }}
                                      className={classes.formLabelControlTime}
                                    >
                                      <TextField
                                        label=""
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 1, max: 12 }
                                        }}
                                        variant="outlined"
                                        placeholder="hh"
                                        //style={{height: '23px', marginTop: 0}}
                                        className={classes.inputControlBoxTime}
                                        name={`entries[${index}].newFromTimeFormate.hh`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          if (
                                            (e.target.value.length <= 2 &&
                                              parseInt(e.target.value) <= 12 &&
                                              parseInt(e.target.value) > 0 &&
                                              Number.isInteger(
                                                parseInt(e.target.value)
                                              )) ||
                                            e.target.value === ''
                                          ) {
                                            handleChange(e);
                                            if (
                                              hasData &&
                                              hasData.length === 0
                                            ) {
                                              setFieldValue(
                                                `entries[${index}].newToTimeFormate.hh`,
                                                parseInt(e.target.value) === 12
                                                  ? '1'
                                                  : 1 + parseInt(e.target.value)
                                              );
                                            }
                                          }
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .newFromTimeFormate &&
                                            values.entries[index]
                                              .newFromTimeFormate.hh) ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index]
                                              .newFromTimeFormate &&
                                            touched.entries[index]
                                              .newFromTimeFormate.hh &&
                                            errors.entries[index] &&
                                            errors.entries[index]
                                              .newFromTimeFormate &&
                                            errors.entries[index]
                                              .newFromTimeFormate.hh
                                        )}
                                        disabled={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          isKeysDeleted.includes(index) ||
                                          false
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      style={{ marginTop: 0 }}
                                      className={classes.formLabelControlTime}
                                    >
                                      <TextField
                                        label=""
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 0, max: 59 }
                                        }}
                                        variant="outlined"
                                        placeholder="mm"
                                        className={classes.inputControlBoxTime}
                                        name={`entries[${index}].newFromTimeFormate.mm`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          if (
                                            (e.target.value.length <= 2 &&
                                              parseInt(e.target.value) <= 59 &&
                                              parseInt(e.target.value) >= 0 &&
                                              Number.isInteger(
                                                parseInt(e.target.value)
                                              )) ||
                                            e.target.value === ''
                                          ) {
                                            handleChange(e);
                                            setFieldValue(
                                              `entries[${index}].newFromTimeFormate.mm`,
                                              e.target.value
                                            );
                                            if (
                                              hasData &&
                                              hasData.length === 0
                                            ) {
                                              setFieldValue(
                                                `entries[${index}].newToTimeFormate.mm`,
                                                e.target.value
                                              );
                                            }
                                          }
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .newFromTimeFormate &&
                                            values.entries[index]
                                              .newFromTimeFormate.mm) ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index]
                                              .newFromTimeFormate &&
                                            touched.entries[index]
                                              .newFromTimeFormate.mm &&
                                            errors.entries[index] &&
                                            errors.entries[index]
                                              .newFromTimeFormate &&
                                            errors.entries[index]
                                              .newFromTimeFormate.mm
                                        )}
                                        disabled={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          isKeysDeleted.includes(index) ||
                                          false
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      style={{ marginTop: 0 }}
                                      className={classes.formLabelControlTime}
                                    >
                                      <TextField
                                        label=""
                                        variant="outlined"
                                        placeholder="a/p"
                                        className={classes.inputControlBoxTime}
                                        name={`entries[${index}].newFromTimeFormate.ap`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === '' ||
                                            e.target.value.toLowerCase() ===
                                              'a' ||
                                            e.target.value.toLowerCase() === 'p'
                                          ) {
                                            handleChange(e);
                                            if (
                                              hasData &&
                                              hasData.length === 0
                                            ) {
                                              setFieldValue(
                                                `entries[${index}].newToTimeFormate.ap`,
                                                e.target.value
                                              );
                                            }
                                          }
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .newFromTimeFormate &&
                                            values.entries[index]
                                              .newFromTimeFormate.ap) ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index]
                                              .newFromTimeFormate &&
                                            touched.entries[index]
                                              .newFromTimeFormate.ap &&
                                            errors.entries[index] &&
                                            errors.entries[index]
                                              .newFromTimeFormate &&
                                            errors.entries[index]
                                              .newFromTimeFormate.ap
                                        )}
                                        disabled={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          isKeysDeleted.includes(index) ||
                                          false
                                        }
                                      />
                                    </FormControl>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: 10
                                  }}
                                >
                                  <Typography>To</Typography>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: 5
                                    }}
                                  >
                                    <DatePicker
                                      disabled={
                                        hideForRepeat ||
                                        isKeysDeleted.includes(index)
                                      }
                                      autoOk
                                      okLabel=""
                                      cancelLabel=""
                                      name={`entries[${index}].to.date`}
                                      onBlur={handleBlur}
                                      minDate={
                                        (values.entries[index] &&
                                          moment(
                                            values.entries[index].from.date
                                          )) ||
                                        ''
                                      }
                                      onChange={(date) =>
                                        handleDateChange(
                                          date,
                                          index,
                                          values,
                                          'to',
                                          'date'
                                        )
                                      }
                                      value={
                                        (values.entries[index] &&
                                          moment(
                                            values.entries[index].to.date
                                          )) ||
                                        ''
                                      }
                                      style={{ width: '40%' }}
                                      format="MMM DD,YYYY"
                                      className={
                                        classes.formDateControlFromBordered
                                      }
                                    />
                                    <FormControl
                                      style={{ marginTop: 0 }}
                                      className={classes.formLabelControlTime}
                                    >
                                      <TextField
                                        label=""
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 1, max: 12 }
                                        }}
                                        variant="outlined"
                                        placeholder="hh"
                                        //style={{height: '23px', marginTop: 0}}
                                        className={classes.inputControlBoxTime}
                                        name={`entries[${index}].newToTimeFormate.hh`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          if (
                                            (e.target.value.length <= 2 &&
                                              parseInt(e.target.value) <= 12 &&
                                              parseInt(e.target.value) > 0 &&
                                              Number.isInteger(
                                                parseInt(e.target.value)
                                              )) ||
                                            e.target.value === ''
                                          ) {
                                            handleChange(e);
                                          }
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .newToTimeFormate &&
                                            values.entries[index]
                                              .newToTimeFormate.hh) ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index]
                                              .newToTimeFormate &&
                                            touched.entries[index]
                                              .newToTimeFormate.hh &&
                                            errors.entries[index] &&
                                            errors.entries[index]
                                              .newToTimeFormate &&
                                            errors.entries[index]
                                              .newToTimeFormate.hh
                                        )}
                                        disabled={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          isKeysDeleted.includes(index) ||
                                          false
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      style={{ marginTop: 0 }}
                                      className={classes.formLabelControlTime}
                                    >
                                      <TextField
                                        label=""
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 0, max: 59 }
                                        }}
                                        variant="outlined"
                                        placeholder="mm"
                                        className={classes.inputControlBoxTime}
                                        name={`entries[${index}].newToTimeFormate.mm`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          if (
                                            (e.target.value.length <= 2 &&
                                              parseInt(e.target.value) <= 59 &&
                                              parseInt(e.target.value) >= 0 &&
                                              Number.isInteger(
                                                parseInt(e.target.value)
                                              )) ||
                                            e.target.value === ''
                                          ) {
                                            handleChange(e);
                                            setFieldValue(
                                              `entries[${index}].newToTimeFormate.mm`,
                                              e.target.value
                                            );
                                          }
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .newToTimeFormate &&
                                            values.entries[index]
                                              .newToTimeFormate.mm) ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index]
                                              .newToTimeFormate &&
                                            touched.entries[index]
                                              .newToTimeFormate.mm &&
                                            errors.entries[index] &&
                                            errors.entries[index]
                                              .newToTimeFormate &&
                                            errors.entries[index]
                                              .newToTimeFormate.mm
                                        )}
                                        disabled={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          isKeysDeleted.includes(index) ||
                                          false
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      style={{ marginTop: 0 }}
                                      className={classes.formLabelControlTime}
                                    >
                                      <TextField
                                        label=""
                                        variant="outlined"
                                        placeholder="a/p"
                                        //style={{height: '23px', marginTop: 0}}
                                        className={classes.inputControlBoxTime}
                                        name={`entries[${index}].newToTimeFormate.ap`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === '' ||
                                            e.target.value.toLowerCase() ===
                                              'a' ||
                                            e.target.value.toLowerCase() === 'p'
                                          ) {
                                            handleChange(e);
                                          }
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .newToTimeFormate &&
                                            values.entries[index]
                                              .newToTimeFormate.ap) ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index]
                                              .newToTimeFormate &&
                                            touched.entries[index]
                                              .newToTimeFormate.ap &&
                                            errors.entries[index] &&
                                            errors.entries[index]
                                              .newToTimeFormate &&
                                            errors.entries[index]
                                              .newToTimeFormate.ap
                                        )}
                                        disabled={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          isKeysDeleted.includes(index) ||
                                          false
                                        }
                                      />
                                    </FormControl>

                                    {/* <input type="text" placeholder="hh" style={{ width: '45px', textAlign: 'center'}}  name={`entries[${index}].to.hh`} min="1" max="12"/>
                                <input type="text" placeholder="mm" style={{ width: '45px',  textAlign: 'center'}}  name={`entries[${index}].to.mm`} min="1" max="60"/>
                                <input type="text" placeholder="a/p" style={{ width: '45px' textAlign: 'center'}}  name={`entries[${index}].to.ap`} /> */}
                                    {/* <ThemeProvider theme={muiTimePickerTheme}>
                                  <TimePicker
                                    autoOk
                                    name={`entries[${index}].to.time`}
                                    onBlur={handleBlur}
                                    onChange={(e) =>
                                      handleDateChange(
                                        e,
                                        index,
                                        values,
                                        'to',
                                        'time'
                                      )
                                    }
                                    value={
                                      (values.entries[index] &&
                                        moment(
                                          values.entries[index].to.time,
                                          'h:mma'
                                        )) ||
                                      ''
                                    }
                                    format="h:mma"
                                    style={{ width: 80 }}
                                    className={
                                      classes.formDateControlFromBordered
                                    }
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </ThemeProvider> */}
                                  </div>
                                  {Boolean(
                                    touched.entries &&
                                      errors.entries &&
                                      touched.entries[index] &&
                                      touched.entries[index].from &&
                                      touched.entries[index].from.time &&
                                      errors.entries[index] &&
                                      errors.entries[index].to
                                  ) && (
                                    <FormHelperText
                                      style={{ color: colors.error }}
                                    >
                                      {errors.entries[index].to}
                                    </FormHelperText>
                                  )}
                                </div>
                                {/* <div style={{ marginTop: 20, marginLeft: 10 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                    onChange={(e) =>
                                      handleChangeCheck('allDay', index, values)
                                    }
                                    name={`entries[${index}].allDay`}
                                  />
                                }
                                label="All day"
                              />
                            </div> */}
                                <Divider
                                  variant="fullWidth"
                                  style={{ marginBottom: 20, marginTop: 20 }}
                                />
                                {entry.actions.repeat.length && index === 0 ? (
                                  <div style={{ marginTop: 20 }}>
                                    <Typography variant="subtitle2">
                                      Repeat
                                    </Typography>
                                    {entry.actions.repeat.map((data, idx) => (
                                      <div
                                        key={`repeat-${idx}`}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginTop: 16
                                        }}
                                      >
                                        <FormControl
                                          className={
                                            classes.formLabelControlBordered
                                          }
                                          style={{ width: '90%' }}
                                        >
                                          <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            name={`entries[${index}].actions.repeat[${idx}].type`}
                                            onBlur={handleBlur}
                                            onChange={(e) =>
                                              handleRepeatChange(
                                                e,
                                                index,
                                                idx,
                                                values
                                              )
                                            }
                                            value={
                                              (values.entries[index] &&
                                                values.entries[index].actions
                                                  .repeat[idx].type) ||
                                              ''
                                            }
                                            label="Repeat"
                                            disabled={isKeysDeleted.includes(
                                              index
                                            )}
                                          >
                                            {/* <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem> */}
                                            <MenuItem
                                              value={'Does not repeat'}
                                              // disabled={hideForRepeat}
                                            >
                                              Does not repeat
                                            </MenuItem>
                                            <MenuItem
                                              value={'Daily'}
                                              // disabled={hideForRepeat}
                                            >
                                              Daily
                                            </MenuItem>
                                            <MenuItem
                                              value={'Custom'}
                                              // disabled={hideForRepeat}
                                            >
                                              Custom
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                        {(values.entries[index] &&
                                          values.entries[index].actions.repeat[
                                            idx
                                          ].type) === 'Custom' ? (
                                          <VisibilityIcon
                                            onClick={(e) => {
                                              const data = {
                                                selectedIndex: index,
                                                innerIndex: idx,
                                                values: {
                                                  entries: [...values.entries]
                                                }
                                              };
                                              setCustomValue(data);
                                              setOpenModal({
                                                open: true,
                                                value: JSON.stringify(
                                                  values.entries[index].actions
                                                    .repeat[idx]
                                                )
                                              });
                                            }}
                                            style={{ margin: '0 5px' }}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                        {!hideForRepeat ? (
                                          <CloseIcon
                                            onClick={(e) => {
                                              if (
                                                !isKeysDeleted.includes(index)
                                              ) {
                                                handleDateActionRemove(
                                                  index,
                                                  idx,
                                                  values,
                                                  'repeat'
                                                );
                                              }
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={Calendar}
                                            alt="calendar"
                                            onClick={() =>
                                              goSourceEntry(
                                                hasData[0].parentRepeatEntryId
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {entry.actions.notification.length ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      marginTop: 20
                                    }}
                                  >
                                    <Typography variant="subtitle2">
                                      Notification
                                    </Typography>
                                    {entry.actions.notification.map(
                                      (data, idx) => (
                                        <div
                                          key={`notification-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16
                                          }}
                                        >
                                          <FormControl
                                            variant="outlined"
                                            className={
                                              classes.formDayLabelControl
                                            }
                                          >
                                            <DatePicker
                                              disabled={isKeysDeleted.includes(
                                                index
                                              )}
                                              autoOk
                                              okLabel=""
                                              cancelLabel=""
                                              name={`entries[${index}].actions.notification[${idx}].date`}
                                              onBlur={handleBlur}
                                              // maxDate={
                                              //   (values.entries[index] &&
                                              //     moment(
                                              //       values.entries[index].from.date
                                              //     )) ||
                                              //   ''
                                              // }
                                              onChange={(date) =>
                                                handleNotificationDateChange(
                                                  date,
                                                  index,
                                                  idx,
                                                  values,
                                                  'date'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .notification[idx].date) ||
                                                moment().format('MMM DD,YYYY')
                                              }
                                              style={{ width: '80%' }}
                                              minDateMessage=""
                                              format="MMM DD,YYYY"
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </FormControl>
                                          <ThemeProvider
                                            theme={muiTimePickerTheme}
                                          >
                                            <TimePicker
                                              disabled={isKeysDeleted.includes(
                                                index
                                              )}
                                              autoOk
                                              name={`entries[${index}].notification.time`}
                                              onBlur={handleBlur}
                                              onChange={(e) =>
                                                handleDateActionChange(
                                                  e,
                                                  index,
                                                  idx,
                                                  values,
                                                  'notification',
                                                  'time'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  moment(
                                                    values.entries[index]
                                                      .actions.notification[idx]
                                                      .time,
                                                    'h:mma'
                                                  )) ||
                                                ''
                                              }
                                              format="h:mma"
                                              style={{ width: '40%' }}
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </ThemeProvider>
                                          <CloseIcon
                                            onClick={(e) => {
                                              if (
                                                !isKeysDeleted.includes(index)
                                              ) {
                                                handleDateActionRemove(
                                                  index,
                                                  idx,
                                                  values,
                                                  'notification'
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {entry.actions.reminder.length ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      marginTop: 20
                                    }}
                                  >
                                    {entry.actions.reminder.map((data, idx) => (
                                      <Fragment key={`reminder-${idx}`}>
                                        <Typography variant="subtitle2">
                                          Reminder
                                        </Typography>
                                        <div
                                          key={`reminder-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16
                                          }}
                                        >
                                          <DatePicker
                                            autoOk
                                            okLabel=""
                                            cancelLabel=""
                                            disabled={isKeysDeleted.includes(
                                              index
                                            )}
                                            name={`entries[${index}].reminder`}
                                            onBlur={handleBlur}
                                            onChange={(e) =>
                                              handleDateActionChange(
                                                e,
                                                index,
                                                idx,
                                                values,
                                                'reminder',
                                                'date'
                                              )
                                            }
                                            value={
                                              (values.entries[index] &&
                                                moment(
                                                  values.entries[index].actions
                                                    .reminder[idx]
                                                )) ||
                                              ''
                                            }
                                            format="MMM DD,YYYY"
                                            style={{ width: '80%' }}
                                            className={
                                              classes.formDateControlFromBordered
                                            }
                                          />
                                          <CloseIcon
                                            onClick={(e) => {
                                              if (
                                                !isKeysDeleted.includes(index)
                                              ) {
                                                handleDateActionRemove(
                                                  index,
                                                  idx,
                                                  values,
                                                  'reminder'
                                                );
                                              }
                                            }}
                                          />
                                        </div>

                                        {values.entries[index] &&
                                        values.entries[index].actions
                                          .reminderNotification &&
                                        values.entries[index].actions
                                          .reminderNotification[idx] ? (
                                          <>
                                            <div
                                              key={`reminderNotificationTitle-${idx}`}
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: 16
                                              }}
                                            >
                                              <Typography variant="subtitle2">
                                                Reminder (Notification)
                                              </Typography>
                                            </div>
                                            <div
                                              key={`reminderNotification-${idx}`}
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: 16,
                                                marginBottom: 16
                                              }}
                                            >
                                              <FormControl
                                                variant="outlined"
                                                className={
                                                  classes.formDayLabelControl
                                                }
                                              >
                                                <DatePicker
                                                  disabled={isKeysDeleted.includes(
                                                    index
                                                  )}
                                                  autoOk
                                                  okLabel=""
                                                  cancelLabel=""
                                                  name={`entries[${index}].actions.reminderNotification[${idx}].date`}
                                                  onBlur={handleBlur}
                                                  // maxDate={
                                                  //   (values.entries[index] &&
                                                  //     moment(
                                                  //       values.entries[index].from.date
                                                  //     )) ||
                                                  //   ''
                                                  // }
                                                  onChange={(date) =>
                                                    handleNotificationDateChange(
                                                      date,
                                                      index,
                                                      idx,
                                                      values,
                                                      'date',
                                                      'reminderNotification'
                                                    )
                                                  }
                                                  value={
                                                    (values.entries[index] &&
                                                      values.entries[index]
                                                        .actions
                                                        .reminderNotification &&
                                                      values.entries[index]
                                                        .actions
                                                        .reminderNotification[
                                                        idx
                                                      ] &&
                                                      values.entries[index]
                                                        .actions
                                                        .reminderNotification[
                                                        idx
                                                      ].date) ||
                                                    moment().format(
                                                      'MMM DD,YYYY'
                                                    )
                                                  }
                                                  style={{ width: '80%' }}
                                                  minDateMessage=""
                                                  format="MMM DD,YYYY"
                                                  className={
                                                    classes.formDateControlFromBordered
                                                  }
                                                />
                                              </FormControl>
                                              <ThemeProvider
                                                theme={muiTimePickerTheme}
                                              >
                                                <TimePicker
                                                  disabled={isKeysDeleted.includes(
                                                    index
                                                  )}
                                                  autoOk
                                                  name={`entries[${index}].reminderNotification.time`}
                                                  onBlur={handleBlur}
                                                  onChange={(e) =>
                                                    handleDateActionChange(
                                                      e,
                                                      index,
                                                      idx,
                                                      values,
                                                      'reminderNotification',
                                                      'time'
                                                    )
                                                  }
                                                  value={
                                                    (values.entries[index] &&
                                                      values.entries[index]
                                                        .actions
                                                        .reminderNotification &&
                                                      values.entries[index]
                                                        .actions
                                                        .reminderNotification[
                                                        idx
                                                      ] &&
                                                      moment(
                                                        values.entries[index]
                                                          .actions
                                                          .reminderNotification[
                                                          idx
                                                        ].time,
                                                        'h:mma'
                                                      )) ||
                                                    ''
                                                  }
                                                  format="h:mma"
                                                  style={{ width: '40%' }}
                                                  className={
                                                    classes.formDateControlFromBordered
                                                  }
                                                />
                                              </ThemeProvider>
                                              <CloseIcon
                                                onClick={(e) => {
                                                  if (
                                                    !isKeysDeleted.includes(
                                                      index
                                                    )
                                                  ) {
                                                    handleDateActionRemove(
                                                      index,
                                                      idx,
                                                      values,
                                                      'reminderNotification'
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                          </>
                                        ) : null}
                                      </Fragment>
                                    ))}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {entry.actions.deadline.length ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      marginTop: 20
                                    }}
                                  >
                                    {entry.actions.deadline.map((data, idx) => (
                                      <Fragment key={`deadline-${idx}`}>
                                        <Typography variant="subtitle2">
                                          Deadline
                                        </Typography>
                                        <div
                                          key={`deadline-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16
                                          }}
                                        >
                                          <DatePicker
                                            autoOk
                                            okLabel=""
                                            cancelLabel=""
                                            disabled={isKeysDeleted.includes(
                                              index
                                            )}
                                            name={`entries[${index}].deadline`}
                                            onBlur={handleBlur}
                                            onChange={(e) =>
                                              handleDateActionChange(
                                                e,
                                                index,
                                                idx,
                                                values,
                                                'deadline',
                                                'date'
                                              )
                                            }
                                            value={
                                              (values.entries[index] &&
                                                moment(
                                                  values.entries[index].actions
                                                    .deadline[idx]
                                                )) ||
                                              ''
                                            }
                                            format="MMM DD,YYYY"
                                            style={{ width: '80%' }}
                                            className={
                                              classes.formDateControlFromBordered
                                            }
                                          />
                                          <CloseIcon
                                            onClick={(e) => {
                                              if (
                                                !isKeysDeleted.includes(index)
                                              ) {
                                                handleDateActionRemove(
                                                  index,
                                                  idx,
                                                  values,
                                                  'deadline'
                                                );
                                              }
                                            }}
                                          />
                                        </div>

                                        {values.entries[index] &&
                                        values.entries[index].actions
                                          .deadlineNotification &&
                                        values.entries[index].actions
                                          .deadlineNotification[idx] ? (
                                          <>
                                            <div
                                              key={`reminderDeadlineTitle-${idx}`}
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: 16
                                              }}
                                            >
                                              <Typography variant="subtitle2">
                                                Deadline (Notification)
                                              </Typography>
                                            </div>
                                            <div
                                              key={`deadlineNotification-${idx}`}
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: 16,
                                                marginBottom: 16
                                              }}
                                            >
                                              <FormControl
                                                variant="outlined"
                                                className={
                                                  classes.formDayLabelControl
                                                }
                                              >
                                                <DatePicker
                                                  disabled={isKeysDeleted.includes(
                                                    index
                                                  )}
                                                  autoOk
                                                  okLabel=""
                                                  cancelLabel=""
                                                  name={`entries[${index}].actions.deadlineNotification[${idx}].date`}
                                                  onBlur={handleBlur}
                                                  // maxDate={
                                                  //   (values.entries[index] &&
                                                  //     moment(
                                                  //       values.entries[index].from.date
                                                  //     )) ||
                                                  //   ''
                                                  // }
                                                  onChange={(date) =>
                                                    handleNotificationDateChange(
                                                      date,
                                                      index,
                                                      idx,
                                                      values,
                                                      'date',
                                                      'deadlineNotification'
                                                    )
                                                  }
                                                  value={
                                                    (values.entries[index] &&
                                                      values.entries[index]
                                                        .actions
                                                        .deadlineNotification &&
                                                      values.entries[index]
                                                        .actions
                                                        .deadlineNotification[
                                                        idx
                                                      ] &&
                                                      values.entries[index]
                                                        .actions
                                                        .deadlineNotification[
                                                        idx
                                                      ].date) ||
                                                    moment().format(
                                                      'MMM DD,YYYY'
                                                    )
                                                  }
                                                  style={{ width: '80%' }}
                                                  minDateMessage=""
                                                  format="MMM DD,YYYY"
                                                  className={
                                                    classes.formDateControlFromBordered
                                                  }
                                                />
                                              </FormControl>
                                              <ThemeProvider
                                                theme={muiTimePickerTheme}
                                              >
                                                <TimePicker
                                                  disabled={isKeysDeleted.includes(
                                                    index
                                                  )}
                                                  autoOk
                                                  name={`entries[${index}].deadlineNotification.time`}
                                                  onBlur={handleBlur}
                                                  onChange={(e) =>
                                                    handleDateActionChange(
                                                      e,
                                                      index,
                                                      idx,
                                                      values,
                                                      'deadlineNotification',
                                                      'time'
                                                    )
                                                  }
                                                  value={
                                                    (values.entries[index] &&
                                                      values.entries[index]
                                                        .actions
                                                        .deadlineNotification &&
                                                      values.entries[index]
                                                        .actions
                                                        .deadlineNotification[
                                                        idx
                                                      ] &&
                                                      moment(
                                                        values.entries[index]
                                                          .actions
                                                          .deadlineNotification[
                                                          idx
                                                        ].time,
                                                        'h:mma'
                                                      )) ||
                                                    ''
                                                  }
                                                  format="h:mma"
                                                  style={{ width: '40%' }}
                                                  className={
                                                    classes.formDateControlFromBordered
                                                  }
                                                />
                                              </ThemeProvider>
                                              <CloseIcon
                                                onClick={(e) => {
                                                  if (
                                                    !isKeysDeleted.includes(
                                                      index
                                                    )
                                                  ) {
                                                    handleDateActionRemove(
                                                      index,
                                                      idx,
                                                      values,
                                                      'deadlineNotification'
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                          </>
                                        ) : null}
                                      </Fragment>
                                    ))}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {entry.actions.isCancelled ||
                                entry.actions.isPostponed ||
                                entry.actions.isChanged ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginTop: 20
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          color: entry.actions.isChanged
                                            ? '#000'
                                            : colors.tertiaryRed
                                        }}
                                        className={classes.updatedLabel}
                                      >
                                        {entry.actions.isPostponed
                                          ? 'Postponed'
                                          : entry.actions.isCancelled
                                          ? 'Cancelled'
                                          : 'Updated'}
                                      </Typography>
                                      <Typography
                                        className={classes.updatedDesc}
                                        style={{
                                          ...(entry.actions.isChanged
                                            ? {}
                                            : { fontSize: 16 }),
                                          color: colors.primaryDarkGrey
                                        }}
                                      >
                                        Display{' '}
                                        {entry.actions.isPostponed
                                          ? 'Postponed'
                                          : entry.actions.isCancelled
                                          ? 'Cancelled'
                                          : 'Updated'}{' '}
                                        to member
                                      </Typography>
                                    </div>
                                    <CloseIcon
                                      onClick={(e) =>
                                        handleDateActionRemove(
                                          index,
                                          0,
                                          values,
                                          entry.actions.isPostponed
                                            ? 'isPostponed'
                                            : entry.actions.isCancelled
                                            ? 'isCancelled'
                                            : 'isChanged'
                                        )
                                      }
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <FormControl
                                  variant="outlined"
                                  // disabled={hideForRepeat}
                                  style={{ marginBottom: 24 }}
                                  className={classes.formLabelControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Apply Action
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={''}
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        handleActionChange(e, index, values);
                                      }
                                    }}
                                    disabled={isKeysDeleted.includes(index)}
                                    label="Apply Action"
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {index === 0 && (
                                      <MenuItem
                                        disabled={
                                          entry.actions.repeat.length ||
                                          hideForRepeat
                                            ? true
                                            : false
                                        }
                                        value={'repeat'}
                                      >
                                        Repeat
                                      </MenuItem>
                                    )}
                                    <MenuItem value={'notification'}>
                                      Notification
                                    </MenuItem>
                                    <MenuItem
                                      value={'deadline'}
                                      disabled={hideForRepeat}
                                    >
                                      Deadline
                                    </MenuItem>
                                    <MenuItem
                                      value={'reminder'}
                                      disabled={hideForRepeat}
                                    >
                                      Reminder
                                    </MenuItem>
                                    {index === 0 && (
                                      <MenuItem
                                        value={'cancelled'}
                                        // disabled={hideForRepeat}
                                      >
                                        Cancelled
                                      </MenuItem>
                                    )}
                                    {index === 0 && (
                                      <MenuItem
                                        value={'postponed'}
                                        // disabled={hideForRepeat}
                                      >
                                        Postponed
                                      </MenuItem>
                                    )}
                                    {index === 0 && (
                                      <MenuItem
                                        value={'changed'}
                                        // disabled={hideForRepeat}
                                      >
                                        Updated
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </form>
                </>
              )}
              {isRepeatEntryModal && (
                <Modal
                  isOpen={isRepeatEntryModal}
                  onClose={() => {
                    setRepeatEntryModal(false);
                  }}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className={classes.label}>
                      <Typography className={classes.repeatEveryText}>
                        {isRepeatEntryModal === true ? 'Edit' : 'Delete'}{' '}
                        recurring event
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      aria-label="endsType"
                      name="repeatType"
                      onChange={(e) => {
                        setRepeatEntryRadioData(e.target.value);
                      }}
                      value={repeatEntryRadioData}
                    >
                      {!isRepeatChange && (
                        <FormControlLabel
                          value={'This Event'}
                          control={<Radio color="primary" />}
                          label={'This Event'}
                        />
                      )}
                      <FormControlLabel
                        value={'This and following events'}
                        control={<Radio color="primary" />}
                        label={'This and following events'}
                      />
                      {isRepeatEntryModal !== 'delete' &&
                        showAllEventOption && (
                          <FormControlLabel
                            value={'All events'}
                            control={<Radio color="primary" />}
                            label={'All events'}
                            disabled={changePrimaryDay !== '' ? true : false}
                          />
                        )}
                    </RadioGroup>
                    {changePrimaryDay !== '' && (
                      <span className={classes.noteText}>
                        You had changed the primary entry day({changePrimaryDay}
                        ) so you can't change "All Events".
                      </span>
                    )}
                  </FormControl>
                  <div className={classes.modalActions}>
                    <Button onClick={() => setRepeatEntryModal(false)}>
                      {' '}
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (isRepeatEntryModal === 'delete') {
                          setRepeatEntryModal(false);
                          _deleteEntryHandleForRepeat();
                        } else {
                          handleSubmit();
                        }
                      }}
                      color="primary"
                    >
                      Ok
                    </Button>
                  </div>
                </Modal>
              )}
            </>
          );
        }}
      </Formik>
      {isOpenModal.open && (
        <Modal isOpen={isOpenModal.open} onClose={handleCustomDateClose}>
          <CustomDateModal
            data={customValue}
            submitForm={handleCustomDateSubmit}
            selectedValue={JSON.parse(isOpenModal.value)}
            onClose={handleCustomDateClose}
            isRepeatEntry={isKeysDeleted.includes(0) ? true : false}
          />
        </Modal>
      )}

      {isOpenDeleteModal && (
        <Modal
          isOpen={isOpenDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Delete Entry Creation?
            </Typography>
            <Typography className={classes.modalSubTitle}>
              Are you sure you want to delete this new entry?
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  if (hasData && hasData.length) {
                    // _deleteEntryHandle();
                    deleteAllEntries();
                  } else {
                    setUpdatedEntry(null);
                    dispatch(
                      setFamilyOrSchoolPreserveEntryData({
                        entries: [...entryObject.entries]
                      })
                    );
                    setRightOpen(false);
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Delete'}
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenDeleteLinkedModal.open && (
        <Modal
          isOpen={isOpenDeleteLinkedModal.open}
          onClose={() => setOpenDeleteLinkedModal({ open: false, id: null })}
        >
          <div className={classes.modalBox}>
            <Typography
              className={classes.modalTitle}
              style={{ marginBottom: 18 }}
            >
              Delete Linked Entry?
            </Typography>
            <Typography className={classes.modalSubTitle}>
              Are you sure you want to delete this linked entry?
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={removeLinkedEntry}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
              >
                Delete
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteLinkedModal({ open: false, id: null });
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default withFirebase(CreateCalenderEntry);