import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import Routes from '../src/Routes';
import { withFirebase } from 'src/utils/firebase';
import { logoutSuccess, sessionInative } from 'src/redux/actions/authActions';
import { useHistory } from 'react-router-dom';
import { getMaintenanceMode } from './services/auth';
import { setMaintenanceMode } from './redux/actions/maintenanceActions';

const App = ({ firebase }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const maintenanceMode = useSelector((state) => state.maintenance.maintenanceMode);
  const user = useSelector((state) => state.auth.user);
  let maintenanceTimer;

  const handleOnIdle = (event) => {
    localStorage.clear();
    dispatch(logoutSuccess());
    dispatch(sessionInative({ activeStatus: true}));
    window.location.replace('/login');
  };

  const handleOnActive = (event) => {
    console.log('time remaining', getRemainingTime());
  };

  const handleOnAction = (e) => {
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    crossTab: true,
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });

  const fetchMaintenanceMode = async () => {
    const data = await getMaintenanceMode();
      dispatch(setMaintenanceMode(data.mode));
  };

  useEffect(() => {
    if(user) {
      maintenanceTimer = setInterval(() => {
        fetchMaintenanceMode();
      },[5000]);
      fetchMaintenanceMode();
    }
    return () => clearInterval(maintenanceTimer);
  },[user]);

  return <Routes />;
};

export default withFirebase(App);
