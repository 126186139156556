import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField
  // useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { Formik } from 'formik';
import moment from 'moment';
import _ from 'lodash';
import * as Yup from 'yup';
import { DatePicker } from '@material-ui/pickers';
import { colors, weekNames } from 'src/utils/constant';

const WeekString = ['Dummy', 'first', 'second', 'third', 'fourth', 'last'];

const useStyles = makeStyles((theme) => ({
  formDayLabelControl: {
    width: '30%',
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '2.5px 14px',
      marginTop: 7
    }
  },
  formMonthLabelControl: {
    width: 'fit-content',
    marginTop: 24,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '2.5px 40px 2.5px 14px',
      marginTop: 7
    }
  },
  numericInputClass: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '2.5px 14px',
      margin: '4px 0'
    }
  },
  formDateControlFromBordered: {
    border: `1px solid ${colors.primaryLightGrey}`,
    background: colors.secondaryGreyLight,
    width: 121,
    borderRadius: 3,
    padding: 0,
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-input': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10
    }
  },
  modalBox: {
    width: 341,
    height: 460,
    display: 'flex',
    flexDirection: 'column'
  },
  modalTitle: {
    fontSize: 23,
    fontWeight: 500,
    color: colors.primaryDark
  },
  repeatEveryText: {
    fontSize: 16,
    fontWeight: 500,
    color: colors.primaryDark
  },
  dayTitle: {
    fontSize: 12,
    fontWeight: 500
  },
  selectedWeek: {
    height: 24,
    width: 24,
    backgroundColor: colors.primary,
    color: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 15,
    cursor: 'pointer'
  },
  notSelectedWeek: {
    height: 24,
    width: 24,
    backgroundColor: colors.secondaryGreyLight,
    color: colors.primaryLightGrey,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    borderRadius: 15,
    cursor: 'pointer'
  },
  displayFlex: { display: 'flex' },
  flexColumn: { flexDirection: 'column' },
  flex1: { flex: 1 },
  flex2: { flex: 2 },
  justifySpace: { justifyContent: 'space-between' },
  justifyFlexEnd: { justifyContent: 'flex-end' },
  alignCenter: { alignItems: 'center' },
  mt10: { marginTop: 10 },
  mt24: { marginTop: 24 }
}));

const entryObject = {
  repeatDay: 1,
  repeatType: 'Day',
  repeatOn: [],
  repeatMonth: { type: 'day', value: moment().format('MMM DD,YYYY') },
  endsType: 'Never',
  endsDate: moment().format('MMM DD,YYYY'),
  endsAfter: 1
};

function CustomDateModal({
  submitForm,
  data,
  selectedValue,
  onClose,
  isRepeatEntry = false
}) {
  const classes = useStyles();
  const [initialEntry, setInitialEntry] = useState(
    selectedValue && selectedValue.values
      ? selectedValue.values
      : {
          ...entryObject,
          repeatMonth: {
            type: 'day',
            value: moment(
              data.values.entries[0].from.date,
              'MMM DD,YYYY'
            ).format('MMM DD,YYYY')
          }
        }
  );
  const [weekNamesData, setWeekNames] = useState(weekNames);
  const [monthsData, setMonthsData] = useState([]);
  const [monthDate, setMonthDate] = useState();

  const weekOfMonth = (m) => {
    const statOfM = moment(m, 'MMM DD,YYYY').startOf('month');
    const daysInMonth = statOfM.daysInMonth();
    let weekDayNumber = 0;
    for (let i = 0; i < daysInMonth; i++) {
      const newMDate = moment(statOfM, 'MMM DD,YYYY').add(i, 'days');
      const diffDateInDay = moment(m, 'MMM DD,YYYY').diff(newMDate, 'days');
      if (
        newMDate.format('ddd') === moment(m, 'MMM DD,YYYY').format('ddd') &&
        diffDateInDay >= 0
      ) {
        weekDayNumber = weekDayNumber + 1;
      }
    }
    return weekDayNumber;
  };

  useEffect(() => {
    let initialEntryValueForMonth = initialEntry;
    if (
      selectedValue &&
      selectedValue.values &&
      selectedValue.values.repeatOn &&
      selectedValue.values.repeatOn.length
    ) {
      const entryDay = moment(
        data.values.entries[0].from.date,
        'MMM DD,YYYY'
      ).format('ddd');
      const updatedWeek = weekNamesData.map((week) => {
        return {
          ...week,
          selected:
            selectedValue.values.repeatOn.includes(week.value) ||
            entryDay === week.value
              ? true
              : false
        };
      });
      setWeekNames(updatedWeek);
      setInitialEntry({
        ...initialEntry,
        repeatOn: _.uniq([...initialEntry.repeatOn, entryDay])
      });
    } else {
      const fromDate = moment(
        data.values.entries[0].from.date,
        'MMM DD,YYYY'
      ).format('MMM DD,YYYY');
      const endsDate =
        selectedValue.values && selectedValue.values.endsDate
          ? moment(selectedValue.values.endsDate, 'MMM DD,YYYY').format(
              'MMM DD,YYYY'
            )
          : fromDate;
      const entryDay = moment(
        data.values.entries[0].from.date,
        'MMM DD,YYYY'
      ).format('ddd');
      const updatedWeek = weekNamesData.map((week) => {
        return {
          ...week,
          selected:
            moment(fromDate).format('ddd') === week.value ||
            entryDay === week.value
              ? true
              : false
        };
      });
      setWeekNames(updatedWeek);
      setInitialEntry({
        ...initialEntry,
        endsDate,
        repeatOn: _.uniq([moment(fromDate).format('ddd'), entryDay])
      });
      initialEntryValueForMonth = {
        ...initialEntry,
        endsDate,
        repeatOn: [moment(fromDate).format('ddd')]
      };
    }
    setMonthData(initialEntryValueForMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setMonthData = (initialEntryValueForMonth) => {
    const fromDate = moment(
      data.values.entries[0].from.date,
      'MMM DD,YYYY'
    ).format('MMM DD,YYYY');
    if (
      selectedValue &&
      selectedValue.values &&
      selectedValue.values.repeatMonth &&
      selectedValue.values.repeatMonth.type
    ) {
      setMonthDate(selectedValue.values.repeatMonth.value);
      setMonthsData([
        {
          type: 'day',
          value: `Monthly on day ${moment(
            selectedValue.values.repeatMonth.value
          ).format('DD')}`
        },
        {
          type: 'week',
          value: `Monthly on the ${
            WeekString[weekOfMonth(selectedValue.values.repeatMonth.value)]
          } ${moment(selectedValue.values.repeatMonth.value).format('dddd')}`
        }
      ]);
    } else {
      setMonthDate(fromDate);
      setInitialEntry({
        ...initialEntryValueForMonth,
        repeatMonth: {
          type: 'day',
          value: moment(fromDate).format('MMM DD,YYYY')
        }
      });
      setMonthsData([
        {
          type: 'day',
          value: `Monthly on day ${moment(fromDate).format('DD')}`
        },
        {
          type: 'week',
          value: `Monthly on the ${WeekString[weekOfMonth(fromDate)]} ${moment(
            fromDate
          ).format('dddd')}`
        }
      ]);
    }
  };

  const validationSchema = Yup.object().shape({
    repeatDay: Yup.string().trim(),
    repeatType: Yup.string().trim(),
    repeatOn: Yup.array(),
    endsType: Yup.string().trim(),
    endsDate: Yup.string().trim(),
    endsAfter: Yup.string().trim()
  });

  const handleWeekClick = (selectedIndex, values, setFieldValue) => {
    const fromDate = moment(
      data.values.entries[0].from.date,
      'MMM DD,YYYY'
    ).format('ddd');
    const updateWeeks = weekNamesData.map((week, index) => {
      if (selectedIndex === index) {
        if (week.selected) {
          const findRepeatIndex = _.findIndex(
            values.repeatOn,
            (item) => item === week.value
          );
          if (
            findRepeatIndex > -1 &&
            values.repeatOn.length > 1 &&
            values.repeatOn[findRepeatIndex] !== fromDate
          ) {
            values.repeatOn.splice(findRepeatIndex, 1);
          }
        } else {
          values.repeatOn.push(week.value);
        }
        return {
          ...week,
          selected: week.selected
            ? week.value === fromDate
              ? true
              : false
            : true
        };
      } else {
        return { ...week };
      }
    });

    let selectedWeekCount = 0;
    updateWeeks.map((week, index) => {
      if (week.selected) {
        selectedWeekCount++;
      }
    });
    if (selectedWeekCount > 0) {
      setFieldValue('repeatOn', values.repeatOn);
      setWeekNames(updateWeeks);
    }
  };

  const handleDateChange = (e, values) => {
    // const updateInitialValue = values.endsDate(moment(e));
    setInitialEntry({ ...values, endsDate: moment(e).format('MMM DD,YYYY') });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialEntry}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          submitForm({ data, values });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue
        }) => {
          return (
            <>
              <form noValidate onSubmit={handleSubmit}>
                <div className={classes.modalBox}>
                  <Typography className={classes.modalTitle}>
                    Set Recurrence
                  </Typography>
                  <div
                    className={clsx(
                      classes.displayFlex,
                      // classes.justifySpace,
                      classes.alignCenter,
                      classes.mt10
                    )}
                  >
                    <Typography className={classes.repeatEveryText}>
                      Repeat every
                    </Typography>
                    <FormControl
                      variant="outlined"
                      style={{ width: '20%', marginLeft: 17, marginRight: 17 }}
                      className={classes.numericInputClass}
                    >
                      <TextField
                        id="outlined-number"
                        type="number"
                        variant="outlined"
                        name={`repeatDay`}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (e.target.value && e.target.value !== '0') {
                            setFieldValue(
                              `repeatDay`,
                              e.target.value.replace(/[^0-9]/g, '')
                            );
                          }
                        }}
                        InputProps={{ inputProps: { min: 1 } }}
                        value={values.repeatDay}
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formDayLabelControl}
                    >
                      <Select
                        labelId="demo-simple-select-outlined-for"
                        id="demo-simple-select-outlined"
                        name={`repeatType`}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={values.repeatType}
                      >
                        {/* <MenuItem value="">
                          <em>None</em>
                        </MenuItem> */}
                        <MenuItem value="Day">Days</MenuItem>
                        <MenuItem value="Week">Weeks</MenuItem>
                        <MenuItem value="Month">Months</MenuItem>
                        <MenuItem value="Year">Years</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {values.repeatType === 'Week' ? (
                    <div
                      className={clsx(
                        classes.displayFlex,
                        classes.flexColumn,
                        classes.justifySpace,
                        classes.mt24
                      )}
                    >
                      <Typography className={classes.repeatEveryText}>
                        Repeat on
                      </Typography>
                      <div
                        className={clsx(
                          classes.displayFlex,
                          // classes.justifySpace,
                          classes.mt10
                        )}
                      >
                        {weekNamesData.map((name, idx) => (
                          <div
                            style={{
                              marginLeft: idx !== 0 && 5
                            }}
                            className={
                              name.selected
                                ? classes.selectedWeek
                                : classes.notSelectedWeek
                            }
                            onClick={() =>
                              handleWeekClick(idx, values, setFieldValue)
                            }
                            key={`week-${idx + 1}`}
                          >
                            <Typography className={classes.dayTitle}>
                              {' '}
                              {name.label}{' '}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {values.repeatType === 'Month' ? (
                    <FormControl
                      variant="outlined"
                      className={classes.formMonthLabelControl}
                    >
                      <Select
                        labelId="demo-simple-select-outlined-for"
                        id="demo-simple-select-outlined"
                        name={`repeatMonth.type`}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (e.target.value === 'week') {
                            setFieldValue('repeatMonth', {
                              type: 'week',
                              value: moment(monthDate).format('MMM DD,YYYY')
                            });
                          } else {
                            setFieldValue('repeatMonth', {
                              type: 'day',
                              value: moment(monthDate).format('MMM DD,YYYY')
                            });
                          }
                        }}
                        value={values.repeatMonth && values.repeatMonth.type}
                      >
                        {monthsData.map((month, index) => (
                          <MenuItem key={`month-${index}`} value={month.type}>
                            {month.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <></>
                  )}
                  <div
                    className={clsx(
                      classes.displayFlex,
                      classes.justifySpace,
                      classes.alignCenter,
                      classes.mt24
                    )}
                  >
                    <FormControl component="fieldset">
                      <FormLabel component="legend" className={classes.label}>
                        <Typography className={classes.repeatEveryText}>
                          Ends
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        aria-label="endsType"
                        name="endsType"
                        onChange={(e) => {
                          handleChange(e);
                          if (e.target.value === 'Never') {
                            setFieldValue(
                              'endsDate',
                              moment().format('MMM DD,YYYY')
                            );
                            setFieldValue('endsAfter', 1);
                          } else if (e.target.value === 'On') {
                            setFieldValue(
                              'endsDate',
                              moment().format('MMM DD,YYYY')
                            );
                            setFieldValue('endsAfter', 1);
                          } else if (e.target.value === 'After') {
                            setFieldValue(
                              'endsDate',
                              moment().format('MMM DD,YYYY')
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        value={values.endsType}
                      >
                        <FormControlLabel
                          value={'Never'}
                          control={<Radio color="primary" />}
                          label={'Never'}
                        />
                        <div
                          className={clsx(
                            classes.displayFlex,
                            classes.alignCenter
                          )}
                        >
                          <FormControlLabel
                            value={'On'}
                            control={<Radio color="primary" />}
                            label={'On'}
                            className={classes.flex1}
                          />
                          <div className={classes.flex2}>
                            <DatePicker
                              disabled={
                                values.endsType === 'Never' ||
                                values.endsType === 'After'
                              }
                              name={`endsDate`}
                              onBlur={handleBlur}
                              onChange={(date) =>
                                handleDateChange(date, values)
                              }
                              value={values.endsDate}
                              // style={{ width: '60%' }}
                              format="MMM DD,YYYY"
                              minDate={data.values.entries[0].from.date || ''}
                              minDateMessage=""
                              className={clsx(
                                classes.formDateControlFromBordered
                              )}
                            />
                          </div>
                        </div>
                        <div
                          className={clsx(
                            classes.displayFlex,
                            classes.alignCenter
                          )}
                        >
                          <FormControlLabel
                            value={'After'}
                            control={<Radio color="primary" />}
                            label={'After'}
                            className={classes.flex1}
                            style={{ marginRight: 25 }}
                          />
                          <FormControl
                            variant="outlined"
                            className={clsx(
                              classes.numericInputClass,
                              classes.flex2
                            )}
                          >
                            <TextField
                              id="outlined-number-endDate"
                              type="number"
                              style={{ width: 75 }}
                              variant="outlined"
                              name={`endsAfter`}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                if (e.target.value && e.target.value !== '0') {
                                  setFieldValue(
                                    `endsAfter`,
                                    e.target.value.replace(/[^0-9]/g, '')
                                  );
                                }
                              }}
                              InputProps={{ inputProps: { min: 1 } }}
                              value={values.endsAfter}
                              disabled={
                                values.endsType === 'Never' ||
                                values.endsType === 'On'
                              }
                            />
                          </FormControl>
                          <Typography
                            style={{
                              marginLeft: 22,
                              fontSize: 14,
                              color: colors.primaryDark
                            }}
                          >
                            occurrences
                          </Typography>
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div
                    className={clsx(
                      classes.displayFlex,
                      classes.justifyFlexEnd,
                      classes.alignCenter
                    )}
                    style={{
                      position: 'absolute',
                      width: 'inherit',
                      marginTop: 430
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={isRepeatEntry}
                    >
                      Continue
                    </Button>
                    <Button
                      variant="outlined"
                      style={{ marginLeft: 15 }}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default CustomDateModal;
