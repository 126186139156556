import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import lodash from 'lodash'
import { TableCell, TableRow, Typography, Checkbox, FormControlLabel, Grid, Divider } from "@material-ui/core";
import ConsoleLayout from "..";
import Dashboard from "src/views/layout/Dashboard";
import TableList from "src/components/TableList";
import apiIcon from "../../../assets/apiIcon.svg";
import DownloadArrowIcon from "../../../assets/Icons/downloadArrowIcon";
import useStyles from "./schoolIndex.styles";
import RightDrawer from "src/components/RightDrawer";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { IndeterminateCheckBoxOutlined, IndeterminateCheckBox } from "@material-ui/icons";
import { getSchoolsByFilter, fetchLanguageList, generateStudentReport } from "src/services/schools";
import { loaderStart, loaderStop } from "src/redux/actions/appActions";
import { withFirebase } from "src/utils/firebase";

const columns = [
    {
        id: 'schoolName',
        label: 'School',
        minWidth: 120,
        width: '20%'
    },
    {
        id: 'pack1',
        label: 'Pack1',
        minWidth: 40,
        width: '8%'
    },
    {
        id: 'pack2',
        label: 'Pack2',
        minWidth: 40,
        width: '8%'
    },
    {
        id: 'paidTranslationEnabled',
        label: 'P2 ON',
        minWidth: 100,
        width: '15%'
    },
    {
        id: 'pop',
        label: 'Pop.',
        minWidth: 40,
        width: '8%'
    },
    {
        id: 'active',
        label: 'Active',
        minWidth: 40,
        width: '8%'
    },
    {
        id: 'inActive',
        label: 'Inactive',
        minWidth: 40,
        width: '8%'
    },
    {
        id: 'opp',
        label: 'Opp.',
        minWidth: 40,
        width: '8%'
    },
    {
        id: 'report',
        label: 'Report',
        minWidth: 50,
        width: '5%'
    },
    {
        id: 'apiSync',
        label: '',
        align: 'center',
        format: 'icon',
        iconPosition: 'center',
        icon: false
      }
]

function School({firebase}){
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [schoolsData, setSchoolsData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [tableRecords, setTableRecords] = useState(0);
    const [debouncedSearchText, setDebouncedSearchText] = useState('');
    const [rowsCountPerPage, setRowsCountPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState();
    const [schoolType, setSchoolType] = useState("all");
    const [languageList, setLanguageList] = useState([]);

    useEffect(() => {
        dispatch(loaderStart())
        getSchoolsByFilter({
          pageSize: rowsCountPerPage,
          searchText: debouncedSearchText,
          createdBy: schoolType === "all" ? "" : schoolType
        }).then(result => {
          setSchoolsData(result.data)
          setTableRecords(result.totalRecords)
        }).catch(error => {
          console.error(error)
        }).finally(() => {
          dispatch(loaderStop())
        });
    }, [debouncedSearchText, rowsCountPerPage, schoolType]);

    const searchDebouncedFunction = useCallback(
		lodash.debounce((debouncedValue) => {
			setDebouncedSearchText(debouncedValue);
		}, 1000),
		[],
	);

    useEffect(() => {
        const fetchLanguages = async () => {
          const data = await fetchLanguageList();
          const sortedData = sortLanguages(data);
          setLanguageList(sortedData);
        }
        fetchLanguages();
      },[]);


    const sortLanguages = (array) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a,b) => {
            if(a[0].code === "en"){
            return -1;
            }else if(b[0].code === "en"){
            return 1;
            }else {
            if(a[0].name < b[0].name) { return -1; }
            if(a[0].name > b[0].name) { return 1; }
            }
            return 0;
    });
    return stabilizedThis.map((el) => el[0]);
    };


  // Search text function
  const searchChangeHandler = (e) => {
    setCurrentPage((prev) => !prev);
    const { value } = e.target;
    setSearchText(value.toLowerCase().trim());
    searchDebouncedFunction(value.toLowerCase().trim())
  };

    const schoolTypeHandler = (e) => {
        setSchoolType(e.target.value);
    };

      // Page change next/prev function
  const handlePageChange = async (currentPage, newPage, rowsPerPage) => {
    dispatch(loaderStart())
    try {
      const result = await getSchoolsByFilter({
        pageSize: rowsPerPage,
        pageNo: newPage + 1,
        searchText: debouncedSearchText
      });
      setSchoolsData(result.data)
      setTableRecords(result.totalRecords)
      dispatch(loaderStop())
    } catch(error) {
      console.error(error)
      dispatch(loaderStop())
    }
  };

  // Page size change function
  const handleRowsPerPageChange = async (rowsPerPage) => {
    dispatch(loaderStart());
    try {
      const result = await getSchoolsByFilter({
        pageSize: rowsPerPage,
        searchText: searchText
      });
      setSchoolsData(result.data);
      setTableRecords(result.totalRecords);
      dispatch(loaderStop());
        } catch(error) {
        console.error(error);
        dispatch(loaderStop());
        }
    };

    const downloadSchoolReport = async(school) => {
        dispatch(loaderStart());
        const response = await generateStudentReport(school.id);
        if(response.message){
            const url = await firebase.getDownloadURLForSchoolReport(school.schoolName);
            window.open(url, "_blank");
            dispatch(loaderStop())
        }
    };

    const CellItem = ({ row, column }) => {
        const classes = useStyles();
        const value = row[column.id];
        if(column.id === "schoolName"){
            return (
                <span className={classes.schoolName}>
                    {value}
                </span>
            )
        }
        if(column.id === "pack1"){
            const active = row.selectedFreeLanguageCodes.length > 1;
            return (
                <span className={active ? classes.active : classes.inactive }>
                    {row.selectedFreeLanguageCodes.length > 1 ? "Active": "Inactive"}
                </span>
            )
        }
        if(column.id === "pack2"){
            const active = row.selectedPaidLanguageCodes.length > 1;
            return (
                <span className={active ? classes.active : classes.inactive }>
                    {row.selectedPaidLanguageCodes.length > 1 ? "Active": "Inactive"}
                </span>
            )
        }

        if(column.id === "paidTranslationEnabled"){
            const active = row.paidTranslationEnabled;
            return (
                <span className={active ? classes.active : classes.inactive }>
                    {active ? "Yes": "No"}
                </span>
            )
        }

        if(column.id === "pop"){
            return (
                <span>
                    {row.studentReport.apiStudent}
                </span>
            )
        }

        if(column.id === "active"){
            return (
                <span>
                    {row.studentReport.active}
                </span>
            )
        }

        if(column.id === "inActive"){
            return (
                <span>
                    {row.studentReport.inActive}
                </span>
            )
        }

        if(column.id === "opp"){
            return (
                <span>
                    {row.studentReport.nonRegistered}
                </span>
            )
        }

        if (column.id === "report" ) {
            return <div className={classes.downloadArrowContainer}><DownloadArrowIcon className={"downloadArrow"} onClick={(e) => {
                e.stopPropagation();
                downloadSchoolReport(row);
            }}/></div>;
        }

        if (column.id === 'apiSync' && row?.createdByApiSync) {
            return <img src={apiIcon} style={{paddingRight:"1rem",float:"right"}} />;
        }
    }

    const  TableRowItem = ({row, columns}) => {
        const classes = useStyles();
        return (
            <TableRow
                hover
                tabIndex={-1}
                className={classes.tableRow}
                onClick={() => openRightDrawer(row)}
            >
                {columns.map((column) => {
                    return (
                        <TableCell
                            key={column.id}
                            align={column.align}
                            className={classes.tableCell}
                            style={{ width: column.width, padding: "16px 16px 16px 0 !important" }}
                        >
                            <CellItem row={row} column={column}/>
                        </TableCell>
                    )
                })}
            </TableRow>
        )
    }

    const openRightDrawer = useCallback((data) => {
        setIsOpen(true);
        setSelectedSchool(data);
    },[])

    const handleClose = () => {
        setIsOpen(false);
        setSelectedSchool();
    }

    return (
        <Dashboard>
            <Typography
                variant="h2"
                color="textPrimary"
                style={{ marginBottom: 20 }}
                >
                Console
            </Typography>
            <ConsoleLayout>
                <div className={classes.root}>
                    <TableList
                        columns={columns}
                        currentPage={currentPage}
                        rows={schoolsData || []}
                        totalCount={tableRecords || 0}
                        className={classes.table}
                        searchPlaceholderText={'Search by school name'}
                        schoolType={schoolType}
                        schoolTypeHandler={schoolTypeHandler}
                        searchChangeHandler={searchChangeHandler}
                        tableRow={(row, index) => (
                            <TableRowItem
                              key={index}
                              columns={columns}
                              row={row}
                            />
                        )}
                        handlePageChange={handlePageChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        setRowsCountPerPage={setRowsCountPerPage}
                    />
                </div>
            </ConsoleLayout>
            {isOpen && <RightDrawer isOpen={isOpen} onClose={handleClose} className={classes.list}>
                <Typography className={classes.title}>{selectedSchool.schoolName}</Typography>
                <Divider className={classes.divider} />
                <Typography className={classes.context}>Languages in use (this month)</Typography>
                <Grid container className={classes.pack1Container}>
                    <Typography className={classes.label}>Pack 1</Typography>
                    {!!selectedSchool && languageList.filter(l => l.pack === "free").map(language => {
                        const selected = selectedSchool.selectedFreeLanguageCodes.includes(language.code);
                        return (
                            <Grid key={language.label} lg={6} md={6} xs={6} sm={6} className={classes.checkboxGrid}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selected}
                                        icon={selected ? <CheckBoxOutlineBlankIcon/> : <IndeterminateCheckBoxOutlined />}
                                        checkedIcon={selected ? <CheckBoxIcon fontSize="small" />: <IndeterminateCheckBox fontSize="small" />}
                                        indeterminate={!selected}
                                        className={
                                            classes.controlCheck
                                          }
                                          classes={{
                                            root: classes.checkbox,
                                            checked: classes.checked,
                                            indeterminate: classes.indeterminateCheckbox
                                          }}
                                    />
                                }
                                label={language.name}
                            />
                          </Grid>
                        )
                    })}
                </Grid>
                <Divider className={classes.divider1} />
                <Grid container className={classes.pack1Container}>
                    <Typography className={classes.label}>Pack 2</Typography>
                    {!!selectedSchool && languageList.filter(l => l.pack !== "free").map(language => {
                        const selected = selectedSchool.selectedPaidLanguageCodes.includes(language.code);
                        return (
                            <Grid key={language.label} lg={6} md={6} xs={6} sm={6} className={classes.checkboxGrid}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selected}
                                        icon={selected ? <CheckBoxOutlineBlankIcon/> : <IndeterminateCheckBoxOutlined />}
                                        checkedIcon={selected ? <CheckBoxIcon fontSize="small" />: <IndeterminateCheckBox fontSize="small" />}
                                        indeterminate={!selected}
                                        className={
                                            classes.controlCheck
                                          }
                                          classes={{
                                            root: classes.checkbox,
                                            checked: classes.checked,
                                            indeterminate: classes.indeterminateCheckbox
                                          }}
                                    />
                                }
                                label={language.name}
                            />
                          </Grid>
                        )
                    })}
                </Grid>
            </RightDrawer>}
        </Dashboard>
    )
}

export default withFirebase(School);