import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { withFirebase, FIREBASE_API_URL } from '../../../utils/firebase';
import { routes } from '../../../utils/constant';
import { loaderStop, loaderStart, addMessage } from '../../../redux/actions/appActions';
import { loginSuccess, sessionInative } from '../../../redux/actions/authActions';
import { generateToken, sendVerificationCode } from 'src/services/auth';
import { LOCAL_STORAGE_KEY, MESSAGE_TYPES } from 'src/constants/common';

// SCHEMA VALIDATOR FOR LOGIN FORM
const loginFormSchema = Yup.object().shape({
  email: Yup.string().trim()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
  password: Yup.string().trim().matches(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
    'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.'
  ).required('Password is required')
});

function LoginForm({ firebase }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [errorFromFirebase, setErrorFromFirebase] = useState('');
  const onTogglePasswordFieldType = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
    } else {
      setPasswordFieldType('password');
    }
  };

  const _handleFormSubmit = (submittedFormFieldValues) => {
    const { email, password } = submittedFormFieldValues;
    dispatch(loaderStart());
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((response) => {
        const userId = response?.user?.uid;
        localStorage.setItem("emailID",email);
        localStorage.setItem("userID",userId);

        generateToken(userId).then(result => {
          localStorage.setItem(LOCAL_STORAGE_KEY.AUTH_TOKEN, result);
        }).catch(error => {
          console.error(error)
        })

        firebase.isPasswordExpired({userId}).then((response) => {
          if(response === false){
            firebase
            .getUserByID(userId)
            .then(async (doc) => {
              const userData = doc.data();
              if (
                userData &&
                ['Agent', 'Manager', 'Admin'].includes(userData.role)
              ) {
                try {
                  const result = await sendVerificationCode(email);
                  if (result.message) {

                    localStorage.setItem(LOCAL_STORAGE_KEY.IS_CODE_VERIFIED, false);
                    localStorage.setItem(LOCAL_STORAGE_KEY.USER_ID, userId);

                    window.sessionStorage.setItem(LOCAL_STORAGE_KEY.IS_CODE_VERIFIED, true);

                    dispatch(loginSuccess({ email }));
                    dispatch(sessionInative({ activeStatus: false}));
                    dispatch(loaderStop());

                    history.push(routes.codeVerify);
                  }
                } catch(error) {
                  const errorMessage = _.get(error.response.data, 'message', '');
                  setErrorFromFirebase(errorMessage);
                  dispatch(loaderStop());
                  console.log('error', error)
                }
              } else {
                firebase.doSignOut();
                setErrorFromFirebase('Permission Denied');
                dispatch(loaderStop());
              }
            })
            .catch(function (error) {
              setErrorFromFirebase('Something weng wrong! Please try again.');
              console.log('Error getting cached document:', error);
              firebase.doSignOut();
              dispatch(loaderStop());
            });
          }else{
            setErrorFromFirebase('Password Expired! Please Reset and try again.');
            dispatch(loaderStop());
            dispatch(addMessage('Password Expired! Please Reset and try again.', MESSAGE_TYPES.ERROR));
            history.push({
              pathname: routes.resetPassword,
              state: email
            });
          }
        });
      })
      .catch((error) => {
        switch (error.code) {
          case 'auth/user-not-found':
            setErrorFromFirebase('This email is not registered');
            break;
          case 'auth/wrong-password':
            setErrorFromFirebase('Invalid credentials');
            break;
          case 'auth/network-request-failed':
            setErrorFromFirebase('Network error. Please check your connection');
            break;
          case 'auth/internal-error':
              setErrorFromFirebase('Invalid credentials');
              break;
          default:
            const errorMessage = _.get(error, 'message', '');
            setErrorFromFirebase(errorMessage);
            break;
        }

        dispatch(loaderStop());
      });
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={loginFormSchema}
      onSubmit={(values) => {
        setErrorFromFirebase('');
        _handleFormSubmit(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => {
        let isSubmitButtonDisabled =
          _.isEmpty(values.email) || _.isEmpty(values.password);
        if(_.isEmpty(values.password)){
          setErrorFromFirebase('')
        }
        if (
          !_.isEmpty(touched) &&
          !isSubmitButtonDisabled &&
          !_.isEmpty(errors)
        ) {
          isSubmitButtonDisabled = true;
        }
        return (
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              error={
                Boolean(touched.email && errors.email) ||
                !_.isEmpty(errorFromFirebase)
              }
              fullWidth
              // autoFocus
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
              autoComplete="off"
            />
            <TextField
              error={
                Boolean(touched.password && errors.password) ||
                !_.isEmpty(errorFromFirebase)
              }
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              autoComplete="off"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={passwordFieldType}
              value={values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => onTogglePasswordFieldType()}
                      tabIndex="-1"
                    >
                      <img
                        alt="Password Requirement"
                        src={
                          passwordFieldType === 'text'
                            ? '/static/images/password-hide.png'
                            : '/static/images/password-see.png'
                        }
                        width="22"
                        height="22"
                      />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {errorFromFirebase && (
              <Box ml={2} mb={4}>
                <FormHelperText error>{errorFromFirebase}</FormHelperText>
              </Box>
            )}
            <Box mt={2} style={{ marginTop: 24 }}>
              <Button
                disabled={isSubmitButtonDisabled}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color={isSubmitButtonDisabled ? 'secondary' : 'primary'}
              >
                Sign In
              </Button>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

export default withFirebase(LoginForm);
