import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

// Assets
import INFO from 'src/assets/info.svg';
import Pocket from 'src/assets/pocket.svg';
import TranslationIcon from 'src/assets/translate.svg';
import { RightArrow, SchoolsIcon } from 'src/assets/Icons';
import { HandBook, Edit } from 'src/assets';
import api_icon from 'src/assets/apiIcon.svg';
import schoolBytes from 'src/assets/schoolByte2.webp';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import studentReportingIcon from 'src/assets/icn-student-reporting.svg';
import { editSchoolProfileStyles } from 'src/styles/schoolStyle/editSchoolProfileStyles';

// Material-UI Components
import {
  Paper,
  Typography,
  Button,
  Divider,
  FormControl,
  TextField,
  CircularProgress,
  Link,
  Backdrop
} from '@material-ui/core';

// DraftJS
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

// Components
import Profile from './Profile';
import Calendar from './Calendar';
import Modal from 'src/components/Modal';
import RightDrawer from 'src/components/RightDrawer';
import StepperComponent from 'src/components/StepperComponent';
import NavTab from './NavTab';

// Layouts
import Dashboard from '../layout/Dashboard';

// Utils
import { routes } from 'src/utils/constant';

// Redux-Actions
import {
  setSchool,
  setSchoolPreserveValues
} from 'src/redux/actions/schoolActions';
import {
  loaderStop,
  addMessage,
  loaderStart
} from 'src/redux/actions/appActions';
import { getSchoolList, updateSchoolList } from 'src/redux/actions/authActions';

// Services
import {
  addSchoolFormDetailsOrUpdateSchool,
  deleteSchool,
  getSchoolByID,
  getSchools
} from 'src/services/schools';

// Utils
import { withFirebase } from 'src/utils/firebase';
import Translation from './Translation';
import { MESSAGE_TYPES } from 'src/constants/common';

function getSteps() {
  return ['Profile', 'Years', 'Translation'];
}

function ColorLibStepIcon(props) {
  const classes = editSchoolProfileStyles();
  const { active, translationPackEnabled } = props;
  const icons = {
    1: (
      <div
        className={classes.iconStyle}
        style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
      >
        <img src={INFO} alt="Search" className={classes.image} />
      </div>
    ),
    2: (
      <div
        className={classes.iconStyle}
        style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
      >
        <img src={Pocket} alt="Search" className={classes.image} />
      </div>
    ),
    3: (
      <div
        className={translationPackEnabled ? classes.translationIconStyle : ''}
      >
        <div
          className={classes.iconStyle}
          style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
        >
          <img src={TranslationIcon} alt="Search" className={classes.image} />
        </div>
      </div>
    )
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function EditSchoolProfileView({ firebase }) {
  const classes = editSchoolProfileStyles();

  const history = useHistory();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [isUpdate, setUpdate] = useState(false);
  const [isNotDeleteSchool, setNotDeleteSchool] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isCurrentSchoolLoading, setCurrentSchoolLoading] = useState(false);
  const [isOpenConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [deleteText, setDeleteText] = useState('');
  const [handleCancelClick, setHandleCancelClick] = useState(false);
  const [translationWarningModal, setTranslationWarningModal] = useState({});
  const [translationOffText, setTranslationOffText] = useState('');
 
  const currentSchool = useSelector((state) => state.school.currentSchool);
  const preserveValuesSchoolData = useSelector(
    (state) => state.school.preserveValues
  );
  const isLoading = useSelector((state) => state.app.isLoading);

  const getSchoolId = () => {
    return window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1
    );
  };
  const schoolID = useMemo(() => getSchoolId(), []);

  const [isEditMode, setEditMode] = useState(false);
  const [memberData, setMemberData] = useState(false);
  const [defaultNote, setDefaultNote] = useState('');
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [openStudentReportPopUp, setOpenStudentReportPopUp] = useState(false);
  const [translationPackEnabled, setTranslationPackEnabled] = useState(false);

  useEffect(() => {
    setTranslationPackEnabled(currentSchool?.paidTranslationEnabled);
  }, [currentSchool]);

  const handleToggleTranslationSwitch = (callback, args) => {
    if (translationPackEnabled) {
      setTranslationWarningModal({
        isOpen: true,
        callback,
        args
      });
    }
  };

  const onCloseForm = () => {
    const defaultData = draftToHtml(
      convertToRaw(defaultNote.getCurrentContent())
    );
    const memberNotes = memberData.notes;
    if (defaultData === memberNotes) {
      // handleClose();
      onClose();
    } else if (
      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
      !memberNotes
    ) {
      onClose();
    } else if (memberNotes === '') {
      // handleClose();
      onClose();
    } else {
      //
    }
  };

  const onClose = useCallback(() => {
    setEditMode(false);
    setOpenDrawer(false);
  }, [setOpenDrawer]);

  const openRightDrawer = useCallback(
    (data) => {
      setMemberData(data);
      const contentBlock = htmlToDraft(data.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      setOpenDrawer(true);
    },
    [setOpenDrawer, currentSchool]
  );

  const handleClose = useCallback(() => {
    setEditMode(false);
  }, []);

  const onEditorChange = (value) => {
    const editorData = draftToHtml(convertToRaw(value.getCurrentContent()));
    setMemberData({ ...memberData, notes: editorData });
  };

  const onSaveChanges = async () => {
    // PHASE2/CHANGES/SCHOOL
    dispatch(loaderStart());

    const payload = {
      notes: memberData?.notes
    };

    try {
      await addSchoolFormDetailsOrUpdateSchool(currentSchool.id, payload);
      dispatch(loaderStop());
      dispatch(setSchool(payload));
      dispatch(setSchoolPreserveValues(payload));
      dispatch(addMessage('Changes saved'));
      const contentBlock = htmlToDraft(payload.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      handleClose();
    } catch (error) {
      console.error(error);
      dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  useEffect(() => {
    if (!currentSchool.schoolName && schoolID) {
      setCurrentSchoolLoading(true);
      dispatch(loaderStart());
      // PHASE2/CHANGES/SCHOOL

      getSchoolByID(schoolID)
        .then((school) => {
          if (school) {
            dispatch(setSchool(school));
            dispatch(setSchoolPreserveValues(school));
          }
          dispatch(loaderStop());
          setCurrentSchoolLoading(false);
        })
        .catch((error) => {
          console.error(error);
          dispatch(addMessage('Error on getting school', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
          setCurrentSchoolLoading(false);
        });
    }
  }, [schoolID, dispatch]);

  const { schoolName } = useMemo(() => {
    if (currentSchool) {
      return {
        schoolName: currentSchool.schoolName,
        id: currentSchool.id
      };
    }
    return {
      schoolName: '',
      id: ''
    };
  }, [currentSchool]);

  const getFormData = async (formData, buttonText) => {
    const schoolData = {
      ...currentSchool,
      ...formData
    };
    const compareData1 = JSON.parse(JSON.stringify(preserveValuesSchoolData));
    const compareData2 = JSON.parse(JSON.stringify(schoolData));
    delete compareData1?.isSchoolFormUpdated;
    delete compareData2?.isSchoolFormUpdated;
    const isSchoolFormUpdated = !_.isEqual(compareData2, compareData1);

    if (activeStep < 1 && buttonText === 'Next') {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      dispatch(setSchool({ ...schoolData, isSchoolFormUpdated }));
    } else if (activeStep === 1 && buttonText === 'Next') {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      dispatch(setSchool({ ...schoolData, isSchoolFormUpdated }));
    } else if (activeStep === 1 && buttonText === 'Back') {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      dispatch(setSchool({ ...schoolData, isSchoolFormUpdated }));
    } else if (activeStep === 2 && buttonText === 'Back') {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      dispatch(setSchool({ ...schoolData, isSchoolFormUpdated }));
    } else if (buttonText === 'Save') {
      const newReduxSchoolData = {
        ...schoolData,
        isSchoolFormUpdated: false
      };
      dispatch(setSchool(newReduxSchoolData));
      dispatch(setSchoolPreserveValues(newReduxSchoolData));
      dispatch(loaderStart());

      // PHASE2/CHANGES/SCHOOL

      const payload = {
        schoolName: schoolData?.schoolName,
        country: schoolData?.country,
        timezone: schoolData?.timezone,
        state: schoolData?.state,
        notificationsTiming: schoolData?.notificationsTiming,
        freeCalendars: schoolData?.freeCalendars,
        notes: schoolData?.notes,
        paidTranslationEnabled: schoolData?.paidTranslationEnabled
      };

      try {
        await addSchoolFormDetailsOrUpdateSchool(schoolData.id, payload);
        dispatch(loaderStop());
        dispatch(addMessage('School Updated!'));
        const yearsData = schoolData.freeCalendars.map((data) => {
          return {
            label: `Year ${data}`,
            value: data
          };
        });
        const schoolUpdateData = {
          name: schoolData.schoolName,
          id: schoolData.id,
          years: yearsData
        };
        dispatch(updateSchoolList(schoolUpdateData));
      } catch (error) {
        console.error(error);
        dispatch(addMessage('School was not updated. Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      }
    }
  };

  const handleCancelDisable = useCallback(
    (isUpdate) => {
      setUpdate(isUpdate);
    },
    [setUpdate]
  );

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [setActiveStep]);

  const handleBack = useCallback(() => {
    setHandleCancelClick(true);
  }, [setActiveStep, dispatch]);

  const handleReset = useCallback(() => {
    setActiveStep(0);
  }, [setActiveStep]);

  const getStepContent = (
    step,
    getFormData,
    getTranslationStatus,
    onToggleTranslationSwitch
  ) => {
    switch (step) {
      case 0:
        return (
          <Profile
            submitForm={getFormData}
            handleCancelDisable={handleCancelDisable}
            isEdit={true}
            accountFlag={true}
            isOpenDeleteModal={isOpenDeleteModal}
            setOpenDeleteModal={setOpenDeleteModal}
            handleCancelClick={handleCancelClick}
            setHandleCancelClick={setHandleCancelClick}
          />
        );
      case 1:
        return (
          <Calendar
            submitForm={getFormData}
            isEdit={true}
            handleCancelDisable={handleCancelDisable}
            accountFlag={true}
            handleCancelClick={handleCancelClick}
            setHandleCancelClick={setHandleCancelClick}
          />
        );
      case 2:
        return (
          <Translation
            isEdit={true}
            onSave={getFormData}
            getTranslationStatus={getTranslationStatus}
            onToggleTranslationSwitch={onToggleTranslationSwitch}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  const deleteSchoolEntries = async (id) => {
    await firebase.deleteSchoolEntries(id);
  };

  const getAllSchools = async () => {
    // PHASE2/CHANGES/SCHOOL

    try {
      const result = await getSchools();
      const schools = [];
      result.forEach((school) => {
        const yearData = school.freeCalendars.map((calendar) => ({
          label: `Year ${calendar}`,
          value: calendar
        }));

        schools.push({
          id: school.id,
          name: school.schoolName,
          years: yearData.length ? yearData : []
        });
      });
      dispatch(getSchoolList(schools));
    } catch (error) {
      console.error(error);
      dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
    }
  };

  const deleteSchoolHandle = async () => {
    dispatch(loaderStart());
    firebase
      .getFamilyBySchoolId(currentSchool.id)
      .then(function (querySnapshot) {
        const SchoolsData = querySnapshot.size;
        // querySnapshot.forEach(function (doc) {
        //   SchoolsData.push({ id: doc.id });
        // });
        if (!SchoolsData) {
          // PHASE2/CHANGES/SCHOOL

          deleteSchool(currentSchool.id)
            .then(() => {
              deleteSchoolEntries(currentSchool.id);
              dispatch(loaderStop());
              dispatch(addMessage('School Deleted!'));
              setOpenDeleteModal(false);
              setOpenConfirmDeleteModal(true);
              getAllSchools();
            })
            .catch(function (error) {
              console.error(error);
              dispatch(addMessage('School was not deleted. Please try again', MESSAGE_TYPES.ERROR));
              dispatch(loaderStop());
            });
        } else {
          dispatch(loaderStop());
          setOpenDeleteModal(false);
          setNotDeleteSchool(true);
        }
      })
      .catch(function (error) {
        console.error(error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  };

  const navTabsOptions = useMemo(() => {
    return [
      ...(currentSchool?.freeCalendars?.length
        ? [
            {
              label: 'Calendar',
              link: `${routes.schoolCalendar}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Groups',
        link: `${routes.schoolGroup}/${schoolID}`
      },
      {
        label: 'Students',
        link: `${routes.schoolStudent}/${schoolID}`
      },
      {
        divider: true
      },
      ...(currentSchool?.freeCalendars?.length
        ? [
            {
              label: 'Links',
              link: `${routes.schoolLinks}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Notes',
        handleTabClick: () => openRightDrawer(currentSchool)
      },
      {
        label: 'Files',
        link: `${routes.schoolFiles}/${schoolID}`
      },
      ...(currentSchool?.freeCalendars?.length
        ? [
            {
              label: 'Form',
              link: `${routes.schoolForm}/${schoolID}`
            }
          ]
        : []),
      {
        divider: true
      },
      {
        label: 'Account',
        link: `${routes.schoolAccount}/${schoolID}`
      }
    ];
  }, [currentSchool]);

  const getTranslationStatus = (value) => {
    setTranslationPackEnabled(value);
  };

  return (
    <React.Fragment>
      <Dashboard>
        <div className={classes.root}>
          <div>
            <Typography
              variant="body1"
              className={classes.bredthcrumbTitle}
              onClick={() => history && history.push(routes.schools)}
            >
              Schools
            </Typography>
            <RightArrow
              viewBox="0 0 24 24"
              height={24}
              width={24}
              style={{ fontSize: 22, verticalAlign: 'middle' }}
            />
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ marginBottom: 20, marginTop: 22 }}
          >
            {schoolName}
          </Typography>
          <Paper style={{ position: 'relative' }}>
            <img
              className={classes.reportImage}
              src={studentReportingIcon}
              alt="Student report"
              onClick={() => setOpenStudentReportPopUp(!openStudentReportPopUp)}
            />
            {openStudentReportPopUp ? (
              <article className={classes.studentReportPopUp}>
                <div style={{ fontWeight: 500, marginBottom: '10px' }}>
                  Student reporting
                </div>
                {currentSchool?.createdByApiSync ? (
                  <div className={classes.flexBoxBetween}>
                    <span>Population</span>
                    <span>{currentSchool?.studentReport?.apiStudent || 0}</span>
                  </div>
                ) : null}
                <div className={classes.flexBoxBetween}>
                  <span>Active</span>
                  <span>{currentSchool?.studentReport?.active || 0}</span>
                </div>
                <div className={classes.flexBoxBetween}>
                  <span>Inactive</span>
                  <span>{currentSchool?.studentReport?.inActive || 0}</span>
                </div>
                {currentSchool?.createdByApiSync ? (
                  <div className={classes.flexBoxBetween}>
                    <span>Opportunity</span>
                    <span>
                      {currentSchool?.studentReport?.nonRegistered || 0}
                    </span>
                  </div>
                ) : null}
              </article>
            ) : null}
            <section
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 20px 0'
              }}
            >
              <div>
                <NavTab
                  tabs={navTabsOptions}
                  selectedTab={isOpenDrawer ? 'Notes' : 'Account'}
                />
              </div>
              {currentSchool && currentSchool?.createdByApiSync && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '1rem'
                    // padding: '0 2rem'
                  }}
                >
                  <img src={schoolBytes} height="38px" />
                  <img src={api_icon} />
                </div>
              )}
            </section>
            <Divider />
            <div className={classes.stepperStyle}>
              {!isCurrentSchoolLoading ? (
                <StepperComponent
                  steps={getSteps()}
                  getStepContent={(step) =>
                    getStepContent(
                      step,
                      getFormData,
                      getTranslationStatus,
                      handleToggleTranslationSwitch
                    )
                  }
                  stepClick={(step) => setActiveStep(step)}
                  title={'School'}
                  colorlibStepIcon={(props) => {
                    return (
                      <ColorLibStepIcon
                        translationPackEnabled={translationPackEnabled}
                        {...props}
                      />
                    );
                  }}
                  successTitle={'School Updated!'}
                  subTitle={'View School'}
                  iconImage={<SchoolsIcon />}
                  buttonLabel={'Edit Another School'}
                  listUrl={routes.schools}
                  createUrl={routes.schoolCreate}
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  handleReset={handleReset}
                  isCancelDisable={isUpdate}
                />
              ) : (
                <></>
              )}
            </div>
          </Paper>
        </div>

        {isNotDeleteSchool && (
          <Modal
            isOpen={isNotDeleteSchool}
            onClose={() => {
              setNotDeleteSchool(false);
            }}
          >
            <div className={classes.modalBox}>
              <Typography
                className={classes.modalTitle}
                style={{ marginBottom: 18 }}
              >
                Cannot Delete School
              </Typography>
              <Typography
                className={classes.modalSubTitle}
                style={{ marginBottom: 26 }}
              >
                First remove associated families / children.
              </Typography>
              <div className={classes.modalActions}>
                <Button
                  onClick={() => {
                    setNotDeleteSchool(false);
                  }}
                  variant="contained"
                  size="large"
                  className={classes.greyButton}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {isOpenDeleteModal && (
          <Modal
            isOpen={isOpenDeleteModal}
            onClose={() => {
              setOpenDeleteModal(false);
            }}
          >
            <div className={classes.modalBox}>
              <Typography className={classes.modalTitle}>
                Delete School Profile?
              </Typography>
              <div className={classes.modalSubHeading}>
                <Typography className={classes.modalSubTitle}>
                  <span style={{ fontWeight: 'bold' }}>
                    Warning: This action cannot be reversed.
                  </span>
                  <br />
                  The following data will be permanently removed from the
                  database.
                </Typography>
              </div>
              <div className={classes.modalContent}>
                <Typography className={classes.modalSubTitle}>
                  - School profile (admin & app)
                  <br />
                  - Existing calendar entries for free and paid members (admin &
                  App)
                  <br />
                  - All Groups created for this school
                  <br />
                </Typography>
              </div>
              <div className={classes.modalSubHeading}>
                <span
                  className={classes.modalSubTitle}
                  style={{ fontWeight: 500, paddingBottom: 14 }}
                >
                  Type “DELETE SCHOOL” to confirm
                </span>
                <FormControl component="fieldset" style={{ width: '100%' }}>
                  <TextField
                    label=""
                    variant="outlined"
                    className={classes.inputControlBox}
                    style={{ marginTop: 0 }}
                    value={deleteText}
                    onChange={(e) => setDeleteText(e.target.value)}
                  />
                </FormControl>
              </div>
              <div className={classes.modalActions}>
                <Button
                  onClick={deleteSchoolHandle}
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={deleteText !== 'DELETE SCHOOL'}
                  className={classes.primaryButton}
                  startIcon={
                    isLoading && (
                      <CircularProgress color="secondary" size={25} />
                    )
                  }
                >
                  {!isLoading && 'Delete'}
                </Button>
                <Button
                  onClick={() => {
                    setOpenDeleteModal(false);
                  }}
                  variant="contained"
                  size="large"
                  className={classes.greyButton}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {isOpenConfirmDeleteModal && (
          <Modal
            isOpen={isOpenConfirmDeleteModal}
            onClose={() => history && history.push(routes.schools)}
          >
            <div className={classes.modalBox}>
              <Typography
                className={classes.modalTitle}
                style={{ marginBottom: 18 }}
              >
                School Deleted
              </Typography>
              <Typography
                className={classes.modalSubTitle}
                style={{ marginBottom: 26 }}
              >
                All data associated with {currentSchool.schoolName} has now been
                removed from the database.
              </Typography>
              <div className={classes.modalActions}>
                <Button
                  onClick={() => {
                    setOpenConfirmDeleteModal(false);
                    history && history.push(routes.schools);
                  }}
                  color="primary"
                  variant="contained"
                  size="large"
                  className={classes.primaryButton}
                >
                  Continue
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {translationWarningModal?.isOpen && (
          <Modal
            isOpen={translationWarningModal.isOpen}
            onClose={() => {
              setTranslationWarningModal({
                isOpen: false
              });
              setTranslationOffText('')
            }}
          >
            <div className={classes.modalBox}>
              <Typography className={classes.modalTitle}>
                Warning - This may affect members
              </Typography>
              <div className={classes.modalSubHeading}>
                <Typography className={classes.modalSubTitle}>
                  <span style={{ fontWeight: 'bold', marginTop: 14 }}>
                    If the extended translation pack is turned off for this
                    school it will revert the calendar to English for all
                    members using a language from this pack.
                  </span>
                </Typography>
              </div>
              <div className={classes.modalSubHeading}>
                <span
                  className={classes.modalSubTitle}
                  style={{ fontWeight: 500 }}
                >
                  Type &quot;TURN OFF&quot; to confirm
                </span>
                <FormControl component="fieldset" style={{ width: '100%',  marginTop: 14 , marginBottom: 10 }}>
                  <TextField
                    label=""
                    variant="outlined"
                    className={classes.inputControlBox}
                    style={{ marginTop: 0 }}
                    value={translationOffText}
                    onChange={(e) => setTranslationOffText(e.target.value)}
                  />
                </FormControl>
              </div>
              <div className={classes.modalActions}>
                <Button
                  onClick={() => {
                    translationWarningModal.callback(translationWarningModal.args)
                    setTranslationWarningModal({
                      isOpen: false
                    });
                    setTranslationOffText('')
                  }}
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={translationOffText !== 'TURN OFF'}
                  className={classes.primaryButton}
                  startIcon={
                    isLoading && (
                      <CircularProgress color="secondary" size={25} />
                    )
                  }
                >
                  {!isLoading && 'Turn off'}
                </Button>
                <Button
                  onClick={() => {
                    setTranslationWarningModal({
                      isOpen: false
                    });
                    setTranslationOffText('')
                  }}
                  variant="contained"
                  size="large"
                  className={classes.greyButton}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
        )}

        <RightDrawer
          isOpen={isOpenDrawer}
          onClose={() => {
            onClose();
            onCloseForm();
          }}
          width={900}
          style={{ overflowX: 'hidden' }}
        >
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="primary" />
          </Backdrop>
          <div className={classes.drawerLeftContent}>
            <div className={classes.leftContentMargin}>
              <div className={classes.leftContentHeader}>
                {isEditMode ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.siderEdit}
                    onClick={() => {
                      const defaultData = draftToHtml(
                        convertToRaw(defaultNote.getCurrentContent())
                      );
                      const memberNotes = memberData.notes;
                      if (defaultData === memberNotes) {
                        handleClose();
                      } else if (
                        defaultData.replace(/(\r\n|\n|\r)/gm, '') ===
                          '<p></p>' &&
                        !memberNotes
                      ) {
                        handleClose();
                      } else {
                        onSaveChanges();
                      }
                    }}
                    style={{ width: 165 }}
                  >
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.siderEdit}
                    onClick={() => setEditMode(true)}
                    startIcon={<img src={Edit} />}
                  >
                    Edit
                  </Button>
                )}
              </div>
              <Divider
                variant="fullWidth"
                style={{ marginBottom: 20, marginTop: 22 }}
              />
              {!isEditMode && !memberData.notes && (
                <Typography className={classes.siderDetail}>
                  Click Edit to add a note...
                </Typography>
              )}
              {!isEditMode &&
                memberData.notes &&
                memberData.notes.replace(/(\r\n|\n|\r)/gm, '') ===
                  '<p></p>' && (
                  <Typography className={classes.siderDetail}>
                    Click Edit to add a note...
                  </Typography>
                )}
              <Editor
                stripPastedStyles={true}
                readOnly={!isEditMode}
                defaultEditorState={defaultNote}
                toolbarClassName={
                  !isEditMode ? classes.toolbarHidden : classes.toolbar
                }
                onEditorStateChange={onEditorChange}
                editorClassName={
                  !isEditMode ? classes.textEditor : classes.textEditorOnEdit
                }
                toolbar={{
                  options: ['inline', 'list', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough']
                  },
                  list: {
                    options: ['unordered']
                  },
                  link: {
                    defaultTargetOption: '_blank',
                    options: ['link']
                  }
                }}
              />
            </div>
          </div>
          <div className={classes.drawerRightContent}>
            <div style={{ padding: 18, zIndex: 1 }}>
              <div className={classes.header}>
                {memberData.schoolName && (
                  <Typography
                    className={classes.textwraplabel}
                    title={memberData.schoolName}
                  >
                    {memberData.schoolName}
                  </Typography>
                )}
              </div>
              <Divider style={{ marginBottom: 20 }} />
              {memberData.country && (
                <>
                  <Typography className={classes.heading}>COUNTRY</Typography>
                  <Typography className={classes.siderDetail}>
                    {memberData.country}
                  </Typography>
                </>
              )}
              {memberData.websiteUrl && (
                <>
                  <Typography className={classes.heading}>WEBSITE</Typography>
                  <Link
                    color="secondary"
                    component={Link}
                    onClick={() => window.open(memberData.websiteUrl, '_blank')}
                    className={classes.siderDetail}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    Click Here
                  </Link>
                </>
              )}
            </div>
            <div className={classes.handBookContainer}>
              <img src={HandBook} alt="" className={classes.handBookClass} />
            </div>
          </div>
        </RightDrawer>
      </Dashboard>
    </React.Fragment>
  );
}

export default withFirebase(EditSchoolProfileView);
