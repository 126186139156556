import { groupSchema, groupModalDataSchema, paidGroupModalDataSchema } from 'src/utils/schemas';
// Initial State
const initialState = {
  currentGroup: groupSchema,
  groupAddEditModalData: groupModalDataSchema,
  groupPaidModalData: paidGroupModalDataSchema,
};

// Reducers (Modifies The State And Returns A New State)
const groupReducer = (state = initialState, action) => {
  switch (action.type) {
    // Group Set
    case 'SET_GROUP': {
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          ...action.group,
          createdByApiSync: !!action?.group?.createdByApiSync
        }
      };
    }
    case 'RESET_GROUP': {
      return {
        ...state,
        currentGroup: groupSchema
      };
    }
    case 'SET_ADD_EDIT_GROUP_MODAL_DATA': {
      return {
        ...state,
        groupAddEditModalData: {
          ...state.groupAddEditModalData,
          ...action.groupAddEditModalData
        }
      };
    }
    case 'SET_PAID_GROUP_MODAL_DATA': {
      return {
        ...state,
        groupPaidModalData: {
          ...state.groupPaidModalData,
          ...action.groupPaidModalData
        }
      };
    }
    // Default
    default: {
      return state;
    }
  }
};

// Exports
export default groupReducer;
