import { makeStyles } from "@material-ui/core"
import { colors } from "src/utils/constant";

const useStyles = makeStyles({
    root:{
        height: "calc(100vh - 205px)",
        width: "100%",
        display: "inline-flex",
        justifyContent: "center",
        boxShadow: "0 0 25px 0 rgba(76, 91, 104, 0.13) !important"
    },
    active:{
        height: "24px !important",
        background: colors.secondaryGreenLightSync,
        display: "inline-block",
        padding: "1px 6px",
        borderRadius: "3px"
    },
    inactive:{
        height: "24px !important",
        background: colors.secondaryGreyLightSync,
        display: "inline-block",
        padding: "1px 6px",
        borderRadius: "3px"
    },
    tableCell:{
        padding: "4px 4px 4px 8px !important",
    },
    tableRow:{
        "&:hover":{
            "& .downloadArrow":{
                background: colors.primary,
                borderRadius: "50%",
                "& g":{
                    stroke: `${colors.white} !important`,
                    "& path":{
                        fill: `${colors.primary} !important`
                    }
                }
            }
        },
        "& .downloadArrow":{
            marginLeft: "4px",
            height: "40px",
            width: "40px",
            cursor: "pointer",
            "& g":{
                transform: "translate(10px, 8px) scale(1)",
                "& path":{
                    fill: `${colors.white} !important`
                },
            }
        }
    },
    downloadArrowContainer:{
        display: "flex"
    },
    schoolName:{
        paddingLeft: "16px !important",
        fontWeight: 500
    },
    table:{
        boxShadow: "none !important",
        display: "flex",
        flexFlow: "column",
        height: "100% !important",
        maxHeight: "100% !important",
        "& .MuiTablePagination-root": {
            marginBottom: "0px",
            marginTop: "auto",
            display: "inline-table"
        }
    },
    pack1Container:{
        width: "379px",
        margin: "0 25px 10px 24px",
        position: "relative",
        border: `1px solid ${colors.primaryLightGrey}`,
        borderRadius: "3px",
        padding: "24px 13px"
    },
    list:{
        width: "auto !important"
    },
    context:{
        fontSize: "16px",
        margin: "0 auto 36px 24px"
    },
    title:{
        fontSize: "23px",
        lineHeight: 1.74,
        fontWeight: 500,
        margin: "20px auto 25px 24px"
    },
    divider:{
        marginTop: "20px",
        marginBottom: "20px",
        background: colors.primaryLightGrey
    },
    divider1:{
        margin: "34px 24px 36px 24px",
        background: colors.primaryLightGrey
    },
    label:{
        fontSize: "14px",
        fontWeight: 500,
        position: "absolute",
        top: "-12px",
        left: "15px",
        background: colors.white,
        padding: "0 4px"
    },
    checkboxGrid:{
        height: "32px",
        marginBottom: "4px",
        "& label":{
            height: "32px",
            width: "100%"
        }
    },
    controlCheck: {
        margin: '5%'
      },
    checkbox: {
        color: colors.primaryLightGrey,
        '&$checked': {
            color: colors.primary
        },
        "& svg":{
            height: "18px !important",
            width: "18px !important",
            position: "relative",
            borderRadius: "2px",
            "& path":{
                position: "absolute",
                transform: "translate(-4px, -4px) scale(1.4)",
            }
        },
    },
    checked:{
        color: colors.primary
    },
    indeterminateCheckbox:{
        "& svg":{
            background: colors.secondaryGrey,
            "& path":{
                fill: colors.secondaryGreyLightSync
            }
        },
    }
})

export default useStyles;