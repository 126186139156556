import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";

export const membersListPendingStyles = makeStyles({
  container: {
    maxHeight: '91.1%',
    borderRadius: 4,
    overflowX: 'hidden'
  },
  tableRow: {
    height: '72px',
    '&:hover': {
      backgroundColor: `rgba(244, 245, 247, 0.51) !important`,
      cursor: 'pointer'
    }
  },
  dataTableCell: {
    color: colors.primaryDark,
    padding: 8,
    '&:first-child': {
      paddingLeft: 52
    }
  },
  inputTableCell: {
    borderBottom: 'none',
    paddingLeft: 20,
    paddingTop: 29,
    paddingBottom: 14,
    backgroundColor: colors.secondaryGreyLight
  },
  tableHead: {
    maxHeight: '17.1%'
  },
  checkbox: {
    color: '#474747',
    marginLeft: 9,
    '&$checked': {
      color: '#474747'
    }
  },
  checkbox2: {
    color: '#000000',
    marginLeft: 9,
    '&.Mui-checked': {
      color: '#8bc517'
    }
  },
  textFieldRoot: {
    width: 280,
    height: 45,
    borderRadius: 4,
    border: 1,
    borderColor: 'rgb(224, 224, 224)',
    backgroundColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    margin: '5px 10px 5px 0'
  },
  inputControlBoxContainer: {
    margin: '5px 0'
  },
  inputControlBox: {
    width: 572,
    backgroundColor: '#ffffff',
    marginTop: 24,
    '& .MuiSelect-root': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  placeholderText: {
    position: 'absolute'
  },
  freeMembersButton: {
    height: '42px',
    width: '140px',
    borderRadius: '25px',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    margin: '5px 10px'
  },
  countmemberBox: {
    padding: '4px 6px',
    height: '20px',
    marginLeft: '10px',
    backgroundColor: '#8bc517',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '2px'
  },
  memberboxActiveDisable: {
    padding: '4px 6px',
    height: '20px',
    marginLeft: '10px',
    backgroundColor: '#D3D3D3',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center'
  },
  countmemberBoxNew: {
    padding: '4px 6px',
    height: '20px',
    marginLeft: '10px',
    backgroundColor: '#0084ff',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center'
  },
  countmemberBoxPending: {
    padding: '4px 6px',
    height: '20px',
    marginLeft: '10px',
    backgroundColor: '#ff9b00',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center'
  },
  header: {
    width: '100%',
    boxShadow: `0 0 25px 0 rgba(76, 91, 104, 0.13)`
  },
  actionButton: {
    padding: '8px 17px',
    fontSize: 16,
    lineHeight: '24px',
    float: 'right'
  },
  startIcon: {
    marginRight: 12
  },
  surname: {
    fontWeight: 500
  },
  pauseStatus: {
    fontSize: 14,
    backgroundColor: '#bd0000',
    color: '#ffffff',
    padding: '0 5px'
  },
  freeStatus: {
    fontSize: 14,
    backgroundColor: '#e8f1f5',
    color: '#2f7d91',
    padding: '0 5px'
  },
  paidStatus: {
    fontSize: 14,
    backgroundColor: '#e8f5e9',
    color: '#348f37',
    padding: '2px 5px'
  },
  actionIcon: {
    height: 50,
    width: 50,
    padding: 10,
    cursor: 'pointer',
    margin: 'auto',
    '&:hover': {
      backgroundColor: colors.primary,
      borderRadius: 50,
      '& svg g': {
        fill: '#ffffff'
      }
    }
  },

  redirectionClass: {
    padding: '4px 10px',
    fontSize: 14,
    textDecoration: 'none',
    color: colors.primaryDark,
    borderRadius: 13.5,
    '&:hover': {
      backgroundColor: colors.primary,
      borderRadius: 13.5,
      boxShadow: `0 2px 12px 0 rgba(0, 0, 0, 0.18)`,
      cursor: 'pointer',
      padding: '4px 10px',
      fontSize: 14,
      color: colors.white
    }
  },
  statusFilterContainer: {
    float: 'right',
    '@media (max-width: 1720px)': {
      float: 'unset'
    }
  },
  clearFiltersContainer: {
    width: 'max-content',
    display: 'inline-flex',
    height: '43px',
    alignItems: 'center',
    margin: '5px 0',
    cursor: 'pointer',
    fontWeight: 300
  },
  filterCard:{
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px 12px'
  },
  selectedRow:{
    backgroundColor: 'rgba(139, 197, 23, 0.05) !important'
  }
});

export const membersListPendingToolbarStyles = makeStyles({
  root: {
    // paddingRight: theme.spacing.unit,
    width: '725%',
    position: 'relative',
    left: 0,
    right: 0
  },
  modalViewerDesign: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center',
    margin: 'auto 0 auto 10px'
  },
  approveButton: {
    width: '100px',
    height: '37px',
    flexGrow: 0,
    margin: '0 45px 0 -12px',
    padding: '8px 17px 8px 16px',
    borderRadius: '3px',
    backgroundColor: '#474747',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#474747'
    }
  },
  deleteText: {
    width: '40px',
    height: '12px',
    margin: 'auto 0 auto -20px',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 0.86,
    textAlign: 'center',
    color: '#474747',
    cursor: 'pointer'
  },
  highlight: {
    backgroundColor: 'rgba(139, 197, 23, 0.5)'
  },
  spacer: {
    flex: '1 1 100%'
  },
  title: {
    flex: '0 0 auto'
  },
  modalBox: {
    width: 450
    // height: 170,
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark
  },
  modalActions: {
    marginTop: '2%'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  }
});