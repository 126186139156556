/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';

const TruncatedText = ({ line, text, truncateText, showMoreButton,style={} }) => {
  const [showMore, setShowMore] = useState(false);
  const createMarkup = () => ({ __html: text });

  const showHideText = () => {
    setShowMore(!showMore);
  };

  const readMoreLink = showMoreButton && (
    <span // eslint-disable-next-line
      className="text-primary cursor-pointer ml-1"
      onClick={showHideText}
    >
      {!showMore ? 'Read More' : 'Read Less'}
    </span>
  );

  const wholeText = (
    <span style={{...style}}>
      <span dangerouslySetInnerHTML={createMarkup()} />
      {readMoreLink}
    </span>
  );

  return showMore ? (
    wholeText
  ) : (
    <TextTruncate
    style={{...style}}
      line={line}
      element="span"
      truncateText={truncateText}
      text={text}
      textTruncateChild={readMoreLink}
    />
  );
};

TruncatedText.propTypes = {
  text: PropTypes.string.isRequired,
  showMoreButton: PropTypes.bool,
  line: PropTypes.number,
  truncateText: PropTypes.string
};

TruncatedText.defaultProps = {
  showMoreButton: false,
  line: 1,
  truncateText: '...'
};

export default TruncatedText;
