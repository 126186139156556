import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  Fragment,
  useRef
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Box,
  IconButton,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Select,
  MenuItem,
  Chip,
  ThemeProvider,
  createMuiTheme,
  Link,
  CircularProgress,
  Backdrop
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import clsx from 'clsx';
import moment from 'moment';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { DatePicker, MuiPickersUtilsProvider, Day } from '@material-ui/pickers';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import TodayOutlined from '@material-ui/icons/TodayOutlined';
import Dashboard from '../layout/Dashboard';
import TextTruncate from 'src/components/TextTruncate';
import {
  routes,
  colors,
  schoolYearsColors,
  taskLabels,
  year as DefaultYear
} from 'src/utils/constant';
import { RightArrow } from 'src/assets/Icons';
import RightDrawer from 'src/components/RightDrawer';
import CreateSchoolCalenderEntry from './CreateSchoolCalenderEntry';
import {
  loaderStart,
  loaderStop,
  addMessage,
  setFamilyOrSchoolPreserveEntryData
} from 'src/redux/actions/appActions';
import { withFirebase } from 'src/utils/firebase';
import { setSchool, setSchoolPreserveValues } from 'src/redux/actions/schoolActions';
import { RepeatIcon } from 'src/assets';
import { generateFileHash } from 'src/utils/functions';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { HandBook, Edit } from 'src/assets';
import CategoryGroupCalendarEntry from './CategoryGroupCalendarEntry';
import api_icon from 'src/assets/apiIcon.svg';
import schoolBytes from 'src/assets/schoolByte2.webp';
import studentReportingIcon from 'src/assets/icn-student-reporting.svg';

import NavTab from './NavTab';
import {
  addSchoolFormDetailsOrUpdateSchool,
  getSchoolByID
} from 'src/services/schools';
import { getGroupBySchoolId } from 'src/services/groups';
import { editSchoolCalendarStyles } from 'src/styles/schoolStyle/editSchoolCalendarStyles';
import { createVirtualEntries } from 'src/utils/virtualEntryJSON';
import { MESSAGE_TYPES } from 'src/constants/common';
import { manageSchoolEntries } from 'src/services/entries';


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersStaticWrapper: { staticWrapperRoot: { alignItems: 'center' } },
    MuiPickersCalendar: {
      transitionContainer: { margin: '20px 0 20px 0', marginBottom: 10 },
      week: {
        justifyContent: 'space-between',
        padding: '0 16px',
        daySelected: {
          color: `${colors.primaryDark} !important`,
          backgroundColor: `${colors.primaryLightGrey} !important`
        }
      }
    },
    MuiPickersBasePicker: {
      container: {
        width: '100%'
      },
      pickerView: {
        // maxWidth: 'unset',
        maxWidth: '100%',
        justifyContent: 'space-between'
      }
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        justifyContent: 'space-between',
        padding: '0 16px'
      },
      switchHeader: {
        position: 'relative'
      },
      dayLabel: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 3.5,
        letterSpacing: 0.1,
        alignItems: 'center',
        color: `${colors.primaryDark} !important`
      },
      iconButton: {
        '&:first-of-type': {
          position: 'absolute',
          right: 50
        },
        '&:second-of-type': {
          position: 'absolute',
          right: 0
        }
      },
      transitionContainer: {
        height: 40,
        '& p': {
          left: 15,
          right: 'unset',
          fontSize: 23,
          fontWeight: 500,
          lineHeight: 1.74,
          color: colors.primaryDark
        }
      }
    },
    MuiPickersDay: {
      day: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 3.5,
        letterSpacing: 0.1,
        alignItems: 'center',
        color: colors.primaryDark
      },
      daySelected: {
        color: `${colors.primaryDark} !important`,
        backgroundColor: `${colors.primaryLightGrey} !important`
      }
    }
  }
});

const defaultProps = {
  bgcolor: 'background.paper',
  m: 1
  // border: 2
};
const labelDefaultProps = {
  bgcolor: 'background.paper',
  border: 1,
  style: {
    width: 'fit-content',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: 12,
    height: '20px'
  }
};

const nameDefaultProps = {
  bgcolor: '#FF5100',
  style: {
    width: 'fit-content',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: 12,
    color: '#FFFFFF'
  }
};

function paginate(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function EditSchoolCalendarView({ firebase, location }) {
  const classes = editSchoolCalendarStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const calendarScoll = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [memberData, setMemberData] = useState(false);
  const [defaultNote, setDefaultNote] = useState('');
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [buttonState, setButtonState] = useState('Add Year');
  const [catgeoryButton, setCategoryButton] = useState('year');
  const [openStudentReportPopUp, setOpenStudentReportPopUp] = useState(false);

  const isLoading = useSelector((state) => state.app.isLoading);

  const onCloseForm = () => {
    const defaultData = draftToHtml(
      convertToRaw(defaultNote.getCurrentContent())
    );
    const memberNotes = memberData.notes;
    if (defaultData === memberNotes) {
      // handleClose();
      onClose();
    } else if (
      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
      !memberNotes
    ) {
      onClose();
    } else if (memberNotes === '') {
      // handleClose();
      onClose();
    } else {
      setOpenModal(true);
    }
  };

  const onClose = useCallback(() => {
    setEditMode(false);
    setOpenDrawer(false);
  }, [setOpenDrawer]);
  const [isOpenModal, setOpenModal] = useState(false);

  const openRightDrawer = useCallback(
    (data) => {
      setMemberData(data);
      const contentBlock = htmlToDraft(data.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      setOpenDrawer(true);
    },
    [setOpenDrawer]
  );

  const handleClose = useCallback(() => {
    setEditMode(false);
    setOpenModal(false);
  }, [setOpenModal]);

  const onEditorChange = (value) => {
    const editorData = draftToHtml(convertToRaw(value.getCurrentContent()));
    setMemberData({ ...memberData, notes: editorData });
  };

  const onSaveChanges = async () => {
    // PHASE2/CHANGES/SCHOOL
    dispatch(loaderStart());

    const payload = {
      notes: memberData?.notes
    };

    try {
      await addSchoolFormDetailsOrUpdateSchool(currentSchool.id, payload);
      dispatch(setSchool(payload));
      dispatch(setSchoolPreserveValues(payload));
      dispatch(addMessage('Changes saved'));
      dispatch(loaderStop());
      const contentBlock = htmlToDraft(payload.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      handleClose();
      setOpen(false);
    } catch (error) {
      console.error(error);
      dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  //New
  const [lodingEntries, setLodingEntries] = useState(true);
  const [pagination, setPagination] = useState({ pageSize: 10, pageNumber: 1 });
  const [selectedPaginationData, setSelectedPaginationData] = useState([]);
  const [dataInscroll, setDataInscroll] = useState([]);
  const [parrentIds, setParrentIds] = useState([]);
  //New end

  //Date picker section start
  // const [daysWithDot, setDaysWithDot] = useState([]);
  const [freeCalendarsOptions, setFreeCalendarsOptions] = useState([]);
  // const [schoolOptions, setSchoolOptions] = useState([]);
  const [date, changeDate] = useState(moment());
  const [sortEntriesData, setSortEntriesData] = useState([]);
  // const [listScrollToIndex, setListScrollToIndex] = useState(0);
  const [allCalenderData, setAllCalenderData] = useState([]);
  const [currentYear, setCurrentYear] = useState('');
  //const [section, setSection] = useState([]);
  //const [allSection, setAllSection] = useState([]);
  const [hasEntryData, setEntryData] = useState([]);
  //Date picker section ends

  const currentSchool = useSelector((state) => state.school.currentSchool);
  const [categoryItem, setCategoryItem] = useState([]);
  const schoolID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  const hasFamilyOrSchoolEntryData = useSelector(
    (state) => state.app.hasFamilyOrSchoolEntryData
  );
  const currentUser = useSelector((state) => state.auth.user);
  const [fetchCategory, setFetchCategory] = useState([]);

  useEffect(
    () => {
      let category = currentSchool?.categories?.map((item) => item.id);
      setCategoryItem(category);

      if (hasFamilyOrSchoolEntryData && hasFamilyOrSchoolEntryData.entries) {
        let primaryEntryID = '';
        const updatedPreserveEntryData = hasFamilyOrSchoolEntryData.entries.map(
          (entry) => {
            const freeCalendarsMultipleArray = currentSchool.freeCalendars.map(
              (year) => {
                return {
                  value: year,
                  key: `Year ${year}`,
                  selected:
                    entry.for.length && entry.for.includes(year) ? true : false
                };
              }
            );
            entry.freeCalendarsMultipleOptions = [
              {
                key: `All Years`,
                value: 'all',
                selected: entry.for.includes('all') ? true : false
              },
              ...freeCalendarsMultipleArray
            ];
            entry.id = generateFileHash(20);
            entry.agentName = currentUser.firstName;
            if (entry.isPrimary) {
              primaryEntryID = entry.id;
            } else {
              entry.primaryID = primaryEntryID;
            }
            entry.from.date = date.format('MMM DD,YYYY');
            entry.to.date = date.format('MMM DD,YYYY');
            return entry;
          }
        );
        dispatch(
          setFamilyOrSchoolPreserveEntryData({
            entries: [...updatedPreserveEntryData]
          })
        );
      }
    },
    // eslint-disable-next-line
    [date]
  );

  useEffect(() => {
    if(currentSchool?.id){
      getGroupBySchoolId(currentSchool.id)
        .then(function (result) {
          let GroupData = [];
          let filterGroups = [];

          result.data.forEach(function (doc) {
            if (doc.groupName) {
              GroupData.push({
                ...doc,
                ...{ id: doc.id, category: doc.category }
              });
            }
          });
          filterGroups = GroupData.filter((item) => {
            return item.school === currentSchool.id;
          }).map((item) => item.category);
          setFetchCategory(filterGroups);
          dispatch(loaderStop());
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
          dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
          setOpenModal({ open: false, values: null });
        });
    }
  }, [currentSchool]);

  useEffect(() => {
    if (
      selectedPaginationData.length &&
      calendarScoll.current?.clientHeight > 1766 &&
      pagination.pageSize === 10
    ) {
      setDataInscroll(selectedPaginationData.slice(0, 20));
      setPagination({ pageSize: 20, pageNumber: 1 });
      calendarScoll.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [selectedPaginationData]);

  const getSchoolBackgroundColor = (id) => {
    const foundCalendarColor = _.find(
      freeCalendarsOptions,
      (data) => data.id === id
    );
    if (foundCalendarColor) {
      return foundCalendarColor.color;
    } else {
      return '#6b747b';
    }
  };

  const isWeekdayHandle = (date) => {
    const day = moment(date).day();
    return day === 0 || day === 6;
  };
  const _dateChangeHandle = (date) => {
    changeDate(date);

    const index = _.findIndex(sortEntriesData, function (o) {
      return moment(moment(o.from.date).format('MMM DD,YYYY')).isSameOrAfter(
        moment(date).format('MMM DD,YYYY')
      );
    });

    let paginationData = [...sortEntriesData];
    paginationData = paginationData.slice(index);
    if (currentYear) {
      paginationData = _.filter(paginationData, function (o) {
        return (
          o.for.toString() === currentYear.toString() ||
          o.for.toString() === 'all'
        );
      });
    } else {
    }

    setSelectedPaginationData(paginationData);
    setDataInscroll(paginationData.slice(0, pagination.pageSize));
    setPagination({ pageSize: 10, pageNumber: 1 });
    calendarScoll.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const goSourceEntry = (repeatEntryId) => {
    const sourceEntry = _.find(sortEntriesData, function (o) {
      return o.id === repeatEntryId;
    });
    editEntry(sourceEntry);
  };

  const handleYearChange = (e) => {
    const index = _.findIndex(sortEntriesData, function (o) {
      return moment(moment(o.from.date).format('MMM DD,YYYY')).isSameOrAfter(
        moment(date).format('MMM DD,YYYY')
      );
    });

    let paginationData = [...sortEntriesData];
    paginationData = paginationData.slice(index);
    if (!e.target.value) {
    } else if (e.target.value !== 'all') {
      paginationData = _.filter(paginationData, function (o) {
        // All years, should also appear for every year in the filter
        if (o.groups && o.groups.length && o.forYears && o.forYears.length) {
          return o.forYears.includes(
            e.target.value.toString() || o.for.toString() === 'all'
          );
        }
        return (
          o.for.toString() === e.target.value.toString() ||
          o.for.toString() === 'all'
        );
      });
    } else {
      paginationData = _.filter(paginationData, function (o) {
        // All years, should also appear for every year in the filter
        return o.for.toString() === 'all';
      });
    }

    setSelectedPaginationData(paginationData);
    setDataInscroll(paginationData.slice(0, pagination.pageSize));
    setPagination({ pageSize: 10, pageNumber: 1 });
    calendarScoll.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    setCurrentYear(e.target.value);
  };

  const handleScroll = (event) => {
    const threshold = 5;
    const target = event.target;
    if (
      target.scrollTop + target.clientHeight + threshold >=
      target.scrollHeight
    ) {
      const isPageSizeIncreased = pagination.pageSize === 20;
      const pageSize = isPageSizeIncreased ? 10 : pagination.pageSize;
      const pageNumber = isPageSizeIncreased
        ? pagination.pageNumber + 2
        : pagination.pageNumber + 1;
      const paginateData = paginate(
        selectedPaginationData,
        pageSize,
        pageNumber
      );
      setDataInscroll([...dataInscroll, ...paginateData]);
      setPagination({
        pageSize,
        pageNumber
      });
    }
  };

  const _renderDayInPicker = (
    date,
    selectedDate,
    dayInCurrentMonth,
    dayComponent
  ) => {
    let updatedDotValue = [];
    let hasDot = null;
    if (!lodingEntries) {
      hasDot = _.filter(sortEntriesData, (entry, index) => {
        return entry.from.date === moment(date).format('MMM DD,YYYY');
      });
    }

    if (hasDot && hasDot.length > 0) {
      hasDot.forEach((entry) => {
        if (entry.for === 'Parents') {
          if (!_.includes(updatedDotValue, '#6b747b')) {
            updatedDotValue.push('#6b747b');
          }
        } else {
          const foundChild = _.find(
            freeCalendarsOptions,
            (data) => data.id === entry.for
          );
          if (foundChild) {
            if (!_.includes(updatedDotValue, foundChild.color)) {
              updatedDotValue.push(foundChild.color);
            }
          } else {
            if (!_.includes(updatedDotValue, '#6b747b')) {
              updatedDotValue.push('#6b747b');
            }
          }
        }
      });
    }

    const isWeekEndDay = isWeekdayHandle(date);
    if (hasDot && dayInCurrentMonth) {
      return (
        <div
          className={clsx(
            classes.dayWithDotContainer,
            isWeekEndDay && classes.dayWithWeekColor
          )}
        >
          <Day
            current={date.isSame(moment(), 'day')}
            hidden={!dayInCurrentMonth}
            selected={date.isSame(selectedDate, 'day')}
          >
            {date.date()}
          </Day>
          <div className={classes.dotContainer}>
            {!date.isSame(selectedDate, 'day') && updatedDotValue.length ? (
              updatedDotValue.map((dot, index) => (
                <div
                  key={`${index}-${dot}`}
                  className={clsx(classes.dayWithDot)}
                  style={{
                    backgroundColor: dot
                  }}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={clsx(
            classes.dayWithDotContainer,
            isWeekEndDay && classes.dayWithWeekColor
          )}
        >
          <Day
            current={date.isSame(moment(), 'day')}
            selected={date.isSame(selectedDate, 'day')}
            style={{
              color: !dayInCurrentMonth ? '#dddddd' : colors.primaryDark
            }}
          >
            {date.date()}
          </Day>
        </div>
      );
    }
    return null;
  };

  const editEntry = async (data, e = null) => {

    //Modify for new date filed
    let formTime = data.from.time;
    let formTimeLength = data.from.time.split(':')[0].length;

    let fromhh = formTime.substring(0, 2);
    let frommm = formTime.substring(3, 5);
    let fromap = formTime.substring(5, 7).substring(0, 1);

    if (formTimeLength === 1) {
      fromhh = formTime.substring(0, 1);
      frommm = formTime.substring(2, 4);
      fromap = formTime.substring(4, 6).substring(0, 1);
    }

    let toTime = data.to.time;
    let toTimeLength = data.to.time.split(':')[0].length;

    let tohh = toTime.substring(0, 2);
    let tomm = toTime.substring(3, 5);
    let toap = toTime.substring(5, 7).substring(0, 1);

    if (toTimeLength === 1) {
      tohh = toTime.substring(0, 1);
      tomm = toTime.substring(2, 4);
      toap = toTime.substring(4, 6).substring(0, 1);
    }

    data.newFromTimeFormate = { hh: fromhh, mm: frommm, ap: fromap };
    data.newToTimeFormate = { hh: tohh, mm: tomm, ap: toap };
    //Modify for new date filed end
    if (data.isPrimary) {
      const foundValues = allCalenderData[0].entries.filter(
        (obj) =>
          data.linkedArray &&
          data.linkedArray.length &&
          data.linkedArray.includes(obj.id)
      );

      if (data.isRepeatEntry) {
        if (e.target.getAttribute('alt') === 'Repeat') {
          //Find origin entry
          let primaryData = allCalenderData[0].entries.find(
            (entry) => entry.id === data.parentRepeatEntryId
          );

          const updatedValue = [{ ...primaryData }, ...foundValues].map(
            (value) =>
              value.id === data.id
                ? { ...value, expand: true }
                : { ...value, expand: false }
          );

          setEntryData(updatedValue);
          setOpen(true);
        } else {
          setEntryData([{ ...data, expand: true }]);
          setOpen(true);
        }
      } else if (data.isDynamic) {
        const primary = allCalenderData[0].entries.filter(
          (entry) => entry.id === data.id && entry.isPrimary
        );
        let primaryData = primary[0];
        const updatedValue = [{ ...primaryData }, ...foundValues].map((value) =>
          value.id === data.id
            ? { ...value, expand: true }
            : { ...value, expand: false }
        );

        setEntryData(updatedValue);
        setOpen(true);
      } else {
        const updatedValue = [{ ...data }, ...foundValues].map((value) =>
          value.id === data.id
            ? { ...value, expand: true }
            : { ...value, expand: false }
        );
        setEntryData(updatedValue);
        setOpen(true);
      }
    } else {
      const primary = allCalenderData[0].entries.filter(
        (entry) => entry.id === data.primaryID && entry.isPrimary
      );

      let primaryData = primary[0];
      const updatedPrimary = primary[0]?.repeatEntries?.find(
        (e) => e.id === data.primaryID && e.isPrimary
      );
      if (updatedPrimary) {
        primaryData = updatedPrimary;
      }
      const foundValues = allCalenderData[0].entries.filter(
        (obj) =>
          primaryData &&
          primaryData.linkedArray &&
          primaryData.linkedArray.length &&
          primaryData.linkedArray.includes(obj.id)
      );
      const updatedValue = [{ ...primaryData }, ...foundValues].map((value) => {
        if (data.actionParent) {
          if (
            data.actionParent &&
            data.actionParent.linkedId &&
            data.actionParent.linkedId === value.id
          ) {
            return { ...value, expand: true };
          } else if (
            data.actionParent &&
            data.actionParent.parentID &&
            data.actionParent.parentID === value.id &&
            !data.actionParent.linkedId
          ) {
            return { ...value, expand: true };
          } else {
            return { ...value, expand: false };
          }
        } else {
          if (value.id === data.id) {
            return { ...value, expand: true };
          }
        }

        return { ...value, expand: false };
      });

      setEntryData(updatedValue);
      setOpen(true);
    }
  };

  //List section start

  const getBackGroundColor = (id) => {
    const foundYear = freeCalendarsOptions.find((year) => year.id === id);
    const updatedYears = freeCalendarsOptions.find((year) => year.value === id);
    if (foundYear) {
      return foundYear.color;
    }
    if (updatedYears) {
      return updatedYears.color;
    } else {
      return null;
    }
  };

  const GetYears = (id) => {
    const foundYear = freeCalendarsOptions.find((year) => year.id === id);
    const updatedYears = freeCalendarsOptions.find((year) => year.value === id);

    if (id === 'group' || id.length === 0) {
      return 'GROUP';
    }
    if (id === 'Group') {
      return 'Group';
    }
    if (foundYear) {
      return foundYear.value;
    }
    if (updatedYears) {
      return updatedYears.value;
    } else {
      return id === 'all' ? 'All Years' : '';
    }
  };

  const entryCheckboxChange = (e, data) => {
    setOpen(false);
    if (
      data.isRepeatEntry &&
      data.actions.repeat &&
      data.actions.repeat.length === 0
    ) {
      let primaryData = allCalenderData[0].entries.find(
        (entry) => entry.id === data.parentRepeatEntryId
      );
      if (primaryData.completedDates) {
        let newCompletedDates = primaryData.completedDates.filter(
          (date) => date !== data.from.date
        );
        if (e.target.checked) {
          primaryData.completedDates = [...newCompletedDates, data.from.date];
        } else {
          data.completedDates = newCompletedDates;
          primaryData.completedDates = newCompletedDates;
        }
      } else {
        if (e.target.checked) {
          primaryData.completedDates = [data.from.date];
        }
      }
      data.actions.isComplete = e.target.checked;

      // remove deadline and reminder logic start
      if (e.target.checked) {
        if (data.actions.deadlineNotification)
          delete data.actions.deadlineNotification;
        if (data.actions.reminderNotification)
          delete data.actions.reminderNotification;
      }
      // remove deadline and reminder logic end
      submitSchoolForm(primaryData);
    } else {
      data.actions.isComplete = e.target.checked;
      // remove deadline and reminder logic start
      if (e.target.checked) {
        if (data.actions.deadlineNotification)
          delete data.actions.deadlineNotification;
        if (data.actions.reminderNotification)
          delete data.actions.reminderNotification;
      }
      // remove deadline and reminder logic end
      submitSchoolForm(data);
    }
  };

  const submitSchoolForm = (data) => {
    //Repeat Logic
    let primary = [data].find((o) => o.isPrimary === true);
    if (primary !== undefined && primary.actions.repeat.length > 0) {
      let originEntry = allCalenderData[0].entries.find(
        (entry) => entry.id === primary.id
      );
      if (originEntry !== undefined) {
        data = {
          ...originEntry,
          actions: data.actions,
          isComplete: data.actions.isComplete
        };
      } else {
        if (data.isRepeatEntry) {
          let originEntry = allCalenderData[0].entries.find(
            (entry) => entry.id === primary.parentRepeatEntryId
          );
          let completedDates = originEntry.completedDates
            ? originEntry.completedDates.filter((e) => e !== primary.from.date)
            : [];
          data = {
            ...originEntry,
            completedDates: data.actions.isComplete
              ? [...completedDates, primary.from.date]
              : completedDates
          };
        }
      }
    } else if (data.isRepeatEntry) {
      let originEntry = allCalenderData[0].entries.find(
        (entry) => entry.id === primary.parentRepeatEntryId
      );
      let completedDates = originEntry.completedDates
        ? originEntry.completedDates.filter((e) => e !== primary.from.date)
        : [];
      data = {
        ...originEntry,
        completedDates: data.actions.isComplete
          ? [...completedDates, primary.from.date]
          : completedDates
      };
    }
    //Repeat Logic end
    if (data.bulkID) {
      setLodingEntries(true)
      firebase
        .schoolBulkDataEntries(data.bulkID)
        .then(async function (querySnapshot) {
          let EntriesData = [];
          querySnapshot.forEach(async function (doc) {
            let entry = doc.data();
            if (entry.id === data.id) {
              entry = data;
            }
            entry.bulkID = data.bulkID;
            EntriesData.push(entry);
          });

          if (EntriesData.length > 0) {
            dispatch(loaderStart());
            manageSchoolEntries(EntriesData)
            .then(() => {
              dispatch(addMessage('Entry updated'));
              dispatch(loaderStop());
            })
            .catch((error) => {
                console.log('Error getting documents: ', error);
                dispatch(
                  addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR)
                );
              });
          }
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
          dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
        });
      setLodingEntries(false)
    }
  };


  let dateLabel = '';
  let monthLabel = '';
  const _singleCalendarEntry = (data, index) => {
    let showlable = null;
    let showMonthlable = null;
    if (dateLabel !== data.from.date) {
      dateLabel = data.from.date;
      showlable = data.from.date;
    } else {
      showlable = null;
    }
    const Month = data.from.date.split(' ')[0];
    if (monthLabel !== Month) {
      monthLabel = Month;
      showMonthlable = moment(data.from.date).format('MMMM YYYY');
    } else {
      showMonthlable = null;
    }

    let parrentadded = false;
    let assignedTo = [];
    if (data.assign && data.assign.length > 0) {
      assignedTo = data.assign.filter((id) => {
        if (_.includes(parrentIds, id)) {
          parrentadded = true;
        } else {
          return id;
        }
      });
    }

    let customType = '';
    let parentDate = '';
    if (data.actionParent && data.actionParent.type === 'Reminder') {
      customType = 'REMINDER';
      parentDate = data.actionParent.parentDate
        ? data.actionParent.parentDate
        : '';
    } else if (data.actionParent && data.actionParent.type === 'Deadline') {
      customType = 'DEADLINE';
      parentDate = data.actionParent.parentDate
        ? data.actionParent.parentDate
        : '';
    }
    return (
      <div
        key={'list' + index}
        style={{
          width: '100%'
        }}
      >
        {showMonthlable && (
          <div
            className={index === 0 ? classes.monthNameTop : classes.monthName}
          >
            {showMonthlable}
          </div>
        )}
        <Paper
          className={clsx(
            classes.paper,
            !showlable && index !== 0 && classes.borderClass
          )}
          style={{
            // opacity: data.actions.isCancelled ? 0.5 : 1,
            height: 152,
            marginTop: showlable && index !== 0 ? 20 : -6,
            boxShadow: showlable && index === 0 && 'none',
            borderTopLeftRadius: showlable ? 4 : 0,
            borderTopRightRadius: showlable ? 4 : 0,
            borderBottomRadius: !showlable && index !== 0 && 4
            // borderTop: !showlable && index !== 0 && '2px solid #e0e0e0'
          }}
          onClick={(e) => {
            if (typeof data.for === 'string' && data.for !== 'group') {
              setButtonState('Add Year');
            } else if (data.for.length || data.for === 'group') {
              setButtonState(data.buttonState);
            } else {
              setButtonState(data.buttonState);
            }
            editEntry(data, e);
          }}
        >
          <Grid
            item
            xs={12}
            sm={3}
            style={{
              opacity:
                data.actions.isCancelled || data.actions.isPostponed ? 0.5 : 1
            }}
          >
            <Box display="flex">
              <Typography
                component="div"
                style={{
                  height: 130,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Box
                  fontWeight="fontWeightBold"
                  mb={1}
                  className={classes.labelText}
                >
                  {showlable}
                  {/* {dateLabel === previousDateLabel ? '' : dateLabel} */}
                  {/* {index !== 0 && data.from.date === sortEntriesData[1].data.from.date ? data.from.date : ''} */}
                </Box>
                <Box fontWeight="fontWeightRegular" mb={1}>
                  {data.allDay ? (
                    ''
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p className={classes.fromDateText}>
                        {moment(data.from.time, 'h:mma').format('HH:mm')}
                      </p>
                      <p style={{ margin: '0 5px' }}>-</p>
                      <p className={classes.toDateText}>
                        {moment(data.to.time, 'h:mma').format('HH:mm')}
                      </p>
                    </div>
                  )}
                </Box>
                <Box
                  display="flex"
                  mb={1}
                  // justifyContent="space-between"
                  width="40%"
                >
                  {data.actions?.repeat?.length || data.isRepeatEntry ? (
                    <img
                      src={RepeatIcon}
                      alt="Repeat"
                      className={classes.repeatIconClass}
                    />
                  ) : (
                    <></>
                  )}
                  {data.isLinked || data.linkedArray?.length ? (
                    <IconButton
                      key={1}
                      className={classes.iconButton}
                      style={{ opacity: 0.5 }}
                      aria-label="insert-link"
                    >
                      <InsertLinkIcon />
                    </IconButton>
                  ) : (
                    // ))
                    <></>
                  )}
                </Box>
                <div
                  style={{
                    marginTop: 'auto',
                    display: 'flex',
                    flexWrap: 'wrap'
                  }}
                >
                  {data.forYears &&
                  data.forYears.length &&
                  data.forYears !== 'group' ? (
                    <>
                      {data.for === 'group' ? (
                        <Box
                          borderRadius={50}
                          fontWeight={500}
                          marginRight={'2px'}
                          marginBottom={'2px'}
                          {...nameDefaultProps}
                          bgcolor={
                            data.for === 'all' ||
                            data.for === 'group' ||
                            data.for.length
                              ? '#6b747b'
                              : getBackGroundColor(data.for)
                          }
                        >
                          {GetYears('Group').toUpperCase()}
                        </Box>
                      ) : null}
                      {data.forYears.includes('all') ? (
                        <Box
                          borderRadius={50}
                          fontWeight={500}
                          marginRight={'2px'}
                          marginBottom={'2px'}
                          {...nameDefaultProps}
                          bgcolor={'#6b747b'}
                        >
                          {GetYears('all').toUpperCase()}
                        </Box>
                      ) : (
                        data.forYears
                          .filter((item) => item !== 'all')
                          .map((year, index) => (
                            <Box
                              key={index}
                              borderRadius={50}
                              fontWeight={500}
                              marginRight={'2px'}
                              marginBottom={'2px'}
                              {...nameDefaultProps}
                              bgcolor={
                                data.for === 'all' || year === 'all'
                                  ? '#6b747b'
                                  : getBackGroundColor(year)
                              }
                            >
                              {GetYears(year).toUpperCase()}
                            </Box>
                          ))
                      )}
                    </>
                  ) : (
                    <Box
                      borderRadius={50}
                      fontWeight={500}
                      marginRight={'2px'}
                      marginBottom={'2px'}
                      {...nameDefaultProps}
                      bgcolor={
                        data.for === 'all' ||
                        data.for === 'group' ||
                        data.for.length === 0
                          ? '#6b747b'
                          : getBackGroundColor(data.for)
                      }
                    >
                      {GetYears(data.for).toUpperCase()}
                    </Box>
                  )}
                </div>
              </Typography>
            </Box>
          </Grid>
          <div
            style={{
              height: 130,
              width: 2,
              opacity:
                data.actions.isCancelled || data.actions.isPostponed ? 0.5 : 1,
              backgroundColor:
                data.for === 'all'
                  ? '#6b747b'
                  : getBackGroundColor(data.for)
                  ? getBackGroundColor(data.for)
                  : '#6b747b'
            }}
          ></div>
          <Grid item xs={12} sm={9}>
            <Box
              // className={classes.box}
              {...defaultProps}
              borderTop={0}
              borderBottom={0}
              borderRight={0}
            >
              <Typography component="div">
                <Box display="flex" justifyContent="space-between">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {data.label !== 'none' && data.label === 'Alert' ? (
                      <Box
                        fontWeight={500}
                        {...labelDefaultProps}
                        bgcolor={
                          customType === 'DEADLINE'
                            ? '#000'
                            : 'background.paper'
                        }
                        color={'rgb(189, 0, 0)'}
                        style={{
                          borderRadius: '16px',
                          width: 'fit-content',
                          paddingLeft: 8,
                          paddingRight: 8,
                          fontSize: 12,
                          height: 20,
                          marginRight: '10px'
                        }}
                      >
                        {customType && customType !== ''
                          ? customType.toUpperCase()
                          : data.label.toUpperCase()}
                      </Box>
                    ) : null}

                    {data.actions.isCancelled ||
                    data.actions.isPostponed ||
                    data.actions.isChanged ? (
                      <Chip
                        size="small"
                        label={
                          <span style={{ fontSize: 12 }}>
                            {data.actions.isPostponed
                              ? 'POSTPONED'
                              : data.actions.isCancelled
                              ? 'CANCELLED'
                              : 'UPDATED'}
                          </span>
                        }
                        classes={{
                          labelSmall: classes.chipLabel,
                          root: classes.chipRoot
                        }}
                        style={{
                          marginRight: 10,
                          backgroundColor:
                            data.actions.isCancelled || data.actions.isPostponed
                              ? colors.tertiaryRed
                              : colors.primaryDarkGrey
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    {data.label !== 'none' &&
                    data.label !== 'Alert' &&
                    data.label ? (
                      <Box
                        borderRadius="borderRadius"
                        fontWeight={500}
                        {...labelDefaultProps}
                        bgcolor={
                          customType === 'DEADLINE'
                            ? '#000'
                            : 'background.paper'
                        }
                        color={customType === 'DEADLINE' ? '#fff' : '#000'}
                        style={{
                          opacity:
                            data.actions.isCancelled || data.actions.isPostponed
                              ? 0.5
                              : 1,
                          width: 'fit-content',
                          paddingLeft: 8,
                          paddingRight: 8,
                          fontSize: 12,
                          height: 20
                        }}
                      >
                        {customType && customType !== ''
                          ? customType.toUpperCase()
                          : data.label.toUpperCase()}
                      </Box>
                    ) : null}

                    {(data.label === 'none' || data.label.trim() === '') &&
                    (customType === 'REMINDER' || customType === 'DEADLINE') ? (
                      <Box
                        borderRadius="borderRadius"
                        fontWeight={500}
                        {...labelDefaultProps}
                        bgcolor={
                          customType === 'DEADLINE'
                            ? '#000'
                            : 'background.paper'
                        }
                        color={customType === 'DEADLINE' ? '#fff' : '#000'}
                        style={{
                          opacity:
                            data.actions.isCancelled || data.actions.isPostponed
                              ? 0.5
                              : 1,
                          width: 'fit-content',
                          paddingLeft: 8,
                          paddingRight: 8,
                          fontSize: 12,
                          height: 20
                        }}
                      >
                        {customType && customType !== ''
                          ? customType.toUpperCase()
                          : data.label.toUpperCase()}
                      </Box>
                    ) : null}
                  </div>
                  {taskLabels.includes(data.label) &&
                  customType !== 'DEADLINE' &&
                  customType !== 'REMINDER' ? (
                    <Checkbox
                      color="primary"
                      className={classes.checkboxClass}
                      style={{
                        opacity:
                          data.actions.isCancelled || data.actions.isPostponed
                            ? 0.5
                            : 1,
                        color: data.actions.isComplete
                          ? colors.primary
                          : colors.primaryLightGrey
                      }}
                      checked={data.actions.isComplete || false}
                      onChange={(e) => entryCheckboxChange(e, data)}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
                <Box
                  fontWeight="fontWeightBold"
                  mt={1}
                  className={classes.mainTitleContainer}
                  style={{
                    opacity:
                      data.actions.isCancelled || data.actions.isPostponed
                        ? 0.5
                        : 1
                  }}
                >
                  <p className={classes.titleTextContainer}>
                    {customType &&
                    (customType === 'DEADLINE' || customType === 'REMINDER')
                      ? `RE : ${data.title !== '' ? data.title : ''}`
                      : data.title}
                  </p>
                </Box>
                <Box
                  style={{
                    marginTop: 'auto',
                    alignItems: 'flex-end',
                    height: '45px',
                    opacity:
                      data.actions.isCancelled || data.actions.isPostponed
                        ? 0.5
                        : 1
                  }}
                  display="flex"
                  justifyContent="space-between"
                >
                  {/* Regex Reference :  https://stackoverflow.com/questions/1499889/remove-html-tags-in-javascript-with-regex */}
                  <Box width="70%">
                    <p className={classes.textTruncate}>
                      {data.description
                        .replace(/&nbsp;|(<([^>]+)>)/gi, '')
                        .replace(/&amp;/g, '&')
                        .replace(/&lt;/g, '<')
                        .replace(/&gt;/g, '>')
                        .replace(/&quot;/g, '"')}
                    </p>
                  </Box>
                  <div className={classes.image}>
                    {parrentadded ? (
                      <div
                        key={`dot-${index}`}
                        className={clsx(classes.dot)}
                        style={{
                          backgroundColor: '#6b747b'
                        }}
                      ></div>
                    ) : null}
                    {Array.isArray(data.assign)
                      ? assignedTo.map(function (doc, index) {
                          return (
                            <div
                              key={`dot-${index}`}
                              className={clsx(classes.dot)}
                              style={{
                                backgroundColor: getBackGroundColor(doc)
                              }}
                            ></div>
                          );
                        })
                      : null}
                  </div>
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Paper>
      </div>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentSchool.freeCalendars && currentSchool.freeCalendars.length > 0) {
      const freeCalendarsArray = currentSchool.freeCalendars.map(
        (year, index) => {
          const getCurrentYear = _.find(
            DefaultYear,
            (elem) => elem.value === year
          );
          return {
            id: year,
            value: getCurrentYear?.label,
            color: year.color
              ? year.color
              : schoolYearsColors[parseFloat(year) + 1]
          };
        }
      );
      setFreeCalendarsOptions(_.orderBy(freeCalendarsArray, ['id'], ['asc']));
    }
  }, [currentSchool.freeCalendars]);

  const getEntries = async (EntriesData) => {
    if (EntriesData && EntriesData.length > 0) {
      setAllCalenderData(JSON.parse(JSON.stringify(EntriesData)));
    }
    if (
      EntriesData &&
      EntriesData.length > 0 &&
      EntriesData[0].entries.length > 0
    ) {
      let newEntries = [];
      let newRepeatEntries = [];
      if( EntriesData[0] && EntriesData[0].entries ) {
        EntriesData[0].entries.forEach((entry) => {
          const fromMomentDate = moment(entry.from.date, 'MMM DD,YYYY');
          const toMomentDate = moment(entry.to.date, 'MMM DD,YYYY');
          const diffDateInDay = toMomentDate.diff(fromMomentDate, 'days');
          const startDate = entry.from.date;
          if (diffDateInDay === 0 || entry.actions.repeat.length > 0) {
            //newEntries.push(entry);
            if (
              entry.isPrimary &&
              entry.actions.repeat.length > 0 &&
              entry.actions.repeat[0].type !== 'Does not repeat'
            ) {
              newRepeatEntries = [...newRepeatEntries, entry];
            } else {
              newEntries = [...newEntries, entry];
            }
          } else {
            for (let i = 0; i <= diffDateInDay; i++) {
              if (i === 0) {
                newEntries = [...newEntries, entry];
              } else {
                const newDate = moment(startDate, 'MMM DD,YYYY')
                  .add(i, 'days')
                  .format('MMM DD,YYYY');
                newEntries = [
                  ...newEntries,
                  {
                    ...entry,
                    isDynamic: true,
                    from: { date: newDate, time: entry.from.time }
                  }
                ];
              }
            }
          }
        });
      }

      const getRepeatVirtualEntries = await createVirtualEntries(newRepeatEntries);
      newEntries = [...newEntries, ...getRepeatVirtualEntries];

      const sortEntry = _.orderBy(newEntries, (o) => {
        if (o.allDay === true) {
          return new moment(
            o.from.date + ' ' + '11:59pm',
            'MMM DD,YYYY h:mma'
          ).format();
        } else {
          return new moment(
            o.from.date + ' ' + o.from.time,
            'MMM DD,YYYY h:mma'
          ).format();
        }
      });

      setSortEntriesData(sortEntry);
      const index = _.findIndex(sortEntry, function (o) {
        return moment(moment(o.from.date).format('MMM DD,YYYY')).isSameOrAfter(
          moment(date).format('MMM DD,YYYY')
        );
      });
      let paginationData = [...sortEntry];
      paginationData = paginationData.slice(index);
      setSelectedPaginationData(paginationData);
      setDataInscroll(paginationData.slice(0, pagination.pageSize));
      setPagination({ pageSize: 10, pageNumber: 1 });
    } else {
      setSortEntriesData([]);
      setSelectedPaginationData([]);
      setDataInscroll([]);
      setPagination({ pageSize: 10, pageNumber: 1 });
    }
  };

  useEffect(() => {
    if (!currentSchool.schoolName && schoolID) {
      dispatch(loaderStart());
      setLodingEntries(true);

      // PHASE2/CHANGES/SCHOOL

      getSchoolByID(schoolID)
        .then((school) => {
          if (school) {
            dispatch(setSchool({ ...school, id: school.id }));
            dispatch(setSchoolPreserveValues({ ...school, id: school.id }));
            const category = school?.categories?.map((item) => item.id);
            setCategoryItem(category);
          }
          dispatch(loaderStop());
        })
        .catch((error) => {
          console.error(error);
          dispatch(addMessage('Error on getting school', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    }
    if (currentSchool.id && lodingEntries) {
      firebase
        .getSchoolDataEntries(schoolID)
        .then(function (querySnapshot) {
          let Entries = [];
          querySnapshot.forEach(function (doc) {
            // docId is using when repeated entry deleted
            //Entries.push({ ...doc.data(), docId: doc.id });
            Entries.push({ ...doc, docId: doc.id });
          });
          getEntries([
            {
              entries: Entries,
              schoolId: schoolID,
              id: ''
            }
          ]);
         setLodingEntries(false)

        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
          dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
         setLodingEntries(false)
        });
    }
  }, [schoolID, currentSchool.id, lodingEntries]);



  const navTabsOptions = useMemo(() => {
    return [
      ...(freeCalendarsOptions?.length
        ? [
            {
              label: 'Calendar',
              link: `${routes.schoolCalendar}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Groups',
        link: `${routes.schoolGroup}/${schoolID}`
      },
      {
        label: 'Students',
        link: `${routes.schoolStudent}/${schoolID}`
      },
      {
        divider: true
      },
      ...(freeCalendarsOptions?.length
        ? [
            {
              label: 'Links',
              link: `${routes.schoolLinks}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Notes',
        handleTabClick: () => openRightDrawer(currentSchool)
      },
      {
        label: 'Files',
        link: `${routes.schoolFiles}/${schoolID}`
      },
      ...(freeCalendarsOptions?.length
        ? [
            {
              label: 'Form',
              link: `${routes.schoolForm}/${schoolID}`
            }
          ]
        : []),
      {
        divider: true
      },
      {
        label: 'Account',
        link: `${routes.schoolAccount}/${schoolID}`
      }
    ];
  }, [freeCalendarsOptions, currentSchool]);

  //Dont render all componet if family not available
  if (!currentSchool.id && schoolID) {
    return <Dashboard></Dashboard>;
  }

  return (
    <>
      <Dashboard>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={classes.root}>
            <div>
              <Typography
                variant="body1"
                className={classes.bredthcrumbTitle}
                onClick={() => history && history.push(routes.schools)}
              >
                Schools
              </Typography>
              <RightArrow
                viewBox="0 0 24 24"
                height={24}
                width={24}
                style={{ fontSize: 22, verticalAlign: 'middle' }}
              />
            </div>
            <Typography
              variant="h2"
              color="textPrimary"
              style={{ marginBottom: 20, marginTop: 22 }}
            >
              {currentSchool.schoolName}
            </Typography>
            <Paper className={classes.tabNavigations}>
              <img
                className={classes.reportImage}
                src={studentReportingIcon}
                alt="Student report"
                onClick={() =>
                  setOpenStudentReportPopUp(!openStudentReportPopUp)
                }
              />
              {openStudentReportPopUp ? (
                <article className={classes.studentReportPopUp}>
                  <div style={{ fontWeight: 500, marginBottom: '10px' }}>
                    Student reporting
                  </div>
                  {currentSchool?.createdByApiSync ? (
                    <div className={classes.flexBoxBetween}>
                      <span>Population</span>
                      <span>
                        {currentSchool?.studentReport?.apiStudent || 0}
                      </span>
                    </div>
                  ) : null}
                  <div className={classes.flexBoxBetween}>
                    <span>Active</span>
                    <span>{currentSchool?.studentReport?.active || 0}</span>
                  </div>
                  <div className={classes.flexBoxBetween}>
                    <span>Inactive</span>
                    <span>{currentSchool?.studentReport?.inActive || 0}</span>
                  </div>
                  {currentSchool?.createdByApiSync ? (
                    <div className={classes.flexBoxBetween}>
                      <span>Opportunity</span>
                      <span>
                        {currentSchool?.studentReport?.nonRegistered || 0}
                      </span>
                    </div>
                  ) : null}
                </article>
              ) : null}

              <section
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <div>
                  <NavTab
                    tabs={navTabsOptions}
                    selectedTab={
                      freeCalendarsOptions?.length
                        ? isOpenDrawer
                          ? 'Notes'
                          : 'Calendar'
                        : 'Account'
                    }
                  />
                </div>
                {currentSchool && currentSchool?.createdByApiSync && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: '1rem'
                    }}
                  >
                    <img src={schoolBytes} height="38px" />
                    <img src={api_icon} />
                  </div>
                )}
              </section>

              <Divider />
              {freeCalendarsOptions.length && (
                <div style={{ display: 'flex' }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.actionButton}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setOpen(true);
                      setButtonState('Add Year');
                      setCategoryButton('year');
                      setEntryData([]);
                      dispatch(setFamilyOrSchoolPreserveEntryData(null));
                    }}
                  >
                    Add Year
                  </Button>

                  {fetchCategory?.includes(categoryItem?.[0]) && (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.groupButtons}
                      style={{ display: 'flex', gap: 24 }}
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setOpen(true);
                        setButtonState(categoryItem?.[0]);
                        setCategoryButton('class');
                        setEntryData([]);
                        dispatch(setFamilyOrSchoolPreserveEntryData(null));
                      }}
                    >
                      Add Class
                    </Button>
                  )}

                  {fetchCategory?.includes(categoryItem?.[1]) && (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.groupButtons}
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setOpen(true);
                        setButtonState(categoryItem?.[1]);
                        setCategoryButton('coCurricular');
                        setEntryData([]);
                        dispatch(setFamilyOrSchoolPreserveEntryData(null));
                      }}
                    >
                      Add Co-Curricular
                    </Button>
                  )}

                  {fetchCategory?.includes(categoryItem?.[3]) && (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.groupButtons}
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setOpen(true);
                        setButtonState(categoryItem?.[3]);
                        setCategoryButton('subject');
                        setEntryData([]);
                        dispatch(setFamilyOrSchoolPreserveEntryData(null));
                      }}
                    >
                      Add Subject
                    </Button>
                  )}
                  {fetchCategory?.includes(categoryItem?.[2]) && (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.actionButton}
                      style={{
                        backgroundColor: '#0084ff',
                        margin: '15px 0 20px 24px'
                      }}
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setOpen(true);
                        setButtonState(categoryItem?.[2]);
                        setCategoryButton('staff');
                        setEntryData([]);
                        dispatch(setFamilyOrSchoolPreserveEntryData(null));
                      }}
                    >
                      Add Staff
                    </Button>
                  )}

                  <div className={classes.alignFlexCenter}>
                    <div
                      className={classes.alignFlexCenter}
                      style={{ margin: '0 10px' }}
                    >
                      <Typography>Free Calendar Year</Typography>
                    </div>
                    {freeCalendarsOptions.length ? (
                      <FormControl
                        variant="outlined"
                        className={classes.formLabelControl}
                        style={{
                          backgroundColor: currentYear
                            ? getSchoolBackgroundColor(currentYear)
                            : 'white'
                        }}
                      >
                        <Select
                          labelId="country-select-outlined-label"
                          id="country-select-outlined"
                          value={currentYear}
                          onChange={handleYearChange}
                          name="country"
                          displayEmpty={true}
                          classes={{
                            icon: currentYear
                              ? classes.muiSelectIconWhite
                              : classes.muiSelectIcon
                          }}
                        >
                          <MenuItem
                            value=""
                            selected={true}
                            style={{
                              backgroundColor: colors.white,
                              color: colors.primaryDark
                            }}
                          >
                            <em style={{ color: colors.primaryDark }}>All</em>
                          </MenuItem>
                          <MenuItem value="all">
                            <div className={classes.alignFlexCenter}>
                              <div
                                className={clsx(classes.headerDot)}
                                style={{
                                  backgroundColor: '#6b747b'
                                }}
                              />
                              <Typography>All Year</Typography>
                            </div>
                          </MenuItem>
                          {freeCalendarsOptions.map((year, index) => {
                            return (
                              <MenuItem
                                key={`${year.id}-${year.index}`}
                                value={year.id}
                              >
                                <div
                                  className={classes.alignFlexCenter}
                                  // style={{ margin: '0 10px' }}
                                >
                                  <div
                                    className={clsx(classes.headerDot)}
                                    style={{
                                      backgroundColor: year.color
                                    }}
                                  />
                                  <Typography>{year.value}</Typography>
                                </div>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </Paper>
            {freeCalendarsOptions.length && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <ThemeProvider theme={materialTheme}>
                    <Paper>
                      <DatePicker
                        autoOk
                        okLabel=""
                        cancelLabel=""
                        renderDay={_renderDayInPicker}
                        variant="static"
                        value={date}
                        onChange={(e) => _dateChangeHandle(e)}
                        // showTodayButton
                        disableToolbar
                      />
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.todayButton}
                        startIcon={<TodayOutlined />}
                        onClick={() => _dateChangeHandle(moment())}
                      >
                        Today
                      </Button>
                    </Paper>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <div
                    className={classes.calendarEntryWrapper}
                    ref={calendarScoll}
                    onScroll={handleScroll}
                  >
                    {lodingEntries && (
                      <div className={classes.loadingEntries}>Loading...</div>
                    )}

                    {dataInscroll.length > 0 && !lodingEntries
                      ? dataInscroll.map((data, index) =>
                          _singleCalendarEntry(data, index)
                        )
                      : !lodingEntries && (
                          <div className={classes.loadingEntriesDots}>
                            ........
                          </div>
                        )}
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
          <RightDrawer
            isOpen={isOpen}
            onClose={() => {
              setOpen(false);
              // setEntryData([]);
              // getEntries();
            }}
            width={900}
          >
            {buttonState === 'Add Year' ? (
              <CreateSchoolCalenderEntry
                catgeoryButton={catgeoryButton}
                buttonState={buttonState}
                setRightOpen={setOpen}
                hasData={hasEntryData}
                setHasEntryData={setEntryData}
                isOpen={isOpen}
                getEntries={(date) => {
                  changeDate(date);
                  setLodingEntries(true);
                }}
                date={date}
                allCalenderData={allCalenderData}
                goSourceEntry={goSourceEntry}
              />
            ) : (
              (buttonState === undefined ||
                buttonState === categoryItem?.[0] ||
                buttonState === categoryItem?.[1] ||
                buttonState === categoryItem?.[2] ||
                buttonState === categoryItem?.[3]) && (
                <>
                  <CategoryGroupCalendarEntry
                    buttonState={buttonState}
                    catgeoryButton={catgeoryButton}
                    setRightOpen={setOpen}
                    hasData={hasEntryData}
                    setHasEntryData={setEntryData}
                    isOpen={isOpen}
                    onClose={() => {
                      setOpen(false);
                      // setEntryData([]);
                      //getEntries();
                    }}
                    getEntries={(date) => {
                      changeDate(date);
                      setLodingEntries(true);
                    }}
                    date={date}
                    allCalenderData={allCalenderData}
                    goSourceEntry={goSourceEntry}
                  />
                </>
              )
            )}
          </RightDrawer>
        </MuiPickersUtilsProvider>
        <RightDrawer
          isOpen={isOpenDrawer}
          onClose={() => {
            onClose();
            onCloseForm();
          }}
          width={900}
          style={{ overflowX: 'hidden' }}
        >
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="primary" />
          </Backdrop>
          <div className={classes.drawerLeftContent}>
            <div className={classes.leftContentMargin}>
              <div className={classes.leftContentHeader}>
                {isEditMode ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.siderEdit}
                    onClick={() => {
                      const defaultData = draftToHtml(
                        convertToRaw(defaultNote.getCurrentContent())
                      );
                      const memberNotes = memberData.notes;
                      if (defaultData === memberNotes) {
                        handleClose();
                      } else if (
                        defaultData.replace(/(\r\n|\n|\r)/gm, '') ===
                          '<p></p>' &&
                        !memberNotes
                      ) {
                        handleClose();
                      } else {
                        onSaveChanges();
                      }
                    }}
                    style={{ width: 165 }}
                  >
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.siderEdit}
                    onClick={() => setEditMode(true)}
                    startIcon={<img src={Edit} />}
                  >
                    Edit
                  </Button>
                )}
              </div>
              <Divider
                variant="fullWidth"
                style={{ marginBottom: 20, marginTop: 22 }}
              />
              {!isEditMode && !memberData.notes && (
                <Typography className={classes.siderDetail}>
                  Click Edit to add a note...
                </Typography>
              )}
              {!isEditMode &&
                memberData.notes &&
                memberData.notes.replace(/(\r\n|\n|\r)/gm, '') ===
                  '<p></p>' && (
                  <Typography className={classes.siderDetail}>
                    Click Edit to add a note...
                  </Typography>
                )}
              <Editor
                stripPastedStyles={true}
                readOnly={!isEditMode}
                defaultEditorState={defaultNote}
                toolbarClassName={
                  !isEditMode ? classes.toolbarHidden : classes.toolbar
                }
                onEditorStateChange={onEditorChange}
                editorClassName={
                  !isEditMode ? classes.textEditor : classes.textEditorOnEdit
                }
                toolbar={{
                  options: ['inline', 'list', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough']
                  },
                  list: {
                    options: ['unordered']
                  },
                  link: {
                    defaultTargetOption: '_blank',
                    options: ['link']
                  }
                }}
              />
            </div>
          </div>
          <div className={classes.drawerRightContent}>
            <div style={{ padding: 18, zIndex: 1 }}>
              <div className={classes.header}>
                {memberData.schoolName && (
                  <Typography
                    className={classes.textwraplable}
                    title={memberData.schoolName}
                  >
                    {memberData.schoolName}
                  </Typography>
                )}
              </div>
              <Divider style={{ marginBottom: 20 }} />
              {memberData.country && (
                <>
                  <Typography className={classes.heading}>COUNTRY</Typography>
                  <Typography className={classes.siderDetail}>
                    {memberData.country}
                  </Typography>
                </>
              )}
              {memberData.websiteUrl && (
                <>
                  <Typography className={classes.heading}>WEBSITE</Typography>
                  <Link
                    color="secondary"
                    component={Link}
                    onClick={() => window.open(memberData.websiteUrl, '_blank')}
                    className={classes.siderDetail}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    Click Here
                  </Link>
                </>
              )}
            </div>
            <div className={classes.handBookContainer}>
              <img src={HandBook} alt="" className={classes.handBookClass} />
            </div>
          </div>
        </RightDrawer>
      </Dashboard>
    </>
  );
}

export default withFirebase(EditSchoolCalendarView);
