import React, { useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Button,
  makeStyles,
  FormHelperText,
  InputAdornment
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import FullPage from '../../layout/FullPage';
import { withFirebase, FIREBASE_API_URL } from '../../../utils/firebase';
import { getParameterByName, encryptSHA256 } from '../../../utils/functions';
import {
  loaderStop,
  loaderStart,
  addMessage
} from '../../../redux/actions/appActions';
import { colors } from 'src/utils/constant';
import { MESSAGE_TYPES } from 'src/constants/common';

// SCHEMA VALIDATOR FOR
const createNewPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .trim()
    .matches(/^((?!-).)*$/, 'No Hypen is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
      'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.'
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
    .required('Passwords do not match')
});

const useStyles = makeStyles(() => ({
  constWrapper: {
    marginLeft: 55,
    marginRight: 55
  },
  imageWrapper: {
    marginRight: '20px'
  }
}));

function SetNewPasswordView({ firebase }) {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [newPasswordFieldType, setNewPasswordFieldType] = useState('password');
  const [errorFromFirebase, setErrorFromFirebase] = useState('');
  const [confirmPasswordFieldType, setConfirmPasswordFieldType] =
    useState('password');

  const onToggleNewPasswordFieldType = () => {
    if (newPasswordFieldType === 'password') {
      setNewPasswordFieldType('text');
    } else {
      setNewPasswordFieldType('password');
    }
  };

  const onToggleConfirmPasswordFieldType = () => {
    if (confirmPasswordFieldType === 'password') {
      setConfirmPasswordFieldType('text');
    } else {
      setConfirmPasswordFieldType('password');
    }
  };

  const _handleFormSubmit = useCallback(async (submittedFormFieldValues) => {
    const { newPassword } = submittedFormFieldValues;
    const currentUrl = window.location.href;
    const pathname = window.location.pathname.slice(1);
    const actionCode = getParameterByName('oobCode', currentUrl);
    const mode = getParameterByName('mode', pathname);
    const email = location.state;
    const emailID = localStorage.getItem('emailID');
    const userID = localStorage.getItem('userID');
    const getEmailFromActionData = async (data) => {
      const auth = firebase.auth;
      const email = await auth.verifyPasswordResetCode(data);
      return email;
    };
    if (actionCode) {
      if (pathname === 'reset-password') {
        const auth = firebase.auth;
        const emailFromAction = await getEmailFromActionData(actionCode);
        dispatch(loaderStart());
        const requestOptions = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: emailFromAction || email || emailID,
            newPassword: encryptSHA256(newPassword)
          })
        };
        fetch(`${FIREBASE_API_URL}/adminResetPassword`, requestOptions)
          .then((response) => {
            const result = response.json();
            if (!response.ok) {
              throw new Error(result?.message);
            }
            return result
          })
          .then(
            (result) => {
              if (result.code === 200) {
                auth
                  .confirmPasswordReset(actionCode, newPassword)
                  .then((resp) => {
                    dispatch(loaderStop());
                    dispatch(addMessage('Password Updated'));
                    history.push('/login');
                  })
                  .catch((error) => {
                    dispatch(loaderStop());
                    const errorMessage = _.get(error, 'message', '');
                    setErrorFromFirebase(errorMessage);
                  });
              } else {
                dispatch(loaderStop());
                dispatch(addMessage(result.message));
              }
            },
            (error) => {
              console.log('Error: ', error);
              dispatch(addMessage(error.message, MESSAGE_TYPES.ERROR));
              dispatch(loaderStop());
            }
          )
          .catch((error) => {
            console.log(error, 'error');
            dispatch(loaderStop());
            const errorMessage = _.get(error, 'message', '');
            setErrorFromFirebase(errorMessage);
          });
      } else {
        setErrorFromFirebase('Invalid URL');
      }
    } else {
      if (pathname === 'reset-password') {
        const auth = firebase.auth;
        if (userID && (email || emailID)) {
          dispatch(loaderStart());
          const requestOptions = {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: email || emailID,
              newPassword: encryptSHA256(newPassword)
            })
          };
          fetch(`${FIREBASE_API_URL}/adminResetPassword`, requestOptions)
            .then((response) => {
              const result = response.json();
              if (!response.ok) {
                throw new Error(result?.message);
              }
              return result
            })
            .then(
              (result) => {
                if (result.code === 200) {
                  const requestOption = {
                    method: 'POST',
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      user_id: userID,
                      new_password: newPassword,
                      confirm_password: newPassword
                    })
                  };
                  fetch(`${FIREBASE_API_URL}/changePassword`, requestOption)
                    .then((response) => {
                      const result = response.json();
                      if (!response.ok) {
                        throw new Error(result?.message);
                      }
                      return result
                    })                  
                    .then((result) => {
                      dispatch(loaderStop());
                      dispatch(addMessage(result.message));
                      history.push('/login');
                    })
                    .catch((err) => {
                      console.log(err, 'error');
                      dispatch(addMessage(err.message, MESSAGE_TYPES.ERROR));
                    });
                } else {
                  dispatch(loaderStop());
                  dispatch(addMessage(result.message));
                }
              },
              (error) => {
                console.log('Error: ', error);
                dispatch(addMessage(error.message, MESSAGE_TYPES.ERROR));
                dispatch(loaderStop());
              }
            )
            .catch((error) => {
              console.log(error, 'error');
              dispatch(loaderStop());
              const errorMessage = _.get(error, 'message', '');
              setErrorFromFirebase(errorMessage);
            });
        } else {
          setErrorFromFirebase('email or user id is not found');
        }
      } else {
        setErrorFromFirebase('Invalid URL');
      }
    }
  });

  return (
    <FullPage>
      <div className={classes.constWrapper}>
        <Typography variant="h2" color="textPrimary" gutterBottom={true}>
          Create New Password
        </Typography>
        <Formik
          initialValues={{
            newPassword: '',
            confirmPassword: ''
          }}
          validationSchema={createNewPasswordSchema}
          onSubmit={(values) => {
            _handleFormSubmit(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            // isSubmitting,
            touched,
            values
          }) => {
            let isSubmitButtonDisabled =
              _.isEmpty(values.newPassword) ||
              _.isEmpty(values.confirmPassword);

            if (
              !_.isEmpty(touched) &&
              !isSubmitButtonDisabled &&
              !_.isEmpty(errors)
            ) {
              isSubmitButtonDisabled = true;
            }
            return (
              <form noValidate onSubmit={handleSubmit}>
                <TextField
                  error={
                    Boolean(touched.newPassword && errors.newPassword) ||
                    !_.isEmpty(errorFromFirebase)
                  }
                  fullWidth
                  helperText={touched.newPassword && errors.newPassword}
                  label="New Password"
                  autoComplete="off"
                  margin="normal"
                  name="newPassword"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setErrorFromFirebase(false);
                    handleChange(e);
                  }}
                  type={newPasswordFieldType}
                  value={values.newPassword}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle newPassword visibility"
                          onClick={() => onToggleNewPasswordFieldType()}
                          tabIndex="-1"
                        >
                          <img
                            alt="Password Requirement"
                            src={
                              newPasswordFieldType === 'text'
                                ? '/static/images/password-hide.png'
                                : '/static/images/password-see.png'
                            }
                            width="22"
                            height="22"
                            style={{ opacity: 0.7 }}
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  error={
                    Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    ) || !_.isEmpty(errorFromFirebase)
                  }
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  label="Confirm Password"
                  autoComplete="off"
                  margin="normal"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setErrorFromFirebase(false);
                    handleChange(e);
                  }}
                  type={confirmPasswordFieldType}
                  value={values.confirmPassword}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirmPassword visibility"
                          onClick={() => onToggleConfirmPasswordFieldType()}
                          tabIndex="-1"
                        >
                          <img
                            alt="Password Requirement"
                            src={
                              confirmPasswordFieldType === 'text'
                                ? '/static/images/password-hide.png'
                                : '/static/images/password-see.png'
                            }
                            width="22"
                            height="22"
                            style={{ opacity: 0.7 }}
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                {errorFromFirebase && (
                  <Box ml={2} mb={4}>
                    <FormHelperText error>{errorFromFirebase}</FormHelperText>
                  </Box>
                )}
                <Box mt={2}>
                  <Button
                    disabled={isSubmitButtonDisabled}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color={isSubmitButtonDisabled ? 'secondary' : 'primary'}
                  >
                    Submit
                  </Button>
                  {errors.submit && (
                    <Box mt={3}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </Box>
              </form>
            );
          }}
        </Formik>
        <Box
          mt={6}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <img
            alt="Password Requirement"
            src="/static/images/password-requirement.png"
            width="75"
            className={classes.imageWrapper}
          />
          <Typography style={{ color: 'rgba(76, 91, 104, 0.6)' }}>
            Password must contain at least 8 characters, at least one uppercase
            letter, at least one lowercase letter and at least one number.It
            must not be the same as your last 5 used.
          </Typography>
        </Box>
      </div>
    </FullPage>
  );
}

export default withFirebase(SetNewPasswordView);
