import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import {
  AppBar,
  Divider,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import { colors } from 'src/utils/constant';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'none',
    boxShadow: 'none'
  },
  tabs: {
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    color: colors.primaryDarkGrey
  },
  tab: {
    minWidth: 88,
    textTransform: 'none'
  },
  selectedTab: {
    backgroundColor: colors.primary,
    height: '4px'
  },
  dividerClass: {
    borderBottom: '1px solid #e0e0e0',
    marginTop: '-10.5px'
  },
  searchIconContainer: {
    marginLeft: 'auto',
    height: 24,
    width: 24,
    color: colors.primaryDarkGrey
  },
  searchIcon: {
    height: 24,
    width: 24
  },
  inputPropSearchField: {
    width: '398px',
    border: 'none',
    '&:after': {
      content: '',
      border: 'none !important'
    },
    '&:before': {
      content: '',
      border: 'none !important'
    }
  },
  searchFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  searchClearIcon: {
    color: colors.primaryDarkGrey,
    cursor: 'pointer'
  }
}));

const TabViewWithSearch = ({
  tabOptions = [],
  handleSearchChange,
  searchPlacehlder = '',
  isDisabled = false,
  disabledSearch = false,
}) => {
  const classes = useStyles();
  const [tabOption, setTabOption] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (event, newValue) => {
    setTabOption(newValue);
  };

  const toggleSearch = () => {
    handleSearch('');
    setSearchOpen(!searchOpen);
  };

  const handleSearch = (searchedValue) => {
    setSearchValue(searchedValue);
    handleSearchChange && handleSearchChange(searchedValue);
  };

  return (
    <AppBar
      position="static"
      className={classes.appBar}
      style={isDisabled ? { opacity: 0.5 } : {}}
    >
      <Toolbar>
        {searchOpen ? (
          <div className={classes.searchFieldContainer}>
            <TextField
              placeholder={searchPlacehlder}
              autoFocus
              InputProps={{
                className: classes.inputPropSearchField,
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue && (
                      <span
                        className={classes.searchClearIcon}
                        onClick={() => handleSearch('')}
                      >
                        Clear
                      </span>
                    )}
                  </InputAdornment>
                )
              }}
              fullWidth
              value={searchValue}
              onChange={(event) => handleSearch(event.target.value)}
            />
            <IconButton
              onClick={toggleSearch}
              className={classes.searchIconContainer}
            >
              <CloseIcon className={classes.searchIcon} />
            </IconButton>
          </div>
        ) : (
          <>
            <Tabs
              value={tabOption}
              onChange={handleChange}
              className={classes.tabs}
              classes={{ indicator: classes.selectedTab }}
            >
              {' '}
              {tabOptions.map(({ label, disabled }) => (
                <Tab
                  label={label}
                  key={label}
                  disabled={disabled || isDisabled}
                  className={classes.tab}
                />
              ))}
            </Tabs>

            <IconButton
              onClick={toggleSearch}
              className={classes.searchIconContainer}
              disabled={isDisabled || disabledSearch}
            >
              <SearchIcon className={classes.searchIcon} />
            </IconButton>
          </>
        )}
      </Toolbar>
      <Divider variant="fullWidth" className={classes.dividerClass} />
    </AppBar>
  );
};

export default TabViewWithSearch;
