import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function CornerDownRight({ color, ...props }) {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path id="prefix__a" d="M20.056 14.186l-.074-.084c.037.038.07.078.1.12.014.019.027.039.04.06l.021.038c.012.022.023.044.032.067l.013.031c.011.028.02.057.029.086l.005.022c.006.022.01.044.015.067l.006.044c.005.041.007.077.007.113 0 .08-.01.158-.03.232l-.015.051-.02.058-.021.047-.03.059-.027.045-.038.055-.011.015-.016.02-.044.05-.016.016-4.584 4.584c-.358.357-.938.357-1.296 0-.33-.33-.356-.85-.076-1.21l.076-.087 3.018-3.019H8.333c-2.464 0-4.475-1.945-4.579-4.384l-.004-.199V4.667c0-.507.41-.917.917-.917.47 0 .857.354.91.81l.006.107v6.416c0 1.465 1.145 2.662 2.589 2.746l.161.004h8.786l-3.017-3.018c-.33-.33-.356-.85-.076-1.21l.076-.087c.33-.33.85-.355 1.21-.076l.086.076 4.584 4.584c.026.026.051.055.074.084z" />
        </SvgIcon>
    );
}
export default CornerDownRight;
