import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Checkbox, Typography, makeStyles, FormControlLabel } from '@material-ui/core';
import { routes, colors } from '../../../utils/constant';
import FullPage from '../../layout/FullPage';
import LoginForm from './LoginForm';

const useStyles = makeStyles(() => ({
  a: {
    textDecorationColor: 'transparent',
    color: colors.primaryDark
  }
}));

function LoginView() {
  const classes = useStyles();
  useEffect(() => {
    localStorage.removeItem('isCodeVerified');
  }, []);

  return (
    <FullPage>
      <Typography variant="h2" color="textPrimary">
        Sign In
      </Typography>
      <Box mt={3}>
        <LoginForm />
      </Box>
      <Box
        mt={4.5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: 16 }}
      >
        <Box display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox color="primary" />
            }
            label="Remember Me"
            style={{display : 'none'}}
          />
        </Box>
        <RouterLink to={routes.forgotPassword} className={classes.a}>
          <Typography variant="body1">Forgot Password</Typography>
        </RouterLink>
      </Box>
    </FullPage>
  );
}

export default LoginView;
