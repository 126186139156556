import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";

export const indexAgentStyles = makeStyles(() => ({
  tableRow: {
    height: 72,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: `rgba(244, 245, 247, 0.51) !important`,
      '& svg g': {
        fill: colors.primary
      }
    }
  },
  dataTableCell: {
    color: colors.primaryDark,
    padding: '10px 16px',
    '&:first-child': {
      paddingLeft: 52
    }
  },
  nameColumn: {
    verticalAlign: 'middle',
    display: 'flex',
    lineHeight: '50px',
    fontWeight: 500,
    '& .MuiAvatar-root': {
      marginRight: 24,
      fontWeight: 500,
      height: 50,
      width: 50,
      fontSize: 14,
      color: colors.primaryDarkGrey,
      backgroundColor: colors.primaryLightGrey
    }
  },
  actionButton: {
    padding: '8px 17px',
    fontSize: 16,
    lineHeight: '24px',
    float: 'right',
    margin: '-10px 16px 15px auto'
  },
  startIcon: {
    marginRight: 12
  },
  actionIcon: {
    height: 50,
    width: 50,
    padding: 10,
    cursor: 'pointer',
    margin: 'auto',
    '&:hover': {
      backgroundColor: colors.primary,
      '& svg g': {
        fill: colors.white
      }
    }
  },
  siderSave: {
    width: 95,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary
  },
  mainHeading: {
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  subHeading: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    width: 344
  },
  dividerClass: {
    marginBottom: 8,
    marginTop: 22,
    width: "427px"
  },
  topDividerClass:{
    margin: "32px 0 0 !important",
  },
  imageContainer: {
    width: 100,
    height: 100,
    backgroundColor: colors.primaryLightGrey,
    marginTop: 16,
    borderRadius: '50%',
    fontSize: '32px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: colors.primaryDarkGrey
  },
  inputControlBox: {
    width: 427,
    backgroundColor: '#ffffff',
    marginTop: 24
  },
  popoverClass: {
    '& .MuiPaper-rounded': {
      right: '70px',
      left: 'unset  !important',
      top: '70px !important'
    }
  },

  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    // width: 523,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  modalContent: {
    minHeight: 75,
    paddingTop: 12
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark
  },
  modalBox: {
    width: 450
    // height: 170,
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  modalActions: {
    marginTop: '2%'
  },
  headingContainer: {
    // position: 'relative'
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    padding: "45px 24px 0 24px"
  },
  formMargin: { margin: '10px 30px', marginBottom: 105 },
  boldFont: { fontWeight: 'bold' },
  headingMargin: { marginBottom: 20 },

  profileImageWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  removeImageContainer: {
    marginLeft: 30,
    marginTop: 20,
    color: colors.primaryDarkGrey,
    cursor: 'pointer'
  },
  uploadImageText: {
    fontSize: 14,
    color: colors.primaryDarkGrey,
    fontWeight: 'bold'
  },
  generate:{
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: colors.primary,
    float: 'right',
    display: 'flex',
    height: '30px',
    lineHeight: '30px',
    marginTop: '16px',
    alignItems: 'center',
    "& svg":{
      marginRight: "12px"
    }
  },
  closeIconRotate: {
    transform: 'rotate(-180deg)',
    transition: 'transform 999ms ease',
    fill: colors.primary
  },
  moreVertButton: {
    minWidth: "24px !important",
    padding: "0px !important",
    marginLeft: 24,
    "& svg": {
      width: "24px !important",
      height: "24px !important"
    }
  }
}));