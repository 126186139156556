import React, { useState, Fragment, useEffect } from 'react';
import {
  Typography,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Chip,
  FormHelperText,
  ThemeProvider,
  Radio,
  RadioGroup,
  FormLabel,
  Switch
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import clsx from 'clsx';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import { EditorState } from 'draft-js';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import Modal from 'src/components/Modal';
import {
  colors,
  labels,
  taskLabels,
  muiTimePickerTheme,
  year as DefaultYear,
  default_PN_time,
  multiYear
} from 'src/utils/constant';
import { SchoolsIcon, CornerDownRight } from 'src/assets/Icons';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import { Editor } from 'react-draft-wysiwyg';
import CustomDateModal from '../families/components/CustomeDateModal';
import { useSelector, useDispatch } from 'react-redux';
import { withFirebase } from 'src/utils/firebase';
import {
  loaderStart,
  loaderStop,
  addMessage,
  setFamilyOrSchoolPreserveEntryData
} from 'src/redux/actions/appActions';
import {
  generateFileHash,
  sortLinkedEntries,
  toGetContentFromHTML,
  toGetHTMLContent
} from 'src/utils/functions';
import { EditPencil, Calendar, LocationIcon } from 'src/assets';
import GroupEditSection from '../families/components/GroupEditSection';
import { getSchools } from 'src/services/schools';
import { getGroupBySchoolId } from 'src/services/groups';
import { createSchoolCalenderEntryStyles } from 'src/styles/schoolStyle/createSchoolCalenderEntryStyles';
import EditorComponent from 'src/components/EditorComponent';
import { deepEqual } from 'src/utils/deepEqual';
import { entryValidationSchema } from 'src/utils/validationSchemas';
import { virtualEntries, setFromToTimeFormat } from 'src/utils/virtualEntryJSON';
import { MESSAGE_TYPES } from 'src/constants/common';
import { manageSchoolEntries } from 'src/services/entries';

const entryObject = {
  entries: [
    {
      id: generateFileHash(20),
      isLowPriority: false,
      isPrimary: true,
      linkedArray: [],
      label: '',
      title: '',
      location: '',
      description: '',
      expand: true,
      checkDescription: false,
      for: '',
      primary_description: {
        description: '',
        from: {
          date: moment().format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        }
      },
      from: {
        date: moment().format('MMM DD,YYYY'),
        time: moment().format('h:mma')
      },
      to: {
        date: moment().format('MMM DD,YYYY'),
        time: moment().format('h:mma')
      },
      allDay: true,
      actions: {
        value: '',
        repeat: [],
        notification: [
          {
            days: -1,
            date: moment().subtract(1, 'days').format('MMM DD,YYYY'),
            time: default_PN_time
          },
          {
            days: '',
            date: moment().format('MMM DD,YYYY'),
            time: default_PN_time
          }
        ],
        deadline: [],
        reminder: [],
        isCancelled: false,
        isPostponed: false,
        isChanged: false,
        isComplete: false
      },
      newFromTimeFormate: { hh: '', mm: '', ap: '' },
      newToTimeFormate: { hh: '', mm: '', ap: '' }
    }
  ]
};

function CreateSchoolCalenderEntry({
  firebase,
  buttonState,
  setRightOpen,
  hasData,
  isOpen,
  getEntries,
  date,
  allCalenderData,
  setHasEntryData,
  goSourceEntry,
  catgeoryButton
}) {
  const classes = createSchoolCalenderEntryStyles();
  const dispatch = useDispatch();
  const hasFamilyOrSchoolEntryData = useSelector(
    (state) => state.app.hasFamilyOrSchoolEntryData
  );
  const currentSchool = useSelector((state) => state.school.currentSchool);
  const getCategoryTimings = () => {
    const formID =
      currentSchool?.notificationsTiming &&
      Object.keys(currentSchool?.notificationsTiming)?.find(
        (key) => catgeoryButton === key
      );
    if (formID) {
      const newTime = moment(currentSchool?.notificationsTiming[formID]).format(
        'h:mma'
      );

      return newTime;
    } else {
      return moment(default_PN_time).format('h:mma');
    }
  };
  const currentUser = useSelector((state) => state.auth.user);
  const [recurringEntriesErrors, setRecurringEntriesErrors] = useState({
    message: ''
  });
  const [initialEntry, setInitialEntry] = useState({
    entries: [
      {
        ...entryObject.entries[0],
        id: generateFileHash(20),
        primaryID: '',
        actionParent: { parentID: '', type: '' },
        // PHASE2/CHANGES/SCHOOL (KEY_MODIFIED)
        school: currentSchool?.id,
        agentName: currentUser?.formData?.firstName,
        from: {
          date: date.format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        },
        to: {
          date: date.format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        },
        actions: {
          ...entryObject.entries[0].actions,
          notification: [
            {
              days: -1,
              date: moment(date.format('MMM DD,YYYY'))
                .subtract(1, 'days')
                .format('MMM DD,YYYY'),
              time: getCategoryTimings()
            },
            {
              days: '',
              date: moment(date.format('MMM DD,YYYY')).format('MMM DD,YYYY'),
              time: getCategoryTimings()
            }
          ]
        }
      }
    ]
  });
  const [isOpenModal, setOpenModal] = useState({ open: false, value: [] });
  const [isRepeatEntryModal, setRepeatEntryModal] = useState(false);
  const [repeatEntryRadioData, setRepeatEntryRadioData] =
    useState('This Event');

  const [freeCalendarsOptions, setFreeCalendarsOptions] = useState(null);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenDeleteLinkedModal, setOpenDeleteLinkedModal] = useState({
    open: false,
    id: null
  });
  const [isEditEntry, setisEditEntry] = useState(false);
  const [editForWholeGroup, setEditForWholeGroup] = useState(false);
  const [customValue, setCustomValue] = useState({});
  const [updatedEntry, setUpdatedEntry] = useState({});
  const [defaultNote, setDefaultNote] = useState(EditorState.createEmpty());
  const isLoading = useSelector((state) => state.app.isLoading);
  const [groupYears, setGroupYears] = useState([]);
  const [isRepeatChange, setIsRepeatChange] = useState(false);
  const [changePrimaryDay, setChangePrimaryDay] = useState('');
  const [schools, setSchools] = useState([]);
  const [convertNotificationTime, setConvertNotificationTime] = useState({
    hh: null,
    mm: null,
    ap: null
  });

  useEffect(() => {
    const freeCalendarsArray = currentSchool.freeCalendars.map((year) => {
      const getCurrentYear = _.find(DefaultYear, (elem) => elem.value === year);
      return { id: year, value: getCurrentYear?.label };
    });
    setFreeCalendarsOptions(freeCalendarsArray);
  }, [currentSchool, setFreeCalendarsOptions]);

  useEffect(() => {
    // PHASE2/CHANGES/SCHOOL
    getSchools()
      .then((result) => {
        const schools = [];
        result.forEach(function (school) {
          schools.push({
            id: school.id,
            name: school.schoolName,
            isFree: school.freeCalendars.length ? true : false,
            years: school.freeCalendars
          });
        });
        setSchools(schools);
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
      });
  }, []);

  useEffect(
    () => {
      if (!isOpen) {
        if (isEditEntry) {
          dispatch(
            setFamilyOrSchoolPreserveEntryData({
              entries: [...entryObject.entries]
            })
          );
          setisEditEntry(false);
        } else {
          dispatch(setFamilyOrSchoolPreserveEntryData(updatedEntry));
        }
      }
    },
    // eslint-disable-next-line
    [isOpen, hasData]
  );

  // setting notification of next day auto update
  const setNotificationTime = (convertNotificationTime, values, idx) => {
    let newTime = '';
    if (convertNotificationTime.hh !== null) {
      let hours =
        parseInt(convertNotificationTime.hh) === 1
          ? '12'
          : parseInt(convertNotificationTime.hh) - 1;
      let minute =
        convertNotificationTime.mm !== null ? convertNotificationTime.mm : '00';
      let ap =
        convertNotificationTime.ap !== null ? convertNotificationTime.ap : 'a';

      if (
        parseInt(convertNotificationTime.hh) === 12 &&
        convertNotificationTime.ap === 'p'
      ) {
        ap = 'a';
      } else if (
        parseInt(convertNotificationTime.hh) === 12 &&
        convertNotificationTime.ap === 'a'
      ) {
        ap = 'p';
      }
      newTime = hours + ':' + minute + ap + 'm';
    }
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === idx) {
        const notificationArray = data.actions.notification.map(
          (notificationData, notificationIndex) => {
            if (notificationIndex !== 0) {
              return {
                ...notificationData,
                time: newTime
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, notification: notificationArray }
        };
      } else {
        return { ...data };
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };
  useEffect(
    () => {
      if (hasData && hasData.length) {
        if (hasData[0].school) {
          getGroupBySchoolId(hasData[0].school).then(function (result) {
            let GroupData = [];
            let years = [];
            let selectedYear = [];
            const findYears = _.find(schools && schools, (school) => {
              return school?.id === hasData[0]?.school;
            });
            years = findYears?.years;
            if (
              ((hasData[0].groups && hasData[0].groups.length) ||
                hasData[0].for === multiYear) &&
              !!hasData[0].forYears
            ) {
              selectedYear = findYears?.years
                ?.map((item) => {
                  if (hasData[0].forYears.includes('all')) {
                    return item;
                  }
                  if (
                    item === '0' &&
                    (hasData[0].forYears.includes('Kindy') ||
                      hasData[0].forYears.includes('0'))
                  ) {
                    return '0';
                  }
                  if (
                    item === '-1' &&
                    (hasData[0].forYears.includes('Pre K') ||
                      hasData[0].forYears.includes('-1'))
                  ) {
                    return '-1';
                  }
                  if (hasData[0].forYears.includes(item)) {
                    return item;
                  }
                })
                .filter((x) => x !== undefined);
            }

            if (result.data.length) {
              result.data.forEach(function (item) {
                if (item.groupType === 'Free') {
                  GroupData.push({
                    key: item.groupName,
                    value: item.id,
                    selected:
                      hasData[0].groups &&
                      hasData[0].groups.length &&
                      hasData[0].groups.includes(item.id)
                        ? true
                        : false,
                    years: item.years,
                    groupMembers: item.groupMembers
                  });
                }
              });
            }
            years = _.uniq(years);
            selectedYear = _.uniq(selectedYear);
            let updatedYears = [
              {
                key: 'All Years',
                value: 'all',
                selected: years.length === selectedYear.length ? true : false
              }
            ];
            years.forEach((item) => {
              if (item !== 'all') {
                let yearValue =
                  item && item === 'Pre K'
                    ? '-1'
                    : item === 'Kindy'
                    ? '0'
                    : item?.split('Year ').reverse()[0];
                const getCurrentYear = _.find(
                  DefaultYear,
                  (elem) => elem.value === yearValue
                );
                updatedYears.push({
                  key: getCurrentYear?.label,
                  value: yearValue,
                  selected: selectedYear?.includes(item)
                });
              }
              return null;
            });
            setGroupYears(updatedYears);
          }).catch(error => {
            console.log('Error getting documents: ', error);
            // dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
            dispatch(addMessage(error.message, MESSAGE_TYPES.ERROR));
          });
        }
        // Setting default Note for description check
        if (hasData[0].description) {
          setDefaultNote(toGetContentFromHTML(hasData[0].description));
        }
        // updating push notification PN
        const defaultTime = hasData[0]?.actions?.notification[0]?.time;
        if (defaultTime !== '') {
          const timeSplit = defaultTime?.split(':');
          if (timeSplit && timeSplit[1]?.includes('a')) {
            const amTime = timeSplit && timeSplit[1]?.split('a');
            setConvertNotificationTime({
              ...convertNotificationTime,
              hh: timeSplit && timeSplit[0],
              mm: amTime && amTime[0],
              ap: 'a'
            });
          } else {
            const pmTime = timeSplit && timeSplit[1]?.split('p');
            setConvertNotificationTime({
              ...convertNotificationTime,
              hh: timeSplit && timeSplit[0],
              mm: pmTime && pmTime[0],
              ap: 'p'
            });
          }
        }
        const updateValue = hasData.map((entry) => {
          // Updating for key value from number to "year" keyword --start
          if (entry.for !== multiYear) {
            entry.forYears = [entry.for];
            entry.for = multiYear;
          }
          // Updating for key value from number to "year" keyword --end
          if (!entry.school) {
            entry.school = currentSchool.id;
          }
          return entry;
        });
        setisEditEntry(true);
        // short linked entries incorrect order issue - fix
        const sortUpdatedArray = sortLinkedEntries(updateValue);
        setInitialEntry({ entries: [...sortUpdatedArray] });
      } else if (
        hasFamilyOrSchoolEntryData &&
        hasFamilyOrSchoolEntryData.entries
      ) {
        let primaryEntryID = '';
        const updatedPreserveEntryData = hasFamilyOrSchoolEntryData.entries.map(
          (entry) => {
            const freeCalendarsMultipleArray = currentSchool.freeCalendars.map(
              (year) => {
                const getCurrentYear = _.find(
                  DefaultYear,
                  (elem) => elem.value === year
                );
                return {
                  value: year,
                  key: getCurrentYear?.label,
                  selected:
                    entry.for.length && entry.for.includes(year) ? true : false
                };
              }
            );
            entry.freeCalendarsMultipleOptions = [
              {
                key: `All Years`,
                value: 'all',
                selected: entry.for.includes('all') ? true : false
              },
              ...freeCalendarsMultipleArray
            ];
            entry.id = generateFileHash(20);
            entry.agentName = currentUser.firstName;
            if (entry.isPrimary) {
              primaryEntryID = entry.id;
            } else {
              entry.primaryID = primaryEntryID;
            }

            const onDayBefore = moment(entry.from.date, 'MMM DD,YYYY')
              .subtract(1, 'days')
              .format('MMM DD,YYYY');
            entry.actions = {
              ...entry.actions,
              notification: [
                { days: -1, date: onDayBefore, time: getCategoryTimings() },
                { days: '', date: entry.from.date, time: getCategoryTimings() }
              ]
            };

            return entry;
          }
        );
        setInitialEntry({ entries: [...updatedPreserveEntryData] });
      } else {
        const updatedPreserveEntryData = initialEntry.entries.map((entry) => {
          const freeCalendarsMultipleArray = currentSchool.freeCalendars.map(
            (year) => {
              const getCurrentYear = _.find(
                DefaultYear,
                (elem) => elem.value === year
              );
              return {
                value: year,
                key: getCurrentYear?.label,
                selected: false
              };
            }
          );

          entry.freeCalendarsMultipleOptions = [
            {
              key: `All Years`,
              value: 'all',
              selected: false
            },
            ...freeCalendarsMultipleArray
          ];

          entry.from.date = date.format('MMM DD,YYYY');
          entry.to.date = date.format('MMM DD,YYYY');

          const onDayBefore = moment(date.format('MMM DD,YYYY'), 'MMM DD,YYYY')
            .subtract(1, 'days')
            .format('MMM DD,YYYY');
          entry.actions = {
            ...entry.actions,
            notification: [
              { days: -1, date: onDayBefore, time: getCategoryTimings() },
              { days: '', date: entry.to.date, time: getCategoryTimings() }
            ]
          };

          return entry;
        });
        setInitialEntry({ entries: [...updatedPreserveEntryData] });
      }
    },
    // eslint-disable-next-line
    [hasData, setInitialEntry, schools, currentSchool.id, firebase]
  );
  const addLinkedEntry = (values) => {
    const newLinkedId = generateFileHash(20);
    let groupsInitValues = {};
    let multiYearInitValues = {};
    const updateInitialValue = values.entries.map((data) => {
      if (data.isPrimary) {
        if (
          (data.groups && data.groups.length && data.groupType === 'Free') ||
          data.for === multiYear
        ) {
          groupsInitValues = {
            for: data.for,
            forYears: data.forYears ? data.forYears : [],
            groups: data.groups,
            groupMembers: data.groupMembers,
            groupType: data.groupType
          };
        }
        if (data.for) {
          multiYearInitValues = {
            for: data.for
          };
        }
        return {
          ...data,
          expand: false,
          linkedArray: data.linkedArray
            ? [...data.linkedArray, newLinkedId]
            : [newLinkedId]
        };
      } else {
        delete data.linkedArray;
        return { ...data, expand: false, isPrimary: false };
      }
    });
    delete entryObject.entries[0].linkedArray;

    const freeCalendarsMultipleArray = currentSchool.freeCalendars.map(
      (year) => {
        const getCurrentYear = _.find(
          DefaultYear,
          (elem) => elem.value === year
        );
        return { value: year, key: getCurrentYear?.label, selected: false };
      }
    );

    setInitialEntry({
      entries: [
        ...updateInitialValue,
        {
          ...entryObject.entries[0],
          ...groupsInitValues,
          ...multiYearInitValues,
          id: newLinkedId,
          isPrimary: false,
          school: currentSchool.id,
          agentName: currentUser.firstName,
          freeCalendarsMultipleOptions: [
            {
              key: `All Years`,
              value: 'all',
              selected: false
            },
            ...freeCalendarsMultipleArray
          ],
          from: {
            date:
              moment().format('MMM DD,YYYY') ||
              entryObject.entries[0].from.date,
            time: entryObject.entries[0].from.time
          },
          to: {
            date:
              moment().format('MMM DD,YYYY') || entryObject.entries[0].to.date,
            time: entryObject.entries[0].to.time
          },
          actions: {
            ...entryObject.entries[0].actions,
            // notification : [{days: -1, date: moment(date.format('MMM DD,YYYY'), 'MMM DD,YYYY').subtract(1, 'days').format('MMM DD,YYYY'), time: "7:30pm"},{days: "", date:  date.format('MMM DD,YYYY'), time: "7:30pm"}]
            notification: [
              {
                days: -1,
                date: moment().subtract(1, 'days').format('MMM DD,YYYY'),
                time: getCategoryTimings()
              },
              {
                days: '',
                date: moment().format('MMM DD,YYYY'),
                time: getCategoryTimings()
              }
            ]
          }
        }
      ]
    });
  };

  const removeLinkedEntry = () => {
    if (isOpenDeleteLinkedModal.id) {
      const values = [...initialEntry.entries];

      const removeLinkedEntryId = _.findIndex(
        values[0].linkedArray,
        (item) => item === values[isOpenDeleteLinkedModal.id].id
      );

      const removeLinkedEntryFromCalendar = _.findIndex(
        allCalenderData[0].entries,
        (item) => item.id === values[isOpenDeleteLinkedModal.id].id
      );
      if (removeLinkedEntryFromCalendar > -1) {
        allCalenderData[0].entries.splice(removeLinkedEntryFromCalendar, 1);
      }

      if (removeLinkedEntryId > -1) {
        values[0].linkedArray.splice(removeLinkedEntryId, 1);
      }

      values.splice(isOpenDeleteLinkedModal.id, 1);
      setInitialEntry({ entries: [...values] });
    }
    setOpenDeleteLinkedModal({ open: false, id: null });
  };

  const handleExpandChange = (id, values) => {
    let updateInitialValue = [];
    updateInitialValue = values.entries.map((data, index) => {
      if (index === id) {
        return { ...data, expand: data.expand ? false : true };
      } else {
        return { ...data, expand: false };
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const validationSchema = Yup.object().shape({
    entries: Yup.array().of(entryValidationSchema)
  });

  const handleActionChange = (e, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      let updatedData;
      if (index === selectedIndex) {
        if (e.target.value === 'deadline' || e.target.value === 'reminder') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              [e.target.value]: [
                ...data.actions[e.target.value],
                moment().format('MMM DD,YYYY')
              ],
              [e.target.value + 'Notification']:
                e.target.value === 'reminder'
                  ? [
                      ...(data.actions.reminderNotification || []),
                      {
                        days: '',
                        date: moment().format('MMM DD,YYYY'),
                        time: getCategoryTimings()
                      }
                    ]
                  : [
                      ...(data.actions.deadlineNotification || []),
                      {
                        days: '',
                        date: moment().format('MMM DD,YYYY'),
                        time: getCategoryTimings()
                      }
                    ]
            }
          };
        } else if (e.target.value === 'notification') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              notification: [
                ...data.actions.notification,
                {
                  days: '',
                  date: moment(data.from.date, 'MMM DD,YYYY').format(
                    'MMM DD,YYYY'
                  ),
                  time: moment().format('h:mma')
                }
              ]
            }
          };
        } else if (e.target.value === 'repeat') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              repeat: [...data.actions.repeat, { type: 'Does not repeat' }]
            }
          };
        } else if (e.target.value === 'cancelled') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isChanged: false,
              isPostponed: false,
              isCancelled: true
            }
          };
        } else if (e.target.value === 'postponed') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isChanged: false,
              isCancelled: false,
              isPostponed: true
            }
          };
        } else if (e.target.value === 'changed') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isCancelled: false,
              isPostponed: false,
              isChanged: true
            }
          };
        }
      } else {
        updatedData = data;
      }
      return updatedData;
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const onEditorChange = (e, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (data.isPrimary) {
          setDefaultNote(e);
        }
        return { ...data, description: e };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleChangeCheck = (name, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (name === 'checkDescription') {
          setDefaultNote(toGetContentFromHTML(values.entries[0].description));
          return {
            ...data,
            // description: values.entries[0].description,
            [name]: data[name] ? false : true
          };
        } else {
          return { ...data, [name]: data[name] ? false : true };
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleNotificationDateChange = (
    e,
    selectedIndex,
    innerIndex,
    values,
    value,
    type = ''
  ) => {
    const formateValue = 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (type === 'reminderNotification' && index === selectedIndex) {
        const notificationArray = data.actions.reminderNotification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, reminderNotification: notificationArray }
        };
      } else if (type === 'deadlineNotification' && index === selectedIndex) {
        const notificationArray = data.actions.deadlineNotification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, deadlineNotification: notificationArray }
        };
      } else if (index === selectedIndex) {
        const notificationArray = data.actions.notification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, notification: notificationArray }
        };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleDateChange = (e, selectedIndex, values, key, value) => {
    const formateValue = value === 'time' ? 'h:mma' : 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (key === 'from' && value === 'date') {
          const endDate = moment(data.to.date, 'MMM DD,YYYY').isSameOrAfter(
            moment(e, 'MMM DD,YYYY')
          )
            ? data.to.date
            : moment(e).format(formateValue);
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) },
            to: { ...data['to'], [value]: endDate },
            actions: {
              ...data.actions,
              notification:
                hasData && hasData[0]
                  ? data.actions.notification
                  : [
                      {
                        days: -1,
                        date: moment(e, 'MMM DD,YYYY')
                          .subtract(1, 'days')
                          .format('MMM DD,YYYY'),
                        time: getCategoryTimings()
                      },
                      {
                        days: '',
                        date: moment(e, 'MMM DD,YYYY').format('MMM DD,YYYY'),
                        time: getCategoryTimings()
                      }
                    ]
            }
          };
        } else if (key === 'from' && value === 'time') {
          const endTime = moment(e).add(1, 'hour').format(formateValue);
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) },
            to: { ...data['to'], [value]: endTime }
          };
        } else {
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) }
          };
        }
      } else {
        return data;
      }
    });

    const currentObj = updateInitialValue[selectedIndex];
    const isSameDate = currentObj.from.date === currentObj.to.date;
    if (isSameDate) {
      // Remove Error Message If Date Same
      setRecurringEntriesErrors({ message: '' });
    }

    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleRepeatChange = (e, selectedIndex, innerIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        const repeatArray = data.actions.repeat.map(
          (repeatData, repeatIndex) => {
            if (innerIndex === repeatIndex) {
              return { type: e.target.value };
            } else {
              return repeatData;
            }
          }
        );
        return { ...data, actions: { ...data.actions, repeat: repeatArray } };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
    if (hasData && hasData.length && hasData[0].isRepeatEntry) {
      // Default Primary Entry day selected
      if (hasData[0].originalEntryDate) {
        const primaryDay = moment(
          hasData[0].originalEntryDate,
          'MMM DD,YYYY'
        ).format('ddd');
        if (
          hasData[0].actions.repeat[0].values &&
          hasData[0].actions.repeat[0].values.repeatType === 'Week' &&
          hasData[0].actions.repeat[0].values.repeatOn.indexOf(primaryDay) ===
            -1
        ) {
          setChangePrimaryDay(primaryDay);
        } else {
          setChangePrimaryDay('');
        }
      }
      setIsRepeatChange(true);
    }
    if (e.target.value === 'Custom') {
      const data = {
        selectedIndex,
        innerIndex,
        values: { entries: [...updateInitialValue] }
      };
      setCustomValue(data);
      setOpenModal({
        open: true,
        value: JSON.stringify(
          data.values.entries[selectedIndex].actions.repeat[innerIndex]
        )
      });
    }
  };

  const handleDateActionChange = (
    e,
    selectedIndex,
    innerIndex,
    values,
    key,
    value
  ) => {
    const formateValue = value === 'time' ? 'h:mma' : 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (key === 'notification') {
          const notificationArray = data.actions.notification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: { ...data.actions, notification: notificationArray }
          };
        } else if (key === 'reminderNotification') {
          const notificationArray = data.actions.reminderNotification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              reminderNotification: notificationArray
            }
          };
        } else if (key === 'deadlineNotification') {
          const notificationArray = data.actions.deadlineNotification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              deadlineNotification: notificationArray
            }
          };
        } else if (key === 'reminder') {
          const remindNotifyUpdate = data.actions.reminderNotification
            ? [...data.actions.reminderNotification]
            : [];
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                if (
                  remindNotifyUpdate.length &&
                  remindNotifyUpdate[notificationIndex]
                ) {
                  const notificationDate = moment(e, formateValue);
                  const fromDate = moment(data.from.date, formateValue);
                  let dayValue = '';
                  if (moment(notificationDate).isSame(fromDate)) {
                    dayValue = '';
                  } else {
                    dayValue = notificationDate.diff(fromDate, 'days');
                  }
                  remindNotifyUpdate[notificationIndex] = {
                    ...remindNotifyUpdate[notificationIndex],
                    days: dayValue,
                    date: moment(e).format(formateValue)
                  };
                }
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              [key]: actionArray,
              reminderNotification: remindNotifyUpdate
            }
          };
        } else if (key === 'deadline') {
          const deadlineNotifyUpdate = data.actions.deadlineNotification
            ? [...data.actions.deadlineNotification]
            : [];
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                if (
                  deadlineNotifyUpdate.length &&
                  deadlineNotifyUpdate[notificationIndex]
                ) {
                  const notificationDate = moment(e, formateValue);
                  const fromDate = moment(data.from.date, formateValue);
                  let dayValue = '';
                  if (moment(notificationDate).isSame(fromDate)) {
                    dayValue = '';
                  } else {
                    dayValue = notificationDate.diff(fromDate, 'days');
                  }
                  deadlineNotifyUpdate[notificationIndex] = {
                    ...deadlineNotifyUpdate[notificationIndex],
                    days: dayValue,
                    date: moment(e).format(formateValue)
                  };
                }
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              [key]: actionArray,
              deadlineNotification: deadlineNotifyUpdate
            }
          };
        } else if (key) {
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return { ...data, actions: { ...data.actions, [key]: actionArray } };
        } else {
          return data;
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleDateActionRemove = (selectedIndex, innerIndex, values, key) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (
        key === 'isCancelled' ||
        key === 'isPostponed' ||
        key === 'isChanged'
      ) {
        if (index === selectedIndex) {
          const values = data.actions;
          values[key] = false;
          return { ...data, values };
        } else {
          return data;
        }
      } else if (key === 'reminderNotification') {
        if (index === selectedIndex) {
          const values = data.actions.reminderNotification.filter(
            (date, index) => index !== innerIndex
          );
          return {
            ...data,
            actions: { ...data.actions, reminderNotification: values }
          };
        } else {
          return data;
        }
        //reminderNotification
      } else if (key === 'deadlineNotification') {
        if (index === selectedIndex) {
          const values = data.actions.deadlineNotification.filter(
            (date, index) => index !== innerIndex
          );
          return {
            ...data,
            actions: { ...data.actions, deadlineNotification: values }
          };
        } else {
          return data;
        }
        //deadlineNotification
      } else {
        if (index === selectedIndex) {
          const values = data.actions;
          values[key].splice(innerIndex, 1);
          return { ...data, values };
        } else {
          return data;
        }
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleCustomDateSubmit = (dateModalData) => {
    const {
      data: { selectedIndex, innerIndex }
    } = dateModalData;
    const updateInitialValue = dateModalData.data.values.entries.map(
      (data, index) => {
        if (index === selectedIndex) {
          const repeatArray = data.actions.repeat.map(
            (repeatData, repeatIndex) => {
              if (innerIndex === repeatIndex) {
                if (data.isRepeatEntry) {
                  let isEqual = deepEqual(
                    repeatData.values ? repeatData.values : {},
                    dateModalData.values
                  );
                  if (
                    hasData &&
                    hasData.length &&
                    hasData[0].actions.repeat &&
                    hasData[0].actions.repeat.length &&
                    repeatData.type !== hasData[0].actions.repeat[0].type
                  ) {
                    isEqual = false;
                  }
                  setIsRepeatChange(!isEqual);
                }
                return { ...repeatData, values: dateModalData.values };
              } else {
                return repeatData;
              }
            }
          );

          // Default Primary Entry day selected
          if (hasData && hasData.length && hasData[0].originalEntryDate) {
            const primaryDay = moment(
              hasData[0].originalEntryDate,
              'MMM DD,YYYY'
            ).format('ddd');
            if (
              repeatArray[0].values.repeatType === 'Week' &&
              repeatArray[0].values.repeatOn.indexOf(primaryDay) === -1
            ) {
              setChangePrimaryDay(primaryDay);
            } else {
              setChangePrimaryDay('');
            }
          }

          return { ...data, actions: { ...data.actions, repeat: repeatArray } };
        } else {
          return data;
        }
      }
    );
    setInitialEntry({ entries: [...updateInitialValue] });
    setOpenModal({ open: false, value: [] });
    setCustomValue({});
  };

  const handleCustomDateClose = () => {
    const modalValue = JSON.parse(isOpenModal.value);
    const { selectedIndex, innerIndex } = customValue;
    const updateInitialValue = initialEntry.entries.map((data, index) => {
      if (index === selectedIndex) {
        const repeatArray = data.actions.repeat.map(
          (repeatData, repeatIndex) => {
            if (innerIndex === repeatIndex) {
              const fromDate = moment(data.from.date, 'MMM DD,YYYY').format();
              const customDateObject = {
                repeatDay: 1,
                repeatType: 'Day',
                repeatOn: [moment(fromDate).format('ddd')],
                repeatMonth: {
                  type: 'day',
                  value: moment(fromDate).format('MMM DD,YYYY')
                },
                endsType: 'Never',
                endsDate: moment(fromDate).format('MMM DD,YYYY'),
                endsAfter: 1
              };
              return {
                ...repeatData,
                values: modalValue.values ? modalValue.values : customDateObject
              };
            } else {
              return repeatData;
            }
          }
        );
        return { ...data, actions: { ...data.actions, repeat: repeatArray } };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
    setOpenModal({ open: false, value: [] });
    setCustomValue({});
  };

  // Below function using for when entry is update then old all entries deleting
  const deleteSchoolDataEntry = async (docId, id) => {
    await firebase.deleteSchoolDataEntries(docId, id);
  };

  const submitNewSchoolForm = async (formData, mainEntries) => {
    //New time format
    formData = await setFromToTimeFormat(formData, deleteSchoolDataEntry);
    //New time format end

    const actionDataAppend = [];
    let primary = formData.find((o) => o.isPrimary === true);

    // Create VirtualEntries
    formData = await virtualEntries({ formData, mainEntries, isRepeatChange, allCalenderData, repeatEntryRadioData});

    const groupEntryID = formData[0].groupEntryID
      ? formData[0].groupEntryID
      : null;
    if (
      groupEntryID &&
      formData[0].groupType === 'Free' &&
      mainEntries &&
      mainEntries.length > 0
    ) {
      deleteFreeSchoolFromGroup(groupEntryID, mainEntries);
    }

    // bulk ID created for finding entry into the data entries collections
    const bulkID = primary.bulkID || generateFileHash(20);
    const updateFormData = formData.map((data) => {
      // Primary bulkID attach with all relatives entries
      data.bulkID = bulkID;
      data.schoolId = data.school;
      data.buttonState = buttonState || hasData?.[0]?.buttonState;

      if (data.label === '') {
        data.label = 'none';
      }
      if (data.isPrimary === false) {
        data.isLinked = true;
      } else {
        data.isLinked = false;
      }
      if (data.actions.reminder.length) {
        data.actions.reminder.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Reminder',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            isLinked: false,
            primaryID: data.isPrimary ? data.id : data.primaryID,
            actions: {
              ...data.actions,
              notification:
                data.actions.reminderNotification &&
                data.actions.reminderNotification[index]
                  ? [data.actions.reminderNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.actions.deadline.length) {
        data.actions.deadline.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Deadline',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            isLinked: false,
            primaryID: data.isPrimary ? data.id : data.primaryID,
            actions: {
              ...data.actions,
              notification:
                data.actions.deadlineNotification &&
                data.actions.deadlineNotification[index]
                  ? [data.actions.deadlineNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.isPrimary === false) {
        if (groupEntryID) {
          // data.groupEntryID = groupEntryID;
          data.school = formData[0].school;
        }
        data.isLinked = true;
      } else {
        data.isLinked = false;
      }
      if (groupEntryID) {
        delete data.groupEntryID;
      }

      //delete data.school;
      return data;
    });

    const final = [...updateFormData, ...actionDataAppend];
    await addSchoolDataEntry(final);
  };

  const deleteFreeSchoolFromGroup = (groupEntryID, hasData) => {
    const oldYear = hasData[0].for;
    firebase
      .getGroupEntries(groupEntryID)
      .then((groupEntries) => {
        if (
          groupEntries &&
          groupEntries.entries &&
          groupEntries.entries.length
        ) {
          let newEntries = JSON.parse(JSON.stringify(groupEntries.entries));
          if (newEntries[0].for.length === 1) {
            //delete primary entry if only 1 for
            firebase
              .deleteGroupEntries(groupEntries.docId)
              .then(() => {})
              .catch((error) =>
                console.log('Error to delete group entries', error)
              );
          } else {
            // Delete group entry if primary "For" all
            if (
              newEntries[0].for.length > 1 &&
              newEntries[0].for.includes('all')
            ) {
              firebase
                .deleteGroupEntries(groupEntries.docId)
                .then(() => {})
                .catch((error) =>
                  console.log('Error to delete group entries', error)
                );
            } else {
              let filteredLinkedEntry = groupEntries.entries.map((entry) => {
                if (
                  entry.isPrimary === false &&
                  entry.for.length === 1 &&
                  entry.for.includes(oldYear)
                ) {
                  return entry.id;
                }
              });
              filteredLinkedEntry = filteredLinkedEntry.filter(
                (entry) => entry
              );
              if (filteredLinkedEntry && filteredLinkedEntry.length) {
                newEntries = groupEntries.entries.filter(
                  (entry) => !filteredLinkedEntry.includes(entry.id)
                );
              }
              // end - delete link entry if entry is same as current year and for length 1
              // reset for & forDropdownData
              let newGroupEntries = newEntries.map((entry) => {
                if (entry.for.length > 1 && !entry.for.includes('all')) {
                  const filteredFor = entry.for.filter((v) => v !== oldYear);
                  entry.for = filteredFor;
                  let yearDropDownData = entry.yearDropDownData.map((year) => {
                    if (year.value === oldYear) {
                      year.selected = false;
                    }
                    return year;
                  });
                  entry.yearDropDownData = yearDropDownData;
                }
                return entry;
              });

              let finalData = {
                entries: newGroupEntries,
                groupEntryID: groupEntries.groupEntryID,
                groupType: groupEntries.groupType
              };
              firebase
                .updateGroupEntries(finalData, groupEntries.docId)
                .then(() => {})
                .catch((error) => console.log('error', error));
            }
          }
        } else {
          dispatch(addMessage('Something went wrong to get group entries', MESSAGE_TYPES.ERROR));
        }
      })
      .catch((error) => {
        console.log('error', error);
        dispatch(addMessage('Something went wrong to get group entries', MESSAGE_TYPES.ERROR));
      });
  };

  const _deleteEntryHandleForRepeat = () => {
    const parentRepeatEntryId = hasData[0].parentRepeatEntryId;
    const originEntry = allCalenderData[0].entries.find(
      (entry) => entry.id === parentRepeatEntryId
    );

    const deleteDate = hasData[0].from.date;

    if (repeatEntryRadioData === 'This Event') {
      originEntry.repeatDeleted = originEntry.repeatDeleted
        ? [...originEntry.repeatDeleted, deleteDate]
        : [deleteDate];
    } else if (repeatEntryRadioData === 'This and following events') {
      if (originEntry.actions.repeat[0].values) {
        originEntry.actions.repeat[0].values.endsDate = moment(
          hasData[0].from.date,
          'MMM DD,YYYY'
        )
          .subtract(1, 'days')
          .format('MMM DD,YYYY');
        originEntry.actions.repeat[0].values.endsType = 'On';
      } else {
        const entryObject = {
          repeatDay: 1,
          repeatType: 'Day',
          repeatOn: [],
          repeatMonth: { type: 'day', value: moment().format('MMM DD,YYYY') },
          endsType: 'On',
          endsDate: moment(hasData[0].from.date, 'MMM DD,YYYY')
            .subtract(1, 'days')
            .format('MMM DD,YYYY'),
          endsAfter: 1
        };
        originEntry.actions.repeat[0] = { type: 'Custom', values: entryObject };
      }
    } else {
      // Do nothing for all
      return;
    }
    dispatch(loaderStart());

    // const finalData = {
    //   docId: allCalenderData[0].id,
    //   entry: originEntry
    // };

    manageSchoolEntries([originEntry])
      .then(() => {
        dispatch(loaderStop());
        dispatch(addMessage('Calendar Entry Deleted'));

        setInitialEntry({
          entries: [
            {
              ...entryObject.entries[0],
              id: generateFileHash(20),
              agentName: currentUser.firstName,
              from: {
                date: moment().format('MMM DD,YYYY'),
                time: moment().format('h:mma')
              },
              to: {
                date: moment().format('MMM DD,YYYY'),
                time: moment().format('h:mma')
              }
            }
          ]
        });
        dispatch(setFamilyOrSchoolPreserveEntryData(null));
        setHasEntryData([]);
        setRightOpen(false);
        getEntries(date);
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  };

  const submitForm = (formData) => {
    dispatch(loaderStart())
    if (formData.length) {
      formData.forEach((data) => {
        if (data.freeCalendarsMultipleOptions) {
          delete data.freeCalendarsMultipleOptions;
        }
      });
    }

    // After change repeat option create new entry logic start
    if (
      isRepeatChange &&
      hasData &&
      hasData.length > 0 &&
      repeatEntryRadioData === 'This and following events'
    ) {
      let newEntries = JSON.parse(JSON.stringify(formData));
      // Delete extra field start
      const deleteFields = [
        'followDates',
        'repeatEntries',
        'repeatDeleted',
        'isRepeatEntry',
        'parentRepeatEntryId',
        'completedDates',
        'isDeleted'
      ];
      deleteFields.map((field) => {
        if (newEntries[0][field]) delete newEntries[0][field];
      });
      // Delete extra field end
      newEntries = [
        {
          ...newEntries[0],
          id: generateFileHash(20),
          isPrimary: true,
          isLinked: false,
          primaryID: '',
          for: [newEntries[0].for]
        }
      ];
      submitCreateSchoolForm(newEntries);
    }
    // Update or create existing entry
    if (hasData && hasData.length) {
      if (formData[0].for) {
        submitNewSchoolForm(formData, hasData);
      }
    } else {
      submitCreateSchoolForm(formData);
    }
  };

  const submitCreateSchoolForm = async (formData) => {
    //New time format
    formData = await setFromToTimeFormat(formData);
    //New time format end

    const actionDataAppend = [];
    const primary = formData.find((o) => o.isPrimary === true);
    const linked = formData.filter((o) => o.isPrimary === false);
    const groupEntryID = generateFileHash(20);
    const bulkID = generateFileHash(20);

    //First create seperate entry for each year
    let updatedFormData = [];

    // bulk ID created for finding entry into the data entries collections
    primary.forYears = primary.for;
    primary.for = multiYear;
    primary.bulkID = bulkID;
    linked.map((entry) => {
      entry.school = entry.school ? entry.school : currentSchool.id;
      entry.forYears = entry.for;
      entry.for = multiYear;
      entry.bulkID = bulkID;
      return entry;
    });

    updatedFormData = [primary, ...linked];

    const updateFormData = updatedFormData.map((data, index) => {
      data.schoolId = data.school;
      if (index === 0) {
        data.groupEntryID = groupEntryID;
      } else {
        data.groupEntryID = groupEntryID;
        data.school = formData[0].school;
      }
      if (data.isPrimary === false) {
        data.isLinked = true;
      } else {
        data.isLinked = false;
      }
      if (data.actions.notification.length) {
      }
      if (data.actions.reminder.length) {
        data.actions.reminder.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Reminder',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            isLinked: false,
            primaryID: data.isPrimary ? data.id : data.primaryID,
            actions: {
              ...data.actions,
              notification:
                data.actions.reminderNotification &&
                data.actions.reminderNotification[index]
                  ? [data.actions.reminderNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.actions.deadline.length) {
        data.actions.deadline.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Deadline',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            isLinked: false,
            primaryID: data.isPrimary ? data.id : data.primaryID,
            actions: {
              ...data.actions,
              notification:
                data.actions.deadlineNotification &&
                data.actions.deadlineNotification[index]
                  ? [data.actions.deadlineNotification[index]]
                  : []
            }
          });
        });
      }

      return data;
    });

    const final = [...updateFormData, ...actionDataAppend];
    await addSchoolDataEntry(final);
  };

  // below function use behalf of "_deleteEntryHandle"
  const deleteAllEntries = async () => {
    const primary = hasData.find((o) => o.isPrimary === true);
    if (primary.isPrimary && primary.bulkID) {
      // Remove existing data entries
      dispatch(loaderStart());
      await deleteSchoolDataEntry(primary.docId, primary.bulkID);

      dispatch(loaderStop());
      dispatch(addMessage('Calendar Entry Deleted'));

      setInitialEntry({
        entries: [
          {
            ...entryObject.entries[0],
            id: generateFileHash(20),
            agentName: currentUser.firstName,
            from: {
              date: moment().format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            },
            to: {
              date: moment().format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            }
          }
        ]
      });
      dispatch(setFamilyOrSchoolPreserveEntryData(null));
      setHasEntryData([]);
      setRightOpen(false);
      setTimeout(() => {
        getEntries(date);
      }, 300)
    }
  };

  // Below function using behalf of "addSchoolEntry"
  const addSchoolDataEntry = async (finalData) => {
    dispatch(loaderStart());
    manageSchoolEntries(finalData).then(
      async () => {
        let editedFormDate = null;
        await finalData.forEach(entryItem => {
          const updatedEntry = entryItem?.repeatEntries?.length
            ? entryItem?.repeatEntries[entryItem?.repeatEntries?.length - 1]
            : null;
          const primary = entryItem.isPrimary === true ? entryItem : false;
          if (primary) {
            editedFormDate = primary?.from?.date || null;
            if (
              (entryItem.label === 'Alert' &&
                hasData?.[0]?.label !== 'Alert') ||
              (updatedEntry?.label === 'Alert' &&
                hasData?.[0]?.label !== 'Alert')
            ) {
              const requestOptions = {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  schoolId: entryItem.schoolId,
                  entryId: entryItem.id,
                  repeatEntryId: entryItem?.repeatEntries?.length
                    ? updatedEntry.id
                    : null,
                    isFree: currentSchool.freeCalendars.length ? true : false
                })
              };
              fetch(
                `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/sendInstantPN`,
                requestOptions
              )
                .then((response) => {
                  const result = response.json();
                  if (!response.ok) {
                    throw new Error(result?.message);
                  }
                  return result
                })
                .then((data) => console.log('.'));
            }
          }
        });

        dispatch(addMessage('School Entry Created'));
        dispatch(loaderStop());
        setInitialEntry({
          entries: [
            {
              ...entryObject.entries[0],
              id: generateFileHash(20),
              agentName: currentUser.firstName,
              from: {
                date: moment().format('MMM DD,YYYY'),
                time: moment().format('h:mma')
              },
              to: {
                date: moment().format('MMM DD,YYYY'),
                time: moment().format('h:mma')
              }
            }
          ]
        });
        dispatch(
          setFamilyOrSchoolPreserveEntryData({
            entries: [...entryObject.entries]
          })
        );
        setHasEntryData([]);
        setRightOpen(false);
        if ( editedFormDate ) {
          getEntries(
            moment(
              editedFormDate,
              'MMM DD,YYYY'
            )
          );
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Entry was not created. Please try again', MESSAGE_TYPES.ERROR));
      });
  };

  const handleChangeSingleFreeYear = (
    event,
    selectedIndex,
    entries,
    setFieldValue
  ) => {
    if (selectedIndex === 0) {
      if (entries.length > 0) {
        entries.map((item, index) => {
          if (index === 0) {
          } else {
            setFieldValue(`entries[${index}].for`, '');
          }
        });
      }
    }
  };

  const handleChangeFreeYear = (
    event,
    selectedIndex,
    entries,
    setFieldValue
  ) => {
    const oldAllDropdownSelection = JSON.parse(
      JSON.stringify(
        entries[0]?.freeCalendarsMultipleOptions?.find((e) => e.value === 'all')
      )
    );
    let selectValue = event.target.value;
    // if we have to select all in All Years then uncomment the code

    const allId = _.map(
      entries[selectedIndex].freeCalendarsMultipleOptions,
      (year) => year.value
    );
    if (
      selectValue[selectValue.length - 1] === 'all' &&
      selectValue.includes('all')
    ) {
      selectValue = allId;
    } else {
      const removeAllIndex = _.findIndex(selectValue, (id) => id === 'all');
      if (oldAllDropdownSelection?.selected && !selectValue.includes('all')) {
        selectValue = [];
      }
      if (removeAllIndex > -1) {
        selectValue.splice(removeAllIndex, 1);
      }
    }

    setFieldValue(`entries[${selectedIndex}].for`, selectValue);

    const updatedYears = entries[
      selectedIndex
    ].freeCalendarsMultipleOptions.map((yearData) => {
      if (selectValue.includes('all') || selectValue.includes(yearData.value)) {
        return {
          ...yearData,
          selected: true
        };
      } else {
        return {
          ...yearData,
          selected: false
        };
      }
    });
    setFieldValue(
      `entries[${selectedIndex}].freeCalendarsMultipleOptions`,
      updatedYears
    );

    // If for change for primary entry then change its relevant linked entries value
    if (selectedIndex === 0) {
      entries.map((entry, index) => {
        if (index !== selectedIndex) {
          setFieldValue(`entries[${index}].for`, selectValue);
          setFieldValue(
            `entries[${index}].freeCalendarsMultipleOptions`,
            updatedYears
          );
        }
      });
    }
  };

  const handleForYearChange = (
    event,
    selectedIndex,
    entries,
    setFieldValue
  ) => {
    const oldAllDropdownSelection = JSON.parse(
      JSON.stringify(groupYears?.find((e) => e.value === 'all'))
    );
    let selectValue = event.target.value;
    const allId = _.map(groupYears, (year) => year.value);

    if (
      selectValue.length === allId.length - 1 &&
      !selectValue.includes('all') &&
      !oldAllDropdownSelection?.selected
    ) {
      selectValue.push('all');
    }

    if (
      selectValue[selectValue.length - 1] === 'all' &&
      selectValue.includes('all')
    ) {
      selectValue = allId;
      setFieldValue(`entries[${selectedIndex}].forYears`, selectValue);
      const updatedYears = groupYears.map((yearData) => {
        return { ...yearData, selected: true };
      });
      setGroupYears(updatedYears);
    } else if (
      oldAllDropdownSelection?.selected &&
      !selectValue.includes('all')
    ) {
      selectValue = [];
      setFieldValue(`entries[${selectedIndex}].forYears`, selectValue);
      const updatedYears = groupYears.map((yearData) => {
        return { ...yearData, selected: false };
      });
      setGroupYears(updatedYears);
    } else {
      const removeAllIndex = _.findIndex(selectValue, (id) => id === 'all');
      if (removeAllIndex > -1) {
        selectValue.splice(removeAllIndex, 1);
      }

      const updatedYears = groupYears.map((yearData) => {
        if (selectValue.includes(yearData.value)) {
          return { ...yearData, selected: true };
        } else {
          return { ...yearData, selected: false };
        }
      });
      setGroupYears(updatedYears);
      setFieldValue(`entries[${selectedIndex}].forYears`, selectValue);
    }
    // If for change for primary entry then change its relevant linked entries value
    if (selectedIndex === 0) {
      entries.map((entry, index) => {
        if (index !== selectedIndex) {
          setFieldValue(`entries[${index}].forYears`, selectValue);
        }
      });
    }
  };

  const handleEditForWholeGroup = () => {
    setEditForWholeGroup(!editForWholeGroup);
  };

  const refreshScreen = () => {
    getEntries(date);
    setRightOpen(false);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialEntry}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let primaryDesc = '';

          const recurringEntriesErrorsObj = {};
          const updatedValues = values.entries.map((entry, index) => {
            if (entry?.isPrimary === true && entry?.actions?.repeat?.length) {
              const isRepeatEntry =
                entry?.actions?.repeat?.[0]?.type !== 'Does not repeat';
              // [Custom, Daily, Does not repeat]
              const isSameDate = entry.from.date === entry.to.date;

              if (isRepeatEntry) {
                if (!isSameDate) {
                  recurringEntriesErrorsObj.message =
                    'Cannot save the recurring entry. From and To DAYS must be the same';
                }
              }
            }

            entry = _.omit(
              entry,
              _.filter(_.keys(entry), function (key) {
                return _.isUndefined(entry[key]);
              })
            );
            if (entry.primaryEventTo) {
              delete entry.primaryEventTo;
            }
            if (entry.primaryEventFrom) {
              delete entry.primaryEventFrom;
            }
            if (entry.customType) {
              delete entry.customType;
            }

            //selection logic start
            if (entry.actions.notification.length) {
              const formateValue = 'MMM DD,YYYY';
              entry.actions.notification.forEach((item) => {
                const fromDate = moment(entry.from.date, formateValue);
                const notificationDate = moment(
                  item.date ? item.date : entry.from.date,
                  formateValue
                );
                let dayValue = '';
                if (moment(notificationDate).isSame(fromDate)) {
                  dayValue = '';
                } else {
                  dayValue = notificationDate.diff(fromDate, 'days');
                }
                item.days = dayValue;
              });
            }
            //selection logic end

            //FOR YEARS logic when editing start
            if (entry.forYears?.includes('all')) {
              const getAllYears = _.compact(
                freeCalendarsOptions?.map((years) => years.id)
              );
              entry.forYears = ['all', ...getAllYears];
            }
            //FOR YEARS logic when editing end

            // remove deadline and reminder logic start
            if (entry.actions.isComplete) {
              // entry.actions.deadline = []
              // entry.actions.reminder = []
              if (entry.actions.deadlineNotification)
                delete entry.actions.deadlineNotification;
              if (entry.actions.reminderNotification)
                delete entry.actions.reminderNotification;
            }
            // remove deadline and reminder logic end
            // linked entry description logic start
            if (entry.description && index === 0) {
              primaryDesc = toGetHTMLContent(entry.description).replaceAll('</a>&nbsp;<', '</a><');
            }

            // linked entry description logic end
            return {
              ...entry,
              from: {
                date: entry.from.date,
                time:
                  entry.newFromTimeFormate.hh +
                  ':' +
                  entry.newFromTimeFormate.mm +
                  entry.newFromTimeFormate.ap +
                  'm'
              },
              to: {
                date: entry.to.date,
                time:
                  entry.newToTimeFormate.hh +
                  ':' +
                  entry.newToTimeFormate.mm +
                  entry.newToTimeFormate.ap +
                  'm'
              },
              actions: {
                ...entry.actions,
                repeat: entry.actions.repeat
              },
              expand: index === 0 ? true : false,
              primary_description:
                index !== 0
                  ? {
                      description: primaryDesc ? primaryDesc : '',
                      from: values.entries[0].from
                    }
                  : {},
              primaryID: index !== 0 ? values.entries[0].id : '',
              createdAt: moment().format(),
              description: entry.description
                ? toGetHTMLContent(entry.description).replaceAll('</a>&nbsp;<', '</a><')
                : '',
              agentName: currentUser.firstName
            };
          });
          if (!recurringEntriesErrorsObj.message) {
            let isEqual = true;
            if (
              hasData &&
              hasData.length > 0 &&
              hasData[0].actions.repeat.length > 0 &&
              updatedValues[0].actions.repeat.length > 0
            ) {
              isEqual = deepEqual(
                hasData[0].actions.repeat[0],
                updatedValues[0].actions.repeat[0]
              );
            }
            if (
              updatedValues[0].actions.repeat.length > 0 &&
              updatedValues[0].actions.repeat[0].type === 'Does not repeat'
            ) {
              isEqual = false;
            }
            if (isRepeatChange && !isRepeatEntryModal) {
              setRepeatEntryRadioData('This and following events');
            }
            if (isEqual || isRepeatChange) {
              if (
                hasData &&
                hasData.length > 0 &&
                hasData[0].actions.repeat.length === 0 &&
                !hasData[0].isRepeatEntry
              ) {
                submitForm(updatedValues);
              } else if (
                hasData &&
                hasData.length &&
                updatedValues[0].actions.repeat.length > 0 &&
                !isRepeatEntryModal
              ) {
                setRepeatEntryModal(true);
              } else if (
                hasData &&
                hasData.length &&
                updatedValues[0].isRepeatEntry &&
                !isRepeatEntryModal
              ) {
                setRepeatEntryModal(true);
              } else {
                if (isRepeatEntryModal) {
                  setRepeatEntryModal(false);
                }
                submitForm(updatedValues);
              }
            } else {
              submitForm(updatedValues);
            }
          } else {
            dispatch(addMessage(recurringEntriesErrorsObj.message, MESSAGE_TYPES.ERROR));
            setRecurringEntriesErrors(recurringEntriesErrorsObj);
          }
        }}
        key="schoolForm"
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue
        }) => {
          setUpdatedEntry(values);
          // Below code for hide "All Event" option for repeated entries
          // Because when repeated entry update then do not update past entries from recurring entries
          const showAllEventOption =
            values.entries &&
            values.entries.length > 0 &&
            values.entries[0].from.date === values.entries[0].originalEntryDate
              ? true
              : false;
          let hideForRepeat = false;
          let isRepeatDelete = false;
          if (
            values.entries &&
            values.entries[0]?.isDynamic &&
            values.entries[0].isRepeatEntry
          ) {
            hideForRepeat = true;
            isRepeatDelete = true;
          }
          return (
            <>
              {
                <Backdrop className={classes.backdrop} open={isLoading}>
                  <CircularProgress color="primary" />
                </Backdrop>
              }
              <div>
                {hasData &&
                hasData[0] &&
                values.entries &&
                values.entries.length &&
                values.entries[0].groupEntryID &&
                values.entries[0].groupType &&
                values.entries[0].groupType !== 'Free' &&
                !values.entries[0].isRepeatEntry ? (
                  <FormControl
                    variant="outlined"
                    className={classes.formLabelControl}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={editForWholeGroup}
                          classes={{
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked
                          }}
                          onChange={handleEditForWholeGroup}
                          name="editForWholeGroup"
                        />
                      }
                      style={{
                        marginLeft: 10
                      }}
                      label="Edit for whole group"
                    />
                  </FormControl>
                ) : (
                  <></>
                )}
              </div>
              {editForWholeGroup ? (
                <GroupEditSection
                  isOpen={editForWholeGroup}
                  type={values.entries[0].groupType}
                  editEntryId={
                    (values.entries[0] && values.entries[0].groupEntryID) ||
                    null
                  }
                  closeRightDrawer={refreshScreen}
                />
              ) : (
                <>
                  <form noValidate onSubmit={handleSubmit}>
                    <div className={classes.headerContainer}>
                      <div className={classes.headerTitleContainer}>
                        <SchoolsIcon
                          color={colors.primaryDarkGrey}
                          className={classes.schoolIconStyle}
                        />
                        <Typography variant="h6" className={classes.titleText}>
                          {currentSchool.schoolName}
                        </Typography>
                      </div>
                      <div className={classes.headerButtonContainer}>
                        {/* {hasData && hasData.length ? ( */}
                        <Button
                          className={classes.deleteButton}
                          onClick={() => {
                            if (isRepeatDelete) {
                              setRepeatEntryModal('delete');
                            } else {
                              setOpenDeleteModal(true);
                            }
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          className={classes.publishButton}
                        >
                          Publish
                        </Button>
                      </div>
                    </div>
                    <div style={{ position: 'relative' }}>
                      {values.entries.length &&
                      values.entries[0] &&
                      (values.entries[0].actions.isCancelled ||
                        values.entries[0].actions.isPostponed ||
                        values.entries[0].actions.isChanged) ? (
                        <Chip
                          size="small"
                          label={
                            <span style={{ fontSize: 12 }}>
                              {values.entries[0].actions.isPostponed
                                ? 'POSTPONED'
                                : values.entries[0].actions.isCancelled
                                ? 'CANCELLED'
                                : 'UPDATED'}
                            </span>
                          }
                          classes={{
                            labelSmall: classes.chipLabel,
                            root: classes.chipRoot
                          }}
                          style={{
                            marginRight: 10,
                            backgroundColor:
                              values.entries[0].actions.isCancelled ||
                              values.entries[0].actions.isPostponed
                                ? colors.tertiaryRed
                                : colors.primaryDarkGrey
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <Divider variant="fullWidth" />
                    <div className={classes.drawerLeftContent}>
                      <div className={classes.leftContentMargin}>
                        {values.entries.map((entry, index) => (
                          <Accordion
                            style={{ margin: 10, boxShadow: 'none' }}
                            key={`expand${index}`}
                            expanded={
                              values.entries.length === 1 ? true : entry.expand
                            }
                            onChange={() =>
                              values.entries.length > 1 &&
                              handleExpandChange(index, values)
                            }
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon className={classes.whiteText} />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{
                                backgroundColor:
                                  values.entries.length !== 1
                                    ? entry.expand && index !== 0
                                      ? colors.primaryDarkGrey7
                                      : colors.primaryDarkGrey
                                    : null,
                                height:
                                  values.entries.length !== 1
                                    ? entry.expand
                                      ? 56
                                      : 60
                                    : null
                              }}
                            >
                              {index === 0 ? (
                                <Typography
                                  className={clsx(
                                    classes.titleAccordionText,
                                    values.entries.length > 1
                                      ? classes.whiteText
                                      : classes.darkPrimaryText
                                  )}
                                >
                                  Primary Entry
                                </Typography>
                              ) : (
                                <>
                                  <CornerDownRight
                                    className={classes.whiteText}
                                    style={{ marginTop: 5 }}
                                  />
                                  <Typography
                                    className={clsx(
                                      classes.titleAccordionText,
                                      classes.whiteText
                                    )}
                                    style={{
                                      fontSize: 18,
                                      lineHeight: 2.2,
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    Linked Entry {index}
                                  </Typography>
                                </>
                              )}
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                backgroundColor:
                                  values.entries.length > 1
                                    ? colors.secondaryGreyLight
                                    : ''
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                <div
                                  style={{
                                    flexDirection: 'row',
                                    display: 'flex'
                                  }}
                                >
                                  <FormControl
                                    variant="outlined"
                                    className={classes.formLabelControl}
                                  >
                                    <InputLabel id="select-outlined-label">
                                      Label
                                    </InputLabel>
                                    <Select
                                      labelId="select-outlined-label"
                                      name={`entries[${index}].label`}
                                      onBlur={handleBlur}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `entries[${index}].label`,
                                          e.target.value
                                        );
                                      }}
                                      value={
                                        (values.entries[index] &&
                                          values.entries[index].label) ||
                                        'none'
                                      }
                                      label="Label"
                                      error={Boolean(
                                        touched.entries &&
                                          errors.entries &&
                                          touched.entries[index] &&
                                          touched.entries[index].label &&
                                          errors.entries[index] &&
                                          errors.entries[index].label
                                      )}
                                    >
                                      {labels.map((label, index) => (
                                        <MenuItem
                                          key={`${label.value}-${index}`}
                                          value={label.value}
                                        >
                                          {label.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          values.entries[index].isLowPriority
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `entries[${index}].isLowPriority`,
                                            e.target.checked
                                          );
                                        }}
                                        classes={{
                                          switchBase: classes.switchBase,
                                          thumb: classes.thumb,
                                          track: classes.track,
                                          checked: classes.checked
                                        }}
                                      />
                                    }
                                    label="Low Priority"
                                    style={{
                                      width: '56%',
                                      marginTop: 13,
                                      display: 'flex',
                                      marginLeft: 10,
                                      marginTop: 5
                                    }}
                                  />
                                  {taskLabels.includes(
                                    values.entries[index].label
                                  ) && (
                                    <div
                                      style={{
                                        width: '56%',
                                        marginTop: 13,
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                      }}
                                    >
                                      <Checkbox
                                        color="primary"
                                        className={classes.iconButton}
                                        checked={
                                          (values.entries[index] &&
                                            values.entries[index].actions
                                              .isComplete) ||
                                          false
                                        }
                                        name={`entries[${index}].actions.isComplete`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  )}
                                </div>
                                <FormControl component="fieldset">
                                  <TextField
                                    label="Add Title"
                                    variant="outlined"
                                    className={classes.inputControlBox}
                                    name={`entries[${index}].title`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].title) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index].title &&
                                        errors.entries[index] &&
                                        errors.entries[index].title
                                    )}
                                  />
                                </FormControl>
                                <FormControl component="fieldset">
                                  <TextField
                                    label="Add location"
                                    placeholder="Add Location"
                                    variant="outlined"
                                    className={classes.inputControlBox}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <img
                                            src={LocationIcon}
                                            style={{ opacity: 0.7 }}
                                          />
                                        </InputAdornment>
                                      )
                                    }}
                                    name={`entries[${index}].location`}
                                    onBlur={(e) => {
                                      setFieldValue(
                                        `entries[${index}].location`,
                                        e.target.value
                                          .trim()
                                          .split(/ +/)
                                          .join(' ')
                                      );
                                      handleBlur(e);
                                    }}
                                    onChange={handleChange}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].location) ||
                                      ''
                                    }
                                  />
                                </FormControl>
                                <FormControl component="fieldset">
                                  <EditorComponent
                                    classes={classes}
                                    preserveData={values.entries[index]?.description}
                                    wrapperClassName={clsx(
                                      values.entries[index] &&
                                        values.entries[index].checkDescription
                                        ? classes.wrapperClassName
                                        : classes.wrapperClassNameWithBottomBorder
                                    )}
                                    index={index}
                                    onChange={(e, index) => onEditorChange(e, index, values)}
                                  />
                                  {Boolean(
                                    touched.entries &&
                                      errors.entries &&
                                      touched.entries[index] &&
                                      touched.entries[index].description &&
                                      errors.entries[index] &&
                                      errors.entries[index].description
                                  ) && (
                                    <FormHelperText
                                      style={{ color: colors.error }}
                                    >
                                      {errors.entries[index].description}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                                {values.entries[index] &&
                                values.entries[index].checkDescription ? (
                                  <>
                                    <div
                                      className={classes.descriptionContainer}
                                    >
                                      <div
                                        className={
                                          classes.descriptionMainContainer
                                        }
                                      >
                                        <img
                                          src={EditPencil}
                                          alt=""
                                          className={
                                            classes.descriptionImageContainer
                                          }
                                        />
                                        <p
                                          className={
                                            classes.descriptionTextContainer
                                          }
                                        >
                                          Created on{' '}
                                          {values.entries[index].createdAt
                                            ? moment(
                                                values.entries[index].createdAt
                                              ).format('DD MMM YYYY')
                                            : moment().format(
                                                'DD MMM YYYY'
                                              )}{' '}
                                          by {values.entries[index].agentName}
                                        </p>
                                      </div>
                                      <hr
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          borderTop: `dotted 3px ${colors.primaryLightGrey}`,
                                          width: 570
                                        }}
                                      />
                                      <div
                                        className={
                                          classes.descriptionMainContainer
                                        }
                                      >
                                        <img
                                          src={Calendar}
                                          alt=""
                                          className={
                                            classes.descriptionImageContainer
                                          }
                                        />
                                        <p
                                          className={
                                            classes.descriptionTextContainer
                                          }
                                        >
                                          Relates to:{' '}
                                          {values.entries[0].from.date
                                            ? moment(
                                                values.entries[0].from.date
                                              ).format('DD MMM YYYY')
                                            : moment().format(
                                                'DD MMM YYYY'
                                              )}{' '}
                                          entry
                                        </p>
                                      </div>
                                    </div>

                                    <Editor
                                      readOnly={true}
                                      editorState={defaultNote}
                                      toolbarClassName={
                                        classes.toolbarClassName
                                      }
                                      editorClassName={classes.editorClassName}
                                      wrapperClassName={
                                        classes.wrapperSecondEditorClassName
                                      }
                                      toolbar={{
                                        options: [],
                                        inline: {
                                          options: []
                                        }
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {values.entries[index] &&
                                values.entries[index].isPrimary ? (
                                  <>
                                    <div
                                      className={classes.descriptionContainer}
                                      style={{
                                        borderBottomLeftRadius: 5,
                                        borderBottomRightRadius: 5,
                                        borderBottom: `1px solid ${colors.primaryLightGrey}`
                                      }}
                                    >
                                      <div
                                        className={
                                          classes.descriptionMainContainer
                                        }
                                      >
                                        <img
                                          src={EditPencil}
                                          alt=""
                                          className={
                                            classes.descriptionImageContainer
                                          }
                                        />
                                        <p
                                          className={
                                            classes.descriptionTextContainer
                                          }
                                        >
                                          Created on{' '}
                                          {values.entries[index].createdAt
                                            ? moment(
                                                values.entries[index].createdAt
                                              ).format('DD MMM YYYY')
                                            : moment().format(
                                                'DD MMM YYYY'
                                              )}{' '}
                                          by {values.entries[index].agentName}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {index !== 0 ? (
                                  <div
                                    style={{
                                      marginTop: 20,
                                      marginLeft: 10,
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            (values.entries[index] &&
                                              values.entries[index]
                                                .checkDescription) ||
                                            false
                                          }
                                          onChange={(e) =>
                                            handleChangeCheck(
                                              'checkDescription',
                                              index,
                                              values
                                            )
                                          }
                                          name={`entries[${index}].checkDescription`}
                                        />
                                      }
                                      label="Include main description"
                                      className={classes.includeText}
                                    />
                                    <DeleteOutlineOutlinedIcon
                                      onClick={() =>
                                        setOpenDeleteLinkedModal({
                                          open: true,
                                          id: index
                                        })
                                      }
                                      className={classes.iconTrashStyle}
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>
                      <div>
                        <Button
                          className={classes.linkedButton}
                          startIcon={<AddIcon color={colors.primaryDarkGrey} />}
                          onClick={() => addLinkedEntry(values)}
                          variant="contained"
                          disabled={hideForRepeat}
                        >
                          Add Linked Entry
                        </Button>
                      </div>
                    </div>
                    <div className={classes.drawerRightContent}>
                      <Divider
                        variant="fullWidth"
                        orientation="vertical"
                        style={{ float: 'right' }}
                      />
                      {values.entries.map((entry, index) => (
                        <Fragment key={`right-content-${index}`}>
                          {values.entries.length === 1 || entry.expand ? (
                            <div
                              style={{
                                padding: '0 10px',
                                backgroundColor: colors.tertiary
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                                }}
                              >
                                {hasData && hasData.length ? (
                                  <>
                                    {values.entries[0].groups &&
                                    values.entries[0].groups.length ? (
                                      <FormControl
                                        variant="outlined"
                                        className={classes.formLabelControl}
                                      >
                                        <InputLabel id="demo-simple-select-outlined-for-years">
                                          For
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-for-years"
                                          id="demo-simple-select-outlined"
                                          multiple
                                          name={`entries[${index}].forYears`}
                                          renderValue={(selected) => {
                                            const allSelectedyear =
                                              selected?.includes('Pre K')
                                                ? '-1'
                                                : selected?.includes('Kindy')
                                                ? '0'
                                                : selected;
                                            const gpYear =
                                              groupYears.length &&
                                              groupYears.map((data) => {
                                                if (
                                                  allSelectedyear.includes(
                                                    data.value
                                                  )
                                                ) {
                                                  return data.key;
                                                }
                                                if (
                                                  allSelectedyear.includes(
                                                    'all'
                                                  )
                                                ) {
                                                  return data.key;
                                                } else {
                                                  return null;
                                                }
                                              });
                                            const groupYearsFilter =
                                              gpYear &&
                                              gpYear.filter(
                                                (data) => data && data
                                              );
                                            return groupYearsFilter
                                              ? groupYearsFilter.join(', ')
                                              : '';
                                          }}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleChangeSingleFreeYear(
                                              e,
                                              index,
                                              values.entries,
                                              setFieldValue
                                            );
                                          }}
                                          value={
                                            (values.entries[0] &&
                                              values.entries[0].forYears) ||
                                            ''
                                          }
                                          label="ForYears"
                                        >
                                          <MenuItem value="" disabled={true}>
                                            <em>None</em>
                                          </MenuItem>
                                          <MenuItem value="all" disabled={true}>
                                            <em>All Years</em>
                                          </MenuItem>
                                          {groupYears &&
                                            groupYears.length > 0 &&
                                            groupYears
                                              .sort(function (a, b) {
                                                let yearValue1 = a?.value
                                                  ?.split('Year ')
                                                  .reverse()[0];
                                                let yearValue2 = b?.value
                                                  ?.split('Year ')
                                                  .reverse()[0];
                                                return (
                                                  parseInt(yearValue1) -
                                                  parseInt(yearValue2)
                                                );
                                              })
                                              .map((yearData, forIndex) => (
                                                <MenuItem
                                                  key={`years-${forIndex}`}
                                                  value={yearData.value}
                                                  className={
                                                    classes.menuItemClass
                                                  }
                                                  disabled={true}
                                                >
                                                  <div>
                                                    <Checkbox
                                                      icon={
                                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                                      }
                                                      checkedIcon={
                                                        <CheckBoxIcon fontSize="small" />
                                                      }
                                                      checked={
                                                        yearData.selected
                                                      }
                                                      name={yearData?.value?.toString()}
                                                      className={
                                                        classes.controlCheck
                                                      }
                                                      classes={{
                                                        root: classes.checkbox,
                                                        checked: classes.checked
                                                      }}
                                                    />
                                                    {yearData?.key === 'Year -1'
                                                      ? 'Pre K'
                                                      : yearData?.key}
                                                  </div>
                                                </MenuItem>
                                              ))}
                                        </Select>
                                      </FormControl>
                                    ) : values.entries[index].for ===
                                      multiYear ? (
                                      <FormControl
                                        variant="outlined"
                                        className={classes.formLabelControl}
                                      >
                                        <InputLabel id="multiyear-for-drop-down">
                                          For
                                        </InputLabel>
                                        <Select
                                          labelId="multiyear-for-drop-down"
                                          id="multi-year-select-outlined"
                                          multiple
                                          name={`entries[${index}].forYears`}
                                          renderValue={(selected) => {
                                            const allSelectedyear =
                                              selected?.includes('Pre K')
                                                ? '-1'
                                                : selected?.includes('Kindy')
                                                ? '0'
                                                : selected;
                                            const gpYear =
                                              groupYears.length &&
                                              groupYears.map((data) => {
                                                if (
                                                  allSelectedyear.includes(
                                                    data.value
                                                  )
                                                ) {
                                                  return data.key;
                                                }
                                                if (
                                                  allSelectedyear.includes(
                                                    'all'
                                                  )
                                                ) {
                                                  return data.key;
                                                } else {
                                                  return null;
                                                }
                                              });
                                            const groupYearsFilter =
                                              gpYear &&
                                              gpYear.filter(
                                                (data) => data && data
                                              );
                                            return groupYearsFilter
                                              ? groupYearsFilter.join(', ')
                                              : '';
                                          }}
                                          onChange={(e) => {
                                            handleForYearChange(
                                              e,
                                              index,
                                              values.entries,
                                              setFieldValue
                                            );
                                          }}
                                          value={
                                            (values.entries[0] &&
                                              values.entries[0].forYears) ||
                                            ''
                                          }
                                          label="ForYears"
                                        >
                                          {groupYears &&
                                            groupYears.length > 0 &&
                                            groupYears
                                              .sort(function (a, b) {
                                                let yearValue1 = a?.value
                                                  ?.split('Year ')
                                                  .reverse()[0];
                                                let yearValue2 = b?.value
                                                  ?.split('Year ')
                                                  .reverse()[0];
                                                return (
                                                  parseInt(yearValue1) -
                                                  parseInt(yearValue2)
                                                );
                                              })
                                              .map((yearData, forIndex) => (
                                                <MenuItem
                                                  key={`years-${forIndex}-${yearData.key}`}
                                                  value={yearData.value}
                                                  className={
                                                    classes.menuItemClass
                                                  }
                                                  disabled={index !== 0}
                                                >
                                                  <div>
                                                    <Checkbox
                                                      icon={
                                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                                      }
                                                      checkedIcon={
                                                        <CheckBoxIcon fontSize="small" />
                                                      }
                                                      checked={
                                                        yearData.selected
                                                      }
                                                      name={yearData?.value?.toString()}
                                                      className={
                                                        classes.controlCheck
                                                      }
                                                      classes={{
                                                        root: classes.checkbox,
                                                        checked: classes.checked
                                                      }}
                                                    />
                                                    {yearData?.key === 'Year -1'
                                                      ? 'Pre K'
                                                      : yearData?.key}
                                                  </div>
                                                </MenuItem>
                                              ))}
                                        </Select>
                                      </FormControl>
                                    ) : (
                                      <FormControl
                                        variant="outlined"
                                        className={classes.formLabelControl}
                                      >
                                        <InputLabel id="demo-simple-select-outlined-for">
                                          For
                                        </InputLabel>
                                        <Select
                                          labelId="demo-simple-select-outlined-for"
                                          id="demo-simple-select-outlined"
                                          name={`entries[${index}].for`}
                                          onBlur={handleBlur}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleChangeSingleFreeYear(
                                              e,
                                              index,
                                              values.entries,
                                              setFieldValue
                                            );
                                          }}
                                          value={
                                            freeCalendarsOptions &&
                                            freeCalendarsOptions.length &&
                                            values.entries[index]
                                              ? values.entries[index].for || ''
                                              : values.entries[index].for || ''
                                          }
                                          label="For"
                                          error={Boolean(
                                            touched.entries &&
                                              errors.entries &&
                                              touched.entries[index] &&
                                              touched.entries[index].for &&
                                              errors.entries[index] &&
                                              errors.entries[index].for
                                          )}
                                        >
                                          <MenuItem value="" disabled={true}>
                                            <em>None</em>
                                          </MenuItem>
                                          <MenuItem
                                            value="all"
                                            disabled={
                                              (index !== 0 &&
                                                'all' !==
                                                  values.entries[0].for) ||
                                              (values.entries[0].groups &&
                                                values.entries[0].groups.length)
                                            }
                                          >
                                            <em>All Years</em>
                                          </MenuItem>
                                          {freeCalendarsOptions &&
                                            freeCalendarsOptions.length > 0 &&
                                            freeCalendarsOptions
                                              .sort(function (a, b) {
                                                let yearValue1 = a?.value
                                                  ?.split('Year ')
                                                  .reverse()[0];
                                                let yearValue2 = b?.value
                                                  ?.split('Year ')
                                                  .reverse()[0];
                                                return (
                                                  parseInt(yearValue1) -
                                                  parseInt(yearValue2)
                                                );
                                              })
                                              .map((year, forIndex) => (
                                                <MenuItem
                                                  key={`for-select-${forIndex}`}
                                                  value={year.id}
                                                  disabled={
                                                    index !== 0 &&
                                                    year.id !==
                                                      values.entries[0].for &&
                                                    values.entries[0].for !==
                                                      'all'
                                                  }
                                                >
                                                  {year?.value === 'Year -1'
                                                    ? 'Pre K'
                                                    : year?.value === 'Year 0'
                                                    ? 'Kindy'
                                                    : year?.value}
                                                </MenuItem>
                                              ))}
                                        </Select>
                                      </FormControl>
                                    )}
                                  </>
                                ) : (
                                  <FormControl
                                    variant="outlined"
                                    className={classes.formLabelControl}
                                  >
                                    <InputLabel id="demo-simple-select-outlined-label-for">
                                      For
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label-for"
                                      id="demo-simple-select-outlined-for"
                                      multiple
                                      name={`entries.[${index}].for`}
                                      onBlur={handleBlur}
                                      renderValue={(selected) => {
                                        const familyName =
                                          values.entries[index] &&
                                          values.entries[index]
                                            .freeCalendarsMultipleOptions &&
                                          values.entries[index]
                                            .freeCalendarsMultipleOptions
                                            .length &&
                                          values.entries[
                                            index
                                          ].freeCalendarsMultipleOptions.map(
                                            (data) => {
                                              if (
                                                selected.includes(data.value)
                                              ) {
                                                return data.key;
                                              } else {
                                                return null;
                                              }
                                            }
                                          );
                                        const familyNameFilter =
                                          familyName.filter(
                                            (data) => data && data
                                          );
                                        return familyNameFilter.join(', ');
                                      }}
                                      onChange={(e) => {
                                        handleChange(e);
                                        handleChangeFreeYear(
                                          e,
                                          index,
                                          values.entries,
                                          setFieldValue
                                        );
                                      }}
                                      value={
                                        (values.entries[0] &&
                                          values.entries[0]
                                            .freeCalendarsMultipleOptions &&
                                          values.entries[0]
                                            .freeCalendarsMultipleOptions
                                            .length &&
                                          Array.isArray(
                                            values.entries[0].for
                                          ) &&
                                          values.entries[0].for) ||
                                        []
                                      }
                                      label="For"
                                      error={Boolean(
                                        touched.entries &&
                                          errors.entries &&
                                          touched.entries[index] &&
                                          touched.entries[index].for &&
                                          errors.entries[index] &&
                                          errors.entries[index].for
                                      )}
                                    >
                                      {values.entries[0] &&
                                        values.entries[0]
                                          .freeCalendarsMultipleOptions &&
                                        values.entries[0]
                                          .freeCalendarsMultipleOptions
                                          .length &&
                                        values.entries[0].freeCalendarsMultipleOptions
                                          ?.sort(function (a, b) {
                                            let yearValue1 = a?.value
                                              ?.split('Year ')
                                              .reverse()[0];
                                            let yearValue2 = b?.value
                                              ?.split('Year ')
                                              .reverse()[0];
                                            return (
                                              parseInt(yearValue1) -
                                              parseInt(yearValue2)
                                            );
                                          })
                                          .map((yearData, forIndex) => (
                                            <MenuItem
                                              key={`years-${forIndex}`}
                                              value={yearData.value}
                                              className={classes.menuItemClass}
                                              disabled={index !== 0}
                                            >
                                              <div>
                                                <Checkbox
                                                  color="primary"
                                                  icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                  }
                                                  checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                  }
                                                  checked={yearData.selected}
                                                  name={yearData.value.toString()}
                                                  className={
                                                    classes.controlCheck
                                                  }
                                                  classes={{
                                                    root: classes.checkbox,
                                                    checked: classes.checked
                                                  }}
                                                />
                                                {yearData.key}
                                              </div>
                                            </MenuItem>
                                          ))}
                                    </Select>
                                  </FormControl>
                                )}
                                <Divider
                                  variant="fullWidth"
                                  style={{ marginTop: '20px' }}
                                />
                                <div style={{ marginTop: 20 }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          false
                                        }
                                        onChange={(e) =>
                                          handleChangeCheck(
                                            'allDay',
                                            index,
                                            values
                                          )
                                        }
                                        name={`entries[${index}].allDay`}
                                      />
                                    }
                                    label="All day"
                                  />
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: 10
                                  }}
                                >
                                  <Typography>From</Typography>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: 5
                                    }}
                                  >
                                    <DatePicker
                                      disabled={hideForRepeat}
                                      autoOk
                                      okLabel=""
                                      cancelLabel=""
                                      name={`entries[${index}].from.date`}
                                      onBlur={handleBlur}
                                      onChange={(date) =>
                                        handleDateChange(
                                          date,
                                          index,
                                          values,
                                          'from',
                                          'date'
                                        )
                                      }
                                      value={
                                        (values.entries[index] &&
                                          moment(
                                            values.entries[index].from.date
                                          )) ||
                                        ''
                                      }
                                      style={{ width: '40%' }}
                                      format="MMM DD,YYYY"
                                      error={
                                        values.entries?.[index]?.isPrimary &&
                                        recurringEntriesErrors.message
                                          ? true
                                          : false
                                      }
                                      className={
                                        values.entries?.[index]?.isPrimary &&
                                        recurringEntriesErrors.message
                                          ? classes.formDateControlFromBorderedError
                                          : classes.formDateControlFromBordered
                                      }
                                    />
                                    <FormControl
                                      style={{ marginTop: 0 }}
                                      className={classes.formLabelControlTime}
                                    >
                                      <TextField
                                        label=""
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 1, max: 12 }
                                        }}
                                        variant="outlined"
                                        placeholder="hh"
                                        //style={{height: '23px', marginTop: 0}}
                                        className={classes.inputControlBoxTime}
                                        name={`entries[${index}].newFromTimeFormate.hh`}
                                        onBlur={(e) => {
                                          const updatedValues =
                                            values.entries.map((item, ix) => {
                                              if (index === ix) {
                                                if (!e.target.value) {
                                                  item.newFromTimeFormate.hh =
                                                    '00';
                                                } else if (
                                                  parseInt(e.target.value) <
                                                    10 &&
                                                  e.target.value.length < 2
                                                ) {
                                                  item.newFromTimeFormate.hh = `0${e.target.value}`;
                                                } else {
                                                  item.newFromTimeFormate.hh = `${e.target.value}`;
                                                }
                                              }
                                              return item;
                                            });
                                          setNotificationTime(
                                            convertNotificationTime,
                                            values,
                                            index
                                          );
                                        }}
                                        onChange={(e) => {
                                          if (
                                            (e.target.value.length <= 2 &&
                                              parseInt(e.target.value) <= 12 &&
                                              parseInt(e.target.value) > 0 &&
                                              Number.isInteger(
                                                parseInt(e.target.value)
                                              )) ||
                                            e.target.value === ''
                                          ) {
                                            handleChange(e);
                                            if (
                                              hasData &&
                                              hasData.length === 0
                                            ) {
                                              setFieldValue(
                                                `entries[${index}].newToTimeFormate.hh`,
                                                parseInt(e.target.value) === 12
                                                  ? '1'
                                                  : 1 + parseInt(e.target.value)
                                              );
                                            }
                                            setConvertNotificationTime({
                                              ...convertNotificationTime,
                                              hh: parseInt(e.target.value)
                                            });
                                          }
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .newFromTimeFormate &&
                                            values.entries[index]
                                              .newFromTimeFormate.hh) ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index]
                                              .newFromTimeFormate &&
                                            touched.entries[index]
                                              .newFromTimeFormate.hh &&
                                            errors.entries[index] &&
                                            errors.entries[index]
                                              .newFromTimeFormate &&
                                            errors.entries[index]
                                              .newFromTimeFormate.hh
                                        )}
                                        disabled={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          false
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      style={{ marginTop: 0 }}
                                      className={classes.formLabelControlTime}
                                    >
                                      <TextField
                                        label=""
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 0, max: 59 }
                                        }}
                                        variant="outlined"
                                        placeholder="mm"
                                        className={classes.inputControlBoxTime}
                                        name={`entries[${index}].newFromTimeFormate.mm`}
                                        onBlur={(e) => {
                                          // if (!e.target.value) {
                                          //   setFieldValue(
                                          //     `entries[${index}].newFromTimeFormate.mm`,
                                          //     '00'
                                          //   );
                                          // } else if (
                                          //   parseInt(e.target.value) < 10 &&
                                          //   e.target.value.length < 2
                                          // ) {
                                          //   setFieldValue(
                                          //     `entries[${index}].newFromTimeFormate.mm`,
                                          //     `0${e.target.value}`
                                          //   );
                                          // } else {
                                          //   setFieldValue(
                                          //     `entries[${index}].newFromTimeFormate.mm`,
                                          //     e.target.value
                                          //   );
                                          // }
                                          const updatedValues =
                                            values.entries.map((item, ix) => {
                                              if (index === ix) {
                                                if (!e.target.value) {
                                                  item.newFromTimeFormate.mm =
                                                    '00';
                                                } else if (
                                                  parseInt(e.target.value) <
                                                    10 &&
                                                  e.target.value.length < 2
                                                ) {
                                                  item.newFromTimeFormate.mm = `0${e.target.value}`;
                                                } else {
                                                  item.newFromTimeFormate.mm = `${e.target.value}`;
                                                }
                                              }
                                              return item;
                                            });
                                          setNotificationTime(
                                            convertNotificationTime,
                                            values,
                                            index
                                          );
                                        }}
                                        onChange={(e) => {
                                          if (
                                            (e.target.value.length <= 2 &&
                                              parseInt(e.target.value) <= 59 &&
                                              parseInt(e.target.value) >= 0 &&
                                              Number.isInteger(
                                                parseInt(e.target.value)
                                              )) ||
                                            e.target.value === ''
                                          ) {
                                            handleChange(e);
                                            setFieldValue(
                                              `entries[${index}].newFromTimeFormate.mm`,
                                              e.target.value
                                            );
                                            if (
                                              hasData &&
                                              hasData.length === 0
                                            ) {
                                              setFieldValue(
                                                `entries[${index}].newToTimeFormate.mm`,
                                                e.target.value
                                              );
                                            }
                                            setConvertNotificationTime({
                                              ...convertNotificationTime,
                                              mm: parseInt(e.target.value)
                                            });
                                          }
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .newFromTimeFormate &&
                                            values.entries[index]
                                              .newFromTimeFormate.mm) ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index]
                                              .newFromTimeFormate &&
                                            touched.entries[index]
                                              .newFromTimeFormate.mm &&
                                            errors.entries[index] &&
                                            errors.entries[index]
                                              .newFromTimeFormate &&
                                            errors.entries[index]
                                              .newFromTimeFormate.mm
                                        )}
                                        disabled={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          false
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      style={{ marginTop: 0 }}
                                      className={classes.formLabelControlTime}
                                    >
                                      <TextField
                                        label=""
                                        variant="outlined"
                                        placeholder="a/p"
                                        //style={{height: '23px', marginTop: 0}}
                                        className={classes.inputControlBoxTime}
                                        name={`entries[${index}].newFromTimeFormate.ap`}
                                        onBlur={() => {
                                          setNotificationTime(
                                            convertNotificationTime,
                                            values,
                                            index
                                          );
                                        }}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === '' ||
                                            e.target.value.toLowerCase() ===
                                              'a' ||
                                            e.target.value.toLowerCase() === 'p'
                                          ) {
                                            handleChange(e);
                                            if (
                                              hasData &&
                                              hasData.length === 0
                                            ) {
                                              setFieldValue(
                                                `entries[${index}].newToTimeFormate.ap`,
                                                e.target.value
                                              );
                                            }
                                            setConvertNotificationTime({
                                              ...convertNotificationTime,
                                              ap: e.target.value
                                            });
                                          }
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .newFromTimeFormate &&
                                            values.entries[index]
                                              .newFromTimeFormate.ap) ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index]
                                              .newFromTimeFormate &&
                                            touched.entries[index]
                                              .newFromTimeFormate.ap &&
                                            errors.entries[index] &&
                                            errors.entries[index]
                                              .newFromTimeFormate &&
                                            errors.entries[index]
                                              .newFromTimeFormate.ap
                                        )}
                                        disabled={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          false
                                        }
                                      />
                                    </FormControl>

                                    {/* <ThemeProvider theme={muiTimePickerTheme}>
                                  <TimePicker
                                    autoOk
                                    name={`entries[${index}].from.time`}
                                    onBlur={handleBlur}
                                    onChange={(e) =>
                                      handleDateChange(
                                        e,
                                        index,
                                        values,
                                        'from',
                                        'time'
                                      )
                                    }
                                    value={
                                      (values.entries[index] &&
                                        moment(
                                          values.entries[index].from.time,
                                          'h:mma'
                                        )) ||
                                      ''
                                    }
                                    format="h:mma"
                                    style={{ width: 80 }}
                                    className={
                                      classes.formDateControlFromBordered
                                    }
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </ThemeProvider> */}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: 10
                                  }}
                                >
                                  <Typography>To</Typography>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: 5
                                    }}
                                  >
                                    <DatePicker
                                      disabled={hideForRepeat}
                                      autoOk
                                      okLabel=""
                                      cancelLabel=""
                                      name={`entries[${index}].to.date`}
                                      onBlur={handleBlur}
                                      minDate={
                                        (values.entries[index] &&
                                          moment(
                                            values.entries[index].from.date
                                          )) ||
                                        ''
                                      }
                                      onChange={(date) =>
                                        handleDateChange(
                                          date,
                                          index,
                                          values,
                                          'to',
                                          'date'
                                        )
                                      }
                                      value={
                                        (values.entries[index] &&
                                          moment(
                                            values.entries[index].to.date
                                          )) ||
                                        ''
                                      }
                                      style={{ width: '40%' }}
                                      format="MMM DD,YYYY"
                                      error={
                                        values.entries?.[index]?.isPrimary &&
                                        recurringEntriesErrors.message
                                          ? true
                                          : false
                                      }
                                      className={
                                        values.entries?.[index]?.isPrimary &&
                                        recurringEntriesErrors.message
                                          ? classes.formDateControlFromBorderedError
                                          : classes.formDateControlFromBordered
                                      }
                                    />
                                    <FormControl
                                      style={{ marginTop: 0 }}
                                      className={classes.formLabelControlTime}
                                    >
                                      <TextField
                                        label=""
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 1, max: 12 }
                                        }}
                                        variant="outlined"
                                        placeholder="hh"
                                        //style={{height: '23px', marginTop: 0}}
                                        className={classes.inputControlBoxTime}
                                        name={`entries[${index}].newToTimeFormate.hh`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          if (
                                            (e.target.value.length <= 2 &&
                                              parseInt(e.target.value) <= 12 &&
                                              parseInt(e.target.value) > 0 &&
                                              Number.isInteger(
                                                parseInt(e.target.value)
                                              )) ||
                                            e.target.value === ''
                                          )
                                            handleChange(e);
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .newToTimeFormate &&
                                            values.entries[index]
                                              .newToTimeFormate.hh) ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index]
                                              .newToTimeFormate &&
                                            touched.entries[index]
                                              .newToTimeFormate.hh &&
                                            errors.entries[index] &&
                                            errors.entries[index]
                                              .newToTimeFormate &&
                                            errors.entries[index]
                                              .newToTimeFormate.hh
                                        )}
                                        disabled={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          false
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      style={{ marginTop: 0 }}
                                      className={classes.formLabelControlTime}
                                    >
                                      <TextField
                                        label=""
                                        type="number"
                                        InputProps={{
                                          inputProps: { min: 0, max: 59 }
                                        }}
                                        variant="outlined"
                                        placeholder="mm"
                                        className={classes.inputControlBoxTime}
                                        name={`entries[${index}].newToTimeFormate.mm`}
                                        onBlur={(e) => {
                                          if (!e.target.value) {
                                            setFieldValue(
                                              `entries[${index}].newToTimeFormate.mm`,
                                              '00'
                                            );
                                          } else if (
                                            parseInt(e.target.value) < 10 &&
                                            e.target.value.length < 2
                                          ) {
                                            setFieldValue(
                                              `entries[${index}].newToTimeFormate.mm`,
                                              `0${e.target.value}`
                                            );
                                          } else {
                                            setFieldValue(
                                              `entries[${index}].newToTimeFormate.mm`,
                                              e.target.value
                                            );
                                          }
                                        }}
                                        onChange={(e) => {
                                          if (
                                            (e.target.value.length <= 2 &&
                                              parseInt(e.target.value) <= 59 &&
                                              parseInt(e.target.value) >= 0 &&
                                              Number.isInteger(
                                                parseInt(e.target.value)
                                              )) ||
                                            e.target.value === ''
                                          ) {
                                            handleChange(e);
                                            setFieldValue(
                                              `entries[${index}].newToTimeFormate.mm`,
                                              e.target.value
                                            );
                                          }
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .newToTimeFormate &&
                                            values.entries[index]
                                              .newToTimeFormate.mm) ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index]
                                              .newToTimeFormate &&
                                            touched.entries[index]
                                              .newToTimeFormate.mm &&
                                            errors.entries[index] &&
                                            errors.entries[index]
                                              .newToTimeFormate &&
                                            errors.entries[index]
                                              .newToTimeFormate.mm
                                        )}
                                        disabled={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          false
                                        }
                                      />
                                    </FormControl>
                                    <FormControl
                                      style={{ marginTop: 0 }}
                                      className={classes.formLabelControlTime}
                                    >
                                      <TextField
                                        label=""
                                        variant="outlined"
                                        placeholder="a/p"
                                        //style={{height: '23px', marginTop: 0}}
                                        className={classes.inputControlBoxTime}
                                        name={`entries[${index}].newToTimeFormate.ap`}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                          if (
                                            e.target.value === '' ||
                                            e.target.value.toLowerCase() ===
                                              'a' ||
                                            e.target.value.toLowerCase() === 'p'
                                          ) {
                                            handleChange(e);
                                          }
                                        }}
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index]
                                              .newToTimeFormate &&
                                            values.entries[index]
                                              .newToTimeFormate.ap) ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.entries &&
                                            errors.entries &&
                                            touched.entries[index] &&
                                            touched.entries[index]
                                              .newToTimeFormate &&
                                            touched.entries[index]
                                              .newToTimeFormate.ap &&
                                            errors.entries[index] &&
                                            errors.entries[index]
                                              .newToTimeFormate &&
                                            errors.entries[index]
                                              .newToTimeFormate.ap
                                        )}
                                        disabled={
                                          (values.entries[index] &&
                                            values.entries[index].allDay) ||
                                          false
                                        }
                                      />
                                    </FormControl>
                                    {/* <ThemeProvider theme={muiTimePickerTheme}>
                                  <TimePicker
                                    autoOk
                                    name={`entries[${index}].to.time`}
                                    onBlur={handleBlur}
                                    onChange={(e) =>
                                      handleDateChange(
                                        e,
                                        index,
                                        values,
                                        'to',
                                        'time'
                                      )
                                    }
                                    value={
                                      (values.entries[index] &&
                                        moment(
                                          values.entries[index].to.time,
                                          'h:mma'
                                        )) ||
                                      ''
                                    }
                                    format="h:mma"
                                    style={{ width: 80 }}
                                    className={
                                      classes.formDateControlFromBordered
                                    }
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </ThemeProvider> */}
                                  </div>
                                  {Boolean(
                                    touched.entries &&
                                      errors.entries &&
                                      touched.entries[index] &&
                                      touched.entries[index].from &&
                                      touched.entries[index].from.time &&
                                      errors.entries[index] &&
                                      errors.entries[index].to
                                  ) && (
                                    <FormHelperText
                                      style={{ color: colors.error }}
                                    >
                                      {errors.entries[index].to}
                                    </FormHelperText>
                                  )}
                                </div>
                                {/* <div style={{ marginTop: 20, marginLeft: 10 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                    onChange={(e) =>
                                      handleChangeCheck('allDay', index, values)
                                    }
                                    name={`entries[${index}].allDay`}
                                  />
                                }
                                label="All day"
                              />
                            </div> */}
                                <Divider
                                  variant="fullWidth"
                                  style={{ marginBottom: 20, marginTop: 20 }}
                                />
                                {entry.actions.repeat.length && index === 0 ? (
                                  <div style={{ marginTop: 20 }}>
                                    <Typography variant="subtitle2">
                                      Repeat
                                    </Typography>
                                    {entry.actions.repeat.map((data, idx) => (
                                      <div
                                        key={`repeat-${idx}`}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginTop: 16
                                        }}
                                      >
                                        <FormControl
                                          className={
                                            classes.formLabelControlBordered
                                          }
                                          style={{ width: '90%' }}
                                        >
                                          <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            name={`entries[${index}].actions.repeat[${idx}].type`}
                                            onBlur={handleBlur}
                                            onChange={(e) =>
                                              handleRepeatChange(
                                                e,
                                                index,
                                                idx,
                                                values
                                              )
                                            }
                                            value={
                                              (values.entries[index] &&
                                                values.entries[index].actions
                                                  .repeat[idx].type) ||
                                              ''
                                            }
                                            label="Repeat"
                                            // disabled={hideForRepeat}
                                          >
                                            {/* <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem> */}
                                            <MenuItem
                                              value={'Does not repeat'}
                                              // disabled={hideForRepeat}
                                            >
                                              Does not repeat
                                            </MenuItem>
                                            <MenuItem
                                              value={'Daily'}
                                              // disabled={hideForRepeat}
                                            >
                                              Daily
                                            </MenuItem>
                                            <MenuItem
                                              value={'Custom'}
                                              // disabled={hideForRepeat}
                                            >
                                              Custom
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                        {(values.entries[index] &&
                                          values.entries[index].actions.repeat[
                                            idx
                                          ].type) === 'Custom' ? (
                                          <VisibilityIcon
                                            onClick={(e) => {
                                              const data = {
                                                selectedIndex: index,
                                                innerIndex: idx,
                                                values: {
                                                  entries: [...values.entries]
                                                }
                                              };
                                              setCustomValue(data);
                                              setOpenModal({
                                                open: true,
                                                value: JSON.stringify(
                                                  values.entries[index].actions
                                                    .repeat[idx]
                                                )
                                              });
                                            }}
                                            style={{ margin: '0 5px' }}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                        {!hideForRepeat ? (
                                          <CloseIcon
                                            onClick={(e) =>
                                              handleDateActionRemove(
                                                index,
                                                idx,
                                                values,
                                                'repeat'
                                              )
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={Calendar}
                                            alt="calendar"
                                            onClick={() =>
                                              goSourceEntry(
                                                hasData[0].parentRepeatEntryId
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {entry.actions.notification.length ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      marginTop: 20
                                    }}
                                  >
                                    <Typography variant="subtitle2">
                                      Notification
                                    </Typography>
                                    {entry.actions.notification.map(
                                      (data, idx) => (
                                        <div
                                          key={`notification-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16
                                          }}
                                        >
                                          <FormControl
                                            variant="outlined"
                                            className={
                                              classes.formDayLabelControl
                                            }
                                          >
                                            <DatePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              okLabel=""
                                              cancelLabel=""
                                              name={`entries[${index}].actions.notification[${idx}].date`}
                                              onBlur={handleBlur}
                                              // maxDate={
                                              //   (values.entries[index] &&
                                              //     moment(
                                              //       values.entries[index].from.date
                                              //     )) ||
                                              //   ''
                                              // }
                                              onChange={(date) =>
                                                handleNotificationDateChange(
                                                  date,
                                                  index,
                                                  idx,
                                                  values,
                                                  'date'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .notification[idx].date) ||
                                                moment().format('MMM DD,YYYY')
                                              }
                                              style={{ width: '80%' }}
                                              minDateMessage=""
                                              format="MMM DD,YYYY"
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </FormControl>
                                          <ThemeProvider
                                            theme={muiTimePickerTheme}
                                          >
                                            <TimePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              name={`entries[${index}].notification.time`}
                                              onBlur={handleBlur}
                                              onChange={(e) =>
                                                handleDateActionChange(
                                                  e,
                                                  index,
                                                  idx,
                                                  values,
                                                  'notification',
                                                  'time'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  moment(
                                                    values.entries[index]
                                                      .actions.notification[idx]
                                                      .time,
                                                    'h:mma'
                                                  )) ||
                                                ''
                                              }
                                              format="h:mma"
                                              style={{ width: 80 }}
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </ThemeProvider>
                                          <CloseIcon
                                            onClick={(e) => {
                                              // if(!hideForRepeat){
                                              handleDateActionRemove(
                                                index,
                                                idx,
                                                values,
                                                'notification'
                                              );
                                              // }
                                            }}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {entry.actions.reminder.length ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      marginTop: 20
                                    }}
                                  >
                                    {entry.actions.reminder.map((data, idx) => (
                                      <>
                                        <Typography variant="subtitle2">
                                          Reminder
                                        </Typography>
                                        <div
                                          key={`reminder-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16
                                          }}
                                        >
                                          <DatePicker
                                            autoOk
                                            okLabel=""
                                            cancelLabel=""
                                            name={`entries[${index}].reminder`}
                                            onBlur={handleBlur}
                                            onChange={(e) =>
                                              handleDateActionChange(
                                                e,
                                                index,
                                                idx,
                                                values,
                                                'reminder',
                                                'date'
                                              )
                                            }
                                            value={
                                              (values.entries[index] &&
                                                moment(
                                                  values.entries[index].actions
                                                    .reminder[idx]
                                                )) ||
                                              ''
                                            }
                                            format="MMM DD,YYYY"
                                            style={{ width: '80%' }}
                                            className={
                                              classes.formDateControlFromBordered
                                            }
                                          />
                                          <CloseIcon
                                            onClick={(e) =>
                                              handleDateActionRemove(
                                                index,
                                                idx,
                                                values,
                                                'reminder'
                                              )
                                            }
                                          />
                                        </div>

                                        {values.entries[index] &&
                                        values.entries[index].actions
                                          .reminderNotification &&
                                        values.entries[index].actions
                                          .reminderNotification[idx] ? (
                                          <>
                                            <div
                                              key={`reminderNotificationTitle-${idx}`}
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: 16
                                              }}
                                            >
                                              <Typography variant="subtitle2">
                                                Reminder (Notification)
                                              </Typography>
                                            </div>
                                            <div
                                              key={`reminderNotification-${idx}`}
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: 16,
                                                marginBottom: 16
                                              }}
                                            >
                                              <FormControl
                                                variant="outlined"
                                                className={
                                                  classes.formDayLabelControl
                                                }
                                              >
                                                <DatePicker
                                                  // disabled={hideForRepeat}
                                                  autoOk
                                                  okLabel=""
                                                  cancelLabel=""
                                                  name={`entries[${index}].actions.reminderNotification[${idx}].date`}
                                                  onBlur={handleBlur}
                                                  // maxDate={
                                                  //   (values.entries[index] &&
                                                  //     moment(
                                                  //       values.entries[index].from.date
                                                  //     )) ||
                                                  //   ''
                                                  // }
                                                  onChange={(date) =>
                                                    handleNotificationDateChange(
                                                      date,
                                                      index,
                                                      idx,
                                                      values,
                                                      'date',
                                                      'reminderNotification'
                                                    )
                                                  }
                                                  value={
                                                    (values.entries[index] &&
                                                      values.entries[index]
                                                        .actions
                                                        .reminderNotification &&
                                                      values.entries[index]
                                                        .actions
                                                        .reminderNotification[
                                                        idx
                                                      ] &&
                                                      values.entries[index]
                                                        .actions
                                                        .reminderNotification[
                                                        idx
                                                      ].date) ||
                                                    moment().format(
                                                      'MMM DD,YYYY'
                                                    )
                                                  }
                                                  style={{ width: '80%' }}
                                                  minDateMessage=""
                                                  format="MMM DD,YYYY"
                                                  className={
                                                    classes.formDateControlFromBordered
                                                  }
                                                />
                                              </FormControl>
                                              <ThemeProvider
                                                theme={muiTimePickerTheme}
                                              >
                                                <TimePicker
                                                  // disabled={hideForRepeat}
                                                  autoOk
                                                  name={`entries[${index}].reminderNotification.time`}
                                                  onBlur={handleBlur}
                                                  onChange={(e) =>
                                                    handleDateActionChange(
                                                      e,
                                                      index,
                                                      idx,
                                                      values,
                                                      'reminderNotification',
                                                      'time'
                                                    )
                                                  }
                                                  value={
                                                    (values.entries[index] &&
                                                      values.entries[index]
                                                        .actions
                                                        .reminderNotification &&
                                                      values.entries[index]
                                                        .actions
                                                        .reminderNotification[
                                                        idx
                                                      ] &&
                                                      moment(
                                                        values.entries[index]
                                                          .actions
                                                          .reminderNotification[
                                                          idx
                                                        ].time,
                                                        'h:mma'
                                                      )) ||
                                                    ''
                                                  }
                                                  format="h:mma"
                                                  style={{ width: '40%' }}
                                                  className={
                                                    classes.formDateControlFromBordered
                                                  }
                                                />
                                              </ThemeProvider>
                                              <CloseIcon
                                                onClick={(e) => {
                                                  // if(!hideForRepeat){
                                                  handleDateActionRemove(
                                                    index,
                                                    idx,
                                                    values,
                                                    'reminderNotification'
                                                  );
                                                  // }
                                                }}
                                              />
                                            </div>
                                          </>
                                        ) : null}
                                      </>
                                    ))}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {entry.actions.deadline.length ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      marginTop: 20
                                    }}
                                  >
                                    {entry.actions.deadline.map((data, idx) => (
                                      <>
                                        <Typography variant="subtitle2">
                                          Deadline
                                        </Typography>
                                        <div
                                          key={`deadline-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16
                                          }}
                                        >
                                          <DatePicker
                                            autoOk
                                            okLabel=""
                                            cancelLabel=""
                                            name={`entries[${index}].deadline`}
                                            onBlur={handleBlur}
                                            onChange={(e) =>
                                              handleDateActionChange(
                                                e,
                                                index,
                                                idx,
                                                values,
                                                'deadline',
                                                'date'
                                              )
                                            }
                                            value={
                                              (values.entries[index] &&
                                                moment(
                                                  values.entries[index].actions
                                                    .deadline[idx]
                                                )) ||
                                              ''
                                            }
                                            format="MMM DD,YYYY"
                                            style={{ width: '80%' }}
                                            className={
                                              classes.formDateControlFromBordered
                                            }
                                          />
                                          <CloseIcon
                                            onClick={(e) =>
                                              handleDateActionRemove(
                                                index,
                                                idx,
                                                values,
                                                'deadline'
                                              )
                                            }
                                          />
                                        </div>

                                        {values.entries[index] &&
                                        values.entries[index].actions
                                          .deadlineNotification &&
                                        values.entries[index].actions
                                          .deadlineNotification[idx] ? (
                                          <>
                                            <div
                                              key={`reminderDeadlineTitle-${idx}`}
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: 16
                                              }}
                                            >
                                              <Typography variant="subtitle2">
                                                Deadline (Notification)
                                              </Typography>
                                            </div>
                                            <div
                                              key={`deadlineNotification-${idx}`}
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginTop: 16,
                                                marginBottom: 16
                                              }}
                                            >
                                              <FormControl
                                                variant="outlined"
                                                className={
                                                  classes.formDayLabelControl
                                                }
                                              >
                                                <DatePicker
                                                  // disabled={hideForRepeat}
                                                  autoOk
                                                  okLabel=""
                                                  cancelLabel=""
                                                  name={`entries[${index}].actions.deadlineNotification[${idx}].date`}
                                                  onBlur={handleBlur}
                                                  // maxDate={
                                                  //   (values.entries[index] &&
                                                  //     moment(
                                                  //       values.entries[index].from.date
                                                  //     )) ||
                                                  //   ''
                                                  // }
                                                  onChange={(date) =>
                                                    handleNotificationDateChange(
                                                      date,
                                                      index,
                                                      idx,
                                                      values,
                                                      'date',
                                                      'deadlineNotification'
                                                    )
                                                  }
                                                  value={
                                                    (values.entries[index] &&
                                                      values.entries[index]
                                                        .actions
                                                        .deadlineNotification &&
                                                      values.entries[index]
                                                        .actions
                                                        .deadlineNotification[
                                                        idx
                                                      ] &&
                                                      values.entries[index]
                                                        .actions
                                                        .deadlineNotification[
                                                        idx
                                                      ].date) ||
                                                    moment().format(
                                                      'MMM DD,YYYY'
                                                    )
                                                  }
                                                  style={{ width: '80%' }}
                                                  minDateMessage=""
                                                  format="MMM DD,YYYY"
                                                  className={
                                                    classes.formDateControlFromBordered
                                                  }
                                                />
                                              </FormControl>
                                              <ThemeProvider
                                                theme={muiTimePickerTheme}
                                              >
                                                <TimePicker
                                                  // disabled={hideForRepeat}
                                                  autoOk
                                                  name={`entries[${index}].deadlineNotification.time`}
                                                  onBlur={handleBlur}
                                                  onChange={(e) =>
                                                    handleDateActionChange(
                                                      e,
                                                      index,
                                                      idx,
                                                      values,
                                                      'deadlineNotification',
                                                      'time'
                                                    )
                                                  }
                                                  value={
                                                    (values.entries[index] &&
                                                      values.entries[index]
                                                        .actions
                                                        .deadlineNotification &&
                                                      values.entries[index]
                                                        .actions
                                                        .deadlineNotification[
                                                        idx
                                                      ] &&
                                                      moment(
                                                        values.entries[index]
                                                          .actions
                                                          .deadlineNotification[
                                                          idx
                                                        ].time,
                                                        'h:mma'
                                                      )) ||
                                                    ''
                                                  }
                                                  format="h:mma"
                                                  style={{ width: '40%' }}
                                                  className={
                                                    classes.formDateControlFromBordered
                                                  }
                                                />
                                              </ThemeProvider>
                                              <CloseIcon
                                                onClick={(e) => {
                                                  // if(!hideForRepeat){
                                                  handleDateActionRemove(
                                                    index,
                                                    idx,
                                                    values,
                                                    'deadlineNotification'
                                                  );
                                                  // }
                                                }}
                                              />
                                            </div>
                                          </>
                                        ) : null}
                                      </>
                                    ))}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {entry.actions.isCancelled ||
                                entry.actions.isPostponed ||
                                entry.actions.isChanged ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginTop: 20
                                    }}
                                  >
                                    <div>
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          color: entry.actions.isChanged
                                            ? '#000'
                                            : colors.tertiaryRed
                                        }}
                                        className={classes.updatedLabel}
                                      >
                                        {entry.actions.isPostponed
                                          ? 'Postponed'
                                          : entry.actions.isCancelled
                                          ? 'Cancelled'
                                          : 'Updated'}
                                      </Typography>
                                      <Typography
                                        className={classes.updatedDesc}
                                        style={{
                                          ...(entry.actions.isChanged
                                            ? {}
                                            : { fontSize: 16 }),
                                          color: colors.primaryDarkGrey
                                        }}
                                      >
                                        Display{' '}
                                        {entry.actions.isPostponed
                                          ? 'Postponed'
                                          : entry.actions.isCancelled
                                          ? 'Cancelled'
                                          : 'Updated'}{' '}
                                        to member
                                      </Typography>
                                    </div>
                                    <CloseIcon
                                      onClick={(e) =>
                                        handleDateActionRemove(
                                          index,
                                          0,
                                          values,
                                          entry.actions.isPostponed
                                            ? 'isPostponed'
                                            : entry.actions.isCancelled
                                            ? 'isCancelled'
                                            : 'isChanged'
                                        )
                                      }
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <FormControl
                                  variant="outlined"
                                  // disabled={hideForRepeat}
                                  style={{ marginBottom: 24 }}
                                  className={classes.formLabelControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label">
                                    Apply Action
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={''}
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        handleActionChange(e, index, values);
                                      }
                                    }}
                                    label="Apply Action"
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {index === 0 && (
                                      <MenuItem
                                        disabled={
                                          entry.actions.repeat.length ||
                                          hideForRepeat
                                            ? true
                                            : false
                                        }
                                        value={'repeat'}
                                      >
                                        Repeat
                                      </MenuItem>
                                    )}
                                    <MenuItem value={'notification'}>
                                      Notification
                                    </MenuItem>
                                    <MenuItem
                                      value={'deadline'}
                                      disabled={hideForRepeat}
                                    >
                                      Deadline
                                    </MenuItem>
                                    <MenuItem
                                      value={'reminder'}
                                      disabled={hideForRepeat}
                                    >
                                      Reminder
                                    </MenuItem>
                                    {index === 0 && (
                                      <MenuItem
                                        value={'cancelled'}
                                        // disabled={hideForRepeat}
                                      >
                                        Cancelled
                                      </MenuItem>
                                    )}
                                    {index === 0 && (
                                      <MenuItem
                                        value={'postponed'}
                                        // disabled={hideForRepeat}
                                      >
                                        Postponed
                                      </MenuItem>
                                    )}
                                    {index === 0 && (
                                      <MenuItem
                                        value={'changed'}
                                        // disabled={hideForRepeat}
                                      >
                                        Updated
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </form>
                </>
              )}
              {console.log('isRepeatEntryModal', isRepeatEntryModal)}
              {isRepeatEntryModal && (
                <Modal
                  isOpen={isRepeatEntryModal}
                  onClose={() => {
                    setRepeatEntryModal(false);
                  }}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className={classes.label}>
                      <Typography className={classes.repeatEveryText}>
                        {isRepeatEntryModal === true ? 'Edit' : 'Delete'}{' '}
                        recurring event
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      aria-label="endsType"
                      name="repeatType"
                      onChange={(e) => {
                        setRepeatEntryRadioData(e.target.value);
                      }}
                      value={repeatEntryRadioData}
                    >
                      {!isRepeatChange && (
                        <FormControlLabel
                          value={'This Event'}
                          control={<Radio color="primary" />}
                          label={'This Event'}
                        />
                      )}
                      <FormControlLabel
                        value={'This and following events'}
                        control={<Radio color="primary" />}
                        label={'This and following events'}
                      />
                      {isRepeatEntryModal !== 'delete' &&
                        showAllEventOption && (
                          <FormControlLabel
                            value={'All events'}
                            control={<Radio color="primary" />}
                            label={'All events'}
                            disabled={changePrimaryDay !== '' ? true : false}
                          />
                        )}
                    </RadioGroup>
                    {changePrimaryDay !== '' && (
                      <span className={classes.noteText}>
                        You had changed the primary entry day({changePrimaryDay}
                        ) so you can't change "All Events".
                      </span>
                    )}
                  </FormControl>
                  <div className={classes.modalActions}>
                    <Button onClick={() => setRepeatEntryModal(false)}>
                      {' '}
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (isRepeatEntryModal === 'delete') {
                          setRepeatEntryModal(false);
                          _deleteEntryHandleForRepeat();
                        } else {
                          handleSubmit();
                        }
                      }}
                      color="primary"
                    >
                      Ok
                    </Button>
                  </div>
                </Modal>
              )}
            </>
          );
        }}
      </Formik>
      {isOpenModal.open && (
        <Modal isOpen={isOpenModal.open} onClose={handleCustomDateClose}>
          <CustomDateModal
            data={customValue}
            submitForm={handleCustomDateSubmit}
            selectedValue={JSON.parse(isOpenModal.value)}
            onClose={handleCustomDateClose}
            // isRepeatEntry={
            //   hasData && hasData[0] && hasData[0].isRepeatEntry
            //     ? hasData[0].isRepeatEntry
            //     : false
            // }
          />
        </Modal>
      )}
      {isOpenDeleteModal && (
        <Modal
          isOpen={isOpenDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Delete Entry Creation?
            </Typography>
            <Typography className={classes.modalSubTitle}>
              Are you sure you want to delete this new entry?
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  if (hasData && hasData.length) {
                    // _deleteEntryHandle();
                    deleteAllEntries();
                  } else {
                    setUpdatedEntry(null);
                    dispatch(
                      setFamilyOrSchoolPreserveEntryData({
                        entries: [...entryObject.entries]
                      })
                    );
                    setRightOpen(false);
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Delete'}
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenDeleteLinkedModal.open && (
        <Modal
          isOpen={isOpenDeleteLinkedModal.open}
          onClose={() => setOpenDeleteLinkedModal({ open: false, id: null })}
        >
          <div className={classes.modalBox}>
            <Typography
              className={classes.modalTitle}
              style={{ marginBottom: 18 }}
            >
              Delete Linked Entry?
            </Typography>
            <Typography className={classes.modalSubTitle}>
              Are you sure you want to delete this linked entry?
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={removeLinkedEntry}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
              >
                Delete
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteLinkedModal({ open: false, id: null });
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default withFirebase(CreateSchoolCalenderEntry);
