import {
  Button,
  CircularProgress,
  FormControl,
  TableCell,
  TableRow,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import api_icon from 'src/assets/apiIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import {
  colors,
  shortYears,
  yearsObject,
  year as DefaultYear
} from 'src/utils/constant';
import { useState } from 'react';
import { useCallback } from 'react';
import {
  addMessage,
  loaderStart,
  loaderStop
} from 'src/redux/actions/appActions';
import { withFirebase } from 'src/utils/firebase';
import { useEffect } from 'react';
import {
  setAddEditGroupModalData,
  setGroup,
  setPaidGroupModalData
} from 'src/redux/actions/groupActions';
import TableList from 'src/components/TableList';
import SchoolWrapper from './SchoolGroupWrapper';
import CreateEditGroup from './CreateEditGroup';
import PaidEditGroup from './PaidEditGroup';
import Modal from 'src/components/Modal';
import _ from 'lodash';
import {
  addSchoolFormDetailsOrUpdateSchool,
  getSchoolByID
} from 'src/services/schools';
import {
  deleteGroup,
  getGroup,
  getGroups,
  getMembersBySchoolId
} from 'src/services/groups';
import { schoolGroupStyles } from 'src/styles/schoolStyle/groupStyle/schoolGroupStyles';
import { MESSAGE_TYPES } from 'src/constants/common';



function getComaSeparateYears(years) {
  return years
    ?.filter((year) => year !== 'all')
    ?.map(
      (year) =>
        shortYears[year] ||
        (year === 'Year 0' ? 'K' : year === 'Year -1' ? 'PK' : '') ||
        year
    )
    .join(', ');
}

function CellItem({ row, column, openRightDrawer }) {
  const classes = schoolGroupStyles();
  const dispatch = useDispatch();
  const handleTableRow = async (rowId) => {
    dispatch(loaderStart());
    try {
      const result = await getGroup(rowId);
      dispatch(setGroup(result));
      openRightDrawer(result);
      dispatch(loaderStop());
    } catch (error) {
      dispatch(addMessage(error, MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };
  if (column.id === 'members') {
    return row?.members;
  }
  if (column.id === 'category') {
    return row?.category;
  }
  if (column.id === 'years') {
    return getComaSeparateYears(row?.years);
  }
  if (column.id === 'groups') {
    return (
      <span
        className={classes.hoverTag}
        onClick={() => {
          handleTableRow(row?.id);
        }}
        style={{ fontWeight: 'bold' }}
      >
        {row?.groupName}
      </span>
    );
  }
  if (column.id === 'groupType') {
    return row?.isHide === true || row?.createdByApiSync ? (
      <span className={classes.freeStatus}>HIDE</span>
      ) : (
      <span className={classes.paidStatus}>SHOW</span>
    );
  }
  if (column.id === 'apiSync' && row?.createdByApiSync) {
    return <img src={api_icon} />;
  }
  return '';
}

const columns = [
  {
    id: 'groups',
    label: 'Group Name',
    width: 350,
    align: 'left'
  },
  {
    id: 'category',
    label: 'Category',
    width: 350,
    align: 'left',
    isLink: true
  },
  {
    id: 'years',
    label: 'Years',
    width: 350,
    align: 'left',
    isLink: true
  },
  {
    id: 'members',
    label: 'Students',
    align: 'left',
    isLink: true
  },
  {
    id: 'groupType',
    label: 'Visibility',
    width: 126,
    align: 'left',
    isLink: true
  },
  {
    id: 'apiSync',
    label: '',
    width: 100,
    align: 'center',
    format: 'icon',
    iconPosition: 'center',
    icon: false
  }
];

function TableRowItem({ row, columns, openRightDrawer }) {
  const dispatch = useDispatch();
  const classes = schoolGroupStyles();

  const handleTableRow = async (rowId) => {
    dispatch(loaderStart());
    try {
      const result = await getGroup(rowId);
      dispatch(setGroup(result));
      openRightDrawer(result);
      dispatch(loaderStop());
    } catch (error) {
      dispatch(addMessage(error, MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      className={classes.tableRow}
      onClick={(e) => {
        if (e.target.tagName.toLowerCase() === 'td') {
          handleTableRow(row?.id);
        }
      }}
    >
      {columns.map((column) => {
        return (
          <TableCell
            key={column.id}
            align={column.align}
            className={classes.dataTableCell}
          >
            <CellItem
              row={row}
              column={column}
              openRightDrawer={openRightDrawer}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
}

const CreateEditGroupModalStatus = {
  ADD: 'add',
  EDIT: 'edit'
};

const SchoolGroup = ({ firebase }) => {
  const classes = schoolGroupStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const prefillSchoolData = useSelector((state) => state.school.currentSchool);
  const [searchText, setSearchText] = useState('');
  const [rowsCountPerPage, setRowsCountPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showClearFilters, setShowClearFilters] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isAddEditModelOpen, setAddEditModelOpen] = useState({
    open: false,
    status: CreateEditGroupModalStatus.ADD
  });
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = React.useState({});
  const { currentGroup, groupAddEditModalData, groupPaidModalData } =
    useSelector((state) => state.group);
  const [deleteText, setDeleteText] = React.useState('');
  const [isOpenConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const isLoading = useSelector((state) => state.app.isLoading);
  const currentSchool = useSelector((state) => state.school.currentSchool);
  const childVisiility = useSelector((state) => state?.auth?.user?.country);
  const [tableData, setTableData] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [pageNo, setPageNo] = useState(1);
  const schoolID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const searchDebouncedFunction = useCallback(
    _.debounce((debouncedValue) => {
      setDebouncedSearchText(debouncedValue);
    }, 1000),
    []
  );

  const formatGroupAPIsData = (data) => {
    return data.map((item) => {
      const category = currentSchool?.categories?.find(
        (c) => c.id === item.category
      );
      return {
        ...item,
        category: category?.name ?? '',
        members: item?.groupMembers?.length || 0
      };
    });
  };

  useEffect(() => {
    // PHASE2/CHANGES/GROUP
    dispatch(loaderStart());
    getGroups({
      schoolId: schoolID,
      searchText: debouncedSearchText,
      categoryId: selectedCategory,
      pageSize: rowsPerPage,
      pageNo: pageNo
    })
      .then((result) => {
        setTableData(formatGroupAPIsData(result.data));
        setTotalRecords(result.totalRecords);
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        dispatch(loaderStop());
      });
  }, [schoolID, debouncedSearchText, selectedCategory, rowsPerPage, pageNo]);

  // Category change function
  const groupCategoryChangeHandler = async (e) => {
    setCurrentPage((prev) => !prev);
    const categoryId = e.target.value;
    setShowClearFilters(categoryId !== '');
    setSelectedCategory(categoryId);
  };

  // Search text function
  const searchChangeHandler = (e) => {
    setCurrentPage((prev) => !prev);
    const { value } = e.target;
    setSearchText(value.toLowerCase());
    searchDebouncedFunction(value.toLowerCase());
  };

  // Clear all filters
  const filterClearHandler = async () => {
    setCurrentPage((prev) => !prev);
    setSearchText('');
    searchDebouncedFunction('');
    setSelectedCategory('');
    setShowClearFilters(false);
    setPageNo(1)
  };

  // Page change next/prev function
  const handlePageChange = async (currentPage, newPage, rowsPerPage) => {
    setPageNo(newPage + 1)
  };

  // Page size change function
  const handleRowsPerPageChange = async (rowsPerPage) => {
    setRowsPerPage(rowsPerPage)
  };

  // get parent year name
  const getParentYearName = useCallback((year) => {
    let name = '';
    Object.keys(yearsObject).forEach((parentYearKey) => {
      yearsObject[parentYearKey].forEach((yearVal) => {
        if (yearVal.value === year) {
          name = parentYearKey;
        }
      });
    });
    return name;
  }, []);

  // sort members data
  const customMembersSort = (arr) => {
    arr.sort((a, b) => {
      if (a.fillCreatedByApiSync && !b.fillCreatedByApiSync) {
        return -1;
      } else if (!a.fillCreatedByApiSync && b.fillCreatedByApiSync) {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
  };

  // get parent initials
  const getInitials = (array) => {
    const childArray =
      array && array.length && array.map((child) => child.initials) || [];
    return [...childArray].join(', ');
  };

  // get free members data
  const getMembersData = async (id) => {
    let memberData = [];

    try {
      const result = await getMembersBySchoolId(id);
      result.members.forEach(({ child, family } = {}) => {
        if (child && family.length) {
          const allGroupIds = _.compact([child.group, child.groups]);
          const parents = [];
          for (let i = 0; i < family.length; i++) {
            parents.push(family[i].parents[0]);
          }
          const childYear =
            child.year === '-1'
              ? 'PK'
              : child.year === '0'
              ? 'K'
              : `Y${child.year}`;
          const name =
            child.lastName +
            ' - ' +
            (childVisiility !== 'Australia'
              ? ' (' + childYear + ') '
              : child.firstName + ' (' + getInitials(parents) + ') ');
          const familyIds = family.map((f) => f.id);
          memberData.push({
            id: child.id,
            familyId: familyIds,
            allGroupIds: [].concat.apply([], allGroupIds) || [],
            surname: family[0].familySurname,
            name,
            year: child.year,
            selected: false,
            createdByApiSync: !!child.createdByApiSync
          });
        }
      });
      customMembersSort(memberData);
    } catch (error) {
      dispatch(addMessage(error, MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
    return memberData;
  };

  // Fetch group modal related data
  const fetchGroupModal = useCallback(
    async (status) => {
      if (groupAddEditModalData.schoolId !== prefillSchoolData.id) {
        dispatch(loaderStart());
        const categories = prefillSchoolData.categories.map((item) => {
          return { key: item?.name, id: item?.id, selected: false };
        });
        const years = prefillSchoolData.freeCalendars
          .filter((yearVal) => yearVal !== '')
          .map((year) => {
            const getCurrentYear = _.find(
              DefaultYear,
              (elem) => elem?.value === year
            );
            return {
              key: getCurrentYear?.value,
              label: getCurrentYear?.label,
              parent: getParentYearName(getCurrentYear?.value),
              selected: false
            };
          });
        const freeMembers = await getMembersData(prefillSchoolData.id);
        const allFreeMembersData = [
          { id: 'all', year: 'all', name: 'Select All', selected: false },
          ...freeMembers
        ];
        dispatch(
          setAddEditGroupModalData({
            schoolId: prefillSchoolData.id,
            groupMembers: allFreeMembersData,
            groupYears: years,
            groupCategory: categories
          })
        );
        setAddEditModelOpen({
          open: true,
          status
        });
        dispatch(loaderStop());
      } else {
        dispatch(loaderStop());
        setAddEditModelOpen({
          open: true,
          status
        });
      }
    },
    [groupAddEditModalData.schoolId, prefillSchoolData.id, getParentYearName]
  );

  // Fetch paid group modal data
  const fetchPaidGroupModal = useCallback(async () => {
    if (groupPaidModalData.schoolId !== prefillSchoolData.id) {
      dispatch(loaderStart());
      firebase
        .getFamilyBySchoolId(prefillSchoolData.id)
        .then(function (querySnapshot) {
          let AllFamilyData = [];
          querySnapshot.forEach(function (doc) {
            AllFamilyData.push(doc.data());
          });
          let filteredGroupData = [];
          let yearsArray = [];

          AllFamilyData.map((family) => {
            family.childs.map((child) => {
              if (child.school === prefillSchoolData.id) {
                yearsArray = _.uniq([...yearsArray, child.year]);
                filteredGroupData.push({
                  id: child.id,
                  name: `<p>${
                    family.familySurname +
                    ' - ' +
                    child.firstName +
                    ' (<span style="color: #bfc5d1">' +
                    getInitials(family.parents) +
                    ') </span>'
                  }</p>`,
                  year: child.year,
                  selected: false
                });
              }
              return child;
            });
            return family;
          });

          let updatedYearArray = yearsArray
            .sort((a, b) => a - b)
            .map((year) => {
              return {
                key: `Year ${year}`,
                selected: false
              };
            });
          const years = [
            {
              key: `all`,
              selected: false
            },
            ...updatedYearArray
          ];
          const members = [
            {
              id: 'all',
              year: 'all',
              name: 'Select All',
              selected: false
            },
            ..._.sortBy(filteredGroupData, [(u) => u.name.toLowerCase()])
          ];

          dispatch(
            setPaidGroupModalData({
              schoolId: prefillSchoolData.id,
              groupMembers: members,
              groupYears: years
            })
          );
          setOpen(true);
          dispatch(loaderStop());
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
          dispatch(loaderStop());
          dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
        });
    } else {
      dispatch(loaderStop());
      setOpen(true);
    }
  }, [groupPaidModalData.schoolId, prefillSchoolData.id]);

  const openRightDrawer = useCallback(
    (row) => {
      if (row.groupType !== 'Free') {
        fetchPaidGroupModal();
      } else {
        fetchGroupModal(CreateEditGroupModalStatus.EDIT);
      }
    },
    [firebase, dispatch, fetchGroupModal, fetchPaidGroupModal]
  );

  const hanldeAddGroupClick = useCallback(() => {
    fetchGroupModal(CreateEditGroupModalStatus.ADD);
  }, [fetchGroupModal]);

  const deleteUserHandle = useCallback(async () => {
    setDeleteText('');
    dispatch(loaderStart());
    setSearchText('');
    searchDebouncedFunction('');
    setSelectedCategory('');
    setShowClearFilters(false);
    if (deleteID.groupType === 'Paid') {
      const members = groupAddEditModalData.groupMembers
        .filter(
          (member) =>
            member.id !== 'all' && deleteID.groupMembers.includes(member.id)
        )
        .map((member) => ({ childId: member.id, familyId: member.familyId }));

      try {
        const result = await deleteGroup({
          groupId: deleteID.id,
          groupMembers: members
        });
        if (result) {
          dispatch(loaderStop());
          setOpenDeleteModal(false);

          // PHASE2/CHANGES/SCHOOL
          getSchoolByID(deleteID.school).then((school) => {
            if (school.groups > 0) {
              // PHASE2/CHANGES/SCHOOL

              const payload = {
                schoolName: school?.schoolName,
                country: school?.country,
                timezone: school?.timezone,
                state: school?.state,
                notificationsTiming: school?.notificationsTiming,
                freeCalendars: school?.freeCalendars,
                notes: school?.notes,
                websiteUrl: school?.websiteUrl,
                formData: {
                  schoolShortName: school?.formData?.schoolShortName,
                  schoolLogo: {
                    storage_path: school?.formData?.schoolLogo?.storage_path,
                    url: school?.formData?.schoolLogo?.url
                  },
                  schoolColor: school?.formData?.schoolColor,
                  urlPath: school?.formData?.urlPath,
                  category: school?.formData?.category,
                  firstName: school?.formData?.firstName,
                  email: school?.formData?.email,
                  isPublished: school?.formData?.isPublished,
                  schoolSupportPerson: {
                    storage_path:
                      school?.formData?.schoolSupportPerson?.storage_path,
                    url: school?.formData?.schoolSupportPerson?.url
                  }
                }
              };

              addSchoolFormDetailsOrUpdateSchool(school.id, payload)
                .then(() => {})
                .catch((error) => console.error(error));
            }
          });

          setOpenConfirmDeleteModal(true);
          setCurrentPage((prev) => !prev);
          // fetchData({});

          // PHASE2/CHANGES/GROUP
          dispatch(loaderStart());
          try {
            const result = await getGroups({
              pageSize: rowsPerPage,
              schoolId: schoolID
            });
            setTableData(formatGroupAPIsData(result.data));
            setTotalRecords(result.totalRecords);
            dispatch(loaderStop());
          } catch (error) {
            console.log(error);
            dispatch(loaderStop());
          }
        }
      } catch (error) {
        console.log('Error: ', error);
        dispatch(addMessage('Something went wrong. Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      }
    } else {
      const members = groupAddEditModalData.groupMembers
        .filter(
          (member) =>
            member.id !== 'all' && deleteID.groupMembers.includes(member.id)
        )
        .map((member) => ({ childId: member.id, familyId: member.familyId }));

      // PHASE2/CHANGES/ [IN_PROGRESS]
      try {
        const result = await deleteGroup({
          groupId: deleteID.id,
          groupMembers: members
        });
        setOpenDeleteModal(false);
        dispatch(addMessage(result?.message || 'Group deleted successfully'));
        setOpenConfirmDeleteModal(true);
        setCurrentPage((prev) => !prev);
        // fetchData({});

        // PHASE2/CHANGES/GROUP
        dispatch(loaderStart());

        try {
          const result = await getGroups({
            pageSize: rowsPerPage,
            schoolId: schoolID
          });
          setTableData(formatGroupAPIsData(result.data));
          setTotalRecords(result.totalRecords);
          dispatch(loaderStop());
        } catch (error) {
          console.log(error);
          dispatch(loaderStop());
        }
      } catch (error) {
        dispatch(addMessage(error, MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      }
    }
  }, [dispatch, firebase, deleteID]);

  const handleDeleteConfirmCloseModal = useCallback(() => {
    setOpenConfirmDeleteModal(false);
    setOpen(false);
    setDeleteText('');
  }, [setOpenConfirmDeleteModal]);

  const handleDeleteCloseModal = useCallback(() => {
    setOpenDeleteModal(false);
    setDeleteText('');
  }, [setOpenDeleteModal]);

  const reFetchGroups = async () => {
    setCurrentPage((prev) => !prev);
    // fetchData({});

    // PHASE2/CHANGES/GROUP
    dispatch(loaderStart());
    try {
      const result = await getGroups({
        pageSize: rowsPerPage,
        schoolId: schoolID
      });
      setTableData(formatGroupAPIsData(result.data));
      setTotalRecords(result.totalRecords);
      dispatch(loaderStop());
    } catch (error) {
      console.log(error);
      dispatch(loaderStop());
    }
  };

  const clearAppliedFilters = () => {
    setSearchText('');
    searchDebouncedFunction('');
    setSelectedCategory('');
    setShowClearFilters(false);
    setPageNo(1)
  };

  return (
    <SchoolWrapper>
      <TableList
        currentPage={currentPage}
        columns={columns}
        rows={tableData || []}
        totalCount={totalRecords || 0}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        setRowsCountPerPage={setRowsCountPerPage}
        searchPlaceholderText={'Search by group name'}
        selectedCategory={selectedCategory}
        showClearFilters={showClearFilters}
        searchChangeHandler={searchChangeHandler}
        groupCategoryChangeHandler={groupCategoryChangeHandler}
        filterClearHandler={filterClearHandler}
        schoolCategories={prefillSchoolData?.categories || []}
        hanldeAddGroupClick={hanldeAddGroupClick}
        tableRow={(row, index) => (
          <TableRowItem
            key={index}
            columns={columns}
            row={row}
            openRightDrawer={openRightDrawer}
          />
        )}
        searchText={searchText}
      />
      <CreateEditGroup
        isOpen={!!isAddEditModelOpen.open}
        onClose={() =>
          setAddEditModelOpen((prev) => ({ ...prev, open: false }))
        }
        clearFilters={clearAppliedFilters}
        fetchGroup={reFetchGroups}
        isEditModelOpen={
          isAddEditModelOpen.status === CreateEditGroupModalStatus.EDIT
        }
        onMenuClose={() => {
          if (isAddEditModelOpen.status === CreateEditGroupModalStatus.EDIT) {
            setOpenDeleteModal(true);
            setDeleteID(currentGroup);
          } else {
            setAddEditModelOpen((prev) => ({ ...prev, open: false }));
          }
        }}
      />
      <PaidEditGroup
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        clearFilters={clearAppliedFilters}
        fetchGroup={reFetchGroups}
        onMenuClose={() => {
          setOpenDeleteModal(true);
          setDeleteID(currentGroup);
        }}
      />
      {isOpenDeleteModal && (
        <Modal isOpen={isOpenDeleteModal} onClose={handleDeleteCloseModal}>
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Delete Group?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                <div className={classes.boldFont}>
                  Warning: This action can not be reversed.
                </div>
                The following data will be permanently removed from the
                database.
              </Typography>
            </div>
            <div className={classes.modalContent}>
              <Typography className={classes.modalSubTitle}>
                - Group profile (admin)
                <br />- Existing entries for individual members in this group
                will not be deleted.
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <div
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, marginBottom: '3.3%' }}
              >
                Type &quot;DELETE GROUP&quot; to confirm
              </div>
              <FormControl component="fieldset" style={{ width: '85%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputControlBox}
                  style={{ marginTop: 0 }}
                  value={deleteText}
                  onChange={(e) => setDeleteText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={deleteUserHandle}
                color="primary"
                variant="contained"
                size="large"
                disabled={deleteText !== 'DELETE GROUP'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Delete'}
              </Button>
              <Button
                onClick={handleDeleteCloseModal}
                variant="contained"
                size="large"
                className={classes.greyButton}
                style={{ boxShadow: 'none' }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenConfirmDeleteModal && (
        <Modal isOpen={isOpenConfirmDeleteModal} onClose={() => {}}>
          <div className={classes.modalBox}>
            <Typography
              className={classes.modalTitle}
              style={{ marginBottom: 18 }}
            >
              Group Deleted
            </Typography>
            <Typography
              className={classes.modalSubTitle}
              style={{ marginBottom: 26 }}
            >
              {currentGroup.groupName} has now been deleted.
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  handleDeleteConfirmCloseModal();
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
              >
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </SchoolWrapper>
  );
};

export default withFirebase(SchoolGroup);
