import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";

export const useCalendarStyle = makeStyles((theme) => ({
  chipLabel: {
    height: 18,
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: colors.white,
    marginTop: -5
  },
  chipRoot: {
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    height: 20
  },
  root: {
    flexGrow: 1
  },
  paper: {
    // height: 140,
    padding: theme.spacing(1),
    display: 'flex',
    // marginBottom: '16px',
    borderBottom: '1px solid #f4f5f7',
    '&.isdeleted': {
      backgroundColor: 'rgb(255 255 255 / 0.6)'
    },
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    },
    '&:active': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.75)'
    },
    position: 'relative'
  },
  borderClass: {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      borderTop: '2px solid #e0e0e0',
      width: `calc(100% - 14px)`
    }
  },
  calendarEntryWrapper: {
    overflowY: 'auto',
    height: `calc(100vh - 379px)`
  },
  box: {
    padding: '8px'
  },
  iconButton: {
    padding: 0
  },
  image: {
    alignSelf: 'flex-end',
    display: 'flex'
  },
  tabNavigations: {
    padding: '10px 20px 0',
    marginBottom: 15
  },
  navigationButton: {
    width: 120,
    textTransform: 'none'
  },
  familyBtn:{
    marginRight: 0,
    marginLeft: 'auto'
  },
  actionButton: {
    margin: '15px 0 20px',
    textTransform: 'none'
  },
  familyBtn: {
    marginRight: 0,
    marginLeft: 'auto'
  },
  activeTab: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0
  },
  bredthcrumbTitle: {
    color: '#536e7b',
    fontSize: 16,
    marginRight: 24,
    display: 'inline-block',
    cursor: 'pointer'
  },
  dayWithDotContainer: {
    position: 'relative'
  },
  dayWithWeekColor: {
    position: 'relative',
    '& button': {
      color: `${colors.tertiaryRed} !important`
    }
  },
  dayWithDot: {
    height: 5,
    width: 5,
    borderRadius: 4,
    top: '80%',
    margin: '-7px 1px 1px 1px',
    zIndex: 1
  },
  calendarCard: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  headerDot: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginLeft: 5
  },
  dot: {
    height: 15,
    width: 15,
    borderRadius: 10,
    marginLeft: 5
  },
  dotContainer: { display: 'flex', justifyContent: 'center' },
  alignFlexCenter: {
    alignItems: 'center',
    display: 'flex'
  },
  listContainer: {
    '& .ReactVirtualized__List': {
      width: '100% !important',
      overflowY: 'auto !important'
      // height: `calc(100vh - 380px) !important`
    },
    '& .ReactVirtualized__List .ReactVirtualized__Grid__innerScrollContainer': {
      maxWidth: 'unset !important'
    },
    '& .ReactVirtualized__List .ReactVirtualized__Grid__innerScrollContainer:focus':
      {
        outline: 'unset !important'
      }
  },
  loadingEntries: {
    textAlign: 'center',
    marginTop: '100px'
  },
  loadingEntriesDots: {
    textAlign: 'center',
    marginTop: '100px',
    color: 'transparent'
  },
  monthName: {
    fontSize: 24,
    //marginBottom: 10,
    marginTop: 20
  },
  monthNameTop: {
    fontSize: 24,
    marginBottom: 10,
    marginTop: 0
  },
  withButton: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0,
    paddingTop: 0
  },
  withoutButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: 15,
    paddingTop: 15
  },
  labelText: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.primaryDark
  },
  fromDateText: {
    margin: 0,
    fontSize: 18,
    fontWeight: 500,
    color: colors.primaryDarkGrey
  },
  toDateText: {
    margin: 0,
    fontSize: 18,
    color: colors.primaryDarkGrey
  },
  repeatIconClass: {
    opacity: 0.5,
    width: 22.3,
    height: 24,
    marginRight: 10
  },
  checkboxClass: { padding: 0, height: 17, width: 17 },
  mainTitleContainer: {
    fontSize: 18,
    color: colors.primaryDark
  },
  titleTextContainer: {
    margin: '0 0 10px 0',
    width: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  todayButton: {
    margin: '0 0 20px 20px',
    backgroundColor: colors.primary,
    textTransform: 'none',
    color: colors.white,
    '&:hover': {
      backgroundColor: '#618910'
    }
  },
  textTruncate: {
    margin: 0,
    fontSize: 16,
    letterSpacing: 0.1,
    color: 'rgba(75, 91, 105, 0.5)',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }
}));