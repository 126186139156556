import React, { useState, Fragment, Component, useEffect } from 'react';
import {
  Switch,
  makeStyles,
  Typography,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  IconButton,
  Backdrop,
  CircularProgress,
  Chip,
  ThemeProvider
} from '@material-ui/core';
import clsx from 'clsx';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import { EditorState } from 'draft-js';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import Modal from 'src/components/Modal';
import {
  colors,
  labels,
  copyFileUrl,
  taskLabels,
  muiTimePickerTheme
} from 'src/utils/constant';
import { FamiliesIcon, CornerDownRight } from 'src/assets/Icons';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { MapIcon } from '../../assets/Icons';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Editor } from 'react-draft-wysiwyg';
import CustomDateModal from './components/CustomeDateModal';
import { useSelector, useDispatch } from 'react-redux';
import { withFirebase } from 'src/utils/firebase';
import {
  addMessage,
  loaderStop,
  loaderStart,
  setPreserveEntryData
} from 'src/redux/actions/appActions';
import { generateFileHash, toGetContentFromHTML, toGetHTMLContent } from 'src/utils/functions';
import RightDrawer from 'src/components/RightDrawer';
import AttachGroupFilesModal from './components/AttachGroupFilesModal';
import { EditPencil, Calendar, LocationIcon } from 'src/assets';
import EditorComponent from 'src/components/EditorComponent';
import { MESSAGE_TYPES } from 'src/constants/common';

const useStyles = makeStyles((theme) => ({
  updatedLabel: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.71,
    letterSpacing: 0.09
  },
  updatedDesc: {
    fontSize: 16,
    height: 19,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  chipLabel: {
    height: 18,
    fontfamily: 'Roboto',
    fontweight: 500,
    fontstretch: 'normal',
    fontstyle: 'normal',
    lineheight: 'normal',
    letterspacing: 'normal',
    color: colors.white,
    marginTop: -5
  },
  chipRoot: {
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    height: 20,
    position: 'absolute',
    top: -8,
    left: 10,
    opacity: 1,
    zIndex: 1
  },
  drawerLeftContent: {
    width: `calc(70%)`,
    height: `calc(100vh - 95px)`,
    opacity: 1,
    backgroundColor: colors.white,
    float: 'left',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  drawerRightContent: {
    width: '30%',
    height: `calc(100vh - 95px)`,
    backgroundColor: colors.tertiary,
    float: 'right'
  },
  siderEdit: {
    height: 40,
    marginTop: 19
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 20
  },
  headerTitleContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center'
  },
  publishButton: { marginLeft: 10 },
  familyIconStyle: { height: '40px', width: '40px' },
  titleText: { marginLeft: 10, fontWeight: '600', fontSize: 24 },
  titleAccordionText: {
    marginLeft: 10,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    opacity: 1
  },
  whiteText: { color: colors.white },
  darkPrimaryText: {
    color: colors.primaryDark,
    fontSize: 23,
    fontWeight: '500',
    lineHeight: 1.74
  },
  initialText: {
    color: colors.primaryLightGrey,
    fontSize: 13,
    marginTop: 5,
    marginLeft: 5
  },
  leftContentMargin: { marginTop: 10 },
  inputControlBox: {
    backgroundColor: colors.white,
    marginTop: 24
  },
  formLabelControl: {
    width: 250,
    backgroundColor: colors.white,
    marginTop: 24,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '7.5px 30px 7.5px 14px',
      marginTop: 7
    }
  },
  formLabelControlTime: {
    width: 45,
    backgroundColor: colors.white,
    marginTop: 16,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 7.5,
      paddingBottom: 7.5,
      paddingLeft: 4,
      paddingRight: 4,
      marginTop: 0,
      textAlign: 'center'
    }
  },
  formDayLabelControl: {
    // width: '30%',
    // marginTop:2,
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 9px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    },
    '& .MuiOutlinedInput-input': {
      padding: '2.5px 14px',
      margin: '4px 0'
    }
  },
  formLabelControlBordered: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: 3,
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10
    }
  },
  formDateControlFromBordered: {
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: 3,
    padding: 0,
    '& .MuiInput-underline:before': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'unset !important'
    },
    '& .MuiInput-input': {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 10
    }
  },
  editorClassName: {
    padding: 10,
    backgroundColor: colors.white
  },
  wrapperClassName: {
    width: 570,
    borderTop: `1px solid ${colors.primaryLightGrey}`,
    borderLeft: `1px solid ${colors.primaryLightGrey}`,
    borderRight: `1px solid ${colors.primaryLightGrey}`,
    borderTopRadius: '5px',
    borderLeftRadius: '5px',
    borderRightRadius: '5px',
    marginTop: '20px'
  },
  wrapperClassNameWithBottomBorder: {
    width: 570,
    border: `1px solid ${colors.primaryLightGrey}`,
    borderRadius: '5px',
    marginTop: '20px'
  },
  wrapperSecondEditorClassName: {
    width: 570,
    // borderTop: `1px solid ${colors.primaryLightGrey}`,
    borderLeft: `1px solid ${colors.primaryLightGrey}`,
    borderRight: `1px solid ${colors.primaryLightGrey}`,
    borderBottom: `1px solid ${colors.primaryLightGrey}`,
    borderTopRadius: '5px',
    borderLeftRadius: '5px',
    borderRightRadius: '5px'
    // marginTop: '20px'
  },
  toolbarClassName: {
    border: 'none',
    marginBottom: 0,
    '& .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-target-option': {
      display: 'none'
    }
  },
  linkedButton: {
    height: 40,
    width: 191,
    borderRadius: 3,
    backgroundColor: colors.secondaryGreyLight,
    marginLeft: 15,
    marginBottom: 15,
    color: colors.primaryDarkGrey,
    fontSize: 16,
    boxShadow: 'none'
  },
  includeText: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey
  },
  iconTrashStyle: {
    fontSize: 22,
    verticalAlign: 'middle',
    color: colors.primaryLightGrey
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  modalBox: {
    width: 480
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  modalActions: {
    marginTop: '10%'
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  actionButton: {
    float: 'right',
    marginRight: '1%'
  },
  chipClass: {
    position: 'relative',
    top: 12,
    zIndex: 1,
    left: 30
  },
  groupRadioClass: {
    flexDirection: 'row',
    '& .MuiFormControlLabel-root .MuiIconButton-root': {
      padding: '9px 5px'
    }
  },
  radio: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  checkbox: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  switchBase: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    },
    '&$checked + $track': {
      backgroundColor: colors.primary
    }
  },
  checked: {
    fontWeight: 900
  },
  menuItemClass: {
    padding: '2px 16px'
  },
  descriptionContainer: {
    width: 572,
    borderLeft: `1px solid ${colors.primaryLightGrey}`,
    borderRight: `1px solid ${colors.primaryLightGrey}`
  },
  descriptionMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    backgroundColor: colors.white
  },
  descriptionImageContainer: {
    height: 18,
    width: 18,
    opacity: 0.4
  },
  descriptionTextContainer: {
    margin: 0,
    fontSize: 14,
    color: colors.primaryLightGrey,
    padding: 10
  }
}));

const entryObject = {
  entries: [
    {
      id: generateFileHash(20),
      isLowPriority: false,
      isPrimary: true,
      linkedArray: [],
      label: '',
      title: '',
      location: '',
      description: '',
      expand: true,
      for: '',
      primary_description: {
        description: '',
        from: {
          date: moment().format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        }
      },
      assign: [],
      assignDropDownData: [],
      assignToChild: false,
      from: {
        date: moment().format('MMM DD,YYYY'),
        time: moment().format('h:mma')
      },
      to: {
        date: moment().format('MMM DD,YYYY'),
        time: moment().format('h:mma')
      },
      allDay: true,
      actions: {
        value: '',
        repeat: [],
        notification: [
          {
            days: -1,
            date: moment().subtract(1, 'days').format('MMM DD,YYYY'),
            time: '7:30pm'
          },
          { days: '', date: moment().format('MMM DD,YYYY'), time: '7:30pm' }
        ],
        deadline: [],
        reminder: [],
        isCancelled: false,
        isPostponed: false,
        isChanged: false
      },
      newFromTimeFormate: { hh: '', mm: '', ap: '' },
      newToTimeFormate: { hh: '', mm: '', ap: '' }
    }
  ]
};

function CreateSingleFamilyEntry({
  firebase,
  onClose,
  isOpen,
  type
  // hasPreserveEntryData,
  // setPreserveEntryData
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasPreserveEntryData = useSelector(
    (state) => state.app.hasPreserveEntryData
  );
  const currentUser = useSelector((state) => state.auth.user);
  const [initialEntry, setInitialEntry] = useState({
    entries: [
      {
        ...entryObject.entries[0],
        primaryID: '',
        actionParent: { parentID: '', type: '' },
        groupType: type,
        agentName: currentUser.firstName,
        from: {
          date: moment().format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        },
        to: {
          date: moment().format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        }
      }
    ]
  });
  const [isOpenModal, setOpenModal] = useState({ open: false, value: [] });
  // const [childOptions, setChildOptions] = useState(null);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenDeleteLinkedModal, setOpenDeleteLinkedModal] = useState({
    open: false,
    id: null
  });
  const [defaultNote, setDefaultNote] = useState(EditorState.createEmpty());
  const [customValue, setCustomValue] = useState({});
  const [families, setFamilies] = React.useState([]);
  const [childOptions, setChildOptions] = useState(null);
  const [attachFamilyId, setAttachFamilyId] = React.useState([]);
  const [updatedEntry, setUpdatedEntry] = useState({});

  const isLoading = useSelector((state) => state.app.isLoading);

  useEffect(
    () => {
      // if (!isOpen) {
      dispatch(setPreserveEntryData(updatedEntry));
      // }
    },
    // eslint-disable-next-line
    [isOpen, updatedEntry]
  );

  useEffect(() => {
    firebase
      .getFamilies()
      .then((querySnapshot) => {
        let FamiliesData = [];
        querySnapshot.forEach(function (doc) {
          if (doc.data().childs.length) {
            FamiliesData.push({
              id: doc.id,
              name: `${doc.data().familySurname} (${getInitials(
                doc.data().parents
              )})`,
              childs: doc.data().childs,
              parents: doc.data().parents
            });
          }
        });
        setFamilies(FamiliesData);
        let updateInitialValueEntry = initialEntry.entries;
        if (hasPreserveEntryData) {
          let primaryEntryID = '';
          updateInitialValueEntry = hasPreserveEntryData.entries.map(
            (entry, index) => {
              entry.id = generateFileHash(20);
              if (entry.isPrimary) {
                primaryEntryID = entry.id;
              } else {
                entry.primaryID = primaryEntryID;
              }

              FamiliesData.map((family) => {
                if (
                  hasPreserveEntryData.entries[0].family &&
                  family.id === hasPreserveEntryData.entries[0].family
                ) {
                  const childArray = family.childs.map((child) => {
                    return {
                      id: child.id,
                      value: child.firstName,
                      type: 'child'
                    };
                  });
                  const parentArray =
                    family.parents &&
                    family.parents.length &&
                    family.parents.map((parent) => {
                      return {
                        id: parent.id,
                        value: parent.firstName,
                        type: 'parent'
                      };
                    });
                  entry.assignDropDownData =
                    parentArray && parentArray.length
                      ? [...parentArray, ...childArray]
                      : [...childArray];
                  setAttachFamilyId([
                    {
                      id: hasPreserveEntryData.entries[0].family,
                      name: family.name
                    }
                  ]);
                  return family;
                } else {
                  return family;
                }
              });

              if (index === 0) {
                if (entry.isPrimary) {
                  setDefaultNote(
                    entry.description
                      ? toGetContentFromHTML(entry.description)
                      : EditorState.createEmpty()
                  );
                }
                return {
                  ...entry,
                  groupType: type,
                  for: '',
                  assign: []
                };
              } else {
                return { ...entry, for: '', assign: [] };
              }
            }
          );
          setInitialEntry({
            entries: updateInitialValueEntry
          });
        }
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
      });
    // eslint-disable-next-line
  }, [firebase, setFamilies, dispatch]);

  const getInitials = (array) => {
    const parentArray =
      array && array.length && array.map((parent) => parent.initials);
    return array && array.length ? [...parentArray].join(', ') : '';
  };

  const addLinkedEntry = (values) => {
    const newLinkedId = generateFileHash(20);
    const updateInitialValue = values.entries.map((data, index) => {
      if (data.isPrimary) {
        return {
          ...data,
          expand: false,
          groupType: values.entries[0].groupType,
          linkedArray: data.linkedArray
            ? [...data.linkedArray, newLinkedId]
            : [newLinkedId]
        };
      } else {
        delete data.linkedArray;
        return {
          ...data,
          expand: false,
          isPrimary: false,
          groupType: values.entries[0].groupType
        };
      }
    });
    delete entryObject.entries[0].linkedArray;
    let childArray = [];
    let parentArray = [];
    families.map((family, index) => {
      if (values.entries[0].family === family.id) {
        childArray = family.childs.map((child) => {
          return { id: child.id, value: child.firstName, type: 'child' };
        });
        parentArray =
          family.parents &&
          family.parents.length &&
          family.parents.map((parent) => {
            return {
              id: parent.id,
              value: parent.firstName,
              type: 'parent'
            };
          });
      }
    });

    setInitialEntry({
      entries: [
        ...updateInitialValue,
        {
          ...entryObject.entries[0],
          id: newLinkedId,
          isPrimary: false,
          agentName: currentUser.firstName,
          for: values.entries[0].for,
          assign: values.entries[0].assign,
          assignDropDownData: values.entries[0].assignDropDownData
        }
      ]
    });
  };

  const removeLinkedEntry = () => {
    if (isOpenDeleteLinkedModal.id) {
      const values = [...initialEntry.entries];

      const removeLinkedEntryId = _.findIndex(
        values[0].linkedArray,
        (item) => item === values[isOpenDeleteLinkedModal.id].id
      );
      if (removeLinkedEntryId > -1) {
        values[0].linkedArray.splice(removeLinkedEntryId, 1);
      }

      values.splice(isOpenDeleteLinkedModal.id, 1);
      setInitialEntry({ entries: [...values] });
    }
    setOpenDeleteLinkedModal({ open: false, id: null });
  };

  const handleExpandChange = (id, values) => {
    let updateInitialValue = [];
    updateInitialValue = values.entries.map((data, index) => {
      if (index === id) {
        return { ...data, expand: data.expand ? false : true };
      } else {
        return { ...data, expand: false };
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const validationSchema = Yup.lazy((values) => {
    return Yup.object().shape({
      entries: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          label: Yup.string()
            .trim()
            .default('none')
            .required('Label is required'),
          title: Yup.string().trim().required('Title is required'),
          location: Yup.string().trim(),
          description: Yup.string().trim(),
          family: Yup.string()
            .trim()
            .test('family test', 'Family is required', function (val) {
              return (
                values.entries.length &&
                values.entries[0] &&
                values.entries[0].family
              );
            }),
          for: Yup.string().trim().required('For is required'),
          // for:  Yup.string().trim().test(
          //   'for test',
          //   'For is required',
          //   function (val) {
          //     return values.entries.length && values.entries[0] && values.entries[0].for
          //   }
          // ),
          assign: Yup.array(),
          from: Yup.object().required('From is required'),
          to: Yup.object()
            .required('To is required')
            .test(
              'date test',
              'From date needs to be before the To date',
              function (val) {
                const { from, to } = this.parent;
                return moment(to.date, 'MMM DD,YYYY').isSameOrAfter(
                  moment(from.date, 'MMM DD,YYYY')
                );
              }
            ),
          allDay: Yup.boolean(),
          checkDescription: Yup.boolean(),
          actions: Yup.object().shape({
            repeat: Yup.array().of(
              Yup.object().shape({ type: Yup.string().trim() })
            ),
            notification: Yup.array().of(
              Yup.object().shape({
                days: Yup.string().trim(),
                time: Yup.string().trim()
              })
            ),
            deadline: Yup.array(),
            reminder: Yup.array()
          }),
          newFromTimeFormate: Yup.object().when('allDay', {
            is: false,
            then: Yup.object({
              hh: Yup.string().trim().required('time is required'),
              mm: Yup.string().trim().required('time is required'),
              ap: Yup.string().trim().required('time is required')
            })
          }),
          // newFromTimeFormate: Yup.object().shape({
          //   hh: Yup.string().trim().required('time is required'),
          //   mm: Yup.string().trim().required('time is required'),
          //   ap: Yup.string().trim().required('time is required'),
          // }),
          newToTimeFormate: Yup.object().when('allDay', {
            is: false,
            then: Yup.object({
              hh: Yup.string().trim().required('time is required'),
              mm: Yup.string().trim().required('time is required'),
              ap: Yup.string().trim().required('time is required')
            })
          })
        })
      )
    });
  });

  const handleActionChange = (e, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      let updatedData;
      if (index === selectedIndex) {
        if (e.target.value === 'deadline' || e.target.value === 'reminder') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              [e.target.value]: [
                ...data.actions[e.target.value],
                moment().format('MMM DD,YYYY')
              ],
              [e.target.value + 'Notification']:
                e.target.value === 'reminder'
                  ? [
                      ...(data.actions.reminderNotification || []),
                      {
                        days: '',
                        date: moment().format('MMM DD,YYYY'),
                        time: '7:30pm'
                      }
                    ]
                  : [
                      ...(data.actions.deadlineNotification || []),
                      {
                        days: '',
                        date: moment().format('MMM DD,YYYY'),
                        time: '7:30pm'
                      }
                    ]
            }
          };
        } else if (e.target.value === 'notification') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              notification: [
                ...data.actions.notification,
                {
                  days: '',
                  date: moment(data.from.date, 'MMM DD,YYYY').format(
                    'MMM DD,YYYY'
                  ),
                  time: moment().format('h:mma')
                }
              ]
            }
          };
        } else if (e.target.value === 'repeat') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              repeat: [...data.actions.repeat, { type: 'Does not repeat' }] //[...data.actions.repeat, { type: '', values: {} }]
            }
          };
        } else if (e.target.value === 'cancelled') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isChanged: false,
              isPostponed: false,
              isCancelled: true
            }
          };
        } else if (e.target.value === 'postponed') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isChanged: false,
              isCancelled: false,
              isPostponed: true
            }
          };
        } else if (e.target.value === 'changed') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isCancelled: false,
              isPostponed: false,
              isChanged: true
            }
          };
        }
      } else {
        updatedData = data;
      }
      return updatedData;
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const onEditorChange = (e, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (data.isPrimary) {
          setDefaultNote(e);
        }
        return { ...data, description: e };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleChangeCheck = (name, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (name === 'checkDescription') {
          setDefaultNote(toGetContentFromHTML(values.entries[0].description));
          return {
            ...data,
            // description: values.entries[0].description,
            [name]: data[name] ? false : true
          };
        } else {
          return { ...data, [name]: data[name] ? false : true };
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleNotificationDateChange = (
    e,
    selectedIndex,
    innerIndex,
    values,
    value,
    type = ''
  ) => {
    const formateValue = 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (type === 'reminderNotification' && index === selectedIndex) {
        const notificationArray = data.actions.reminderNotification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, reminderNotification: notificationArray }
        };
      } else if (type === 'deadlineNotification' && index === selectedIndex) {
        const notificationArray = data.actions.deadlineNotification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, deadlineNotification: notificationArray }
        };
      } else if (index === selectedIndex) {
        const notificationArray = data.actions.notification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, notification: notificationArray }
        };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleDateChange = (e, selectedIndex, values, key, value) => {
    const formateValue = value === 'time' ? 'h:mma' : 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (key === 'from' && value === 'date') {
          const endDate = moment(data.to.date, 'MMM DD,YYYY').isSameOrAfter(
            moment(e, 'MMM DD,YYYY')
          )
            ? data.to.date
            : moment(e).format(formateValue);
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) },
            to: { ...data['to'], [value]: endDate }
          };
        } else if (key === 'from' && value === 'time') {
          const endTime = moment(e).add(1, 'hour').format(formateValue);
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) },
            to: { ...data['to'], [value]: endTime }
          };
        } else {
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) }
          };
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleRepeatChange = (e, selectedIndex, innerIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        const repeatArray = data.actions.repeat.map(
          (repeatData, repeatIndex) => {
            if (innerIndex === repeatIndex) {
              return { type: e.target.value };
            } else {
              return repeatData;
            }
          }
        );
        return { ...data, actions: { ...data.actions, repeat: repeatArray } };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
    if (e.target.value === 'Custom') {
      const data = {
        selectedIndex,
        innerIndex,
        values: { entries: [...updateInitialValue] }
      };
      setCustomValue(data);
      setOpenModal({
        open: true,
        value: JSON.stringify(
          data.values.entries[selectedIndex].actions.repeat[innerIndex]
        )
      });
    }
  };

  const handleDateActionChange = (
    e,
    selectedIndex,
    innerIndex,
    values,
    key,
    value
  ) => {
    const formateValue = value === 'time' ? 'h:mma' : 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (key === 'notification') {
          const notificationArray = data.actions.notification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: { ...data.actions, notification: notificationArray }
          };
        } else if (key === 'reminderNotification') {
          const notificationArray = data.actions.reminderNotification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              reminderNotification: notificationArray
            }
          };
        } else if (key === 'deadlineNotification') {
          const notificationArray = data.actions.deadlineNotification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              deadlineNotification: notificationArray
            }
          };
        } else if (key === 'reminder') {
          const remindNotifyUpdate = data.actions.reminderNotification
            ? [...data.actions.reminderNotification]
            : [];
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                if (
                  remindNotifyUpdate.length &&
                  remindNotifyUpdate[notificationIndex]
                ) {
                  const notificationDate = moment(e, formateValue);
                  const fromDate = moment(data.from.date, formateValue);
                  let dayValue = '';
                  if (moment(notificationDate).isSame(fromDate)) {
                    dayValue = '';
                  } else {
                    dayValue = notificationDate.diff(fromDate, 'days');
                  }
                  remindNotifyUpdate[notificationIndex] = {
                    ...remindNotifyUpdate[notificationIndex],
                    days: dayValue,
                    date: moment(e).format(formateValue)
                  };
                }
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              [key]: actionArray,
              reminderNotification: remindNotifyUpdate
            }
          };
        } else if (key === 'deadline') {
          const deadlineNotifyUpdate = data.actions.deadlineNotification
            ? [...data.actions.deadlineNotification]
            : [];
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                if (
                  deadlineNotifyUpdate.length &&
                  deadlineNotifyUpdate[notificationIndex]
                ) {
                  const notificationDate = moment(e, formateValue);
                  const fromDate = moment(data.from.date, formateValue);
                  let dayValue = '';
                  if (moment(notificationDate).isSame(fromDate)) {
                    dayValue = '';
                  } else {
                    dayValue = notificationDate.diff(fromDate, 'days');
                  }
                  deadlineNotifyUpdate[notificationIndex] = {
                    ...deadlineNotifyUpdate[notificationIndex],
                    days: dayValue,
                    date: moment(e).format(formateValue)
                  };
                }
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              [key]: actionArray,
              deadlineNotification: deadlineNotifyUpdate
            }
          };
        } else if (key) {
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return { ...data, actions: { ...data.actions, [key]: actionArray } };
        } else {
          return data;
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleDateActionRemove = (selectedIndex, innerIndex, values, key) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (
        key === 'isCancelled' ||
        key === 'isPostponed' ||
        key === 'isChanged'
      ) {
        if (index === selectedIndex) {
          const values = data.actions;
          values[key] = false;
          return { ...data, values };
        } else {
          return data;
        }
      } else if (key === 'reminderNotification') {
        if (index === selectedIndex) {
          const values = data.actions.reminderNotification.filter(
            (date, index) => index !== innerIndex
          );
          return {
            ...data,
            actions: { ...data.actions, reminderNotification: values }
          };
        } else {
          return data;
        }
        //reminderNotification
      } else if (key === 'deadlineNotification') {
        if (index === selectedIndex) {
          const values = data.actions.deadlineNotification.filter(
            (date, index) => index !== innerIndex
          );
          return {
            ...data,
            actions: { ...data.actions, deadlineNotification: values }
          };
        } else {
          return data;
        }
        //deadlineNotification
      } else {
        if (index === selectedIndex) {
          const values = data.actions;
          values[key].splice(innerIndex, 1);
          return { ...data, values };
        } else {
          return data;
        }
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleCustomDateSubmit = (dateModalData) => {
    const {
      data: { selectedIndex, innerIndex }
    } = dateModalData;
    const updateInitialValue = dateModalData.data.values.entries.map(
      (data, index) => {
        if (index === selectedIndex) {
          const repeatArray = data.actions.repeat.map(
            (repeatData, repeatIndex) => {
              if (innerIndex === repeatIndex) {
                return { ...repeatData, values: dateModalData.values };
              } else {
                return repeatData;
              }
            }
          );
          return { ...data, actions: { ...data.actions, repeat: repeatArray } };
        } else {
          return data;
        }
      }
    );
    setInitialEntry({ entries: [...updateInitialValue] });
    setOpenModal({ open: false, value: [] });
    setCustomValue({});
  };

  const handleCustomDateClose = () => {
    const modalValue = JSON.parse(isOpenModal.value);
    const { selectedIndex, innerIndex } = customValue;
    const updateInitialValue = initialEntry.entries.map((data, index) => {
      if (index === selectedIndex) {
        const repeatArray = data.actions.repeat.map(
          (repeatData, repeatIndex) => {
            if (innerIndex === repeatIndex) {
              const fromDate = moment(data.from.date, 'MMM DD,YYYY').format();
              const customDateObject = {
                repeatDay: 1,
                repeatType: 'Day',
                repeatOn: [moment(fromDate).format('ddd')],
                repeatMonth: {
                  type: 'day',
                  value: moment(fromDate).format('MMM DD,YYYY')
                },
                endsType: 'Never',
                endsDate: moment(fromDate).format('MMM DD,YYYY'),
                endsAfter: 1
              };
              return {
                ...repeatData,
                values: modalValue.values ? modalValue.values : customDateObject
              };
            } else {
              return repeatData;
            }
          }
        );
        return { ...data, actions: { ...data.actions, repeat: repeatArray } };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
    setOpenModal({ open: false, value: [] });
    setCustomValue({});
  };

  const familyChangeHandle = (e, selectedIndex, values) => {
    const updateInitialValueEntry = hasPreserveEntryData.entries.map(
      (entry, index) => {
        const familyId = e.target.value;
        families.map((family) => {
          if (family.id === familyId) {
            const childArray = family.childs.map((child) => {
              return { id: child.id, value: child.firstName, type: 'child' };
            });
            const parentArray =
              family.parents &&
              family.parents.length &&
              family.parents.map((parent) => {
                return {
                  id: parent.id,
                  value: parent.firstName,
                  type: 'parent'
                };
              });
            entry.family = e.target.value;
            entry.for = '';
            entry.assign = [];
            entry.assignDropDownData =
              parentArray && parentArray.length
                ? [...parentArray, ...childArray]
                : [...childArray];
            setAttachFamilyId([{ id: familyId, name: family.name }]);
            return family;
          } else {
            return family;
          }
        });
        return entry;
      }
    );
    setInitialEntry({
      entries: updateInitialValueEntry
    });
  };

  const submitNewFamilyForm = (formData) => {
    //New time format
    formData.forEach((entry, index) => {
      if (entry.allDay) {
        formData[index].from = { ...formData[index].from, time: '12:30am' };
        formData[index].to = { ...formData[index].to, time: '12:30am' };
      } else {
        if (formData[index].newFromTimeFormate) {
          formData[index].newFromTimeFormate = {
            hh: formData[index].newFromTimeFormate.hh,
            mm:
              formData[index].newFromTimeFormate.mm.toString().length === 1
                ? '0' + formData[index].newFromTimeFormate.mm
                : formData[index].newFromTimeFormate.mm.toString(),
            ap:
              formData[index].newFromTimeFormate.ap.toLowerCase() === 'a' ||
              formData[index].newFromTimeFormate.ap.toLowerCase() === 'p'
                ? formData[index].newFromTimeFormate.ap.toLowerCase()
                : 'a'
          };
          formData[index].newToTimeFormate = {
            hh: formData[index].newToTimeFormate.hh,
            mm:
              formData[index].newToTimeFormate.mm.toString().length === 1
                ? '0' + formData[index].newToTimeFormate.mm
                : formData[index].newToTimeFormate.mm.toString(),
            ap:
              formData[index].newToTimeFormate.ap.toLowerCase() === 'a' ||
              formData[index].newToTimeFormate.ap.toLowerCase() === 'p'
                ? formData[index].newToTimeFormate.ap.toLowerCase()
                : 'p'
          };

          formData[index].from = {
            date: formData[index].from.date,
            time:
              formData[index].newFromTimeFormate.hh +
              ':' +
              formData[index].newFromTimeFormate.mm +
              formData[index].newFromTimeFormate.ap +
              'm'
          };
          formData[index].to = {
            date: formData[index].to.date,
            time:
              formData[index].newToTimeFormate.hh +
              ':' +
              formData[index].newToTimeFormate.mm +
              formData[index].newToTimeFormate.ap +
              'm'
          };
        }
      }
    });
    //New time format end

    const actionDataAppend = [];
    const primary = formData.find((o) => o.isPrimary === true);
    // const groupEntryID = generateFileHash(20);
    const bulkID = generateFileHash(20);
    const updateFormData = formData.map((data, index) => {
      data.bulkID = bulkID;
      if (data.label === '') {
        //data.label = 'none';
      }
      if (index === 0) {
        // data.groupEntryID = groupEntryID;
      } else {
        // data.groupEntryID = groupEntryID;
        data.family = formData[0].family;
        data.for = formData[index].for;
        data.assign = formData[index].assign;
      }
      if (data.isPrimary === false) {
        data.isLinked = true;
      } else {
        data.isLinked = false;
      }
      if (data.actions.notification.length) {
        //Remain
      }
      if (data.actions.reminder.length) {
        data.actions.reminder.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Reminder',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            primaryID: data.isPrimary ? data.id : data.primaryID,
            actions: {
              ...data.actions,
              notification:
                data.actions.reminderNotification &&
                data.actions.reminderNotification[index]
                  ? [data.actions.reminderNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.actions.deadline.length) {
        data.actions.deadline.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Deadline',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            primaryID: data.isPrimary ? data.id : data.primaryID,
            actions: {
              ...data.actions,
              notification:
                data.actions.deadlineNotification &&
                data.actions.deadlineNotification[index]
                  ? [data.actions.deadlineNotification[index]]
                  : []
            }
          });
        });
      }

      delete data.school;
      return data;
    });

    const final = [...updateFormData, ...actionDataAppend];

    const finalData = {
      familyId: primary.family,
      entries: final,
      docId: 'newId',
      parentID: primary.id,
      isUpdate: false
    };
    finalData.entries.map(async (entry) => {
      addEntry({ ...entry, familyId: finalData.familyId });
    });
  };
  const submitForm = async (formData) => {
    if (formData[0].for && formData[0].family) {
      submitNewFamilyForm(formData);
    }
  };

  const addEntry = (finalData) => {
    firebase
      // .addNewEntries(finalData)
      .addDataEntries(finalData)
      .then(
        (result) => {
          dispatch(addMessage('Single Family Entry Created'));
          if (finalData.label === 'Alert') {
            const requestOptions = {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                familyId: finalData.familyId,
                entryId: result.id,
                isFree:
                  hasPreserveEntryData?.entries?.[0]?.groupType === 'Free'
                    ? true
                    : false
              })
            };
            fetch(
              `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/sendInstantPN`,
              requestOptions
            )
              .then((response) => {
                const result = response.json();
                if (!response.ok) {
                  throw new Error(result?.message);
                }
                return result
              })
              .then((data) => console.log('.'));
          }

          dispatch(setPreserveEntryData(null));
          dispatch(loaderStop());
          onClose();
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Entry was not created. Please try again', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Entry was not created. Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  };

  return (
    <RightDrawer isOpen={isOpen} onClose={onClose} width={900}>
      <Formik
        enableReinitialize={true}
        initialValues={initialEntry}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let primaryDesc = '';
          const updatedValues = values.entries.map((entry, index) => {
            if (entry.primaryEventTo) {
              delete entry.primaryEventTo;
            }
            if (entry.primaryEventFrom) {
              delete entry.primaryEventFrom;
            }
            if (entry.customType) {
              delete entry.customType;
            }
            //selection logic start
            if (entry.actions.notification.length) {
              const formateValue = 'MMM DD,YYYY';
              entry.actions.notification.forEach((item) => {
                const fromDate = moment(entry.from.date, formateValue);
                const notificationDate = moment(
                  item.date ? item.date : entry.from.date,
                  formateValue
                );
                let dayValue = '';
                if (moment(notificationDate).isSame(fromDate)) {
                  dayValue = '';
                } else {
                  dayValue = notificationDate.diff(fromDate, 'days');
                }
                item.days = dayValue;
              });
            }

            //selection logic end

            // remove deadline and reminder logic start
            if (entry.actions.isComplete) {
              // entry.actions.deadline = []
              // entry.actions.reminder = []
              if (entry.actions.deadlineNotification)
                delete entry.actions.deadlineNotification;
              if (entry.actions.reminderNotification)
                delete entry.actions.reminderNotification;
            }
            // remove deadline and reminder logic end
            // linked entry description logic start
            if (entry.description && index === 0) {
              primaryDesc = toGetHTMLContent(entry.description).replaceAll('</a>&nbsp;<', '</a><')
            }
            // linked entry description logic end
            return {
              ...entry,
              agentName: currentUser.firstName,
              expand: index === 0 ? true : false,
              primary_description:
                index !== 0
                  ? {
                      description: primaryDesc ? primaryDesc : '',
                      from: values.entries[0].from
                    }
                  : {},
              primaryID: index !== 0 ? values.entries[0].id : '',
              createdAt: moment().format(),
              description: entry.description
                ? toGetHTMLContent(entry.description).replaceAll('</a>&nbsp;<', '</a><')
                : ''
            };
          });
          submitForm(updatedValues);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue
        }) => {
          setUpdatedEntry(values);
          return (
            <>
              {
                <Backdrop className={classes.backdrop} open={isLoading}>
                  <CircularProgress color="primary" />
                </Backdrop>
              }
              <form noValidate onSubmit={handleSubmit}>
                <div className={classes.headerContainer}>
                  <div className={classes.headerTitleContainer}>
                    <FamiliesIcon
                      color={colors.primaryDarkGrey}
                      className={classes.familyIconStyle}
                    />
                    <Typography variant="h6" className={classes.titleText}>
                      Single Family
                    </Typography>
                  </div>
                  <div className={classes.headerButtonContainer}>
                    <Button
                      className={classes.deleteButton}
                      onClick={() => {
                        setOpenDeleteModal(true);
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.publishButton}
                    >
                      Publish
                    </Button>
                  </div>
                </div>
                <div style={{ position: 'relative' }}>
                  {values.entries.length &&
                  values.entries[0] &&
                  (values.entries[0].actions.isCancelled ||
                    values.entries[0].actions.isPostponed ||
                    values.entries[0].actions.isChanged) ? (
                    <Chip
                      size="small"
                      label={
                        <span style={{ fontSize: 12 }}>
                          {values.entries[0].actions.isPostponed
                            ? 'POSTPONED'
                            : values.entries[0].actions.isCancelled
                            ? 'CANCELLED'
                            : 'UPDATED'}
                        </span>
                      }
                      classes={{
                        labelSmall: classes.chipLabel,
                        root: classes.chipRoot
                      }}
                      style={{
                        marginRight: 10,
                        backgroundColor:
                          values.entries[0].actions.isCancelled ||
                          values.entries[0].actions.isPostponed
                            ? colors.tertiaryRed
                            : colors.primaryDarkGrey
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <Divider variant="fullWidth" />
                <div className={classes.drawerLeftContent}>
                  <div className={classes.leftContentMargin}>
                    {values.entries.map((entry, index) => (
                      <Accordion
                        style={{ margin: 10, boxShadow: 'none' }}
                        key={`expand${index}`}
                        expanded={
                          values.entries.length === 1 ? true : entry.expand
                        }
                        onChange={() =>
                          values.entries.length > 1 &&
                          handleExpandChange(index, values)
                        }
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className={classes.whiteText} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            backgroundColor:
                              values.entries.length !== 1
                                ? entry.expand && index !== 0
                                  ? colors.primaryDarkGrey7
                                  : colors.primaryDarkGrey
                                : null,
                            height:
                              values.entries.length !== 1
                                ? entry.expand
                                  ? 56
                                  : 60
                                : null
                          }}
                        >
                          {index === 0 ? (
                            <Typography
                              className={clsx(
                                classes.titleAccordionText,
                                values.entries.length > 1
                                  ? classes.whiteText
                                  : classes.darkPrimaryText
                              )}
                            >
                              Primary Entry
                            </Typography>
                          ) : (
                            <>
                              <CornerDownRight
                                className={classes.whiteText}
                                style={{ marginTop: 5 }}
                              />
                              <Typography
                                className={clsx(
                                  classes.titleAccordionText,
                                  classes.whiteText
                                )}
                                style={{
                                  fontSize: 18,
                                  lineHeight: 2.2,
                                  fontWeight: 'bold'
                                }}
                              >
                                Linked Entry {index}
                              </Typography>
                            </>
                          )}
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            backgroundColor:
                              values.entries.length > 1
                                ? colors.secondaryGreyLight
                                : ''
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <div
                              style={{ flexDirection: 'row', display: 'flex' }}
                            >
                              <FormControl
                                variant="outlined"
                                className={classes.formLabelControl}
                                // style={{ height: 40 }}
                              >
                                <InputLabel id="select-outlined-label">
                                  Label
                                </InputLabel>
                                <Select
                                  labelId="select-outlined-label"
                                  name={`entries[${index}].label`}
                                  onBlur={handleBlur}
                                  // onChange={handleChange}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `entries[${index}].label`,
                                      e.target.value
                                    );
                                  }}
                                  value={
                                    (values.entries[index] &&
                                      values.entries[index].label) ||
                                    'none'
                                  }
                                  label="Label"
                                  error={Boolean(
                                    touched.entries &&
                                      errors.entries &&
                                      touched.entries[index] &&
                                      touched.entries[index].label &&
                                      errors.entries[index] &&
                                      errors.entries[index].label
                                  )}
                                >
                                  {labels.map((label, index) => (
                                    <MenuItem
                                      key={`${label.value}-${index}`}
                                      value={label.value}
                                    >
                                      {label.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      values.entries[index].isLowPriority
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `entries[${index}].isLowPriority`,
                                        e.target.checked
                                      );
                                    }}
                                    classes={{
                                      switchBase: classes.switchBase,
                                      thumb: classes.thumb,
                                      track: classes.track,
                                      checked: classes.checked
                                    }}
                                  />
                                }
                                label="Low Priority"
                                style={{
                                  width: '56%',
                                  marginTop: 13,
                                  display: 'flex',
                                  marginLeft: 10,
                                  marginTop: 5
                                }}
                              />
                              {taskLabels.includes(
                                values.entries[index].label
                              ) && (
                                <div
                                  style={{
                                    width: '56%',
                                    marginTop: 13,
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }}
                                >
                                  <Checkbox
                                    color="primary"
                                    className={classes.iconButton}
                                    checked={
                                      (values.entries[index] &&
                                        values.entries[index].actions
                                          .isComplete) ||
                                      false
                                    }
                                    name={`entries[${index}].actions.isComplete`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </div>
                              )}
                            </div>
                            <FormControl component="fieldset">
                              <TextField
                                label="Add Title"
                                variant="outlined"
                                className={classes.inputControlBox}
                                name={`entries[${index}].title`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  (values.entries[index] &&
                                    values.entries[index].title) ||
                                  ''
                                }
                                error={Boolean(
                                  touched.entries &&
                                    errors.entries &&
                                    touched.entries[index] &&
                                    touched.entries[index].title &&
                                    errors.entries[index] &&
                                    errors.entries[index].title
                                )}
                              />
                            </FormControl>
                            {/* {index === 0 && ( */}
                            <FormControl component="fieldset">
                              <TextField
                                label="Add location"
                                placeholder="Add Location"
                                variant="outlined"
                                className={classes.inputControlBox}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        src={LocationIcon}
                                        style={{ opacity: 0.7 }}
                                      />
                                    </InputAdornment>
                                  )
                                }}
                                name={`entries[${index}].location`}
                                onBlur={(e) => {
                                  setFieldValue(
                                    `entries[${index}].location`,
                                    e.target.value.trim().split(/ +/).join(' ')
                                  );
                                  handleBlur(e);
                                }}
                                onChange={handleChange}
                                value={
                                  (values.entries[index] &&
                                    values.entries[index].location) ||
                                  ''
                                }
                              />
                            </FormControl>
                            {/* )} */}
                            <FormControl component="fieldset">
                              <EditorComponent
                                    classes={classes}
                                    preserveData={values.entries[index]?.description}
                                    wrapperClassName={clsx(
                                      values.entries[index] &&
                                        values.entries[index].checkDescription
                                        ? classes.wrapperClassName
                                        : classes.wrapperClassNameWithBottomBorder
                                    )}
                                    index={index}
                                    onChange={(e, index) => onEditorChange(e, index, values)}
                                  />
                            </FormControl>
                            {values.entries[index] &&
                            values.entries[index].checkDescription ? (
                              <>
                                <div className={classes.descriptionContainer}>
                                  <div
                                    className={classes.descriptionMainContainer}
                                  >
                                    <img
                                      src={EditPencil}
                                      alt=""
                                      className={
                                        classes.descriptionImageContainer
                                      }
                                    />
                                    <p
                                      className={
                                        classes.descriptionTextContainer
                                      }
                                    >
                                      Created on{' '}
                                      {values.entries[index].createdAt
                                        ? moment(
                                            values.entries[index].createdAt
                                          ).format('DD MMM YYYY')
                                        : moment().format('DD MMM YYYY')}{' '}
                                      by {values.entries[index].agentName}
                                    </p>
                                  </div>
                                  <hr
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      borderTop: `dotted 3px ${colors.primaryLightGrey}`,
                                      width: 570
                                    }}
                                  />
                                  <div
                                    className={classes.descriptionMainContainer}
                                  >
                                    <img
                                      src={Calendar}
                                      alt=""
                                      className={
                                        classes.descriptionImageContainer
                                      }
                                    />
                                    <p
                                      className={
                                        classes.descriptionTextContainer
                                      }
                                    >
                                      Relates to:{' '}
                                      {values.entries[0].from.date
                                        ? moment(
                                            values.entries[0].from.date
                                          ).format('DD MMM YYYY')
                                        : moment().format('DD MMM YYYY')}{' '}
                                      entry
                                    </p>
                                  </div>
                                </div>
                                <Editor
                                  readOnly={true}
                                  editorState={defaultNote}
                                  toolbarClassName={classes.toolbarClassName}
                                  editorClassName={classes.editorClassName}
                                  wrapperClassName={
                                    classes.wrapperSecondEditorClassName
                                  }
                                  toolbar={{
                                    options: [],
                                    inline: {
                                      options: []
                                    }
                                  }}
                                  // editorState={}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            {values.entries[index] &&
                            values.entries[index].isPrimary ? (
                              <>
                                <div
                                  className={classes.descriptionContainer}
                                  style={{
                                    borderBottomLeftRadius: 5,
                                    borderBottomRightRadius: 5,
                                    borderBottom: `1px solid ${colors.primaryLightGrey}`
                                  }}
                                >
                                  <div
                                    className={classes.descriptionMainContainer}
                                  >
                                    <img
                                      src={EditPencil}
                                      alt=""
                                      className={
                                        classes.descriptionImageContainer
                                      }
                                    />
                                    <p
                                      className={
                                        classes.descriptionTextContainer
                                      }
                                    >
                                      Created on{' '}
                                      {values.entries[index].createdAt
                                        ? moment(
                                            values.entries[index].createdAt
                                          ).format('DD MMM YYYY')
                                        : moment().format('DD MMM YYYY')}{' '}
                                      by {values.entries[index].agentName}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {index !== 0 ? (
                              <div
                                style={{
                                  marginTop: 20,
                                  marginLeft: 10,
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        (values.entries[index] &&
                                          values.entries[index]
                                            .checkDescription) ||
                                        false
                                      }
                                      onChange={(e) =>
                                        handleChangeCheck(
                                          'checkDescription',
                                          index,
                                          values
                                        )
                                      }
                                      name={`entries[${index}].checkDescription`}
                                    />
                                  }
                                  label="Include main description"
                                  className={classes.includeText}
                                />
                                <DeleteOutlineOutlinedIcon
                                  onClick={() =>
                                    setOpenDeleteLinkedModal({
                                      open: true,
                                      id: index
                                    })
                                  }
                                  className={classes.iconTrashStyle}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                  <div>
                    <Button
                      className={classes.linkedButton}
                      startIcon={<AddIcon color={colors.primaryDarkGrey} />}
                      onClick={() => addLinkedEntry(values)}
                      variant="contained"
                      color={colors.primaryDarkGrey}
                    >
                      Add Linked Entry
                    </Button>
                  </div>
                </div>
                <div className={classes.drawerRightContent}>
                  <Divider
                    variant="fullWidth"
                    orientation="vertical"
                    style={{ float: 'right' }}
                  />
                  {values.entries.map((entry, index) => (
                    <Fragment key={`right-content-${index}`}>
                      {values.entries.length === 1 || entry.expand ? (
                        <div
                          style={{
                            padding: '0 10px',
                            backgroundColor: colors.tertiary
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center'
                            }}
                          >
                            <div>
                              {index === 0 ? (
                                <FormControl
                                  variant="outlined"
                                  className={classes.formLabelControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-family">
                                    Family
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-family"
                                    id="demo-simple-select-outlined"
                                    name={`entries[${index}].family`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (e.target.value !== '') {
                                        setFieldValue(
                                          `entries[${index}].family`,
                                          e.target.value
                                        );
                                        setFieldValue(
                                          `entries[${index}].for`,
                                          ''
                                        );
                                        setFieldValue(
                                          `entries[${index}].assign`,
                                          []
                                        );
                                        familyChangeHandle(e, index, values);
                                      }
                                    }}
                                    value={
                                      (families &&
                                        families.length &&
                                        values.entries[index] &&
                                        values.entries[index].family) ||
                                      ''
                                    }
                                    label="family"
                                    error={Boolean(
                                      // touched.entries &&
                                      errors.entries &&
                                        // touched.entries[index] &&
                                        // touched.entries[index].family &&
                                        errors.entries[index] &&
                                        errors.entries[index].family
                                    )}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {families &&
                                      families.length &&
                                      families.map((family, forIndex) => (
                                        <MenuItem
                                          key={`family-select-${forIndex}`}
                                          value={family.id}
                                        >
                                          {family.name}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                <></>
                              )}
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center'
                                }}
                              >
                                <FormControl
                                  variant="outlined"
                                  className={classes.formLabelControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-for">
                                    For
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-for"
                                    id="demo-simple-select-outlined"
                                    name={`entries[${index}].for`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                      values.entries[index]
                                        .assignDropDownData &&
                                      values.entries[index].assignDropDownData
                                        .length &&
                                      values.entries[index]
                                        ? values.entries[index].for || ''
                                        : values.entries[index].for || ''
                                    }
                                    label="For"
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index].for &&
                                        errors.entries[index] &&
                                        errors.entries[index].for
                                    )}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'Parents'}>
                                      Parents
                                    </MenuItem>
                                    {values.entries[index].assignDropDownData &&
                                      values.entries[index].assignDropDownData
                                        .length &&
                                      values.entries[
                                        index
                                      ].assignDropDownData.map(
                                        (child, forIndex) =>
                                          child.type !== 'parent' && (
                                            <MenuItem
                                              key={`for-select-${forIndex}`}
                                              value={child.id}
                                            >
                                              {child.value}
                                            </MenuItem>
                                          )
                                      )}
                                  </Select>
                                </FormControl>
                                <FormControl
                                  variant="outlined"
                                  className={classes.formLabelControl}
                                >
                                  <InputLabel id="demo-simple-select-outlined-label-assign">
                                    Assign
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label-assign"
                                    id="demo-simple-select-outlined-assign"
                                    multiple
                                    name={`entries.[${index}].assign`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `entries[${index}].assign`,
                                        e.target.value
                                      );
                                      const updateInitialValue =
                                        values.entries.map(
                                          (entryData, entryIndex) => {
                                            let updatedAssignDropDown = [];
                                            if (index === entryIndex) {
                                              updatedAssignDropDown =
                                                entryData.assignDropDownData.map(
                                                  (assignData) => {
                                                    return {
                                                      ...assignData,
                                                      selected:
                                                        e.target.value.includes(
                                                          assignData.id
                                                        )
                                                    };
                                                  }
                                                );
                                            }
                                            return {
                                              ...entryData,
                                              assign:
                                                index === entryIndex
                                                  ? e.target.value
                                                  : entryData.assign,
                                              assignDropDownData:
                                                index === entryIndex
                                                  ? updatedAssignDropDown
                                                  : entryData.assignDropDownData
                                            };
                                          }
                                        );
                                      setInitialEntry({
                                        entries: [...updateInitialValue]
                                      });
                                    }}
                                    renderValue={(selected) => {
                                      const assignNames =
                                        values.entries[index]
                                          .assignDropDownData &&
                                        values.entries[index].assignDropDownData
                                          .length
                                          ? values.entries[
                                              index
                                            ].assignDropDownData.map((data) => {
                                              if (selected.includes(data.id)) {
                                                return data.value;
                                              } else {
                                                return null;
                                              }
                                            })
                                          : [];
                                      const assignNamesFilter =
                                        assignNames.filter(
                                          (data) => data && data
                                        );
                                      return assignNamesFilter.join(', ');
                                    }}
                                    value={
                                      (values.entries[index]
                                        .assignDropDownData &&
                                        values.entries[index].assignDropDownData
                                          .length &&
                                        values.entries[index] &&
                                        values.entries[index].assign) ||
                                      []
                                    }
                                    label="Assign"
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index].assign &&
                                        errors.entries[index] &&
                                        errors.entries[index].assign
                                    )}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {values.entries[index].assignDropDownData &&
                                      values.entries[index].assignDropDownData
                                        .length &&
                                      values.entries[
                                        index
                                      ].assignDropDownData.map(
                                        (child, assignIndex) => (
                                          <MenuItem
                                            key={`assign-${assignIndex}`}
                                            value={child.id}
                                            className={classes.menuItemClass}
                                          >
                                            <div>
                                              <Checkbox
                                                icon={
                                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                                }
                                                checkedIcon={
                                                  <CheckBoxIcon fontSize="small" />
                                                }
                                                checked={
                                                  child.selected || false
                                                }
                                                name={child.value}
                                                className={classes.controlCheck}
                                                classes={{
                                                  root: classes.checkbox,
                                                  checked: classes.checked
                                                }}
                                              />
                                              {child.value}
                                            </div>
                                          </MenuItem>
                                        )
                                      )}
                                  </Select>
                                </FormControl>
                              </div>
                            </div>

                            <Divider
                              variant="fullWidth"
                              style={{ marginTop: '20px' }}
                            />

                            <div style={{ marginTop: 20 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                    onChange={(e) =>
                                      handleChangeCheck('allDay', index, values)
                                    }
                                    name={`entries[${index}].allDay`}
                                  />
                                }
                                label="All day"
                              />
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 10
                              }}
                            >
                              <Typography>From</Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginTop: 5
                                }}
                              >
                                <DatePicker
                                  autoOk
                                  okLabel=""
                                  cancelLabel=""
                                  name={`entries[${index}].from.date`}
                                  onBlur={handleBlur}
                                  onChange={(date) =>
                                    handleDateChange(
                                      date,
                                      index,
                                      values,
                                      'from',
                                      'date'
                                    )
                                  }
                                  value={
                                    (values.entries[index] &&
                                      moment(
                                        values.entries[index].from.date
                                      )) ||
                                    ''
                                  }
                                  style={{ width: '40%' }}
                                  format="MMM DD,YYYY"
                                  className={
                                    classes.formDateControlFromBordered
                                  }
                                />
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 1, max: 12 }
                                    }}
                                    variant="outlined"
                                    placeholder="hh"
                                    //style={{height: '23px', marginTop: 0}}
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newFromTimeFormate.hh`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value.length <= 2 &&
                                          parseInt(e.target.value) <= 12 &&
                                          parseInt(e.target.value) > 0 &&
                                          Number.isInteger(
                                            parseInt(e.target.value)
                                          )) ||
                                        e.target.value === ''
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          `entries[${index}].newToTimeFormate.hh`,
                                          parseInt(e.target.value) === 12
                                            ? '1'
                                            : 1 + parseInt(e.target.value)
                                        );
                                      }
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newFromTimeFormate
                                          .hh) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newFromTimeFormate &&
                                        touched.entries[index]
                                          .newFromTimeFormate.hh &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newFromTimeFormate &&
                                        errors.entries[index].newFromTimeFormate
                                          .hh
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 0, max: 59 }
                                    }}
                                    variant="outlined"
                                    placeholder="mm"
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newFromTimeFormate.mm`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value.length <= 2 &&
                                          parseInt(e.target.value) <= 59 &&
                                          parseInt(e.target.value) >= 0 &&
                                          Number.isInteger(
                                            parseInt(e.target.value)
                                          )) ||
                                        e.target.value === ''
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          `entries[${index}].newFromTimeFormate.mm`,
                                          e.target.value
                                        );
                                        setFieldValue(
                                          `entries[${index}].newToTimeFormate.mm`,
                                          e.target.value
                                        );
                                      }
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newFromTimeFormate
                                          .mm) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newFromTimeFormate &&
                                        touched.entries[index]
                                          .newFromTimeFormate.mm &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newFromTimeFormate &&
                                        errors.entries[index].newFromTimeFormate
                                          .mm
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    variant="outlined"
                                    placeholder="a/p"
                                    //style={{height: '23px', marginTop: 0}}
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newFromTimeFormate.ap`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === '' ||
                                        e.target.value.toLowerCase() === 'a' ||
                                        e.target.value.toLowerCase() === 'p'
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          `entries[${index}].newToTimeFormate.ap`,
                                          e.target.value
                                        );
                                      }
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newFromTimeFormate
                                          .ap) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newFromTimeFormate &&
                                        touched.entries[index]
                                          .newFromTimeFormate.ap &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newFromTimeFormate &&
                                        errors.entries[index].newFromTimeFormate
                                          .ap
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>

                                {/* <ThemeProvider theme={muiTimePickerTheme}>
                                  <TimePicker
                                    autoOk
                                    name={`entries[${index}].from.time`}
                                    onBlur={handleBlur}
                                    onChange={(e) =>
                                      handleDateChange(
                                        e,
                                        index,
                                        values,
                                        'from',
                                        'time'
                                      )
                                    }
                                    value={
                                      (values.entries[index] &&
                                        moment(
                                          values.entries[index].from.time,
                                          'h:mma'
                                        )) ||
                                      ''
                                    }
                                    format="h:mma"
                                    style={{ width: 80 }}
                                    className={
                                      classes.formDateControlFromBordered
                                    }
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </ThemeProvider> */}
                              </div>
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 10
                              }}
                            >
                              <Typography>To</Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginTop: 5
                                }}
                              >
                                <DatePicker
                                  autoOk
                                  okLabel=""
                                  cancelLabel=""
                                  name={`entries[${index}].to.date`}
                                  onBlur={handleBlur}
                                  minDate={
                                    (values.entries[index] &&
                                      moment(
                                        values.entries[index].from.date
                                      )) ||
                                    ''
                                  }
                                  onChange={(date) =>
                                    handleDateChange(
                                      date,
                                      index,
                                      values,
                                      'to',
                                      'date'
                                    )
                                  }
                                  value={
                                    (values.entries[index] &&
                                      moment(values.entries[index].to.date)) ||
                                    ''
                                  }
                                  style={{ width: '40%' }}
                                  format="MMM DD,YYYY"
                                  className={
                                    classes.formDateControlFromBordered
                                  }
                                />
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 1, max: 12 }
                                    }}
                                    variant="outlined"
                                    placeholder="hh"
                                    //style={{height: '23px', marginTop: 0}}
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newToTimeFormate.hh`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value.length <= 2 &&
                                          parseInt(e.target.value) <= 12 &&
                                          parseInt(e.target.value) > 0 &&
                                          Number.isInteger(
                                            parseInt(e.target.value)
                                          )) ||
                                        e.target.value === ''
                                      )
                                        handleChange(e);
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newToTimeFormate
                                          .hh) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newToTimeFormate &&
                                        touched.entries[index].newToTimeFormate
                                          .hh &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newToTimeFormate &&
                                        errors.entries[index].newToTimeFormate
                                          .hh
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 0, max: 59 }
                                    }}
                                    variant="outlined"
                                    placeholder="mm"
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newToTimeFormate.mm`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value.length <= 2 &&
                                          parseInt(e.target.value) <= 59 &&
                                          parseInt(e.target.value) >= 0 &&
                                          Number.isInteger(
                                            parseInt(e.target.value)
                                          )) ||
                                        e.target.value === ''
                                      )
                                        handleChange(e);
                                      setFieldValue(
                                        `entries[${index}].newFromTimeFormate.mm`,
                                        e.target.value
                                      );
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newToTimeFormate
                                          .mm) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newToTimeFormate &&
                                        touched.entries[index].newToTimeFormate
                                          .mm &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newToTimeFormate &&
                                        errors.entries[index].newToTimeFormate
                                          .mm
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    variant="outlined"
                                    placeholder="a/p"
                                    //style={{height: '23px', marginTop: 0}}
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newToTimeFormate.ap`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === '' ||
                                        e.target.value.toLowerCase() === 'a' ||
                                        e.target.value.toLowerCase() === 'p'
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newToTimeFormate
                                          .ap) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newToTimeFormate &&
                                        touched.entries[index].newToTimeFormate
                                          .ap &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newToTimeFormate &&
                                        errors.entries[index].newToTimeFormate
                                          .ap
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>

                                {/* <ThemeProvider theme={muiTimePickerTheme}>
                                  <TimePicker
                                    autoOk
                                    name={`entries[${index}].to.time`}
                                    onBlur={handleBlur}
                                    onChange={(e) =>
                                      handleDateChange(
                                        e,
                                        index,
                                        values,
                                        'to',
                                        'time'
                                      )
                                    }
                                    value={
                                      (values.entries[index] &&
                                        moment(
                                          values.entries[index].to.time,
                                          'h:mma'
                                        )) ||
                                      ''
                                    }
                                    format="h:mma"
                                    style={{ width: 80 }}
                                    className={
                                      classes.formDateControlFromBordered
                                    }
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </ThemeProvider> */}
                              </div>
                            </div>
                            {/* <div style={{ marginTop: 20, marginLeft: 10 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                    onChange={(e) =>
                                      handleChangeCheck('allDay', index, values)
                                    }
                                    name={`entries[${index}].allDay`}
                                  />
                                }
                                label="All day"
                              />
                            </div> */}
                            <Divider
                              variant="fullWidth"
                              style={{ marginBottom: 20, marginTop: 20 }}
                            />
                            {entry.actions.repeat.length && index === 0 ? (
                              <div style={{ marginTop: 20 }}>
                                <Typography variant="subtitle2">
                                  Repeat
                                </Typography>
                                {entry.actions.repeat.map((data, idx) => (
                                  <div
                                    key={`repeat-${idx}`}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginTop: 16
                                    }}
                                  >
                                    <FormControl
                                      className={
                                        classes.formLabelControlBordered
                                      }
                                      style={{ width: '90%' }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        name={`entries[${index}].actions.repeat[${idx}].type`}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleRepeatChange(
                                            e,
                                            index,
                                            idx,
                                            values
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index].actions
                                              .repeat[idx].type) ||
                                          ''
                                        }
                                        label="Repeat"
                                      >
                                        {/* <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem> */}
                                        <MenuItem value={'Does not repeat'}>
                                          Does not repeat
                                        </MenuItem>
                                        <MenuItem value={'Daily'}>
                                          Daily
                                        </MenuItem>
                                        <MenuItem value={'Custom'}>
                                          Custom
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                    {(values.entries[index] &&
                                      values.entries[index].actions.repeat[idx]
                                        .type) === 'Custom' ? (
                                      <VisibilityIcon
                                        onClick={(e) => {
                                          const data = {
                                            selectedIndex: index,
                                            innerIndex: idx,
                                            values: {
                                              entries: [...values.entries]
                                            }
                                          };
                                          setCustomValue(data);
                                          setOpenModal({
                                            open: true,
                                            value:
                                              values.entries[index].actions
                                                .repeat[idx]
                                          });
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <CloseIcon
                                      onClick={(e) =>
                                        handleDateActionRemove(
                                          index,
                                          idx,
                                          values,
                                          'repeat'
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {entry.actions.notification.length ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginTop: 20
                                }}
                              >
                                <Typography variant="subtitle2">
                                  Notification
                                </Typography>
                                {entry.actions.notification.map((data, idx) => (
                                  <div
                                    key={`notification-${idx}`}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginTop: 16
                                    }}
                                  >
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formDayLabelControl}
                                    >
                                      <DatePicker
                                        autoOk
                                        okLabel=""
                                        cancelLabel=""
                                        name={`entries[${index}].actions.notification[${idx}].date`}
                                        onBlur={handleBlur}
                                        // maxDate={
                                        //   (values.entries[index] &&
                                        //     moment(
                                        //       values.entries[index].from.date
                                        //     )) ||
                                        //   ''
                                        // }
                                        onChange={(date) =>
                                          handleNotificationDateChange(
                                            date,
                                            index,
                                            idx,
                                            values,
                                            'date'
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index].actions
                                              .notification[idx].date) ||
                                          moment().format('MMM DD,YYYY')
                                        }
                                        style={{ width: '80%' }}
                                        minDateMessage=""
                                        format="MMM DD,YYYY"
                                        className={
                                          classes.formDateControlFromBordered
                                        }
                                      />
                                    </FormControl>
                                    <ThemeProvider theme={muiTimePickerTheme}>
                                      <TimePicker
                                        autoOk
                                        name={`entries[${index}].notification.time`}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleDateActionChange(
                                            e,
                                            index,
                                            idx,
                                            values,
                                            'notification',
                                            'time'
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            moment(
                                              values.entries[index].actions
                                                .notification[idx].time,
                                              'h:mma'
                                            )) ||
                                          ''
                                        }
                                        format="h:mma"
                                        style={{ width: 80 }}
                                        className={
                                          classes.formDateControlFromBordered
                                        }
                                      />
                                    </ThemeProvider>
                                    <CloseIcon
                                      onClick={(e) =>
                                        handleDateActionRemove(
                                          index,
                                          idx,
                                          values,
                                          'notification'
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {entry.actions.reminder.length ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginTop: 20
                                }}
                              >
                                {entry.actions.reminder.map((data, idx) => (
                                  <Fragment key={`reminder-${idx}`}>
                                    <Typography variant="subtitle2">
                                      Reminder
                                    </Typography>
                                    <div
                                      key={`reminder-${idx}`}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: 16
                                      }}
                                    >
                                      <DatePicker
                                        autoOk
                                        okLabel=""
                                        cancelLabel=""
                                        name={`entries[${index}].reminder`}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleDateActionChange(
                                            e,
                                            index,
                                            idx,
                                            values,
                                            'reminder',
                                            'date'
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            moment(
                                              values.entries[index].actions
                                                .reminder[idx]
                                            )) ||
                                          ''
                                        }
                                        format="MMM DD,YYYY"
                                        style={{ width: '80%' }}
                                        className={
                                          classes.formDateControlFromBordered
                                        }
                                      />
                                      <CloseIcon
                                        onClick={(e) =>
                                          handleDateActionRemove(
                                            index,
                                            idx,
                                            values,
                                            'reminder'
                                          )
                                        }
                                      />
                                    </div>

                                    {values.entries[index] &&
                                    values.entries[index].actions
                                      .reminderNotification &&
                                    values.entries[index].actions
                                      .reminderNotification[idx] ? (
                                      <>
                                        <div
                                          key={`reminderNotificationTitle-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16
                                          }}
                                        >
                                          <Typography variant="subtitle2">
                                            Reminder (Notification)
                                          </Typography>
                                        </div>
                                        <div
                                          key={`reminderNotification-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16,
                                            marginBottom: 16
                                          }}
                                        >
                                          <FormControl
                                            variant="outlined"
                                            className={
                                              classes.formDayLabelControl
                                            }
                                          >
                                            <DatePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              okLabel=""
                                              cancelLabel=""
                                              name={`entries[${index}].actions.reminderNotification[${idx}].date`}
                                              onBlur={handleBlur}
                                              // maxDate={
                                              //   (values.entries[index] &&
                                              //     moment(
                                              //       values.entries[index].from.date
                                              //     )) ||
                                              //   ''
                                              // }
                                              onChange={(date) =>
                                                handleNotificationDateChange(
                                                  date,
                                                  index,
                                                  idx,
                                                  values,
                                                  'date',
                                                  'reminderNotification'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .reminderNotification &&
                                                  values.entries[index].actions
                                                    .reminderNotification[
                                                    idx
                                                  ] &&
                                                  values.entries[index].actions
                                                    .reminderNotification[idx]
                                                    .date) ||
                                                moment().format('MMM DD,YYYY')
                                              }
                                              style={{ width: '80%' }}
                                              minDateMessage=""
                                              format="MMM DD,YYYY"
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </FormControl>
                                          <ThemeProvider
                                            theme={muiTimePickerTheme}
                                          >
                                            <TimePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              name={`entries[${index}].reminderNotification.time`}
                                              onBlur={handleBlur}
                                              onChange={(e) =>
                                                handleDateActionChange(
                                                  e,
                                                  index,
                                                  idx,
                                                  values,
                                                  'reminderNotification',
                                                  'time'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .reminderNotification &&
                                                  values.entries[index].actions
                                                    .reminderNotification[
                                                    idx
                                                  ] &&
                                                  moment(
                                                    values.entries[index]
                                                      .actions
                                                      .reminderNotification[idx]
                                                      .time,
                                                    'h:mma'
                                                  )) ||
                                                ''
                                              }
                                              format="h:mma"
                                              style={{ width: '40%' }}
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </ThemeProvider>
                                          <CloseIcon
                                            onClick={(e) => {
                                              // if(!hideForRepeat){
                                              handleDateActionRemove(
                                                index,
                                                idx,
                                                values,
                                                'reminderNotification'
                                              );
                                              // }
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                  </Fragment>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {entry.actions.deadline.length ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginTop: 20
                                }}
                              >
                                {entry.actions.deadline.map((data, idx) => (
                                  <Fragment key={`deadline-${idx}`}>
                                    <Typography variant="subtitle2">
                                      Deadline
                                    </Typography>
                                    <div
                                      key={`deadline-${idx}`}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: 16
                                      }}
                                    >
                                      <DatePicker
                                        autoOk
                                        okLabel=""
                                        cancelLabel=""
                                        name={`entries[${index}].deadline`}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleDateActionChange(
                                            e,
                                            index,
                                            idx,
                                            values,
                                            'deadline',
                                            'date'
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            moment(
                                              values.entries[index].actions
                                                .deadline[idx]
                                            )) ||
                                          ''
                                        }
                                        format="MMM DD,YYYY"
                                        style={{ width: '80%' }}
                                        className={
                                          classes.formDateControlFromBordered
                                        }
                                      />
                                      <CloseIcon
                                        onClick={(e) =>
                                          handleDateActionRemove(
                                            index,
                                            idx,
                                            values,
                                            'deadline'
                                          )
                                        }
                                      />
                                    </div>

                                    {values.entries[index] &&
                                    values.entries[index].actions
                                      .deadlineNotification &&
                                    values.entries[index].actions
                                      .deadlineNotification[idx] ? (
                                      <>
                                        <div
                                          key={`reminderDeadlineTitle-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16
                                          }}
                                        >
                                          <Typography variant="subtitle2">
                                            Deadline (Notification)
                                          </Typography>
                                        </div>
                                        <div
                                          key={`deadlineNotification-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16,
                                            marginBottom: 16
                                          }}
                                        >
                                          <FormControl
                                            variant="outlined"
                                            className={
                                              classes.formDayLabelControl
                                            }
                                          >
                                            <DatePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              okLabel=""
                                              cancelLabel=""
                                              name={`entries[${index}].actions.deadlineNotification[${idx}].date`}
                                              onBlur={handleBlur}
                                              // maxDate={
                                              //   (values.entries[index] &&
                                              //     moment(
                                              //       values.entries[index].from.date
                                              //     )) ||
                                              //   ''
                                              // }
                                              onChange={(date) =>
                                                handleNotificationDateChange(
                                                  date,
                                                  index,
                                                  idx,
                                                  values,
                                                  'date',
                                                  'deadlineNotification'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .deadlineNotification &&
                                                  values.entries[index].actions
                                                    .deadlineNotification[
                                                    idx
                                                  ] &&
                                                  values.entries[index].actions
                                                    .deadlineNotification[idx]
                                                    .date) ||
                                                moment().format('MMM DD,YYYY')
                                              }
                                              style={{ width: '80%' }}
                                              minDateMessage=""
                                              format="MMM DD,YYYY"
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </FormControl>
                                          <ThemeProvider
                                            theme={muiTimePickerTheme}
                                          >
                                            <TimePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              name={`entries[${index}].deadlineNotification.time`}
                                              onBlur={handleBlur}
                                              onChange={(e) =>
                                                handleDateActionChange(
                                                  e,
                                                  index,
                                                  idx,
                                                  values,
                                                  'deadlineNotification',
                                                  'time'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .deadlineNotification &&
                                                  values.entries[index].actions
                                                    .deadlineNotification[
                                                    idx
                                                  ] &&
                                                  moment(
                                                    values.entries[index]
                                                      .actions
                                                      .deadlineNotification[idx]
                                                      .time,
                                                    'h:mma'
                                                  )) ||
                                                ''
                                              }
                                              format="h:mma"
                                              style={{ width: '40%' }}
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </ThemeProvider>
                                          <CloseIcon
                                            onClick={(e) => {
                                              // if(!hideForRepeat){
                                              handleDateActionRemove(
                                                index,
                                                idx,
                                                values,
                                                'deadlineNotification'
                                              );
                                              // }
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                  </Fragment>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {entry.actions.isCancelled ||
                            entry.actions.isPostponed ||
                            entry.actions.isChanged ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  marginTop: 20
                                }}
                              >
                                <div>
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      color: entry.actions.isChanged
                                        ? '#000'
                                        : colors.tertiaryRed
                                    }}
                                    className={classes.updatedLabel}
                                  >
                                    {entry.actions.isPostponed
                                      ? 'Postponed'
                                      : entry.actions.isCancelled
                                      ? 'Cancelled'
                                      : 'Updated'}
                                  </Typography>
                                  <Typography
                                    className={classes.updatedDesc}
                                    style={{
                                      ...(entry.actions.isChanged
                                        ? {}
                                        : { fontSize: 16 }),
                                      color: colors.primaryDarkGrey
                                    }}
                                  >
                                    Display{' '}
                                    {entry.actions.isPostponed
                                      ? 'Postponed'
                                      : entry.actions.isCancelled
                                      ? 'Cancelled'
                                      : 'Updated'}{' '}
                                    to member
                                  </Typography>
                                </div>
                                <CloseIcon
                                  onClick={(e) =>
                                    handleDateActionRemove(
                                      index,
                                      0,
                                      values,
                                      entry.actions.isPostponed
                                        ? 'isPostponed'
                                        : entry.actions.isCancelled
                                        ? 'isCancelled'
                                        : 'isChanged'
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            <FormControl
                              variant="outlined"
                              style={{ marginBottom: 24 }}
                              className={classes.formLabelControl}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Apply Action
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={''}
                                onChange={(e) => {
                                  if (e.target.value) {
                                    handleActionChange(e, index, values);
                                  }
                                }}
                                label="Apply Action"
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {index === 0 && (
                                  <MenuItem
                                    disabled={
                                      entry.actions.repeat.length ? true : false
                                    }
                                    value={'repeat'}
                                  >
                                    Repeat
                                  </MenuItem>
                                )}
                                <MenuItem value={'notification'}>
                                  Notification
                                </MenuItem>
                                <MenuItem value={'deadline'}>Deadline</MenuItem>
                                <MenuItem value={'reminder'}>Reminder</MenuItem>
                                {index === 0 && (
                                  <MenuItem value={'cancelled'}>
                                    Cancelled
                                  </MenuItem>
                                )}
                                {index === 0 && (
                                  <MenuItem value={'postponed'}>
                                    Postponed
                                  </MenuItem>
                                )}
                                {index === 0 && (
                                  <MenuItem value={'changed'}>Updated</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Fragment>
                  ))}
                </div>
              </form>
            </>
          );
        }}
      </Formik>
      {isOpenModal.open && (
        <Modal isOpen={isOpenModal.open} onClose={handleCustomDateClose}>
          <CustomDateModal
            data={customValue}
            submitForm={handleCustomDateSubmit}
            selectedValue={isOpenModal.value}
            onClose={handleCustomDateClose}
          />
        </Modal>
      )}

      {isOpenDeleteModal && (
        <Modal
          isOpen={isOpenDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Delete Entry Creation?
            </Typography>
            <Typography className={classes.modalSubTitle}>
              Are you sure you want to delete this new entry?
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  setUpdatedEntry(null);
                  dispatch(setPreserveEntryData(null));
                  onClose();
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Delete'}
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenDeleteLinkedModal.open && (
        <Modal
          isOpen={isOpenDeleteLinkedModal.open}
          onClose={() => setOpenDeleteLinkedModal({ open: false, id: null })}
        >
          <div className={classes.modalBox}>
            <Typography
              className={classes.modalTitle}
              style={{ marginBottom: 18 }}
            >
              Delete Linked Entry?
            </Typography>
            <Typography className={classes.modalSubTitle}>
              Are you sure you want to delete this linked entry?
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={removeLinkedEntry}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
              >
                Delete
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteLinkedModal({ open: false, id: null });
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </RightDrawer>
  );
}

export default withFirebase(CreateSingleFamilyEntry);
