import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";


export const createEditStudentStyles = makeStyles((theme) => ({
    '& *': {
      fontFamily: 'Roboto'
    },
    backdrop:{
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    },
    header: {
      height: '98px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end',
      alignItems: 'center',
      padding: '24px'
    },
    title:{
      display: "inline-flex",
      alignItems: "center",
      marginRight: "auto",
      "& img":{
        marginRight: "20px"
      }
    },
    moreBtn:{
      marginLeft: 24
    },
    dividerClass: {
      backgroundColor: colors.primaryLightGrey
    },
    mainHeading: {
      height: '40px',
      flexGrow: '0',
      fontFamily: 'Roboto',
      fontSize: '23px',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.74,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: colors.primaryDark,
      marginRight: 'auto',
      marginLeft: 0
    },
    action: {
      width: '144px',
      height: 50,
      borderRadius: 3,
      backgroundColor: colors.primary
    },
    mainContainer: {
      display: 'grid',
      gridTemplateColumns: '49.90% auto 49.90%',
      boxSizing: 'border-box',
      height: 'calc(100vh - 110px)'
    },
    tabs: {
      backgroundColor: colors.secondaryGreyLight
    },
    studentDetailsForm: {
      padding: '30px 24px',
      display: 'flex',
      flexDirection: 'column',
      gap: '30px'
    },
    formControl: {
      width: 450,
      height: 60
    },
    inputControlBox: {
      backgroundColor: '#ffffff',
      fontSize: '16px',
      '& .MuiSelect-outlined:hover, & .MuiSelect-outlined:focus': {
        boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)',
        '&.Mui-disabled': {
          boxShadow: 'none'
        }
      }
    },
    textFieldContainer: {
      '& .MuiInputLabel-shrink': {
        height: 16,
        objectFit: 'contain',
        fontFamily: 'Roboto',
        fontSize: 14,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        paddingLeft: 5,
        paddingRight: 5,
        '&.MuiFormLabel-filled': {
          transform: 'translate(14px, -8px) scale(1) !important',
          color: colors.primary,
          fontWeight: '500',
          '&.Mui-error': {
            color: '#f44336'
          }
        },
        '&.Mui-focused': {
          transform: 'translate(14px, -8px) scale(1) !important',
          color: colors.primary,
          fontWeight: '500',
          '&.Mui-error': {
            color: '#f44336'
          }
        }
      },
      '& .MuiOutlinedInput-notchedOutline legend': {
        fontSize: '14px',
        marginLeft: '5px !important'
      }
    },
    inputControlBoxContainer: {
      margin: '5px 0',
      '& .MuiInputBase-input' :{
        height: 'auto'
      }
    },
    redacted:{
      color: 'transparent',
      textShadow: '0 0 5px rgba(0,0,0,0.5)',
      pointerEvents: 'none',
      userSelect: 'none',
      '&&::selection': {
        color: 'transparent',
        background: 'transparent'
      }
    },
    placeholderText: {
      position: 'absolute'
    },
    appBar: {
      background: 'none',
      boxShadow: 'none'
    },
    customTabs: {
      background: 'none',
      display: 'flex',
      alignItems: 'center',
      color: colors.primaryDarkGrey
    },
    tab: {
      minWidth: 88,
      textTransform: 'none'
    },
    selectedTab: {
      backgroundColor: colors.primary,
      height: '4px'
    },
    bottomDivider: {
      borderBottom: '1px solid #e0e0e0',
      marginTop: '-10.5px'
    },
    categoryList: {
      display: 'inline-flex'
    },
    categoryBtn: {
      marginRight: '12px',
      height: 28,
      padding: '6px 20px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '100px',
      border: '1px solid #d9dce3',
      fontFamily: 'Roboto',
      fontSize: '14px',
      color: '#4c5b68',
      cursor: 'pointer'
    },
    activeCategory: {
      backgroundColor: '#4c5b68',
      color: '#fff',
      border: 'none',
    },
    disabledCategory:{
  
    },
    groupList: {
      display: 'flex',
      flexFlow: 'column',
      marginTop: '24px',
      height: 'calc(86vh - 65px)',
      overflow: 'auto'
    },
    groupListContainer:{
      display: 'inline-flex'
    },
    checkboxColor: {
      color: colors.primaryLightGrey,
      '& .MuiSvgIcon-root': {
        height: 24,
        width: 24
      }
    },
    checkbox: {
      color: `${colors.primaryLightGrey} !important`,
      height: 24,
      width: 24,
      margin: '0 12px 0 0',
    },
    checked:{
      color: `${colors.primary} !important`,
    },
    disabled:{
      opacity: 0.5
    },
    checkboxLabels: {
      display: 'flex',
      flexFlow: 'column',
    },
    formControlLabel:{
      padding: '8px 0px 8px 12px',
      width: '100% !important',
      alignItems: 'flex-start',
      '& .MuiFormControlLabel-label': {
        marginTop: '2px',
        width: "100%"
      }
    },
    labelcontainer:{
      display: "inline-flex",
      width: "100%",
      alignItems: "center",
      "& img":{
        marginRight: "0px",
        marginLeft: "auto"
      }
    },
    selectAll:{
      '& *':{
        fontWeight: 600
      }
    },
    yearsLabel: {
      color: '#bfc5d1'
    },
    membersList: {
      display: 'flex',
      flexFlow: 'column'
    },
    memberDetails: {
      display: 'flex',
      flexFlow: 'column',
      width: '90%'
    },
    details: {
      display: 'inline-flex',
      marginBottom: '16px'
    },
    memberEmail: {
      color: '#bfc5d1'
    },
    rightArrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    margin: 'auto',
    "& svg":{
      height: 16,
      width:16
    }
    },
    modalBox: {
      width: 450
    },
    modalTitle: {
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 1.43,
      letterSpacing: -0.2,
      color: colors.primaryDark
    },
    modalContent: {
      minHeight: 75,
      paddingTop: 12
    },
    modalSubTitle: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.1,
      color: colors.primaryDark
    },
    modalActions: {
      marginTop: '2%'
    },
    primaryButton: {
      width: 163,
      height: 50,
      borderRadius: 3,
      backgroundColor: colors.primary,
      marginRight: 20,
      color: colors.white,
      textTransform: 'none'
    },
    greyButton: {
      width: 136,
      height: 50,
      borderRadius: 3,
      backgroundColor: colors.secondaryText,
      color: colors.white,
      textTransform: 'none'
    },
    popoverClass: {
      '& .MuiPaper-rounded': {
        right: '70px',
        left: 'unset  !important',
        top: '70px !important'
      }
    }
  }));