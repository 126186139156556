import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import {
  Button,
  TableCell,
  IconButton,
  TableRow,
  FormControl,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { linkUrlRegex } from 'src/utils/constant';
import { TrashIcon } from 'src/assets/Icons';
import { Close } from '@material-ui/icons';
import { ReactComponent as EditIcon } from 'src/assets/edit.svg';
import { useLinkStyles } from 'src/styles/linkStyles';
import { setSchool } from 'src/redux/actions/schoolActions';
import { useDispatch } from 'react-redux';

const columns = [
  {
    id: 'title',
    label: 'Title',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '42%'
  },
  {
    id: 'url',
    label: 'URL',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '42%'
  },
  {
    id: 'action',
    label: 'Action',
    iconPosition: 'center',
    minWidth: 170
  }
];
const getClickableLink = (link) => {
  switch (true) {
    case link.startsWith('http://') || link.startsWith('https://') || link.startsWith('mailto:'):
      return link;
    case link.includes('@'):
      return `mailto:${link}`;
    default:
      return `http://${link}`;
  }
};

function CellItem({ row, column, deleteLink, handleEdited }) {
  const value = row[column.id];
  const classes = useLinkStyles();
  if (column.id === 'title') {
    return <p className={classes.text}>{value}</p>;
  }
  if (column.id === 'url') {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={getClickableLink(value)}
        className={classes.linkText}
      >
        {value}
      </a>
    );
  }
  if (column.id === 'action') {
    return (
      <div
        style={{
          textAlign: 'right'
        }}
      >
        <IconButton
          aria-label="Edit"
          className={classes.actionIcon}
          onClick={() => handleEdited(row.id)}
          style={{ marginRight: 20 }}
        >
          <EditIcon className={classes.icon} />
        </IconButton>
        <IconButton
          aria-label="Delete"
          className={classes.actionIcon}
          onClick={() => deleteLink(row)}
        >
          <TrashIcon viewBox="-2 -2 28 28" />
        </IconButton>
      </div>
    );
  }
  return '';
}

function CellInputItem({
  row,
  column,
  handleRowChange,
  updateRow,
  cancel,
  errors
}) {
  const classes = useLinkStyles();

  if (column.id === 'title') {
    return (
      <FormControl component="fieldset" style={{ width: '99%' }}>
        <TextField
          label="Title"
          variant="outlined"
          name="title"
          onChange={(e) => {
            handleRowChange(e, 'title');
          }}
          error={errors.title ? true : false}
          value={row.title}
          className={classes.inputControlBox}
          helperText={errors.title}
        />
      </FormControl>
    );
  }
  if (column.id === 'url') {
    return (
      <FormControl component="fieldset" style={{ width: '99%' }}>
        <TextField
          label="URL/Email"
          variant="outlined"          
          name="url"
          onChange={(e) => {
            handleRowChange(e, 'url');
          }}
          error={errors.url ? true : false}
          value={row.url}
          className={classes.inputControlBox}
          helperText={errors.url}
        />
      </FormControl>
    );
  }
  if (column.id === 'action') {
    return (
      <div style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          component="span"
          color="primary"
          onClick={() => {
            updateRow();
          }}
        >
          Save
        </Button>
        <IconButton
          aria-label="Delete"
          className={classes.actionIcon}
          onClick={cancel}
          style={{ marginLeft: 5, marginRight: 16 }}
        >
          <Close className={classes.icon} />
        </IconButton>
      </div>
    );
  }
  return '';
}

function TableRowItem({ row, columns, updateLink, deleteLink }) {
  const classes = useLinkStyles();
  const [editedRow, setEditedRow] = useState('');
  const [rowValues, setRowValues] = useState(row);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setRowValues(row);
    return () => false;
  }, [row]);

  const updateRow = async () => {
    let errorObj = {};
    if (!rowValues.title) {
      errorObj = { ...errorObj, title: 'Title is required.' };
    }
    if (!rowValues.url) errorObj = { ...errorObj, url: 'URL/Email is required.' };
    if (rowValues.url) {
      if (rowValues.url.includes('@')) {
        // CHECK EMAIL VALIDATION

        const schema = yup.object().shape({
          email: yup.string().email().required(),
        });
        try {
          await schema.validate({
            email: rowValues.url.split('mailto:')[1] ?? rowValues.url
          })
        } catch(error) {
          if (error.message) {
            errorObj = { ...errorObj, url: 'Email is not valid.' };
          }
        }
      } else {
        // CHECK URL VALIDATION
        const checkURLIsValid = rowValues.url.match(linkUrlRegex);
        if (checkURLIsValid == null) {
          errorObj = { ...errorObj, url: 'URL is not valid.' };
        }
      }
    }
    setErrors(errorObj);
    if (!Object.keys(errorObj).length) {
      updateLink(rowValues);
      setEditedRow('');
    }
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      className={clsx(classes.tableRow, {
        [classes.error]: Object.keys(errors).length > 0
      })}
      style={{ width: "100%" }}
    >
      {columns.map((column) => {
        return (
          <TableCell
            key={column.id}
            align={column.align}
            className={clsx(classes.dataTableCell, {
              [classes.padding0]: editedRow === row.id
            })}
            style={{ width: column.width }}
          >
            {editedRow === row.id ? (
              <CellInputItem
                row={rowValues}
                column={column}
                deleteLink={deleteLink}
                updateRow={updateRow}
                handleRowChange={(e, field) => {
                  const newValue = { ...rowValues, [field]: e.target.value };
                  setRowValues(newValue);
                }}
                errors={errors}
                cancel={() => setEditedRow('')}
              />
            ) : (
              <CellItem
                row={row}
                column={column}
                deleteLink={deleteLink}
                handleEdited={(id) => setEditedRow(id)}
              />
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "rgba(0, 0, 0, 0.04)" : "white",
  width: "100%"
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  width: "100%",

  // change background colour if dragging
  background: isDragging ? "rgba(0, 0, 0, 0.04)" : "white",
  display: "table",

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const LinkListComponent = ({ currentSchool, updateLink, deleteLink, submitSchoolData }) => {
  const dispatch = useDispatch();
  const { links } = currentSchool;

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      links,
      result.source.index,
      result.destination.index
    );

    dispatch(setSchool({links: items}));
    submitSchoolData({
      links: items
    }, 'Links order has been updated.');
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <table
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {links?.map((row, index) => (
              <Draggable key={row.id} draggableId={row.id} index={index}>
                {(provided, snapshot) => (
                  <tbody
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <TableRowItem
                      key={index}
                      columns={columns}
                      row={row}
                      updateLink={updateLink}
                      deleteLink={deleteLink}
                    />
                  </tbody>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </table>
        )}
      </Droppable>
    </DragDropContext>
  )
}