import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";

export const schoolIndexStyles = makeStyles({
    tableRow: {
      height: '72px',
      '&:hover': {
        backgroundColor: `rgba(244, 245, 247, 0.51) !important`,
        cursor: 'pointer'
      }
    },
    dataTableCell: {
      color: colors.primaryDark,
      padding: 8,
      '&:first-child': {
        paddingLeft: 52
      }
    },
    actionButton: {
      padding: '8px 17px',
      fontSize: 16,
      lineHeight: '24px',
      float: 'right'
    },
    schoolName: {
      fontWeight: 500
    },
    fromValue: {
      color: '#474747',
      fontSize: '15.5px'
    },
    startIcon: {
      marginRight: 12
    },
    hasCal: {
      fontSize: 14,
      backgroundColor: '#e8f5e9',
      color: '#348f37',
      padding: '0 5px'
    },
    noCal: {
      fontSize: 14,
      backgroundColor: '#f4f5f7',
      color: '#bfc5d1',
      padding: '0 5px'
    },
    actionIcon: {
      height: 50,
      width: 50,
      padding: 10,
      cursor: 'pointer',
      margin: 'auto'
    }
  });