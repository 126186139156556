import {
  makeStyles,
  Button,
  Divider,
  Typography,
  CircularProgress,
  MenuItem,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  InputLabel,
  Select,
  Grid,
  Menu,
  Backdrop,
  FormHelperText,
  RadioGroup,
  Radio
} from '@material-ui/core';
import { withFirebase } from 'src/utils/firebase';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ToggleSwitchButton from 'src/components/ToggleSwitchButton';
import _ from 'lodash';
import * as Yup from 'yup';
import { colors } from 'src/utils/constant';
import { useState } from 'react';
import { MoreVert } from '@material-ui/icons';
import { useEffect } from 'react';
import {
  addMessage,
  loaderStart,
  loaderStop
} from 'src/redux/actions/appActions';
import { useCallback } from 'react';
import { resetGroup } from 'src/redux/actions/groupActions';
import RightDrawer from 'src/components/RightDrawer';
import Modal from 'src/components/Modal';
import { API_IS_GROUP_EXISTS } from 'src/utils/api';
import makeApiRequest from 'src/utils/axios';
import { addSchoolFormDetailsOrUpdateSchool, getSchoolByID } from 'src/services/schools';
import { updateGroup } from 'src/services/groups';
import { paidEditGroupStyles } from 'src/styles/schoolStyle/groupStyle/paidEditGroupStyles';
import { MESSAGE_TYPES } from 'src/constants/common';



function PaidEditGroup({ firebase, onMenuClose, onClose, clearFilters, fetchGroup, isOpen }) {
  const classes = paidEditGroupStyles();
  const [family, setFamily] = useState([]);
  const [year, setYear] = useState([]);
  const [formValues, setFormValues] = useState(null);
  const [isHide, setIsHide] = useState(false);
  const [allFilteredFamily, setAllFilteredFamily] = useState([]);
  const [openDeleteMenu, setOpenDeleteMenu] = useState(null);
  const [isOpenModal, setOpenModal] = useState({ open: false, values: null });
  const dispatch = useDispatch();
  const { currentGroup, groupPaidModalData } = useSelector(
    (state) => state.group
  );
  const isLoading = useSelector((state) => state.app.isLoading);
  // SCHEMA VALIDATOR FOR
  const validationSchema = Yup.object().shape({
    groupName: Yup.string().trim().required('Group Name is required'),
    year: Yup.string().trim().required('Years are required'),
    member: Yup.string().trim().required('Members are required')
  });

  useEffect(() => {
    if (isOpen) {
      const members = groupPaidModalData.groupMembers.map((member) => {
        return {
          ...member,
          selected:
            currentGroup.groupMembers &&
            currentGroup.groupMembers.length &&
            currentGroup.groupMembers.includes(member.id)
              ? true
              : false
        };
      });
      setAllFilteredFamily(members);
      const membersByYears = members.filter((member) => {
        return ( member.id === "all" ||
          (currentGroup.groupMembers.includes(member.id) ||
          currentGroup.years.includes(`Year ${member.year}`))
        );
      });
      setFamily(membersByYears);
      const years = groupPaidModalData.groupYears.map((year) => {
        return {
          ...year,
          selected:
            (currentGroup.years &&
              currentGroup.years.length &&
              currentGroup.years.includes(year.key)) ||
            (year.key === 'all' && currentGroup.years.includes('all'))
              ? true
              : false
        };
      });
      setYear(years);
    }
  }, [firebase, isOpen]);

  const handleOpenDeleteMenu = (event) => {
    if (!openDeleteMenu) {
      setOpenDeleteMenu(event.currentTarget);
    }
  };

  const handleChangeMember = (event, selectedIndex, setFieldValue) => {
    const selectValue = event.target.name;
    let updatedFamily = [];
    if (selectValue === 'all') {
      updatedFamily = family.map((familyData) => {
        if (family[0].selected) {
          return { ...familyData, selected: false };
        } else {
          return { ...familyData, selected: true };
        }
      });
    } else {
      updatedFamily = family.map((familyData, index) => {
        if (index === selectedIndex) {
          return {
            ...familyData,
            selected: familyData.selected ? false : true
          };
        } else {
          return {
            ...familyData,
            selected: index === 0 ? false : familyData.selected
          };
        }
      });
    }
    setFamily(updatedFamily);
    setFieldValue(
      'member',
      updatedFamily.filter((item) => !!item.selected).length ? 'selected' : ''
    );
  };

  const handleChangeYear = (event, selectedIndex, setFieldValue) => {
    const selectValue = event.target.name;
    let updatedYear = [];
    if (selectValue === 'all') {
      updatedYear = year
        .sort((a, b) => a - b)
        .map((yearData) => {
          if (year[0].selected) {
            return { ...yearData, selected: false };
          } else {
            return { ...yearData, selected: true };
          }
        });
      setFamily(allFilteredFamily);
      setFieldValue(
        'member',
        allFilteredFamily.filter((item) => !!item.selected).length
          ? 'selected'
          : ''
      );
    } else {
      updatedYear = year
        .sort((a, b) => a - b)
        .map((yearData, index) => {
          if (index === selectedIndex) {
            return {
              ...yearData,
              selected: yearData.selected ? false : true
            };
          } else {
            return {
              ...yearData,
              selected: index === 0 ? false : yearData.selected
            };
          }
        });

      const selectedYearMap = updatedYear.map((year) => {
        if (year.selected) {
          if (year?.key === 'Pre K') {
            return '-1';
          }
          if (year?.key === 'Kindy') {
            return '0';
          }
          return year.key.split(' ')[1];
        } else {
          return null;
        }
      });
      const selectedYearFilter = selectedYearMap.filter((year) => {
        return year;
      });
      let updatedFamily = [];
      if (selectedYearFilter.length) {
        updatedFamily = allFilteredFamily.filter((data) => {
          const stringToNumber = data.year.toString();
          return selectedYearFilter.includes(stringToNumber);
        });
        const newFamilies = [
          { id: 'all', year: 'all', name: 'Select All', selected: false },
          ..._.sortBy(updatedFamily, [(u) => u.name.toLowerCase()])
        ];
        setFamily(newFamilies);
        setFieldValue(
          'member',
          newFamilies.filter((item) => !!item.selected).length ? 'selected' : ''
        );
      } else {
        setFamily(allFilteredFamily);
        setFieldValue(
          'member',
          allFilteredFamily.filter((item) => !!item.selected).length
            ? 'selected'
            : ''
        );
      }
    }
    setYear(updatedYear);
    setFieldValue(
      'year',
      updatedYear.filter((item) => !!item.selected).length ? 'selected' : ''
    );
  };

  const getCheckboxLabel = (name) => {
    // ref : https://codesandbox.io/s/festive-star-f96eo?file=/src/App.js:134-188
    return <div dangerouslySetInnerHTML={{ __html: name }}></div>;
  };

  const handleClose = useCallback(() => {
    setOpenModal({ open: false, values: null });
    dispatch(resetGroup());
  }, []);

  const getComparedAndDiffrece = (data, compArray = []) => {
    return data.filter((el) => !compArray.includes(el));
  };

  const saveUpdated = async (formData, id, setErrors) => {
    const finalData = {
      ...formData,
      groupName: formData?.groupName?.trim(),
      aliasGroupName: formData?.groupName?.trim()?.toLowerCase()
    };
    dispatch(loaderStart());
    const { data, error } = await makeApiRequest({
      url: API_IS_GROUP_EXISTS,
      method: 'POST',
      data: {
        groupName: finalData.groupName,
        schoolId: finalData.school,
        groupId: id
      }
    });
    if (error) {
      dispatch(addMessage(error, MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    } else if (data) {
      setErrors({
        groupName:
          'Please add a unique group name, This group name already used!'
      });
      dispatch(loaderStop());
    } else {
      const getNewMembers = _.uniq(finalData.groupMembers);
      const getOldMembers = _.uniq(currentGroup.groupMembers);

      // get old member id which is updated from old selection
      const removeMembers = getComparedAndDiffrece(
        getOldMembers,
        getNewMembers
      );

      // get new member id which is updated from old selection
      const newMembers = getComparedAndDiffrece(getNewMembers, getOldMembers);

      let updateMembers = {
        groupId: id,
        families: []
      };

      if (newMembers) {
        newMembers.forEach((el) => {
          const getSelectedChildFamily = family.find((fmly) => fmly.id === el);
          updateMembers.families.push({
            familyId: getSelectedChildFamily.familyId,
            childId: el,
            familyGroups: getSelectedChildFamily.allGroupIds || [],
            isAdd: true
          });
        });
      }
      if (removeMembers) {
        removeMembers.forEach((el) => {
          const getSelectedChildFamily = allFilteredFamily?.find(
            (fmly) => fmly.id === el
          );
          if (getSelectedChildFamily) {
            updateMembers.families.push({
              familyId: getSelectedChildFamily.familyId,
              childId: el,
              familyGroups: getSelectedChildFamily.allGroupIds || [],
              isAdd: false
            });
          }
        });
      }

      // Update families according to selection;
      if (updateMembers.families.length) {
        firebase.updateFamilyByGroupId(updateMembers);
      }
      clearFilters();
        updateGroup({groupId: id, payload: finalData})
        .then(
          () => {
            dispatch(loaderStop());

            // PHASE2/CHANGES/SCHOOL
            getSchoolByID(currentGroup.school).then((school) => {
              if (school.groups > 0) {
                // PHASE2/CHANGES/SCHOOL

                const payload = {
                  schoolName: school?.schoolName,
                  country: school?.country,
                  timezone: school?.timezone,
                  state: school?.state,
                  notificationsTiming: school?.notificationsTiming,
                  freeCalendars: school?.freeCalendars,
                  notes: school?.notes,
                  websiteUrl: school?.websiteUrl,
                  formData: {
                    schoolShortName: school?.formData?.schoolShortName,
                    schoolLogo: {
                      storage_path: school?.formData?.schoolLogo?.storage_path,
                      url: school?.formData?.schoolLogo?.url,
                    },
                    schoolColor: school?.formData?.schoolColor,
                    urlPath: school?.formData?.urlPath,
                    category: school?.formData?.category,
                    firstName: school?.formData?.firstName,
                    email: school?.formData?.email,
                    isPublished: school?.formData?.isPublished,
                    schoolSupportPerson: {
                      storage_path:school?.formData?.schoolSupportPerson?.storage_path,
                      url: school?.formData?.schoolSupportPerson?.url
                    },
                  },
                }

                addSchoolFormDetailsOrUpdateSchool(school.id, payload)
                  .then(() => {})
                  .catch((error) => console.error(error));
              }
            });

            // PHASE2/CHANGES/SCHOOL
            getSchoolByID(finalData.school).then((school) => {
              if (school.groups > 0) {
                // PHASE2/CHANGES/SCHOOL

                const payload = {
                  schoolName: school?.schoolName,
                  country: school?.country,
                  timezone: school?.timezone,
                  state: school?.state,
                  notificationsTiming: school?.notificationsTiming,
                  freeCalendars: school?.freeCalendars,
                  notes: school?.notes,
                  websiteUrl: school?.websiteUrl,
                  formData: {
                    schoolShortName: school?.formData?.schoolShortName,
                    schoolLogo: {
                      storage_path: school?.formData?.schoolLogo?.storage_path,
                      url: school?.formData?.schoolLogo?.url,
                    },
                    schoolColor: school?.formData?.schoolColor,
                    urlPath: school?.formData?.urlPath,
                    category: school?.formData?.category,
                    firstName: school?.formData?.firstName,
                    email: school?.formData?.email,
                    isPublished: school?.formData?.isPublished,
                    schoolSupportPerson: {
                      storage_path:school?.formData?.schoolSupportPerson?.storage_path,
                      url: school?.formData?.schoolSupportPerson?.url
                    },
                  },
                }

                addSchoolFormDetailsOrUpdateSchool(school.id, payload)
                  .then(() => {})
                  .catch((error) => console.error(error));
              }
            });

            fetchGroup();
            onClose();
            dispatch(resetGroup());
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(addMessage('Group was not created. Please try again', MESSAGE_TYPES.ERROR));
            dispatch(loaderStop());
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Group was not created. Please try again', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    }
  };

  const onCloseForm = () => {
    const values = formValues;
    values['groupMembers'] = [];
    values['years'] = [];
    values['id'] = currentGroup.id;
    values['school'] = currentGroup.school;

    if (family && family.length) {
      const updatedGroupMembers = family.filter(
        (familyData) => familyData.selected
      );
      values['groupMembers'] = updatedGroupMembers.map((member) => member.id);
    }
    if (year && year.length) {
      const updatedYear = year.filter((yearData) => yearData.selected);
      values['years'] = updatedYear.map((year) => year.key);
    }

    const propertiesToCompare = Object.keys(values);
    const selectedObject1 = _.pick(values, propertiesToCompare);
    const selectedObject2 = _.pick(currentGroup, propertiesToCompare);

    const sameData = _.isEqual(selectedObject1, selectedObject2);
    if (sameData) {
      onClose();
    } else {
      setOpenModal({
        open: true,
        values: { values, id: currentGroup.id }
      });
    }
  };

  return (
    <RightDrawer
      isOpen={isOpen}
      onClose={() => {
        onCloseForm();
      }}
      width={520}
    >
      <div className={classes.drawerContent}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Formik
          initialValues={{
            groupName: currentGroup.groupName || '',
            school: currentGroup.school || '',
            year: currentGroup.year || '',
            isHide: currentGroup.isHide || false,
            category: currentGroup.category || '',
            groupType: currentGroup.groupType || 'Paid',
            member: currentGroup.member || ''
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setErrors }) => {
            values['groupMembers'] = [];
            values['years'] = [];

            values['id'] = currentGroup.id;
            values['school'] = currentGroup.school;

            const updatedHideShow = isHide;
            values['isHide'] = updatedHideShow;

            if (family && family.length) {
              const updatedGroupMembers = family.filter((familyData) => {
                return familyData.selected;
              });
              values['groupMembers'] = updatedGroupMembers.map((member) => {
                return member.id;
              });
              values.member = 'selected';
            }

            if (year && year.length) {
              const updatedYear = year.filter((yearData) => yearData.selected);
              values['years'] = updatedYear.map((year) => year.key);
            }
            const propertiesToCompare = Object.keys(values);
            const selectedObject1 = _.pick(values, propertiesToCompare);
            const selectedObject2 = _.pick(currentGroup, propertiesToCompare);

            const sameData = _.isEqual(selectedObject1, selectedObject2);
            if (sameData) {
              onClose();
            } else {
              saveUpdated(values, currentGroup.id, setErrors);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            handleLoad,
            setFieldValue,
            setErrors
          }) => {
            const checkSchoolGroupEmpty =
              _.isEmpty(values.groupName) || _.isEmpty(values.school);
            let isSubmitButtonDisabled =
              checkSchoolGroupEmpty ||
              (!_.isEmpty(touched) &&
                !checkSchoolGroupEmpty &&
                !_.isEmpty(errors));
            setFormValues(values);
            return (
              <form noValidate onSubmit={handleSubmit} onLoad={handleLoad}>
                <React.Fragment>
                  <div className={classes.headingContainer}>
                    <span className={classes.mainHeading}>Edit Group</span>
                    <div className={classes.saveButtonContainer}>
                      <Button
                        variant="contained"
                        color={isSubmitButtonDisabled ? 'secondary' : 'primary'}
                        className={classes.siderSave}
                        onClick={handleSubmit}
                        disabled={Boolean(
                          values.school &&
                            !(family.length > 1 && year.length > 1)
                        )}
                      >
                        Save
                      </Button>
                      <Button
                        aria-controls="delete-menu"
                        aria-haspopup="true"
                        onClick={handleOpenDeleteMenu}
                        className={classes.moreVertButton}
                      >
                        <MoreVert />
                        <Menu
                          id="delete-menu"
                          anchorEl={openDeleteMenu}
                          open={Boolean(openDeleteMenu)}
                          onClose={() => setOpenDeleteMenu(null)}
                          className={classes.popoverClass}
                        >
                          <MenuItem
                            onClick={() => {
                              setOpenDeleteMenu(null);
                              onMenuClose();
                            }}
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                      </Button>
                    </div>
                  </div>
                  <Divider variant="middle" className={classes.dividerClass} />
                  <div className={classes.formMargin}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormControl
                          component="fieldset"
                          style={{ width: '100%' }}
                        >
                          <TextField
                            label="Group Name"
                            variant="outlined"
                            name="groupName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(
                              errors.groupName ||
                                (touched.groupName && errors.groupName)
                            )}
                            helperText={
                              errors.groupName ||
                              (touched.groupName && errors.groupName)
                            }
                            value={values.groupName}
                            className={classes.controlBox}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <RadioGroup
                          row
                          aria-label="groupType"
                          name="groupType"
                          onChange={() => {}}
                          value={values?.groupType}
                        >
                          <FormControlLabel
                            value={'Paid'}
                            control={
                              <Radio
                                classes={{
                                  root: classes.radio,
                                  checked: classes.checked
                                }}
                                disabled
                              />
                            }
                            label={'Paid'}
                          />
                          <FormControlLabel
                            value={'Free'}
                            control={
                              <Radio
                                classes={{
                                  root: classes.radio,
                                  checked: classes.checked
                                }}
                                disabled
                              />
                            }
                            label={'Free'}
                          />
                        </RadioGroup>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                          disabled
                        >
                          <InputLabel id="country-select-outlined-label">
                            School
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={values.school}
                            onChange={(e) => {}}
                            onBlur={handleBlur}
                            error={Boolean(touched.school && errors.school)}
                            name="school"
                            label="School"
                            disabled
                          >
                            <MenuItem value="">
                              <em>School</em>
                            </MenuItem>
                            {schools
                              .sort((a, b) => {
                                return a?.name?.toLowerCase() >
                                  b?.name?.toLowerCase()
                                  ? 1
                                  : -1;
                              })
                              .map(function (object) {
                                return (
                                  <MenuItem key={object.id} value={object.id}>
                                    {object.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid> */}

                      {year.length > 1 ? (
                        <Grid item xs={12}>
                          <FormControl
                            component="fieldset"
                            variant="outlined"
                            className={classes.controlBox}
                            classes={{ root: classes.formControl }}
                            error={!!errors.year}
                            style={{
                              width: '57.7%',
                              height: 'auto',
                              borderRadius: 3,
                              border: 1,
                              borderStyle: 'solid',
                              borderColor: colors.primaryLightGrey
                            }}
                          >
                            <FormLabel
                              component="legend"
                              className={classes.checkLabel}
                            >
                              Years
                            </FormLabel>
                            <FormGroup>
                              {year.map((yearData, index) => {
                                if (yearData?.key === undefined) {
                                  return null;
                                }
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={yearData.selected}
                                        onChange={(e) =>
                                          handleChangeYear(
                                            e,
                                            index,
                                            setFieldValue
                                          )
                                        }
                                        name={yearData.key}
                                        className={classes.controlCheck}
                                        classes={{
                                          root: classes.checkbox,
                                          checked: classes.checked
                                        }}
                                      />
                                    }
                                    className={classes.labelText}
                                    label={
                                      yearData.key === 'all'
                                        ? 'Select All'
                                        : yearData.key
                                    }
                                    key={`year-${yearData.key}-${yearData.selected}`}
                                  />
                                );
                              })}
                            </FormGroup>
                            {!!errors.year && (
                              <FormHelperText id="year-text">
                                {errors.year}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      {family.length > 1 ? (
                        <Grid item xs={12}>
                          <FormControl
                            component="fieldset"
                            variant="outlined"
                            className={classes.controlBox}
                            classes={{ root: classes.formControl }}
                            error={!!errors.member}
                            style={{
                              height: 160,
                              minHeight: 160,
                              borderRadius: 3,
                              border: 1,
                              borderStyle: 'solid',
                              borderColor: colors.primaryLightGrey
                            }}
                          >
                            <FormLabel
                              component="legend"
                              className={classes.checkLabel}
                            >
                              Members
                            </FormLabel>
                            <FormGroup
                              classes={{ root: classes.formControl }}
                              style={{
                                overflowY: 'scroll',
                                overflowX: 'hidden',
                                display: 'flex',
                                flexDirection: 'row'
                              }}
                            >
                              {family.map((element, index) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={element.selected}
                                        onChange={(e) =>
                                          handleChangeMember(
                                            e,
                                            index,
                                            setFieldValue
                                          )
                                        }
                                        name={element.id}
                                        className={classes.controlCheck}
                                        classes={{
                                          root: classes.checkbox,
                                          checked: classes.checked
                                        }}
                                      />
                                    }
                                    key={`${index}-${element.name}`}
                                    className={classes.labelText}
                                    label={
                                      index === 0
                                        ? 'Select All'
                                        : getCheckboxLabel(element.name)
                                    }
                                  />
                                );
                              })}
                            </FormGroup>
                            {!!errors.member && (
                              <FormHelperText
                                id="member-text"
                                style={{ marginTop: 10 }}
                              >
                                {errors.member}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{ display: 'flex', margin: '25px 0' }}
                    >
                      <ToggleSwitchButton
                        checked={isHide}
                        handleChecked={() => {
                          setIsHide(!isHide);
                        }}
                      />
                      <Typography style={{ margin: '10px 0 0 20px' }}>
                        Do not show on registration form
                      </Typography>
                    </Grid>
                  </div>
                </React.Fragment>
                {isOpenModal.open && (
                  <Modal isOpen={!!isOpenModal.open} onClose={handleClose}>
                    <div className={classes.modalBox}>
                      <Typography className={classes.modalTitle}>
                        Save your changes?
                      </Typography>
                      <div className={classes.modalContent}>
                        <Typography className={classes.modalSubTitle}>
                          Your changes will be lost if you don't save them.
                        </Typography>
                      </div>
                      <div className={classes.modalActions}>
                        <Button
                          onClick={() => {
                            setOpenModal({ open: false, values: null });
                            saveUpdated(
                              isOpenModal.values.values,
                              isOpenModal.values.id,
                              setErrors
                            );
                          }}
                          color="primary"
                          variant="contained"
                          size="large"
                          startIcon={
                            isLoading && (
                              <CircularProgress color="secondary" size={25} />
                            )
                          }
                          className={classes.primaryButton}
                        >
                          {!isLoading && 'Save Changes'}
                        </Button>
                        <Button
                          onClick={() => {
                            handleClose();
                            onClose();
                          }}
                          variant="contained"
                          size="large"
                          className={classes.greyButton}
                        >
                          Don't Save
                        </Button>
                      </div>
                    </div>
                  </Modal>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
    </RightDrawer>
  );
}

export default withFirebase(PaidEditGroup);
