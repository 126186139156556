import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function downloadArrowIcon({ color, ...props }) {
return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
    <g clipPath="url(#cm9outxl3a)" stroke="#474747" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12.963 9.26 10 12.222 7.037 9.258M10 3.333v8.89M16.667 13.703a2.963 2.963 0 0 1-2.964 2.964H6.297a2.963 2.963 0 0 1-2.964-2.964"/>
    </g>
    <defs>
        <clipPath id="cm9outxl3a">
            <path fill="#fff" d="M0 0h20v20H0z"/>
        </clipPath>
    </defs>
  </SvgIcon>
)
}

export default downloadArrowIcon;