// Return Years like [0,1,2,3, ...]
export const getYearsInNumber = (arr) => {
  return arr.map((item) => {
    if (item === 'Kindy' || item == '0') {
      return '0';
    } else if (item === 'Pre K' || item == '-1') {
      return '-1';
    } else {
      return item.slice(5, item.length) || item;
    }
  });
}