import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Typography,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Link
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { colors, routes } from 'src/utils/constant';
import { withFirebase } from 'src/utils/firebase';
import Dashboard from '../layout/Dashboard';
import TableList from 'src/components/TableList';
import { EDIT_ICON } from 'src/assets';
import { CalendarIcon } from 'src/assets/Icons';
import {
  loaderStart,
  loaderStop,
  addMessage,
  setFamilyOrSchoolPreserveEntryData
} from 'src/redux/actions/appActions';
import { setFamily, resetFamily } from 'src/redux/actions/familyActions';
import CreateGroupCalenderEntry from './CreateGroupCalenderEntry';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { OpenInNew } from '@material-ui/icons';
import CreateSingleFamilyEntry from './CreateSingleFamilyEntry';
import { getSchools } from 'src/services/schools';
import { MESSAGE_TYPES } from 'src/constants/common';

const columns = [
  {
    id: 'familySurname',
    label: 'Surname',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '12%'
  },
  {
    id: 'parents',
    label: 'Parents',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '22%'
  },
  {
    id: 'childs',
    label: 'Children',
    align: 'left',
    format: 'icon',
    onEach: true,
    icon: EDIT_ICON,
    iconPosition: 'before',
    hasSort: true,
    width: '22%'
  },
  {
    id: 'freeHost',
    label: 'Free Host?',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '16%'
  },
  // {
  //   id: 'planType',
  //   label: 'Membership',
  //   minWidth: 170,
  //   setLabelStyle: true,
  //   iconPosition: 'before',
  //   hasSort: true,
  //   width: '16%'
  // },
  {
    id: 'schools',
    label: 'School',
    // minWidth: 170,
    setLabelStyle: true,
    iconPosition: 'before',
    hasSort: true
    // width: '14%'
  },
  {
    id: 'action',
    label: 'Action',
    iconPosition: 'center'
  }
];

const useStyles = makeStyles({
  tableRow: {
    height: '72px',
    '&:hover': {
      backgroundColor: `rgba(244, 245, 247, 0.51) !important`,
      cursor: 'pointer'
    }
  },
  dataTableCell: {
    color: colors.primaryDark,
    padding: 8,
    '&:first-child': {
      paddingLeft: 52
    }
  },
  actionButton: {
    padding: '8px 17px',
    fontSize: 16,
    lineHeight: '24px',
    float: 'right'
  },
  startIcon: {
    marginRight: 12
  },
  surname: {
    fontWeight: 500
  },
  pauseStatus: {
    fontSize: 14,
    backgroundColor: '#bd0000',
    color: '#ffffff',
    padding: '0 5px'
  },
  freeStatus: {
    fontSize: 14,
    backgroundColor: '#e8f1f5',
    color: '#2f7d91',
    padding: '0 5px'
  },
  paidStatus: {
    fontSize: 14,
    backgroundColor: '#e8f5e9',
    color: '#348f37',
    padding: '2px 5px'
  },
  actionIcon: {
    height: 50,
    width: 50,
    padding: 10,
    cursor: 'pointer',
    margin: 'auto',
    '&:hover': {
      backgroundColor: colors.primary,
      borderRadius: 50,
      '& svg g': {
        fill: '#ffffff'
      }
    }
  },

  redirectionClass: {
    padding: '4px 10px',
    fontSize: 14,
    textDecoration: 'none',
    color: colors.primaryDark,
    borderRadius: 13.5,
    '&:hover': {
      backgroundColor: colors.primary,
      borderRadius: 13.5,
      boxShadow: `0 2px 12px 0 rgba(0, 0, 0, 0.18)`,
      cursor: 'pointer',
      padding: '4px 10px',
      fontSize: 14,
      color: colors.white
    }
  }
});

function CellItem({ row, column, schoolData }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const value = row[column.id];
  const classes = useStyles();
  if (column.id === 'familySurname') {
    return (
      <span
        className={classes.surname}
        onClick={() => {
          dispatch(setFamily(row));
          history.push({
            pathname: routes.familyCalendarPaid + '/' + row['id'],
            openRightDrawer: true
          });
        }}
      >
        {value}
      </span>
    );
  }
  if (column.id === 'parents') {
    return value.map((p) => p.firstName).join(' | ');
  }
  if (column.id === 'childs') {
    const childsData = value.map((p, index) => {
      return (
        <span key={`${p?.firstName}-${index}`} style={{ marginRight: 8 }}>
          <Button
            className={classes.redirectionClass}
            startIcon={<OpenInNew />}
            onClick={() =>
              p.schoolPortal ? window.open(p.schoolPortal, '_blank') : null
            }
            component={Link}
          >
            {p.firstName}
          </Button>
        </span>
      );
    });
    return childsData;
  }
  if (column.id === 'freeHost') {
    return (value === 'true' && 'Host') || '';
  }

  if (column.id === 'schools') {
    let childSchools = row.childs.map((child) => child.school);
    childSchools = childSchools.map(
      (id) => schoolData.find((school) => school.id === id)?.name
    );
    const data = _.compact(childSchools);

    return (
      <div>
        {data.map((schools,index) => {
          return <div key={`${schools}-${index}`}>{schools}</div>;
        })}
      </div>
    );
  }

  if (column.id === 'action') {
    return (
      <IconButton
        aria-label="Create Entry"
        className={classes.actionIcon}
        onClick={() => {
          dispatch(setFamily(row));
          history.push({
            pathname: routes.familyCalendarPaid + '/' + row['id'],
            openRightDrawer: true
          });
        }}
      >
        <CalendarIcon />
      </IconButton>
    );
  }
  return '';
}

function TableRowItem({ row, index, columns, schoolData }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      className={classes.tableRow}
      onClick={(e) => {
        if (e.target.tagName.toLowerCase() === 'td') {
          dispatch(setFamily(row));
          history.push({
            pathname: routes.familyCalendarPaid + '/' + row['id'],
            openRightDrawer: false
          });
        }
      }}
    >
      {columns.map((column) => {
        return (
          <TableCell
            key={column.id}
            align={column.align}
            className={classes.dataTableCell}
            style={{ width: column.width }}
          >
            <CellItem row={row} column={column} schoolData={schoolData} />
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function FamiliesListViewPaid({ firebase }) {
  const [families, setFamily] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [schoolData, setSchoolData] = useState([]);
  const [allFamilies, setAllFamily] = useState([]);
  const [isOpenBottom, setOpenBottom] = useState({ open: false, value: null });
  const [isSingleFamilyOpen, setSingleFamilyOpen] = useState({
    open: false,
    value: null
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFamilyOrSchoolPreserveEntryData(null));
    dispatch(resetFamily());
    dispatch(loaderStart());
    setIsDataLoading(true);
    firebase
      .getPaidFamilies()
      .then(function (querySnapshot) {
        let FamiliesData = [];

        querySnapshot.forEach(function (doc) {
          if (doc.data()?.planType !== 'Free') {
            FamiliesData.push({ ...doc.data(), ...{ id: doc.id } });
          }
        });
        setFamily(FamiliesData);
        setAllFamily(FamiliesData);
        setIsDataLoading(false);

        dispatch(loaderStop());
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
        setIsDataLoading(false);
      });
  }, [firebase, setFamily, dispatch]);

  useEffect(() => {
    // PHASE2/CHANGES/SCHOOL

    getSchools()
      .then((result) => {
        const schools = [];
        result.forEach((school) => {
          schools.push({
            id: school.id,
            name: school.schoolName,
            isFree: school.freeCalendars.length ? true : false,
            years: school.freeCalendars,
            categories: school.categories
          });
        });
        setSchoolData(schools);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const searchChangeHandler = (e) => {
    const searchedText = e.target.value;
    const updatedFamily = allFamilies.filter((family) => {
      return family.familySurname
        .toLowerCase()
        .includes(searchedText.toLowerCase().trim());
    });
    setFamily(updatedFamily);
  };

  const schoolChangeHandler = (e) => {
    const searchedText = e.target.value;
    const updatedFamily = allFamilies.filter((family) => {
      const childSchools = family.childs.map((child) => child.school);
      if (searchedText === '') {
        return family.familySurname;
      }
      if (childSchools.includes(searchedText)) {
        return family.familySurname;
        // .toLowerCase()
        // .includes(searchedText.toLowerCase().trim());
      }
    });
    setFamily(updatedFamily);
  };

  return (
    <Dashboard>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20 }}
        >
          Families
          {/* <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.actionButton}
            startIcon={<AddIcon />}
            classes={{ startIcon: classes.startIcon }}
            onClick={() => history.push(routes.familyCreatePaid)}
          >
            Add New Family
          </Button> */}
        </Typography>
        <TableList
          columns={columns}
          rows={families}
          familyTypeCheck={true}
          searchPlaceholderText={'Search by surname'}
          searchChangeHandler={searchChangeHandler}
          schoolChangeHandlerFamilyPaid={schoolChangeHandler}
          pageTitle={'Families'}
          tableRow={(row, index) => (
            <>
              {row.planType !== 'Free' ? (
                <TableRowItem
                  key={index}
                  columns={columns}
                  row={row}
                  schoolData={schoolData}
                />
              ) : null}
            </>
          )}
          isDataLoadingForPaidFamily={isDataLoading}
        />
        {isOpenBottom.open && (
          <CreateGroupCalenderEntry
            isOpen={isOpenBottom.open}
            type={isOpenBottom.value}
            onClose={() => {
              setOpenBottom({ open: false, value: null });
            }}
          />
        )}
        {isSingleFamilyOpen.open && (
          <CreateSingleFamilyEntry
            isOpen={isSingleFamilyOpen.open}
            type={isSingleFamilyOpen.value}
            onClose={() => {
              setSingleFamilyOpen({ open: false, value: null });
            }}
          />
        )}
      </MuiPickersUtilsProvider>
    </Dashboard>
  );
}

export default withFirebase(FamiliesListViewPaid);
