import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import Typography from '@material-ui/core/Typography';
import Dashboard from 'src/views/layout/Dashboard';
import StepperComponent from 'src/components/StepperComponent';
import AddMember from './AddMember';
import AddFamilyHost from './AddFamilyHost';
import FILE from 'src/assets/file-plus.svg';
import POCKET from 'src/assets/pocket.svg';
import USER from 'src/assets/user.svg';
import { withFirebase, FIREBASE_API_URL } from 'src/utils/firebase';
import { routes } from 'src/utils/constant';
import { encryptSHA256 } from 'src/utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import { FamiliesIcon, RightArrow } from 'src/assets/Icons';
import {
  loaderStop,
  loaderStart,
  addMessage
} from 'src/redux/actions/appActions';
import { setFamily, resetFamily } from 'src/redux/actions/familyActions';
import { useHistory } from 'react-router-dom';
import { freeFamilyChildStatus } from '../../utils/constant';
import { useEffect } from 'react';
import AddChildren from './AddChildren';
import {
  API_CHILD_UPDATE_GROUP
} from 'src/utils/api';
import makeApiRequest from 'src/utils/axios';
import { getGroups } from 'src/services/groups';
import { createMemberStyles } from 'src/styles/membersStyle/createMemberStyles';
import { MESSAGE_TYPES } from 'src/constants/common';


function getStepContent(step, getFormData) {
  switch (step) {
    case 0:
      return <AddMember submitForm={getFormData} />;
    case 1:
      return <AddChildren submitForm={getFormData} />;
    // case 2:
    //   return <AddFamilyHost submitForm={getFormData} />;
    default:
      return 'Unknown step';
  }
}

function CreateMember({ firebase }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = createMemberStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [createid, setCreateid] = useState('');
  const familySchema = useSelector((state) => state.family.currentFamily);

  const ColorlibStepIcon = (props) => {
    const { active } = props;
    const icons = {
      1: (
        <div
          className={classes.iconStyle}
          style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
        >
          <img src={FILE} alt="Search" className={classes.image} />
        </div>
      ),
      2: (
        <div
          className={classes.iconStyle}
          style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
        >
          <img src={USER} alt="Search" className={classes.image} />
        </div>
      ),
      3: (
        <div
          className={classes.iconStyle}
          style={{ backgroundColor: active ? '#8bc517' : '#bfc5d1' }}
        >
          {familySchema.planType === 'Free' ? (
            <CalendarTodayOutlinedIcon classes={{ root: classes.closeIcon }} />
          ) : (
            <img src={POCKET} alt="Search" className={classes.image} />
          )}
        </div>
      )
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  };

  const addChild = async (data) => {
    const { child } = data;
    const username = `${child.userName}@mywhanau-user.com`;
    const password = child.password;

    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email_address: username,
        password: password
      })
    };
    return await fetch(`${FIREBASE_API_URL}/createUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result
      })
      .then(
        async (result) => {
          if (result.user) {
            const userID = result.user.uid;
            const userObject = {
              role: 'Child',
              userName: child.userName,
              email: username,
              child: child.id,
              isViewedOnboard: false
            };
            await firebase.addUser(userObject, userID);
            return userID;
          } else {
            // dispatch(loaderStop());
            if (result.code === 'auth/email-already-exists') {
              dispatch(
                addMessage(
                  `${child.userName} username is already in use by another account.`
                )
              );
            } else if (result.code === 'auth/invalid-email') {
              dispatch(addMessage(`${child.userName} username was not valid`));
            } else {
              // dispatch(
              //   addMessage(
              //     `${child.userName} user was  not created. Please try again`
              //   )
              // );
            }
          }
        },
        (error) => {
          console.log('Error: ', error);
          dispatch(addMessage('User was  not created. Please try again', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      )
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('User was not created. Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  };

  const getComparedAndDiffrece = (data, compArray = []) => {
    return data.filter((el) => !compArray.includes(el));
  };

  const getFormData = async (formData, status) => {
    if (activeStep < 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      dispatch(setFamily({ ...familySchema, ...formData }));
    } else if (activeStep === 1) {
      const finalData = {
        ...familySchema,
        ...formData,
        ...{ createdDate: moment.utc().format() }
      };
      dispatch(loaderStart());
      // const groupData = [];

        // getGroups()
        // .then(function (result) {
        //   result.data.forEach(function (item) {
        //     if (item.groupName) {
        //       groupData.push(item);
        //     }
        //   });
        // })
        // .catch(function (error) {
        //   console.log('Error getting documents: ', error);
        //   dispatch(addMessage('Server connection issue. Please refresh'));
        //   dispatch(loaderStop());
        // });

      const username = `${finalData.userName}@mywhanau-user.com`;
      const password = finalData.password;
      let updatedChildData = finalData.childs || [];
      let filterChildData = [];
      let pushRecord = {
        members: []
      };
      if (finalData.childs.length && familySchema.planType !== 'Free') {
        const childArray = [];
        for (const child of finalData.childs) {
          const childData = {
            child
          };
          const data = await addChild(childData);
          childArray.push(data);
        }
        updatedChildData = finalData.childs.map((child, index) => {
          let childObj = {
            ...child,
            userId: childArray[index]
          };
          if (!!child.password) {
            childObj = {
              ...childObj,
              password: '',
              encryptedPassword: encryptSHA256(childObj.password)
            };
          }
          return childArray[index] && childObj;
        });
      } else {
        // update groupData if child have groups
        if (finalData.childs.length) {
          finalData.childs.forEach((child) => {
            let mergeNewClassAndGroups = _.compact([child.group, child.groups]);
            mergeNewClassAndGroups = _.uniq(
              [].concat.apply([], mergeNewClassAndGroups)
            );

            // get new groups id which is updated from old selection
            const newGroupReceived = getComparedAndDiffrece(
              mergeNewClassAndGroups,
              []
            );

            const allGroupIds = _.compact([child.group, child.groups]);

            if (newGroupReceived) {
              newGroupReceived.forEach((el) => {
                pushRecord.members.push({
                  childId: child.id,
                  groupId: el,
                  isAdd: true
                });
              });
            }
          });
        }
      }

      filterChildData = updatedChildData.filter((child) => {
        return child && child;
      });
      let updatedFinalData = {
        ...finalData,
        childs: filterChildData
      };

      if (!!password) {
        updatedFinalData = {
          ...updatedFinalData,
          password: '',
          encryptedPassword: encryptSHA256(password)
        };
      }

      let schoolData = [];
      if (updatedFinalData.childs.length && familySchema.planType !== 'Free') {
        schoolData = updatedFinalData.childs.reduce((v, { school }) => {
          v.push(school);
          return v;
        }, []);
      }
      // else if (familySchema.planType === 'Free') {
      //   updatedFinalData.childs = [];
      // }
      updatedFinalData.childsSchools = schoolData;

      const parents = updatedFinalData.parents;
      if (parents && parents.length > 0) {
        updatedFinalData.primaryEmail = parents[0].emailAddress;
      }

      if (finalData.userName !== '' && password !== '') {
        const requestOptions = {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email_address: username,
            password: password
          })
        };
      }
      if (status === freeFamilyChildStatus.INVITED) {
        await firebase
          .setFamily(updatedFinalData, freeFamilyChildStatus.INVITED)
          .then(
            async (familyResult) => {

              // Update group entries
              if(pushRecord.members.length > 0){
              const { data, error } = await makeApiRequest({
                url: API_CHILD_UPDATE_GROUP,
                method: 'POST',
                data: pushRecord
              });
              if(error){
                throw(error);
              }
              }
              setCreateid(familyResult.id);
              if (updatedFinalData.childs.length) {
                updatedFinalData.childs.map((child) => {
                  if (child.userName !== '' && child.password !== '') {
                    let childObject = {
                      ...child,
                      role: 'Child',
                      userName: child.userName,
                      email: `${child.userName}@mywhanau-user.com`,
                      child: child.id,
                      family: familyResult.id
                    };
                    if (!!child.password) {
                      childObject = {
                        ...childObject,
                        password: '',
                        isViewedOnboard: false,
                        encryptedPassword: encryptSHA256(childObject.password)
                      };
                    }
                    if (familySchema.planType !== 'Free') {
                      firebase.addUser(childObject, child.userId);
                    }
                  }
                  return child;
                });
              }
              dispatch(setFamily(updatedFinalData))
              dispatch(loaderStop());
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            },
            (error) => {
              console.log('Error: ', error);
              dispatch(addMessage('Member was not created. Please try again', MESSAGE_TYPES.ERROR));
              dispatch(loaderStop());
            }
          )
          .catch((error) => {
            console.log('Error: ', error);
            dispatch(addMessage('Member was not created. Please try again', MESSAGE_TYPES.ERROR));
            dispatch(loaderStop());
          });
      } else if (status === freeFamilyChildStatus.NEW) {
        await firebase
          .setFamily(updatedFinalData, freeFamilyChildStatus.NEW)
          .then(
            async (familyResult) => {
              setCreateid(familyResult.id);
              if (updatedFinalData.childs.length) {
                updatedFinalData.childs.map((child) => {
                  if (
                    child.userName !== '' &&
                    child.password !== '' &&
                    child.id === ''
                  ) {
                    let childObject = {
                      ...child,
                      role: 'Child',
                      userName: child.userName,
                      email: `${child.userName}@mywhanau-user.com`,
                      child: child.id,
                      family: familyResult.id
                    };
                    if (!!child.password) {
                      childObject = {
                        ...childObject,
                        password: '',
                        isViewedOnboard: false,
                        encryptedPassword: encryptSHA256(childObject.password)
                      };
                    }
                    if (familySchema.planType !== 'Free') {
                      firebase.addUser(childObject, child.userId);
                    }
                  }
                  return child;
                });
              }
              dispatch(setFamily(updatedFinalData))
              dispatch(loaderStop());
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            },
            (error) => {
              console.log('Error: ', error);
              dispatch(addMessage('Family was not created. Please try again', MESSAGE_TYPES.ERROR));
              dispatch(loaderStop());
            }
          )
          .catch((error) => {
            console.log('Error: ', error);
            dispatch(addMessage('Family was not created. Please try again', MESSAGE_TYPES.ERROR));
            dispatch(loaderStop());
          });
      } else {
        await firebase
          .setFamily(updatedFinalData, freeFamilyChildStatus.ACTIVE)
          .then(
            async (familyResult) => {
              if (
                finalData.userName !== '' &&
                finalData.password !== '' &&
                (!finalData.userId || finalData.userId === '')
              ) {
                const requestOptions = {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    username: finalData.userName,
                    password: finalData.password,
                    isAdmin: true
                  })
                };

                await fetch(
                  `${process.env.REACT_APP_FAMILY}/family/${familyResult.id}/createUser`,
                  requestOptions
                )
                  .then((response) => {
                    const result = response.json();
                    if (!response.ok) {
                      throw new Error(result?.message);
                    }
                    return result
                  })
                  .then(
                    async (result) => {
                      if (result.user) {
                        const userID = result.user.uid;
                        updatedFinalData.userId = userID;
                      } else {
                        dispatch(loaderStop());
                        if (result.code === 'auth/email-already-exists') {
                          dispatch(
                            addMessage(
                              `${finalData.userName} username is already in use by another account.`
                            )
                          );
                        } else if (result.code === 'auth/invalid-email') {
                          dispatch(
                            addMessage(
                              `${finalData.userName} username was not valid`
                            )
                          );
                        }
                      }
                    },
                    (error) => {
                      console.log('Error: ', error);
                      dispatch(
                        addMessage('User was not created. Please try again', MESSAGE_TYPES.ERROR)
                      );
                      dispatch(loaderStop());
                    }
                  )
                  .catch((error) => {
                    console.log('Error: ', error);
                    dispatch(
                      addMessage('User was not created. Please try again', MESSAGE_TYPES.ERROR)
                    );
                    dispatch(loaderStop());
                  });
              }
              setCreateid(familyResult.id);
              if (updatedFinalData.childs.length) {
                updatedFinalData.childs.map((child) => {
                  if (child.userName !== '' && child.password !== '') {
                    let childObject = {
                      ...child,
                      role: 'Child',
                      userName: child.userName,
                      email: `${child.userName}@mywhanau-user.com`,
                      child: child.id,
                      family: familyResult.id
                    };
                    if (!!child.password) {
                      childObject = {
                        ...childObject,
                        password: '',
                        isViewedOnboard: false,
                        encryptedPassword: encryptSHA256(childObject.password)
                      };
                    }
                    if (familySchema.planType !== 'Free') {
                      firebase.addUser(childObject, child.userId);
                    }
                  }
                  return child;
                });
              }
              firebase
              .getFamilyByID(familyResult.id)
              .then(
                async (doc) => {
                  let familyData = doc.data();
                  dispatch(setFamily(familyData))
                })
                .catch(err => {
                  console.log('Error: ', err);
                  dispatch(loaderStop());
                })
              dispatch(loaderStop());
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            },
            (error) => {
              console.log('Error: ', error);
              dispatch(addMessage('Member was not created. Please try again', MESSAGE_TYPES.ERROR));
              dispatch(loaderStop());
            }
          )
          .catch((error) => {
            console.log('Error: ', error);
            dispatch(addMessage('Member was not created. Please try again', MESSAGE_TYPES.ERROR));
            dispatch(loaderStop());
          });
      }
    }
  };

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [setActiveStep]);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [setActiveStep]);

  const handleReset = useCallback(() => {
    dispatch(resetFamily());
    setActiveStep(0);
  }, [setActiveStep]);

  return (
    <Dashboard>
      {activeStep < 2 && (
        <>
          <div>
            <Typography
              variant="body1"
              className={classes.bredthcrumbTitle}
              onClick={() => history && history.push(routes.membersActive)}
            >
              Members
            </Typography>
            <RightArrow
              viewBox="0 0 24 24"
              height={24}
              width={24}
              className={classes.iconStyleBredthcrumb}
            />
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.familySurnameStyle}
          >
            Add a new member
          </Typography>
        </>
      )}
      <div
        className={clsx({
          [classes.rootContainer]: activeStep < 3
        })}
      >
        <StepperComponent
          steps={['Account', 'Children']}
          showSteps={Boolean(familySchema.planType)}
          getStepContent={(step) => getStepContent(step, getFormData)}
          stepClick={(step) => setActiveStep(step)}
          title={'Member'}
          colorlibStepIcon={ColorlibStepIcon}
          successTitle={'Member Created'}
          subTitle={'View Member'}
          iconImage={<FamiliesIcon />}
          buttonLabel={'Create New Member'}
          listUrl={
            familySchema.planType === 'Free'
              ? routes.memberAccount + '/' + createid
              : routes.familyCalendarPaid + '/' + createid
          }
          createUrl={
            familySchema.planType === 'Free'
              ? routes.memberCreate
              : routes.familyCreatePaid
          }
          activeStep={activeStep}
          handleNext={handleNext}
          handleBack={handleBack}
          handleReset={handleReset}
          isCancelDisable={activeStep !== 0}
        />
      </div>
    </Dashboard>
  );
}
export default withFirebase(CreateMember);
