import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  Fragment,
  useCallback
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withFirebase } from 'src/utils/firebase';
import { makeStyles } from '@material-ui/core/styles';
import { colors, schoolFileType } from 'src/utils/constant';
import { isValidFileType, setSchoolFileName } from 'src/utils/functions';
import {
  TextField,
  Paper,
  Divider,
  Typography,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  FormControl,
  Button,
  FormLabel,
  Badge,
  CircularProgress,
  Avatar,
  InputAdornment,
  FormControlLabel,
  Link,
  Backdrop
} from '@material-ui/core';
import { ErrorMessage, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import copy from 'copy-to-clipboard';
import {
  loaderStop,
  addMessage,
  loaderStart
} from 'src/redux/actions/appActions';
import Dashboard from '../layout/Dashboard';
import TableList from 'src/components/TableList';
import ToggleSwitchButton from 'src/components/ToggleSwitchButton';
import { counties, timeZones, routes } from 'src/utils/constant';
import { RightArrow } from 'src/assets/Icons';
import { HandBook, Edit } from 'src/assets';
import PlaceholderImage from '../../assets/placeholder.png';
import { setSchool } from 'src/redux/actions/schoolActions';
import Modal from 'src/components/Modal';
import RightDrawer from 'src/components/RightDrawer';
import ScanningUploadFileModal from 'src/components/ScanningUploadFileModal';
import api_icon from 'src/assets/apiIcon.svg';
import schoolBytes from 'src/assets/schoolByte2.webp';
import NavTab from './NavTab';
import studentReportingIcon from 'src/assets/icn-student-reporting.svg';
import { DEFAULT_SUPPORT_PERSON, MESSAGE_TYPES } from 'src/constants/common';
import {
  addSchoolFormDetailsOrUpdateSchool,
  deleteSchoolForm,
  getSchoolByID,
  isUrlAvailable
} from 'src/services/schools';
import { formStyles } from 'src/styles/schoolStyle/formStyles';


function Form({ firebase, ...props }) {
  const classes = formStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const [openStudentReportPopUp, setOpenStudentReportPopUp] = useState(false);
  const prefillSchoolData = useSelector((state) => state.school.currentSchool);
  const defaultSupportPerson = {
    url: 'https://firebasestorage.googleapis.com/v0/b/mywhanau-admin.appspot.com/o/schools%2FDefault-supportPerson.jpg?alt=media&token=45432f70-f0c6-4a64-a051-bc2c4007c3bd',
    storage_path: 'schools/Default-supportPerson.jpg'
  };
  const [isCurrentSchoolLoading, setCurrentSchoolLoading] = useState(false);

  const hasSchoolLogo = Object.values(prefillSchoolData?.formData?.schoolLogo).filter(val => val).length ? true : false
  const [schoolLogoObject, setSchoolLogoObject] = useState(
    hasSchoolLogo ? prefillSchoolData?.formData?.schoolLogo : null
  );
  const hasSupportPerson = Object.values(prefillSchoolData?.formData?.schoolSupportPerson).filter(val => val).length ? true : false
  const [schoolSupportPersonObject, setSchoolSupportPersonObject] = useState(
    hasSupportPerson ? prefillSchoolData?.formData?.schoolSupportPerson : defaultSupportPerson
  );

  const isLoading = useSelector((state) => state.app.isLoading);
  const fileInput = useRef(null);
  const schoolID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  const { schoolName } = useMemo(() => {
    if (prefillSchoolData) {
      return {
        schoolName: prefillSchoolData.schoolName,
        id: prefillSchoolData.id
      };
    }
    return {
      schoolName: '',
      id: ''
    };
  }, [prefillSchoolData]);

  const {
    isEdit = false,
    accountFlag,
    handleCancelDisable,
    setHandleCancelClick,
    handleCancelClick
  } = props;
  const [isSave, setSave] = useState(false);
  const [memberData, setMemberData] = useState(false);
  const [defaultNote, setDefaultNote] = useState('');
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [unSavedField, setUnSavedField] = useState(false);
  const [schoolColor, setSchoolColor] = useState(
    prefillSchoolData?.formData?.schoolColor || '#1D1D1D'
  );

  const [uploadschoolLogo, setuploadschoolLogo] = useState();

  const schoolFormCategory = prefillSchoolData?.formData?.category?.filter(
    (item) => item.name !== 'Staff'
  );
  const schoolCategories = prefillSchoolData?.categories?.filter(
    (item) => item.name !== 'Staff'
  );

  const [category, setCategory] = useState(
    schoolFormCategory.length ? schoolFormCategory : schoolCategories
  );
  const [urlPath, setURLPath] = useState(
    prefillSchoolData?.formData?.urlPath || ''
  );
  const [initialValues, setInitialValue] = useState({
    schoolName: prefillSchoolData.schoolName || schoolName,
    schoolShortName: prefillSchoolData?.formData?.schoolShortName || '',
    firstName: prefillSchoolData?.formData?.firstName || 'Michelle',
    email: prefillSchoolData?.formData?.email || 'care@myfamilyhand.com.au'
  });
  const [formKey, setFormKey] = useState(Math.random());
  const [isOpenChangeURL, setIsOpenChangeURL] = useState(false);
  const [isOpenPublishModal, setIsOpenPublishModal] = useState(false);
  const [isOpenUnpublishModal, setIsOpenUnpublishModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [deleteText, setDeleteText] = useState('');
  const [unPublishText, setUnPublishText] = useState('');
  const [publishText, setPublishText] = useState('');
  const [urlText, setURLText] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [schoolError, setSchoolError] = useState(null);
  const [isPublished, setIsPublished] = useState(
    prefillSchoolData?.formData?.isPublished
  );
  const [fileUploadModal, setFileUploadModal] = useState({
    isModalOpen: false,
    fileUploadState: 0
  });

  const familyID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  const onCloseForm = () => {
    const defaultData = draftToHtml(
      convertToRaw(defaultNote.getCurrentContent())
    );
    const memberNotes = memberData.notes;
    if (defaultData === memberNotes) {
      // handleClose();
      onClose();
    } else if (
      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
      !memberNotes
    ) {
      onClose();
    } else if (memberNotes === '') {
      // handleClose();
      onClose();
    } else {
      setOpenModal(true);
    }
  };

  const onClose = useCallback(() => {
    setEditMode(false);
    setOpenDrawer(false);
  }, [setOpenDrawer]);

  const handleClose = useCallback(() => {
    setEditMode(false);
    setOpenModal(false);
  }, [setOpenModal]);

  const onEditorChange = (value) => {
    const editorData = draftToHtml(convertToRaw(value.getCurrentContent()));
    setMemberData({ ...memberData, notes: editorData });
  };

  const onSaveChanges = async () => {
    // PHASE2/CHANGES/SCHOOL

    dispatch(loaderStart());

    const payload = {
      notes: memberData?.notes
    };

    try {
      await addSchoolFormDetailsOrUpdateSchool(prefillSchoolData.id, payload);
      dispatch(loaderStop());
      dispatch(setSchool(payload));
      dispatch(addMessage('Changes saved'));
      const contentBlock = htmlToDraft(payload?.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      handleClose();
      setOpen(false);
    } catch (error) {
      console.error(error);
      dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  useEffect(() => {
    if (!prefillSchoolData.schoolName && schoolID) {
      setCurrentSchoolLoading(true);
      dispatch(loaderStart());
      // PHASE2/CHANGES/SCHOOL

      getSchoolByID(schoolID)
        .then((school) => {
          if (school) {
            dispatch(setSchool(school));
            if (school?.formData?.category?.length) {
              let updatedCategotyArray = school?.formData?.category
                .filter((item) => item.name !== 'Staff')
                .map((item) => {
                  return {
                    name: item?.name,
                    id: item?.id,
                    isDisplay: !!item.isDisplay
                  };
                });
              setCategory([...updatedCategotyArray] || []);
            } else {
              let updatedCategotyArray = school?.categories
                .filter((item) => item.name !== 'Staff')
                .map((item) => {
                  return {
                    name: item?.name,
                    id: item?.id,
                    isDisplay: false
                  };
                });
              setCategory([...updatedCategotyArray] || []);
            }
            setInitialValue({
              schoolName: school?.formData?.schoolName || schoolName,
              schoolShortName: school?.formData?.schoolShortName || '',
              schoolColor: school?.formData?.schoolColor || '#1D1D1D',
              firstName: school?.formData?.firstName || 'Michelle',
              email: school?.formData?.email || 'care@myfamilyhand.com.au'
            });
            setURLPath(school?.formData?.urlPath || '');
            setIsPublished(school?.formData?.isPublished || null);
            setSchoolColor(school?.formData?.schoolColor || '#1D1D1D');
            setSchoolLogoObject(school?.formData?.schoolLogo || null);
            setSchoolSupportPersonObject(
              school?.formData?.schoolSupportPerson || defaultSupportPerson
            );
          }
          dispatch(loaderStop());
          setCurrentSchoolLoading(false);
        })
        .catch((error) => {
          console.error(error);
          dispatch(addMessage('Error on getting school', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
          setCurrentSchoolLoading(false);
        });
    }
  }, [prefillSchoolData, schoolID, dispatch]);

  // SCHEMA VALIDATOR FOR
  const validationSchema = Yup.object().shape({
    schoolName: !schoolName
      ? Yup.string().trim().required('School Full name is required')
      : Yup.string().trim(),
    schoolShortName: Yup.string()
      .trim()
      .required('School Short name is required'),
    schoolColor: !schoolColor
      ? Yup.string().trim().required('School color is required')
      : Yup.string().trim(),
    urlPath: !urlPath
      ? Yup.string().trim().required('School URL path is required')
      : Yup.string().trim(),
    schoolLogoObject: !schoolLogoObject
      ? Yup.string().trim().required('School Logo is required')
      : Yup.string().trim(),
    firstName: Yup.string().trim().required('First Name is required'),
    email: Yup.string().trim().required('Email is required')
  });

  const formik = useFormik({
    key: formKey,
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitSchoolForm(values);
    }
  });

  const openRightDrawer = useCallback(
    (data) => {
      setMemberData(data);
      const contentBlock = htmlToDraft(data.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      setOpenDrawer(true);
    },
    [setOpenDrawer, prefillSchoolData]
  );

  const handleOnClose = () => {
    setFileUploadModal((prev) => ({ ...prev, isModalOpen: false }));
  };

  const uploadSchoolImageLogo = (event) => {
    // if (isLoading) {
    //   return <CircularProgress color="secondary" size={25} />;
    // }
    const fileLength = event?.target?.files?.length;
    if (fileLength > 0) {
      const file = event?.target?.files[0];
      // if (e.target.files && e.target.files.length > 0) {
      //   setUploadSchoolLogo(e.target.files[0]);
      // }
      const fileType = file.name.split('.').pop().toLowerCase();
      event.target.value = '';
      if (isValidFileType(fileType, true)) {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 0 });
        firebase
          .uploadSchoolImage(file, prefillSchoolData.id, schoolFileType.LOGO)
          .then((snapShot) => {
            //takes a snap shot of the process as it is happening
            if (snapShot && snapShot.state === 'success') {
              firebase
                .getDownloadURLForSchoolFile(
                  file,
                  prefillSchoolData.id,
                  schoolFileType.LOGO
                )
                .then((fireBaseUrl) => {
                  const schoolImage = {
                    url: fireBaseUrl,
                    storage_path: `schools/${setSchoolFileName(
                      file,
                      prefillSchoolData.id,
                      schoolFileType.LOGO
                    )}`
                  };
                  setSchoolLogoObject(schoolImage);
                  handleOnClose();
                  setSchoolError(null);
                  formik.setFieldError('schoolLogoObject', null)
                })
                .catch((err) => {
                  //catches the errors
                  console.log('Error while Getting Download URL', err);
                  handleOnClose();
                });
            } else {
              setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
            }
          })
          .catch((err) => {
            //catches the errors
            console.log('Error while Uploading Profile Picture', err);
            setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
          });
      } else {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 1 });
      }
    }
  };

  const uploadSchoolImageSupportPerson = (event) => {
    // if (isLoading) {
    //   return <CircularProgress color="secondary" size={25} />;
    // }
    const fileLength = event.target.files.length;
    if (fileLength > 0) {
      const file = event?.target.files[0];
      // if (e.target.files && e.target.files.length > 0) {
      //   setUploadSchoolLogo(e.target.files[0]);
      // }
      const fileType = file.name.split('.').pop().toLowerCase();
      event.target.value = '';
      if (isValidFileType(fileType, true)) {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 0 });
        firebase
          .uploadSchoolImage(
            file,
            prefillSchoolData.id,
            schoolFileType.SUPPORT_PERSON
          )
          .then((snapShot) => {
            //takes a snap shot of the process as it is happening
            if (snapShot && snapShot.state === 'success') {
              firebase
                .getDownloadURLForSchoolFile(
                  file,
                  prefillSchoolData.id,
                  schoolFileType.SUPPORT_PERSON
                )
                .then((fireBaseUrl) => {
                  const schoolImage = {
                    url: fireBaseUrl,
                    storage_path: `schools/${setSchoolFileName(
                      file,
                      prefillSchoolData.id,
                      schoolFileType.SUPPORT_PERSON
                    )}`
                  };
                  setSchoolSupportPersonObject(schoolImage);
                  handleOnClose();
                })
                .catch((err) => {
                  //catches the errors
                  console.log('Error while Getting Download URL', err);
                  handleOnClose();
                });
            } else {
              setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
            }
          })
          .catch((err) => {
            //catches the errors
            console.log('Error while Uploading Profile Picture', err);
            setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
          });
      } else {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 1 });
      }
    }
  };



  const handleSchoolLogoImage = () => {
    setSchoolLogoObject(null);
    formik.setFieldError('schoolLogoObject', 'School Logo is required')
  }


  const removeSchoolImageSupportPerson = (storagePath) => {
    if (storagePath) {
      firebase
        .deleteSchoolFileFromStorage(storagePath)
        .then(() => {
          setSchoolSupportPersonObject(DEFAULT_SUPPORT_PERSON);
        })
        .catch((error) => {
          console.log('error while removing profile picture', error);
        });
    }
  };

  const handleInputChange = () => {
    setUnSavedField(true);
    setSchoolError('School Logo is required');
  };

  const handleColorChange = (e) => {
    setSchoolColor(e.target.value);
  };
  const handleURLChange = (e) => {
    setURLPath(e.target.value.trim());
  };
  const handleResetColor = () => {
    setSchoolColor('#1D1D1D');
  };
  const handleCheckURL = async (e) => {
    /// PHASE2/CHANGES/SCHOOL
    const urlCheck = e.target.value.toLowerCase();
    if (urlCheck.trim()) {
      try {
        const result = await isUrlAvailable(urlCheck);
        if (result && urlCheck !== prefillSchoolData.formData.urlPath) {
          setError('The URL is unavailable, please take another !');
        } else {
          setError('');
        }
      } catch (error) {
        setError('');
        console.error(error);
      }
    } else {
      setError('School URL path is required');
    }
  };

  const handleChangeCategory = (event, selectedIndex, setFieldValue) => {
    const selectValue = event.target.name;
    let updatedCategory = category.map((item, index) => {
      if (index === selectedIndex) {
        return {
          ...item,
          isDisplay: item?.isDisplay ? false : true
        };
      } else {
        return {
          ...item
        };
      }
    });
    setCategory(updatedCategory);
  };

  const deleteSchoolHandle = async () => {
    // PHASE2/CHANGES/SCHOOL

    dispatch(loaderStart());
    try {
      await deleteSchoolForm(prefillSchoolData.id);
      dispatch(loaderStop());
      dispatch(addMessage('School Form Deleted!'));
      setOpenDeleteModal(false);
      setOpenConfirmDeleteModal(true);
    } catch (error) {
      console.error(error);
      dispatch(addMessage('School Form was not deleted. Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  const unPublishSchoolForm = async (values) => {
    // PHASE2/CHANGES/SCHOOL
    const schoolData = {
      schoolName: values?.formData?.schoolName,
      formData: {
        schoolShortName: values?.formData?.schoolShortName,
        schoolLogo: schoolLogoObject,
        schoolColor: schoolColor,
        urlPath: urlPath,
        category: category,
        firstName: values?.formData?.firstName,
        email: values?.formData?.email,
        schoolSupportPerson: schoolSupportPersonObject,
        isPublished: false
      }
    };

    dispatch(loaderStart());

    try {
      await addSchoolFormDetailsOrUpdateSchool(
        prefillSchoolData.id,
        schoolData
      );
      dispatch(loaderStop());
      dispatch(addMessage('School data Updated!'));
      setIsPublished(false);
      setIsOpenUnpublishModal(false);
      setIsOpenChangeURL(false);
    } catch (error) {
      console.error(error);
      dispatch(addMessage('School was not updated. Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  const publishSchoolForm = async (values) => {
    // PHASE2/CHANGES/SCHOOL
    const schoolData = {
      schoolName: values?.schoolName,
      formData: {
        schoolShortName: values?.formData?.schoolShortName,
        schoolLogo: schoolLogoObject,
        schoolColor: schoolColor,
        urlPath: urlPath,
        category: category,
        firstName: values?.formData?.firstName,
        email: values?.formData?.email,
        schoolSupportPerson: schoolSupportPersonObject,
        isPublished: true
      }
    };

    dispatch(loaderStart());

    try {
      await addSchoolFormDetailsOrUpdateSchool(
        prefillSchoolData.id,
        schoolData
      );
      dispatch(loaderStop());
      dispatch(addMessage('School form data published!'));
      setIsPublished(true);
      setIsOpenUnpublishModal(false);
      setIsOpenPublishModal(false);
      setIsOpenChangeURL(false);
    } catch (error) {
      console.error(error);
      dispatch(addMessage('School was not updated. Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  const submitSchoolForm = async (values) => {
    // PHASE2/CHANGES/SCHOOL

    const schoolData = {
      schoolName: values.schoolName || schoolName,
      formData: {
        schoolShortName: values.schoolShortName,
        schoolLogo: schoolLogoObject,
        schoolColor: schoolColor,
        urlPath: urlPath.toLowerCase(),
        category: category,
        schoolSupportPerson: schoolSupportPersonObject,
        firstName: values.firstName,
        email: values.email,
        isPublished: true
      }
    };

    dispatch(loaderStart());

    if (
      prefillSchoolData?.formData?.isPublished === true &&
      schoolData?.formData?.urlPath !== prefillSchoolData?.formData?.urlPath
    ) {
      dispatch(setSchool({ ...prefillSchoolData, ...schoolData }));
      dispatch(loaderStop());
      setIsOpenChangeURL(true);
    } else {
      dispatch(setSchool({ ...prefillSchoolData, ...schoolData }));

      try {
        await addSchoolFormDetailsOrUpdateSchool(
          prefillSchoolData.id,
          schoolData
        );
        dispatch(loaderStop());
        dispatch(setSchool({ ...prefillSchoolData, ...schoolData }));
        setIsPublished(true);
        setUnSavedField(false);
        dispatch(addMessage('School data Updated!'));
      } catch (error) {
        console.error(error);
        dispatch(addMessage('School was not updated. Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      }
    }
  };

  const handlePublishForm = async () => {
    const touchedObj = {};
    Object.entries(formik.values).forEach(([key]) => {
      touchedObj[key] = true;
    });
    const result = await formik.setTouched(touchedObj, true);
    if (Object.entries(result).length <= 0) {
      setIsOpenPublishModal(true);
    }
  };

  const handleUnpublishForm = async () => {
    const touchedObj = {};
    Object.entries(formik.values).forEach(([key]) => {
      touchedObj[key] = true;
    });
    const result = await formik.setTouched(touchedObj, true);
    if (Object.entries(result).length <= 0) {
      setIsOpenUnpublishModal(true);
    }
  };

  const copyToClipboard = () => {
    copy(`${process.env.REACT_APP_REGISTRATION_URL}/${urlPath.toLowerCase()}`);
  };

  const navTabsOptions = useMemo(() => {
    return [
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Calendar',
              link: `${routes.schoolCalendar}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Groups',
        link: `${routes.schoolGroup}/${schoolID}`
      },
      {
        label: 'Students',
        link: `${routes.schoolStudent}/${schoolID}`
      },
      {
        divider: true
      },
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Links',
              link: `${routes.schoolLinks}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Notes',
        handleTabClick: () => openRightDrawer(prefillSchoolData)
      },
      {
        label: 'Files',
        link: `${routes.schoolFiles}/${schoolID}`
      },
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Form',
              link: `${routes.schoolForm}/${schoolID}`
            }
          ]
        : []),
      {
        divider: true
      },
      {
        label: 'Account',
        link: `${routes.schoolAccount}/${schoolID}`
      }
    ];
  }, [prefillSchoolData]);

  return (
    <React.Fragment>
      <Dashboard>
        <div className={classes.root}>
          <div>
            <Typography
              variant="body1"
              className={classes.bredthcrumbTitle}
              onClick={() => history && history.push(routes.schools)}
            >
              Schools
            </Typography>
            <RightArrow
              viewBox="0 0 24 24"
              height={24}
              width={24}
              style={{ fontSize: 22, verticalAlign: 'middle' }}
            />
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ marginBottom: 20, marginTop: 22 }}
          >
            {schoolName}
          </Typography>
          <Paper className={classes.tabNavigations}>
            <img
              className={classes.reportImage}
              src={studentReportingIcon}
              alt="Student report"
              onClick={() => setOpenStudentReportPopUp(!openStudentReportPopUp)}
            />
            {openStudentReportPopUp ? (
              <article className={classes.studentReportPopUp}>
                <div style={{ fontWeight: 500, marginBottom: '10px' }}>
                  Student reporting
                </div>
                {prefillSchoolData?.createdByApiSync ? (
                  <div className={classes.flexBoxBetween}>
                    <span>Population</span>
                    <span>
                      {prefillSchoolData?.studentReport?.apiStudent || 0}
                    </span>
                  </div>
                ) : null}
                <div className={classes.flexBoxBetween}>
                  <span>Active</span>
                  <span>{prefillSchoolData?.studentReport?.active || 0}</span>
                </div>
                <div className={classes.flexBoxBetween}>
                  <span>Inactive</span>
                  <span>{prefillSchoolData?.studentReport?.inActive || 0}</span>
                </div>
                {prefillSchoolData?.createdByApiSync ? (
                  <div className={classes.flexBoxBetween}>
                    <span>Opportunity</span>
                    <span>
                      {prefillSchoolData?.studentReport?.nonRegistered || 0}
                    </span>
                  </div>
                ) : null}
              </article>
            ) : null}
            <section
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div>
                <NavTab
                  tabs={navTabsOptions}
                  selectedTab={isOpenDrawer ? 'Notes' : 'Form'}
                />
              </div>
              {prefillSchoolData && prefillSchoolData?.createdByApiSync && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '1rem'
                    //  padding: '0 2rem'
                  }}
                >
                  <img src={schoolBytes} height="38px" />
                  <img src={api_icon} />
                </div>
              )}
            </section>
            <Divider />
            {isPublished === false && (
              <div
                style={{
                  width: '102%',
                  height: '35px',
                  backgroundColor: '#FFB8BF',
                  margin: '-17px 0 0 -20px'
                }}
              >
                <p style={{ textAlign: 'center', padding: '7px 0 0 0' }}>
                  Form is unpublished
                </p>
              </div>
            )}

            <div style={{ float: 'right' }}>
              {prefillSchoolData?.formData === null ||
              prefillSchoolData?.formData === undefined ? null : (
                <div className={classes.pauseContainer}>
                  {isPublished === true || isPublished === undefined ? (
                    <Button onClick={handleUnpublishForm}>
                      <div className={classes.unPublishText}>
                        Unpublish Form
                      </div>
                    </Button>
                  ) : (
                    <Button onClick={() => handlePublishForm()}>
                      <div
                        className={classes.unPublishText}
                        style={{ color: '#8bc517' }}
                      >
                        Publish Form
                      </div>
                    </Button>
                  )}

                  <Button
                    onClick={() =>
                      setOpenDeleteModal && setOpenDeleteModal(true)
                    }
                  >
                    <div className={classes.unPublishText}>Delete Form</div>
                  </Button>
                </div>
              )}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {isPublished === true ? (
                <Button
                  className={classes.viewFormButton}
                  onClick={(e) => {
                    // e.preventDefault();
                    window.open(
                      `${process.env.REACT_APP_REGISTRATION_URL}/${prefillSchoolData?.formData?.urlPath.toLowerCase()}`
                    );
                  }}
                >
                  View Form
                </Button>
              ) : (
                <Button className={classes.disableViewFormButton} disabled>
                  View Form
                </Button>
              )}
              {/* -----TODO: FORMIK----- */}
              <form
                noValidate
                onSubmit={formik.handleSubmit}
                className={classes.contentWrapper}
              >
                <div>
                  <Typography className={classes.formTitle}>
                    School Details
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControl
                        component="fieldset"
                        classes={{ root: classes.formControl }}
                      >
                        <TextField
                          label="School Full Name"
                          variant="outlined"
                          name="schoolName"
                          onChange={(e) => {
                            handleInputChange();
                            formik.handleChange(e);
                          }}
                          error={Boolean(
                            formik.touched?.schoolName &&
                              formik.errors?.schoolName
                          )}
                          helperText={
                            formik.touched?.schoolName &&
                            formik.errors?.schoolName
                          }
                          value={formik.values?.schoolName || schoolName}
                          className={classes.controlBox}
                          style={{ margin: '20px 0 0 0' }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl
                        component="fieldset"
                        classes={{ root: classes.formControl }}
                      >
                        <TextField
                          label="School Short Name"
                          variant="outlined"
                          name="schoolShortName"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleInputChange();
                            accountFlag && isEdit && handleCancelDisable(true);
                          }}
                          error={Boolean(
                            formik.touched?.schoolShortName &&
                              formik.errors?.schoolShortName
                          )}
                          helperText={formik.errors?.schoolShortName}
                          value={formik.values?.schoolShortName}
                          className={classes.controlBox}
                        />
                      </FormControl>
                    </Grid>

                    <div
                      className={
                        schoolLogoObject
                          ? classes.profileImageWrapper
                          : classes.placeholderImageWrapper
                      }
                    >
                      <label htmlFor="upload-school-picture-logo">
                        <TextField
                          style={{ display: 'none' }}
                          id="upload-school-picture-logo"
                          name="upload-school-picture-logo"
                          type="file"
                          onChange={(e) => {
                            handleInputChange();
                            uploadSchoolImageLogo(e);
                          }}
                        />
                        <Badge
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                        >
                          <Avatar
                            variant="square"
                            className={
                              schoolLogoObject
                                ? classes.imageContainer
                                : classes.placeholderContainer
                            }
                            alt={formik.values?.initials}
                            src={
                              schoolLogoObject
                                ? _.get(schoolLogoObject, 'url', '')
                                : PlaceholderImage
                            }
                          >
                            {formik.values?.initials}
                          </Avatar>
                        </Badge>
                      </label>
                    </div>

                    {!_.isEmpty(schoolLogoObject) ? (
                      <div
                        className={classes.removeImageContainer}
                        onClick={handleSchoolLogoImage}
                      >
                        Remove
                      </div>
                    ) : (
                      <label htmlFor="upload-school-picture-logo">
                        <TextField
                          style={{ display: 'none' }}
                          id="upload-school-picture-logo"
                          name="upload-school-picture-logo"
                          type="file"
                          onChange={(e) => {
                            handleInputChange();
                            uploadSchoolImageLogo(e);
                          }}
                        />
                        <p className={classes.uploadImageText}>Upload Logo</p>
                      </label>
                    )}
                    {(formik.errors?.schoolLogoObject) && (
                      <p
                        style={{
                          color: 'red',
                          width: '100%',
                          fontSize: '14px',
                          margin: '0 0 6px 17px'
                        }}
                      >
                        {formik.errors?.schoolLogoObject}
                      </p>
                    )}
                    <br />

                    <Grid item xs={12}>
                      <FormControl
                        component="fieldset"
                        classes={{ root: classes.formControl }}
                        style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <TextField
                          variant="outlined"
                          name="schoolColor"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            handleColorChange(e);
                            handleInputChange();
                            formik.handleChange(e);
                            accountFlag && isEdit && handleCancelDisable(true);
                          }}
                          error={Boolean(
                            formik.touched?.schoolColor &&
                              formik.errors?.schoolColor
                          )}
                          helperText={
                            formik.touched?.schoolColor &&
                            formik.errors?.schoolColor
                          }
                          value={schoolColor}
                          style={{ width: '80%' }}
                          className={classes.controlBox}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                style={{
                                  width: '20%',
                                  marginLeft: '-14px'
                                }}
                              >
                                <Typography
                                  aria-label="Add"
                                  edge="end"
                                  color="primary"
                                  variant="contained"
                                  style={{
                                    height: '58px',
                                    width: '80%',
                                    paddingRight: '0px',
                                    backgroundColor: schoolColor
                                  }}
                                ></Typography>
                              </InputAdornment>
                            ),

                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ width: '50px' }}
                              >
                                <Button
                                  aria-label="Add"
                                  edge="end"
                                  color="primary"
                                  variant="contained"
                                  style={{
                                    height: '58px',
                                    width: '60%',
                                    paddingRight: '0px'
                                  }}
                                >
                                  <span
                                    style={{ margin: '0 13px 0 0' }}
                                    onClick={handleResetColor}
                                  >
                                    Reset
                                  </span>
                                </Button>
                              </InputAdornment>
                            )
                          }}
                        />
                        <Typography className={classes.boxContentLabel}>
                          School Colour
                        </Typography>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl
                        component="fieldset"
                        classes={{ root: classes.formControl }}
                        style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <TextField
                          variant="outlined"
                          name="urlPath"
                          onBlur={(e) =>
                            handleCheckURL(e, prefillSchoolData.id)
                          }
                          onChange={(e) => {
                            handleInputChange();
                            formik.handleChange(e);
                            handleURLChange(e);
                          }}
                          error={Boolean(
                            formik.touched?.urlPath && formik.errors?.urlPath
                          )}
                          helperText={
                            formik.touched?.urlPath && formik.errors?.urlPath
                          }
                          value={urlPath.toLowerCase()}
                          style={{ width: '80%' }}
                          className={
                            error ? classes.errorTextBox : classes.controlBox
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ width: '50px' }}
                              >
                                <Button
                                  aria-label="Add"
                                  edge="end"
                                  color="primary"
                                  variant="contained"
                                  style={{
                                    height: '58px',
                                    width: '60%',
                                    paddingRight: '0px'
                                  }}
                                  onClick={copyToClipboard}
                                >
                                  <span style={{ margin: '0 13px 0 0' }}>
                                    Copy
                                  </span>
                                </Button>
                              </InputAdornment>
                            )
                          }}
                        />
                        <Typography className={classes.boxContentLabel}>
                          Form URL
                        </Typography>
                      </FormControl>
                      {formik.errors?.urlPath && formik.errors?.urlPath ? (
                        <p className={classes.errors}>
                          {formik.errors?.urlPath}
                        </p>
                      ) : error ? (
                        <p className={classes.errors}>{error}</p>
                      ) : null}
                    </Grid>
                    {!prefillSchoolData?.createdByApiSync ? (
                      <>
                        <Grid item xs={12}>
                          <Typography className={classes.formTitle}>
                            Group Display
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft: '0px',
                            justifyContent: 'space-between'
                          }}
                        >
                          {category?.map((categoryData, index) => {
                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginLeft: '10px'
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: '17px',
                                    display: 'block',
                                    margin: '0 0 0 -10px'
                                  }}
                                >
                                  {categoryData?.name}
                                </Typography>

                                <FormControlLabel
                                  style={{ display: 'block' }}
                                  control={
                                    <ToggleSwitchButton
                                      checked={categoryData?.isDisplay}
                                      handleChecked={(e) => {
                                        handleInputChange();
                                        handleChangeCategory(
                                          e,
                                          index,
                                          formik.setFieldValue
                                        );
                                      }}
                                      name={categoryData.name}
                                      className={classes.controlCheck}
                                      classes={{
                                        root: classes.checkbox,
                                        checked: classes.checked
                                      }}
                                    />
                                  }
                                  className={classes.labelText}
                                />
                                {/* {index === 0 && <Divider />} */}
                              </div>
                            );
                          })}
                        </Grid>
                      </>
                    ) : null}

                    <Grid item xs={12}>
                      <Typography className={classes.formTitle}>
                        Support Person
                      </Typography>
                    </Grid>

                    <div style={{ backgroundColor: '#ffffff' }}>
                      <label htmlFor="upload-school-picture-supportPerson">
                        <TextField
                          style={{ display: 'none' }}
                          id="upload-school-picture-supportPerson"
                          name="upload-school-picture-supportPerson"
                          type="file"
                          onChange={(e) => uploadSchoolImageSupportPerson(e)}
                        />
                        <Badge
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                        >
                          <Avatar
                            className={classes.supportPersonImageContainer}
                            alt={formik.values?.initials}
                            src={_.get(schoolSupportPersonObject, 'url', '')}
                            onError={(e) => {
                              setSchoolSupportPersonObject(
                                defaultSupportPerson
                              );
                            }}
                          >
                            {formik.values?.initials}
                          </Avatar>
                        </Badge>
                      </label>
                    </div>
                    {schoolSupportPersonObject.storage_path !==
                    defaultSupportPerson.storage_path ? (
                      <div
                        onClick={() =>
                          removeSchoolImageSupportPerson(
                            schoolSupportPersonObject.storage_path
                          )
                        }
                      >
                        <p className={classes.resetSupportImage}>Reset</p>
                      </div>
                    ) : (
                      <label htmlFor="upload-school-picture-supportPerson">
                        <TextField
                          style={{ display: 'none' }}
                          id="upload-school-picture-supportPerson"
                          name="upload-school-picture-supportPerson"
                          type="file"
                          onChange={(e) => uploadSchoolImageSupportPerson(e)}
                        />
                        <p className={classes.uploadImageTextSupportPerson}>
                          Upload Image
                        </p>
                      </label>
                    )}

                    <Grid item xs={12}>
                      <FormControl
                        component="fieldset"
                        classes={{ root: classes.formControl }}
                      >
                        <TextField
                          label="First Name"
                          variant="outlined"
                          name="firstName"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            handleInputChange();
                            formik.handleChange(e);
                            accountFlag && isEdit && handleCancelDisable(true);
                          }}
                          error={Boolean(
                            formik.touched?.firstName &&
                              formik.errors?.firstName
                          )}
                          helperText={
                            formik.touched?.firstName &&
                            formik.errors?.firstName
                          }
                          value={formik.values?.firstName}
                          className={classes.controlBox}
                          style={{ margin: '10px 0 0 0' }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        component="fieldset"
                        classes={{ root: classes.formControl }}
                      >
                        <TextField
                          label="Email Address"
                          variant="outlined"
                          name="email"
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            handleInputChange();
                            formik.handleChange(e);
                            accountFlag && isEdit && handleCancelDisable(true);
                          }}
                          error={Boolean(
                            formik.touched?.email && formik.errors?.email
                          )}
                          helperText={
                            formik.touched?.email && formik.errors?.email
                          }
                          value={formik.values?.email}
                          className={classes.controlBox}
                        />
                      </FormControl>
                    </Grid>
                    {/*
                        <Grid item xs={12}>
                          <Typography className={classes.formTitle}>
                            Social Image
                          </Typography>
                        </Grid>

                        <div
                          className={
                            schoolSocialImageObject
                              ? classes.socialImageWrapper
                              : classes.placeholderImageWrapper
                          }
                        >
                          <label htmlFor="upload-school-picture-social-image">
                            <TextField
                              style={{ display: 'none' }}
                              id="upload-school-picture-social-image"
                              name="upload-school-picture-social-image"
                              type="file"
                              onChange={(e) => {
                                handleInputChange();
                                uploadSchoolSocialImage(e);
                              }}
                            />
                            <Badge
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                              }}
                            >
                              <Avatar
                                variant="square"
                                className={
                                  schoolSocialImageObject
                                    ? classes.socialImageContainer
                                    : classes.placeholderContainer
                                }
                                alt={values.initials}
                                src={
                                  schoolSocialImageObject
                                    ? _.get(
                                        schoolSocialImageObject,
                                        'url',
                                        ''
                                      )
                                    : PlaceholderImage
                                }
                              >
                                {values.initials}
                              </Avatar>
                            </Badge>
                          </label>
                        </div>
                        {!_.isEmpty(schoolSocialImageObject) ? (
                          <div
                            className={classes.removeImageContainer}
                            onClick={() =>
                              removeSchoolSocialImage(
                                schoolSocialImageObject.storage_path
                              )
                            }
                          >
                            Remove
                          </div>
                        ) : (
                          <label htmlFor="upload-school-picture-social-image">
                            <TextField
                              style={{ display: 'none' }}
                              id="upload-school-picture-social-image"
                              name="upload-school-picture-social-image"
                              type="file"
                              onChange={(e) => {
                                handleInputChange();
                                uploadSchoolSocialImage(e);
                              }}
                            />
                            <p className={classes.uploadImageTextSocialImage}>
                              Upload Image
                            </p>
                          </label>
                        )} */}

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonText}
                        disabled={!unSavedField}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </div>
          </Paper>

          {isOpenDeleteModal && (
            <Modal
              isOpen={isOpenDeleteModal}
              onClose={() => {
                setOpenDeleteModal(false);
              }}
            >
              <div className={classes.modalBox}>
                <Typography className={classes.modalTitle}>
                  Delete School Form?
                </Typography>
                <div className={classes.modalSubHeading}>
                  <Typography className={classes.modalSubTitle}>
                    <span style={{ fontWeight: 'bold' }}>
                      Warning: This action cannot be reversed.
                    </span>
                    <br />
                    The following data will be permanently removed from the
                    database.
                  </Typography>
                </div>
                <div className={classes.modalContent}>
                  <Typography className={classes.modalSubTitle}>
                    - School profile (admin & app)
                    <br />
                  </Typography>
                </div>
                <div className={classes.modalSubHeading}>
                  <span
                    className={classes.modalSubTitle}
                    style={{ fontWeight: 500, paddingBottom: 14 }}
                  >
                    Type “DELETE SCHOOL FORM” to confirm
                  </span>
                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <TextField
                      label=""
                      variant="outlined"
                      className={classes.inputControlBox}
                      style={{ marginTop: 0 }}
                      value={deleteText}
                      onChange={(e) => setDeleteText(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className={classes.modalActions}>
                  <Button
                    onClick={deleteSchoolHandle}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={deleteText !== 'DELETE SCHOOL FORM'}
                    className={classes.primaryButton}
                    startIcon={
                      isLoading && (
                        <CircularProgress color="secondary" size={25} />
                      )
                    }
                  >
                    {!isLoading && 'Delete'}
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenDeleteModal(false);
                    }}
                    variant="contained"
                    size="large"
                    className={classes.greyButton}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          )}
          {isOpenConfirmDeleteModal && (
            <Modal
              isOpen={isOpenConfirmDeleteModal}
              onClose={() => history && history.push(routes.schools)}
            >
              <div className={classes.modalBox}>
                <Typography
                  className={classes.modalTitle}
                  style={{ marginBottom: 18 }}
                >
                  School Form Deleted
                </Typography>
                <Typography
                  className={classes.modalSubTitle}
                  style={{ marginBottom: 26 }}
                >
                  All Form data associated with {prefillSchoolData.schoolName}{' '}
                  has now been removed from the database.
                </Typography>
                <div className={classes.modalActions}>
                  <Button
                    onClick={() => {
                      setOpenConfirmDeleteModal(false);
                      history && history.push(routes.schools);
                    }}
                    color="primary"
                    variant="contained"
                    size="large"
                    className={classes.primaryButton}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </Modal>
          )}

          {isOpenPublishModal && (
            <Modal
              isOpen={isOpenPublishModal}
              onClose={() => {
                setIsOpenPublishModal(false);
              }}
            >
              <div className={classes.modalBox}>
                <Typography className={classes.modalTitle}>
                  Publish School Form?
                </Typography>

                <div className={classes.modalSubHeading}>
                  <span
                    className={classes.modalSubTitle}
                    style={{ fontWeight: 500, paddingBottom: 14 }}
                  >
                    Type “PUBLISH FORM” to confirm
                  </span>
                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <TextField
                      label=""
                      variant="outlined"
                      className={classes.inputControlBox}
                      style={{ marginTop: 0 }}
                      value={publishText}
                      onChange={(e) => setPublishText(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className={classes.modalActions}>
                  <Button
                    onClick={() => publishSchoolForm(prefillSchoolData)}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={publishText !== 'PUBLISH FORM'}
                    className={classes.primaryButton}
                    startIcon={
                      isLoading && (
                        <CircularProgress color="secondary" size={25} />
                      )
                    }
                  >
                    {!isLoading && 'Publish'}
                  </Button>
                  <Button
                    onClick={() => {
                      setIsOpenPublishModal(false);
                    }}
                    variant="contained"
                    size="large"
                    className={classes.greyButton}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          )}

          {isOpenChangeURL && (
            <Modal
              isOpen={isOpenChangeURL}
              onClose={() => {
                setIsOpenChangeURL(false);
              }}
            >
              <div className={classes.modalBox}>
                <Typography className={classes.modalTitle}>
                  Change Form URL?
                </Typography>

                <div className={classes.modalSubHeading}>
                  <span
                    className={classes.modalSubTitle}
                    style={{ fontWeight: 500, paddingBottom: 14 }}
                  >
                    Type “CHANGE URL” to confirm
                  </span>
                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <TextField
                      label=""
                      variant="outlined"
                      className={classes.inputControlBox}
                      style={{ marginTop: 0 }}
                      value={urlText}
                      onChange={(e) => setURLText(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className={classes.modalActions}>
                  <Button
                    onClick={() => publishSchoolForm(prefillSchoolData)}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={urlText !== 'CHANGE URL'}
                    className={classes.primaryButton}
                    startIcon={
                      isLoading && (
                        <CircularProgress color="secondary" size={25} />
                      )
                    }
                  >
                    {!isLoading && 'Change URL'}
                  </Button>
                  <Button
                    onClick={() => {
                      setIsOpenChangeURL(false);
                    }}
                    variant="contained"
                    size="large"
                    className={classes.greyButton}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          )}

          {isOpenUnpublishModal && (
            <Modal
              isOpen={isOpenUnpublishModal}
              onClose={() => {
                setIsOpenUnpublishModal(false);
              }}
            >
              <div className={classes.modalBox}>
                <Typography className={classes.modalTitle}>
                  Unpublish School Form?
                </Typography>

                <div className={classes.modalSubHeading}>
                  <span
                    className={classes.modalSubTitle}
                    style={{ fontWeight: 500, paddingBottom: 14 }}
                  >
                    Type “UNPUBLISH FORM” to confirm
                  </span>
                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <TextField
                      label=""
                      variant="outlined"
                      className={classes.inputControlBox}
                      style={{ marginTop: 0 }}
                      value={unPublishText}
                      onChange={(e) => setUnPublishText(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className={classes.modalActions}>
                  <Button
                    onClick={() => unPublishSchoolForm(prefillSchoolData)}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={unPublishText !== 'UNPUBLISH FORM'}
                    className={classes.primaryButton}
                    startIcon={
                      isLoading && (
                        <CircularProgress color="secondary" size={25} />
                      )
                    }
                  >
                    {!isLoading && 'Unpublish'}
                  </Button>
                  <Button
                    onClick={() => {
                      setIsOpenUnpublishModal(false);
                    }}
                    variant="contained"
                    size="large"
                    className={classes.greyButton}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </div>

        <RightDrawer
          isOpen={isOpenDrawer}
          onClose={() => {
            onClose();
            onCloseForm();
          }}
          width={900}
          style={{ overflowX: 'hidden' }}
        >
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="primary" />
          </Backdrop>
          <div className={classes.drawerLeftContent}>
            <div className={classes.leftContentMargin}>
              <div className={classes.leftContentHeader}>
                {isEditMode ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.siderEdit}
                    onClick={() => {
                      const defaultData = draftToHtml(
                        convertToRaw(defaultNote.getCurrentContent())
                      );
                      const memberNotes = memberData.notes;
                      if (defaultData === memberNotes) {
                        handleClose();
                      } else if (
                        defaultData.replace(/(\r\n|\n|\r)/gm, '') ===
                          '<p></p>' &&
                        !memberNotes
                      ) {
                        handleClose();
                      } else {
                        onSaveChanges();
                      }
                    }}
                    style={{ width: 165 }}
                  >
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.siderEdit}
                    onClick={() => setEditMode(true)}
                    startIcon={<img src={Edit} />}
                  >
                    Edit
                  </Button>
                )}
              </div>
              <Divider
                variant="fullWidth"
                style={{ marginBottom: 20, marginTop: 22 }}
              />
              {!isEditMode && !memberData.notes && (
                <Typography className={classes.siderDetail}>
                  Click Edit to add a note...
                </Typography>
              )}
              {!isEditMode &&
                memberData.notes &&
                memberData.notes.replace(/(\r\n|\n|\r)/gm, '') ===
                  '<p></p>' && (
                  <Typography className={classes.siderDetail}>
                    Click Edit to add a note...
                  </Typography>
                )}
              <Editor
                stripPastedStyles={true}
                readOnly={!isEditMode}
                defaultEditorState={defaultNote}
                toolbarClassName={
                  !isEditMode ? classes.toolbarHidden : classes.toolbar
                }
                onEditorStateChange={onEditorChange}
                editorClassName={
                  !isEditMode ? classes.textEditor : classes.textEditorOnEdit
                }
                toolbar={{
                  options: ['inline', 'list', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough']
                  },
                  list: {
                    options: ['unordered']
                  },
                  link: {
                    defaultTargetOption: '_blank',
                    options: ['link']
                  }
                }}
              />
            </div>
          </div>
          <div className={classes.drawerRightContent}>
            <div style={{ padding: 18, zIndex: 1 }}>
              <div className={classes.header}>
                {memberData.schoolName && (
                  <Typography
                    className={classes.textwraplabel}
                    title={memberData.schoolName}
                  >
                    {memberData.schoolName}
                  </Typography>
                )}
              </div>
              <Divider style={{ marginBottom: 20 }} />
              {memberData.country && (
                <>
                  <Typography className={classes.heading}>COUNTRY</Typography>
                  <Typography className={classes.siderDetail}>
                    {memberData.country}
                  </Typography>
                </>
              )}
              {memberData.websiteUrl && (
                <>
                  <Typography className={classes.heading}>WEBSITE</Typography>
                  <Link
                    color="secondary"
                    component={Link}
                    onClick={() => window.open(memberData.websiteUrl, '_blank')}
                    className={classes.siderDetail}
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    Click Here
                  </Link>
                </>
              )}
            </div>
            <div className={classes.handBookContainer}>
              <img src={HandBook} alt="" className={classes.handBookClass} />
            </div>
          </div>
        </RightDrawer>
      </Dashboard>
      <ScanningUploadFileModal
        handleOnClose={handleOnClose}
        isOpen={fileUploadModal.isModalOpen}
        fileUploadState={fileUploadModal.fileUploadState}
      />
    </React.Fragment>
  );
}

export default withFirebase(Form);
