// Initial State
const initialState = {
    maintenanceMode: false
  };

// Reducers (Modifies The State And Returns A New State)
const maintenanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MAINTENANCE_MODE':{
            return {
                ...state,
                maintenanceMode: action.maintenanceMode
            }
        }

        default:{
            return state;
        }
    }

}

export default maintenanceReducer;