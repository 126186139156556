import React, { useEffect, useState } from 'react';
import API_ICON from 'src/assets/apiIcon.svg';
import RED_FLAG from 'src/assets/red_flag.svg';
import completeTick from 'src/assets/completeTick.svg';
import { makeStyles } from '@material-ui/core/styles';
import { colors, routes } from 'src/utils/constant';
import { Button } from '@material-ui/core';
import { height } from '@mui/system';
import axios from 'src/services/axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAPISchoolData,
  setAPISchoolID
} from 'src/redux/actions/schoolActions';
import { useHistory } from 'react-router-dom';
import { withFirebase } from 'src/utils/firebase';
import { getSchoolList } from 'src/redux/actions/authActions';
import { getSchools } from 'src/services/schools';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%'
  },
  cancelButtonCustom: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: ' 1.5',
    letterSpacing: '0.1px',
    color: '#bfc5d1',
    border: 'transparent',
    outline: 'transparent',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  primaryButton: {
    height: '50px',
    width:'211px'
  },
  cancelButton: {
    width: 106,
    borderRadius: 3,
    border: `solid 1px ${colors.primaryLightGrey}`,
    backgroundColor: colors.secondaryGreyLightSync,
    height: 50,
    marginLeft: '2%',
    color: colors.primaryDarkGrey,
    boxShadow: 'none !important',
    "&:hover":{
      backgroundColor: colors.secondaryGreyLightSync,
    }
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: '500',
    color: '#474747'
  },
  titleLight: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    color: '#4c5b68',
    opacity: ' 0.7',
    marginBottom: '.5rem'
  },
  titleVeryLight: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: 1.5,
    letterSpacing: '0.1px',
    color: ' #4c5b68'
  },
  textLight: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#4c5b68',
    opacity: ' 0.7',
    marginBottom: '.5rem'
  },
  warningText: {
    color: colors.tertiaryDeleteRed
  },
  flexBoxClass: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  loaderBox: {
    width: '30rem',
    padding: '1rem',
    borderRadius: '0.1875rem',
    backgroundColor: '#f4f5f7',
    marginBottom: '1rem'
  }
}));
const LoadingBar = (props) => {
  const { setIsSchoolCreated, firebase, setIsValidationSuccessFull } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [resultData, setResultData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  // for school creation states
  const [schoolProgress, setSchoolProgress] = useState(0);
  const [buttonText, setButtonText] = useState('Create School Profile');
  const [isSchoolCreationStarted, setIsSchoolCreationStarted] = useState(false);
  const [generationDone, setGenerationDone] = useState(false);
  const [warningText, setWarningText] = useState(false);
  const apiSchoolFormData = useSelector((state) => state.school.apiSchool);


  // PHASE2/CHANGES/SCHOOL
  const { auth, data, endPointState, apiSource, method } = apiSchoolFormData;
  const requiredFormData = {
    method,
    apiSource,
    auth,
    data,
    endPointState
  };
  useEffect(() => {
    getSchoolDetails(requiredFormData);
  }, []);

  // Get all schools list
  const getAllSchools = async () => {
    // PHASE2/CHANGES/SCHOOL

    try {
      const result = await getSchools();
      const schools = [];
      result.forEach((school) => {
        const yearData = school.freeCalendars.map((calendar) => ({
          label: `Year ${calendar}`,
          value: calendar
        }));

        schools.push({
          id: school.id,
          name: school.schoolName,
          years: yearData.length ? yearData : []
        });
      });
      dispatch(getSchoolList(schools));
    } catch (error) {
      console.error(error);
    }
  };

  // Axios function
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const getSchoolDetails = async (requiredFormData) => {
    const { method, apiSource, auth } = requiredFormData;
    const url = `${BASE_URL}/${apiSource}/school/detail`;
    try {
      const result = await axios({
        method,
        url,
        data: { auth },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentageProgress = Math.round((loaded / total) * 100);
          setProgress(percentageProgress);
        }
      });
      if (result.status === 200) {
        setResultData(result.data);
        setDataLoaded(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGenerateButton = async (requiredFormData) => {
    setWarningText(true);
    setIsSchoolCreationStarted(true);
    const { method, apiSource, auth, data } = requiredFormData;
    const url = `${BASE_URL}/${apiSource}/school/create`;
    try {
      const result = await axios({
        method,
        url,
        data: { auth, data },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentageProgress = Math.round((loaded / total) * 100);
          setSchoolProgress(percentageProgress);
        }
      });
      if (result.status === 200) {
      const schoolID = await result?.data?.data?.schoolId || null;
        dispatch(setAPISchoolID(schoolID));
        setGenerationDone(true);
        getAllSchools();
        setWarningText(false);
        setIsSchoolCreated(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancelButton = async (requiredFormData) => {
    const { method, apiSource, auth, data } = requiredFormData;
    const url = `${BASE_URL}/${apiSource}/school/delete`;
    try {
      const result = await axios({
        method,
        url,
        data: { auth, data }
      });
      if (result.status === 200) {
       console.log("()=>",result?.data);
       setIsValidationSuccessFull(false)
      }
    } catch (error) {
      console.log(error);
      setIsValidationSuccessFull(false)
    }
  };
  useEffect(() => {
    dispatch(setAPISchoolData(resultData));
  }, [resultData]);

  // useEffect(() => {
  //   console.log("()=>",schoolAPIResult,"schoolAPIResult");
  //   dispatch(setAPISchoolID(schoolAPIResult));
  // }, [schoolAPIResult]);

  return (
    <article>
      <section
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '.8rem',
          marginBottom: '1rem'
        }}
      >
        <img src={API_ICON} />
        <div className={classes.title}>
          {requiredFormData && requiredFormData?.data?.schoolName}
        </div>
      </section>
      <section className={classes.loaderBox}>
        <div className={classes.titleLight}>Scanning API...</div>
        <div className={classes.flexBoxClass}>
          <div className={classes.textLight}>Years</div>
          {dataLoaded ? (
            <span
              className={classes.textLight}
              style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}
            >
              {resultData?.data?.years?.map((year, yearIndex) => {
                if (resultData?.data?.years.length === yearIndex + 1) {
                  return `${year}`;
                } else {
                  return `${year},`;
                }
              })}

              {resultData?.data?.years?.length === 0 ? (
                <img src={RED_FLAG} />
              ) : (
                <img src={completeTick} />
              )}
            </span>
          ) : (
            <>
              <div
                style={{
                  width: `100px`,
                  height: '8px',
                  backgroundColor: '#d9dce3',
                  transition: 'width 0.1s',
                  borderRadius: '100px'
                }}
              >
                <div
                  style={{
                    width: `${progress}%`,
                    height: '8px',
                    backgroundColor: colors.primary,
                    transition: 'width 0.1s',
                    borderRadius: '100px'
                  }}
                ></div>
              </div>
            </>
          )}
        </div>
        <div className={classes.flexBoxClass}>
          <div className={classes.textLight}>Class Groups</div>
          {dataLoaded ? (
            <>
              <span
                className={classes.textLight}
                style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}
              >
                {resultData?.data?.groups} Found
                {resultData?.data?.groups === 0 ? (
                  <img src={RED_FLAG} />
                ) : (
                  <img src={completeTick} />
                )}
              </span>
            </>
          ) : (
            <>
              <div
                style={{
                  width: `100px`,
                  height: '8px',
                  backgroundColor: '#d9dce3',
                  transition: 'width 0.1s',
                  borderRadius: '100px'
                }}
              >
                <div
                  style={{
                    width: `${progress}%`,
                    height: '8px',
                    backgroundColor: colors.primary,
                    transition: 'width 0.1s',
                    borderRadius: '100px'
                  }}
                ></div>
              </div>
            </>
          )}
        </div>
      </section>
      {dataLoaded ? (
        <>
          <section
            style={{ display: 'flex', gap: '.3rem', marginBottom: '1rem' }}
          >
            <img src={completeTick} />
            <span className={classes.titleVeryLight}>
              Scan complete. Finish setup...
            </span>
          </section>
          <section style={{ marginBottom: '1rem' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleGenerateButton(requiredFormData);
              }}
              className={classes.primaryButton}
            >
              {!isSchoolCreationStarted
                ? buttonText
                : generationDone
                ? 'Generating 100%'
                : `Generating ${schoolProgress}%`}
            </Button>
            <Button
              variant="contained"
              className={classes.cancelButton}
              onClick={() => handleCancelButton(requiredFormData)}
            >
              Cancel
            </Button>
          </section>

          {warningText && (
            <section>
              <span className={classes.warningText}>Do not close browser</span>
            </section>
          )}
        </>
      ) : (
        <section>
          <button
            className={classes.cancelButtonCustom}
            onClick={() => setIsValidationSuccessFull(false)}
          >
            Cancel
          </button>
        </section>
      )}
    </article>
  );
};

export default withFirebase(LoadingBar);
