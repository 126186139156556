// Initial State
const initialState = {
  user: null,
  schools:[],
  families:[],
  error: '',
  activeStatus: false,
};

// Reducers (Modifies The State And Returns A New State)
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login
    case 'LOGIN_SUCCESS': {
      return {
        ...state,
        user: action.user,
        error: ''
      };
    }
    case 'LOGIN_FAIL': {
      return {
        ...state,
        user: null,
        error: action.message
      };
    }
    case 'LOGOUT_SUCCESS': {
      return initialState;
    }
    case 'SESSION_INACTIVE':{
      return {
        ...state,
        activeStatus: true,
      };
    }
    //get school list
    case 'GET_SCHOOL_LIST': {
      return {
        ...state,
        schools:action.schools,
        error: ''
      };
    }
    case 'UPDATE_SCHOOL_LIST': {
      const updateSchools = state.schools.map((school) => {
        if(school.id === action.school.id){
          school = action.school;
        }
        return school;
      })
      return {
        ...state,
        schools: updateSchools,
        error: ''
      };
    }
    //get family list
    case 'GET_FAMILY_LIST': {
      return {
        ...state,
        families:action.families,
        error: ''
      };
    }
    // Default
    default: {
      return state;
    }
  }
};

// Exports
export default authReducer;
