import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";

export const paidEditGroupStyles = makeStyles((theme) => ({
    tableRow: {
      height: 72,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: `rgba(244, 245, 247, 0.51) !important`,
        "& [class^='makeStyles-hoverTag-']": {
          color: colors.primary
        }
      }
    },
    dataTableCell: {
      color: colors.primaryDark,
      padding: '10px 16px',
      '&:first-child': {
        paddingLeft: 52
      }
    },
    nameColumn: {
      verticalAlign: 'middle',
      display: 'flex',
      lineHeight: '50px',
      fontWeight: 500,
      '& .MuiAvatar-root': {
        marginRight: 24,
        fontWeight: 500,
        height: 50,
        width: 50,
        fontSize: 14,
        color: colors.primaryDarkGrey,
        backgroundColor: colors.primaryLightGrey
      }
    },
    actionButton: {
      padding: '8px 17px',
      fontSize: 16,
      lineHeight: '24px',
      float: 'right'
    },
    startIcon: {
      marginRight: 12
    },
    actionIcon: {
      height: 50,
      width: 50,
      padding: 10,
      cursor: 'pointer',
      margin: 'auto',
      '&:hover': {
        backgroundColor: colors.primary,
        '& svg g': {
          fill: colors.white
        }
      }
    },
    siderSave: {
      width: 95,
      height: 50,
      borderRadius: 3,
      backgroundColor: colors.primary
    },
    mainHeading: {
      width: 93,
      height: 40,
      fontFamily: 'Roboto',
      fontSize: 23,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.74,
      letterSpacing: 'normal',
      color: colors.primaryDark
    },
    subHeading: {
      height: 24,
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 0.1,
      color: colors.primaryDark,
      width: 344
    },
    dividerClass: {
      marginBottom: 22,
      marginTop: 22
    },
    imageContainer: {
      width: 100,
      height: 100,
      backgroundColor: colors.primaryLightGrey,
      marginTop: 16,
      borderRadius: '50%',
      fontSize: '32px',
      fontWeight: 500,
      letterSpacing: '0.2px',
      color: colors.primaryDarkGrey
    },
    controlBox: {
      width: '100%',
      borderRadius: 4,
      backgroundColor: '#ffffff',
      '& .MuiOutlinedInput-root:hover, & .MuiOutlinedInput-root:focus': {
        boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
      }
    },
    popoverClass: {
      '& .MuiPaper-rounded': {
        right: '70px',
        left: 'unset  !important',
        top: '70px !important'
      }
    },
  
    modalTitle: {
      fontSize: 28,
      fontWeight: 500,
      lineHeight: 1.43,
      letterSpacing: -0.2,
      color: colors.primaryDark
    },
    modalSubHeading: {
      width: 523,
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 0.1,
      color: colors.primaryDark,
      marginTop: '2%'
    },
    modalContent: {
      minHeight: 75,
      paddingTop: 12
    },
    modalSubTitle: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.1,
      color: colors.primaryDark
    },
    modalBox: {
      width: 450
      // height: 170,
    },
    primaryButton: {
      width: 163,
      height: 50,
      borderRadius: 3,
      backgroundColor: colors.primary,
      marginRight: 20,
      color: colors.white,
      textTransform: 'none'
    },
    greyButton: {
      width: 136,
      height: 50,
      borderRadius: 3,
      backgroundColor: colors.secondaryText,
      color: colors.white,
      textTransform: 'none'
    },
    modalActions: {
      marginTop: '2%'
    },
    headingContainer: {
      margin: '10px 30px',
      marginBottom: '3.7%',
      marginTop: '4.7%',
      position: 'relative'
    },
    saveButtonContainer: {
      float: 'right',
      position: 'absolute',
      top: 0,
      right: 0
    },
    checked: {},
    formMargin: { margin: '10px 30px', marginBottom: 105 },
    boldFont: { fontWeight: 'bold' },
    headingMargin: { marginBottom: 20 },
    controlCheck: {
      margin: '5%'
    },
    checkbox: {
      color: colors.primaryLightGrey,
      '&$checked': {
        color: colors.primary
      }
    },
    formControl: {
      width: '100%'
    },
    labelText: {
      height: 19,
      width: '100%',
      fontFamily: 'Roboto',
      fontSize: 16,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: colors.primaryDark,
      marginTop: 7.5,
      marginBottom: 7.5
    },
    checkLabel: {
      height: 16,
      objectFit: 'contain',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: colors.primary
    },
    hoverTag: {
      padding: '4px 10px',
      fontSize: 14,
      '&:hover': {
        cursor: 'pointer',
        padding: '4px 10px',
        fontSize: 14,
        color: colors.primary
      }
    },
    moreVertButton: {
      justifyContent: 'flex-end',
      padding: 'unset  !important'
    },
    drawerContent: {
      height: `calc(100vh - 20px)`
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    },
    freeStatus: {
      fontSize: 14,
      backgroundColor: '#fcf5f5',
      color: '#d6575c',
      padding: '4px 8px'
    },
    paidStatus: {
      fontSize: 14,
      backgroundColor: '#e8f5e9',
      color: '#348f37',
      padding: '2px 5px'
    }
  }));