import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isCodeVerified , isCodeVerifiedSession  } from '../utils/functions';
import { routes } from '../utils/constant';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isVerified = isCodeVerified();
  return (
    <Route
      {...rest}
      render={(props) =>
        isCodeVerifiedSession && isVerified && isVerified.toString() === 'true' ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={isVerified === null ? routes.login : routes.codeVerify}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
