import { schoolSchema } from 'src/utils/schemas';

// Initial State
const initialState = {
  currentSchool: schoolSchema,
  preserveValues: schoolSchema,
  apiSchool: {},
};

// Reducers (Modifies The State And Returns A New State)
const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    // School Set
    case 'SET_SCHOOL_PRESERVE': {
      return {
        ...state,
        preserveValues: {
          ...state.preserveValues,
          ...action.payload
        },
      };
    }
    case 'SET_SCHOOL': {
      return {
        ...state,
        currentSchool: {
          ...state.currentSchool,
          ...action.school,
          createdByApiSync: !!action?.school?.createdByApiSync
        },
        apiSchool: {
          ...state.currentSchool,
          ...action.school,
          createdByApiSync: !!action?.school?.createdByApiSync
        }
      };
    }
    case 'RESET_SCHOOL': {
      return {
        ...state,
        currentSchool: schoolSchema,
        preserveValues: schoolSchema,
        apiSchool: {},
      };
    }
    case 'SCHOOL_CREATED': {
      return {
        ...state,
        apiSchool: {
          ...state.currentSchool,
          ...state.apiSchool,          
          schoolIDGeneratedAPI: action.APISchoolID
        }
      };
    }
    case 'SCHOOL_DATA': {
      return {
        ...state,
        apiSchool: {
          ...state.currentSchool,
          ...state.apiSchool,
          schoolData: { ...action.APISchoolData },
          createdByApiSync: !!action?.APISchoolData?.createdByApiSync
        }
      };
    }
    // Default
    default: {
      return state;
    }
  }
};

// Exports
export default schoolReducer;
