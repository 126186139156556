import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";

export const membersAccountStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '80%'
  },
  tabNavigations: {
    padding: '10px 20px 0',
    height: '100%'
  },
  navigationButton: {
    width: 120,
    textTransform: 'none'
  },
  actionButton: {
    margin: '15px 0 20px',
    textTransform: 'none'
  },
  activeTab: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0
  },
  bredthcrumbTitle: {
    color: '#536e7b',
    fontSize: 16,
    marginRight: 24,
    display: 'inline-block',
    cursor: 'pointer'
  },

  iconStyle: {
    height: 48,
    width: 48,
    position: 'relative',
    borderRadius: 50,
    backgroundColor: '#8bc517'
  },
  image: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: '25%',
    left: '25%',
    tintColor: 'white'
  },
  stepperStyle: {
    height: `calc(100% - 64px)`,
    backgroundColor: '#ffffff',
    display: 'flex',
    '& .drawerClass': {
      marginLeft: 'unset !important',
      padding: 'unset !important'
    },
    '& .drawerClass .MuiToolbar-regular': {
      minHeight: 'unset !important'
    },
    '& .drawerClass .MuiPaper-root': {
      boxShadow: 'none !important'
    }
  },
  closeIcon: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: '25%',
    left: '25%',
    fill: '#ffffff'
  }
}));