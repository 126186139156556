import { useState, useEffect } from 'react';
import axios from 'src/config/axios'

const useGetFamilyCount = (URL) => {

  const [familyCount, setFamilyCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCount = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(URL);
      setFamilyCount(response?.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCount();
  }, []);

  return { familyCount, loading, error, fetchCount };
};

export default useGetFamilyCount;
