import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";

export const createMemberStyles = makeStyles((theme) => ({
  rootContainer: {
    backgroundColor: '#ffffff',
    height: `100%`
    // height: `calc(100% - 64px)`
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%'
  },
  iconStyle: {
    height: 48,
    width: 48,
    position: 'relative',
    borderRadius: 50,
    backgroundColor: '#8bc517'
  },
  image: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: '25%',
    left: '25%',
    tintColor: 'white'
  },
  closeIcon: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: '25%',
    left: '25%',
    fill: '#ffffff'
  },
  bredthcrumbTitle: {
    color: '#536e7b',
    fontSize: 16,
    marginRight: 24,
    display: 'inline-block',
    cursor: 'pointer'
  },
  iconStyleBredthcrumb: {
    fontSize: 22,
    verticalAlign: 'middle'
  },
  familySurnameStyle: {
    marginBottom: 12,
    marginTop: 8
  }
}));