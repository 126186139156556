import {
  Divider,
  Paper,
  Typography,
  TableCell,
  TableRow,
  Backdrop,
  CircularProgress,
  Link,
  Button
} from '@material-ui/core';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment-timezone';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Edit, HandBook } from 'src/assets';
import { RightArrow } from 'src/assets/Icons';
import Dashboard from 'src/views/layout/Dashboard';
import api_icon from 'src/assets/apiIcon.svg';
import schoolBytes from 'src/assets/schoolByte2.webp';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { routes } from 'src/utils/constant';
import { year as DefaultYear, shortYears } from 'src/utils/constant';
import NavTab from '../NavTab';
import TableList from 'src/components/TableList';
import {
  addMessage,
  loaderStart,
  loaderStop
} from 'src/redux/actions/appActions';
import { setSchool } from 'src/redux/actions/schoolActions';
import { withFirebase } from 'src/utils/firebase';
import CreateEditStudent from './CreateEditStudent';
import clsx from 'clsx';
import studentReportingIcon from 'src/assets/icn-student-reporting.svg';
import { addSchoolFormDetailsOrUpdateSchool, getSchoolByID, getSchools } from 'src/services/schools';
import RightDrawer from 'src/components/RightDrawer';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ContentState } from 'draft-js';
import { EditorState } from 'draft-js';
import { getGroups, updateGroupEntries } from 'src/services/groups';
import { studentIndexStyles } from 'src/styles/schoolStyle/students/studentIndexStyles';
import { MESSAGE_TYPES } from 'src/constants/common';



function CellItem({ row, column }) {
  const classes = studentIndexStyles();
  const childVisibility = useSelector((state) => state?.auth?.user?.country);
  if (column.id === 'accountName') {
    return (
      <span
        className={classes.hoverTag}
        onClick={() => {}}
        style={{ fontWeight: 'bold' }}
      >
        {row?.accountName}
      </span>
    );
  }
  if (column.id === 'member') {
    return (
      <a
        href={`/members/users/${row.familyId}`}
        onClick={(e) => e.stopPropagation()}
      >
        {row?.parents[0].member}
      </a>
    );
  }
  if (column.id === 'year') {
    const yearIndex = DefaultYear.findIndex((y) => y.value === row?.year);
    return yearIndex !== -1 && shortYears[DefaultYear[yearIndex].label];
  }
  if (column.id === 'studentFirstName') {
    return (
      <span
        className={clsx({
          [classes.redacted]: childVisibility !== 'Australia'
        })}
      >
        {row?.studentFirstName}
      </span>
    );
  }
  if (column.id === 'statusActivatedAt') {
    return row.statusActivatedAt
      ? moment(row.statusActivatedAt).tz('Australia/Sydney').format('DD MMM YY')
      : '';
  }
  if (column.id === 'apiSync' && row?.createdByApiSync) {
    return <img src={api_icon} />;
  }
  return '';
}

function TableRowItem({ row, columns, onClick }) {
  const classes = studentIndexStyles();
  return (
    <TableRow
      hover
      tabIndex={-1}
      className={classes.tableRow}
      onClick={onClick}
    >
      {columns.map((column) => {
        return (
          <TableCell
            key={column.id}
            align={column.align}
            className={classes.dataTableCell}
          >
            <CellItem row={row} column={column} />
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function stableSort(arr) {
  const stabilizedThis = arr.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const accountNameA = a[0].accountName?.toLowerCase();
    const accountNameB = b[0].accountName?.toLowerCase();

    if (accountNameB > accountNameA) {
      return -1;
    } else if (accountNameB < accountNameA) {
      return 1;
    } else {
      return 0;
    }
  });
  return stabilizedThis.map((el) => el[0]);
}

const columns = [
  {
    id: 'accountName',
    label: 'Account Name',
    width: 350,
    align: 'left'
  },
  {
    id: 'studentFirstName',
    label: 'First Name',
    width: 320,
    align: 'left'
  },
  {
    id: 'year',
    label: 'Year',
    width: 230,
    align: 'left'
  },
  {
    id: 'member',
    label: 'Members',
    align: 'left',
    isLink: true
  },
  {
    id: 'statusActivatedAt',
    label: 'Activated',
    align: 'left',
    width: 130
  },
  {
    id: 'apiSync',
    label: '',
    width: 100,
    align: 'center',
    format: 'icon',
    iconPosition: 'center',
    icon: false
  }
];

const SchoolStudents = ({ firebase }) => {
  const classes = studentIndexStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const prefillSchoolData = useSelector((state) => state.school.currentSchool);
  const isLoading = useSelector((state) => state.app.isLoading);
  const [currentPage, setCurrentPage] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [rowsCountPerPage, setRowsCountPerPage] = useState(20);
  const [data, setData] = useState();
  const [studentsData, setStudentsData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [yearsData, setYearsData] = useState();
  const [year, setYear] = useState('');
  const [memberStatus, setMemberStatus] = useState('active');
  const [page, setPage] = useState(0);
  const [schools, setSchools] = useState();
  const [showClearFilters, setShowClearFilters] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentStudent, setCurrentStudent] = useState();
  const [groups, setGroups] = useState();
  const [categories, setCategories] = useState();
  const [nonActiveCount, setNonActiveCount] = useState(0);
  const [openStudentReportPopUp, setOpenStudentReportPopUp] = useState(false);
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [memberData, setMemberData] = useState('')
  const [defaultNote, setDefaultNote] = useState('')

  const schoolID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  useEffect(() => {
    if (schoolID) {
      fetchYearsOnSchoolChange(schoolID);
    }
  }, [schoolID]);

  const fetchYearsOnSchoolChange = async (schoolId) => {
    // PHASE2/CHANGES/SCHOOL

    try {
      const school = await getSchoolByID(schoolId);
      const calendars = school.freeCalendars;
      const yearsData = DefaultYear.filter((y) => calendars.includes(y.value));
      setYearsData(yearsData);
      const categories = [];
      school.categories.forEach((category) => {
        categories.push(category);
      });
      setCategories(categories);
      fetchGroups();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let studentsDataArr = [];
    dispatch(loaderStart());

    // Fetch families
    firebase
      .getFamilyBySchoolIdCalendarHost(schoolID)
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          const row = doc.data();
          const childrenArr = row.childs;
          childrenArr.map((child) => {
            let mergeNewClassAndGroups = _.compact([child.group, child.groups]);
            mergeNewClassAndGroups = _.uniq(
              [].concat.apply([], mergeNewClassAndGroups)
            );
            let parents = row.parents.map((p) => ({
              email: p.emailAddress,
              member: p.firstName + ' ' + p.lastName
            }));
            studentsDataArr.push({
              parents: parents,
              accountName: child.lastName || row.parents[0]?.lastName,
              studentFirstName: child.firstName,
              studentLastName: child.lastName || row.parents[0]?.lastName,
              year: child.year,
              statusActivatedAt:
                row.status?.toLowerCase() === 'active' ? child.createdDate : '',
              status: row.status,
              groups: mergeNewClassAndGroups,
              familyId: row.id,
              id: child.id,
              school: child.school,
              createdByApiSync: child.createdByApiSync,
              planType: row.planType
            });
          });
        });
        const activeStudents = stableSort(
          studentsDataArr.filter(
            (x) => x.status?.toLowerCase() === memberStatus
             &&  x.school=== schoolID) || []
            );
        setStudentsData(activeStudents);
        setData(stableSort(studentsDataArr));
        setNonActiveCount(studentsDataArr.length - activeStudents.length);
        resetFilters();
        handlePagination(activeStudents, page, rowsCountPerPage);
      })
      .finally(() => {
        dispatch(loaderStop());
      });

    // PHASE2/CHANGES/SCHOOL

    try {
      const result = await getSchools();
      const schools = [];
      result.forEach((school) => {
        const yearData = school.freeCalendars.map((calendar) => {
          const getCurrentYear = _.find(
            DefaultYear,
            (elem) => elem.value === calendar
          );
          return ({
            label: getCurrentYear.label,
            value: calendar
          })
        });
        schools.push({
          id: school.id,
          name: school.schoolName,
          years: yearData.length ? yearData : []
        });
      });
      setSchools(schools);
    } catch (error) {
      console.error(error);
      dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
    }
  };

  const fetchGroups = () => {
      getGroups({schoolId: prefillSchoolData.id})
      .then(function (result) {
        let groupsList = [];
        result.data.forEach(function (doc) {
          const row = doc;
          groupsList.push({
            key: row.groupName,
            value: doc.id,
            years: doc.years,
            selected: false,
            groupMembers: row.groupMembers,
            category: row.category ? [row.category] : [],
            createdByApiSync: row.createdByApiSync | false,
            autoAssignedMembers: row?.autoAssignedMembers
          });
        });
        if (groupsList.length > 0) {
          groupsList.push({
            key: 'Select All',
            value: 'all',
            selected: false
          });
        }
        setGroups(groupsList);
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
      });
  };

  const { schoolName } = useMemo(() => {
    if (prefillSchoolData) {
      return {
        schoolName: prefillSchoolData.schoolName,
        id: prefillSchoolData.id
      };
    }
    return {
      schoolName: '',
      id: ''
    };
  }, [prefillSchoolData]);

  useEffect(() => {
    if (!prefillSchoolData.schoolName && schoolID) {
      // PHASE2/CHANGES/SCHOOL

      getSchoolByID(schoolID)
        .then((school) => {
          if (school) {
            dispatch(setSchool(school));
          }
        })
        .catch((error) => {
          console.error(error);
          dispatch(addMessage('Error on getting school', MESSAGE_TYPES.ERROR));
        });
    }
  }, [prefillSchoolData.schoolName, schoolID, dispatch]);

  const navTabsOptions = useMemo(() => {
    return [
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Calendar',
              link: `${routes.schoolCalendar}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Groups',
        link: `${routes.schoolGroup}/${schoolID}`
      },
      {
        label: 'Students',
        link: `${routes.schoolStudent}/${schoolID}`
      },
      {
        divider: true
      },
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Links',
              link: `${routes.schoolLinks}/${schoolID}`
            }
          ]
        : []),
      {
        label: 'Notes',
        handleTabClick: () => openRightDrawer(prefillSchoolData)
      },
      {
        label: 'Files',
        link: `${routes.schoolFiles}/${schoolID}`
      },
      ...(prefillSchoolData?.freeCalendars?.length
        ? [
            {
              label: 'Form',
              link: `${routes.schoolForm}/${schoolID}`
            }
          ]
        : []),
      {
        divider: true
      },
      {
        label: 'Account',
        link: `${routes.schoolAccount}/${schoolID}`
      }
    ];
  }, [prefillSchoolData]);

  // Page change next/prev function
  const handlePageChange = (currentPage, newPage, rowsPerPage) => {
    const updatedPage = newPage;
    handlePagination(studentsData, updatedPage, rowsPerPage);
  };

  // Page size change function
  const handleRowsPerPageChange = (rowsPerPage) => {
    setRowsCountPerPage(rowsPerPage);
    handlePagination(studentsData, page, rowsPerPage);
  };

  // Search text function
  const searchChangeHandler = (e) => {
    setCurrentPage((prev) => !prev);
    const { value } = e.target;
    const text = value?.toLowerCase();
    setSearchText(text);
    searchDelayed(text);
  };

  const searchDelayed = _.debounce((value) => {
    applyFilter(value?.toLowerCase(), year, memberStatus);
  }, 500);

  const handlePagination = (studentsDataArr, currentPage, rowsPerPage) => {
    let startIndex = rowsPerPage * currentPage;
    let endIndex = rowsPerPage * (currentPage + 1);
    if (endIndex > studentsDataArr.length) {
      endIndex = studentsDataArr.length;
    }
    const filteredDataArr = studentsDataArr.slice(startIndex, endIndex);
    setFilteredData(filteredDataArr);
  };

  const onClose = () => {
    setEditMode(false);
    setOpenDrawer(false);
  };

  const onCloseForm = () => {
    const defaultData = draftToHtml(
      convertToRaw(defaultNote.getCurrentContent())
    );
    const memberNotes = memberData.notes;
    if (defaultData === memberNotes) {
      // handleClose();
      onClose();
    } else if (
      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
      !memberNotes
    ) {
      onClose();
    } else if (memberNotes === '') {
      // handleClose();
      onClose();
    }
  };

  const onSaveChanges = async () => {
    // PHASE2/CHANGES/SCHOOL
    dispatch(loaderStart());

    const payload = {
      notes: memberData?.notes,
    }

    try {
      await addSchoolFormDetailsOrUpdateSchool(
        prefillSchoolData.id,
        payload
      );
      dispatch(loaderStop());
      dispatch(setSchool(payload));
      dispatch(addMessage('Changes saved'));
      const contentBlock = htmlToDraft(payload?.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      handleClose();
    } catch (error) {
      console.error(error);
      dispatch(addMessage('Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  const onEditorChange = (value) => {
    const editorData = draftToHtml(convertToRaw(value.getCurrentContent()));
    setMemberData({ ...memberData, notes: editorData });
  };



  const openRightDrawer = useCallback(
    (data) => {
      setMemberData(data);
      const contentBlock = htmlToDraft(data.notes || '');
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDefaultNote(editorState);
      }
      setOpenDrawer(true);
    },
    [setOpenDrawer, prefillSchoolData]
  );


  const applyFilter = (value, year, status) => {
    let updatedData = data.filter((d) => {
      let memberEmailSearch = d.parents.filter((p) =>
        p.email.toLowerCase().includes(value)
      );
      return (
        (memberEmailSearch.length > 0 ||
          d.accountName.toLowerCase().includes(value)) &&
        (year ? d.year === year : true) && prefillSchoolData.id === d.school
      );
    });
    let totalCount = updatedData.length;
    if (status === 'active') {
      updatedData = _.remove(
        updatedData,
        (d) => d.status?.toLowerCase() === 'active'
      );
    } else {
      updatedData = _.remove(
        updatedData,
        (d) => d.status?.toLowerCase() !== 'active'
      );
    }
    updatedData = stableSort(updatedData);
    setStudentsData(updatedData);
    handlePagination(updatedData, 0, rowsCountPerPage);
    setNonActiveCount(totalCount - updatedData.length);
  };

  const yearChangeHandler = (e) => {
    setYear(e.target.value);
    setShowClearFilters(e.target.value !== '');
    setCurrentPage((prev) => !prev);
    setPage(0);
    applyFilter(searchText, e.target.value, memberStatus);
  };

  const studentCategoryChangeHandler = (e) => {
    let status = e.target.value === 'active' ? 'active' : 'nonActive';
    if (status === 'nonActive') {
      setShowClearFilters(true);
    }
    setMemberStatus(status);
    setCurrentPage((prev) => !prev);
    setPage(0);
    applyFilter(searchText, year, status);
  };

  const handleClose = useCallback(
    (isUpdated) => {
      setIsOpen(false);
      setCurrentStudent();
      if (isUpdated) {
        fetchData();
      }
    },
    [setIsOpen]
  );

  const resetFilters = (isApplyFilter) => {
    setSearchText('');
    setMemberStatus('active');
    setCurrentPage((prev) => !prev);
    setPage(0);
    setYear('');
    setShowClearFilters(false);
    if (isApplyFilter) {
      filterClearHandler();
    }
  };

  const filterClearHandler = () => {
    let status = 'active';
    applyFilter('', '', status);
  };

  const handleUpdate = (formData, groupData, student) => {
    firebase
      .getFamilyByID(student.familyId)
      .then(async (doc) => {
        let familyData = doc.data();

        if (familyData) {
          let childData = [...familyData.childs];
          let childIndex = childData
            ? _.findIndex(childData, { id: student.id })
            : -1;
          if (childIndex !== -1) {
            let allGroupIds = [...student.groups];
            let selectedGroupsIds =
              groupData.length > 0
                ? _.filter(groupData, (group) => group.selected).map(
                    (g) => g.value
                  )
                : [];
            let receivedGroupIds = _.filter(
              selectedGroupsIds,
              (group) => !allGroupIds.includes(group)
            );
            let removedGroupsIds = _.filter(
              allGroupIds,
              (group) => !selectedGroupsIds.includes(group)
            );
            let pushRecord = {
              members: []
            };

            if (receivedGroupIds) {
              receivedGroupIds.forEach((el) => {
                pushRecord.members.push({
                  childId: student.id,
                  groupId: el,
                  isAdd: true
                });
              });
            }

            if (removedGroupsIds) {
              removedGroupsIds.forEach((el) => {
                pushRecord.members.push({
                  childId: student.id,
                  groupId: el,
                  isAdd: false
                });
              });
            }
            let childSchool = schools.find((school)=>school.id ===formData.school)
            childData[childIndex] = {
              ...childData[childIndex],
              year: formData.year,
              firstName: formData.firstName,
              lastName: formData.surname,
              school: formData.school,
              years:childSchool.years,
              groups: [...selectedGroupsIds],
              initials: `${formData.firstName[0] || ''}${
                formData.surname[0] || ''
              }`
            };

            familyData.childs = childData;

            let schoolData = [];
            if (familyData.childs.length && familyData.planType !== 'Free') {
              schoolData = familyData.childs.reduce((v, { school }) => {
                v.push(school);
                return v;
              }, []);
            }

            let finalSchools = [...familyData.schools];

            for (let i = 0; i < childData.length; i++) {
              if (childData[i].id === student.id) {
                let schoolIndex = finalSchools.findIndex(
                  (school) => school.schoolName === childData[i].school
                );
                let school = schools.find(
                  (school) => school.id === childData[i].school
                );
                if (schoolIndex === -1 && finalSchools.length <= i) {
                  finalSchools.push({
                    schoolName: childData[i].school,
                    year: childData[i].year,
                    years: [...school.years],
                    color: ''
                  });
                } else {
                  finalSchools[i] = {
                    ...finalSchools[i],
                    schoolName: childData[i].school,
                    year: childData[i].year,
                    years: [...school.years]
                  };
                }
              }
            }
            let schoolIds = familyData.childs.map((child) => child.school);
            familyData.childsSchools = schoolData;
            familyData.schoolIds = schoolIds;
            familyData.schools = finalSchools;

            //Update family
            firebase
              .updateFamily(familyData, student.familyId)
              .then(async () => {
                dispatch(addMessage('Student updated successfully'));
                if (pushRecord.members.length > 0) {
                  try {
                    await updateGroupEntries(pushRecord);
                  }catch(error){
                    handleClose();
                    dispatch(loaderStop());
                  }
                }else{
                  handleClose();
                  fetchData();
                  dispatch(loaderStop());
                }
              })
              .catch(() => {
                dispatch(loaderStop());
                handleClose();
                dispatch(addMessage('Error on updating student', MESSAGE_TYPES.ERROR));
              })
              .finally(() => {
                fetchData();
                handleClose();
                dispatch(loaderStop());
              });
          }
        }
      })
      .catch(() => {
        dispatch(addMessage('Error on updating student', MESSAGE_TYPES.ERROR));
      });
  };

  const handleOpenEditModal = useCallback(
    (row) => {
      setCurrentStudent(row);
      setIsOpen(true);
    },
    [setIsOpen, setCurrentStudent]
  );

  return (
    <Dashboard>
      <div className={classes.root}>
        <div>
          <Typography
            variant="body1"
            className={classes.bredthcrumbTitle}
            onClick={() => history && history.push(routes.schools)}
          >
            Schools
          </Typography>
          <RightArrow
            viewBox="0 0 24 24"
            height={24}
            width={24}
            style={{ fontSize: 22, verticalAlign: 'middle' }}
          />
        </div>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20, marginTop: 22 }}
        >
          {schoolName}
        </Typography>
        <Paper style={{ position: 'relative' }}>
          <img
            className={classes.reportImage}
            src={studentReportingIcon}
            alt="Student report"
            onClick={() => setOpenStudentReportPopUp(!openStudentReportPopUp)}
          />
          {openStudentReportPopUp ? (
            <article className={classes.studentReportPopUp}>
              <div style={{ fontWeight: 500, marginBottom: '10px' }}>
                Student reporting
              </div>
              {prefillSchoolData?.createdByApiSync ? (
                <div className={classes.flexBoxBetween}>
                  <span>Population</span>
                  <span>
                    {prefillSchoolData?.studentReport?.apiStudent || 0}
                  </span>
                </div>
              ) : null}
              <div className={classes.flexBoxBetween}>
                <span>Active</span>
                <span>{prefillSchoolData?.studentReport?.active || 0}</span>
              </div>
              <div className={classes.flexBoxBetween}>
                <span>Inactive</span>
                <span>{prefillSchoolData?.studentReport?.inActive || 0}</span>
              </div>
              {prefillSchoolData?.createdByApiSync ? (
                <div className={classes.flexBoxBetween}>
                  <span>Opportunity</span>
                  <span>
                    {prefillSchoolData?.studentReport?.nonRegistered || 0}
                  </span>
                </div>
              ) : null}
            </article>
          ) : null}
          <section
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 20px 0'
            }}
          >
            <div>
              <NavTab tabs={navTabsOptions} selectedTab={'Students'} />
            </div>
            {prefillSchoolData && prefillSchoolData?.createdByApiSync && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '1rem'
                  // padding: '0 2rem'
                }}
              >
                <img src={schoolBytes} height="38px" />
                <img src={api_icon} />
              </div>
            )}
          </section>
          <Divider />
          <TableList
            currentPage={currentPage}
            columns={columns}
            rows={filteredData}
            totalCount={studentsData?.length}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            setRowsCountPerPage={setRowsCountPerPage}
            searchPlaceholderText={'Account name or member email'}
            year={year}
            years={yearsData && [...yearsData]}
            yearChangeHandlerFree={yearChangeHandler}
            searchChangeHandler={searchChangeHandler}
            studentCategoryChangeHandler={studentCategoryChangeHandler}
            memberStatus={memberStatus}
            showClearFilters={showClearFilters}
            filterClearHandler={() => resetFilters(true)}
            nonActiveCount={nonActiveCount}
            tableRow={(row, index) => (
              <TableRowItem
                key={index}
                columns={columns}
                row={row}
                onClick={() => handleOpenEditModal(row)}
              />
            )}
            searchText={searchText}
          />
        </Paper>
      </div>
      {isOpen && (
        <CreateEditStudent
          isOpen={isOpen}
          onClose={handleClose}
          handleSchoolChange={fetchYearsOnSchoolChange}
          onUpdate={handleUpdate}
          schools={schools}
          years={yearsData}
          student={currentStudent}
          groups={groups}
          categories={categories}
          isEdit={true}
        />
      )}

      <RightDrawer
        isOpen={isOpenDrawer}
        onClose={() => {
          onClose();
          onCloseForm();
        }}
        width={900}
        style={{ overflowX: 'hidden' }}
      >
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
        <div className={classes.drawerLeftContent}>
          <div className={classes.leftContentMargin}>
            <div className={classes.leftContentHeader}>
              {isEditMode ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.siderEdit}
                  onClick={() => {
                    const defaultData = draftToHtml(
                      convertToRaw(defaultNote.getCurrentContent())
                    );
                    const memberNotes = memberData.notes;
                    if (defaultData === memberNotes) {
                      handleClose();
                    } else if (
                      defaultData.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' &&
                      !memberNotes
                    ) {
                      handleClose();
                    } else {
                      onSaveChanges();
                    }
                  }}
                  style={{ width: 165 }}
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.siderEdit}
                  onClick={() => setEditMode(true)}
                  startIcon={<img src={Edit} />}
                >
                  Edit
                </Button>
              )}
            </div>
            <Divider
              variant="fullWidth"
              style={{ marginBottom: 20, marginTop: 22 }}
            />
            {!isEditMode && !memberData.notes && (
              <Typography className={classes.siderDetail}>
                Click Edit to add a note...
              </Typography>
            )}
            {!isEditMode &&
              memberData.notes &&
              memberData.notes.replace(/(\r\n|\n|\r)/gm, '') === '<p></p>' && (
                <Typography className={classes.siderDetail}>
                  Click Edit to add a note...
                </Typography>
              )}
            <Editor
              stripPastedStyles={true}
              readOnly={!isEditMode}
              defaultEditorState={defaultNote}
              toolbarClassName={
                !isEditMode ? classes.toolbarHidden : classes.toolbar
              }
              onEditorStateChange={onEditorChange}
              editorClassName={
                !isEditMode ? classes.textEditor : classes.textEditorOnEdit
              }
              toolbar={{
                options: ['inline', 'list', 'link'],
                inline: {
                  options: ['bold', 'italic', 'underline', 'strikethrough']
                },
                list: {
                  options: ['unordered']
                },
                link: {
                  defaultTargetOption: '_blank',
                  options: ['link']
                }
              }}
            />
          </div>
        </div>
        <div className={classes.drawerRightContent}>
          <div style={{ padding: 18, zIndex: 1 }}>
            <div className={classes.header}>
              {memberData.schoolName && (
                <Typography
                  className={classes.textwraplabel}
                  title={memberData.schoolName}
                >
                  {memberData.schoolName}
                </Typography>
              )}
            </div>
            <Divider style={{ marginBottom: 20 }} />
            {memberData.country && (
              <>
                <Typography className={classes.heading}>COUNTRY</Typography>
                <Typography className={classes.siderDetail}>
                  {memberData.country}
                </Typography>
              </>
            )}
            {memberData.websiteUrl && (
              <>
                <Typography className={classes.heading}>WEBSITE</Typography>
                <Link
                  color="secondary"
                  component={RouterLink}
                  onClick={() => window.open(memberData.websiteUrl, '_blank')}
                  className={classes.siderDetail}
                  style={{ textDecoration: 'none', cursor: 'pointer' }}
                >
                  Click Here
                </Link>
              </>
            )}
          </div>
          <div className={classes.handBookContainer}>
            <img src={HandBook} alt="" className={classes.handBookClass} />
          </div>
        </div>
      </RightDrawer>
    </Dashboard>
  );
};

export default withFirebase(SchoolStudents);
