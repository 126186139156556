import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  FormHelperText,
  TextField,
  FormControl,
  Checkbox,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Input
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Loop, Add } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { colors, year as DefaultYear } from 'src/utils/constant';
import { parentSchema, childSchema } from 'src/utils/schemas';
import { generateFileHash, generatePatternPassword } from 'src/utils/functions';
import { addMessage } from 'src/redux/actions/appActions';
import { withFirebase } from 'src/utils/firebase';
import { loaderStart, loaderStop } from 'src/redux/actions/appActions';
import { setSchool } from 'src/redux/actions/schoolActions';
import PhoneInput from 'src/components/PhoneInput';
import apiShortIcon from 'src/assets/api_icon_outline.svg';
import { getSchools } from 'src/services/schools';
import { getGroups } from 'src/services/groups';
import { MESSAGE_TYPES } from 'src/constants/common';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%'
  },
  cancelButton: {
    width: 106,
    height: 50,
    borderRadius: 3,
    border: 'solid 1 ',
    backgroundColor: colors.primaryLightGrey,
    borderColor: colors.primaryLightGrey,
    float: 'left'
  },
  title: {
    width: 303,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    marginTop: 24
  },
  subtitle: {
    width: 400,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey
  },
  label: {
    width: 200,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    marginTop: 24
  },
  controlBox: {
    width: 572,
    height: 60,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    marginTop: 24,
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  inputControlBox: {
    width: 572,
    backgroundColor: '#ffffff',
    marginTop: 24
  },
  childVisiileCotrolBox: {
    width: 572,
    marginTop: 24,
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)'
  },
  errorBox: {
    border: '1px solid red'
  },
  radio: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  checked: {},
  checkbox: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  rightAd: {
    position: 'absolute',
    top: '20%',
    right: '5%',
    width: 250,
    height: 170,
    borderRadius: 10,
    backgroundColor: '#f3f4f6'
  },
  adTitle: {
    width: 169,
    height: 28,
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHight: 'normal',
    letterSpacing: 0.2,
    color: colors.primaryDark,
    margin: '5%'
  },
  adSubTitle: {
    width: 200,
    height: 54,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    margin: '5%'
  },
  adButton: {
    width: 134,
    height: 30,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    marginTop: '5%'
  },
  adButtonText: {
    height: 18,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: colors.primary,
    marginTop: '4.3%'
  },
  generate: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: colors.primary,
    float: 'right',
    display: 'flex',
    height: '30px',
    lineHeight: '30px',
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center'
  },
  buttonText: {
    width: 94,
    height: 50,
    borderRadius: 3,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.white,
    marginTop: '4.3%'
  },
  primaryButtonSave: {
    width: 106,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginLeft: '15%',
    marginTop: '5.5%',
    color: colors.white
  },
  buttonTextSave: {
    height: 24,
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center'
  },
  closeIconRotate: {
    transform: 'rotate(-180deg)',
    transition: 'transform 999ms ease',
    fill: colors.primary
  },
  NonAUchildBox: {
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)',
    pointerEvents: 'none',
    userSelect: 'none',
    '&&::selection': {
      color: 'transparent',
      background: 'transparent'
    }
  },
  menuItemClass: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  AuChildBox: {}
}));

const parentObject = {
  firstName: '',
  lastName: '',
  initials: '',
  emailAddress: '',
  phoneNo: ''
};
const childObject = {
  firstName: '',
  lastName: '',
  initials: '',
  userName: '',
  password: '',
  school: '',
  year: '',
  group: '',
  groups: [],
  schoolPortal: '',
  schoolPortalLogin: '',
  schoolPortalPassword: ''
};

function AddFamilyMember({
  firebase,
  submitForm,
  accountFlag,
  isEdit,
  handleCancelDisable,
  setHandleCancelClick,
  handleCancelClick
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [formKey, setFormKey] = useState(Math.random());
  const [initialValues, setInitialValue] = useState({
    parents: [],
    childs: []
  });
  const onTogglePasswordFieldType = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
    } else {
      setPasswordFieldType('password');
    }
  };
  const prefillFamilyData = useSelector((state) => state.family.currentFamily);
  const [schools, setSchools] = React.useState([]);
  const [fields, setFields] = useState([
    { ...parentObject, id: generateFileHash(20) }
  ]);
  const [fieldsChild, setFieldsChild] = useState([
    { ...childObject, id: generateFileHash(20) }
  ]);
  const [isRotate, setRotate] = useState(false);
  const [isSave, setSave] = useState(false);
  const [Groups, setGroupData] = useState([]);

  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const childVisiility = useSelector((state) => state?.auth?.user?.country);
  // Existing Email check
  const [isPrimaryEmailExistCheck, setIsPrimaryEmailExistCheck] =
    useState(false);
  const [isUserExistPrimary, setIsUserExistPrimary] = useState('');

  useEffect(() => {
    // PHASE2/CHANGES/SCHOOL
    getSchools().then(result => {
      const schools = [];
      result.forEach((school) => {
        schools.push({
          id: school.id,
          name: school.schoolName,
          isFree:school.freeCalendars.length ? true : false,
          years: school.freeCalendars,
        });
      });
      setSchools(schools);
    }).catch(error => {
      console.error(error);
      dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
    })
  }, []);

  useEffect(() => {
    setInitialValue({
      parents: prefillFamilyData.parents.length
        ? prefillFamilyData.parents
        : [{ ...parentObject, id: generateFileHash(20) }],
      childs: prefillFamilyData.childs.length
        ? prefillFamilyData.childs
        : prefillFamilyData.planType !== 'FreeVersion'
        ? [{ ...childObject, id: generateFileHash(20) }]
        : []
    });
    const parentsValues = (prefillFamilyData.parents || []).map((val) => {
      return val;
    });
    if (parentsValues.length) setFields(parentsValues);

    const childsValues = (prefillFamilyData.childs || []).map((val) => {
      return val;
    });

    const updatedValue = childsValues?.map((value, index) => {
      const findYears = _.find(
        schools,
        (school) => school.id === value?.school
      );
      const yearValue = findYears?.years?.map((data) => {
        const getCurrentYear = _.find(
          DefaultYear,
          (elem) => elem.value === data
        );
        return {
          label: getCurrentYear?.label,
          value: data
        };
      });
      const allCategory = findYears?.categories?.map((data) => {
        return {
          key: data?.name,
          value: data?.id
        };
      });
      const sortYear = yearValue?.sort(function (a, b) {
        return parseInt(a.value) - parseInt(b.value);
      });
      return {
        ...value,
        school: value.school,
        year: value.year,
        years: yearValue ? yearValue : [],
      };
    });

    if (updatedValue.length) {
      setFieldsChild([...updatedValue]);
    }
  }, [prefillFamilyData, schools]);

  useEffect(() => {
    if (handleCancelClick) {
      setInitialValue({
        parents: prefillFamilyData.parents.length
          ? prefillFamilyData.parents
          : [{ ...parentObject, id: generateFileHash(20) }],
        childs: prefillFamilyData.childs.length
          ? prefillFamilyData.childs
          : prefillFamilyData.planType !== 'FreeVersion'
          ? [{ ...childObject, id: generateFileHash(20) }]
          : []
      });
      const parentsValues = (prefillFamilyData.parents || []).map((val) => {
        return val;
      });
      if (parentsValues.length) setFields(parentsValues);

      const childsValues = (prefillFamilyData.childs || []).map((val) => {
        return val;
      });
      if (childsValues.length) setFieldsChild(childsValues);
      setFormKey(Math.random());
      setHandleCancelClick(false);
    }
  }, [handleCancelClick, setHandleCancelClick]);

  useEffect(() => {
    getGroups().then(function (result) {
      let GroupData = [];

      result.data.forEach(function (item) {
        if (item.groupName) {
          GroupData.push(item);
        }
      });
      setGroupData(GroupData);
      dispatch(loaderStop());
    });
  }, []);

  const handleParentAdd = (formValue) => {
    setInitialValue({
      ...formValue,
      parents: [
        ...formValue.parents,
        { ...parentObject, id: generateFileHash(20) }
      ]
    });
    const updateValues = [...fields];
    updateValues.push({ ...parentSchema, id: generateFileHash(20) });
    setFields(updateValues);
  };

  const handleParentRemove = (i) => {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  };

  const handleChildAdd = (formValue) => {
    setInitialValue({
      ...formValue,
      childs: [
        ...formValue.childs,
        { ...childObject, id: generateFileHash(20) }
      ]
    });
    const values = [...fieldsChild];
    values.push({ ...childSchema, id: generateFileHash(20) });
    setFieldsChild(values);
  };

  const handleChildRemove = (i) => {
    const values = [...fieldsChild];
    values.splice(i, 1);
    setFieldsChild(values);
  };

  // SCHEMA VALIDATOR FOR CREATE NEW PASSWORD FORM
  const validationSchema = Yup.object().shape({
    parents: Yup.array()
      .of(
        Yup.object().shape({
          firstName: Yup.string().trim().required('First name is required'),
          lastName: Yup.string().trim().required('Last name is required'),
          initials: Yup.string()
            .trim()
            .required('Initials is required')
            .max(2, 'Initials contains only 2 characters'),
          emailAddress: Yup.string()
            .trim()
            .email()
            .required('Valid email is required'),
          profileImage: Yup.mixed(),
          id: Yup.string().trim()
        })
      )
      .min(1, 'At least one primary parent is required')
      .required(),
    childs: Yup.array().of(
      Yup.object().shape({
        userId: Yup.string().trim(),
        firstName:
          prefillFamilyData.planType !== 'Free'
            ? Yup.string().trim().required('First name is required')
            : null,
        lastName:
          prefillFamilyData.planType !== 'Free'
            ? Yup.string().trim().required('Last name is required')
            : null,
        initials:
          prefillFamilyData.planType !== 'Free'
            ? Yup.string()
                .trim()
                .required('Initials is required')
                .max(2, 'Initials contains only 2 characters')
            : null,
        profileImage:
          prefillFamilyData.planType !== 'Free' ? Yup.mixed() : null,
        userName:
          prefillFamilyData.planType !== 'Free'
            ? Yup.string()
                .trim()
                .required('User Name is required')
                .matches(/^[a-zA-Z0-9]+$/, 'Invalid User Name')
            : null,
        password:
          prefillFamilyData.planType !== 'Free'
            ? !!isEdit
              ? Yup.string()
                  .trim()
                  .matches(
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
                    'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.',
                    { excludeEmptyString: true }
                  )
              : Yup.string()
                  .trim()
                  .required('Password is required')
                  .matches(
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
                    'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.'
                  )
            : null,
        school:
          prefillFamilyData.planType !== 'Free'
            ? Yup.string().trim().required('School is required')
            : null,
        year:
          prefillFamilyData.planType !== 'Free'
            ? Yup.string().trim().required('Year is required')
            : null,
        schoolPortal:
          prefillFamilyData.planType !== 'Free'
            ? Yup.string().trim().url('Enter valid URL')
            : null,
        schoolPortalLogin:
          prefillFamilyData.planType !== 'Free' ? Yup.string().trim() : null,
        schoolPortalPassword:
          prefillFamilyData.planType !== 'Free' ? Yup.string().trim() : null,
        id: Yup.string().trim()
      })
    )
  });

  const generatePassword = (idx, setFieldValue) => {
    setRotate(true);
    setFieldValue(`childs[${idx}].password`, generatePatternPassword(8));
    dispatch(addMessage('Password generated and set'));
    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };

  const getYearFromSchoolId = (
    schoolID,
    selectedIndex,
    values,
    setFieldValue
  ) => {
    const updatedValue = values?.childs?.map((value, index) => {
      if (index === selectedIndex) {
        const findYears = _.find(schools, (school) => school.id === schoolID);
        const yearValue = findYears.years.map((data) => {
          const getCurrentYear = _.find(
            DefaultYear,
            (elem) => elem.value === data
          );
          return {
            label: getCurrentYear?.label,
            value: data
          };
        });
        const allCategory = findYears?.categories?.map((data) => {
          return {
            key: data?.name,
            value: data?.id
          };
        });
        const sortYear = yearValue?.sort(function (a, b) {
          return parseInt(a.value) - parseInt(b.value);
        });
        return {
          ...value,
          school: schoolID,
          year: '',
          years: yearValue ? yearValue : [],
        };
      } else {
        return { ...value };
      }
    });
    setFieldValue(`childs[${selectedIndex}].school`, schoolID);
    setFieldValue(`childs[${selectedIndex}].groups`, '');
    setFieldsChild([...updatedValue]);
    setInitialValue({
      ...values,
      childs: [...updatedValue]
    });
  };

  const checkUserExistOrNot = (userName, setErrors, index, values) => {
    const email = `${userName}@mywhanau-user.com`;
    firebase
      .isUserExists(email)
      .then((results) => {
        if (results.size > 0) {
          const errorArray = values.childs.map((child) => {
            return null;
          });
          errorArray[index] = {
            userName: `Username is already in use by another account.`
          };
          const child = { childs: [...errorArray] };
          setErrors(child);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onUserNameChangeTextDelayed = _.debounce(checkUserExistOrNot, 500);

  const handleGroupChange = (
    event,
    selectedIndex,
    categoryIndex,
    childs,
    setFieldValue
  ) => {
    const updatedGroups = Groups.map((groupData, index) => {
      let selectValue = event.target.value;

      if (selectValue.includes(groupData.id)) {
        return {
          ...groupData,
          selected: true
        };
      } else {
        return {
          ...groupData,
          selected: false
        };
      }
    });
    setGroupData(updatedGroups);
  };

  return (
    <div>
      <div className={classes.title}>Family Members</div>
      <div className={classes.subtitle}>
        Add the details for each family member. More can be added at any time by
        editing the family account.
      </div>
      <Formik
        key={formKey}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors }) => {
          let hasError = [];
          for await (const [index, child] of values.childs.entries()) {
            if (
              (prefillFamilyData.childs[index] &&
                !prefillFamilyData.childs[index].userName) ||
              !prefillFamilyData.childs[index] ||
              child.userName !== prefillFamilyData.childs[index].userName
            ) {
              const email = `${child.userName}@mywhanau-user.com`;
              await firebase
                .isUserExists(email)
                .then((results) => {
                  if (results.size > 0) {
                    const errorArray = values.childs.map((childData) => {
                      return null;
                    });
                    errorArray[index] = {
                      userName: `Username is already in use by another account.`
                    };
                    const child = { childs: [...errorArray] };
                    setErrors(child);
                    hasError.push(values.childs[index].userName);
                  }
                })
                .catch((error) => {
                  hasError.push(values.childs[index].userName);
                  console.log(error);
                });
            } else {
              hasError = false;
            }
          }
          if (!hasError.length) {
            if (accountFlag && isSave) {
              submitForm(values, true);
            } else {
              submitForm(values);
              handleCancelDisable(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          setErrors
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <div className={classes.label}>Parent 1 (Primary) </div>
              {values?.parents?.map((field, idx) => {
                const ID = idx;
                return (
                  <div key={`${field}-${idx}`}>
                    {idx > 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          width: 572,
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography className={classes.title}>
                          Parent {idx + 1}
                        </Typography>
                        {isEdit && ID >= prefillFamilyData.parents.length && (
                          <IconButton
                            aria-label="upload picture"
                            component="span"
                            style={{ marginTop: 15 }}
                            onClick={() => {
                              const newValues = values.parents;
                              newValues.splice(idx, 1);
                              setFieldValue('parents', newValues);
                              handleParentRemove(idx);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                        {!isEdit && (
                          <IconButton
                            aria-label="upload picture"
                            component="span"
                            style={{ marginTop: 15 }}
                            onClick={() => {
                              const newValues = values.parents;
                              newValues.splice(idx, 1);
                              setFieldValue('parents', newValues);
                              handleParentRemove(idx);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                      <TextField
                        label="First Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        name={`parents[${idx}].firstName`}
                        onChange={(e) => {
                          accountFlag && isEdit && handleCancelDisable(true);
                          setFieldValue(
                            `parents[${idx}].firstName`,
                            e.target.value
                          );
                          setFieldValue(
                            `parents[${idx}].initials`,
                            `${e.target.value.charAt(0)}${values.parents[
                              idx
                            ].lastName.charAt(0)}`
                          );
                        }}
                        value={
                          (values.parents[idx] &&
                            values.parents[idx].firstName) ||
                          ''
                        }
                        error={Boolean(
                          touched.parents &&
                            errors.parents &&
                            touched.parents[idx] &&
                            touched.parents[idx].firstName &&
                            errors.parents[idx] &&
                            errors.parents[idx].firstName
                        )}
                        helperText={
                          touched.parents &&
                          errors.parents &&
                          touched.parents[idx] &&
                          touched.parents[idx].firstName &&
                          errors.parents[idx] &&
                          errors.parents[idx].firstName
                        }
                        className={classes.inputControlBox}
                      />
                    </FormControl>
                    <FormControl component="fieldset">
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        name={`parents[${idx}].lastName`}
                        onChange={(e) => {
                          accountFlag && isEdit && handleCancelDisable(true);
                          setFieldValue(
                            `parents[${idx}].lastName`,
                            e.target.value
                          );
                          setFieldValue(
                            `parents[${idx}].initials`,
                            `${values.parents[idx].firstName.charAt(
                              0
                            )}${e.target.value.charAt(0)}`
                          );
                        }}
                        value={
                          (values.parents[idx] &&
                            values.parents[idx].lastName) ||
                          ''
                        }
                        error={Boolean(
                          touched.parents &&
                            errors.parents &&
                            touched.parents[idx] &&
                            touched.parents[idx].lastName &&
                            errors.parents[idx] &&
                            errors.parents[idx].lastName
                        )}
                        helperText={
                          touched.parents &&
                          errors.parents &&
                          touched.parents[idx] &&
                          touched.parents[idx].lastName &&
                          errors.parents[idx] &&
                          errors.parents[idx].lastName
                        }
                        className={classes.inputControlBox}
                      />
                    </FormControl>
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                      <TextField
                        label="Initials"
                        variant="outlined"
                        className={classes.inputControlBox}
                        onBlur={handleBlur}
                        name={`parents[${idx}].initials`}
                        onChange={(e) => {
                          accountFlag && isEdit && handleCancelDisable(true);
                          setFieldValue(
                            `parents[${idx}].initials`,
                            e.target.value
                          );
                        }}
                        value={
                          (values.parents[idx] &&
                            values.parents[idx].initials) ||
                          ''
                        }
                        error={Boolean(
                          touched.parents &&
                            errors.parents &&
                            touched.parents[idx] &&
                            touched.parents[idx].initials &&
                            errors.parents[idx] &&
                            errors.parents[idx].initials
                        )}
                        helperText={
                          touched.parents &&
                          errors.parents &&
                          touched.parents[idx] &&
                          touched.parents[idx].initials &&
                          errors.parents[idx] &&
                          errors.parents[idx].initials
                        }
                        style={{ width: 100 }}
                      />
                    </FormControl>
                    <FormControl component="fieldset">
                      <TextField
                        label={
                          idx === 0
                            ? `Email Address (Primary)`
                            : `Email Address`
                        }
                        variant="outlined"
                        className={classes.inputControlBox}
                        onBlur={async (event) => {
                          if (event.target.value) {
                            const isEmailExist =
                              await firebase.isPrimaryEmailExist(
                                event.target.value
                              );
                            if (isEmailExist?.size > 0) {
                              setIsPrimaryEmailExistCheck(true);
                              const familyName =
                                isEmailExist?.docs[0].data().familySurname;
                              setIsUserExistPrimary(familyName);
                            } else {
                              setIsPrimaryEmailExistCheck(false);
                            }
                          }
                        }}
                        name={`parents[${idx}].emailAddress`}
                        onChange={(e) => {
                          accountFlag && isEdit && handleCancelDisable(true);
                          setFieldValue(
                            `parents[${idx}].emailAddress`,
                            e.target.value.trim()
                          );
                        }}
                        value={
                          (values.parents[idx] &&
                            values.parents[idx].emailAddress) ||
                          ''
                        }
                        error={
                          isPrimaryEmailExistCheck ||
                          Boolean(
                            touched.parents &&
                              errors.parents &&
                              touched.parents[idx] &&
                              touched.parents[idx].emailAddress &&
                              errors.parents[idx] &&
                              errors.parents[idx].emailAddress &&
                              'Valid email is required'
                          )
                        }
                        helperText={
                          (touched.parents &&
                            errors.parents &&
                            touched.parents[idx] &&
                            touched.parents[idx].emailAddress &&
                            errors.parents[idx] &&
                            errors.parents[idx].emailAddress &&
                            'Valid email is required') ||
                          (isPrimaryEmailExistCheck &&
                            `Email already exists (${isUserExistPrimary})`)
                        }
                      />
                    </FormControl>
                    {errors.parents && _.isString(errors.parents) && (
                      <FormHelperText
                        error={Boolean(
                          errors.parents && _.isString(errors.parents)
                        )}
                      >
                        {errors.parents}
                      </FormHelperText>
                    )}
                    <br />

                    {prefillFamilyData.planType === 'Free' && (
                      <FormControl component="fieldset">
                        <TextField
                          label="Phone Number"
                          variant="outlined"
                          name={`parents[${idx}].phoneNo`}
                          value={
                            (values.parents[idx] &&
                              values.parents[idx].phoneNo) ||
                            ''
                          }
                          disableUnderline
                          onBlur={handleBlur}
                          onChange={(e) => {
                            accountFlag && isEdit && handleCancelDisable(true);
                            setFieldValue(
                              `parents[${idx}].phoneNo`,
                              e.target.value
                            );
                          }}
                          className={classes.inputControlBox}
                          style={{ margin: '20px 0 20px 0' }}
                        />
                      </FormControl>
                    )}
                  </div>
                );
              })}
              <Button
                className={classes.adButton}
                style={{
                  backgroundColor: '#f4f5f7',
                  marginLeft: 0,
                  height: 50,
                  color: colors.primaryDarkGrey
                }}
                onClick={() => {
                  handleParentAdd(values);
                }}
              >
                <Add />
                Add Parent
              </Button>
              {prefillFamilyData.planType !== 'FreeVersion' && (
                <div>
                  <div className={classes.label}>Child 1 </div>
                  {fieldsChild?.map((fieldChild, idx) => {
                    const ID = idx;
                    const selectedSchool = schools?.find(s => s?.id === values?.childs[idx]?.school);
                    return (
                      <div key={`${fieldChild}-${idx}`}>
                        {idx > 0 ? (
                          <div
                            style={{
                              display: 'flex',
                              width: 572,
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography className={classes.title}>
                              Child {idx + 1}
                            </Typography>
                            {isEdit &&
                              ID >= prefillFamilyData.childs.length && (
                                <IconButton
                                  aria-label="upload picture"
                                  component="span"
                                  style={{ marginTop: 15 }}
                                  onClick={() => {
                                    const newValues = values.childs;
                                    newValues.splice(idx, 1);
                                    setFieldValue('childs', newValues);
                                    handleChildRemove(idx);
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              )}
                            {!isEdit && (
                              <IconButton
                                aria-label="upload picture"
                                component="span"
                                style={{ marginTop: 15 }}
                                onClick={() => {
                                  const newValues = values.childs;
                                  newValues.splice(idx, 1);
                                  setFieldValue('childs', newValues);
                                  handleChildRemove(idx);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                        <Input
                          type="hidden"
                          name={`childs[${idx}].color`}
                          value={
                            (values?.childs?.[idx] &&
                              values?.childs[idx]?.color) ||
                            ''
                          }
                        />
                        <FormControl
                          component="fieldset"
                          style={{ color: 'pink' }}
                        >
                          <TextField
                            label="First Name"
                            variant="outlined"
                            onBlur={handleBlur}
                            name={`childs[${idx}].firstName`}
                            onChange={(e) => {
                              accountFlag &&
                                isEdit &&
                                handleCancelDisable(true);
                              setFieldValue(
                                `childs[${idx}].firstName`,
                                e.target.value
                              );
                              setFieldValue(
                                `childs[${idx}].initials`,
                                `${e.target.value.charAt(0)}${values.childs[
                                  idx
                                ]?.lastName?.charAt(0)}`
                              );
                            }}
                            value={
                              prefillFamilyData.planType === 'Free' &&
                              childVisiility !== 'Australia'
                                ? 'Redacted'
                                : (values?.childs?.[idx] &&
                                    values?.childs?.[idx].firstName) ||
                                  ''
                            }
                            error={Boolean(
                              prefillFamilyData.planType !== 'Free' &&
                                touched.childs &&
                                errors.childs &&
                                touched.childs[idx] &&
                                touched.childs[idx].firstName &&
                                errors.childs[idx] &&
                                errors.childs[idx].firstName
                            )}
                            helperText={
                              prefillFamilyData.planType !== 'Free' &&
                              touched.childs &&
                              errors.childs &&
                              touched.childs[idx] &&
                              touched.childs[idx].firstName &&
                              errors.childs[idx] &&
                              errors.childs[idx].firstName
                            }
                            className={classes.inputControlBox}
                            style={{ width: 273, marginRight: 25 }}
                            inputProps={{
                              className:
                                prefillFamilyData.planType === 'Free' &&
                                childVisiility !== 'Australia' &&
                                prefillFamilyData?.childs?.length !== 0
                                  ? classes.NonAUchildBox
                                  : classes.AuChildBox,
                              readOnly:
                                prefillFamilyData.planType === 'Free' &&
                                childVisiility !== 'Australia' &&
                                prefillFamilyData?.childs?.length !== 0
                                  ? true
                                  : false
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                        </FormControl>
                        <FormControl component="fieldset">
                          <TextField
                            label="Last Name"
                            variant="outlined"
                            onBlur={handleBlur}
                            name={`childs[${idx}].lastName`}
                            onChange={(e) => {
                              accountFlag &&
                                isEdit &&
                                handleCancelDisable(true);
                              setFieldValue(
                                `childs[${idx}].lastName`,
                                e.target.value
                              );
                              setFieldValue(
                                `childs[${idx}].initials`,
                                `${values.childs[idx].firstName.charAt(
                                  0
                                )}${e.target.value.charAt(0)}`
                              );
                            }}
                            value={
                              prefillFamilyData.planType === 'Free' &&
                              childVisiility !== 'Australia'
                                ? 'Redacted'
                                : (values.childs[idx] &&
                                    values.childs[idx].lastName) ||
                                  ''
                            }
                            error={Boolean(
                              prefillFamilyData.planType !== 'Free' &&
                                touched.childs &&
                                errors.childs &&
                                touched.childs[idx] &&
                                touched.childs[idx].lastName &&
                                errors.childs[idx] &&
                                errors.childs[idx].lastName
                            )}
                            helperText={
                              prefillFamilyData.planType !== 'Free' &&
                              touched.childs &&
                              errors.childs &&
                              touched.childs[idx] &&
                              touched.childs[idx].lastName &&
                              errors.childs[idx] &&
                              errors.childs[idx].lastName
                            }
                            className={classes.inputControlBox}
                            style={{ width: 273 }}
                            inputProps={{
                              className:
                                prefillFamilyData.planType === 'Free' &&
                                childVisiility !== 'Australia' &&
                                prefillFamilyData?.childs?.length !== 0
                                  ? classes.NonAUchildBox
                                  : classes.AuChildBox,
                              readOnly:
                                prefillFamilyData.planType === 'Free' &&
                                childVisiility !== 'Australia' &&
                                prefillFamilyData?.childs?.length !== 0
                                  ? true
                                  : false
                            }}
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                          />
                        </FormControl>
                        <div style={{ width: '100%' }}>
                          <FormControl component="fieldset">
                            <TextField
                              label="Initials"
                              variant="outlined"
                              className={classes.inputControlBox}
                              onBlur={handleBlur}
                              name={`childs[${idx}].initials`}
                              onChange={(e) => {
                                accountFlag &&
                                  isEdit &&
                                  handleCancelDisable(true);
                                setFieldValue(
                                  `childs[${idx}].initials`,
                                  e.target.value
                                );
                              }}
                              value={
                                prefillFamilyData.planType === 'Free' &&
                                childVisiility !== 'Australia'
                                  ? 'Redacted'
                                  : (values.childs[idx] &&
                                      values.childs[idx].initials) ||
                                    ''
                              }
                              error={Boolean(
                                prefillFamilyData.planType !== 'Free' &&
                                  touched.childs &&
                                  errors.childs &&
                                  touched.childs[idx] &&
                                  touched.childs[idx].initials &&
                                  errors.childs[idx] &&
                                  errors.childs[idx].initials
                              )}
                              helperText={
                                prefillFamilyData.planType !== 'Free' &&
                                touched.childs &&
                                errors.childs &&
                                touched.childs[idx] &&
                                touched.childs[idx].initials &&
                                errors.childs[idx] &&
                                errors.childs[idx].initials
                              }
                              style={{ width: 120, marginRight: 25 }}
                              inputProps={{
                                className:
                                  prefillFamilyData.planType === 'Free' &&
                                  childVisiility !== 'Australia' &&
                                  prefillFamilyData?.childs?.length !== 0
                                    ? classes.NonAUchildBox
                                    : classes.AuChildBox,
                                readOnly:
                                  prefillFamilyData.planType === 'Free' &&
                                  childVisiility !== 'Australia' &&
                                  prefillFamilyData?.childs?.length !== 0
                                    ? true
                                    : false
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                            />
                          </FormControl>
                        </div>
                        {prefillFamilyData.planType !== 'Free' && (
                          <div>
                            <FormControl component="fieldset">
                              <TextField
                                label="Username"
                                variant="outlined"
                                onBlur={(e) => {
                                  handleBlur(e);
                                }}
                                name={`childs[${idx}].userName`}
                                onChange={(e) => {
                                  accountFlag &&
                                    isEdit &&
                                    handleCancelDisable(true);
                                  setFieldValue(
                                    `childs[${idx}].userName`,
                                    e.target.value
                                      ? e.target.value.replace(/\s/g, '')
                                      : ''
                                  );

                                  if (
                                    ((prefillFamilyData.childs[idx] &&
                                      !prefillFamilyData.childs[idx]
                                        .userName) ||
                                      !prefillFamilyData.childs[idx] ||
                                      (values.childs[idx] &&
                                        prefillFamilyData.childs[idx] &&
                                        values.childs[idx].userName !==
                                          prefillFamilyData.childs[idx]
                                            .userName)) &&
                                    e.target.value
                                  ) {
                                    onUserNameChangeTextDelayed(
                                      e.target.value,
                                      setErrors,
                                      idx,
                                      values
                                    );
                                  }
                                }}
                                value={
                                  (values.childs[idx] &&
                                    values.childs[idx].userName) ||
                                  ''
                                }
                                error={Boolean(
                                  touched.childs &&
                                    errors.childs &&
                                    touched.childs[idx] &&
                                    touched.childs[idx].userName &&
                                    errors.childs[idx] &&
                                    errors.childs[idx].userName
                                )}
                                helperText={
                                  touched.childs &&
                                  errors.childs &&
                                  touched.childs[idx] &&
                                  touched.childs[idx].userName &&
                                  errors.childs[idx] &&
                                  errors.childs[idx].userName
                                }
                                className={classes.inputControlBox}
                              />
                            </FormControl>

                            <FormControl
                              component="fieldset"
                              style={{ width: '100%' }}
                            >
                              <div style={{ width: 680 }}>
                                <TextField
                                  label="Login Password"
                                  variant="outlined"
                                  onBlur={handleBlur}
                                  name={`childs[${idx}].password`}
                                  onChange={(e) => {
                                    accountFlag &&
                                      isEdit &&
                                      handleCancelDisable(true);
                                    setFieldValue(
                                      `childs[${idx}].password`,
                                      e.target.value
                                    );
                                  }}
                                  value={
                                    (values.childs[idx] &&
                                      values.childs[idx].password) ||
                                    ''
                                  }
                                  error={Boolean(
                                    prefillFamilyData.planType === 'Free' &&
                                      touched.childs &&
                                      errors.childs &&
                                      touched.childs[idx] &&
                                      touched.childs[idx].password &&
                                      errors.childs[idx] &&
                                      errors.childs[idx].password
                                  )}
                                  helperText={
                                    prefillFamilyData.planType === 'Free' &&
                                    touched.childs &&
                                    errors.childs &&
                                    touched.childs[idx] &&
                                    touched.childs[idx].password &&
                                    errors.childs[idx] &&
                                    errors.childs[idx].password
                                  }
                                  className={classes.inputControlBox}
                                  type={passwordFieldType}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() =>
                                            onTogglePasswordFieldType()
                                          }
                                          tabIndex="-1"
                                        >
                                          <img
                                            alt="Password Requirement"
                                            src={
                                              passwordFieldType === 'text'
                                                ? '/static/images/password-hide.png'
                                                : '/static/images/password-see.png'
                                            }
                                            width="22"
                                            height="22"
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                                <span className={classes.generate}>
                                  Generate
                                  <Loop
                                    height={24}
                                    width={24}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      generatePassword(idx, setFieldValue)
                                    }
                                    className={
                                      isRotate && classes.closeIconRotate
                                    }
                                  />
                                </span>
                              </div>
                            </FormControl>
                          </div>
                        )}
                        <div
                          style={{
                            width: '100%',
                            marginTop: 24
                          }}
                        >
                          <FormControl variant="outlined">
                            <InputLabel id="country-select-outlined-label">
                              School Name
                            </InputLabel>

                            <Select
                              labelId="schoolName-select-outlined-label"
                              className={classes.inputControlBox}
                              style={{
                                width: 273,
                                marginRight: 25,
                                marginTop: 0
                              }}
                              name={`childs[${idx}].school`}
                              onChange={(e) => {
                                accountFlag &&
                                  isEdit &&
                                  handleCancelDisable(true);
                                getYearFromSchoolId(
                                  e.target.value,
                                  idx,
                                  values,
                                  setFieldValue
                                );
                              }}
                              value={
                                (values.childs[idx] &&
                                  values.childs[idx].school) ||
                                ''
                              }
                              error={Boolean(
                                touched.childs &&
                                  errors.childs &&
                                  touched.childs[idx] &&
                                  touched.childs[idx].school &&
                                  errors.childs[idx] &&
                                  errors.childs[idx].school
                              )}
                              id="school-name-select-outlined"
                              onBlur={handleBlur}
                              label="School Name"
                            >
                              <MenuItem value="">
                                <em>School Name</em>
                              </MenuItem>

                              {schools
                                .filter((e) => {
                                  if (
                                    e.isFree === true ||
                                    prefillFamilyData.planType !== 'Free'
                                  ) {
                                    return e;
                                  }
                                })
                                .map(function (object) {
                                  return (
                                    <MenuItem key={object.id} value={object.id}>
                                      {object.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            {Boolean(
                              touched.childs &&
                                errors.childs &&
                                touched.childs[idx] &&
                                touched.childs[idx].school &&
                                errors.childs[idx] &&
                                errors.childs[idx].school
                            ) ? (
                              <FormHelperText
                                error={Boolean(
                                  touched.childs &&
                                    errors.childs &&
                                    touched.childs[idx] &&
                                    touched.childs[idx].school &&
                                    errors.childs[idx] &&
                                    errors.childs[idx].school
                                )}
                              >
                                {errors.childs[idx].school}
                              </FormHelperText>
                            ) : (
                              ''
                            )}
                          </FormControl>
                          <FormControl variant="outlined">
                            <InputLabel id="year-select-outlined-label">
                              Current Year
                            </InputLabel>
                            <Select
                              labelId="year-select-outlined-label"
                              className={classes.inputControlBox}
                              name={`childs[${idx}].year`}
                              onChange={(e) => {
                                accountFlag &&
                                  isEdit &&
                                  handleCancelDisable(true);
                                setFieldValue(
                                  `childs[${idx}].year`,
                                  e.target.value
                                );
                                setFieldValue(`childs[${idx}].groups`, '');
                              }}
                              value={
                                (values.childs[idx] &&
                                  values.childs[idx].year) ||
                                ''
                              }
                              error={Boolean(
                                touched.childs &&
                                  errors.childs &&
                                  touched.childs[idx] &&
                                  touched.childs[idx].year &&
                                  errors.childs[idx] &&
                                  errors.childs[idx].year
                              )}
                              style={{ width: 273, marginTop: 0 }}
                              id="year-select-outlined"
                              onBlur={handleBlur}
                              label="Current Year"
                            >
                              <MenuItem value="">
                                <em>Current Year</em>
                              </MenuItem>
                              {fieldChild?.years?.length &&
                              prefillFamilyData?.planType === 'Free'
                                ? fieldChild &&
                                  fieldChild?.years?.length &&
                                  fieldChild?.years?.map(function (object) {
                                    return (
                                      <MenuItem
                                        key={object.value}
                                        value={object.value}
                                      >
                                        {object.label}
                                      </MenuItem>
                                    );
                                  })
                                : DefaultYear &&
                                  DefaultYear.map((object) => {
                                    return (
                                      <MenuItem
                                        key={object.value}
                                        value={object.value}
                                      >
                                        {object.label}
                                      </MenuItem>
                                    );
                                  })}
                            </Select>
                            {Boolean(
                              touched.childs &&
                                errors.childs &&
                                touched.childs[idx] &&
                                touched.childs[idx].year &&
                                errors.childs[idx] &&
                                errors.childs[idx].year
                            ) ? (
                              <FormHelperText
                                error={Boolean(
                                  touched.childs &&
                                    errors.childs &&
                                    touched.childs[idx] &&
                                    touched.childs[idx].year &&
                                    errors.childs[idx] &&
                                    errors.childs[idx].year
                                )}
                              >
                                {errors.childs[idx].year}
                              </FormHelperText>
                            ) : (
                              ''
                            )}
                          </FormControl>
                          <br />
                          {prefillFamilyData.planType === 'Free' && (
                            <div
                              style={{
                                marginTop: 12,
                                display:
                                  selectedSchool?.categories?.length > 0
                                    ? 'inline-grid'
                                    : 'none',
                                gridTemplateRows: '80px 60px',
                                gridTemplateColumns: '295px 100px',
                                gridGap: '3px'
                              }}
                            >
                              {prefillFamilyData.planType === 'Free' &&
                                selectedSchool?.categories?.map(
                                  (Category, categoryIndex) => {
                                    return (
                                      <div style={{ marginTop: 10 }} key={`${Category.name}-${categoryIndex}`}>
                                        <FormControl variant="outlined">
                                          <InputLabel id="demo-simple-select-outlined-label-Group">
                                            {Category.name}
                                          </InputLabel>
                                          <Select
                                            className={classes.inputControlBox}
                                            style={{
                                              width: 273,
                                              marginRight: 25,
                                              marginTop: 0
                                            }}
                                            labelId="demo-simple-select-outlined-label-Group"
                                            id="demo-simple-select-outlined-Group"
                                            multiple
                                            name={Category.name}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                              accountFlag &&
                                                isEdit &&
                                                handleCancelDisable(true);
                                              handleGroupChange(
                                                e,
                                                idx,
                                                categoryIndex,
                                                values.childs,
                                                setFieldValue(
                                                  `childs[${idx}].groups`,
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            value={
                                              (values?.childs?.[idx] &&
                                                values?.childs?.[idx].groups) ||
                                              []
                                            }
                                            renderValue={(selected) => {
                                              const groupName = Groups.map(
                                                (data) => {
                                                  if (
                                                    selected.includes(
                                                      data.id
                                                    ) &&
                                                    data.category ===
                                                      Category.id
                                                  ) {
                                                    if (
                                                      data.years.includes(
                                                        `Year ${values.childs[idx].year}`
                                                      ) ||
                                                      (data.years.includes(
                                                        'Kindy'
                                                      ) &&
                                                        values.childs[idx]
                                                          .year === '0') ||
                                                      (data.years.includes(
                                                        'Pre K'
                                                      ) &&
                                                        values.childs[idx]
                                                          .year === '-1')
                                                    ) {
                                                      return data.groupName;
                                                    }
                                                    return null;
                                                  } else {
                                                    return null;
                                                  }
                                                }
                                              );
                                              const groupNameFilter =
                                                groupName.filter(
                                                  (data) => data && data
                                                );
                                              return groupNameFilter.join(', ');
                                            }}
                                            label={Category.name}
                                          >
                                            {Groups.sort((a, b) => {
                                              return a?.groupName?.toLowerCase() >
                                                b?.groupName?.toLowerCase()
                                                ? 1
                                                : -1;
                                            })
                                              .filter((g) => {
                                                if (
                                                  g.groupType === 'Free' &&
                                                  g.school ===
                                                    values?.childs[idx]
                                                      ?.school &&
                                                  g.category === Category.id
                                                ) {
                                                  if (
                                                    !values.childs[idx].year
                                                  ) {
                                                    return g;
                                                  }
                                                  if (
                                                    g.years.includes(
                                                      `Year ${values.childs[idx].year}`
                                                    ) ||
                                                    (g.years.includes(
                                                      'Kindy'
                                                    ) &&
                                                      values.childs[idx]
                                                        .year === '0') ||
                                                    (g.years.includes(
                                                      'Pre K'
                                                    ) &&
                                                      values.childs[idx]
                                                        .year === '-1')
                                                  ) {
                                                    return g;
                                                  } else return false;
                                                } else {
                                                  return false;
                                                }
                                              })
                                              .map((group) => {
                                                return (
                                                  <MenuItem
                                                    key={group.id}
                                                    value={group.id}
                                                    className={
                                                      classes.menuItemClass
                                                    }
                                                  >
                                                    <div>
                                                      <Checkbox
                                                        icon={
                                                          <CheckBoxOutlineBlankIcon fontSize="small" />
                                                        }
                                                        checkedIcon={
                                                          <CheckBoxIcon fontSize="small" />
                                                        }
                                                        checked={values?.childs?.[
                                                          idx
                                                        ]?.groups?.includes(
                                                          group.id
                                                        )}
                                                        name={group.id}
                                                        className={
                                                          classes.controlCheck
                                                        }
                                                        classes={{
                                                          root: classes.checkbox,
                                                          checked:
                                                            classes.checked
                                                        }}
                                                      />
                                                      {group.groupName}
                                                    </div>
                                                    {group?.createdByApiSync && (
                                                    <span>
                                                      <img src={apiShortIcon} />
                                                    </span>
                                                  )}
                                                  </MenuItem>
                                                );
                                              })}
                                          </Select>
                                        </FormControl>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          )}
                        </div>
                        {prefillFamilyData.planType !== 'Free' && (
                          <FormControl component="fieldset">
                            <TextField
                              label="School Portal URL"
                              variant="outlined"
                              onBlur={(e) => {
                                setFieldValue(
                                  `childs[${idx}].schoolPortal`,
                                  e.target.value.trim().split(/ +/).join(' ')
                                );
                                handleBlur(e);
                              }}
                              name={`childs[${idx}].schoolPortal`}
                              onChange={(e) => {
                                accountFlag &&
                                  isEdit &&
                                  handleCancelDisable(true);
                                setFieldValue(
                                  `childs[${idx}].schoolPortal`,
                                  e.target.value
                                );
                              }}
                              value={
                                (values.childs[idx] &&
                                  values.childs[idx].schoolPortal) ||
                                ''
                              }
                              error={Boolean(
                                prefillFamilyData.planType !== 'Free' &&
                                  touched.childs &&
                                  errors.childs &&
                                  touched.childs[idx] &&
                                  touched.childs[idx].schoolPortal &&
                                  errors.childs[idx] &&
                                  errors.childs[idx].schoolPortal
                              )}
                              helperText={
                                prefillFamilyData.planType !== 'Free' &&
                                touched.childs &&
                                errors.childs &&
                                touched.childs[idx] &&
                                touched.childs[idx].schoolPortal &&
                                errors.childs[idx] &&
                                errors.childs[idx].schoolPortal
                              }
                              className={classes.inputControlBox}
                            />
                          </FormControl>
                        )}
                        {prefillFamilyData.planType !== 'Free' && (
                          <div style={{ width: '100%' }}>
                            <FormControl component="fieldset">
                              <TextField
                                label="School Portal Login"
                                variant="outlined"
                                onBlur={(e) => {
                                  setFieldValue(
                                    `childs[${idx}].schoolPortalLogin`,
                                    e.target.value.trim().split(/ +/).join(' ')
                                  );
                                  handleBlur(e);
                                }}
                                name={`childs[${idx}].schoolPortalLogin`}
                                onChange={(e) => {
                                  accountFlag &&
                                    isEdit &&
                                    handleCancelDisable(true);
                                  setFieldValue(
                                    `childs[${idx}].schoolPortalLogin`,
                                    e.target.value
                                  );
                                }}
                                value={
                                  (values.childs[idx] &&
                                    values.childs[idx].schoolPortalLogin) ||
                                  ''
                                }
                                error={Boolean(
                                  prefillFamilyData.planType !== 'Free' &&
                                    touched.childs &&
                                    errors.childs &&
                                    touched.childs[idx] &&
                                    touched.childs[idx].schoolPortalLogin &&
                                    errors.childs[idx] &&
                                    errors.childs[idx].schoolPortalLogin
                                )}
                                helperText={
                                  prefillFamilyData.planType !== 'Free' &&
                                  touched.childs &&
                                  errors.childs &&
                                  touched.childs[idx] &&
                                  touched.childs[idx].schoolPortalLogin &&
                                  errors.childs[idx] &&
                                  errors.childs[idx].schoolPortalLogin
                                }
                                className={classes.inputControlBox}
                                style={{ width: 273, marginRight: 25 }}
                              />
                            </FormControl>
                            <FormControl component="fieldset">
                              <TextField
                                label="School Portal Password"
                                variant="outlined"
                                onBlur={(e) => {
                                  setFieldValue(
                                    `childs[${idx}].schoolPortalPassword`,
                                    e.target.value.trim().split(/ +/).join(' ')
                                  );
                                  handleBlur(e);
                                }}
                                name={`childs[${idx}].schoolPortalPassword`}
                                onChange={(e) => {
                                  accountFlag &&
                                    isEdit &&
                                    handleCancelDisable(true);
                                  setFieldValue(
                                    `childs[${idx}].schoolPortalPassword`,
                                    e.target.value
                                  );
                                }}
                                value={
                                  (values.childs[idx] &&
                                    values.childs[idx].schoolPortalPassword) ||
                                  ''
                                }
                                error={Boolean(
                                  prefillFamilyData.planType !== 'Free' &&
                                    touched.childs &&
                                    errors.childs &&
                                    touched.childs[idx] &&
                                    touched.childs[idx].schoolPortalPassword &&
                                    errors.childs[idx] &&
                                    errors.childs[idx].schoolPortalPassword
                                )}
                                helperText={
                                  prefillFamilyData.planType !== 'Free' &&
                                  touched.childs &&
                                  errors.childs &&
                                  touched.childs[idx] &&
                                  touched.childs[idx].schoolPortalPassword &&
                                  errors.childs[idx] &&
                                  errors.childs[idx].schoolPortalPassword
                                }
                                className={classes.inputControlBox}
                                style={{ width: 273 }}
                              />
                            </FormControl>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div>
                    <Button
                      className={classes.adButton}
                      style={{
                        backgroundColor: '#f4f5f7',
                        marginLeft: 0,
                        height: 50,
                        color: colors.primaryDarkGrey
                      }}
                      onClick={() => handleChildAdd(values)}
                    >
                      <Add />
                      Add Child
                    </Button>
                  </div>
                </div>
              )}

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSave(false);
                    handleSubmit();
                    window.scroll(0, 0);
                  }}
                  className={classes.buttonText}
                >
                  Next
                </Button>
                {accountFlag && isEdit && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonText}
                    style={{ marginLeft: '2%' }}
                    onClick={() => {
                      setSave(true);
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                )}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default withFirebase(AddFamilyMember);
