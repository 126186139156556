import { useState, useEffect } from 'react';
import axios from 'src/config/axios';

const useApiCalls = (initialUrl,{accountStatus}={}) => {
  // const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // const complete_URL = ` ${BASE_URL}/admin/${initialUrl}`;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async ({
    pageSize,
    prevPageRef,
    nextPageRef,
    searchText,
    schoolId,
    categoryId,
    year,
    pendingStatus
  }) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/${initialUrl}${accountStatus ? `/${accountStatus}`:""}`, {
        params: {
          searchText,
          pageSize,
          nextPageRef,
          prevPageRef,
          schoolId,
          categoryId,
          year,
          pendingStatus
        }
      });
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (complete_URL) {
      fetchData({});
    // }
  }, []);

  return { data, loading, error, fetchData };
};

export default useApiCalls;
