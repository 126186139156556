import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";

export const addFamilyHostStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%'
  },
  cancelButton: {
    width: 106,
    height: 50,
    borderRadius: 3,
    border: 'solid 1 ',
    backgroundColor: colors.primaryLightGrey,
    borderColor: colors.primaryLightGrey,
    float: 'left'
  },
  title: {
    width: 303,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  subtitle: {
    width: 425,
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey
  },
  label: {
    width: 134,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  controlBox: {
    width: 572,
    height: 60,
    borderRadius: 4,
    borderStyle: 'solid',
    border: 1,
    borderColor: colors.primaryLightGrey,
    backgroundColor: '#ffffff',
    marginTop: 24,
    marginLeft: 0,
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  inputControlBox: {
    width: 572,
    height: 60,
    backgroundColor: '#ffffff',
    marginTop: 24,
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  radio: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  checked: {},
  rightAd: {
    position: 'absolute',
    top: '20%',
    right: '5%',
    width: 250,
    height: 170,
    borderRadius: 10,
    backgroundColor: '#f3f4f6'
  },
  adTitle: {
    width: 169,
    height: 28,
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHight: 'normal',
    letterSpacing: 0.2,
    color: colors.primaryDark,
    margin: '5%'
  },
  adSubTitle: {
    width: 200,
    height: 54,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    margin: '5%'
  },
  adButton: {
    height: 30,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    margin: '5%',
    marginTop: '5%',
    marginLeft: 0
  },
  adButtonText: {
    // width: 83,
    height: 18,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  schoolDesc: {
    width: 582,
    height: 72,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: ' rgba(76, 91, 104, 0.6)'
  },
  error: {
    color: '#f44336',
    marginLeft: 14,
    margin: 0,
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 1.66
  },
  buttonText: {
    height: 50,
    borderRadius: 3,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.white,
    marginTop: '4.3%'
  },
  buttonTextSave: {
    height: 24,
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center'
  },
  createButtonText: {
    width: 200,
    height: 50,
    borderRadius: 3,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.white,
    marginTop: '4.3%'
  },
  createButtonTextFree: {
    width: 200,
    height: 50,
    borderRadius: 3,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.white
  },
  ORtext: {
    margin: '15px 30px 0 30px',
    color: '#4c5b68',
    fontSize: '16px',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: 'normal'
  }
}));