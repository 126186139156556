import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Input,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { Add, VpnLock } from '@material-ui/icons';
import { colors, hostRadio, year as DefaultYear } from 'src/utils/constant';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%'
  },
  cancelButton: {
    width: 106,
    height: 50,
    borderRadius: 3,
    border: 'solid 1 ',
    backgroundColor: colors.primaryLightGrey,
    borderColor: colors.primaryLightGrey,
    float: 'left'
  },
  title: {
    width: 303,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  subtitle: {
    width: 425,
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey
  },
  label: {
    width: 134,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  controlBox: {
    width: 572,
    height: 60,
    borderRadius: 4,
    borderStyle: 'solid',
    border: 1,
    borderColor: colors.primaryLightGrey,
    backgroundColor: '#ffffff',
    marginTop: 24,
    marginLeft: 0,
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  inputControlBox: {
    width: 572,
    height: 60,
    backgroundColor: '#ffffff',
    marginTop: 24,
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  radio: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  checked: {},
  rightAd: {
    position: 'absolute',
    top: '20%',
    right: '5%',
    width: 250,
    height: 170,
    borderRadius: 10,
    backgroundColor: '#f3f4f6'
  },
  adTitle: {
    width: 169,
    height: 28,
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHight: 'normal',
    letterSpacing: 0.2,
    color: colors.primaryDark,
    margin: '5%'
  },
  adSubTitle: {
    width: 200,
    height: 54,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    margin: '5%'
  },
  adButton: {
    height: 30,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    margin: '5%',
    marginTop: '5%',
    marginLeft: 0
  },
  adButtonText: {
    // width: 83,
    height: 18,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  schoolDesc: {
    width: 582,
    height: 72,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: ' rgba(76, 91, 104, 0.6)'
  },
  error: {
    color: '#f44336',
    marginLeft: 14,
    margin: 0,
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 1.66
  },
  buttonText: {
    height: 50,
    borderRadius: 3,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.white,
    marginTop: '4.3%'
  },
  buttonTextSave: {
    height: 24,
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center'
  },
  createButtonText: {
    width: 200,
    height: 50,
    borderRadius: 3,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.white,
    marginTop: '4.3%'
  },
  createButtonTextFree: {
    width: 200,
    height: 50,
    borderRadius: 3,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.white
  },
  ORtext: {
    margin: '15px 30px 0 30px',
    color: '#4c5b68',
    fontSize: '16px',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: 'normal'
  }
}));

function AddFamilyHost({
  submitForm,
  accountFlag,
  isEdit,
  handleCancelDisable,
  setHandleCancelClick,
  handleCancelClick
}) {
  const classes = useStyles();
  const prefillFamilyData = useSelector((state) => state.family.currentFamily);
  const schoolsData = useSelector((state) => state.auth.schools);
  const [formKey, setFormKey] = useState(Math.random());
  const [schools, setSchools] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [status, setStatus] = useState('');

  const [initialValues, setInitialValue] = useState({
    freeHost:
      prefillFamilyData.planType === 'Free'
        ? 'true'
        : prefillFamilyData.freeHost || 'false',
    schools: (prefillFamilyData.schools &&
      prefillFamilyData.schools.length &&
      prefillFamilyData.schools) || [
      { schoolName: '', year: '', color: '', years: [] }
    ]
  });

  const [isSave, setSave] = useState(false);

  useEffect(() => {
    if (handleCancelClick) {
      setInitialValue({
        freeHost:
          prefillFamilyData.planType === 'Free'
            ? 'true'
            : prefillFamilyData.freeHost || 'false',
        schools: (prefillFamilyData.schools &&
          prefillFamilyData.schools.length &&
          prefillFamilyData.schools) || [
          { schoolName: '', year: '', color: '', years: [] }
        ]
      });
      setFormKey(Math.random());
      setHandleCancelClick(false);
      handleCancelDisable(false);
    }
  }, [handleCancelClick, setHandleCancelClick]);

  useEffect(() => {
    const updatedSchool = schoolsData.filter((school) => school.years.length);
    setSchools(updatedSchool);
  }, [schoolsData, setSchools]);

  useEffect(() => {
    const schoolValues = (prefillFamilyData.schools || []).map((val) => {
      return val;
    });
    const updatedValue = schoolValues?.map((value, index) => {
      const findYears = _.find(
        schoolsData,
        (school) => school.id === value?.schoolName
      );
      const yearValue = findYears?.years?.map((data) => {
        const getCurrentYear = _.find(
          DefaultYear,
          (elem) => elem.value === data?.value
        );
        return {
          label: getCurrentYear?.label,
          value: data?.value
        };
      });
      const sortYear = findYears?.years.sort(function (a, b) {
        return parseInt(a.value) - parseInt(b.value);
      });
      return {
        ...value,
        schoolName: value?.schoolName,
        years: yearValue ? yearValue : []
      };
    });

    if (prefillFamilyData.planType === 'Free') {
      setInitialValue({
        freeHost: prefillFamilyData.freeHost
          ? prefillFamilyData.freeHost
          : prefillFamilyData.planType === 'Free'
          ? 'true'
          : prefillFamilyData.freeHost || 'false',
        schools: (updatedValue && updatedValue.length && updatedValue) || [
          { schoolName: '', year: '', color: '', years: [] }
        ]
      });
    }
  }, [prefillFamilyData, schoolsData, schools]);

  function handleAdd(values) {
    setInitialValue({
      ...values,
      schools: [
        ...values.schools,
        { schoolName: '', year: '', color: '', years: [] }
      ]
    });
    setFormKey(Math.random());
  }

  // SCHEMA VALIDATOR FOR CREATE NEW PASSWORD FORM
  const validationSchema = Yup.object().shape({
    freeHost: Yup.boolean().required('Free Host is required'),
    schools: Yup.array().when('freeHost', {
      is: true,
      then: Yup.array().of(
        Yup.object().shape({
          schoolName: Yup.string().trim().required('School Name is required'),
          year: Yup.string().trim().required('Year is required')
        })
      ),
      otherwise: Yup.array().of(
        Yup.object().shape({
          schoolName: Yup.string('School Name is required'),
          year: Yup.string('Year is required')
        })
      )
    })
  });

  const getYearFromSchoolId = (
    schoolID,
    selectedIndex,
    values,
    setFieldValue
  ) => {
    const updatedValue = values?.schools?.map((value, index) => {
      if (index === selectedIndex) {
        const findYears = _.find(schools, (school) => school.id === schoolID);
        const yearValue = findYears.years.map((data) => {
          const getCurrentYear = _.find(
            DefaultYear,
            (elem) => elem.value === data?.value
          );
          return {
            label: getCurrentYear?.label,
            value: data?.value
          };
        });
        const sortYear = findYears?.years.sort(function (a, b) {
          return parseInt(a.value) - parseInt(b.value);
        });
        return {
          ...value,
          schoolName: schoolID,
          years: yearValue ? yearValue : []
        };
      } else {
        return { ...value };
      }
    });
    setFieldValue(`schools[${selectedIndex}].schoolName`, schoolID);
    setInitialValue({
      ...values,
      schools: [...updatedValue]
    });
  };
  return (
    <div>
      <div className={classes.title}>
        Free Calendar{' '}
        {prefillFamilyData.planType === 'Free' ? 'Subscriptions' : 'Host'}
      </div>
      <div className={classes.subtitle}>
        {prefillFamilyData.planType === 'Free'
          ? 'Select the school and year that the family subscribed to.'
          : 'If this member has agreed to be a free calendar host, select Yes and then assign the schools.'}
      </div>
      <Formik
        key={formKey}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (values.freeHost) {
            if (values.schools.length) {
              values.schoolIds = values.schools.map(
                (school) => school.schoolName
              );
            } else {
              values.schoolIds = [];
            }
          } else {
            values.schools = [];
            values.schoolIds = [];
          }
          if (accountFlag && isSave) {
            submitForm(values, true);
          } else {
            submitForm(values, status);
            // handleCancelDisable(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue
        }) => {
          return (
            <form
              noValidate
              onSubmit={(value) => {
                handleSubmit(value);
              }}
            >
              {prefillFamilyData.planType !== 'Free' && (
                <FormControl component="fieldset" style={{ marginTop: 47 }}>
                  <RadioGroup
                    aria-label="freeHost"
                    name="freeHost"
                    onChange={(e) => {
                      handleChange(e);
                      accountFlag && isEdit && handleCancelDisable(true);
                      if (e.target.value === 'false') {
                        setFieldValue('schools', [
                          { schoolName: '', year: '', color: '', years: [] }
                        ]);
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.freeHost}
                  >
                    {hostRadio.map(function (object) {
                      return (
                        <FormControlLabel
                          key={object.label}
                          value={object.value}
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                            />
                          }
                          label={object.label}
                          className={classes.controlBox}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              )}
              {values.freeHost === 'true' ? (
                <>
                  {prefillFamilyData.planType !== 'Free' && (
                    <div style={{ marginTop: '2%' }}>
                      <div className={classes.title}>School</div>
                      <div className={classes.schoolDesc}>
                        The school must first exist for it to appear in the list
                        below. You can continue by creating the family account,
                        then add the school and then edit the family account to
                        include the free calendar host details.
                      </div>
                    </div>
                  )}
                  {values.schools.map((value, idx) => {
                    return (
                      <div key={`${value}-${idx}`}>
                        <Input
                          type="hidden"
                          name={`schools[${idx}].color`}
                          value={
                            (values.schools[idx] &&
                              values.schools[idx].color) ||
                            // value.color ||
                            ''
                          }
                        />
                        <FormControl
                          variant="outlined"
                          style={{ marginTop: 24 }}
                        >
                          <InputLabel id="school-name-select-outlined-label">
                            School Name
                          </InputLabel>
                          <Select
                            labelId="schoolName-select-outlined-label"
                            className={classes.inputControlBox}
                            style={{
                              width: 323,
                              marginRight: 25,
                              marginTop: 0
                            }}
                            value={
                              (values.schools[idx] &&
                                values.schools[idx].schoolName) ||
                              value.schoolName ||
                              ''
                            }
                            onChange={(e) => {
                              accountFlag &&
                                isEdit &&
                                handleCancelDisable(true);
                              if (e.target.value !== '') {
                                // setFieldValue(
                                //   `schools[${idx}].schoolName`,
                                //   e.target.value
                                // );
                                getYearFromSchoolId(
                                  e.target.value,
                                  idx,
                                  values,
                                  setFieldValue
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            id="school-name-select-outlined-label"
                            label="School Name"
                            error={Boolean(
                              touched.schools &&
                                errors.schools &&
                                touched.schools[idx] &&
                                touched.schools[idx].schoolName &&
                                errors.schools[idx] &&
                                errors.schools[idx].schoolName
                            )}
                            name={`schools[${idx}].schoolName`}
                          >
                            <MenuItem value="">
                              <em>School Name</em>
                            </MenuItem>
                            {prefillFamilyData.planType !== 'Free' &&
                              schoolsData.map(function (object) {
                                return (
                                  <MenuItem key={object.id} value={object.id}>
                                    {object.name}
                                  </MenuItem>
                                );
                              })}
                            {prefillFamilyData.planType === 'Free' &&
                              schools.map(function (object) {
                                return (
                                  <MenuItem key={object.id} value={object.id}>
                                    {object.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {Boolean(
                            touched.schools &&
                              errors.schools &&
                              touched.schools[idx] &&
                              touched.schools[idx].schoolName &&
                              errors.schools[idx] &&
                              errors.schools[idx].schoolName
                          ) ? (
                            <FormHelperText
                              error={Boolean(
                                touched.schools &&
                                  errors.schools &&
                                  touched.schools[idx] &&
                                  touched.schools[idx].schoolName &&
                                  errors.schools[idx] &&
                                  errors.schools[idx].schoolName
                              )}
                            >
                              {/* {errors.schools[idx].schoolName} */}
                              {'School Name is a required field'}
                            </FormHelperText>
                          ) : (
                            ''
                          )}
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          style={{ marginTop: 24 }}
                          disabled={
                            prefillFamilyData.planType === 'Free'
                              ? !value.years || !value.years.length
                              : false
                          }
                        >
                          <InputLabel id="year-select-outlined-label">
                            Year
                          </InputLabel>
                          <Select
                            labelId="year-select-outlined-label"
                            id="year-select-outlined-label"
                            value={
                              (values.schools &&
                                values.schools[idx] &&
                                values.schools[idx].year) ||
                              ''
                            }
                            onChange={(e) => {
                              accountFlag &&
                                isEdit &&
                                handleCancelDisable(true);
                              if (e.target.value !== '') {
                                setFieldValue(
                                  `schools[${idx}].year`,
                                  e.target.value
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.year &&
                                errors.year &&
                                touched.schools[idx] &&
                                touched.schools[idx].year &&
                                errors.schools[idx] &&
                                errors.schools[idx].year
                            )}
                            name={`schools[${idx}].year`}
                            className={classes.inputControlBox}
                            style={{
                              width: 223,
                              marginRight: 25,
                              marginTop: 0
                            }}
                            label="Year"
                          >
                            <MenuItem value="">
                              <em>Year</em>
                            </MenuItem>
                            {prefillFamilyData.planType !== 'Free' &&
                              DefaultYear?.map(function (object) {
                                return (
                                  <MenuItem
                                    key={object.value}
                                    value={object.value}
                                  >
                                    {object.label}
                                  </MenuItem>
                                );
                              })}
                            {prefillFamilyData.planType === 'Free' &&
                              value?.years &&
                              value?.years?.length &&
                              value?.years
                                ?.sort(function (a, b) {
                                  let yearValue1 = a?.value
                                    ?.split('Year ')
                                    .reverse()[0];
                                  let yearValue2 = b?.value
                                    ?.split('Year ')
                                    .reverse()[0];
                                  return (
                                    parseInt(yearValue1) - parseInt(yearValue2)
                                  );
                                })
                                .map((object) => {
                                  return (
                                    <MenuItem
                                      key={object.value}
                                      value={object.value}
                                    >
                                      {object.label === 'Year 0'
                                        ? 'Kindy'
                                        : object.label}
                                    </MenuItem>
                                  );
                                })}
                          </Select>
                          {Boolean(
                            touched.schools &&
                              errors.schools &&
                              touched.schools[idx] &&
                              touched.schools[idx].year &&
                              errors.schools[idx] &&
                              errors.schools[idx].year
                          ) ? (
                            <FormHelperText
                              error={Boolean(
                                touched.schools &&
                                  errors.schools &&
                                  touched.schools[idx] &&
                                  touched.schools[idx].year &&
                                  errors.schools[idx] &&
                                  errors.schools[idx].year
                              )}
                            >
                              {/* {errors.schools[idx].year} */}
                              {'Year is a required field'}
                            </FormHelperText>
                          ) : (
                            ''
                          )}
                        </FormControl>
                        {idx > 0 && (
                          <Button
                            disableRipple
                            style={{
                              height: 70,
                              fontSize: 25,
                              marginTop: 24
                            }}
                            onClick={() => {
                              const newValues = values.schools;
                              newValues.splice(idx, 1);
                              setFieldValue('schools', newValues);
                            }}
                          >
                            X
                          </Button>
                        )}
                      </div>
                    );
                  })}
                  <Button
                    className={classes.adButton}
                    style={{
                      backgroundColor: '#f4f5f7',
                      marginLeft: 0,
                      height: 50,
                      color: colors.primaryDarkGrey
                    }}
                    onClick={() => handleAdd(values)}
                  >
                    <Add />
                    <div className={classes.adButtonText}> Add Another</div>
                  </Button>
                </>
              ) : (
                ''
              )}
              <Grid item xs={12}>
                {accountFlag && isEdit && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonText}
                    onClick={() => {
                      setSave(true);
                      handleSubmit();
                      window.scroll(0, 0);
                    }}
                  >
                    Save Changes
                  </Button>
                )}
                {!accountFlag &&
                !isEdit &&
                prefillFamilyData.planType !== 'Free' ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.createButtonText}
                    onClick={() => {
                      handleSubmit();
                      setSave(true);
                      window.scroll(0, 0);
                    }}
                  >
                    Create Family Account
                  </Button>
                ) : !accountFlag && !isEdit ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {prefillFamilyData.userName === '' &&
                      prefillFamilyData.password === '' && (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.createButtonTextFree}
                          onClick={() => {
                            setSave(true);
                            handleSubmit();
                            setStatus('Invited');
                            window.scroll(0, 0);
                          }}
                        >
                          Save & Invite Now
                        </Button>
                      )}

                    {prefillFamilyData.userName === '' &&
                      prefillFamilyData.password === '' && (
                        <Typography className={classes.ORtext}> or </Typography>
                      )}

                    {prefillFamilyData.userName === '' &&
                      prefillFamilyData.password === '' && (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.createButtonTextFree}
                          onClick={() => {
                            setSave(true);
                            handleSubmit();
                            setStatus('New');
                            window.scroll(0, 0);
                          }}
                        >
                          Save as New
                        </Button>
                      )}

                    {prefillFamilyData.userName !== '' &&
                      prefillFamilyData.password !== '' && (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.createButtonTextFree}
                          onClick={() => {
                            setSave(true);
                            handleSubmit();
                            setStatus('Active');
                            window.scroll(0, 0);
                          }}
                        >
                          Save as Active
                        </Button>
                      )}
                  </div>
                ) : null}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AddFamilyHost;
