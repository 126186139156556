import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material-UI Components
import { Button, Grid } from '@material-ui/core';

// Components
import ToggleSwitchButton from 'src/components/ToggleSwitchButton';

// Utils
import { withFirebase } from 'src/utils/firebase';

// Assets
import { calendarStyles } from 'src/styles/schoolStyle/calendarStyles';
import { setSchool } from 'src/redux/actions/schoolActions';

function Translation({
  isEdit,
  onSave,
  getTranslationStatus,
  onToggleTranslationSwitch
}) {
  const classes = calendarStyles();

  const prefillSchoolData = useSelector((state) => state.school.currentSchool);
  const dispatch = useDispatch();

  const [isFormValuesUpdated, setIsFormValuesUpdated] = useState(false);
  const [preserveValueOfToggle, setPreserveValueOfToggle] = useState(false);
  const [isTranslationSwitchOn, setIsTranslationSwitchOn] = useState(false);

  useEffect(() => {
    setIsTranslationSwitchOn(prefillSchoolData?.paidTranslationEnabled);
    getTranslationStatus(prefillSchoolData?.paidTranslationEnabled);
  }, [prefillSchoolData]);

  useEffect(() => {
    if (isEdit) {
      setPreserveValueOfToggle(prefillSchoolData?.paidTranslationEnabled);
    }
  }, [isEdit]);

  const toggleTranslationSwitch = (newToggleState) => {
    const isFormUpdated =
      newToggleState !== preserveValueOfToggle ? true : false;
    setIsTranslationSwitchOn(newToggleState);
    setIsFormValuesUpdated(isFormUpdated);
    dispatch(
      setSchool({
        ...prefillSchoolData,
        paidTranslationEnabled: newToggleState
      })
    );
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'end' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <div className={classes.title}>
            Calendar Translation (Extension Pack)
          </div>
          <div className={classes.subtitle}>
            Enable the extended translation pack for this school
          </div>
        </div>
        <div style={{ marginBottom: '22px', marginLeft: '24px' }}>
          <ToggleSwitchButton
            checked={isTranslationSwitchOn}
            handleChecked={() => {              
              if (isTranslationSwitchOn && isEdit) {
                onToggleTranslationSwitch(
                  toggleTranslationSwitch,
                  !isTranslationSwitchOn
                );
              } else {
                toggleTranslationSwitch(!isTranslationSwitchOn);
              }
            }}
            name="translationSwitch"
          />
        </div>
      </div>

      <Grid container spacing={0}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onSave(
              {
                paidTranslationEnabled: isTranslationSwitchOn
              },
              'Back'
            );
          }}
          className={classes.disabledButtonText}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onSave(
              {
                paidTranslationEnabled: isTranslationSwitchOn
              },
              'Save'
            );
            setIsFormValuesUpdated(false);
            setPreserveValueOfToggle(isTranslationSwitchOn);
          }}
          style={{ marginLeft: '16px', width: isEdit ? 106 : '' }}
          className={
            isEdit
              ? isFormValuesUpdated || prefillSchoolData?.isSchoolFormUpdated
                ? classes.buttonText
                : classes.disabledButtonText
              : classes.buttonText
          }
          disabled={
            isEdit
              ? isFormValuesUpdated || prefillSchoolData?.isSchoolFormUpdated
                ? false
                : true
              : false
          }
        >
          {isEdit ? 'Save' : 'Create School Profile'}
        </Button>
      </Grid>
    </div>
  );
}

export default withFirebase(Translation);
