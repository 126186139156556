import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function UserMinusIcon({ color, ...props }) {
  return (
    <SvgIcon  width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path id="prefix__a" d="M12 13.833c2.465 0 4.475 1.946 4.58 4.385l.003.199v1.833c0 .506-.41.917-.916.917-.47 0-.858-.354-.91-.81l-.007-.107v-1.833c0-1.465-1.145-2.662-2.588-2.746L12 15.667H5.583c-1.464 0-2.661 1.145-2.745 2.588l-.005.162v1.833c0 .506-.41.917-.916.917-.47 0-.858-.354-.91-.81L1 20.25v-1.833c0-2.465 1.945-4.475 4.385-4.58l.198-.004H12zm-3.208-11c2.531 0 4.583 2.052 4.583 4.584 0 2.531-2.052 4.583-4.583 4.583-2.532 0-4.584-2.052-4.584-4.583 0-2.532 2.052-4.584 4.584-4.584zm13.291 7.334c.507 0 .917.41.917.916 0 .47-.354.858-.81.91l-.107.007h-5.5c-.506 0-.916-.41-.916-.917 0-.47.354-.857.81-.91l.106-.006h5.5zm-13.291-5.5c-1.52 0-2.75 1.23-2.75 2.75 0 1.518 1.23 2.75 2.75 2.75 1.518 0 2.75-1.232 2.75-2.75 0-1.52-1.232-2.75-2.75-2.75z"/>
    </SvgIcon>
  );
}
export default UserMinusIcon;