import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

function MembersIcon({ color, ...props }) {
    return (
        <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
            <g fill="none" fillRule="evenodd">
                <circle cx="20" cy="20" r="20" />
                <g stroke={color || '#4C5B68'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2">
                    <path transform="translate(3 0)scale(1.2)" d="M24.258 11.11a2.36 2.36 0 1 1-3.338 3.339 2.36 2.36 0 0 1 3.338-3.338zM16.57 6.898a3.634 3.634 0 1 1-5.14 5.14 3.634 3.634 0 0 1 5.14-5.14zM7.08 11.11a2.36 2.36 0 1 1-3.338 3.339A2.36 2.36 0 0 1 7.08 11.11zM26.833 22.167v-1.279a2.916 2.916 0 0 0-2.916-2.916h-.935M1.167 22.167v-1.279a2.916 2.916 0 0 1 2.916-2.916h.935M20.229 22.167v-1.868a4.083 4.083 0 0 0-4.084-4.083h-4.292a4.083 4.083 0 0 0-4.083 4.083v1.868"/>
                </g>
                <defs>
                    <clipPath id="5iph4e8nva">
                        <path fill="#fff" d="M0 0h28v28H0z"/>
                    </clipPath>
                </defs>
            </g>
        </SvgIcon>
    )
}

export default MembersIcon;