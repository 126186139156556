import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";


export const membersUsersViewStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  tabNavigations: {
    padding: '10px 20px 0',
    marginBottom: 40
  },
  navigationButton: {
    width: 120,
    textTransform: 'none'
  },
  actionButton: {
    margin: '15px 0 20px',
    textTransform: 'none'
  },
  activeTab: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0
  },
  checked: {},
  checkbox: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  bredthcrumbTitle: {
    color: '#536e7b',
    fontSize: 16,
    marginRight: 24,
    display: 'inline-block',
    cursor: 'pointer'
  },
  inputControlBox: {
    width: 427,
    backgroundColor: '#ffffff',
    marginTop: 24
  },
  NonAUchildBox: {
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)',
    pointerEvents: 'none',
    userSelect: 'none',
    '&&::selection': {
      color: 'transparent',
      background: 'transparent'
    }
  },
  AuChildBox: {},
  errorBox: {
    border: '1px solid red',
    width: 427,
    backgroundColor: '#ffffff',
    marginTop: 24,
    borderRadius: '4px'
  },
  leftContentHeader: {
    textAlign: 'right'
  },
  label: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.2,
    letterSpacing: 0.13,
    color: colors.primaryDarkGrey,
    // marginLeft: 14,
    marginTop: 20,
    float: 'left'
  },
  siderAvatar: {
    marginTop: 12,
    width: 40,
    float: 'left'
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    height: 71
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primaryDarkGrey
  },
  siderDetail: {
    fontSize: 14,
    color: colors.primaryDarkGrey,
    marginBottom: 8
  },
  icon: {
    marginRight: 17,
    height: 20,
    width: 20
  },
  siderSave: {
    width: 95,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary
  },
  mainHeading: {
    width: 93,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  subHeading: {
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    width: 344
  },
  accountText: {
    fontWeight: 500,
    color: colors.primary
  },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    width: 523,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  modalContent: {
    minHeight: 75,
    paddingTop: 12
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark
  },
  modalBox: {
    width: 450
    // height: 170,
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  modalActions: {
    marginTop: '2%'
  },
  imageContainer: {
    width: 100,
    height: 100,
    backgroundColor: colors.primaryLightGrey,
    marginTop: 16,
    borderRadius: '50%',
    fontSize: '32px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: colors.primaryDarkGrey
  },
  NonAUImageContainer: {
    width: 100,
    height: 100,
    backgroundColor: colors.primaryLightGrey,
    marginTop: 16,
    borderRadius: '50%',
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)',
    pointerEvents: 'none',
    userSelect: 'none',
    '&&::selection': {
      color: 'transparent',
      background: 'transparent'
    }
  },
  popoverClass: {
    '& .MuiPaper-rounded': {
      right: '70px',
      left: 'unset  !important',
      top: '70px !important'
    }
  },
  dividerClass: {
    marginBottom: 22,
    marginTop: 22
  },
  moreVertButton: {
    justifyContent: 'flex-end',
    padding: 'unset  !important'
    // '& .MuiButton-text':{
    // }
  },
  profileImageWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  removeImageContainer: {
    marginLeft: 30,
    marginTop: 20,
    color: colors.primaryDarkGrey,
    cursor: 'pointer'
  }
}));