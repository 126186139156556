import { Modal, makeStyles } from '@material-ui/core';
import React from 'react';
import fileScanning from '../assets/file-scanning.gif';
import fileNotSupported from '../assets/icons-file-not-supported.svg';
import fileVirus from '../assets/icons-file-virus.svg';
import { colors } from '../utils/constant';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "rgba(145, 153, 161, 0.8)"
  },
  modalWrapper: {
    borderRadius: 4,
    boxShadow: `0 0 25px 0 rgba(0, 0, 0, 0.35)`,
    backgroundColor: colors.white,
    outline: 0,
    borderRadius: '16px',
    border: 'solid 1px #bfc5d1',
    width: '200px',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '27px'
  },
  fileNotSupportedModalWrapper: {
    backgroundColor: colors.tertiaryConfirm,
    border: 'unset',
    paddingTop: '15px'
  },
  fileThreatModalWrapper: {
    backgroundColor: colors.tertiaryRed,
    border: 'unset',
    paddingTop: '15px'
  },
  scanningFileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  fileStatusText: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    color: colors.primaryDark
  },
  fileInvalidStatusText: {
    color: colors.white,
    marginTop: '20px',
    fontWeight: 500
  },
  fileInvalidStatusDescription: {
    fontSize: '16px',
    color: colors.white,
    marginTop: '6px',
    width: '122px',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    fontWeight: 'normal',
    textAlign: 'center'
  },
  fileNotSupportedContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export default function ScanningUploadFileModal({
  isOpen,
  handleOnClose,
  fileUploadState = 0
}) {
  const classes = useStyles();
  return (
    <Modal className={classes.modal} open={isOpen} onClose={handleOnClose}>
      <div
        className={clsx(classes.modalWrapper, {
          [classes.fileNotSupportedModalWrapper]: fileUploadState === 1,
          [classes.fileThreatModalWrapper]: fileUploadState === 2
        })}
      >
        {fileUploadState === 1 ? (
          <div className={classes.fileNotSupportedContainer}>
            <img src={fileNotSupported} alt="file not supported" />
            <span
              className={clsx(
                classes.fileStatusText,
                classes.fileInvalidStatusText
              )}
            >
              Invalid File Type
            </span>
            <p
              className={clsx(
                classes.fileStatusText,
                classes.fileInvalidStatusDescription
              )}
            >
              That file format is not supported
            </p>
          </div>
        ) : fileUploadState === 2 ? (
          <div className={classes.fileNotSupportedContainer}>
            <img src={fileVirus} alt="Threat Detected" />
            <span
              className={clsx(
                classes.fileStatusText,
                classes.fileInvalidStatusText
              )}
            >
              Threat Detected
            </span>
            <p
              className={clsx(
                classes.fileStatusText,
                classes.fileInvalidStatusDescription
              )}
            >
              This file has <strong>not</strong> been saved
            </p>
          </div>
        ) : (
          <div className={classes.scanningFileContainer}>
            <img src={fileScanning} alt="scanning" />
            <span className={classes.fileStatusText}>Scanning file</span>
          </div>
        )}
      </div>
    </Modal>
  );
}
