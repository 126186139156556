import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import NavTab from "../schools/NavTab";
import { routes } from "src/utils/constant";

const useStyles = makeStyles({
    root: {
        width: "100%",
        maxHeight: "848px",
        "& .MuiPaper-elevation1":{
            boxShadow: "none !important"
        }
    },
    navtab:{
        paddingTop: "26px",
        borderBottom: "1px solid #e0e0e0"
    }
})

function ConsoleLayout({sideButton, children }){
    const classes = useStyles();
    const currentUser = useSelector((state) => state.auth.user);
    const { role } = useMemo(() => {
        return { role: currentUser?.role || '' };
      }, [currentUser]);

    const tabOptions = useMemo(() => {
        return [
            ...role !== "Agent" ? [{
                label: "Team",
                link: `${routes.consoleTeam}`
            }] : [],
            {
                label: "Schools",
                link: `${routes.consoleSchools}`
            },
            ...role !== "Agent" ? [{
                label: "Maintenance",
                link: `${routes.consoleMaintenance}`
            }] : []
        ]
    },[]);

    return (
        <Paper className={classes.root}>
             <Typography
                variant="h2"
                color="textPrimary"
                className={classes.headingMargin}
            ></Typography>
            <div className={classes.navtab}>
            <NavTab
                tabs={tabOptions}
                selectedTab={
                    // eslint-disable-next-line no-restricted-globals
                    location.pathname.includes(routes.consoleTeam)
                    ? "Team"
                    // eslint-disable-next-line no-restricted-globals
                    : location.pathname.includes(routes.consoleSchools)
                    ? "Schools"
                    // eslint-disable-next-line no-restricted-globals
                    : location.pathname.includes(routes.consoleMaintenance)
                    ? "Maintenance"
                    : "Team" }
            />
            {sideButton}
            </div>
            {children}
        </Paper>
    )
}

export default ConsoleLayout;