import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { colors, routes } from 'src/utils/constant';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { LOGO } from 'src/assets';
import { useSelector, useDispatch } from 'react-redux';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { resetFamily } from 'src/redux/actions/familyActions';
import { resetSchool, setSchool } from 'src/redux/actions/schoolActions';
import { withFirebase } from 'src/utils/firebase';
import _ from 'lodash';
import { addMessage } from 'src/redux/actions/appActions';
import { getSchoolByID } from 'src/services/schools';
import { MESSAGE_TYPES } from 'src/constants/common';

const useStyles = makeStyles(() => ({
  container: {
    // marginTop: 116,
    backgroundColor: '#F4F6F8',
    height: '100%'
  },
  button: {
    backgroundColor: colors.primary,
    color: colors.white,
    justifyContent: 'center'
  },
  buttonArticle: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem'
  },
  underlineBtn: {
    textDecoration: 'underline',
    fontWeight: '400',
    cursor: 'pointer'
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: -0.22,
    textAlign: 'center',
    color: colors.primaryDark,
    marginTop: 48
  },
  link: {
    display: 'flex',
    height: 50,
    width: 250,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  logoContainer: {
    width: 267.6,
    height: 268.1,
    backgroundColor: colors.primary,
    borderRadius: '50%',
    position: 'relative'
  },
  image: {
    position: 'absolute',
    top: 42,
    left: 38
  }
}));

function SuccessComponent({ firebase, ...props }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [schoolNameListData, setSchoolNameListData] = useState([]);
  const currentFamily = useSelector((state) => state.family.currentFamily);
  const schoolList = useSelector((state) => state.auth?.schools);
  const {
    mainTitle,
    title,
    subTitle,
    iconImage,
    buttonLabel,
    listUrl,
    handleReset
  } = props;

  const downloadTxtFile = (data) => {
    // ref : https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react/44661948
    const username = `${data.userName}`;
    const password = data.password;
    const parentString = `Parent | ${username} | ${password}`;
    let childData = [];
    if (data.childs && data.childs.length) {
      childData = data.childs.map((child) => {
        const username = `${child.userName}`;
        const password = child.password;
        return `${child.lastName} ${child.firstName} | ${username} | ${password}`;
      });
    }
    const childString = childData.length ? childData.join('\n') : '';
    const element = document.createElement('a');
    const file = new Blob([`${parentString}\n${childString}`], {
      type: 'text/plain'
    });
    element.href = URL.createObjectURL(file);
    element.download = 'myFile.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  // Get school Name
  const getSchoolName = (id) => {
    const schoolName = schoolList?.find((school) => school.id === id);
    return schoolName ? schoolName?.name : null;
  };
  // Filter duplicate schools
  const filteredChildSchools = _.uniqBy(currentFamily?.childs, 'school') || [];
  // Set school data

  const setSchoolDataToStore = async (id) => {
    // PHASE2/CHANGES/SCHOOL

    try {
      const school = await getSchoolByID(id)
      if (school) {
        dispatch(setSchool(school));
      }
      history.push(routes.schoolAccount + '/' + id);
    } catch(error) {
      console.error(error);
      dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
    }
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignContent="center"
      className={classes.container}
    >
      <Grid>
        <div className={classes.logoContainer}>
          <img alt="Logo" src={LOGO} className={classes.image} />
        </div>
      </Grid>
      <Grid item xs={12} align="center">
        <Typography className={classes.title} align="center">
          {title}
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        align="center"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 48,
          marginBottom: 48
        }}
      >
        {mainTitle === 'Family' ? (
          <Button
            className={classes.link}
            onClick={() => {
              downloadTxtFile(currentFamily);
              dispatch(resetFamily());
            }}
            style={{ marginBottom: 40 }}
          >
            {<DescriptionOutlinedIcon />}
            <span style={{ marginLeft: 14 }}>Download Credentials</span>
          </Button>
        ) : (
          <></>
        )}
        {mainTitle === 'Member' ? (
          <article className={classes.buttonArticle}>
            <span
              className={classes.underlineBtn}
              onClick={() => {
                listUrl && history.push(listUrl);
              }}
            >
              {subTitle}
            </span>
            {filteredChildSchools?.map((child) => {
              return (
                <span
                  className={classes.underlineBtn}
                  onClick={async() => {
                    child?.id && setSchoolDataToStore(child?.school)
                  }}
                >
                  View {getSchoolName(child?.school)}
                </span>
              );
            })}
          </article>
        ) : (
          <>
            <Button
              className={classes.link}
              onClick={() => {
                listUrl && history.push(listUrl);
                if (mainTitle === 'School') {
                  dispatch(resetSchool());
                }
              }}
            >
              {iconImage}
              <span style={{ marginLeft: 14 }}>{subTitle}</span>
            </Button>
          </>
        )}
      </Grid>
      <Grid item xs={12} align="center">
        <Button
          color="primary"
          variant="contained"
          align="center"
          onClick={handleReset}
        >
          {mainTitle === 'Member' ? null : <Add />}

          <span style={{ margin: '10 0' }}>{buttonLabel}</span>
        </Button>
      </Grid>
    </Grid>
  );
}
export default withFirebase(SuccessComponent);
