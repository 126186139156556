import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from 'src/utils/firebase';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
import { colors, routes, year as DefaultYear, categories, schoolTypes } from 'src/utils/constant';
import { SEARCH_ICON } from '../assets';
import { API_GET_ALL_FREE_SCHOOLS } from 'src/utils/api';
import makeApiRequest from 'src/utils/axios';
import {
  addMessage,
  loaderStart,
  loaderStop
} from 'src/redux/actions/appActions';
import clsx from 'clsx';
import { Add as AddIcon } from '@material-ui/icons';
import {
  setFreeSchoolList,
} from 'src/redux/actions/reusableStateValueActions';
import { MESSAGE_TYPES } from 'src/constants/common';

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: '91.1%',
    borderRadius: 4,
    boxShadow: `0 0 25px 0 rgba(76, 91, 104, 0.13)`
  },
  schoolGroupTabContainer: {
    boxShadow: 'none'
  },
  header: {
    width: '100%'
  },
  inputControlBoxContainer: {
    margin: '5px 0'
  },
  inputControlBox: {
    width: 572,
    backgroundColor: '#ffffff',
    marginTop: 24,
    '& .MuiSelect-root': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  studentsFilterDropdown:{
    width: 120
  },
  placeholderText: {
    position: 'absolute'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  tableHead: {
    maxHeight: '17.1%'
  },
  tableRow: {
    height: '8.8%',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: `rgba(244, 245, 247, 0.51) !important`
    }
  },
  secondaryGreyLightBackground: {
    backgroundColor: colors.secondaryGreyLight,
    padding: 0,
    '&:first-child': {
      paddingLeft: 16
    }
  },
  secondaryGreyLightBackgroundForGroup: {
    '&:first-child': {
      paddingLeft: 16
    }
  },
  columnTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    padding: 8,
    fontWeight: 500
  },
  inputTableCell: {
    borderBottom: 'none',
    paddingLeft: 20,
    paddingTop: 29,
    paddingBottom: 14,
    backgroundColor: colors.secondaryGreyLight
  },
  inputTableCellSchools:{
    paddingLeft: 24,
  },
  textFieldRoot: {
    width: 280,
    height: 45,
    borderRadius: 4,
    border: 1,
    borderColor: 'rgb(224, 224, 224)',
    backgroundColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    margin: '5px 10px 5px 0'
  },
  studentsTextFieldRoot:{
    width: 295,
    margin: '5px 20px 5px 0',
    height: 42
  },
  studentsTextFieldInputRoot:{
    height: 42
  },
  studentsTextFieldInputBaseRoot:{
    height: '21px !important'
  },
  tableStickyHeader: {},
  pagination: {
    lineHeight: 'normal !important',
    '& .MuiInputBase-input': {
      height: 20
    },
    '& .MuiSelect-icon': {
      top: 3
    }
  },
  freeMembersButton: {
    height: '42px',
    width: '140px',
    borderRadius: '25px',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    margin: '5px 10px'
  },
  filterCard:{
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px 12px'
  },
  countmemberBox: {
    height: '20px',
    padding: '4px 6px',
    marginLeft: '14px',
    backgroundColor: '#8bc517',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center'
  },
  countmemberBoxNew: {
    height: '20px',
    padding: '4px 6px',
    marginLeft: '10px',
    backgroundColor: '#0084ff',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center'
  },
  countmemberBoxPending: {
    padding: '4px 6px',
    height: '20px',
    marginLeft: '10px',
    backgroundColor: '#ff9b00',
    color: '#ffffff',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center'
  },
  statusFilterContainer: {
    float: 'right',
    '@media (max-width: 1560px)': {
      float: 'unset'
    }
  },
  clearFiltersContainer: {
    width: 'max-content',
    display: 'inline-flex',
    height: '43px',
    alignItems: 'center',
    margin: '5px 0',
    cursor: 'pointer',
    fontWeight: 300
  },
  actionButton: {
    padding: '8px 17px',
    fontSize: 16,
    lineHeight: '24px',
    float: 'right',
    marginRight: '9px',
    height: '44px',
    marginTop: '5px'
  },
  startIcon: {
    marginRight: 12
  }
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const EnhancedTableHead = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [schoolDropDown, setSchoolDropDown] = useState([]);
  const {
    order,
    orderBy,
    onRequestSort,
    columns,
    searchPlaceholderText,
    disableSearch,
    disableColumnLabel,
    searchChangeHandler,
    schoolChangeHandler,
    groupCategoryChangeHandler,
    studentCategoryChangeHandler,
    schoolChangeHandlerFamilyFree,
    schoolChangeHandlerFamilyPaid,
    yearChangeHandlerFree,
    year,
    filterClearHandler,
    selectedCategory,
    selectedSchool,
    showClearFilters,
    totalFamilies,
    searchText,
    schoolCategories,
    hanldeAddGroupClick,
    totalCount,
    years,
    memberStatus,
    nonActiveCount,
    schoolTypeHandler,
    schoolType
  } = props;
  const dispatch = useDispatch();
  const pathName = window.location.pathname;
  const isSchoolGroupsTab = pathName.includes('/schools/groups');
  const isSchoolStudentsTab = pathName.includes('/schools/students');
  const isSchoolConsoleTab = pathName.includes('/console/schools');

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  useEffect(() => {
    if (!isSchoolGroupsTab && !isSchoolStudentsTab) {
      dispatch(loaderStart());
      const fetchFreeSchoolData = async () => {
        const { data, error } = await makeApiRequest({
          url: API_GET_ALL_FREE_SCHOOLS
        });
        dispatch(setFreeSchoolList(data?.data || []));
        setSchoolDropDown(data?.data || []);
        if (error) {
          dispatch(addMessage(error, MESSAGE_TYPES.ERROR));
        }
        dispatch(loaderStop());
      };
      fetchFreeSchoolData();
    }
  }, []);

  return (
    <TableHead className={classes.tableHead}>
      {!disableSearch && (
        <TableRow className={classes.header}>
          <TableCell
            colSpan={columns.length}
            className={clsx(classes.inputTableCell, { [classes.inputTableCellSchools] : isSchoolGroupsTab })}
          >
            <TextField
              size="small"
              name="searchClient"
              variant="outlined"
              placeholder={searchPlaceholderText}
              InputProps={{
                classes : {root: clsx({ [classes.studentsTextFieldInputRoot] : isSchoolStudentsTab }), input : clsx({ [classes.studentsTextFieldInputBaseRoot] : isSchoolStudentsTab })},
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SEARCH_ICON} alt="Search" tabIndex="-1" />
                  </InputAdornment>
                )
              }}
              value={searchText}
              onChange={searchChangeHandler}
              classes={{
                root: clsx(classes.textFieldRoot, { [classes.studentsTextFieldRoot] : isSchoolStudentsTab })
              }}
            />
            {pathName === '/' ||
            pathName === '/members/active' ||
            pathName === '/members/new' ||
            pathName === '/members/pending'  ? (
              <FormControl
                variant="outlined"
                size="small"
                classes={{ root: classes.inputControlBoxContainer }}
                disabled={year?.length >= 1}
              >
                <InputLabel
                  className={classes.placeholderText}
                  id="country-select-outlined-label"
                >
                  All Schools
                </InputLabel>

                <Select
                  labelId="demo-select-small"
                  className={classes.inputControlBox}
                  style={{
                    height: 45,
                    width: 273,
                    marginRight: 12,
                    marginTop: 0
                  }}
                  name="schoolData"
                  onChange={
                    schoolChangeHandler ||
                    schoolChangeHandlerFamilyFree ||
                    schoolChangeHandlerFamilyPaid
                  }
                  id="demo-select-small"
                  label="All Schools"
                  {...(props.hasOwnProperty('selectedSchool')
                    ? { value: selectedSchool }
                    : {})}
                >
                  <MenuItem value="">
                    <em>All Schools</em>
                  </MenuItem>
                  {schoolDropDown &&
                    schoolDropDown.map(function (object) {
                      return (
                        <MenuItem key={object.id} value={object.id}>
                          {object.schoolName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            ) : null}
            {isSchoolGroupsTab ? (
              <FormControl
                variant="outlined"
                size="small"
                classes={{ root: classes.inputControlBoxContainer }}
                style={
                  schoolCategories?.length < 1
                    ? { display: 'none' }
                    : { display: 'inline-block' }
                }
              >
                <InputLabel
                  className={classes.placeholderText}
                  id="category-select-outlined-label"
                >
                  All Categories
                </InputLabel>

                <Select
                  labelId="category-select-outlined-label"
                  className={classes.inputControlBox}
                  style={{
                    height: 45,
                    width: 273,
                    marginRight: 12,
                    marginTop: 0
                  }}
                  name="categoryData"
                  onChange={groupCategoryChangeHandler}
                  id="category-select-outlined"
                  label="All Categories"
                  value={selectedCategory}
                  disabled={schoolCategories?.length < 1 ? true : false}
                >
                  <MenuItem value="">
                    <em>All Categories</em>
                  </MenuItem>

                  {schoolCategories?.map((category) => {
                    return (
                      <MenuItem key={category.id} value={category.id}>
                        <span>{category.name}</span>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}
             {isSchoolStudentsTab ? (
              <>
                <FormControl
              variant="outlined"
              size="small"
              classes={{ root: classes.inputControlBoxContainer }}
              style={{ width: 140 }}
              disabled={selectedSchool?.length >= 1}
            >
              <InputLabel
                id="year-select-outlined-label"
                className={classes.placeholderText}
              >
               All Years
              </InputLabel>
              <Select
                labelId="year-select-outlined-label"
                className={clsx(classes.inputControlBox, {[classes.studentsFilterDropdown] : isSchoolStudentsTab})}
                name="years"
                onChange={yearChangeHandlerFree}
                style={{
                  height: 42,
                  width: 120,
                  marginRight: 250,
                  marginTop: 0
                }}
                id="year-select-outlined"
                label="All Years"
                value={year}
              >
                <MenuItem value="">
                  <em>All Years</em>
                </MenuItem>
                {years?.length > 0 &&
                  years.map((object) => {
                    return (
                      <MenuItem key={object.value} value={object.value}>
                        {object.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              classes={{ root: classes.inputControlBoxContainer }}
              style={{ width: 185 }}
              disabled={selectedSchool?.length >= 1}
            >
              <Select
               className={clsx(classes.inputControlBox, {[classes.studentsFilterDropdown] : isSchoolStudentsTab})}
                name="categrory"
                onChange={studentCategoryChangeHandler}
                style={{
                  height: 42,
                  width: 145,
                  marginRight: 250,
                  marginTop: 0
                }}
                id="categrory-select-outlined"
                value={memberStatus}
                inputProps={{className: clsx({ [classes.studentsTextFieldInputBaseRoot] : isSchoolStudentsTab })}}
              >
                {categories &&
                  categories.map((object) => {
                    return (
                      <MenuItem key={object.value} value={object.value}>
                        {object.label}{`(${object.value === memberStatus ? totalCount : nonActiveCount})`}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
              </>
            ) : null}
            {isSchoolConsoleTab ? (
              <FormControl
              variant="outlined"
              size="small"
              classes={{ root: classes.inputControlBoxContainer }}
              style={{ width: 140,
                marginLeft: 8 }}
            >
              <InputLabel
                id="year-select-outlined-label"
                className={classes.placeholderText}
              >
               All Types
              </InputLabel>
              <Select
                labelId="year-select-outlined-label"
                className={clsx(classes.inputControlBox, {[classes.studentsFilterDropdown] : isSchoolStudentsTab})}
                name="years"
                onChange={schoolTypeHandler}
                style={{
                  height: 44,
                  width: 120,
                  marginRight: 250,
                  marginTop: 2
                }}
                id="year-select-outlined"
                label="All Years"
                value={schoolType}
              >
                {schoolTypes?.length > 0 &&
                  schoolTypes.map((object) => {
                    return (
                      <MenuItem key={object.value} value={object.value}>
                        {object.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            ): null}
            {pathName === '/' ||
            pathName === '/members/active' ||
            pathName === '/members/new' ||
            pathName === '/members/pending' ? (
              <FormControl
                variant="outlined"
                size="small"
                classes={{ root: classes.inputControlBoxContainer }}
                style={{ width: 185 }}
                disabled={selectedSchool?.length >= 1}
              >
                <InputLabel
                  id="year-select-outlined-label"
                  className={classes.placeholderText}
                >
                  Year
                </InputLabel>
                <Select
                  labelId="year-select-outlined-label"
                  className={classes.inputControlBox}
                  name="years"
                  onChange={yearChangeHandlerFree}
                  style={{
                    height: 45,
                    width: 173,
                    marginRight: 250,
                    marginTop: 0
                  }}
                  id="year-select-outlined"
                  label="Year"
                  value={year}
                >
                  <MenuItem value="">
                    <em>All Years</em>
                  </MenuItem>
                  {DefaultYear &&
                    DefaultYear.map((object) => {
                      return (
                        <MenuItem key={object.value} value={object.value}>
                          {object.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            ) : null}
            {showClearFilters &&
            (pathName === '/' ||
              isSchoolGroupsTab || isSchoolStudentsTab ||
              pathName === '/members/active' ||
              pathName === '/members/new' ||
              pathName === '/members/pending') ? (
              <div
                className={classes.clearFiltersContainer}
                onClick={filterClearHandler}
              >
                <span>clear</span>
              </div>
            ) : null}
            {isSchoolGroupsTab ? (
              <span className={classes.statusFilterContainer}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.actionButton}
                  startIcon={<AddIcon />}
                  classes={{ startIcon: classes.startIcon }}
                  onClick={hanldeAddGroupClick}
                >
                  Group
                </Button>
              </span>
            ) : null}
            {pathName === '/' ||
            pathName === '/members/active' ||
            pathName === '/members/new' ||
            pathName === '/members/pending' ? (
              <span className={classes.statusFilterContainer}>
                <Button
                  className={classes.freeMembersButton}
                  onClick={() => history.push(routes.membersActive)}
                  style={{
                    border:
                      pathName === '/members/active' || pathName === '/'
                        ? '1px solid #8bc517'
                        : '1px solid #bfc5d1',
                    backgroundColor:
                      pathName === '/members/active' || pathName === '/'
                        ? 'rgba(139, 197, 23, 0.2)'
                        : '#fff'
                  }}
                >
                  <div className={classes.filterCard}>
                    <span>Active</span>
                    <span className={classes.countmemberBox}>
                      {totalCount || 0}
                    </span>
                  </div>
                </Button>

                <Button
                  className={classes.freeMembersButton}
                  onClick={() => history.push(routes.membersNew)}
                  style={{
                    border:
                      pathName === '/members/new'
                        ? '1px solid #0084ff'
                        : '1px solid #bfc5d1',
                    backgroundColor:
                      pathName === '/members/new'
                        ? 'rgba(0, 132, 255, 0.2)'
                        : '#fff'
                  }}
                >
                  <div className={classes.filterCard}>
                    <span>New</span>
                    <span className={classes.countmemberBoxNew}>
                      {totalFamilies?.newFamiliesCount || 0}
                    </span>
                  </div>
                </Button>

                <Button
                  className={classes.freeMembersButton}
                  onClick={() => history.push(routes.membersPending)}
                  style={{
                    border:
                      pathName === '/members/pending'
                        ? '1px solid #ff9b00'
                        : '1px solid #bfc5d1',
                    backgroundColor:
                      pathName === '/members/pending'
                        ? 'rgba(255, 155, 0, 0.2)'
                        : '#fff'
                  }}
                >
                  <div className={classes.filterCard}>
                    <span>Pending</span>
                    <span className={classes.countmemberBoxPending}>
                      {totalFamilies?.pendingFamiliesCount || 0}
                    </span>
                  </div>
                </Button>
              </span>
            ) : null}
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        {columns?.map((column) => {
          if (!disableColumnLabel) {
            return (
              <TableCell
                key={column.id}
                align={column.align}
                sortDirection={orderBy === column.id ? order : false}
                className={clsx(classes.secondaryGreyLightBackground, {
                  [classes.secondaryGreyLightBackgroundForGroup]:
                    isSchoolGroupsTab | isSchoolStudentsTab
                })}
                width={column.width || 'auto'}
                style={disableSearch ? { padding: '16px' } : {}}
              >
                {column.hasSort ? (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id)}
                    className={classes.columnTitle}
                  >
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                    {column.content
                      ? column.content()
                      : column.label.toUpperCase()}
                  </TableSortLabel>
                ) : (
                  <div className={classes.columnTitle}>
                    {column.content
                      ? column.content()
                      : column.label.toUpperCase()}
                  </div>
                )}
              </TableCell>
            );
          }
          return null;
        })}
      </TableRow>
    </TableHead>
  );
};

const StickyHeadTable = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('statusUpdatedAt');
  const dispatch = useDispatch();

  const {
    columns,
    rows,
    searchPlaceholderText,
    tableRow,
    disableSearch,
    disableColumnLabel,
    searchChangeHandler,
    schoolChangeHandler,
    groupCategoryChangeHandler,
    studentCategoryChangeHandler,
    schoolChangeHandlerFamilyFree,
    schoolChangeHandlerFamilyPaid,
    yearChangeHandlerFree,
    year,
    selectedSchool,
    selectedCategory,
    filterClearHandler,
    showClearFilters,
    totalCount = 0,
    handlePageChange,
    handleRowsPerPageChange,
    totalFamilies,
    familyTypeCheck,
    setRowsCountPerPage,
    currentPage,
    searchText,
    schoolCategories,
    hanldeAddGroupClick,
    years,
    memberStatus,
    nonActiveCount,
    rowsCountPerPage,
    schoolTypeHandler,
    schoolType,
    className,
  } = props;

  useEffect(() => {
    setPage(0);
  }, [currentPage]);

  useEffect(() => {
    if(rowsCountPerPage){
      setRowsPerPage(rowsCountPerPage);
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    if (handlePageChange) handlePageChange(page, newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsCount = +event.target.value;
    setRowsPerPage(newRowsCount);
    if (setRowsCountPerPage) {
      setRowsCountPerPage(newRowsCount);
    }
    if (handleRowsPerPageChange) {
      handleRowsPerPageChange(newRowsCount);
    }
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer
        className={clsx(classes.container, className, {
          [classes.schoolGroupTabContainer]:
            window.location.pathname.includes('/schools/groups')
        })}
      >
        <Table
          options={{
            selection: true
          }}
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
            totalFamilies={totalFamilies}
            searchPlaceholderText={searchPlaceholderText}
            disableSearch={disableSearch || false}
            disableColumnLabel={disableColumnLabel || false}
            searchChangeHandler={searchChangeHandler}
            searchText={searchText}
            schoolChangeHandler={schoolChangeHandler}
            groupCategoryChangeHandler={groupCategoryChangeHandler}
            schoolChangeHandlerFamilyFree={schoolChangeHandlerFamilyFree}
            schoolChangeHandlerFamilyPaid={schoolChangeHandlerFamilyPaid}
            yearChangeHandlerFree={yearChangeHandlerFree}
            year={year}
            showClearFilters={showClearFilters}
            filterClearHandler={filterClearHandler}
            selectedCategory={selectedCategory}
            selectedSchool={selectedSchool}
            schoolCategories={schoolCategories}
            hanldeAddGroupClick={hanldeAddGroupClick}
            totalCount={totalCount}
            years={years}
            studentCategoryChangeHandler={studentCategoryChangeHandler}
            memberStatus={memberStatus}
            nonActiveCount={nonActiveCount}
            schoolTypeHandler={schoolTypeHandler}
            schoolType={schoolType}
          />

          <TableBody>
            {rows?.length ? (
              rows?.map((row, index) => {
                return tableRow && tableRow(row, index);
              })
            ) : (
              <TableRow className="MuiTableRow-root makeStyles-tableRow-1 MuiTableRow-hover">
                <TableCell
                  className="MuiTableCell-root MuiTableCell-body"
                  colSpan={columns?.length}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={familyTypeCheck ? rows?.length||0 : totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          classes={{ selectRoot: classes.pagination }}
        />
      </TableContainer>
    </Paper>
  );
};
export default withFirebase(StickyHeadTable, EnhancedTableHead);
