import moment from 'moment';

export const getNewNotificationArray = (entry, entryDate) => {
  let notifications = [];
  if (entry.actions && entry.actions.notification.length) {
    entry.actions.notification.forEach((noti) => {
      const days = parseInt(noti.days);
      if (noti.date) {
        noti.date = moment(entryDate, 'MMM DD,YYYY')
          .add(days, 'days')
          .format('MMM DD,YYYY');
      }
      notifications.push(noti);
    });
  }
  return notifications;
};