import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  FormControlLabel,
  CircularProgress,
  Backdrop
} from '@material-ui/core';
import Dashboard from 'src/views/layout/Dashboard';
import ConsoleLayout from '..';
import ToggleSwitchButton from '../../../components/ToggleSwitchButton';
import { updateMaintenanceMode } from 'src/services/auth';
import { setMaintenanceMode } from 'src/redux/actions/maintenanceActions';
import { loaderStart, loaderStop } from 'src/redux/actions/appActions';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 205px)',
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'center',
    paddingTop: '60px'
  },
  section1: {
    marginRight: '93px',
    '& .title': {
      fontSize: '20px',
      fontWeight: 500
    }
  },
  section2: {
    display: 'flex',
    height: 'fit-content',
    padding: '4.5px 0'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function Maintenance() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.app.isLoading);
  const maintenanceMode = useSelector(
    (state) => state.maintenance.maintenanceMode
  );
  const currentUser = useSelector((state) => state.auth.user);
  const { role } = useMemo(() => {
    return { role: currentUser?.role || '' };
  }, [currentUser]);

  const handleMaintenanceMode = (mode) => {
    dispatch(loaderStart());
    updateMaintenanceMode(mode)
      .then(() => {
        dispatch(setMaintenanceMode(mode));
      })
      .catch((err) => {
        dispatch(setMaintenanceMode(!mode));
        console.log('()=>error', err);
      })
      .finally(() => {
        dispatch(loaderStop());
      });
  };

  return (
    <Dashboard>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Typography variant="h2" color="textPrimary" style={{ marginBottom: 20 }}>
        Console
      </Typography>
        <ConsoleLayout>
        {role !== 'Agent' && <div className={classes.root}>
            <div className={classes.section1}>
              <Typography className="title">Maintenance Mode</Typography>
              <Typography>{`Display the maintenance page instead of the (live) registration form`}</Typography>
            </div>
            <div className={classes.section2}>
              <FormControlLabel
                control={
                  <ToggleSwitchButton
                    checked={maintenanceMode}
                    handleChecked={(e) => {
                      handleMaintenanceMode(e.target.checked);
                    }}
                  />
                }
              />
            </div>
          </div>}
        </ConsoleLayout>
    </Dashboard>
  );
}

export default Maintenance;
