export const DEFAULT_SUPPORT_PERSON = {
  url: 'https://firebasestorage.googleapis.com/v0/b/mywhanau-admin.appspot.com/o/schools%2FDefault-supportPerson.jpg?alt=media&token=45432f70-f0c6-4a64-a051-bc2c4007c3bd',
  storage_path: 'schools/Default-supportPerson.jpg'
};

export const LOCAL_STORAGE_KEY = {
  AUTH_TOKEN: 'mywhanau_token',
  USER_ID: 'userID',
  EMAIL_ID: 'emailID',
  IS_CODE_VERIFIED: 'isCodeVerified',
  REDUX: 'persist:root_mywhanau'
}

export const OTP_LENGTH = 6

export const MESSAGE_TYPES = {
  ERROR : 'ERROR'
}