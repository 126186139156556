import {
  Backdrop,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddIcon from '@material-ui/icons/Add';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { EditorState } from 'draft-js';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, EditPencil, LocationIcon } from 'src/assets';
import { CornerDownRight, FamiliesIcon } from 'src/assets/Icons';
import Modal from 'src/components/Modal';
import {
  addMessage,
  loaderStart,
  loaderStop,
  setFamilyOrSchoolPreserveEntryData,
  setPreserveEntryData
} from 'src/redux/actions/appActions';
import {
  year as DefaultYear,
  colors,
  default_PN_time,
  labels,
  muiTimePickerTheme,
  taskLabels
} from 'src/utils/constant';
import { withFirebase } from 'src/utils/firebase';
import { generateFileHash, sortLinkedEntries, toGetContentFromHTML, toGetHTMLContent } from 'src/utils/functions';
import * as Yup from 'yup';
import CustomDateModal from '../families/components/CustomeDateModal';
import { getSchoolByID, getSchools } from 'src/services/schools';
import { getGroupBySchoolId } from 'src/services/groups';
import { categoryGroupCalendarEntryStyles } from 'src/styles/schoolStyle/categoryGroupCalendarEntryStyles';
import EditorComponent from 'src/components/EditorComponent';
import { deepEqual } from 'src/utils/deepEqual';
import { setFromToTimeFormat, virtualEntries } from 'src/utils/virtualEntryJSON';
import { getYearsInNumber } from 'src/utils/years';
import { MESSAGE_TYPES } from 'src/constants/common';
import { manageSchoolEntries } from 'src/services/entries';

const entryObject = {
  entries: [
    {
      id: generateFileHash(20),
      isLowPriority: false,
      isPrimary: true,
      linkedArray: [],
      label: '',
      title: '',
      location: '',
      description: '',
      expand: true,
      checkDescription: false,
      groupType: '',
      school: '',
      years: [],
      groups: [],
      for: '',
      categoryInfo: [],
      forDropDownData: [],
      // yearDropDownData: [],
      categoryDropDownData: [],
      primary_description: {
        description: '',
        from: {
          date: moment().format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        }
      },
      assignToChild: false,
      assign: [],
      from: {
        date: moment().format('MMM DD,YYYY'),
        time: moment().format('h:mma')
      },
      to: {
        date: moment().format('MMM DD,YYYY'),
        time: moment().format('h:mma')
      },
      allDay: true,
      actions: {
        value: '',
        repeat: [],
        notification: [
          {
            days: -1,
            date: moment().subtract(1, 'days').format('MMM DD,YYYY'),
            time: default_PN_time
          },
          {
            days: '',
            date: moment().format('MMM DD,YYYY'),
            time: default_PN_time
          }
        ],
        deadline: [],
        reminder: [],
        isCancelled: false,
        isPostponed: false,
        isChanged: false,
        isComplete: false
      },
      newFromTimeFormate: { hh: '', mm: '', ap: '' },
      newToTimeFormate: { hh: '', mm: '', ap: '' }
    }
  ]
};

function ClassGroupCalendarEntry({
  firebase,
  onClose,
  isOpen,
  setRightOpen,
  type,
  buttonState,
  catgeoryButton,
  hasData,
  getEntries,
  date,
  allCalenderData,
  setHasEntryData,
  goSourceEntry
}) {
  const classes = categoryGroupCalendarEntryStyles();
  const dispatch = useDispatch();
  const hasPreserveEntryData = useSelector(
    (state) => state.app.hasPreserveEntryData
  );
  const hasFamilyOrSchoolEntryData = useSelector(
    (state) => state.app.hasFamilyOrSchoolEntryData
  );
  const currentUser = useSelector((state) => state.auth.user);
  const schoolData = useSelector((state) => state.school.currentSchool);
  const getCategoryTimings = () => {
    const formID =
      schoolData?.notificationsTiming &&
      Object.keys(schoolData?.notificationsTiming)?.find(
        (key) => catgeoryButton === key
      );
    if (formID) {
      const newTime = moment(schoolData?.notificationsTiming[formID]).format(
        'h:mma'
      );

      return newTime;
    } else {
      return moment(default_PN_time).format('h:mma');
    }
  };
  const [initialEntry, setInitialEntry] = useState({
    entries: [
      {
        ...entryObject.entries[0],
        primaryID: '',
        actionParent: { parentID: '', type: '' },
        groupType: 'Free',
        agentName: currentUser.firstName,
        for: 'group',
        from: {
          date: moment().format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        },
        to: {
          date: moment().format('MMM DD,YYYY'),
          time: moment().format('h:mma')
        }
      }
    ]
  });
  const [isOpenModal, setOpenModal] = useState({ open: false, value: [] });
  const [isModalOpen, setIsModalOpen] = useState({ open: isOpen, value: [] });
  const [isRepeatChange, setIsRepeatChange] = useState(false);
  const [changePrimaryDay, setChangePrimaryDay] = useState('');

  // const [childOptions, setChildOptions] = useState(null);
  const [isRepeatEntryModal, setRepeatEntryModal] = useState(false);
  const [repeatEntryRadioData, setRepeatEntryRadioData] =
    useState('This Event');
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenDeleteLinkedModal, setOpenDeleteLinkedModal] = useState({
    open: false,
    id: null
  });
  const [groupMembers, setGroupmembers] = useState([]);
  const [defaultNote, setDefaultNote] = useState(EditorState.createEmpty());
  const [customValue, setCustomValue] = useState({});
  const [schools, setSchools] = React.useState([]);
  const [years, setYears] = React.useState([]);
  const [category, setCategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [allYears, setAllYears] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [attachFamilyId, setAttachFamilyId] = React.useState([]);
  const [family, setFamily] = React.useState([]);
  const [groupFamily, setGroupFamily] = React.useState([]);
  const [groupAllFamily, setAllGroupFamily] = React.useState([]);
  const [allFamily, setAllFamily] = React.useState([]);
  const [familySurnameWise, setFamilySurnameWise] = React.useState([]);
  const [updatedEntry, setUpdatedEntry] = useState({});
  const isLoading = useSelector((state) => state.app.isLoading);
  const [paidFamiliesData, setPaidFamilies] = React.useState([]);
  const [Groups, setGroupData] = useState([]);
  const [freeCalendarsYear, setFreeCalendarsYear] = useState([]);
  const [year, setYear] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [groupYear, setGroupYear] = useState([]);
  const [convertNotificationTime, setConvertNotificationTime] = useState({
    hh: null,
    mm: null,
    ap: null
  });
  const [recurringEntriesErrors, setRecurringEntriesErrors] = useState({message:''})

  useEffect(() => {
    const freeCalendarsArray = schoolData.freeCalendars.map((year) => {
      const getCurrentYear = _.find(DefaultYear, (elem) => elem.value === year);
      return { id: year, value: getCurrentYear?.label, selected: false };
    });
    setFreeCalendarsYear(freeCalendarsArray);
  }, [schoolData, setFreeCalendarsYear]);

  const fetchGroup = (schoolId, ids) => {
    setGroups([]);
    getGroupBySchoolId(schoolId)
      .then(function (result) {
        let GroupData = [];
        let groupMemberIds = [];
        result.data.filter(item => ids.includes(item.category)).forEach(function (doc) {
          if (doc.groupType !== 'Free') {
            GroupData.push({
              key: doc.groupName,
              value: doc.id,
              selected: false,
              groupMembers: doc.groupMembers
            });
          }
          if (doc.groupType === 'Free') {
            groupMemberIds = groupMemberIds.concat(doc.groupMembers);
            GroupData.push({
              key: doc.groupName,
              value: doc.id,
              selected: hasData?.[0]?.groups?.includes(doc.id) ? true : false,
              years: doc.years,
              groupMembers: doc.groupMembers,
              category: [doc.category]
            });
          }
        });

        let groupUpdated = [];
        groupUpdated = GroupData.filter(
          (item) =>
            typeof item.category === 'object' &&
            item.category.includes(buttonState)
        ).map((val) => val);
        setGroups(groupUpdated);

        let attachFamily = [];
        // PHASE2/CHANGES/SCHOOL
        attachFamily.push({ id: schoolData.id, name: schoolData.schoolName });
        setAttachFamilyId(attachFamily);
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
      });
  };

  useEffect(
    () => {
      if (hasPreserveEntryData) {
        let primaryEntryID = '';
        const updateInitialValueEntry = hasPreserveEntryData.entries.map(
          (entry, index) => {
            entry.id = generateFileHash(20);
            if (entry.isPrimary) {
              primaryEntryID = entry.id;
            } else {
              entry.primaryID = primaryEntryID;
            }
            if (entry.isPrimary) {
              setDefaultNote(
                entry.description
                  ? toGetContentFromHTML(entry.description)
                  : EditorState.createEmpty()
              );
            }
            // }
            if (index === 0) {
              return {
                ...entry,
                groupType: 'Free',
                school: '',
                years: [],
                groups: [],
                for: 'group',
                forDropDownData: []
              };
            } else {
              return { ...entry, for: 'group', forDropDownData: [] };
            }
          }
        );

        setInitialEntry({
          entries: updateInitialValueEntry
        });
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    let categoryIds = [];
    categoryIds = schoolData.categories.map((categoryData) => {
      return categoryData?.id;
    });

    // if (!isModalOpen) {
    fetchGroup(schoolData.id, categoryIds);
  }, [isOpen]);

  useEffect(
    () => {
      dispatch(setPreserveEntryData(updatedEntry));
      // }
    },
    // eslint-disable-next-line
    [isOpen, updatedEntry]
  );

  //Only get free school
  const getFreeSchools = async () => {
    // PHASE2/CHANGES/SCHOOL

    dispatch(loaderStart());
    try {
      const result = await getSchools();
      const schools = [];
      result.forEach(function (school) {
        schools.push({
          id: school.id,
          name: school.schoolName,
          years: getYearsData(school.freeCalendars),
          freeCalLength: school.freeCalendars.length,
          // groups: school.groups, FIXME:
          freeCalendars: school.freeCalendars,
          categories: school.categories
        });
      });
      setSchools(schools);
      dispatch(loaderStop());
    } catch (error) {
      console.error(error);
      dispatch(loaderStop());
      dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
    }
  };

  // Only get Paid family
  const getFamilies = () => {
    dispatch(loaderStart());
    firebase
      .getPaidFamilies()
      .then((querySnapshot) => {
        const paidFamily = [];
        let familySchool = [];
        let years = [];
        querySnapshot.forEach(function (family) {
          paidFamily.push({ ...family.data(), familyId: family.id });
          familySchool = [...familySchool, ...family.data().childsSchools];
          if (family.data().childs && family.data().childs.length > 0) {
            family.data().childs.forEach((child) => {
              years.push({
                key: `Year ${child.year}`,
                value: child.year,
                selected: false
              });
            });
          }
        });

        setPaidFamilies(paidFamily);
        const uniYear = _.uniqBy(years, 'value');
        const sortYear = uniYear.sort(function (a, b) {
          return parseInt(a.value) - parseInt(b.value);
        });

        setYears([
          {
            key: `All Years`,
            value: 'all',
            selected: false
          },
          ...sortYear
        ]);
        setAllYears([
          {
            key: `All Years`,
            value: 'all',
            selected: false
          },
          ...sortYear
        ]);
        familySchool = _.uniq(familySchool);
        let schoolsData = [];
        familySchool.forEach((id, index) => {
          // PHASE2/CHANGES/SCHOOL

          getSchoolByID(id).then((result) => {
            if (result) {
              schoolsData.push({
                id: id,
                name: result.schoolName,
                years: getYearsData(result.freeCalendars),
                freeCalLength: result.freeCalendars.length,
                groups: result.groups
              });
            }
            if (index === familySchool.length - 1) {
              const sortSchoolByName = _.sortBy(schoolsData, ['name']);
              setSchools(sortSchoolByName);
            }
          })
          .catch(function (error) {
            console.log('Error getting documents: ', error);
            dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
          });
        });
        dispatch(loaderStop());
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
      });
  };

  useEffect(() => {
    if (type === 'Families' || type === 'Group') {
      getFamilies();
    } else {
      getFreeSchools();
    }
  }, [firebase, setSchools, dispatch]);

  // setting notification of next day auto update
  const setNotificationTime = (convertNotificationTime, values, idx) => {
    let newTime = '';
    if (convertNotificationTime.hh !== null) {
      let hours =
        parseInt(convertNotificationTime.hh) === 1
          ? '12'
          : parseInt(convertNotificationTime.hh) - 1;
      let minute =
        convertNotificationTime.mm !== null ? convertNotificationTime.mm : '00';
      let ap =
        convertNotificationTime.ap !== null ? convertNotificationTime.ap : 'a';

      if (
        parseInt(convertNotificationTime.hh) === 12 &&
        convertNotificationTime.ap === 'p'
      ) {
        ap = 'a';
      } else if (
        parseInt(convertNotificationTime.hh) === 12 &&
        convertNotificationTime.ap === 'a'
      ) {
        ap = 'p';
      }
      newTime = hours + ':' + minute + ap + 'm';
    }
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === idx) {
        const notificationArray = data.actions.notification.map(
          (notificationData, notificationIndex) => {
            if (notificationIndex !== 0) {
              return {
                ...notificationData,
                time: newTime
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, notification: notificationArray }
        };
      } else {
        return { ...data };
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  useEffect(
    () => {
      console.log("=> Group HasData", hasData)
      if (hasData && hasData.length) {
        if (hasData[0].school) {
          dispatch(loaderStart());
          getGroupBySchoolId(hasData[0].school).then(result => {
            let GroupData = [];
            let groupMemberIds = [];
            result.data.forEach(function (item) {
              if (item.groupType === 'Free') {
                groupMemberIds = groupMemberIds?.concat(
                  item.groupMembers
                );

                GroupData.push({
                  key: item.groupName,
                  value: item.id,
                  selected:
                    hasData[0].groups &&
                    hasData[0].groups.length &&
                    hasData[0].groups.includes(item.id)
                      ? true
                      : false,
                  years: item.years,
                  groupMembers: item.groupMembers,
                  category: [item.category]
                });
              }
            });

            let groupUpdated = [];
            let groupMembers = [];
            let yearArray = [];
            let schoolGroupsData = [];

            if (hasData && hasData?.[0]?.years?.length) {
              yearArray = GroupData.filter((item) => {
                const filterResult = item?.years?.map(
                  (data) => hasData && hasData?.[0]?.years?.includes(data)
                );
                if (
                  filterResult.includes(true) &&
                  (hasData?.[0]?.categoryInfo?.includes(
                    item?.category?.[0]
                  ) ||
                    item.category.includes(hasData[0]?.buttonState))
                ) {
                  return item.key;
                }
              });
              console.log("=> Group yearArray", yearArray);
              setGroups(yearArray);
              schoolGroupsData = yearArray;
            } else if (
              hasData[0].for === 'group' ||
              hasData[0]?.years?.length === 0
            ) {
              groupUpdated = GroupData.filter(
                (item) =>
                  hasData?.[0]?.categoryInfo?.includes(item?.category?.[0]) ||
                  item.category.includes(hasData[0]?.buttonState)
              ).map((val) => val);
              console.log("=> Group groupUpdated", groupUpdated);
              setGroups(groupUpdated);
              schoolGroupsData = groupUpdated;
            }
            console.log("=> Group groups", groups);
            schoolGroupsData.forEach((groupData) => {
              console.log("=> Group groups include", hasData?.[0]?.groups?.includes(groupData.value));
              if (hasData?.[0]?.groups?.includes(groupData.value)) {
                groupMembers = groupMembers.concat(groupData.groupMembers);
              }
            });
            console.log("=> Group groupMembers", groupMembers);
            groupMembers = _.uniq(groupMembers);
            console.log("=> Group 3", groupMembers);
            setGroupmembers(groupMembers);
            dispatch(loaderStop());
          }).catch(error => {
            console.log('error', error);
            dispatch(addMessage('Server connection issue. Please refresh'));
          })
        }
         // Setting default Note for description check
         if (hasData[0].description) {
          setDefaultNote(toGetContentFromHTML(hasData[0].description));
        }
        // updating push notification PN
        const defaultTime = hasData[0]?.actions?.notification[0]?.time;
        if (defaultTime !== '') {
          const timeSplit = defaultTime?.split(':');
          if (timeSplit && timeSplit[1]?.includes('a')) {
            const amTime = timeSplit && timeSplit[1]?.split('a');
            setConvertNotificationTime({
              ...convertNotificationTime,
              hh: timeSplit && timeSplit[0],
              mm: amTime && amTime[0],
              ap: 'a'
            });
          } else {
            const pmTime = timeSplit && timeSplit[1]?.split('p');
            setConvertNotificationTime({
              ...convertNotificationTime,
              hh: timeSplit && timeSplit[0],
              mm: pmTime && pmTime[0],
              ap: 'p'
            });
          }
        }

        const updateValue = hasData.map((entry) => {
          if (!entry.school) {
            entry.school = schoolData.id;
          }

          return entry;
        });
        // short linked entries incorrect order issue - fix
        const sortUpdatedArray = sortLinkedEntries(updateValue);
        setInitialEntry({ entries: [...sortUpdatedArray] });
      } else if (
        hasFamilyOrSchoolEntryData &&
        hasFamilyOrSchoolEntryData.entries
      ) {
        let primaryEntryID = '';
        const updatedPreserveEntryData = hasFamilyOrSchoolEntryData.entries.map(
          (entry) => {
            const freeCalendarsMultipleArray = schoolData.freeCalendars.map(
              (year) => {
                const getCurrentYear = _.find(
                  DefaultYear,
                  (elem) => elem.value === year
                );
                return {
                  value: year,
                  key: getCurrentYear?.label,
                  selected:
                    entry.for.length && entry.for.includes(year) ? true : false
                };
              }
            );
            entry.freeCalendarsMultipleOptions = [
              {
                key: `All Years`,
                value: 'all',
                selected: entry.for.includes('all') ? true : false
              },
              ...freeCalendarsMultipleArray
            ];
            entry.id = generateFileHash(20);
            entry.agentName = currentUser.firstName;
            if (entry.isPrimary) {
              primaryEntryID = entry.id;
            } else {
              entry.primaryID = primaryEntryID;
            }

            const onDayBefore = moment(entry.from.date, 'MMM DD,YYYY')
              .subtract(1, 'days')
              .format('MMM DD,YYYY');
            entry.actions = {
              ...entry.actions,
              notification: [
                { days: -1, date: onDayBefore, time: getCategoryTimings() },
                { days: '', date: entry.from.date, time: getCategoryTimings() }
              ]
            };

            return entry;
          }
        );
        setInitialEntry({ entries: [...updatedPreserveEntryData] });
      } else {
        const updatedPreserveEntryData = initialEntry.entries.map((entry) => {
          const freeCalendarsMultipleArray = schoolData.freeCalendars.map(
            (year) => {
              const getCurrentYear = _.find(
                DefaultYear,
                (elem) => elem.value === year
              );
              return {
                value: year,
                key: getCurrentYear?.label,
                selected: false
              };
            }
          );

          entry.freeCalendarsMultipleOptions = [
            {
              key: `All Years`,
              value: 'all',
              selected: false
            },
            ...freeCalendarsMultipleArray
          ];

          entry.from.date = date.format('MMM DD,YYYY');
          entry.to.date = date.format('MMM DD,YYYY');

          const onDayBefore = moment(date.format('MMM DD,YYYY'), 'MMM DD,YYYY')
            .subtract(1, 'days')
            .format('MMM DD,YYYY');
          entry.actions = {
            ...entry.actions,
            notification: [
              { days: -1, date: onDayBefore, time: getCategoryTimings() },
              { days: '', date: entry.to.date, time: getCategoryTimings() }
            ]
          };

          return entry;
        });
        setInitialEntry({ entries: [...updatedPreserveEntryData] });
      }
    },
    // eslint-disable-next-line
    [hasData, setInitialEntry, schools, schoolData.id, firebase, buttonState]
  );

  const getYearsData = (data) => {
    const updatedYear =
      data.length &&
      data.map((year) => {
        return { key: `Year ${year}` };
      });
    return updatedYear && updatedYear.length ? updatedYear : [];
  };

  const getInitials = (array) => {
    const childArray =
      array && array.length && array.map((child) => child.initials);
    return array && array.length ? [...childArray].join(', ') : '';
  };

  const addLinkedEntry = (values) => {
    const newLinkedId = generateFileHash(20);
    const updateInitialValue = values.entries.map((data, index) => {
      if (data.isPrimary) {
        return {
          ...data,
          expand: false,
          groupType: 'Free',
          linkedArray: data.linkedArray
            ? [...data.linkedArray, newLinkedId]
            : [newLinkedId]
        };
      } else {
        delete data.linkedArray;
        return {
          ...data,
          expand: false,
          isPrimary: false,
          groupType: 'Free'
        };
      }
    });
    delete entryObject.entries[0].linkedArray;

    let selectedForDropDownData = [];
    if (family.length) {
      const foundSelectedFamilyMap = _.map(family, (familyData) => {
        return familyData.selected ? familyData.familyId : null;
      });
      const foundSelectedFamilyFilter = _.filter(
        foundSelectedFamilyMap,
        (familyData) => familyData
      );

      const foundSelectedFamilyWithSurnameFilter = _.filter(
        family,
        (familyData) => foundSelectedFamilyFilter.includes(familyData.familyId)
      );
      if (
        foundSelectedFamilyWithSurnameFilter &&
        foundSelectedFamilyWithSurnameFilter.length
      ) {
        selectedForDropDownData = [
          family[0],
          ...foundSelectedFamilyWithSurnameFilter
        ];
      } else {
        selectedForDropDownData = family;
      }
    }

    setYear(values.entries[0].years);
    const yearsFind = values?.entries[0]?.forDropDownData?.length
      ? values?.entries[0]?.forDropDownData
      : groupYear;
    setGroupYear(yearsFind);
    setInitialEntry({
      entries: [
        ...updateInitialValue,
        {
          ...entryObject.entries[0],
          id: newLinkedId,
          isPrimary: false,
          agentName: currentUser.firstName,
          groupType: 'Free',
          groupMembers:
            values.entries[0].groupType === 'Free'
              ? values.entries[0].groupMembers
                ? values.entries[0].groupMembers
                : []
              : [],
          groups: values.entries[0].groups ? values.entries[0].groups : [],
          forDropDownData:
            values.entries[0].groupType === 'Families' ||
            values.entries[0].groupType === 'Group'
              ? selectedForDropDownData
              : [],
          for: 'group',
          assignToChild: values.entries[0].assignToChild,
          // yearDropDownData: values.entries[0].yearDropDownData,
          categoryDropDownData: values.entries[0].categoryDropDownData,
          years: values.entries[0].years,
          actions: {
            ...entryObject.entries[0].actions,
            // notification : [{days: -1, date: moment(date.format('MMM DD,YYYY'), 'MMM DD,YYYY').subtract(1, 'days').format('MMM DD,YYYY'), time: "7:30pm"},{days: "", date:  date.format('MMM DD,YYYY'), time: "7:30pm"}]
            notification: [
              {
                days: -1,
                date: moment().subtract(1, 'days').format('MMM DD,YYYY'),
                time: getCategoryTimings()
              },
              {
                days: '',
                date: moment().format('MMM DD,YYYY'),
                time: getCategoryTimings()
              }
            ]
          }
        }
      ]
    });
  };

  const removeLinkedEntry = () => {
    if (isOpenDeleteLinkedModal.id) {
      const values = [...initialEntry.entries];
      const removeLinkedEntryId = _.findIndex(
        values[0].linkedArray,
        (item) => item === values[isOpenDeleteLinkedModal.id].id
      );
      if (removeLinkedEntryId > -1) {
        values[0].linkedArray.splice(removeLinkedEntryId, 1);
      }

      values.splice(isOpenDeleteLinkedModal.id, 1);
      setInitialEntry({ entries: [...values] });
    }
    setOpenDeleteLinkedModal({ open: false, id: null });
  };

  const handleExpandChange = (id, values) => {
    let updateInitialValue = [];
    updateInitialValue = values.entries.map((data, index) => {
      if (index === id) {
        return { ...data, expand: data.expand ? false : true };
      } else {
        return { ...data, expand: false };
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const validationSchema = Yup.lazy((values) => {
    return Yup.object().shape({
      entries: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          label: Yup.string()
            .trim()
            .default('none')
            .required('Label is required'),
          title: Yup.string().trim().required('Title is required'),
          location: Yup.string().trim(),
          description: Yup.string().trim(),
          years: Yup.array().test(
            'year test',
            'Year is required',
            function (val) {
              return values.entries.length &&
                values.entries[0] &&
                values.entries[0].groupType === 'Families'
                ? values.entries[0].years.length
                : true;
            }
          ),
          groupType: Yup.string().trim(),
          groups: Yup.array().test(
            'group test',
            'Group is required',
            function (val) {
              return values.entries.length && values.entries[0]
                ? values.entries[0].groups.length
                : true;
            }
          ),
          // for: Yup.array().required('For is required'),
          from: Yup.object().required('From is required'),
          // to: Yup.object().required('To is required'),
          to: Yup.object()
            .required('To is required')
            .test(
              'date test',
              'From date needs to be before the To date',
              function (val) {
                const { from, to } = this.parent;
                return moment(to.date, 'MMM DD,YYYY').isSameOrAfter(
                  moment(from.date, 'MMM DD,YYYY')
                );
              }
            ),
          allDay: Yup.boolean(),
          checkDescription: Yup.boolean(),
          actions: Yup.object().shape({
            repeat: Yup.array().of(
              Yup.object().shape({ type: Yup.string().trim() })
            ),
            notification: Yup.array().of(
              Yup.object().shape({
                days: Yup.string().trim(),
                time: Yup.string().trim()
              })
            ),
            deadline: Yup.array(),
            reminder: Yup.array()
          }),
          newFromTimeFormate: Yup.object().when('allDay', {
            is: false,
            then: Yup.object({
              hh: Yup.string().trim().required('time is required'),
              mm: Yup.string().trim().required('time is required'),
              ap: Yup.string().trim().required('time is required')
            })
          }),
          // newFromTimeFormate: Yup.object().shape({
          //   hh: Yup.string().trim().required('time is required'),
          //   mm: Yup.string().trim().required('time is required'),
          //   ap: Yup.string().trim().required('time is required'),
          // }),
          newToTimeFormate: Yup.object().when('allDay', {
            is: false,
            then: Yup.object({
              hh: Yup.string().trim().required('time is required'),
              mm: Yup.string().trim().required('time is required'),
              ap: Yup.string().trim().required('time is required')
            })
          })
        })
      )
    });
  });

  const _deleteEntryHandleForRepeat = () => {
    let formData = [];
    const parentRepeatEntryId = hasData[0].parentRepeatEntryId;
    const originEntry = allCalenderData[0].entries.find(
      (entry) => entry.id === parentRepeatEntryId
    );
    //const linkedEntries = allCalenderData[0].entries.find(entry => originEntry.linkedArray.includes(entry.id));
    const familyId = schoolData.id;
    const deleteDate = hasData[0].from.date;

    if (repeatEntryRadioData === 'This Event') {
      originEntry.repeatDeleted = originEntry.repeatDeleted
        ? [...originEntry.repeatDeleted, deleteDate]
        : [deleteDate];
    } else if (repeatEntryRadioData === 'This and following events') {
      if (originEntry.actions.repeat[0].values) {
        originEntry.actions.repeat[0].values.endsDate = moment(
          hasData[0].from.date,
          'MMM DD,YYYY'
        )
          .subtract(1, 'days')
          .format('MMM DD,YYYY');
        originEntry.actions.repeat[0].values.endsType = 'On';
      } else {
        const entryObject = {
          repeatDay: 1,
          repeatType: 'Day',
          repeatOn: [],
          repeatMonth: { type: 'day', value: moment().format('MMM DD,YYYY') },
          endsType: 'On',
          endsDate: moment(hasData[0].from.date, 'MMM DD,YYYY')
            .subtract(1, 'days')
            .format('MMM DD,YYYY'),
          endsAfter: 1
        };
        originEntry.actions.repeat[0] = { type: 'Custom', values: entryObject };
      }
    } else {
      // Do nothing for all
      return;
    }
    dispatch(loaderStart());

    // const finalData = {
    //   docId: allCalenderData[0].id,
    //   entry: originEntry
    // };

    manageSchoolEntries([originEntry])
      .then(() => {
        dispatch(loaderStop());
        dispatch(addMessage('Calendar Entry Deleted'));

        setInitialEntry({
          entries: [
            {
              ...entryObject.entries[0],
              id: generateFileHash(20),
              agentName: currentUser.firstName,
              from: {
                date: moment().format('MMM DD,YYYY'),
                time: moment().format('h:mma')
              },
              to: {
                date: moment().format('MMM DD,YYYY'),
                time: moment().format('h:mma')
              }
            }
          ]
        });

        dispatch(setFamilyOrSchoolPreserveEntryData(null));
        setHasEntryData([]);
        setRightOpen(false);
        getEntries(date);
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  };

  const handleActionChange = (e, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      let updatedData;
      if (index === selectedIndex) {
        if (e.target.value === 'deadline' || e.target.value === 'reminder') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              [e.target.value]: [
                ...data.actions[e.target.value],
                moment().format('MMM DD,YYYY')
              ],
              [e.target.value + 'Notification']:
                e.target.value === 'reminder'
                  ? [
                      ...(data.actions.reminderNotification || []),
                      {
                        days: '',
                        date: moment().format('MMM DD,YYYY'),
                        time: getCategoryTimings()
                      }
                    ]
                  : [
                      ...(data.actions.deadlineNotification || []),
                      {
                        days: '',
                        date: moment().format('MMM DD,YYYY'),
                        time: getCategoryTimings()
                      }
                    ]
            }
          };
          // setGroupYear(data?.forDropDownData)
        } else if (e.target.value === 'notification') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              notification: [
                ...data.actions.notification,
                {
                  days: '',
                  date: moment(data.from.date, 'MMM DD,YYYY').format(
                    'MMM DD,YYYY'
                  ),
                  time: moment().format('h:mma')
                }
              ]
            }
          };
          // setGroupYear(data?.forDropDownData)
        } else if (e.target.value === 'repeat') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              repeat: [...data.actions.repeat, { type: 'Does not repeat' }] //[...data.actions.repeat, { type: '', values: {} }]
            }
          };
        } else if (e.target.value === 'cancelled') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isChanged: false,
              isPostponed: false,
              isCancelled: true
            }
          };
        } else if (e.target.value === 'postponed') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isChanged: false,
              isCancelled: false,
              isPostponed: true
            }
          };
        } else if (e.target.value === 'changed') {
          updatedData = {
            ...data,
            actions: {
              ...data.actions,
              value: '',
              isCancelled: false,
              isPostponed: false,
              isChanged: true
            }
          };
        }
      } else {
        updatedData = data;
      }
      return updatedData;
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const onEditorChange = (e, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (data.isPrimary) {
          setDefaultNote(e);
        }
        return { ...data, description: e };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleChangeCheck = (name, selectedIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (name === 'checkDescription') {
          setDefaultNote(toGetContentFromHTML(values.entries[0].description));
          return {
            ...data,
            [name]: data[name] ? false : true
          };
        } else {
          return { ...data, [name]: data[name] ? false : true };
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleNotificationDateChange = (
    e,
    selectedIndex,
    innerIndex,
    values,
    value,
    type = ''
  ) => {
    const formateValue = 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (type === 'reminderNotification' && index === selectedIndex) {
        const notificationArray = data.actions.reminderNotification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, reminderNotification: notificationArray }
        };
      } else if (type === 'deadlineNotification' && index === selectedIndex) {
        const notificationArray = data.actions.deadlineNotification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, deadlineNotification: notificationArray }
        };
      } else if (index === selectedIndex) {
        const notificationArray = data.actions.notification.map(
          (notificationData, notificationIndex) => {
            if (innerIndex === notificationIndex) {
              const notificationDate = moment(e, formateValue);
              const fromDate = moment(data.from.date, formateValue);
              let dayValue = '';
              if (moment(notificationDate).isSame(fromDate)) {
                dayValue = '';
              } else {
                dayValue = notificationDate.diff(fromDate, 'days');
              }
              return {
                ...notificationData,
                days: dayValue,
                date: moment(e).format(formateValue)
              };
            } else {
              return notificationData;
            }
          }
        );
        return {
          ...data,
          actions: { ...data.actions, notification: notificationArray }
        };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleDateChange = (e, selectedIndex, values, key, value) => {
    const formateValue = value === 'time' ? 'h:mma' : 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (key === 'from' && value === 'date') {
          const endDate = moment(data.to.date, 'MMM DD,YYYY').isSameOrAfter(
            moment(e, 'MMM DD,YYYY')
          )
            ? data.to.date
            : moment(e).format(formateValue);
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) },
            to: { ...data['to'], [value]: endDate },
            actions: {
              ...data.actions,
              notification:
                hasData && hasData[0]
                  ? data.actions.notification
                  : [
                      {
                        days: -1,
                        date: moment(e, 'MMM DD,YYYY')
                          .subtract(1, 'days')
                          .format('MMM DD,YYYY'),
                        time: getCategoryTimings()
                      },
                      {
                        days: '',
                        date: moment(e, 'MMM DD,YYYY').format('MMM DD,YYYY'),
                        time: getCategoryTimings()
                      }
                    ]
            }
          };
        } else if (key === 'from' && value === 'time') {
          const endTime = moment(e).add(1, 'hour').format(formateValue);
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) },
            to: { ...data['to'], [value]: endTime }
          };
        } else {
          return {
            ...data,
            [key]: { ...data[key], [value]: moment(e).format(formateValue) }
          };
        }
      } else {
        return data;
      }
    });

    const currentObj = updateInitialValue[selectedIndex]
    const isSameDate = currentObj.from.date === currentObj.to.date
    if (isSameDate) {
      // Remove Error Message If Date Same
      setRecurringEntriesErrors({message: ''})
    }

    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleRepeatChange = (e, selectedIndex, innerIndex, values) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        const repeatArray = data.actions.repeat.map(
          (repeatData, repeatIndex) => {
            if (innerIndex === repeatIndex) {
              return { type: e.target.value };
            } else {
              return repeatData;
            }
          }
        );
        return { ...data, actions: { ...data.actions, repeat: repeatArray } };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
    if (e.target.value === 'Custom') {
      const data = {
        selectedIndex,
        innerIndex,
        values: { entries: [...updateInitialValue] }
      };
      setCustomValue(data);
      setOpenModal({
        open: true,
        value: JSON.stringify(
          data.values.entries[selectedIndex].actions.repeat[innerIndex]
        )
      });
    }
  };

  const handleDateActionChange = (
    e,
    selectedIndex,
    innerIndex,
    values,
    key,
    value
  ) => {
    const formateValue = value === 'time' ? 'h:mma' : 'MMM DD,YYYY';
    const updateInitialValue = values.entries.map((data, index) => {
      if (index === selectedIndex) {
        if (key === 'notification') {
          const notificationArray = data.actions.notification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: { ...data.actions, notification: notificationArray }
          };
        } else if (key === 'reminderNotification') {
          const notificationArray = data.actions.reminderNotification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              reminderNotification: notificationArray
            }
          };
        } else if (key === 'deadlineNotification') {
          const notificationArray = data.actions.deadlineNotification.map(
            (notificationData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return {
                  days: notificationData.days ? notificationData.days : '',
                  date: notificationData.date
                    ? notificationData.date
                    : moment(data.from.date, 'MMM DD,YYYY').format(
                        'MMM DD,YYYY'
                      ),
                  [value]: moment(e).format(formateValue)
                };
              } else {
                return notificationData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              deadlineNotification: notificationArray
            }
          };
        } else if (key === 'reminder') {
          const remindNotifyUpdate = data.actions.reminderNotification
            ? [...data.actions.reminderNotification]
            : [];
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                if (
                  remindNotifyUpdate.length &&
                  remindNotifyUpdate[notificationIndex]
                ) {
                  const notificationDate = moment(e, formateValue);
                  const fromDate = moment(data.from.date, formateValue);
                  let dayValue = '';
                  if (moment(notificationDate).isSame(fromDate)) {
                    dayValue = '';
                  } else {
                    dayValue = notificationDate.diff(fromDate, 'days');
                  }
                  remindNotifyUpdate[notificationIndex] = {
                    ...remindNotifyUpdate[notificationIndex],
                    days: dayValue,
                    date: moment(e).format(formateValue)
                  };
                }
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              [key]: actionArray,
              reminderNotification: remindNotifyUpdate
            }
          };
        } else if (key === 'deadline') {
          const deadlineNotifyUpdate = data.actions.deadlineNotification
            ? [...data.actions.deadlineNotification]
            : [];
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                if (
                  deadlineNotifyUpdate.length &&
                  deadlineNotifyUpdate[notificationIndex]
                ) {
                  const notificationDate = moment(e, formateValue);
                  const fromDate = moment(data.from.date, formateValue);
                  let dayValue = '';
                  if (moment(notificationDate).isSame(fromDate)) {
                    dayValue = '';
                  } else {
                    dayValue = notificationDate.diff(fromDate, 'days');
                  }
                  deadlineNotifyUpdate[notificationIndex] = {
                    ...deadlineNotifyUpdate[notificationIndex],
                    days: dayValue,
                    date: moment(e).format(formateValue)
                  };
                }
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return {
            ...data,
            actions: {
              ...data.actions,
              [key]: actionArray,
              deadlineNotification: deadlineNotifyUpdate
            }
          };
        } else if (key) {
          const actionArray = data.actions[key].map(
            (actionData, notificationIndex) => {
              if (innerIndex === notificationIndex) {
                return moment(e).format(formateValue);
              } else {
                return actionData;
              }
            }
          );
          return { ...data, actions: { ...data.actions, [key]: actionArray } };
        } else {
          return data;
        }
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleDateActionRemove = (selectedIndex, innerIndex, values, key) => {
    const updateInitialValue = values.entries.map((data, index) => {
      if (
        key === 'isCancelled' ||
        key === 'isPostponed' ||
        key === 'isChanged'
      ) {
        if (index === selectedIndex) {
          const values = data.actions;
          values[key] = false;
          return { ...data, values };
        } else {
          return data;
        }
      } else if (key === 'reminderNotification') {
        if (index === selectedIndex) {
          const values = data.actions.reminderNotification.filter(
            (date, index) => index !== innerIndex
          );
          return {
            ...data,
            actions: { ...data.actions, reminderNotification: values }
          };
        } else {
          return data;
        }
        //reminderNotification
      } else if (key === 'deadlineNotification') {
        if (index === selectedIndex) {
          const values = data.actions.deadlineNotification.filter(
            (date, index) => index !== innerIndex
          );
          return {
            ...data,
            actions: { ...data.actions, deadlineNotification: values }
          };
        } else {
          return data;
        }
        //deadlineNotification
      } else {
        if (index === selectedIndex) {
          const values = data.actions;
          values[key].splice(innerIndex, 1);
          return { ...data, values };
        } else {
          return data;
        }
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
  };

  const handleCustomDateSubmit = (dateModalData) => {
    const {
      data: { selectedIndex, innerIndex }
    } = dateModalData;
    const updateInitialValue = dateModalData.data.values.entries.map(
      (data, index) => {
        if (index === selectedIndex) {
          const repeatArray = data.actions.repeat.map(
            (repeatData, repeatIndex) => {
              if (innerIndex === repeatIndex) {
                return { ...repeatData, values: dateModalData.values };
              } else {
                return repeatData;
              }
            }
          );
          return { ...data, actions: { ...data.actions, repeat: repeatArray } };
        } else {
          return data;
        }
      }
    );
    setInitialEntry({ entries: [...updateInitialValue] });
    setOpenModal({ open: false, value: [] });
    setCustomValue({});
  };

  const handleCustomDateClose = () => {
    const modalValue =
      typeof isOpenModal?.value === 'string'
        ? JSON.parse(isOpenModal.value)
        : isOpenModal?.value;
    const { selectedIndex, innerIndex } = customValue;
    const updateInitialValue = initialEntry.entries.map((data, index) => {
      if (index === selectedIndex) {
        const repeatArray = data.actions.repeat.map(
          (repeatData, repeatIndex) => {
            if (innerIndex === repeatIndex) {
              const fromDate = moment(data.from.date, 'MMM DD,YYYY').format();
              const customDateObject = {
                repeatDay: 1,
                repeatType: 'Day',
                repeatOn: [moment(fromDate).format('ddd')],
                repeatMonth: {
                  type: 'day',
                  value: moment(fromDate).format('MMM DD,YYYY')
                },
                endsType: 'Never',
                endsDate: moment(fromDate).format('MMM DD,YYYY'),
                endsAfter: 1
              };
              return {
                ...repeatData,
                values: modalValue?.values ? modalValue.values : customDateObject
              };
            } else {
              return repeatData;
            }
          }
        );
        return { ...data, actions: { ...data.actions, repeat: repeatArray } };
      } else {
        return data;
      }
    });
    setInitialEntry({ entries: [...updateInitialValue] });
    setOpenModal({ open: false, value: [] });
    setCustomValue({});
  };

  const handleChangeFreeYear = (
    event,
    selectedIndex,
    entries,
    setFieldValue
  ) => {
    let groupMembers = [];
    let selectValue = event.target.value;
    setYear(selectValue);
    groups.forEach((groupData) => {
      if (selectValue.includes(groupData.value)) {
        groupMembers = groupMembers.concat(groupData.groupMembers);
      }
    });
    groupMembers = _.uniq(groupMembers);
    console.log("=> Group 1", groupMembers);
    setGroupmembers(groupMembers);
    // setFieldValue(`entries[${selectedIndex}].years`,[...entries[0].years, ...selectValue]);
    // if we have to select all in All Years then uncomment the code

    const allId = _.map(years, (year) => year.value);
    if (
      selectValue[selectValue.length - 1] === 'all' &&
      selectValue.includes('all')
    ) {
      selectValue = allId;
    } else {
      const removeAllIndex = _.findIndex(selectValue, (id) => id === 'all');
      if (removeAllIndex > -1) {
        selectValue.splice(removeAllIndex, 1);
      }
      // selectValue = selectValue;
    }

    if (entries[selectedIndex].groupType === 'Families') {
      // setFieldValue(`entries[${selectedIndex}].for`, []);
      // setFieldValue(`entries[${selectedIndex}].years`, selectValue);
      if (selectValue.includes('all')) {
        setFamily(allFamily);
        entries.map((entryData, index) => {
          if (selectedIndex === index) {
            setFieldValue(`entries[${selectedIndex}].years`, selectValue);
            setFieldValue(
              `entries[${selectedIndex}].forDropDownData`,
              allFamily
            );
          } else {
            setFieldValue(`entries[${index}].forDropDownData`, allFamily);
            setFieldValue(`entries[${index}].years`, selectValue);
          }
          return entries;
        });
      } else {
        const yearFamiy = [
          {
            id: 'Select All Parents',
            name: 'Select All Parents',
            type: 'Select All Parents',
            selected: false
          }
        ];
        allFamily.forEach((element) => {
          let added = false;

          if (element.yearArray) {
            selectValue.forEach((val) => {
              if (element.yearArray.indexOf(val) > -1 && !added) {
                added = true;
                yearFamiy.push(element);
              }
            });
          } else {
            if (selectValue.includes(element.year)) {
              yearFamiy.push(element);
            }
          }
        });
        setFamily(yearFamiy);
        // setFieldValue(`entries[${selectedIndex}].forDropDownData`, yearFamiy);
        entries.map((entryData, index) => {
          if (selectedIndex === index) {
            setFieldValue(`entries[${selectedIndex}].for`, []);
            setFieldValue(`entries[${selectedIndex}].years`, selectValue);
            // setFieldValue(
            //   `entries[${selectedIndex}].forDropDownData`,
            //   yearFamiy
            // );
          } else {
            // setFieldValue(`entries[${index}].forDropDownData`, yearFamiy);
            setFieldValue(`entries[${index}].for`, []);
            setFieldValue(`entries[${index}].years`, selectValue);
          }
          return entries;
        });
      }
    }
    let updatedGroupYears = [];
    const updatedYears = freeCalendarsYear.map((yearData, index) => {
      if (selectValue.includes(yearData.value)) {
        updatedGroupYears.push(yearData.value);
        return {
          ...yearData,
          selected: true
        };
      } else {
        return {
          ...yearData,
          selected: false
        };
      }
    }).filter(x => x !== undefined);;
    setGroupYear(updatedGroupYears);
    setFreeCalendarsYear(updatedYears);

    const updatedGroups = groups.map((groupData, index) => {
      // if (categoryFilter.includes(groupData.value)) {
      //   return {
      //     ...groupData,
      //     selected: true
      //   };
      // } else {
      //   return {
      //     ...groupData,
      //     selected: false
      //   };
      // }
      return {
        ...groupData,
        selected: false
      };
    });
    setGroups(updatedGroups);
  };

  const handleCategoryChange = (
    event,
    selectedIndex,
    entries,
    setFieldValue
  ) => {
    let selectValue = event.target.value;
    let groupMembers = [];
    groups.forEach((groupData, index) => {
      if (selectValue?.includes(groupData.value)) {
        groupMembers = groupMembers.concat(groupData.groupMembers);
      }
    });
    groupMembers = _.uniq(groupMembers);
    console.log("=> Group 2", groupMembers);
    setGroupmembers(groupMembers);

    setCategoryFilter(event.target.value);
    if (hasData?.length) {
      setYear(hasData?.[0]?.years);
    }
    const updatedGroups = groups.map((groupData, index) => {
      if (selectValue.includes(groupData.value)) {
        return {
          ...groupData,
          selected: true
        };
      } else {
        return {
          ...groupData,
          selected: false
        };
      }
    });

    setGroups(updatedGroups);

    const updatedYears = updatedGroups
      .filter((item) => item.selected)
      .map((yearData, index) => {
        if (selectValue.includes(yearData.value)) {
          return {
            ...yearData,
            selected: true
          };
        } else {
          return {
            ...yearData,
            selected: false
          };
        }
      });
    const groupYears = updatedYears?.map((item) => item.years);
    const filterYears = _.uniq(groupYears.flat());
    setGroupYear(filterYears);

    const updatedFreeCalenderYears = freeCalendarsYear.map(
      (yearData, index) => {
        if (filterYears.includes(yearData.value)) {
          return {
            ...yearData,
            selected: true
          };
        } else {
          return {
            ...yearData,
            selected: false
          };
        }
      }
    );
    setFreeCalendarsYear(updatedFreeCalenderYears);
    setFieldValue(`entries[${selectedIndex}].years`, filterYears);
  };

  const deleteSchoolDataEntry = async (docId, id) => {
    await firebase.deleteSchoolDataEntries(docId, id);
  };

  const compareDataAndUpdate = (entry, hasData, formData) => {
    let newFor = entry.for;
    let assign = entry.assign;
    if (formData[0].for !== hasData[0].for) {
      newFor = formData[0].for;
    } else if (
      Array.isArray(formData[0].for) &&
      Array.isArray(hasData[0].for)
    ) {
      if (!_.isEqual(_.sortBy(formData[0].for), _.sortBy(hasData[0].for))) {
        newFor = formData[0].for;
      } else {
        newFor = hasData[0].for;
      }
    } else {
      newFor = hasData[0].for;
    }

    if (formData[0].assign !== hasData[0].assign) {
      assign = formData[0].assign;
    } else if (
      Array.isArray(formData[0].assign) &&
      Array.isArray(hasData[0].assign)
    ) {
      if (
        !_.isEqual(_.sortBy(formData[0].assign), _.sortBy(hasData[0].assign))
      ) {
        assign = formData[0].assign;
      } else {
        assign = hasData[0].assign;
      }
    } else {
      assign = hasData[0].assign;
    }

    let editedFormData = {
      ...formData[0],
      label:
        hasData[0].label === formData[0].label
          ? entry.label
          : formData[0].label,
      title:
        hasData[0].title === formData[0].title
          ? entry.title
          : formData[0].title,
      location:
        hasData[0].location === formData[0].location
          ? entry.location
          : formData[0].location,
      description:
        hasData[0].description === formData[0].description
          ? entry.description
          : formData[0].description,
      for: newFor,
      assign: assign,
      from: {
        date: entry.from.date,
        time:
          hasData[0].from.time === formData[0].from.time
            ? entry.from.time
            : formData[0].from.time
      },
      to: {
        date: entry.to.date,
        time:
          hasData[0].to.time === formData[0].to.time
            ? entry.to.time
            : formData[0].to.time
      },
      allDay:
        hasData[0].allDay === formData[0].allDay
          ? entry.allDay
          : formData[0].allDay,
      actions: {
        ...formData[0].actions,
        notification: entry.actions.notification
      }
    };
    return editedFormData;
  };

  const deleteFreeSchoolFromGroup = (groupEntryID, hasData) => {
    const oldYear = hasData[0].for;
    firebase
      .getGroupEntries(groupEntryID)
      .then((groupEntries) => {
        if (
          groupEntries &&
          groupEntries.entries &&
          groupEntries.entries.length
        ) {
          let newEntries = JSON.parse(JSON.stringify(groupEntries.entries));
          if (newEntries[0].for.length === 1) {
            //delete primary entry if only 1 for
            firebase
              .deleteGroupEntries(groupEntries.docId)
              .then(() => {})
              .catch((error) =>
                console.log('Error to delete group entries', error)
              );
          } else {
            // Delete group entry if primary "For" all
            if (
              newEntries[0].for.length > 1 &&
              newEntries[0].for.includes('all')
            ) {
              firebase
                .deleteGroupEntries(groupEntries.docId)
                .then(() => {})
                .catch((error) =>
                  console.log('Error to delete group entries', error)
                );
            } else {
              let filteredLinkedEntry = groupEntries.entries.map((entry) => {
                if (
                  entry.isPrimary === false &&
                  entry.for.length === 1 &&
                  entry.for.includes(oldYear)
                ) {
                  return entry.id;
                }
              });
              filteredLinkedEntry = filteredLinkedEntry.filter(
                (entry) => entry
              );
              if (filteredLinkedEntry && filteredLinkedEntry.length) {
                newEntries = groupEntries.entries.filter(
                  (entry) => !filteredLinkedEntry.includes(entry.id)
                );
              }
              // end - delete link entry if entry is same as current year and for length 1
              // reset for & forDropdownData
              let newGroupEntries = newEntries.map((entry) => {
                if (entry.for.length > 1 && !entry.for.includes('all')) {
                  const filteredFor = entry.for.filter((v) => v !== oldYear);
                  entry.for = filteredFor;
                  let yearDropDownData = entry.yearDropDownData.map((year) => {
                    if (year.value === oldYear) {
                      year.selected = false;
                    }
                    return year;
                  });
                  entry.yearDropDownData = yearDropDownData;
                }
                return entry;
              });

              let finalData = {
                entries: newGroupEntries,
                groupEntryID: groupEntries.groupEntryID,
                groupType: groupEntries.groupType
              };
              firebase
                .updateGroupEntries(finalData, groupEntries.docId)
                .then(() => {})
                .catch((error) => console.log('error', error));
            }
          }
        } else {
          dispatch(addMessage('Something went wrong to get group entries', MESSAGE_TYPES.ERROR));
        }
      })
      .catch((error) => {
        console.log('error', error);
        dispatch(addMessage('Something went wrong to get group entries', MESSAGE_TYPES.ERROR));
      });
  };

  const submitNewSchoolForm = async (formData, mainEntries) => {
    setYear(formData[0].years);
    //New time format
    formData = await setFromToTimeFormat(formData, deleteSchoolDataEntry);
    //New time format end

    const actionDataAppend = [];
    let primary = formData.find((o) => o.isPrimary === true);

    // Create VirtualEntries
    formData = await virtualEntries({ formData, mainEntries, isRepeatChange, allCalenderData, repeatEntryRadioData});

    const groupEntryID = formData[0].groupEntryID
      ? formData[0].groupEntryID
      : null;
    if (
      groupEntryID &&
      formData[0].groupType === 'Free' &&
      mainEntries &&
      mainEntries.length > 0
    ) {
      deleteFreeSchoolFromGroup(groupEntryID, mainEntries);
    }

    const bulkID = primary.bulkID || generateFileHash(20);
    let yearValue = groupYear?.length
      ? groupYear
      : hasData?.[0]?.forYears || hasData?.[0]?.forDropDownData;
    const allYears = getYearsInNumber(yearValue);
    const updateFormData = formData.map((data) => {
      data.bulkID = bulkID;
      data.years = hasData?.[0]?.years;

      data.forYears = data?.repeatEntries?.length
        ? data?.repeatEntries[data.repeatEntries.length - 1]?.forYears
        : allYears;

      data.groups = data?.repeatEntries?.length
        ? data?.repeatEntries[data.repeatEntries.length - 1]?.groups
        : data.groups;
      data.groupMembers = data?.repeatEntries?.length
        ? data?.repeatEntries[data.repeatEntries.length - 1]?.groupMembers
        : data.groupMembers;
      data.schoolId = data.school || schoolData.id;
      data.buttonState = buttonState ||
                          hasData?.[0]?.buttonState ||
                          hasData?.[0]?.categoryInfo?.[0];

      if (data?.repeatEntries?.length) {
        data.repeatEntries = data?.repeatEntries?.map((entry) => {
          return {
            ...entry,
            groups: data?.repeatEntries[data.repeatEntries.length - 1]?.groups
          };
        });
        data.repeatEntries = data?.repeatEntries?.map((entry) => {
          return {
            ...entry,
            groupMembers:
              data?.repeatEntries[data.repeatEntries.length - 1]?.groupMembers
          };
        });
        data.repeatEntries = data?.repeatEntries?.map((entry) => {
          return {
            ...entry,
            forYears: allYears.length
              ? allYears
              : data?.repeatEntries[data.repeatEntries.length - 1]?.forYears
          };
        });
      }

      if (data.label === '') {
        data.label = 'none';
      }
      if (data.isPrimary === false) {
        data.isLinked = true;
      } else {
        data.isLinked = false;
      }
      if (data.actions.reminder.length) {
        data.actions.reminder.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Reminder',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            isLinked: false,
            primaryID: data.isPrimary ? data.id : data.primaryID,
            actions: {
              ...data.actions,
              notification:
                data.actions.reminderNotification &&
                data.actions.reminderNotification[index]
                  ? [data.actions.reminderNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.actions.deadline.length) {
        data.actions.deadline.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Deadline',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            isLinked: false,
            primaryID: data.isPrimary ? data.id : data.primaryID,
            actions: {
              ...data.actions,
              notification:
                data.actions.deadlineNotification &&
                data.actions.deadlineNotification[index]
                  ? [data.actions.deadlineNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.groupEntryID) {
        delete data.groupEntryID;
      }

      //delete data.school;
      return data;
    });

    const final = [...updateFormData, ...actionDataAppend];
    await _addSchoolEntry(final);
    dispatch(addMessage('Group Entry Created'));
  };

  const deleteAllEntries = async () => {
    const primary = hasData.find((o) => o.isPrimary === true);
    if (primary.isPrimary && primary.bulkID) {
      // Remove existing data entries
      dispatch(loaderStart());
      await deleteSchoolDataEntry(primary.docId, primary.bulkID);

      dispatch(addMessage('Calendar Entry Deleted'));

      setInitialEntry({
        entries: [
          {
            ...entryObject.entries[0],
            id: generateFileHash(20),
            agentName: currentUser.firstName,
            from: {
              date: moment().format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            },
            to: {
              date: moment().format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            }
          }
        ]
      });
      dispatch(setFamilyOrSchoolPreserveEntryData(null));
      setHasEntryData([]);
      setRightOpen(false);
      setTimeout(() => {
        getEntries(date);
      }, 300)
      dispatch(loaderStop());
    }
  };

  const _submitForm = async (formData) => {
    // After change repeat option create new entry logic start
    if (
      isRepeatChange &&
      hasData &&
      hasData.length > 0 &&
      repeatEntryRadioData === 'This and following events'
    ) {
      let newEntries = JSON.parse(JSON.stringify(formData));
      // Delete extra field start
      const deleteFields = [
        'followDates',
        'repeatEntries',
        'repeatDeleted',
        'isRepeatEntry',
        'parentRepeatEntryId',
        'completedDates',
        'isDeleted'
      ];
      deleteFields.map((field) => {
        if (newEntries[0][field]) delete newEntries[0][field];
      });
      // Delete extra field end
      newEntries = [
        {
          ...newEntries[0],
          id: generateFileHash(20),
          isPrimary: true,
          isLinked: false,
          primaryID: '',
          for: [newEntries[0].for]
        }
      ];
      submitCreateSchoolForm(newEntries);
    }

    // Update or create existing entry
    if (hasData && hasData.length) {
      submitNewSchoolForm(formData, hasData);
    } else {
      submitCreateSchoolForm(formData);
    }
  };

  const submitCreateSchoolForm = async (formData) => {
    //New time format
    formData = await setFromToTimeFormat(formData);
    //New time format end

    const actionDataAppend = [];
    const primary = formData.find((o) => o.isPrimary === true);
    const linked = formData.filter((o) => o.isPrimary === false);
    const groupEntryID = generateFileHash(20);

    //First create seperate entry for each year
    let primaryPerYear = [];
    let linkedPerYear = [];
    let updatedFormData = [];

    // bulk ID created for finding entry into the data entries collections

    const bulkID = primary.bulkID || generateFileHash(20);
    const primaryID = generateFileHash(20);
    const linkedIdArray = [];

    linked.forEach((entry, index) => {
      const linkedId = generateFileHash(20);
      linkedPerYear.push({
        ...entry,
        id: linkedId,
        for: 'group',
        // forYears: entry.for,
        primaryID: primaryID,
        bulkID: bulkID
      });
      linkedIdArray.push(linkedId);
    });
    primaryPerYear.push({
      ...primary,
      id: primaryID,
      for: 'group',
      bulkID: bulkID,
      linkedArray: linkedIdArray
    });

    let yearValue = groupYear?.length
      ? groupYear
      : hasData?.[0]?.forYears || hasData?.[0]?.forDropDownData;
    const allYears = getYearsInNumber(yearValue);

    updatedFormData = [...primaryPerYear, ...linkedPerYear];
    const updateFormData = updatedFormData.map((data) => {
      data.bulkID = bulkID;
      data.forYears = allYears || [];
      data.schoolId = data.school || schoolData.id;
      data.buttonState = buttonState ||
                        hasData?.[0]?.buttonState ||
                        hasData?.[0]?.categoryInfo?.[0];
      data.years = yearValue;
      if (data.label === '') {
        data.label = 'none';
      }
      if (data.isPrimary === false) {
        data.isLinked = true;
      } else {
        data.isLinked = false;
      }
      if (data.actions.reminder.length) {
        data.actions.reminder.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Reminder',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            isLinked: false,
            primaryID: data.isPrimary ? data.id : data.primaryID,
            actions: {
              ...data.actions,
              notification:
                data.actions.reminderNotification &&
                data.actions.reminderNotification[index]
                  ? [data.actions.reminderNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.actions.deadline.length) {
        data.actions.deadline.forEach(function (e, index) {
          actionDataAppend.push({
            ...data,
            id: generateFileHash(20),
            actionParent: {
              parentID: data.isPrimary ? data.id : data.primaryID,
              linkedId: data.isPrimary ? 0 : data.id,
              type: 'Deadline',
              parentDate: { from: { ...data.from }, to: { ...data.to } }
            },
            from: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment().format('h:mma')
            },
            to: {
              date: moment(e).format('MMM DD,YYYY'),
              time: moment(e).format('h:mma')
            },
            allDay: true,
            isPrimary: false,
            isLinked: false,
            primaryID: data.isPrimary ? data.id : data.primaryID,
            actions: {
              ...data.actions,
              notification:
                data.actions.deadlineNotification &&
                data.actions.deadlineNotification[index]
                  ? [data.actions.deadlineNotification[index]]
                  : []
            }
          });
        });
      }
      if (data.groupEntryID) {
        delete data.groupEntryID;
      }

      //delete data.school;
      return data;
    });

    const final = [...updateFormData, ...actionDataAppend];
    await _addSchoolEntry(final);
    dispatch(addMessage('Group Entry Created'));
  };

  const _addSchoolEntry = async (finalData) => {
    dispatch(loaderStart());
    manageSchoolEntries(finalData).then(
      async () => {
        let editedFormDate = null;
        await finalData.forEach(entryItem => {
          const updatedEntry = entryItem?.repeatEntries?.length
            ? entryItem?.repeatEntries[entryItem?.repeatEntries?.length - 1]
            : null;
          const primary = entryItem.isPrimary === true ? entryItem : false;
          if (primary) {
            editedFormDate = primary?.from?.date || null;
            if (
              (entryItem.label === 'Alert' &&
                hasData?.[0]?.label !== 'Alert') ||
              (updatedEntry?.label === 'Alert' &&
                hasData?.[0]?.label !== 'Alert')
            ) {
              const requestOptions = {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  schoolId: entryItem.schoolId,
                  entryId: entryItem.id,
                  repeatEntryId: entryItem?.repeatEntries?.length
                    ? updatedEntry.id
                    : null,
                    isFree: true
                })
              };
              fetch(
                `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URL}/sendInstantPN`,
                requestOptions
              )
                .then((response) => {
                  const result = response.json();
                  if (!response.ok) {
                    throw new Error(result?.message);
                  }
                  return result
                })
                .then((data) => console.log('.'));
            }
          }
        });

        dispatch(setPreserveEntryData(null));
        dispatch(loaderStop());
        setRightOpen(false);
        onClose();
        if (editedFormDate) {
          getEntries(
            moment(
              editedFormDate,
              'MMM DD,YYYY'
            )
          );
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Entry was not created. Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialEntry}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(loaderStart())
          let primaryDesc = '';
          const recurringEntriesErrorsObj = {}
          const updatedValues = values.entries.map((entry, index) => {
            if (entry?.isPrimary === true && entry?.actions?.repeat?.length) {
              const isRepeatEntry = entry?.actions?.repeat?.[0]?.type !== 'Does not repeat'
              // [Custom, Daily, Does not repeat]
              const isSameDate = entry.from.date === entry.to.date

              if (isRepeatEntry) {
                if (!isSameDate) {
                  recurringEntriesErrorsObj.message = 'Cannot save the recurring entry. From and To DAYS must be the same'
                }
              }
            }
            entry = _.omit(
              entry,
              _.filter(_.keys(entry), function (key) {
                return _.isUndefined(entry[key]);
              })
            );
            if (entry.primaryEventTo) {
              delete entry.primaryEventTo;
            }
            if (entry.primaryEventFrom) {
              delete entry.primaryEventFrom;
            }
            if (entry.customType) {
              delete entry.customType;
            }
            if (entry.forDropDownData) {
              // delete entry.forDropDownData;
            }
            if (entry.yearDropDownData) {
              // delete entry.yearDropDownData;
            }

            //selection logic start
            if (entry.actions.notification.length) {
              const formateValue = 'MMM DD,YYYY';
              entry.actions.notification.forEach((item) => {
                const fromDate = moment(entry.from.date, formateValue);
                const notificationDate = moment(
                  item.date ? item.date : entry.from.date,
                  formateValue
                );
                let dayValue = '';
                if (moment(notificationDate).isSame(fromDate)) {
                  dayValue = '';
                } else {
                  dayValue = notificationDate.diff(fromDate, 'days');
                }
                item.days = dayValue;
              });
            }

            //selection logic end

            // remove deadline and reminder logic start
            if (entry.actions.isComplete) {
              // entry.actions.deadline = []
              // entry.actions.reminder = []
              if (entry.actions.deadlineNotification)
                delete entry.actions.deadlineNotification;
              if (entry.actions.reminderNotification)
                delete entry.actions.reminderNotification;
            }
            // remove deadline and reminder logic end
            // linked entry description logic start
            if (entry.description && index === 0) {
              primaryDesc =
                toGetHTMLContent(entry?.description).replaceAll('</a>&nbsp;<', '</a><')
              ;
            }
            // linked entry description logic end
            let updatedYears = hasData?.[0]?.years || entry.years;
            let allYears = [];
            let yearValue = groupYear?.length
              ? groupYear
              : hasData?.[0]?.forYears.length
              ? hasData?.[0]?.forYears
              : entry?.forYears;
            yearValue &&
              yearValue.map((item) => {
                if (item === 'Kindy' || item == '0') {
                  allYears.push('0');
                } else if (item === 'Pre K' || item == '-1') {
                  allYears.push('-1');
                } else {
                  allYears.push(item.slice(5, item.length) || item);
                }
              });
            console.log("=> Group Submit", groupMembers);
            return {
              ...entry,
              groupMembers: groupMembers,
              for: 'group',
              // forDropDownData: groupYear.length ? groupYear :  hasData?.[0]?.forDropDownData.length ?  hasData?.[0]?.forDropDownData : entry.forDropDownData.length ?  entry.forDropDownData : [],
              forYears: allYears || [],
              years: year || updatedYears || [],
              agentName: currentUser.firstName,
              expand: index === 0 ? true : false,
              from: {
                date: entry.from.date,
                time:
                  entry.newFromTimeFormate.hh +
                  ':' +
                  entry.newFromTimeFormate.mm +
                  entry.newFromTimeFormate.ap +
                  'm'
              },
              to: {
                date: entry.to.date,
                time:
                  entry.newToTimeFormate.hh +
                  ':' +
                  entry.newToTimeFormate.mm +
                  entry.newToTimeFormate.ap +
                  'm'
              },
              primary_description:
                index !== 0
                  ? {
                      description: primaryDesc ? primaryDesc : '',
                      from: values.entries[0].from
                    }
                  : {},
              primaryID: index !== 0 ? values.entries[0].id : '',
              createdAt: moment().format(),
              description: entry.description
                ? toGetHTMLContent(entry.description).replaceAll('</a>&nbsp;<', '</a><')
                : ''
            };
          });

          if (!recurringEntriesErrorsObj.message) {
            let isEqual = true;
            if (
              hasData &&
              hasData.length > 0 &&
              hasData[0].actions.repeat.length > 0 &&
              updatedValues[0].actions.repeat.length > 0
            ) {
              setYear(hasData?.[0]?.years);
              // setGroupYear(hasData?.[0]?.forDropDownData)
              isEqual = deepEqual(
                hasData[0].actions.repeat[0],
                updatedValues[0].actions.repeat[0]
              );
            }
            if (
              updatedValues[0].actions.repeat.length > 0 &&
              updatedValues[0].actions.repeat[0].type === 'Does not repeat'
            ) {
              setYear(hasData?.[0]?.years);
              // setGroupYear(hasData?.[0]?.forDropDownData)
              isEqual = false;
            }
            if (isRepeatChange && !isRepeatEntryModal) {
              setYear(hasData?.[0]?.years);
              // setGroupYear(hasData?.[0]?.forDropDownData)
              setRepeatEntryRadioData('This and following events');
            }
            if (isEqual || isRepeatChange) {
              if (
                hasData &&
                hasData.length > 0 &&
                hasData[0].actions.repeat.length === 0 &&
                !hasData[0].isRepeatEntry
              ) {
                _submitForm(updatedValues);
              } else if (
                hasData &&
                hasData.length &&
                updatedValues[0].actions.repeat.length > 0 &&
                !isRepeatEntryModal
              ) {
                setRepeatEntryModal(true);
              } else if (
                hasData &&
                hasData.length &&
                updatedValues[0].isRepeatEntry &&
                !isRepeatEntryModal
              ) {
                setRepeatEntryModal(true);
              } else {
                if (isRepeatEntryModal) {
                  setRepeatEntryModal(false);
                }

                _submitForm(updatedValues);
              }
            } else {
              _submitForm(updatedValues);
            }
          } else {
            setRecurringEntriesErrors(recurringEntriesErrorsObj)
            dispatch(addMessage(recurringEntriesErrorsObj.message, MESSAGE_TYPES.ERROR))
            dispatch(loaderStop())
          }

        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue
        }) => {
          const showAllEventOption =
            values.entries &&
            values.entries.length > 0 &&
            values.entries[0].from.date === values.entries[0].originalEntryDate
              ? true
              : false;
          let hideForRepeat = false;
          let isRepeatDelete = false;
          if (
            values.entries &&
            values.entries[0].isDynamic &&
            values.entries[0].isRepeatEntry
          ) {
            hideForRepeat = true;
            isRepeatDelete = true;
          }
          setUpdatedEntry(values);

          return (
            <>
              {
                <Backdrop className={classes.backdrop} open={isLoading}>
                  <CircularProgress color="primary" />
                </Backdrop>
              }
              <form noValidate onSubmit={handleSubmit}>
                <div className={classes.headerContainer}>
                  <div className={classes.headerTitleContainer}>
                    <FamiliesIcon
                      color={colors.primaryDarkGrey}
                      className={classes.familyIconStyle}
                    />
                    <Typography variant="h6" className={classes.titleText}>
                      Group Entry
                    </Typography>
                  </div>
                  <div className={classes.headerButtonContainer}>
                    <Button
                      className={classes.deleteButton}
                      onClick={() => {
                        if (isRepeatDelete) {
                          setRepeatEntryModal('delete');
                        } else {
                          setOpenDeleteModal(true);
                        }
                      }}
                    >
                      Delete
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      className={classes.publishButton}
                    >
                      Publish
                    </Button>
                  </div>
                </div>
                <div style={{ position: 'relative' }}>
                  {values.entries.length &&
                  values.entries[0] &&
                  (values.entries[0].actions.isCancelled ||
                    values.entries[0].actions.isPostponed ||
                    values.entries[0].actions.isChanged) ? (
                    <Chip
                      size="small"
                      label={
                        <span style={{ fontSize: 12 }}>
                          {values.entries[0].actions.isPostponed
                            ? 'POSTPONED'
                            : values.entries[0].actions.isCancelled
                            ? 'CANCELLED'
                            : 'UPDATED'}
                        </span>
                      }
                      classes={{
                        labelSmall: classes.chipLabel,
                        root: classes.chipRoot
                      }}
                      style={{
                        marginRight: 10,
                        backgroundColor:
                          values.entries[0].actions.isCancelled ||
                          values.entries[0].actions.isPostponed
                            ? colors.tertiaryRed
                            : colors.primaryDarkGrey
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <Divider variant="fullWidth" />
                <div className={classes.drawerLeftContent}>
                  <div className={classes.leftContentMargin}>
                    {values.entries.map((entry, index) => (
                      <Accordion
                        style={{ margin: 10, boxShadow: 'none' }}
                        key={`expand${index}`}
                        expanded={
                          values.entries.length === 1 ? true : entry.expand
                        }
                        onChange={() =>
                          values.entries.length > 1 &&
                          handleExpandChange(index, values)
                        }
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className={classes.whiteText} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            backgroundColor:
                              values.entries.length !== 1
                                ? entry.expand && index !== 0
                                  ? colors.primaryDarkGrey7
                                  : colors.primaryDarkGrey
                                : null,
                            height:
                              values.entries.length !== 1
                                ? entry.expand
                                  ? 56
                                  : 60
                                : null
                          }}
                        >
                          {index === 0 ? (
                            <Typography
                              className={clsx(
                                classes.titleAccordionText,
                                values.entries.length > 1
                                  ? classes.whiteText
                                  : classes.darkPrimaryText
                              )}
                            >
                              Primary Entry
                            </Typography>
                          ) : (
                            <>
                              <CornerDownRight
                                className={classes.whiteText}
                                style={{ marginTop: 5 }}
                              />
                              <Typography
                                className={clsx(
                                  classes.titleAccordionText,
                                  classes.whiteText
                                )}
                                style={{
                                  fontSize: 18,
                                  lineHeight: 2.2,
                                  fontWeight: 'bold'
                                }}
                              >
                                Linked Entry {index}
                              </Typography>
                            </>
                          )}
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            backgroundColor:
                              values.entries.length > 1
                                ? colors.secondaryGreyLight
                                : ''
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <div
                              style={{ flexDirection: 'row', display: 'flex' }}
                            >
                              <FormControl
                                variant="outlined"
                                className={classes.formLabelControl}
                                // style={{ height: 40 }}
                              >
                                <InputLabel id="select-outlined-label">
                                  Label
                                </InputLabel>
                                <Select
                                  labelId="select-outlined-label"
                                  name={`entries[${index}].label`}
                                  onBlur={handleBlur}
                                  // onChange={handleChange}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `entries[${index}].label`,
                                      e.target.value
                                    );
                                  }}
                                  value={
                                    (values.entries[index] &&
                                      values.entries[index].label) ||
                                    'none'
                                  }
                                  label="Label"
                                >
                                  {labels.map((label, index) => (
                                    <MenuItem
                                      key={`${label.value}-${index}`}
                                      value={label.value}
                                    >
                                      {label.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      values.entries[index].isLowPriority
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `entries[${index}].isLowPriority`,
                                        e.target.checked
                                      );
                                    }}
                                    classes={{
                                      switchBase: classes.switchBase,
                                      thumb: classes.thumb,
                                      track: classes.track,
                                      checked: classes.checked
                                    }}
                                  />
                                }
                                label="Low Priority"
                                style={{
                                  width: '56%',
                                  marginTop: 13,
                                  display: 'flex',
                                  marginLeft: 10,
                                  marginTop: 5
                                }}
                              />
                              {taskLabels.includes(
                                values.entries[index].label
                              ) && (
                                <div
                                  style={{
                                    width: '56%',
                                    marginTop: 13,
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }}
                                >
                                  <Checkbox
                                    color="primary"
                                    className={classes.iconButton}
                                    checked={
                                      (values.entries[index] &&
                                        values.entries[index].actions
                                          .isComplete) ||
                                      false
                                    }
                                    name={`entries[${index}].actions.isComplete`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </div>
                              )}
                            </div>
                            <FormControl component="fieldset">
                              <TextField
                                label="Add Title"
                                variant="outlined"
                                className={classes.inputControlBox}
                                name={`entries[${index}].title`}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={
                                  (values.entries[index] &&
                                    values.entries[index].title) ||
                                  ''
                                }
                                error={Boolean(
                                  touched.entries &&
                                    errors.entries &&
                                    touched.entries[index] &&
                                    touched.entries[index].title &&
                                    errors.entries[index] &&
                                    errors.entries[index].title
                                )}
                              />
                            </FormControl>
                            {/* {index === 0 && ( */}
                            <FormControl component="fieldset">
                              <TextField
                                label="Add location"
                                placeholder="Add Location"
                                variant="outlined"
                                className={classes.inputControlBox}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        src={LocationIcon}
                                        style={{ opacity: 0.7 }}
                                      />
                                    </InputAdornment>
                                  )
                                }}
                                name={`entries[${index}].location`}
                                onBlur={(e) => {
                                  setFieldValue(
                                    `entries[${index}].location`,
                                    e.target.value.trim().split(/ +/).join(' ')
                                  );
                                  handleBlur(e);
                                }}
                                onChange={handleChange}
                                value={
                                  (values.entries[index] &&
                                    values.entries[index].location) ||
                                  ''
                                }
                              />
                            </FormControl>
                            {/* )} */}
                            <FormControl component="fieldset">
                            <EditorComponent
                                    classes={classes}
                                    preserveData={values.entries[index]?.description}
                                    wrapperClassName={clsx(
                                      values.entries[index] &&
                                        values.entries[index].checkDescription
                                        ? classes.wrapperClassName
                                        : classes.wrapperClassNameWithBottomBorder
                                    )}
                                    index={index}
                                    onChange={(e, index) => onEditorChange(e, index, values)}
                                  />
                            </FormControl>
                            {values.entries[index] &&
                            values.entries[index].checkDescription ? (
                              <>
                                <div className={classes.descriptionContainer}>
                                  <div
                                    className={classes.descriptionMainContainer}
                                  >
                                    <img
                                      src={EditPencil}
                                      alt=""
                                      className={
                                        classes.descriptionImageContainer
                                      }
                                    />
                                    <p
                                      className={
                                        classes.descriptionTextContainer
                                      }
                                    >
                                      Created on{' '}
                                      {values.entries[index].createdAt
                                        ? moment(
                                            values.entries[index].createdAt
                                          ).format('DD MMM YYYY')
                                        : moment().format('DD MMM YYYY')}{' '}
                                      by {values.entries[index].agentName}
                                    </p>
                                  </div>
                                  <hr
                                    style={{
                                      margin: 0,
                                      padding: 0,
                                      borderTop: `dotted 3px ${colors.primaryLightGrey}`,
                                      width: 570
                                    }}
                                  />
                                  <div
                                    className={classes.descriptionMainContainer}
                                  >
                                    <img
                                      src={Calendar}
                                      alt=""
                                      className={
                                        classes.descriptionImageContainer
                                      }
                                    />
                                    <p
                                      className={
                                        classes.descriptionTextContainer
                                      }
                                    >
                                      Relates to:{' '}
                                      {values.entries[0].from.date
                                        ? moment(
                                            values.entries[0].from.date
                                          ).format('DD MMM YYYY')
                                        : moment().format('DD MMM YYYY')}{' '}
                                      entry
                                    </p>
                                  </div>
                                </div>
                                <Editor
                                  readOnly={true}
                                  editorState={defaultNote}
                                  toolbarClassName={classes.toolbarClassName}
                                  editorClassName={classes.editorClassName}
                                  wrapperClassName={
                                    classes.wrapperSecondEditorClassName
                                  }
                                  toolbar={{
                                    options: [],
                                    inline: {
                                      options: []
                                    }
                                  }}
                                  // editorState={}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                            {values.entries[index] &&
                            values.entries[index].isPrimary ? (
                              <>
                                <div
                                  className={classes.descriptionContainer}
                                  style={{
                                    borderBottomLeftRadius: 5,
                                    borderBottomRightRadius: 5,
                                    borderBottom: `1px solid ${colors.primaryLightGrey}`
                                  }}
                                >
                                  <div
                                    className={classes.descriptionMainContainer}
                                  >
                                    <img
                                      src={EditPencil}
                                      alt=""
                                      className={
                                        classes.descriptionImageContainer
                                      }
                                    />
                                    <p
                                      className={
                                        classes.descriptionTextContainer
                                      }
                                    >
                                      Created on{' '}
                                      {values.entries[index].createdAt
                                        ? moment(
                                            values.entries[index].createdAt
                                          ).format('DD MMM YYYY')
                                        : moment().format('DD MMM YYYY')}{' '}
                                      by {values.entries[index].agentName}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            {index !== 0 ? (
                              <div
                                style={{
                                  marginTop: 24,
                                  marginLeft: 10,
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        (values.entries[index] &&
                                          values.entries[index]
                                            .checkDescription) ||
                                        false
                                      }
                                      onChange={(e) =>
                                        handleChangeCheck(
                                          'checkDescription',
                                          index,
                                          values
                                        )
                                      }
                                      name={`entries[${index}].checkDescription`}
                                    />
                                  }
                                  label="Include main description"
                                  className={classes.includeText}
                                />
                                <DeleteOutlineOutlinedIcon
                                  onClick={() =>
                                    setOpenDeleteLinkedModal({
                                      open: true,
                                      id: index
                                    })
                                  }
                                  className={classes.iconTrashStyle}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                  <div>
                    <Button
                      className={classes.linkedButton}
                      startIcon={<AddIcon color={colors.primaryDarkGrey} />}
                      variant="contained"
                      onClick={() => addLinkedEntry(values)}
                      color={colors.primaryDarkGrey}
                    >
                      Add Linked Entry
                    </Button>
                  </div>
                </div>
                <div className={classes.drawerRightContent}>
                  <Divider
                    variant="fullWidth"
                    orientation="vertical"
                    style={{ float: 'right' }}
                  />
                  {values.entries.map((entry, index) => (
                    <Fragment key={`right-content-${index}`}>
                      {values.entries.length === 1 || entry.expand ? (
                        <div
                          style={{
                            padding: '0 10px',
                            backgroundColor: colors.tertiary
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center'
                            }}
                          >
                            {catgeoryButton === 'staff' ||
                            (hasData?.[0]?.forDropDownData?.length === 0 &&
                              hasData?.[0]?.forYears?.length === 0) ? (
                              <></>
                            ) : (
                              <FormControl
                                variant="outlined"
                                className={classes.formLabelControl}
                              >
                                <InputLabel id="demo-simple-select-outlined-label-years">
                                  For
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label-years"
                                  id="demo-simple-select-outlined-years"
                                  multiple
                                  name={`entries.[${index}].years`}
                                  onBlur={handleBlur}
                                  // renderValue={(selected) => selected.join(', ')}
                                  renderValue={(selected) => {
                                    const familyName = freeCalendarsYear.map(
                                      (data) => {
                                        if (groupYear.length) {
                                          if (groupYear.includes(data.value)) {
                                            return data.value;
                                          }
                                        } else if (hasData?.[0]?.forYears) {
                                          if (
                                            hasData?.[0]?.forYears?.includes(
                                              data.id
                                            ) ||
                                            hasData?.[0]?.forYears?.includes(
                                              'all'
                                            ) ||
                                            groupYear?.includes(data.value)
                                          ) {
                                            return data.value;
                                          } else {
                                            return null;
                                          }
                                        } else {
                                          if (selected.includes(data.value)) {
                                            return data.value;
                                          } else {
                                            return null;
                                          }
                                        }
                                      }
                                    );
                                    const familyNameFilter = familyName.filter(
                                      (data) => data && data
                                    );
                                    return familyNameFilter.join(', ');
                                  }}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleChangeFreeYear(
                                      e,
                                      index,
                                      values.entries,
                                      setFieldValue
                                    );
                                    setFieldValue(
                                      `entries.[${index}].groups`,
                                      []
                                    );
                                  }}
                                  value={
                                    values?.entries?.[index]?.years?.length
                                      ? (freeCalendarsYear &&
                                          freeCalendarsYear.length &&
                                          values.entries[index] &&
                                          values.entries[0].years) ||
                                        []
                                      : (freeCalendarsYear &&
                                          freeCalendarsYear.length &&
                                          ((values.entries[index] &&
                                            values.entries[0].forYears) ||
                                            groupYear)) ||
                                        []
                                  }
                                  label="Years"
                                  error={Boolean(
                                    touched.entries &&
                                      errors.entries &&
                                      touched.entries[index] &&
                                      touched.entries[index].for &&
                                      errors.entries[index] &&
                                      errors.entries[index].for
                                  )}
                                >
                                  {/* <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem> */}
                                  {freeCalendarsYear &&
                                    freeCalendarsYear.length &&
                                    freeCalendarsYear
                                      .sort((a, b) => {
                                        return a?.id - b?.id
                                      })
                                      .map((yearData, forIndex) => (
                                        <MenuItem
                                          key={`years-${forIndex}`}
                                          value={yearData.value}
                                          className={classes.menuItemClass}
                                          disabled={
                                            hasData?.[0]?.forYears?.length
                                              ? true
                                              : false || index !== 0
                                          }
                                        >
                                          <div>
                                            <Checkbox
                                              icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                              }
                                              checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                              }
                                              checked={
                                                groupYear.length
                                                  ? groupYear.includes(
                                                      yearData.value
                                                    )
                                                  : hasData?.length
                                                  ? hasData?.[0]?.forYears?.includes(
                                                      yearData.id
                                                    ) ||
                                                    hasData?.[0]?.forYears?.includes(
                                                      'all'
                                                    )
                                                  : groupYear.includes(
                                                      yearData.value
                                                    )
                                                  ? true
                                                  : yearData.selected
                                              }
                                              name={yearData.value.toString()}
                                              className={classes.controlCheck}
                                              classes={{
                                                root: classes.checkbox,
                                                checked: classes.checked
                                              }}
                                            />
                                            {yearData.value}
                                          </div>
                                        </MenuItem>
                                      ))}
                                </Select>
                              </FormControl>
                            )}

                            <FormControl
                              variant="outlined"
                              className={classes.formLabelControl}
                              // disabled={!groups.length}
                            >
                              <InputLabel id="demo-simple-select-outlined-label-Group">
                                Custom Group
                              </InputLabel>
                              <Select
                                required
                                labelId="demo-simple-select-outlined-label-Group"
                                id="demo-simple-select-outlined-Group"
                                multiple
                                name={`entries.[${index}].groups`}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleCategoryChange(
                                    e,
                                    index,
                                    values.entries,
                                    setFieldValue
                                  );
                                  setFieldValue(
                                    `entries.[${index}].groups`,
                                    e.target.value
                                  );
                                }}
                                value={
                                  (values.entries[index] &&
                                    values.entries[0].groups) ||
                                  []
                                }
                                renderValue={(selected) => {
                                  const groupName = groups.map((data) => {
                                    if (selected.includes(data.value)) {
                                      return data.key;
                                    } else {
                                      return null;
                                    }
                                  });
                                  const groupNameFilter = groupName.filter(
                                    (data) => data && data
                                  );
                                  return groupNameFilter.join(', ');
                                }}
                                label="Custom Group"
                                error={Boolean(
                                  touched.entries &&
                                    errors.entries &&
                                    touched.entries[index] &&
                                    touched.entries[index].groups &&
                                    errors.entries[index] &&
                                    errors.entries[index].groups
                                )}
                              >
                                {/* <MenuItem value="">
                                              <em>None</em>
                                            </MenuItem> */}
                                {groups &&
                                  groups.length &&
                                  groups
                                    .sort((a, b) => {
                                      return a?.key?.toLowerCase() >
                                        b?.key?.toLowerCase()
                                        ? 1
                                        : -1;
                                    })
                                    .filter((item) => {
                                      if (item.years) {
                                        const filterResult = item.years.map(
                                          (data) => {
                                            return (
                                              year?.includes(data) ||
                                              hasData[0]?.forDropDownData?.includes(
                                                data
                                              )
                                            );
                                          }
                                        );
                                        if (year?.length === 0) {
                                          return item.key;
                                        }
                                        if (filterResult.includes(true)) {
                                          return item.key;
                                        } else if (hasData[0]?.groups) {
                                          return item.key;
                                        } else if (catgeoryButton === 'staff') {
                                          return item.key;
                                        }
                                      }
                                    })
                                    .map((group, forIndex) => {
                                      return (
                                        <MenuItem
                                          key={`group-${forIndex}`}
                                          value={group.value}
                                          className={classes.menuItemClass}
                                          disabled={index !== 0}
                                        >
                                          <div>
                                            <Checkbox
                                              icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                              }
                                              checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                              }
                                              checked={group.selected}
                                              name={group.value}
                                              className={classes.controlCheck}
                                              classes={{
                                                root: classes.checkbox,
                                                checked: classes.checked
                                              }}
                                            />
                                            {group.key}
                                          </div>
                                        </MenuItem>
                                      );
                                    })}
                              </Select>
                            </FormControl>

                            <Divider
                              variant="fullWidth"
                              style={{ marginTop: '20px' }}
                            />

                            <div style={{ marginTop: 20 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                    onChange={(e) =>
                                      handleChangeCheck('allDay', index, values)
                                    }
                                    name={`entries[${index}].allDay`}
                                  />
                                }
                                label="All day"
                              />
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 10
                              }}
                            >
                              <Typography>From</Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginTop: 5
                                }}
                              >
                                <DatePicker
                                  autoOk
                                  okLabel=""
                                  cancelLabel=""
                                  name={`entries[${index}].from.date`}
                                  onBlur={handleBlur}
                                  onChange={(date) =>
                                    handleDateChange(
                                      date,
                                      index,
                                      values,
                                      'from',
                                      'date'
                                    )
                                  }
                                  value={
                                    (values.entries[index] &&
                                      moment(
                                        values.entries[index].from.date
                                      )) ||
                                    ''
                                  }
                                  style={{ width: '40%' }}
                                  format="MMM DD,YYYY"

                                  error={values.entries?.[index]?.isPrimary && recurringEntriesErrors.message ? true : false}
                                  className={
                                    values.entries?.[index]?.isPrimary && recurringEntriesErrors.message ? classes.formDateControlFromBorderedError : classes.formDateControlFromBordered
                                  }
                                />

                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 1, max: 12 }
                                    }}
                                    variant="outlined"
                                    placeholder="hh"
                                    //style={{height: '23px', marginTop: 0}}
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newFromTimeFormate.hh`}
                                    onBlur={(e) => {
                                      const updatedValues = values.entries.map(
                                        (item, ix) => {
                                          if (index === ix) {
                                            if (!e.target.value) {
                                              item.newFromTimeFormate.hh = '00';
                                            } else if (
                                              parseInt(e.target.value) < 10 &&
                                              e.target.value.length < 2
                                            ) {
                                              item.newFromTimeFormate.hh = `0${e.target.value}`;
                                            } else {
                                              item.newFromTimeFormate.hh = `${e.target.value}`;
                                            }
                                          }
                                          return item;
                                        }
                                      );
                                      setNotificationTime(
                                        convertNotificationTime,
                                        { entries: updatedValues },
                                        index
                                      );
                                    }}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value.length <= 2 &&
                                          parseInt(e.target.value) <= 12 &&
                                          parseInt(e.target.value) > 0 &&
                                          Number.isInteger(
                                            parseInt(e.target.value)
                                          )) ||
                                        e.target.value === ''
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          `entries[${index}].newToTimeFormate.hh`,
                                          parseInt(e.target.value) === 12
                                            ? '1'
                                            : 1 + parseInt(e.target.value)
                                        );
                                        setConvertNotificationTime({
                                          ...convertNotificationTime,
                                          hh: parseInt(e.target.value)
                                        });
                                      }
                                      setConvertNotificationTime({
                                        ...convertNotificationTime,
                                        hh: parseInt(e.target.value)
                                      });
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newFromTimeFormate
                                          .hh) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newFromTimeFormate &&
                                        touched.entries[index]
                                          .newFromTimeFormate.hh &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newFromTimeFormate &&
                                        errors.entries[index].newFromTimeFormate
                                          .hh
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 0, max: 59 }
                                    }}
                                    variant="outlined"
                                    placeholder="mm"
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newFromTimeFormate.mm`}
                                    onBlur={(e) => {
                                      const updatedValues = values.entries.map(
                                        (item, ix) => {
                                          if (index === ix) {
                                            if (!e.target.value) {
                                              item.newFromTimeFormate.mm = '00';
                                            } else if (
                                              parseInt(e.target.value) < 10 &&
                                              e.target.value.length < 2
                                            ) {
                                              item.newFromTimeFormate.mm = `0${e.target.value}`;
                                            } else {
                                              item.newFromTimeFormate.mm = `${e.target.value}`;
                                            }
                                          }
                                          return item;
                                        }
                                      );
                                      setNotificationTime(
                                        convertNotificationTime,
                                        { entries: updatedValues },
                                        index
                                      );
                                    }}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value.length <= 2 &&
                                          parseInt(e.target.value) <= 59 &&
                                          parseInt(e.target.value) >= 0 &&
                                          Number.isInteger(
                                            parseInt(e.target.value)
                                          )) ||
                                        e.target.value === ''
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          `entries[${index}].newFromTimeFormate.mm`,
                                          e.target.value
                                        );
                                      }
                                      setConvertNotificationTime({
                                        ...convertNotificationTime,
                                        mm: parseInt(e.target.value)
                                      });
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newFromTimeFormate
                                          .mm) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newFromTimeFormate &&
                                        touched.entries[index]
                                          .newFromTimeFormate.mm &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newFromTimeFormate &&
                                        errors.entries[index].newFromTimeFormate
                                          .mm
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    variant="outlined"
                                    placeholder="a/p"
                                    //style={{height: '23px', marginTop: 0}}
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newFromTimeFormate.ap`}
                                    onBlur={() => {
                                      setNotificationTime(
                                        convertNotificationTime,
                                        values,
                                        index
                                      );
                                    }}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === '' ||
                                        e.target.value.toLowerCase() === 'a' ||
                                        e.target.value.toLowerCase() === 'p'
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          `entries[${index}].newToTimeFormate.ap`,
                                          e.target.value
                                        );
                                      }
                                      setConvertNotificationTime({
                                        ...convertNotificationTime,
                                        ap: e.target.value
                                      });
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newFromTimeFormate
                                          .ap) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newFromTimeFormate &&
                                        touched.entries[index]
                                          .newFromTimeFormate.ap &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newFromTimeFormate &&
                                        errors.entries[index].newFromTimeFormate
                                          .ap
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>

                                {/* <ThemeProvider theme={muiTimePickerTheme}>
                                  <TimePicker
                                    autoOk
                                    name={`entries[${index}].from.time`}
                                    onBlur={handleBlur}
                                    onChange={(e) =>
                                      handleDateChange(
                                        e,
                                        index,
                                        values,
                                        'from',
                                        'time'
                                      )
                                    }
                                    value={
                                      (values.entries[index] &&
                                        moment(
                                          values.entries[index].from.time,
                                          'h:mma'
                                        )) ||
                                      ''
                                    }
                                    format="h:mma"
                                    style={{ width: 80 }}
                                    className={
                                      classes.formDateControlFromBordered
                                    }
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </ThemeProvider> */}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 10
                              }}
                            >
                              <Typography>To</Typography>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginTop: 5
                                }}
                              >
                                <DatePicker
                                  autoOk
                                  okLabel=""
                                  cancelLabel=""
                                  name={`entries[${index}].to.date`}
                                  onBlur={handleBlur}
                                  minDate={
                                    (values.entries[index] &&
                                      moment(
                                        values.entries[index].from.date
                                      )) ||
                                    ''
                                  }
                                  onChange={(date) =>
                                    handleDateChange(
                                      date,
                                      index,
                                      values,
                                      'to',
                                      'date'
                                    )
                                  }
                                  value={
                                    (values.entries[index] &&
                                      moment(values.entries[index].to.date)) ||
                                    ''
                                  }
                                  style={{ width: '40%' }}
                                  format="MMM DD,YYYY"
                                  error={values.entries?.[index]?.isPrimary && recurringEntriesErrors.message ? true : false}
                                  className={
                                    values.entries?.[index]?.isPrimary && recurringEntriesErrors.message ? classes.formDateControlFromBorderedError : classes.formDateControlFromBordered
                                  }
                                />

                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 1, max: 12 }
                                    }}
                                    variant="outlined"
                                    placeholder="hh"
                                    //style={{height: '23px', marginTop: 0}}
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newToTimeFormate.hh`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value.length <= 2 &&
                                          parseInt(e.target.value) <= 12 &&
                                          parseInt(e.target.value) > 0 &&
                                          Number.isInteger(
                                            parseInt(e.target.value)
                                          )) ||
                                        e.target.value === ''
                                      )
                                        handleChange(e);
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newToTimeFormate
                                          .hh) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newToTimeFormate &&
                                        touched.entries[index].newToTimeFormate
                                          .hh &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newToTimeFormate &&
                                        errors.entries[index].newToTimeFormate
                                          .hh
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>

                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    type="number"
                                    InputProps={{
                                      inputProps: { min: 0, max: 59 }
                                    }}
                                    variant="outlined"
                                    placeholder="mm"
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newToTimeFormate.mm`}
                                    onBlur={(e) => {
                                      if (!e.target.value) {
                                        setFieldValue(
                                          `entries[${index}].newToTimeFormate.mm`,
                                          '00'
                                        );
                                      } else if (
                                        parseInt(e.target.value) < 10 &&
                                        e.target.value.length < 2
                                      ) {
                                        setFieldValue(
                                          `entries[${index}].newToTimeFormate.mm`,
                                          `0${e.target.value}`
                                        );
                                      } else {
                                        setFieldValue(
                                          `entries[${index}].newToTimeFormate.mm`,
                                          e.target.value
                                        );
                                      }
                                    }}
                                    onChange={(e) => {
                                      if (
                                        (e.target.value.length <= 2 &&
                                          parseInt(e.target.value) <= 59 &&
                                          parseInt(e.target.value) >= 0 &&
                                          Number.isInteger(
                                            parseInt(e.target.value)
                                          )) ||
                                        e.target.value === ''
                                      ) {
                                        handleChange(e);
                                        setFieldValue(
                                          `entries[${index}].newToTimeFormate.mm`,
                                          e.target.value
                                        );
                                      }
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newToTimeFormate
                                          .mm) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newToTimeFormate &&
                                        touched.entries[index].newToTimeFormate
                                          .mm &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newToTimeFormate &&
                                        errors.entries[index].newToTimeFormate
                                          .mm
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>
                                <FormControl
                                  style={{ marginTop: 0 }}
                                  className={classes.formLabelControlTime}
                                >
                                  <TextField
                                    label=""
                                    variant="outlined"
                                    placeholder="a/p"
                                    //style={{height: '23px', marginTop: 0}}
                                    className={classes.inputControlBoxTime}
                                    name={`entries[${index}].newToTimeFormate.ap`}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === '' ||
                                        e.target.value.toLowerCase() === 'a' ||
                                        e.target.value.toLowerCase() === 'p'
                                      ) {
                                        handleChange(e);
                                      }
                                    }}
                                    value={
                                      (values.entries[index] &&
                                        values.entries[index].newToTimeFormate
                                          .ap) ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.entries &&
                                        errors.entries &&
                                        touched.entries[index] &&
                                        touched.entries[index]
                                          .newToTimeFormate &&
                                        touched.entries[index].newToTimeFormate
                                          .ap &&
                                        errors.entries[index] &&
                                        errors.entries[index]
                                          .newToTimeFormate &&
                                        errors.entries[index].newToTimeFormate
                                          .ap
                                    )}
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </FormControl>

                                {/* <ThemeProvider theme={muiTimePickerTheme}>
                                  <TimePicker
                                    autoOk
                                    name={`entries[${index}].to.time`}
                                    onBlur={handleBlur}
                                    onChange={(e) =>
                                      handleDateChange(
                                        e,
                                        index,
                                        values,
                                        'to',
                                        'time'
                                      )
                                    }
                                    value={
                                      (values.entries[index] &&
                                        moment(
                                          values.entries[index].to.time,
                                          'h:mma'
                                        )) ||
                                      ''
                                    }
                                    format="h:mma"
                                    style={{ width: 80 }}
                                    className={
                                      classes.formDateControlFromBordered
                                    }
                                    disabled={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                  />
                                </ThemeProvider> */}
                              </div>
                            </div>
                            {/* <div style={{ marginTop: 20, marginLeft: 10 }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      (values.entries[index] &&
                                        values.entries[index].allDay) ||
                                      false
                                    }
                                    onChange={(e) =>
                                      handleChangeCheck('allDay', index, values)
                                    }
                                    name={`entries[${index}].allDay`}
                                  />
                                }
                                label="All day"
                              />
                            </div> */}
                            <Divider
                              variant="fullWidth"
                              style={{ marginBottom: 20, marginTop: 20 }}
                            />
                            {entry.actions.repeat.length && index === 0 ? (
                              <div style={{ marginTop: 24 }}>
                                <Typography variant="subtitle2">
                                  Repeat
                                </Typography>
                                {entry.actions.repeat.map((data, idx) => (
                                  <div
                                    key={`repeat-${idx}`}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginTop: 16
                                    }}
                                  >
                                    <FormControl
                                      className={
                                        classes.formLabelControlBordered
                                      }
                                      style={{ width: '90%' }}
                                    >
                                      <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        name={`entries[${index}].actions.repeat[${idx}].type`}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleRepeatChange(
                                            e,
                                            index,
                                            idx,
                                            values
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index].actions
                                              .repeat[idx].type) ||
                                          ''
                                        }
                                        label="Repeat"
                                      >
                                        {/* <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem> */}
                                        <MenuItem value={'Does not repeat'}>
                                          Does not repeat
                                        </MenuItem>
                                        <MenuItem value={'Daily'}>
                                          Daily
                                        </MenuItem>
                                        <MenuItem value={'Custom'}>
                                          Custom
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                    {(values.entries[index] &&
                                      values.entries[index].actions.repeat[idx]
                                        .type) === 'Custom' ? (
                                      <VisibilityIcon
                                        onClick={(e) => {
                                          const data = {
                                            selectedIndex: index,
                                            innerIndex: idx,
                                            values: {
                                              entries: [...values.entries]
                                            }
                                          };
                                          setCustomValue(data);
                                          setOpenModal({
                                            open: true,
                                            value:
                                              values.entries[index].actions
                                                .repeat[idx]
                                          });
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}

                                    {!hideForRepeat ? (
                                      <CloseIcon
                                        onClick={(e) =>
                                          handleDateActionRemove(
                                            index,
                                            idx,
                                            values,
                                            'repeat'
                                          )
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={Calendar}
                                        alt="calendar"
                                        onClick={() =>
                                          goSourceEntry(
                                            hasData[0].parentRepeatEntryId
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {entry.actions.notification.length ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginTop: 24
                                }}
                              >
                                <Typography variant="subtitle2">
                                  Notification
                                </Typography>
                                {entry.actions.notification.map((data, idx) => (
                                  <div
                                    key={`notification-${idx}`}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginTop: 16
                                    }}
                                  >
                                    <FormControl
                                      variant="outlined"
                                      className={classes.formDayLabelControl}
                                    >
                                      <DatePicker
                                        autoOk
                                        okLabel=""
                                        cancelLabel=""
                                        name={`entries[${index}].actions.notification[${idx}].date`}
                                        onBlur={handleBlur}
                                        // maxDate={
                                        //   (values.entries[index] &&
                                        //     moment(
                                        //       values.entries[index].from.date
                                        //     )) ||
                                        //   ''
                                        // }
                                        onChange={(date) =>
                                          handleNotificationDateChange(
                                            date,
                                            index,
                                            idx,
                                            values,
                                            'date'
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            values.entries[index].actions
                                              .notification[idx].date) ||
                                          moment().format('MMM DD,YYYY')
                                        }
                                        style={{ width: '80%' }}
                                        minDateMessage=""
                                        format="MMM DD,YYYY"
                                        className={
                                          classes.formDateControlFromBordered
                                        }
                                      />
                                    </FormControl>
                                    <ThemeProvider theme={muiTimePickerTheme}>
                                      <TimePicker
                                        autoOk
                                        name={`entries[${index}].notification.time`}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleDateActionChange(
                                            e,
                                            index,
                                            idx,
                                            values,
                                            'notification',
                                            'time'
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            moment(
                                              values.entries[index].actions
                                                .notification[idx].time,
                                              'h:mma'
                                            )) ||
                                          ''
                                        }
                                        format="h:mma"
                                        style={{ width: 80 }}
                                        className={
                                          classes.formDateControlFromBordered
                                        }
                                      />
                                    </ThemeProvider>
                                    <CloseIcon
                                      onClick={(e) =>
                                        handleDateActionRemove(
                                          index,
                                          idx,
                                          values,
                                          'notification'
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {entry.actions.reminder.length ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginTop: 20
                                }}
                              >
                                {entry.actions.reminder.map((data, idx) => (
                                  <>
                                    <Typography variant="subtitle2">
                                      Reminder
                                    </Typography>
                                    <div
                                      key={`reminder-${idx}`}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: 16
                                      }}
                                    >
                                      <DatePicker
                                        autoOk
                                        okLabel=""
                                        cancelLabel=""
                                        name={`entries[${index}].reminder`}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleDateActionChange(
                                            e,
                                            index,
                                            idx,
                                            values,
                                            'reminder',
                                            'date'
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            moment(
                                              values.entries[index].actions
                                                .reminder[idx]
                                            )) ||
                                          ''
                                        }
                                        format="MMM DD,YYYY"
                                        style={{ width: '80%' }}
                                        className={
                                          classes.formDateControlFromBordered
                                        }
                                      />
                                      <CloseIcon
                                        onClick={(e) =>
                                          handleDateActionRemove(
                                            index,
                                            idx,
                                            values,
                                            'reminder'
                                          )
                                        }
                                      />
                                    </div>

                                    {values.entries[index] &&
                                    values.entries[index].actions
                                      .reminderNotification &&
                                    values.entries[index].actions
                                      .reminderNotification[idx] ? (
                                      <>
                                        <div
                                          key={`reminderNotificationTitle-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16
                                          }}
                                        >
                                          <Typography variant="subtitle2">
                                            Reminder (Notification)
                                          </Typography>
                                        </div>
                                        <div
                                          key={`reminderNotification-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16,
                                            marginBottom: 16
                                          }}
                                        >
                                          <FormControl
                                            variant="outlined"
                                            className={
                                              classes.formDayLabelControl
                                            }
                                          >
                                            <DatePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              okLabel=""
                                              cancelLabel=""
                                              name={`entries[${index}].actions.reminderNotification[${idx}].date`}
                                              onBlur={handleBlur}
                                              // maxDate={
                                              //   (values.entries[index] &&
                                              //     moment(
                                              //       values.entries[index].from.date
                                              //     )) ||
                                              //   ''
                                              // }
                                              onChange={(date) =>
                                                handleNotificationDateChange(
                                                  date,
                                                  index,
                                                  idx,
                                                  values,
                                                  'date',
                                                  'reminderNotification'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .reminderNotification &&
                                                  values.entries[index].actions
                                                    .reminderNotification[
                                                    idx
                                                  ] &&
                                                  values.entries[index].actions
                                                    .reminderNotification[idx]
                                                    .date) ||
                                                moment().format('MMM DD,YYYY')
                                              }
                                              style={{ width: '80%' }}
                                              minDateMessage=""
                                              format="MMM DD,YYYY"
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </FormControl>
                                          <ThemeProvider
                                            theme={muiTimePickerTheme}
                                          >
                                            <TimePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              name={`entries[${index}].reminderNotification.time`}
                                              onBlur={handleBlur}
                                              onChange={(e) =>
                                                handleDateActionChange(
                                                  e,
                                                  index,
                                                  idx,
                                                  values,
                                                  'reminderNotification',
                                                  'time'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .reminderNotification &&
                                                  values.entries[index].actions
                                                    .reminderNotification[
                                                    idx
                                                  ] &&
                                                  moment(
                                                    values.entries[index]
                                                      .actions
                                                      .reminderNotification[idx]
                                                      .time,
                                                    'h:mma'
                                                  )) ||
                                                ''
                                              }
                                              format="h:mma"
                                              style={{ width: '40%' }}
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </ThemeProvider>
                                          <CloseIcon
                                            onClick={(e) => {
                                              // if(!hideForRepeat){
                                              handleDateActionRemove(
                                                index,
                                                idx,
                                                values,
                                                'reminderNotification'
                                              );
                                              // }
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                  </>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {entry.actions.deadline.length ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  marginTop: 20
                                }}
                              >
                                {entry.actions.deadline.map((data, idx) => (
                                  <>
                                    <Typography variant="subtitle2">
                                      Deadline
                                    </Typography>
                                    <div
                                      key={`deadline-${idx}`}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: 16
                                      }}
                                    >
                                      <DatePicker
                                        autoOk
                                        okLabel=""
                                        cancelLabel=""
                                        name={`entries[${index}].deadline`}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleDateActionChange(
                                            e,
                                            index,
                                            idx,
                                            values,
                                            'deadline',
                                            'date'
                                          )
                                        }
                                        value={
                                          (values.entries[index] &&
                                            moment(
                                              values.entries[index].actions
                                                .deadline[idx]
                                            )) ||
                                          ''
                                        }
                                        format="MMM DD,YYYY"
                                        style={{ width: '80%' }}
                                        className={
                                          classes.formDateControlFromBordered
                                        }
                                      />
                                      <CloseIcon
                                        onClick={(e) =>
                                          handleDateActionRemove(
                                            index,
                                            idx,
                                            values,
                                            'deadline'
                                          )
                                        }
                                      />
                                    </div>

                                    {values.entries[index] &&
                                    values.entries[index].actions
                                      .deadlineNotification &&
                                    values.entries[index].actions
                                      .deadlineNotification[idx] ? (
                                      <>
                                        <div
                                          key={`reminderDeadlineTitle-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16
                                          }}
                                        >
                                          <Typography variant="subtitle2">
                                            Deadline (Notification)
                                          </Typography>
                                        </div>
                                        <div
                                          key={`deadlineNotification-${idx}`}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: 16,
                                            marginBottom: 16
                                          }}
                                        >
                                          <FormControl
                                            variant="outlined"
                                            className={
                                              classes.formDayLabelControl
                                            }
                                          >
                                            <DatePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              okLabel=""
                                              cancelLabel=""
                                              name={`entries[${index}].actions.deadlineNotification[${idx}].date`}
                                              onBlur={handleBlur}
                                              // maxDate={
                                              //   (values.entries[index] &&
                                              //     moment(
                                              //       values.entries[index].from.date
                                              //     )) ||
                                              //   ''
                                              // }
                                              onChange={(date) =>
                                                handleNotificationDateChange(
                                                  date,
                                                  index,
                                                  idx,
                                                  values,
                                                  'date',
                                                  'deadlineNotification'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .deadlineNotification &&
                                                  values.entries[index].actions
                                                    .deadlineNotification[
                                                    idx
                                                  ] &&
                                                  values.entries[index].actions
                                                    .deadlineNotification[idx]
                                                    .date) ||
                                                moment().format('MMM DD,YYYY')
                                              }
                                              style={{ width: '80%' }}
                                              minDateMessage=""
                                              format="MMM DD,YYYY"
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </FormControl>
                                          <ThemeProvider
                                            theme={muiTimePickerTheme}
                                          >
                                            <TimePicker
                                              // disabled={hideForRepeat}
                                              autoOk
                                              name={`entries[${index}].deadlineNotification.time`}
                                              onBlur={handleBlur}
                                              onChange={(e) =>
                                                handleDateActionChange(
                                                  e,
                                                  index,
                                                  idx,
                                                  values,
                                                  'deadlineNotification',
                                                  'time'
                                                )
                                              }
                                              value={
                                                (values.entries[index] &&
                                                  values.entries[index].actions
                                                    .deadlineNotification &&
                                                  values.entries[index].actions
                                                    .deadlineNotification[
                                                    idx
                                                  ] &&
                                                  moment(
                                                    values.entries[index]
                                                      .actions
                                                      .deadlineNotification[idx]
                                                      .time,
                                                    'h:mma'
                                                  )) ||
                                                ''
                                              }
                                              format="h:mma"
                                              style={{ width: '40%' }}
                                              className={
                                                classes.formDateControlFromBordered
                                              }
                                            />
                                          </ThemeProvider>
                                          <CloseIcon
                                            onClick={(e) => {
                                              // if(!hideForRepeat){
                                              handleDateActionRemove(
                                                index,
                                                idx,
                                                values,
                                                'deadlineNotification'
                                              );
                                              // }
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                  </>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {entry.actions.isCancelled ||
                            entry.actions.isPostponed ||
                            entry.actions.isChanged ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  marginTop: 20
                                }}
                              >
                                <div>
                                  <Typography
                                    variant="subtitle2"
                                    style={{
                                      color: entry.actions.isChanged
                                        ? '#000'
                                        : colors.tertiaryRed
                                    }}
                                    className={classes.updatedLabel}
                                  >
                                    {entry.actions.isPostponed
                                      ? 'Postponed'
                                      : entry.actions.isCancelled
                                      ? 'Cancelled'
                                      : 'Updated'}
                                  </Typography>
                                  <Typography
                                    className={classes.updatedDesc}
                                    style={{
                                      ...(entry.actions.isChanged
                                        ? {}
                                        : { fontSize: 16 }),
                                      color: colors.primaryDarkGrey
                                    }}
                                  >
                                    Display{' '}
                                    {entry.actions.isPostponed
                                      ? 'Postponed'
                                      : entry.actions.isCancelled
                                      ? 'Cancelled'
                                      : 'Updated'}{' '}
                                    to member
                                  </Typography>
                                </div>
                                <CloseIcon
                                  onClick={(e) =>
                                    handleDateActionRemove(
                                      index,
                                      0,
                                      values,
                                      entry.actions.isPostponed
                                        ? 'isPostponed'
                                        : entry.actions.isCancelled
                                        ? 'isCancelled'
                                        : 'isChanged'
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            <FormControl
                              variant="outlined"
                              style={{ marginBottom: 24 }}
                              className={classes.formLabelControl}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Apply Action
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={''}
                                onChange={(e) => {
                                  if (e.target.value) {
                                    handleActionChange(e, index, values);
                                  }
                                }}
                                label="Apply Action"
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {index === 0 && (
                                  <MenuItem
                                    disabled={
                                      entry.actions.repeat.length ? true : false
                                    }
                                    value={'repeat'}
                                  >
                                    Repeat
                                  </MenuItem>
                                )}
                                <MenuItem value={'notification'}>
                                  Notification
                                </MenuItem>
                                <MenuItem value={'deadline'}>Deadline</MenuItem>
                                <MenuItem value={'reminder'}>Reminder</MenuItem>
                                {index === 0 && (
                                  <MenuItem value={'cancelled'}>
                                    Cancelled
                                  </MenuItem>
                                )}
                                {index === 0 && (
                                  <MenuItem value={'postponed'}>
                                    Postponed
                                  </MenuItem>
                                )}
                                {index === 0 && (
                                  <MenuItem value={'changed'}>Updated</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Fragment>
                  ))}
                </div>
              </form>

              {isRepeatEntryModal && (
                <Modal
                  isOpen={isRepeatEntryModal}
                  onClose={() => {
                    setRepeatEntryModal(false);
                  }}
                >
                  <FormControl component="fieldset">
                    <FormLabel component="legend" className={classes.label}>
                      <Typography className={classes.repeatEveryText}>
                        {isRepeatEntryModal === true ? 'Edit' : 'Delete'}{' '}
                        recurring event
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      aria-label="endsType"
                      name="repeatType"
                      onChange={(e) => {
                        setRepeatEntryRadioData(e.target.value);
                      }}
                      value={repeatEntryRadioData}
                    >
                      {!isRepeatChange && (
                        <FormControlLabel
                          value={'This Event'}
                          control={<Radio color="primary" />}
                          label={'This Event'}
                        />
                      )}
                      <FormControlLabel
                        value={'This and following events'}
                        control={<Radio color="primary" />}
                        label={'This and following events'}
                      />
                      {isRepeatEntryModal !== 'delete' &&
                        showAllEventOption && (
                          <FormControlLabel
                            value={'All events'}
                            control={<Radio color="primary" />}
                            label={'All events'}
                            disabled={changePrimaryDay !== '' ? true : false}
                          />
                        )}
                    </RadioGroup>
                    {changePrimaryDay !== '' && (
                      <span className={classes.noteText}>
                        You had changed the primary entry day({changePrimaryDay}
                        ) so you can't change "All Events".
                      </span>
                    )}
                  </FormControl>
                  <div className={classes.modalActions}>
                    <Button onClick={() => setRepeatEntryModal(false)}>
                      {' '}
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        if (isRepeatEntryModal === 'delete') {
                          setRepeatEntryModal(false);
                          _deleteEntryHandleForRepeat();
                        } else {
                          handleSubmit();
                        }
                      }}
                      color="primary"
                    >
                      Ok
                    </Button>
                  </div>
                </Modal>
              )}
            </>
          );
        }}
      </Formik>
      {isOpenModal.open && (
        <Modal isOpen={isOpenModal.open} onClose={handleCustomDateClose}>
          <CustomDateModal
            data={customValue}
            submitForm={handleCustomDateSubmit}
            selectedValue={isOpenModal.value}
            onClose={handleCustomDateClose}
          />
        </Modal>
      )}

      {isOpenDeleteModal && (
        <Modal
          isOpen={isOpenDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Delete Entry Creation?
            </Typography>
            <Typography className={classes.modalSubTitle}>
              Are you sure you want to delete this new entry?
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  if (hasData && hasData.length) {
                    // _deleteEntryHandle();
                    deleteAllEntries();
                  } else {
                    setUpdatedEntry(null);
                    dispatch(
                      setFamilyOrSchoolPreserveEntryData({
                        entries: [...entryObject.entries]
                      })
                    );
                    setRightOpen(false);
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Delete'}
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenDeleteLinkedModal.open && (
        <Modal
          isOpen={isOpenDeleteLinkedModal.open}
          onClose={() => setOpenDeleteLinkedModal({ open: false, id: null })}
        >
          <div className={classes.modalBox}>
            <Typography
              className={classes.modalTitle}
              style={{ marginBottom: 18 }}
            >
              Delete Linked Entry?
            </Typography>
            <Typography className={classes.modalSubTitle}>
              Are you sure you want to delete this linked entry?
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={removeLinkedEntry}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
              >
                Delete
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteLinkedModal({ open: false, id: null });
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default withFirebase(ClassGroupCalendarEntry);
