import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";


export const addChildrenStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%'
  },
  cancelButton: {
    width: 106,
    height: 50,
    borderRadius: 3,
    border: 'solid 1 ',
    backgroundColor: colors.primaryLightGrey,
    borderColor: colors.primaryLightGrey,
    float: 'left'
  },
  title: {
    width: 303,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark
    // marginTop: 24
  },
  subtitle: {
    width: 400,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey
  },
  label: {
    width: 200,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  controlBox: {
    width: 572,
    height: 60,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    marginTop: 24,
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    }
  },
  inputControlBox: {
    width: 572,
    backgroundColor: '#ffffff',
    marginTop: 24
  },
  childVisiileCotrolBox: {
    width: 572,
    marginTop: 24,
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)'
  },
  errorBox: {
    border: '1px solid red'
  },
  radio: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  checked: {},
  checkbox: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  rightAd: {
    position: 'absolute',
    top: '20%',
    right: '5%',
    width: 250,
    height: 170,
    borderRadius: 10,
    backgroundColor: '#f3f4f6'
  },
  adTitle: {
    width: 169,
    height: 28,
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHight: 'normal',
    letterSpacing: 0.2,
    color: colors.primaryDark,
    margin: '5%'
  },
  adSubTitle: {
    width: 200,
    height: 54,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    margin: '5%'
  },
  adButton: {
    width: 134,
    height: 30,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    marginBottom: '5rem'
  },
  createButtonTextFree: {
    width: 200,
    height: 50,
    borderRadius: 3,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.white
  },
  ORtext: {
    margin: '15px 30px 0 30px',
    color: '#4c5b68',
    fontSize: '16px',
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    fontWeight: 'normal'
  },
  adButtonText: {
    height: 18,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: colors.primary,
    marginTop: '4.3%'
  },
  generate: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: colors.primary,
    float: 'right',
    display: 'flex',
    height: '30px',
    lineHeight: '30px',
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center'
  },
  buttonText: {
    width: 94,
    height: 50,
    borderRadius: 3,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.white,
    marginTop: '4.3%'
  },
  primaryButtonSave: {
    width: 106,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginLeft: '15%',
    marginTop: '5.5%',
    color: colors.white
  },
  buttonTextSave: {
    height: 24,
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center'
  },
  closeIconRotate: {
    transform: 'rotate(-180deg)',
    transition: 'transform 999ms ease',
    fill: colors.primary
  },
  NonAUchildBox: {
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)',
    pointerEvents: 'none',
    userSelect: 'none',
    '&&::selection': {
      color: 'transparent',
      background: 'transparent'
    }
  },
  AuChildBox: {}
}));