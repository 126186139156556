import React from 'react';
import {
  makeStyles,
  Backdrop,
  CircularProgress,
  Toolbar
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import TopHeader from '../../components/TopHeader';
import LeftSideBar from '../../components/LeftSideBar';
import MessageSnackbar from '../../components/Message';
import { drawerWidth, colors } from '../../utils/constant';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.primary
  },
  content: {
    flexGrow: 1,
    width: `calc(100% - ${drawerWidth}px)`,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    minHeight: '100vh',
    backgroundColor: '#F4F6F8',
    marginLeft: drawerWidth,
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(1)
  },
  maintenanceMode:{
    paddingTop: `48px !important`
  }
}));

function Dashboard({ children }) {
  const classes = useStyles();
  const isLoading = useSelector((state) => state.app.isLoading);
  const maintenanceMode = useSelector((state) => state.maintenance.maintenanceMode);

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={isLoading || false}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MessageSnackbar />
      <TopHeader />
      <LeftSideBar />
      <main className={clsx(classes.content, 'drawerClass', {[classes.maintenanceMode]: maintenanceMode})}>
        <Toolbar />
        {children}
      </main>
    </div>
  );
}

export default Dashboard;
