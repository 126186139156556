import axios from 'axios';
import { LOCAL_STORAGE_KEY } from 'src/constants/common';
import { generateToken } from 'src/services/auth';


const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/admin`
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const headers = config.headers;

    const token = localStorage.getItem(LOCAL_STORAGE_KEY.AUTH_TOKEN);
    return {
      ...config,
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      try {
        const userId = localStorage.getItem(LOCAL_STORAGE_KEY.USER_ID);
        const token = await generateToken(userId);
        localStorage.setItem(LOCAL_STORAGE_KEY.AUTH_TOKEN, token);

        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axios(originalRequest);
      } catch (error) {
        console.error(error);
      }
    }
    return Promise.reject(error)
  }
);

export default axiosInstance;
