import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";

export const membersListActiveStyles = makeStyles({
  tableRow: {
    height: '72px',
    '&:hover': {
      backgroundColor: `rgba(244, 245, 247, 0.51) !important`,
      cursor: 'pointer'
    }
  },
  dataTableCell: {
    color: colors.primaryDark,
    padding: 8,
    '&:first-child': {
      paddingLeft: 24
    }
  },
  actionButton: {
    padding: '8px 17px',
    fontSize: 16,
    lineHeight: '24px',
    float: 'right'
  },
  startIcon: {
    marginRight: 12
  },
  surname: {
    fontWeight: 500
  },
  pauseStatus: {
    fontSize: 14,
    backgroundColor: '#bd0000',
    color: '#ffffff',
    padding: '0 5px'
  },
  freeStatus: {
    fontSize: 14,
    backgroundColor: '#e8f1f5',
    color: '#2f7d91',
    padding: '0 5px'
  },
  paidStatus: {
    fontSize: 14,
    backgroundColor: '#e8f5e9',
    color: '#348f37',
    padding: '2px 5px'
  },
  actionIcon: {
    height: 50,
    width: 50,
    padding: 10,
    cursor: 'pointer',
    margin: 'auto',
    '&:hover': {
      backgroundColor: colors.primary,
      borderRadius: 50,
      '& svg g': {
        fill: '#ffffff'
      }
    }
  },

  redirectionClass: {
    padding: '4px 10px',
    fontSize: 14,
    textDecoration: 'none',
    color: colors.primaryDark,
    borderRadius: 13.5,
    '&:hover': {
      backgroundColor: colors.primary,
      borderRadius: 13.5,
      boxShadow: `0 2px 12px 0 rgba(0, 0, 0, 0.18)`,
      cursor: 'pointer',
      padding: '4px 10px',
      fontSize: 14,
      color: colors.white
    }
  },
  newButton: {
    backgroundColor: '#0084ff',
    // border: '1px solid #0084ff',
    // padding: '3px 5px 3px 5px',
    color: '#fff',
    margin: '15px 0 0 0',
    borderRadius: '2px',
    fontSize: '14px'
  },
  nonAUChild: {
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)',
    pointerEvents: 'none',
    userSelect: 'none',
    '&&::selection': {
      color: 'transparent',
      background: 'transparent'
    }
  },
  filterCard: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px 12px'
  }
});