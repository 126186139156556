import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";

export const addMemberStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%'
  },
  contentWrapper: {
    maxWidth: 572,
    width: '100%'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
  },
  cancelButton: {
    width: 106,
    height: 50,
    borderRadius: 3,
    border: 'solid 1 ',
    backgroundColor: colors.primaryLightGrey,
    borderColor: colors.primaryLightGrey,
    float: 'left'
  },
  title: {
    width: 303,
    height: 40,
    fontFamily: 'Roboto',
    fontSize: 23,
    fontWeight: 500,
    lineHeight: 1.74,
    letterSpacing: 'normal',
    color: colors.primaryDark
  },
  subtitle: {
    width: 400,
    height: 24,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDarkGrey
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    marginTop: 10
  },
  controlBox: {
    width: 572,
    height: 60,
    borderRadius: 4,
    borderStyle: 'solid',
    border: 1,
    borderColor: colors.primaryLightGrey,
    backgroundColor: '#ffffff',
    marginTop: 24,
    // marginLeft: 0,
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px -6px rgba(0, 0, 0, 0.25)'
    },
    color: colors.primaryDarkGrey7
  },
  inputControlBox: {
    color: colors.primaryDarkGrey7,
    width: 572,
    backgroundColor: '#ffffff',
    marginTop: 24,
    '& .MuiInputLabel-shrink': {
      height: 16,
      objectFit: 'contain',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      paddingLeft: 5,
      paddingRight: 5,
      '&.MuiFormLabel-filled, &.Mui-focused': {
        transform: 'translate(14px, -8px) scale(1) !important'
      }
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      fontSize: '14px',
      marginLeft: '5px !important'
    }
  },
  textFieldContainer: {
    '*': {
      fontWeight: '500'
    },
    '& .MuiInputLabel-shrink': {
      height: 16,
      objectFit: 'contain',
      fontFamily: 'Roboto',
      fontSize: 14,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      paddingLeft: 5,
      paddingRight: 5,
      '&.MuiFormLabel-filled, &.Mui-focused': {
        transform: 'translate(14px, -8px) scale(1) !important'
      }
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      fontSize: '14px',
      marginLeft: '5px !important'
    },
    "& .MuiFormHelperText-root":{
      color: '#f44336',
    marginLeft: 14,
    margin: 0,
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 1.66,
    fontSize: "16px"
    }
  },
  radio: {
    color: colors.primaryLightGrey,
    '&$checked': {
      color: colors.primary
    }
  },
  checked: {},
  rightAd: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 260,
    height: 260,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: '#f3f4f6',
    padding: '19px 19px',
    background: 'url(/static/images/school-book.png) bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  },
  adTitle: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.2,
    color: colors.primaryDark
  },
  adSubTitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: colors.primaryDark,
    marginTop: 14
  },
  adButton: {
    height: 30,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    marginTop: 14,
    color: colors.primary
  },

  generate: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: colors.primary,
    float: 'right',
    display: 'flex',
    height: '30px',
    lineHeight: '30px',
    marginTop: '24px',
    alignItems: 'center',
    marginLeft: '1rem'
  },
  error: {
    color: '#f44336',
    marginLeft: 14,
    margin: 0,
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: 1.66
  },
  buttonText: {
    width: 94,
    height: 50,
    borderRadius: 3,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center',
    color: colors.white,
    marginTop: '4.3%'
  },
  pauseContainer: {
    position: 'absolute',
    top: 0,
    right: 80,
    display: 'flex',
    flexDirection: 'column'
  },
  pauseText: { textDecoration: 'underline' },
  modalTitle: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: -0.2,
    color: colors.primaryDark
  },
  modalSubHeading: {
    // width: 523,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark,
    marginTop: '2%'
  },
  modalContent: {
    minHeight: 75,
    paddingTop: 12
  },
  modalSubTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.1,
    color: colors.primaryDark
  },
  modalBox: {
    width: 450
    // height: 170,
  },
  primaryButton: {
    width: 163,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.primary,
    marginRight: 20,
    color: colors.white,
    textTransform: 'none'
  },
  greyButton: {
    width: 136,
    height: 50,
    borderRadius: 3,
    backgroundColor: colors.secondaryText,
    color: colors.white,
    textTransform: 'none'
  },
  modalActions: {
    marginTop: '2%'
  },
  inputModalControlBox: {
    backgroundColor: '#ffffff',
    marginTop: 24
  },
  buttonTextSave: {
    height: 24,
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1,
    textAlign: 'center'
  },
  boldFont: {
    fontWeight: 'bold',
    color: 'rgb(71,71,71,1)'
  },
  closeIconRotate: {
    transform: 'rotate(-180deg)',
    transition: 'transform 999ms ease',
    fill: colors.primary
  }
}));