import React, { useCallback, useEffect, useState } from 'react';
import Dashboard from '../layout/Dashboard';
import AddIcon from '@material-ui/icons/Add';
import { EDIT_ICON, SEARCH_ICON } from 'src/assets';
import { withFirebase } from 'src/utils/firebase';
import { useDispatch, useSelector } from 'react-redux';
import {
  CircularProgress,
  makeStyles,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Fab,
  Popover,
  List,
  ListItem,
  ListItemText,
  Link,
  Checkbox,
  Paper,
  Table,
  FormControlLabel,
  TablePagination,
  Toolbar,
  Tooltip,
  TableContainer,
  TableBody,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  withStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import _ from 'lodash';
import { routes, year as DefaultYear } from 'src/utils/constant';
import {
  loaderStart,
  loaderStop,
  addMessage,
  setFamilyOrSchoolPreserveEntryData
} from 'src/redux/actions/appActions';
import { resetFamily } from 'src/redux/actions/familyActions';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Modal from 'src/components/Modal';
import useApiCalls from 'src/customHooks/useApiCalls';
import useGetFamilyCount from 'src/customHooks/useGetFamilyCount';
import makeApiRequest from 'src/utils/axios';
import { API_GET_ALL_FREE_SCHOOLS, API_CHILD_UPDATE_GROUP } from 'src/utils/api';
import { setFreeSchoolList } from 'src/redux/actions/reusableStateValueActions';
import api_icon from 'src/assets/apiIcon.svg';
import { membersListNewStyles, useToolbarStyles } from 'src/styles/membersStyle/membersListNewStyles';
import { MESSAGE_TYPES } from 'src/constants/common';


const headCells = [
  {
    id: 'familySurname',
    label: 'Surname',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true
  },
  {
    id: 'parents',
    label: 'First',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true
  },
  {
    id: 'schools',
    label: 'School Name',
    // minWidth: 170,
    setLabelStyle: true,
    iconPosition: 'before',
    hasSort: true
    // width: '14%'
  },
  {
    id: 'childs',
    label: 'Year',
    align: 'left',
    format: 'icon',
    onEach: true,
    icon: EDIT_ICON,
    iconPosition: 'before',
    hasSort: true
  },
  // {
  //   id: 'freeHost',
  //   label: 'Free Host?',
  //   minWidth: 170,
  //   iconPosition: 'before',
  //   hasSort: true,
  //   width: '16%'
  // },

  {
    id: 'date',
    label: 'Joined',
    minWidth: 170,
    setLabelStyle: true,
    iconPosition: 'before',
    hasSort: true
  },
  {
    id: 'apiSync',
    label: '',
    align: 'center',
    format: 'icon',
    iconPosition: 'right',
    icon: false,
    width: '5%'
  }
];
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    selected
  } = props;
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{
            position: selected.length > 0 ? 'relative' : 'none',
            height: selected.length > 0 ? 0 : 0,
            background:
              selected.length > 0 ? 'rgba(139, 197, 23, 0.5)' : '#f4f5f7'
          }}
        >
          <Checkbox
            className={classes.checkbox}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              background: selected.length > 0 ? '#fff' : '#f4f5f7',
              fontSize: '14px'
            }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label.toLocaleUpperCase()}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, firebase, selected } = props;
  const dispatch = useDispatch();
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenApproveModal, setIsOpenApproveModal] = useState(false);
  const isLoading = useSelector((state) => state.app.isLoading);
  const [deleteText, setDeleteText] = useState('');
  const [approveText, setApproveText] = useState('');


  const deleteUserHandle = async () => {
    setDeleteText('');
    dispatch(loaderStart());
    selected.forEach((el) => {
      firebase.getFamilyByID(el)
      .then(
        async (doc) => {
          let familyData = doc.data();
          let pushRecord = {
            members: []
          };
          Array.isArray(familyData.childs) &&
          familyData.childs.map((childData) => {
            let groups = _.compact([
              childData.group,
              childData.groups
            ]);
            groups = _.uniq(
              [].concat.apply([], groups)
            );
            groups.forEach((el) => {
              pushRecord.members.push({
                childId: childData.id,
                groupId: el,
                isAdd: false
              });
            });
        });

        if(pushRecord.members.length > 0){
          const { data, error } = await makeApiRequest({
            url: API_CHILD_UPDATE_GROUP,
            method: 'POST',
            data: pushRecord
          });
          if(error){
            throw(error);
          }
        }
        })
        .catch(err => {
          console.log('Error: ', err);
        })
    })

    firebase
    .deleteNonActiveFamiliesById(selected)
    .then(
      async() => {
        dispatch(addMessage('Family Deleted'));
        setOpenDeleteModal(false);
        dispatch(loaderStop());
        window.location.reload();
      },
      (error) => {
        console.log('Error: ', error);
        dispatch(addMessage('Family was not deleted. Please try again', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      }
    )
    .catch((error) => {
      console.log('Error: ', error);
      dispatch(addMessage('Family was not deleted. Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    });
  };

  const approveFamily = () => {
    dispatch(loaderStart());
    firebase
      .approveFamiliesById(selected)
      .then((res) => {
        setIsOpenApproveModal(false);
        dispatch(loaderStop());
        dispatch(addMessage('Family Approved'));
        window.location.reload();
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
      });
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <div className={classes.modalViewerDesign}>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.approveButton}
            classes={{ startIcon: classes.startIcon }}
            onClick={() => setIsOpenApproveModal && setIsOpenApproveModal(true)}
          >
            Approve
          </Button>
          <Typography
            className={classes.deleteText}
            variant="subtitle1"
            component="div"
            onClick={() => setOpenDeleteModal && setOpenDeleteModal(true)}
          >
            Delete
          </Typography>

          {isOpenApproveModal && (
            <Modal
              isOpen={isOpenApproveModal}
              onClose={() => {
                setIsOpenApproveModal(false);
              }}
            >
              <div className={classes.modalBox}>
                <Typography className={classes.modalTitle}>
                  Approve Member?
                </Typography>

                <div className={classes.modalSubHeading}>
                  <span
                    className={classes.modalSubTitle}
                    style={{ fontWeight: 500, paddingBottom: 14 }}
                  >
                    Type “APPROVE MEMBER” to confirm
                  </span>
                  <FormControl
                    component="fieldset"
                    classes={{ root: classes.inputControlBoxContainer }}
                    style={{ width: '100%' }}
                  >
                    <TextField
                      label=""
                      variant="outlined"
                      className={classes.inputControlBox}
                      style={{ marginTop: 0 }}
                      value={approveText}
                      onChange={(e) => setApproveText(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className={classes.modalActions}>
                  <Button
                    onClick={approveFamily}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={approveText !== 'APPROVE MEMBER'}
                    className={classes.primaryButton}
                    startIcon={
                      isLoading && (
                        <CircularProgress color="secondary" size={25} />
                      )
                    }
                  >
                    {!isLoading && 'Approve'}
                  </Button>
                  <Button
                    onClick={() => {
                      setIsOpenApproveModal(false);
                    }}
                    variant="contained"
                    size="large"
                    className={classes.greyButton}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          )}

          {isOpenDeleteModal && (
            <Modal
              isOpen={isOpenDeleteModal}
              onClose={() => {
                setOpenDeleteModal(false);
              }}
            >
              <div className={classes.modalBox}>
                <Typography className={classes.modalTitle}>
                  Delete Member Account?
                </Typography>
                <div className={classes.modalSubHeading}>
                  <Typography className={classes.modalSubTitle}>
                    <span style={{ fontWeight: 'bold' }}>
                      Warning: This action cannot be reversed.
                    </span>
                    <br />
                    The following data will be permanently removed from the
                    database.
                  </Typography>
                </div>
                <div className={classes.modalContent}>
                  <Typography className={classes.modalSubTitle}>
                    - Member account (admin)
                    <br />
                    - Member account and login credentials (app)
                    <br />
                  </Typography>
                </div>
                <div className={classes.modalSubHeading}>
                  <span
                    className={classes.modalSubTitle}
                    style={{ fontWeight: 500, paddingBottom: 14 }}
                  >
                    Type “DELETE ACCOUNT” to confirm
                  </span>
                  <FormControl component="fieldset" style={{ width: '100%' }}>
                    <TextField
                      label=""
                      variant="outlined"
                      className={classes.inputModalControlBox}
                      style={{ marginTop: 0 }}
                      value={deleteText}
                      onChange={(e) => setDeleteText(e.target.value)}
                    />
                  </FormControl>
                </div>
                <div className={classes.modalActions}>
                  <Button
                    onClick={deleteUserHandle}
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={deleteText !== 'DELETE ACCOUNT'}
                    className={classes.primaryButton}
                    startIcon={
                      isLoading && (
                        <CircularProgress color="secondary" size={25} />
                      )
                    }
                  >
                    {!isLoading && 'Delete'}
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenDeleteModal(false);
                    }}
                    variant="contained"
                    size="large"
                    className={classes.greyButton}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </div>
      ) : (
        ''
      )}
    </Toolbar>
  );
};
const MembersListNew = (props) => {
  const classes = membersListNewStyles();
  const history = useHistory();
  const prefillFamilyData = useSelector((state) => state.family.currentFamily);
  const [families, setFamily] = useState([]);
  const [allFamilies, setAllFamily] = useState([]);
  const [schoolChange, setSchoolChange] = useState('');
  const [year, setYear] = useState('');
  const [showClearFilters, setShowClearFilters] = useState(false);
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('statusUpdatedAt');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [schoolDropDown, setSchoolDropDown] = useState([]);
  const [searchText, setSearchText] = useState('');

  const {
    planType,
    columns,
    rows,
    searchPlaceholderText,
    tableRow,
    disableSearch,
    disableColumnLabel,
    // searchChangeHandler,
    // schoolChangeHandler,
    schoolChangeHandlerFamilyFree,
    schoolChangeHandlerFamilyPaid,
    yearChangeHandlerFree,
    firebase
  } = props;

  const { data, loading, error, fetchData } = useApiCalls('families/free', {
    accountStatus: 'New'
  });
  const { familyCount } = useGetFamilyCount('families/free/counts');

  useEffect(() => {
    dispatch(resetFamily());
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = families.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  // Page change next/prev function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData({
      ...(page < newPage
        ? { nextPageRef: data?.nextPageRef }
        : { prevPageRef: data?.prevPageRef }),
      pageSize: rowsPerPage,
      searchText: searchText,
      schoolId: schoolChange,
      year: year
    });
  };
  // Page size change function
  const handleChangeRowsPerPage = (event) => {
    const newRowsCount = +event.target.value;
    setRowsPerPage(newRowsCount);
    fetchData({
      pageSize: newRowsCount,
      searchText: searchText,
      schoolId: schoolChange,
      year: year
    });
    setPage(0);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  useEffect(() => {
    dispatch(setFamilyOrSchoolPreserveEntryData(null));
    dispatch(resetFamily());
    dispatch(loaderStart());
    firebase
      .getFreeFamiliesByStatus('New')
      .then(function (querySnapshot) {
        let FamiliesData = [];
        querySnapshot.forEach(function (doc) {
          FamiliesData.push({ ...doc.data(), ...{ id: doc.id } });
        });
        FamiliesData.map((data) => {
          data.statusUpdatedAt =
            data.childs?.length > 0
              ? data.childs?.[0].statusUpdatedAt
              : data.familyStatusUpdatedAt;
        });
        setFamily(FamiliesData);
        setAllFamily(FamiliesData);
        dispatch(loaderStop());
      })
      .catch(function (error) {
        console.log('Error getting documents: ', error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  }, [firebase, setFamily, dispatch]);
  useEffect(() => {
    dispatch(loaderStart());
    const fetchFreeSchoolData = async () => {
      const { data, error } = await makeApiRequest({
        url: API_GET_ALL_FREE_SCHOOLS
      });
      setSchoolDropDown(data?.data || []);
      dispatch(setFreeSchoolList(data?.data || []));
      if (error) {
        dispatch(addMessage(error, MESSAGE_TYPES.ERROR));
      }
      dispatch(loaderStop());
    };
    fetchFreeSchoolData();
  }, [dispatch]);

  const handleFamiliesChange = (school, year) => {
    setShowClearFilters(school !== '' || year !== '');
    return allFamilies.filter((family) => {
      const childSchools = family.childs.map((child) => child.school);
      const childYears = family.childs.map((child) => child.year);
      const validateSchool = school === '' || childSchools.includes(school);
      const validateYear = year === '' || childYears.includes(year);
      return validateSchool && validateYear;
    });
  };
  // School change function
  const schoolChangeHandler = (e) => {
    setPage(0);
    setYear('');
    const schoolID = e.target.value;
    setSchoolChange(schoolID);
    fetchData({
      schoolId: schoolID,
      searchText: searchText,
      pageSize: rowsPerPage,
      nextPageRef: null,
      prevPageRef: null
    });
    setFamily(handleFamiliesChange(e.target.value, year));
  };

  // Clear all filters
  const filterClearHandler = () => {
    setPage(0);
    setYear('');
    setSearchText('');
    setSchoolChange('');
    setShowClearFilters(false);
    setFamily(allFamilies);
    fetchData({ pageSize: rowsPerPage });
  };

  // Year change function
  const yearChangeHandler = (e) => {
    setPage(0);
    setSchoolChange('');
    const yearValue = e.target.value;
    setYear(yearValue);
    fetchData({
      year: yearValue,
      searchText: searchText,
      pageSize: rowsPerPage,
      nextPageRef: null,
      prevPageRef: null
    });
    setFamily(handleFamiliesChange(schoolChange, e.target.value));
  };

  const handleRowSelect = (row) => {
    history.push(routes.memberAccount + '/' + 'new' + '/' + row['id']);
  };

  // Search text change function
  const searchChangeHandler = useCallback(
    (e) => {
      setPage(0);
      const { value } = e.target;
      setSearchText(value.toLowerCase().trim());
    },
    [searchText]
  );
  useEffect(() => {
    const getData = setTimeout(() => {
      fetchData({
        searchText: searchText,
        pageSize: rowsPerPage,
        schoolId: schoolChange,
        year: year,
        nextPageRef: null,
        prevPageRef: null
      });
    }, 300);
    return () => clearTimeout(getData);
  }, [searchText]);

  // Filter school name
  const filterSchoolNameList = (row) => {
    let childSchools = row.childs.map((child) => child.school);
    const data = _.compact(_.uniq(childSchools));
    return (
      <>
        {data.map((schools) => {
          return <div>{schools}</div>;
        })}
      </>
    );
  };
  // Filter years data to display
  const filterYearsDisplayData = (value) => {
    const result = value.reduce((acc, student) => {
      const existingSchool = acc.find((item) => item.school === student.school);

      if (existingSchool) {
        // School already exists, update the year array
        if (!existingSchool.year.includes(student.year)) {
          existingSchool.year.push(student.year);
        }
      } else {
        // School doesn't exist, create a new entry
        acc.push({ school: student.school, year: [student.year] });
      }

      return acc;
    }, []);

    const childsData = result.map((p, index) => {
      return (
        <>
          <span key={index}>
            {p.year.map((data, dataIdx) => {
              return (
                <>
                  {data === '-1' ? `PK` : data === `0` ? `K` : `Y${data}`}
                  {p?.year.length !== dataIdx + 1 ? `, ` : ''}
                </>
              );
            })}
            {/* {childVisibility === 'Australia' ? (
            <span> {p.firstName}</span>
          ) : (
            <span className={classes.nonAUChild}>Redacted</span>
          )} */}
          </span>
          <br />
        </>
      );
    });
    return childsData;
  };
  if (loading) {
    dispatch(loaderStart());
  } else {
    dispatch(loaderStop());
  }
  return (
    <Dashboard>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20 }}
        >
          Members
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.actionButton}
            startIcon={<AddIcon />}
            classes={{ startIcon: classes.startIcon }}
            onClick={() => history.push(routes.memberCreate)}
          >
            New Member
          </Button>
        </Typography>

        <div className={classes.root}>
          <Paper className={classes.paper}>
            <div className={classes.header}>
              <div
                colSpan={headCells?.length}
                className={classes.inputTableCell}
              >
                <TextField
                  size="small"
                  name="searchClient"
                  variant="outlined"
                  placeholder="Search by surname or email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={SEARCH_ICON} alt="Search" tabIndex="-1" />
                      </InputAdornment>
                    )
                  }}
                  onChange={searchChangeHandler}
                  classes={{
                    root: classes.textFieldRoot
                  }}
                />

                <FormControl
                  variant="outlined"
                  size="small"
                  classes={{ root: classes.inputControlBoxContainer }}
                  disabled={year?.length >= 1}
                >
                  <InputLabel
                    className={classes.placeholderText}
                    id="country-select-outlined-label"
                  >
                    All Schools
                  </InputLabel>
                  <Select
                    labelId="schoolName-select-outlined-label"
                    className={classes.inputControlBox}
                    style={{
                      height: 45,
                      width: 273,
                      marginRight: 12,
                      marginTop: 0
                    }}
                    name="schoolData"
                    onChange={
                      schoolChangeHandler ||
                      schoolChangeHandlerFamilyFree ||
                      schoolChangeHandlerFamilyPaid
                    }
                    id="school-name-select-outlined"
                    label="All Schools"
                    value={schoolChange}
                  >
                    <MenuItem value="">
                      <em>All Schools</em>
                    </MenuItem>

                    {schoolDropDown &&
                      schoolDropDown.map(function (object) {
                        return (
                          <MenuItem key={object.id} value={object.id}>
                            {object.schoolName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

                <FormControl
                  variant="outlined"
                  size="small"
                  classes={{ root: classes.inputControlBoxContainer }}
                  disabled={schoolChange?.length >= 1}
                  style={{ width: 185 }}
                >
                  <InputLabel
                    id="year-select-outlined-label"
                    className={classes.placeholderText}
                  >
                    Year
                  </InputLabel>
                  <Select
                    labelId="year-select-outlined-label"
                    className={classes.inputControlBox}
                    name="years"
                    onChange={yearChangeHandler}
                    style={{
                      height: 45,
                      width: 173,
                      marginRight: 250,
                      marginTop: 0
                    }}
                    id="year-select-outlined"
                    label="Year"
                    value={year}
                  >
                    <MenuItem value="">
                      <em>All Years</em>
                    </MenuItem>
                    {DefaultYear &&
                      DefaultYear.map((object) => {
                        return (
                          <MenuItem key={object.value} value={object.value}>
                            {object.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

                {showClearFilters &&
                window.location.pathname.includes('member') ? (
                  <div
                    className={classes.clearFiltersContainer}
                    onClick={filterClearHandler}
                  >
                    <span>clear</span>
                  </div>
                ) : null}

                <span className={classes.statusFilterContainer}>
                  <Button
                    className={classes.freeMembersButton}
                    onClick={() => history.push(routes.membersActive)}
                    style={{
                      border:
                        window.location.pathname === routes.membersActive
                          ? '1px solid #8bc517'
                          : '1px solid #bfc5d1',
                      backgroundColor:
                        window.location.pathname === routes.membersActive
                          ? 'rgba(139, 197, 23, 0.2)'
                          : '#fff'
                    }}
                  >
                    <div className={classes.filterCard}>
                      <span>Active</span>
                      <span
                        className={
                          window.location.pathname === routes.membersActive
                            ? classes.countmemberBox
                            : classes.memberboxActiveDisable
                        }
                      >
                        {familyCount?.activeFamiliesCount || 0}
                      </span>
                    </div>
                  </Button>

                  <Button
                    className={classes.freeMembersButton}
                    onClick={() => history.push(routes.membersNew)}
                    style={{
                      border:
                        window.location.pathname === routes.membersNew
                          ? '1px solid #0084ff'
                          : '1px solid #bfc5d1',
                      backgroundColor:
                        window.location.pathname === routes.membersNew
                          ? 'rgba(0, 132, 255, 0.2)'
                          : '#fff'
                    }}
                  >
                    <div className={classes.filterCard}>
                      <span>New</span>
                      <span className={classes.countmemberBoxNew}>
                        {error ? 0 : data?.totalCount || 0}
                      </span>
                    </div>
                  </Button>

                  <Button
                    className={classes.freeMembersButton}
                    onClick={() => history.push(routes.membersPending)}
                    style={{
                      border:
                        window.location.pathname === routes.membersPending
                          ? '1px solid #ff9b00'
                          : '1px solid #bfc5d1',
                      backgroundColor:
                        window.location.pathname === routes.membersPending
                          ? 'rgba(255, 155, 0, 0.2)'
                          : '#fff'
                    }}
                  >
                    <div className={classes.filterCard}>
                      <span>Pending</span>
                      <span className={classes.countmemberBoxPending}>
                        {familyCount?.pendingFamiliesCount || 0}
                      </span>
                    </div>
                  </Button>
                </span>
              </div>
            </div>

            <div style={{ position: 'relative' }}>
              <div
                style={{
                  position: selected.length > 0 ? 'absolute' : 'absolute',
                  zIndex: selected.length > 0 ? 3 : 0,
                  left: selected.length > 0 ? '48px' : 0,
                  width: selected.length > 0 ? 'calc(100% - 48px)' : 0,
                  top: 0,
                  backgroundColor: selected.length > 0 ? 'white' : '',
                  height: selected.length > 0 ? '60px' : 0,
                  overflow: selected.length > 0 ? 'hidden' : '',
                  display: selected.length > 0 ? '' : 'none'
                }}
              >
                <EnhancedTableToolbar
                  numSelected={selected.length}
                  rows={rows}
                  classes={classes}
                  selected={selected}
                  firebase={firebase}
                />
              </div>
              <TableContainer className={classes.container}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  classes={{ stickyHeader: classes.tableStickyHeader }}
                >
                  <EnhancedTableHead
                    rows={families}
                    classes={classes}
                    numSelected={selected?.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={families?.length}
                    searchPlaceholderText={searchPlaceholderText}
                    schoolDropDown={schoolDropDown}
                    prefillFamilyData={prefillFamilyData}
                    selected={selected}
                    searchChangeHandler={searchChangeHandler}
                    schoolChangeHandler={schoolChangeHandler}
                    schoolChangeHandlerFamilyFree={
                      schoolChangeHandlerFamilyFree
                    }
                    schoolChangeHandlerFamilyPaid={
                      schoolChangeHandlerFamilyPaid
                    }
                    yearChangeHandlerFree={yearChangeHandler}
                    firebase={firebase}
                  />
                  <TableBody>
                    {!error ? (
                      data &&
                      data?.data.map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            style={{ height: '4.5rem' }}
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            className={clsx({[classes.selectedRow]: isItemSelected})}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) => handleClick(event, row.id)}
                                className={classes.checkbox2}
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '18%' }}
                            >
                              <span style={{ marginLeft: '15px' }}>
                                {row.familySurname}
                              </span>
                            </TableCell>
                            <TableCell
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '19%' }}
                            >
                              {row.parents.map((data) => {
                                return <div>{data.firstName}</div>;
                              })}
                            </TableCell>
                            <TableCell onClick={() => handleRowSelect(row)} style={{ width: '20%' }}>
                              {filterSchoolNameList(row)}
                            </TableCell>
                            <TableCell
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '23.8%' }}
                            >
                              {filterYearsDisplayData(row?.childs)}
                            </TableCell>

                            <TableCell
                              onClick={() => handleRowSelect(row)}
                              style={{ width: '14%' }}
                            >
                              <div>
                              {row?.childs[0]
                                  ? moment(row.childs[0].statusUpdatedAt)
                                      .tz('Australia/Sydney')
                                      .format('DD MMM YY')
                                  : row?.familyStatusUpdatedAt
                                  ? moment(row.familyStatusUpdatedAt)
                                      .tz('Australia/Sydney')
                                      .format('DD MMM YY')
                                  : null}
                              </div>
                            </TableCell>
                            <TableCell>
                              {row?.createdByApiSync ? <img src={api_icon} style={{paddingRight:"1rem",float:"right"}} /> : <></>}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow className="MuiTableRow-root makeStyles-tableRow-1 MuiTableRow-hover">
                        <TableCell
                          className="MuiTableCell-root MuiTableCell-body"
                          colSpan={headCells?.length + 1}
                        >
                          No Records Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={!error ? data?.totalCount || 0 : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
            </div>
          </Paper>
        </div>
      </MuiPickersUtilsProvider>
    </Dashboard>
  );
};

export default withFirebase(MembersListNew);
