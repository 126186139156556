import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  makeStyles,
  Paper,
  Button,
  Divider,
  TableRow,
  TableCell,
  TextField,
  Typography,
  IconButton,
  Snackbar,
  SnackbarContent,
  FormControl,
  Select,
  MenuItem,
  Link
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import { generateFileHash, isValidFileType } from '../../utils/functions';
import { withFirebase } from 'src/utils/firebase';
import Dashboard from '../layout/Dashboard';
import { routes, colors, copyFileUrl } from 'src/utils/constant';
import {
  RightArrow,
  TrashIcon,
  DownloadIcon,
  LinkIcon,
  CheckCircleIcon
} from 'src/assets/Icons';
import TableList from 'src/components/TableList';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { NO_IMAGE, FILE_ICON, LOADER } from '../../assets';
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { setFamily } from 'src/redux/actions/familyActions';
import FileTableRow from 'src/components/FileTableRow';
import clsx from 'clsx';
import ScanningUploadFileModal from 'src/components/ScanningUploadFileModal';
import { formatFilename } from 'src/utils/functions';
import { MESSAGE_TYPES } from 'src/constants/common';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  siderDetail: {
    fontSize: 16,
    color: colors.primaryDarkGrey,
    marginBottom: 8,
    '&:hover': {
      color: '#8bc517'
    }
  },
  tabNavigations: {
    padding: '10px 20px 0',
    marginBottom: 15
  },
  navigationButton: {
    width: 120,
    textTransform: 'none'
  },
  actionButton: {
    margin: '15px 0 20px',
    textTransform: 'none'
  },
  activeTab: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 0
  },
  bredthcrumbTitle: {
    color: '#536e7b',
    fontSize: 16,
    marginRight: 24,
    display: 'inline-block',
    cursor: 'pointer'
  },
  tableRow: {
    height: '72px',
    '&:hover': {
      backgroundColor: `rgba(244, 245, 247, 0.51) !important`,
      cursor: 'pointer'
    }
  },
  dataTableCell: {
    color: colors.primaryDark,
    padding: 8,
    '&:first-child': {
      paddingLeft: '2.1%'
    }
  },
  fileImage: {
    width: 56,
    height: 56,
    borderRadius: 5,
    border: 'solid 1px ##bfc5d1',
    backgroundColor: colors.secondaryGreyLight
  },
  fileStatus: {
    fontSize: 14,
    backgroundColor: '#e8f5e9',
    color: '#348f37',
    padding: '0 5px',
    marginLeft: 36
  },
  dateText: {
    lineHeight: '45px',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.11,
    color: colors.primaryDark
  },
  fileName: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '45px',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: colors.primaryDark,
    '&:hover': {
      color: '#8bc517',
      '& .action-items': {
        opacity: 1
      }
    }
  },
  fileNameFormat: {
    width: '15%',
    overflowWrap: 'break-word'
  },
  actionButtons: {
    opacity: 0
  },
  actionIcons: {
    margin: '0 10px'
  },
  modalBox: {
    height: 500,
    outline: 0
  },
  imagePreview: {
    height: '100%',
    margin: 'auto',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%'
  },
  pdfPreview: {
    height: 450,
    position: 'relative',
    zIndex: 1500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${LOADER})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%'
  },
  topHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#000000',
    color: 'white',
    height: '10%',
    paddingLeft: '1.6%',
    paddingRight: '1.3%',
    marginBottom: '4.4%'
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  primaryIconButton: {
    backgroundColor: '#8bc517',
    borderRadius: 2,
    padding: 4,
    marginLeft: 16,
    '&:hover': {
      backgroundColor: '#8bc517'
    }
  },
  backArrowMargin: {
    marginLeft: 16
  },
  tableContainer: {
    marginLeft: '-20px',
    width: `calc(100% + 40px)`,
    '& .MuiTableContainer-root': {
      boxShadow: 'none'
    }
  },
  tableContainerTopBorder: {
    borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
  buttonRoot: {
    border: '1px solid white',
    paddingLeft: 20,
    paddingRight: 20
  },
  buttonLabel: {
    color: '#fff'
  },
  filterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 134,
    '& .MuiSelect-root': {
      paddingTop: 12,
      paddingBottom: 4
    }
  },
  iconStyle: {
    fontSize: 22,
    verticalAlign: 'middle'
  },
  buttonLabelText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.1
  },
  familySurnameStyle: {
    marginBottom: 20,
    marginTop: 22
  },
  iconWhite: {
    color: '#fff'
  }
}));

const columns = [
  {
    id: 'fileUrl',
    minWidth: 50,
    align: 'left',
    cellAttr: { width: window.location.pathname.match('/paid') ? 126 : 85 }
  },
  { id: 'fileName', minWidth: 170, align: 'left' },
  {
    id: 'uploadDate',
    minWidth: 170,
    align: window.location.pathname.match('/paid') ? 'center' : 'left',
    cellAttr: { width: 200 }
  }
];

const generateMonthOptionsForFilter = (files) => {
  const monthView = [];
  files.map((fileItem) => {
    const uploadedDate = dayjs.unix(fileItem.uploadDate).format('MMMM YYYY');
    const foundMonthIndex = _.findIndex(
      monthView,
      (month) => month.value === uploadedDate
    );
    if (foundMonthIndex > -1) {
      monthView[foundMonthIndex] = {
        label: uploadedDate,
        value: uploadedDate
      };
    } else {
      monthView.push({
        label: uploadedDate,
        value: uploadedDate
      });
    }
    return null;
  });
  return monthView;
};

const FilesView = ({ firebase }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentTimestamp = dayjs().unix();
  const [files, setFiles] = useState([]);
  const [state, setState] = useState({});
  const monthOptionsForFilter = generateMonthOptionsForFilter(files);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [activeRow, setActiveRowData] = useState({});
  const [isFileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCopyToChipboardSuccess, setCopyToChipboardSuccess] = useState(false);
  const [isDeleteFileSuccess, setDeleteFileSuccess] = useState(false);
  const [month, setMonth] = useState('');
  const [fileUploadModal, setFileUploadModal] = useState({
    isModalOpen: false,
    fileUploadState: 0
  });
  const currentFamily = useSelector((state) => state.family.currentFamily);

  const familyID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  useEffect(() => {
    if (!currentFamily.familySurname && familyID) {
      dispatch(loaderStart());
      firebase
        .getFamilyByID(familyID)
        .then(
          async (doc) => {
            let familyData = doc.data();
            if (familyData) {
              dispatch(setFamily(familyData));
            }
            dispatch(loaderStop());
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
            dispatch(loaderStop());
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    }
  }, [currentFamily, familyID, firebase, dispatch]);

  const { familySurname } = useMemo(() => {
    if (currentFamily) {
      return {
        familySurname: currentFamily.familySurname,
        id: currentFamily.id
      };
    }
    return {
      familySurname: '',
      id: ''
    };
  }, [currentFamily]);

  const getUploadedFiles = useCallback(() => {
    dispatch(loaderStart());
    firebase
      .getFiles(familyID)
      .then((querySnapshot) => {
        let FilesData = [];
        querySnapshot.forEach((doc) => {
          FilesData.push({ ...doc.data(), ...{ id: doc.id } });
        });
        setFiles(FilesData);
        setFilteredFiles(FilesData);
        dispatch(loaderStop());
        setDeleteFileSuccess(false);
        setFileUploadSuccess(false);
        setMonth('');
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
        dispatch(loaderStop());
      });
  }, [
    firebase,
    setFiles,
    dispatch,
    isFileUploadSuccess,
    isDeleteFileSuccess,
    familyID
  ]);

  useEffect(() => {
    getUploadedFiles();
  }, [getUploadedFiles]);

  const handleOnClose = () => {
    setFileUploadModal((prev) => ({ ...prev, isModalOpen: false }));
  };

  const onFileChange = (event) => {
    const fileLength = event.target.files.length;
    if (fileLength > 0) {
      const file = event.target.files[0];
      const fileType = file.name.split('.').pop().toLowerCase();
      event.target.value = ""
      if (isValidFileType(fileType)) {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 0 });
        const name =  file.name.substring(0, file.name.lastIndexOf('.'))
        const fileName = name + '_mywhanau_' + Date.now() + "." + fileType;
        firebase
          .uploadFile(file, familyID, fileName)
          .then((snapShot) => {
            //takes a snap shot of the process as it is happening
            if (snapShot && snapShot.state === 'success') {
              firebase
                .getDownloadURL(file, familyID, fileName)
                .then((fireBaseUrl) => {
                  const fileData = {
                    familyId: familyID,
                    fileHash: generateFileHash(),
                    fileName: fileName,
                    fileType: file.type,
                    fileUrl: fireBaseUrl,
                    uploadDate: currentTimestamp
                  };
                  firebase.addFile(fileData);
                  setFileUploadSuccess(true);
                  handleOnClose();
                })
                .catch((err) => {
                  //catches the errors
                  console.log('Error while Getting Download URL', err);
                  handleOnClose();
                });
            } else {
              setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
            }
          })
          .catch((err) => {
            //catches the errors
            console.log('Error while Uploading URL', err);
            setFileUploadModal({ isModalOpen: true, fileUploadState: 2 });
          });
      } else {
        setFileUploadModal({ isModalOpen: true, fileUploadState: 1 });
      }
    }
  };

  const handleMonthChange = (event) => {
    const selectedValue = event.target.value;
    setMonth(selectedValue);
    const selectedMonth = dayjs(selectedValue).format('MM');
    const selectedYear = dayjs(selectedValue).format('YYYY');
    let filteredFiles = files;
    if (!_.isEmpty(selectedValue)) {
      filteredFiles = filteredFiles.filter((e) => {
        const uploadedDate = e.uploadDate;
        const uploadedMonth = dayjs.unix(uploadedDate).format('MM');
        const uploadedYear = dayjs.unix(uploadedDate).format('YYYY');
        return selectedMonth === uploadedMonth && selectedYear === uploadedYear;
      });
      setFilteredFiles(filteredFiles);
    } else {
      setFilteredFiles(filteredFiles);
    }
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setFileUploadSuccess(false);
    setIsOpenModal(false);
  };

  const handleModalClose = () => {
    setActiveRowData('');
    setIsOpenModal(false);
  };

  const deleteFile = (fileName, docId) => {
    handleModalClose();
    firebase
      .deleteFileFromStorage(fileName, familyID)
      .then(() => {
        firebase
          .deleteFileDocument(docId)
          .then(() => {
            setDeleteFileSuccess(true);
          })
          .catch((error) => {
            console.error('Error removing document: ', error);
          });
      })
      .catch((error) => {
        if (error && error.code === 'storage/object-not-found') {
          firebase
            .deleteFileDocument(docId)
            .then(() => {
              setDeleteFileSuccess(true);
            })
            .catch((error) => {
              console.error('Error removing document: ', error);
            });
        }
        console.log('Error removing file', error);
      });
  };

  const downloadFile = (fileUrl, fileName) => {
    fetch(fileUrl).then(async (response) => {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const CheckCircleIconComponent = ({ iconColor }) => (
    <CheckCircleIcon
      viewBox="0 0 24 24"
      height={24}
      width={24}
      color={iconColor}
      className={classes.iconStyle}
    />
  );

  const DownloadIconComponent = ({ iconColor }) => (
    <DownloadIcon
      viewBox="0 0 24 24"
      height={24}
      width={24}
      color={iconColor}
      className={classes.iconStyle}
    />
  );

  const DeleteIconComponent = ({ iconColor }) => (
    <TrashIcon
      viewBox="0 0 24 24"
      height={24}
      width={24}
      color={iconColor}
      className={classes.iconStyle}
    />
  );

  const CopyToClipboardComponent = ({ text }) => (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setCopyToChipboardSuccess(true);
      }}
    >
      <Button
        variant="contained"
        component="span"
        size="small"
        color="primary"
        startIcon={
          <LinkIcon
            viewBox="0 0 24 24"
            height={24}
            width={30}
            color="#FFF"
            className={classes.iconStyle}
          />
        }
        style={{ borderRadius: 3, width: 169, height: 40 }}
      >
        <Typography className={classes.buttonLabelText}>
          Copy File URL
        </Typography>
      </Button>
    </CopyToClipboard>
  );

  const CellItem = ({ row, column }) => {
    const value = row[column.id];
    const classes = useStyles();
    if (column.id === 'fileName') {
      const currentData = dayjs.unix(currentTimestamp).format('YYYY/MM/DD');
      const uploadedDate = dayjs.unix(row['uploadDate']).format('YYYY/MM/DD');
      const fileUrl = row['fileHash'];
      return (
        <div className={classes.fileName}>
          <span className={classes.fileNameFormat}>
            {formatFilename(value)}
            {currentData === uploadedDate && (
              <span className={classes.fileStatus}>NEW</span>
            )}
          </span>
          {/* <span>{`${copyFileUrl}${fileUrl}`}</span> */}
          <span>
            {' '}
            <Link
              color="secondary"
              component={Link}
              onClick={() => window.open(`${copyFileUrl}${fileUrl}`, '_blank')}
              className={classes.siderDetail}
              style={{ textDecoration: 'none', cursor: 'pointer' }}
            >
              {`${copyFileUrl}${fileUrl}`}
            </Link>
          </span>
          <div className={[classes.actionButtons + ' action-items']}>
            {isCopyToChipboardSuccess ? (
              <Button
                component="span"
                size="small"
                variant="outlined"
                color="primary"
                disable="true"
                style={{ borderRadius: 3, width: 119, height: 40 }}
                startIcon={<CheckCircleIconComponent iconColor="#8bc517" />}
              >
                <Typography className={classes.buttonLabelText}>
                  Copied
                </Typography>
              </Button>
            ) : (
              <CopyToClipboardComponent
                text={`${copyFileUrl}${fileUrl}`}
                iconColor="#FFF"
              />
            )}
            <IconButton
              aria-label="download"
              className={classes.actionIcons}
              onClick={() => downloadFile(row['fileUrl'], value)}
            >
              <DownloadIconComponent />
            </IconButton>
            <IconButton
              aria-label="delete"
              className={classes.actionIcons}
              onClick={() => {
                deleteFile(value, row['id']);
              }}
            >
              <DeleteIconComponent />
            </IconButton>
          </div>
        </div>
      );
    }
    if (column.id === 'fileUrl') {
      let imageSource = '';
      if (!_.isEmpty(value)) {
        if (row['fileType'].indexOf('image/') !== -1) {
          imageSource = value;
        } else {
          imageSource = FILE_ICON;
        }
      } else {
        imageSource = NO_IMAGE;
      }

      return (
        <img
          alt={row['fileName']}
          src={(state['imageError' + row['id']] && NO_IMAGE) || imageSource}
          onError={() => {
            setState({
              ['imageError' + row['id']]: true
            });
          }}
          className={classes.fileImage}
          onClick={() => {
            setIsOpenModal(true);
            setActiveRowData(row);
            setCopyToChipboardSuccess(false);
          }}
        />
      );
    }

    if (column.id === 'uploadDate') {
      return (
        <span className={classes.dateText}>
          {dayjs.unix(value).format('YYYY/MM/DD')}
        </span>
      );
    }
    return '';
  };

  const TopHeader = () => {
    const activeFileUrl = _.get(activeRow, 'fileUrl', '');
    const activeFileId = _.get(activeRow, 'id', '');
    const activeFileUploadDate = _.get(activeRow, 'uploadDate', '');
    const activeFileName = _.get(activeRow, 'fileName', '');
    const activeFileType = _.get(activeRow, 'fileType', '');
    const activeFileHash = _.get(activeRow, 'fileHash', '');
    let imageSource = '';
    let fileTypePdfText = false;
    if (!_.isEmpty(activeFileUrl)) {
      if (activeFileType.indexOf('image/') !== -1) {
        imageSource = activeFileUrl;
      } else {
        fileTypePdfText = true;
      }
    }

    return (
      <>
        <div className={classes.topHeader}>
          <div className={classes.flex}>
            <IconButton
              aria-label="back button"
              onClick={() => setIsOpenModal(false)}
            >
              <ArrowBackIcon className={classes.iconWhite} />
            </IconButton>
            <span className={classes.backArrowMargin}>{activeFileName}</span>
          </div>
          <div>
            {isCopyToChipboardSuccess ? (
              <Button
                component="span"
                size="small"
                classes={{
                  root: classes.buttonRoot,
                  label: classes.buttonLabel
                }}
                style={{ borderRadius: 3, width: 119, height: 40 }}
                disable="true"
                startIcon={<CheckCircleIconComponent iconColor="#FFF" />}
              >
                <Typography className={classes.buttonLabelText}>
                  Copied
                </Typography>
              </Button>
            ) : (
              <CopyToClipboardComponent
                text={`${copyFileUrl}${activeFileHash}`}
              />
            )}
          </div>
          <div className={classes.flex}>
            {`Saved ${dayjs.unix(activeFileUploadDate).format('DD MMMM YYYY')}`}
            <IconButton
              className={classes.primaryIconButton}
              aria-label="download"
              onClick={() => downloadFile(activeFileUrl, activeFileName)}
            >
              <DownloadIconComponent iconColor="#FFF" />
            </IconButton>
            <IconButton
              className={classes.primaryIconButton}
              aria-label="delete"
              onClick={() => {
                deleteFile(activeFileName, activeFileId);
              }}
            >
              <DeleteIconComponent iconColor="#FFF" />
            </IconButton>
          </div>
        </div>
        {fileTypePdfText ? (
          <div className={classes.pdfPreview}>
            <iframe
              title="File Preview"
              src={`${activeFileUrl}#toolbar=0`}
              width="50%"
              height="450"
            ></iframe>
          </div>
        ) : (
          <div
            className={classes.imagePreview}
            style={{ backgroundImage: `url(${imageSource})` }}
          />
        )}
      </>
    );
  };

  const TableRowItem = ({ row, columns }) => {
    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        className={classes.tableRow}
        onMouseLeave={(e) => {
          if (e.target.tagName.toLowerCase() === 'td') {
            setCopyToChipboardSuccess(false);
          }
        }}
      >
        {columns.map((column) => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              className={classes.dataTableCell}
              {...column.cellAttr}
            >
              <CellItem row={row} column={column} />
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <>
      <Dashboard>
        <div className={classes.root}>
          <div>
            <Typography
              variant="body1"
              className={classes.bredthcrumbTitle}
              onClick={() =>
                window.location.pathname.match('/new')
                  ? history && history.push(routes.familiesFreeNew)
                  : window.location.pathname.match('/pending')
                  ? history && history.push(routes.familiesFreePending)
                  : window.location.pathname.match('/paid')
                  ? history && history.push(routes.familiesPaid)
                  : history && history.push(routes.familiesFree)
              }
            >
              Families
            </Typography>
            <RightArrow
              viewBox="0 0 24 24"
              height={24}
              width={24}
              className={classes.iconStyle}
            />
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.familySurnameStyle}
          >
            {familySurname}
          </Typography>
          <Paper className={classes.tabNavigations}>
            <div>
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyCalendar + '/' + familyID)
                    : history.push(routes.familyCalendarPaid + '/' + familyID);
                }}
              >
                Calendar
              </Button>
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyFiles + '/' + familyID)
                    : history.push(routes.familyFilesPaid + '/' + familyID);
                }}
                className={classes.activeTab}
              >
                Files
              </Button>
              {currentFamily.planType !== 'Free' && (
                <Button
                  classes={{ root: classes.navigationButton }}
                  onClick={() => {
                    currentFamily.planType === 'Free'
                      ? history.push(routes.familyNotes + '/' + familyID)
                      : history.push(routes.familyNotesPaid + '/' + familyID);
                  }}
                >
                  Notes
                </Button>
              )}
              {currentFamily.planType !== 'Free' && (
                <Button
                  classes={{ root: classes.navigationButton }}
                  onClick={() => {
                    currentFamily.planType === 'Free'
                      ? history.push(routes.familyLinks + '/' + familyID)
                      : history.push(routes.familyLinksPaid + '/' + familyID);
                  }}
                >
                  Links
                </Button>
              )}
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyUsers + '/' + familyID)
                    : history.push(routes.familyUsersPaid + '/' + familyID);
                }}
              >
                Family
              </Button>
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyAccount + '/' + familyID)
                    : history.push(routes.familyAccountPaid + '/' + familyID);
                }}
              >
                Account
              </Button>
            </div>
            <Divider />
            <div className={classes.filterWrapper}>
              <label htmlFor="upload-new-file">
                <TextField
                  style={{ display: 'none' }}
                  id="upload-new-file"
                  name="upload-new-file"
                  type="file"
                  onChange={(e) => onFileChange(e)}
                />
                <Button
                  variant="contained"
                  component="span"
                  size="small"
                  color="primary"
                  className={classes.actionButton}
                  startIcon={<AddIcon />}
                >
                  Upload New File
                </Button>
              </label>
              <div className={classes.flex}>
                <span>Month</span>
                <FormControl
                  variant="outlined"
                  color="secondary"
                  className={classes.formControl}
                >
                  <Select
                    labelId="filter"
                    id="filter"
                    displayEmpty
                    notched={false}
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={month}
                    onChange={handleMonthChange}
                    label="FilterMonth"
                  >
                    <MenuItem value="">Select Month</MenuItem>
                    {!_.isEmpty(monthOptionsForFilter) &&
                      monthOptionsForFilter.map((monthValues, key) => {
                        return (
                          <MenuItem key={key} value={monthValues.value}>
                            {monthValues.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div
              className={clsx(classes.tableContainer, {
                [classes.tableContainerTopBorder]: !_.isEmpty(filteredFiles)
              })}
            >
              {!_.isEmpty(filteredFiles) && (
                <TableList
                  columns={columns}
                  rows={filteredFiles}
                  disableSearch
                  disableColumnLabel
                  tableRow={(row, index) =>
                    window.location.pathname.match('/paid') ? (
                      <TableRowItem key={index} columns={columns} row={row} />
                    ) : (
                      <FileTableRow
                        key={index}
                        columns={columns}
                        row={row}
                        deleteFile={deleteFile}
                        isCopyToChipboardSuccess={isCopyToChipboardSuccess}
                        setActiveRowData={setActiveRowData}
                        setCopyToChipboardSuccess={setCopyToChipboardSuccess}
                        setIsOpenModal={setIsOpenModal}
                        getFiles={getUploadedFiles}
                      />
                    )
                  }
                />
              )}
            </div>
          </Paper>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={isFileUploadSuccess}
          autoHideDuration={3000}
          onClose={handleSnackBarClose}
        >
          <SnackbarContent
            style={{
              backgroundColor: colors.primaryDarkGrey
            }}
            message="File Uploaded Successfully"
            action={
              <>
                <IconButton
                  size="small"
                  aria-label="close"
                  onClick={handleSnackBarClose}
                >
                  <CloseIcon fontSize="small" className={classes.iconWhite} />
                </IconButton>
              </>
            }
          />
        </Snackbar>
      </Dashboard>
      <Modal open={isOpenModal} onClose={handleModalClose}>
        <div className={classes.modalBox}>
          <TopHeader />
        </div>
      </Modal>
      <ScanningUploadFileModal
        handleOnClose={handleOnClose}
        isOpen={fileUploadModal.isModalOpen}
        fileUploadState={fileUploadModal.fileUploadState}
      />
    </>
  );
};

export default withFirebase(FilesView);
