import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import {
  generateFileHash,
  setSchoolFileName,
  formatFreeFamily,
  emailToApprovedFamily,
  encryptSHA256,
  getGroupYears,
  getGroupMembers,
  updateYearAndMemForRepeatEntries,
  generateUserEmail,
  sleep
} from '../functions';
import { freeFamilyChildStatus, firestoreLimit } from '../constant';
const { REACT_APP_FIREBASE_API_KEY, REACT_APP_FIREBASE_AUTH_DOMAIN, REACT_APP_FIREBASE_DB_URL, REACT_APP_PROJECT, REACT_APP_FIREBASE_STORAGE_BUCKET, REACT_APP_FIREBASE_MSG_SENDER_ID, REACT_APP_FIREBASE_APP_ID, REACT_APP_API_BASE_URL } = process.env;

const config = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DB_URL,
  projectId: REACT_APP_PROJECT,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage().ref();
    this.unscannedBucket = app.app().storage(`gs://unscanned-${config.projectId}`).ref();
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  // For Agent role after password reset
  setNewPassword = async ({ email, newPassword }) => {
    console.log(email, newPassword)
    try {
      if (!email) { throw new Error('email is required'); }
      if (!newPassword) { throw new Error('newPassword is required'); }
      const userSnap = await this.db.collection('users').where('email', '==', email).get();
      if (userSnap.size > 0) {
        const ref = userSnap.docs[0].ref;
        const userData = userSnap.docs[0].data();
        if (userData && ['Agent', 'Manager', 'Admin'].includes(userData.role)) {
          let allEncryptedPSW;
          const encryptedPassword = encryptSHA256(newPassword);

          if (userData.prevPasswords) {
            if (userData.prevPasswords.includes(encryptedPassword)) {
              throw new Error('new password must not be one of the old 5 passwords');
            } else {
              allEncryptedPSW = (userData.prevPasswords.length == 5) ?
              // Remove first password and append new one in array
              [..._.drop(userData.prevPasswords), encryptedPassword] :
              [...userData.prevPasswords, encryptedPassword]
            }
          } else {
            allEncryptedPSW = [encryptedPassword];
          }
          // Update user record with passwordExpireOn,encryptedPassword,prevPasswords
          const passwordExpireOn = moment.utc().add(60, 'days').format()
          await ref.update({
            passwordExpireOn,
            encryptedPassword,
            prevPasswords: allEncryptedPSW,
          });
        }
      }
      return null;
    } catch (error) {
      const  message = error && error.message ? error.message : 'Something went wrong';
      throw new Error(message);
    }
  };

  isPasswordExpired = async ({ userId }) => {
    try {
      if (!userId) { throw new Error('userId is required'); }

      let isPasswordExpired = false;
      const userSnap = await this.db.collection('users').doc(userId).get();
      const userData = userSnap.data();
      if (userData && userData.passwordExpireOn) {
        const todaysDate = moment.utc();
        if(moment(todaysDate).isAfter(userData.passwordExpireOn)){
          isPasswordExpired = true;
        }
      }
      return isPasswordExpired;
    } catch (error) {
      const  message = error && error.message ? error.message : 'Something went wrong';
      throw new Error(message);
    }
  };

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);
  getCurrentUser = () => this.auth.currentUser;
  doOnAuthStateChanged = (callback) => this.auth.onAuthStateChanged(callback);

  // DB reference
  getDBBatch = () => this.db.batch();

  // User functions
  getUsers = () => this.db.collection('users').get();
  getUserByID = (id) => this.db.collection('users').doc(id).get();
  addUser = (userData, uid) =>
    this.db.collection('users').doc(uid).set(userData);

  getFileUrl = async (path) => {
    try {
      const url = await this.storage.child(path).getDownloadURL();
      return Promise.resolve(url);
    } catch (error) {
      if (error.code === 'storage/object-not-found') {
        return Promise.resolve(null);
      } else {
        return Promise.reject(error);
      }
    }
  }

  isFileInfected = async (path) => {
    try {
      let downloadURL = null;
      let retryCount = 0;
      while (!downloadURL && retryCount < 5) {
        await sleep(2000);
        downloadURL = await this.getFileUrl(path);
        retryCount++;
      }
      return !downloadURL;
    } catch (error) {
      const message = error && error.message ? error.message : 'Something went wrong';
      throw new Error(message);
    }
  }
  uploadProfilePicture = async (file, familyID) => {
    try {
      const filePath = `/userProfiles/${familyID}/${file.name}`;
      const snapShot = await this.unscannedBucket.child(filePath).put(file);
      const isInfected = await this.isFileInfected(filePath);
      return (!isInfected)? snapShot : null;
    } catch (error) {
      const message = error && error.message ? error.message : 'Something went wrong';
      throw new Error(message);
    }
  };

  getDownloadURLForProfileImage = (file, familyID) =>
    this.storage
      .child(`/userProfiles/${familyID}/` + file.name)
      .getDownloadURL();
  deleteProfileImageFromStorage = (storagePath) =>
    this.storage.child(storagePath).delete();
  deleteUser = (id) => this.db.collection('users').doc(id).delete();
  isUserExists = (email) =>
    this.db.collection('users').where('email', '==', email).limit(1).get();
  isPrimaryEmailExist = (email) =>
    this.db.collection('families').where('primaryEmail', '==', _.trim(_.toLower(email))).get();

  // School form tab functions
  uploadSchoolImage = async (file, schoolId, type) => {
    try {
      const filePath = `/schools/${setSchoolFileName(file, schoolId, type)}`;
      const snapShot = await this.unscannedBucket.child(filePath).put(file);
      const isInfected = await this.isFileInfected(filePath);
      return (!isInfected)? snapShot : null;
    } catch (error) {
      const message = error && error.message ? error.message : 'Something went wrong';
      throw new Error(message);
    }
  };

  getDownloadURLForSchoolFile = (file, schoolId, type) =>
    this.storage
      .child(`/schools/${setSchoolFileName(file, schoolId, type)}`)
      .getDownloadURL();
  deleteSchoolFileFromStorage = (storagePath) =>
    this.storage.child(storagePath).delete();
  // addSchoolFormDetails = (schoolId, formData = null) =>
  //   this.db
  //     .collection('schools')
  //     .doc(schoolId)
  //     .get()
  //     .then((schoolData) => {
  //       const updatedData = {
  //         ...schoolData.data(),
  //         formData,
  //         formUrl: formData?.urlPath || null,
  //         isPublished: !!formData?.isPublished
  //       };
  //       updatedData.categories = updatedData?.categories.map((category) => {
  //         const cData = formData?.category?.find((c) => c.id === category.id);
  //         const updatedCategory = {
  //           ...category,
  //           isDisplay: !!cData?.isDisplay
  //         };
  //         return updatedCategory;
  //       });
  //       return schoolData.ref.set(updatedData);
  //     });
  // isUrlAvailable = (url, schoolId) => {
  //   return this.db
  //     .collection('schools')
  //     .where('formUrl', '==', url)
  //     .get()
  //     .then((school) => {
  //       if (!school.docs.length) return true;
  //       else if (
  //         school?.docs.filter((data) => data.data().id === schoolId).length
  //       )
  //         return true;
  //       else return false;
  //     })
  //     .catch(function (error) {
  //       console.log('Error: ', error);
  //       return error;
  //     });
  // };

  // Families functions
  getPaidFamilies = () =>
    this.db
      .collection('families')
      .where('planType', 'in', [
        'Paid 1-Child',
        'Paid 2-Child',
        'Paid 3-Child',
        'Paid 4-Child'
      ])
      .get();
  getFamilies = () =>
    this.db.collection('families').orderBy('familySurname').get();
  getFamilyByID = (id) => this.db.collection('families').doc(id).get();
  setFamily = async (familyData, accountStatus = freeFamilyChildStatus.NEW) => {
    try {
      let formatedFamily = { ...familyData };
      // Check primary email is unique or not
      const familiesSnap = await this.isPrimaryEmailExist(formatedFamily.primaryEmail);
      if (familiesSnap.size > 0) {
        throw new Error('Primary email is already in use by another account');
      }
      // Check username is unique or not
      if (formatedFamily.userName) {
        const authEmail = generateUserEmail(formatedFamily.userName);
        const userSnap = await this.isUserExists(authEmail);
        if (userSnap.size > 0) {
          throw new Error('Username is already in use by another account');
        }
      }
      if (familyData.planType === 'Free') {
        formatedFamily = formatFreeFamily(familyData, accountStatus);
      }else {
        formatedFamily = familyData;
      }
      formatedFamily = { ...formatedFamily, primaryEmail: _.toLower(formatedFamily.primaryEmail) };
      // Set account approved date
      if (accountStatus === freeFamilyChildStatus.INVITED) {
        formatedFamily.accountApprovedDate = moment.utc().format();
      }
      let promise = new Promise((resolve, reject) => {
        this.db
          .collection('families')
          .add(formatedFamily)
          .then(async(docRef) => {
            docRef.update({ id: docRef.id });
            if (accountStatus === freeFamilyChildStatus.INVITED && formatedFamily.childs.length > 0) {
              const schoolData = await axios.get(`${REACT_APP_API_BASE_URL}/admin/school/${formatedFamily.childs[0].school}`);
              await emailToApprovedFamily(formatedFamily, schoolData.data.data.schoolName);
            }
            // this.db.collection('entries').add({
            //   entries: [],
            //   familyId: docRef.id
            // });
            resolve(docRef);
          });
      });
      return promise;
    }  catch (error) {
      const message = error && error.message ? error.message : 'Something went wrong';
      throw new Error(message);
    }
  };
  updateFamily = async(familyData, id, accountStatus = freeFamilyChildStatus.ACTIVE) => {
    try {
      let formatedFamily = { ...familyData };
      // Check primary email is unique or not
      const familiesSnap = await this.isPrimaryEmailExist(formatedFamily.primaryEmail);
      if (familiesSnap.size > 1 || (familiesSnap.size == 1 && familiesSnap.docs[0].id !== id)) {
        throw new Error('Primary email is already in use by another account');
      }
      // Check username is unique or not
      const authEmail = generateUserEmail(formatedFamily.userName);
      if (formatedFamily.userName) {
        const userSnap = await this.isUserExists(authEmail);
        if (userSnap.size == 1 && userSnap.docs[0].data().family !== id) {
          throw new Error('Username is already in use by another account');
        }
      }

      if (familyData.planType === 'Free') {
        formatedFamily = formatFreeFamily(familyData, accountStatus);
      }
      formatedFamily = { ...formatedFamily, primaryEmail: _.toLower(formatedFamily.primaryEmail) };
      // Update email and userName in user table
      if (formatedFamily.userName) {
        const userQuerySnapshot = await this.db.collection('users').where('family', '==', id).get();
        const user = userQuerySnapshot.docs[0];
        if (user?.data()?.userName && user.data().userName !== formatedFamily.userName) {
          await user.ref.update({
            email: authEmail,
            userName: formatedFamily.userName,
          });
        }
      }
      return this.db.collection('families').doc(id).set(formatedFamily);
    } catch (error) {
      const message = error && error.message ? error.message : 'Something went wrong';
      throw new Error(message);
    }
  }
  //only child schools
  getFamilyBySchoolId = (id) =>
    this.db
      .collection('families')
      .where('childsSchools', 'array-contains', id)
      .get();
  // all family associated with this id
  getFamilyBySchoolIdCalendarHost = (id) =>
    this.db
      .collection('families')
      .orderBy('familySurname')
      .where('schoolIds', 'array-contains', id)
      .get();
  getFreeFamiliesByStatus = (status = 'Active') => {
    let statusToFilter = [freeFamilyChildStatus.ACTIVE];
    if (status === 'Pending')
      statusToFilter = [
        freeFamilyChildStatus.INVITED,
        freeFamilyChildStatus.REMIND1,
        freeFamilyChildStatus.REMIND2,
        freeFamilyChildStatus.OPEN,
        freeFamilyChildStatus.OPEN_REMIND
      ];
    if (status === 'New') statusToFilter = [freeFamilyChildStatus.NEW];
    return this.db
      .collection('families')
      .where('planType', '==', 'Free')
      .where('accountStatus', 'array-contains-any', statusToFilter)
      .get();
  };
  approveFamiliesById = (familyIds, status = 'Invited') => {
    const arrayOfArray = new Array(Math.ceil(familyIds.length / 10))
      .fill()
      .map(() => familyIds.splice(0, 10));
    const promises = Promise.all(
      arrayOfArray.map(async (fIds) => {
        const querySnapshot = await this.db.collection('families').where('id', 'in', fIds).get();
        const batch = app.firestore().batch();
        await Promise.all(querySnapshot.docs.map(async (doc) => {
          const childs = doc.data().childs.map((child) => {
            let updatedChild = { ...child };
            if (child.status === 'New') {
              updatedChild = {
                ...child,
                status,
                statusUpdatedAt: moment.utc().format()
              }
            }
            return updatedChild;
          });
          try {
            const schoolData = await axios.get(`${REACT_APP_API_BASE_URL}/admin/school/${doc.data().schoolIds[0]}`);
            await emailToApprovedFamily(doc.data(), schoolData.data.data.schoolName);
            const accountStatus = childs.map(child => child.status);
            batch.update(doc.ref, {
              childs,
              accountStatus,
              status,
              statusUpdatedAt: moment.utc().format(),
              accountApprovedDate: moment.utc().format()
            });
          } catch (err) {
            console.log('Error:', err.message);
            throw new Error(err.message);
          }
          return null;
        }));
        return batch.commit();
      })
    )
    return promises;
  }
  deleteNonActiveFamiliesById = (familyIds) => {
    const arrayOfArray = new Array(Math.ceil(familyIds.length / 10))
      .fill()
      .map(() => familyIds.splice(0, 10));
    const promises = Promise.all(
      arrayOfArray.map((fIds) => {
        return this.db
          .collection('families')
          .where('id', 'in', fIds)
          .get()
          .then(function (querySnapshot) {
            const nonActiveFamilies = querySnapshot.docs.filter(family => !family.data().accountStatus.includes('Active'));
            var batch = app.firestore().batch();
            nonActiveFamilies.forEach(family => {
              batch.delete(family.ref);
            });
            return batch.commit();
          })
          .catch(function (error) {
            console.log('Error: ', error);
            return error;
          });
      })
    );
    return promises;
  }
  deleteFamily = (id) => this.db.collection('families').doc(id).delete();

  // Files fuctions
  getSchoolEntriesFiles = (schoolId) =>
    this.db
      .collection('files')
      .where('schoolId', '==', schoolId)
      .orderBy('uploadDate', 'desc')
      .get();
  getFiles = (familyId) =>
    this.db
      .collection('files')
      .where('familyId', '==', familyId)
      .orderBy('uploadDate', 'desc')
      .get();
  addFile = (fileData) => this.db.collection('files').add(fileData);
  updateFile = (fileData, id) => this.db.collection('files').doc(id).set(fileData);
  deleteFileDocument = (id) => this.db.collection('files').doc(id).delete();

  uploadFile = async (file, familyID, fileName) => {
    try {
      const filePath = `/userFiles/${familyID}/${fileName}`;
      const snapShot = await this.unscannedBucket.child(filePath).put(file);
      const isInfected = await this.isFileInfected(filePath);
      return (!isInfected)? snapShot : null;
    } catch (error) {
      const message = error && error.message ? error.message : 'Something went wrong';
      throw new Error(message);
    }
  };

  getDownloadURL = (file, familyID, fileName) =>
    this.storage.child(`/userFiles/${familyID}/` + fileName).getDownloadURL();
  deleteFileFromStorage = (fileName, familyID) =>
    this.storage.child(`/userFiles/${familyID}/` + fileName).delete();
  // Storage functions for school file
  uploadSchoolEntriesFile = async (file, schoolId, fileName) => {
    try {
      const filePath = `/schoolFiles/${schoolId}/${fileName}`;
      const snapShot = await this.unscannedBucket.child(filePath).put(file);
      const isInfected = await this.isFileInfected(filePath);
      return (!isInfected)? snapShot : null;
    } catch (error) {
      const message = error && error.message ? error.message : 'Something went wrong';
      throw new Error(message);
    }
  };
  getDownloadURLForSchoolEntriesFile = (schoolId, fileName) =>
    this.storage.child(`/schoolFiles/${schoolId}/${fileName}`).getDownloadURL();
  getDownloadURLForSchoolReport = (schoolName) =>
    this.storage.child(`/studentReport/${schoolName}.xlsx`).getDownloadURL();
  deleteSchoolEntriesFile = (schoolId, fileName) =>
    this.storage.child(`/schoolFiles/${schoolId}/${fileName}`).delete();

  // Schools functions
  // getFreeSchools = () =>
  //   this.db
  //     .collection('schools')
  //     .where('freeCalendars', 'in', [
  //       '0',
  //       '1',
  //       '2',
  //       '3',
  //       '4',
  //       '5',
  //       '6',
  //       '7',
  //       '8',
  //       '9',
  //       '10',
  //       '11',
  //       '12',
  //       '13'
  //     ])
  //     .orderBy('schoolName')
  //     .get();
  // getSchools = () => this.db.collection('schools').orderBy('schoolName').get();
  // getSchoolByID = (id) => this.db.collection('schools').doc(id).get();
  // addSchool = (schoolData) => {
  //   let promise = new Promise((resolve, reject) => {
  //     this.db
  //       .collection('schools')
  //       // adding default 4 categories to all new schools
  //       .add({
  //         ...schoolData,
  //         categories: [
  //           { id: generateFileHash(20), name: 'Class' },
  //           { id: generateFileHash(20), name: 'Co-Curricular' },
  //           { id: generateFileHash(20), name: 'Staff' },
  //           { id: generateFileHash(20), name: 'Subjects' }
  //         ]
  //       })
  //       .then((docRef) => {
  //         docRef.update({ id: docRef.id });
  //         this.db.collection('schoolEntries').add({
  //           entries: [],
  //           schoolId: docRef.id
  //         });
  //         resolve(docRef);
  //       });
  //   });
  //   return promise;
  // };
  // updateSchool = (schoolData, id) =>
  //   this.db.collection('schools').doc(id).set(schoolData);
  // deleteSchool = async (id) => {
  //   try {
  //     let school = this.db.collection('schools').doc(id).get();
  //     let groups = this.db.collection('groups').where('school', '==', id).get();
  //     let schoolDataEntries = this.db.collection('schooldataentries').where('schoolId', '==', id).get();
  //     [school, groups, schoolDataEntries] = [await school, await groups, await schoolDataEntries];
  //     const schoolRelatedRecords = [school, ...groups.docs, ...schoolDataEntries.docs];
  //     // Delete school and it's related records
  //     const allSchoolRecords = new Array(Math.ceil(schoolRelatedRecords.length / 500)).fill().map(() => schoolRelatedRecords.splice(0, 500));
  //     await Promise.all(
  //       allSchoolRecords.map(async data => {
  //         const batch = app.firestore().batch();
  //         data.forEach((doc) => {
  //           batch.delete(doc.ref);
  //         });
  //         await batch.commit();
  //       })
  //     )
  //     // Delete files
  //     if (school.data().formData) {
  //       const { schoolLogo, schoolSupportPerson } = school.data().formData;
  //       if (schoolLogo && schoolLogo.storage_path) {
  //         await this.storage.child(schoolLogo.storage_path).delete();
  //       }
  //       if (schoolSupportPerson && schoolSupportPerson.storage_path) {
  //         const fileURL = schoolSupportPerson.storage_path;
  //         const fileName = _.get(fileURL.match(/\/([^/]+)\.[^.]+$/), '[1]', '');
  //         if (fileName !== 'Default-supportPerson') {
  //           await this.storage.child(schoolSupportPerson.storage_path).delete();
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     const message = error && error.message ? error.message : 'Something went wrong';
  //     throw new Error(message);
  //   }
  // }

  // deleteChildRelatedData = async (childId) => {
  //   try {
  //     let groups = this.db.collection('groups').where('groupMembers', 'array-contains', childId).get();
  //     let schoolDataEntries = this.db.collection('schooldataentries').where('groupMembers', 'array-contains', childId).get();

  //     [groups, schoolDataEntries] = [await groups, await schoolDataEntries];
  //     const childRelatedRecords = [...groups.docs, ...schoolDataEntries.docs];
  //     const batchedRecords = _.chunk(childRelatedRecords, 500);
  //     return await Promise.all(
  //       batchedRecords.map(async (data) => {
  //         const batch = app.firestore().batch();
  //         data.forEach((doc) => {
  //           const updatedGroupMembers = _.pull(doc.data().groupMembers, childId);
  //           batch.update(doc.ref, { groupMembers: updatedGroupMembers });
  //         });
  //         await batch.commit();
  //       })
  //     );
  //   } catch (error) {
  //     const message = error && error.message ? error.message : "Something went wrong";
  //     throw new Error(message);
  //   }
  // };

  // agents functions
  getAgents = () =>
    this.db
      .collection('users')
      .where('role', 'in', ['Agent', 'Manager'])
      .orderBy('firstName')
      .get();
  addAgent = (agentData) => this.db.collection('users').add(agentData);
  updateAgent = (agentData, id) =>
    this.db.collection('users').doc(id).update(agentData);
  deleteAgent = (id) => this.db.collection('users').doc(id).delete();

  uploadAgentsProfilePicture = async (file) => {
    try {
      const filePath = `/userProfiles/agents/${file.name}`;
      const snapShot = await this.unscannedBucket.child(filePath).put(file);
      const isInfected = await this.isFileInfected(filePath);
      return (!isInfected)? snapShot : null;
    } catch (error) {
      const message = error && error.message ? error.message : 'Something went wrong';
      throw new Error(message);
    }
  };

  getDownloadURLForAgentsProfileImage = (file) =>
    this.storage.child(`/userProfiles/agents/` + file.name).getDownloadURL();
  deleteAgentsProfileImageFromStorage = (storagePath) =>
    this.storage.child(storagePath).delete();
  // getGroupBySchoolId = (id) =>
  //   this.db.collection('groups').where('school', '==', id).get();

  // groups functions
  // getGroup = () => this.db.collection('groups').get();
  // addGroup = (groupData) => this.db.collection('groups').add(groupData);

  // updateGroup = async (groupData, id) => {
  //   try {
  //     const prevGroupDataSnap = await this.db.collection('groups').doc(id).get();
  //     const prevGroupData = prevGroupDataSnap.data();
  //     // Update group
  //     const updatedGroup = await this.db.collection('groups').doc(id).set(groupData);
  //     // Update Entries
  //     const updateYear = !(_.isEqual(prevGroupData.years, groupData.years));
  //     const updateMember = !(_.isEqual(prevGroupData.groupMembers, groupData.groupMembers));

  //     if (groupData.groupType === 'Free' && (updateYear || updateMember)) {
  //       const schoolDataentries = await this.db.collection('schooldataentries').where('groups', 'array-contains', id).get();
  //       // Find all groups from entry and repeat entries
  //       const allEntryGroups = _.compact(_.uniq(_.flatten(schoolDataentries.docs.map(entry => {
  //         const entryData = entry.data();
  //         let groups = []; // groups of repeatEntries
  //         if (entryData.repeatEntries && entryData.repeatEntries.length) {
  //           groups = entryData.repeatEntries.map(repeatEntry => {
  //             return repeatEntry.groups ? repeatEntry.groups : []
  //           })
  //         }
  //         groups = _.flatten(groups);
  //         return _.flatten([groups, entry.data().groups]);
  //       }))))
  //       let allGroupData = await Promise.all(
  //         // Split all groups Ids into given array size.
  //         _.chunk(allEntryGroups, firestoreLimit.inQuery).map((groups) => {
  //           return this.db.collection('groups').where('id', 'in', groups).get();
  //         })
  //       )
  //       allGroupData = _.flatten(allGroupData.map(data => data.docs))
  //       await Promise.allSettled(
  //         _.chunk(schoolDataentries.docs, firestoreLimit.batchQuery).map(entryDoc => {
  //           const batch = app.firestore().batch();
  //           entryDoc.map(entry => {
  //             const entryGroups = allGroupData.filter(group => entry.data().groups.includes(group.data().id))
  //             const updatedYears = getGroupYears({ groups: entryGroups })
  //             const updatedMembers = getGroupMembers({ groups: entryGroups })
  //             // Repeat entries
  //             let updatedRepeatEntries = null;
  //             if (entry.data().repeatEntries && entry.data().repeatEntries.length) {
  //               updatedRepeatEntries = updateYearAndMemForRepeatEntries({
  //                 repeatEntries: entry.data().repeatEntries,
  //                 entryData: { groups: entry.data().groups, forYears: updatedYears, groupMembers: updatedMembers },
  //                 allGroupData
  //               });
  //             }
  //             const updatedEntryData = {
  //               forYears: updatedYears,
  //               groupMembers: updatedMembers
  //             }
  //             if (updatedRepeatEntries) {
  //               updatedEntryData.repeatEntries = updatedRepeatEntries;
  //             }
  //             batch.update(entry.ref, updatedEntryData);
  //           })
  //           return batch.commit();
  //         })
  //       )
  //     }
  //     return updatedGroup;
  //   } catch (error) {
  //     const message = error && error.message ? error.message : 'Something went wrong';
  //     throw new Error(message);
  //   }
  // }

  // updateGroupsByFamilyId = async ({ familyId, groupIds }) => {
  //   const promises = Promise.all(
  //     groupIds.map((group) => {
  //       return this.db
  //         .collection('groups')
  //         .doc(group.id)
  //         .update({
  //           groupMembers: group.isAdd
  //             ? app.firestore.FieldValue.arrayUnion(familyId)
  //             : app.firestore.FieldValue.arrayRemove(familyId)
  //         });
  //     })
  //   );
  //   return promises;
  // };
  updateFamilyByGroupId = async ({ groupId, families }) => {
    const allFamilies = families.filter((family) => !!family.familyId);
    const arrayOfArray = new Array(Math.ceil(allFamilies.length / 10))
      .fill()
      .map(() => allFamilies.splice(0, 10));
    const promises = Promise.all(
      arrayOfArray.map((familyArray) => {
        if (_.compact(_.map(familyArray, 'familyId')).length) {
          return this.db
            .collection('families')
            .where('id', 'in', _.compact(_.map(familyArray, 'familyId')))
            .get()
            .then(function (querySnapshot) {
              var batch = app.firestore().batch();
              querySnapshot.docs.forEach(function (doc) {
                const childs = doc.data().childs.map((child) => {
                  const updateData = _.find(
                    familyArray,
                    (family) => family.childId == child.id
                  );
                  if (updateData) {
                    if (updateData.isAdd) {
                      child.groups = !child.groups.length
                        ? [groupId]
                        : _.uniq([...child.groups, groupId]);
                    } else {
                      if (child.groups != '' && child.groups.length) {
                        child.groups = _.filter(
                          child.groups,
                          (group) => group != groupId
                        );
                      }
                      if (child.group === groupId) child.group = '';
                    }
                  }
                  return child;
                });
                batch.update(doc.ref, { childs });
              });
              return batch.commit();
            })
            .catch(function (error) {
              console.log('Error: ', error);
              return error;
            });
        }
        return false;
      })
    );
    return promises;
  };

  // deleteGroup = (id) => this.db.collection('groups').doc(id).delete();

  // Category functions
  // getGroupsByCategory = (schoolId, categoryIds) => {
  //   if (categoryIds.length) {
  //     return this.db
  //       .collection('groups')
  //       .where('school', '==', schoolId)
  //       .where('category', 'in', categoryIds)
  //       .get();
  //   } else {
  //     return this.db.collection('groups').where('school', '==', schoolId).get();
  //   }
  // };

  // getCategoryNameForGroups = (groups) =>
  //   this.db
  //     .collection('schools')
  //     .where(
  //       'id',
  //       'in',
  //       groups.map((group) => group.school)
  //     )
  //     .get()
  //     .then((querySnapshot) => {
  //       const updatedGroups = groups.map((group) => {
  //         const school = querySnapshot.docs.find(
  //           (school) => school.data().id === group.school
  //         );
  //         const category =
  //           school && school.data().categories
  //             ? school
  //                 .data()
  //                 .categories.find((category) => category.id === group.category)
  //             : null;
  //         const updatedGroup = {
  //           ...group,
  //           categoryName: category ? category.name : null
  //         };
  //         return updatedGroup;
  //       });
  //       return updatedGroups;
  //     })
  //     .catch(function (error) {
  //       console.log('Error: ', error);
  //     });

  // Entries
  updateSpecificEntry = (data) => {
    let promise = new Promise((resolve, reject) => {
      const ref = this.db.collection('entries').doc(data.docId);
      ref
        .get()
        .then(function (querySnapshot) {
          const querydata = querySnapshot.data().entries.map((entry) => {
            if (entry.id === data.entry.id) {
              entry = data.entry;
            }
            return entry;
          });

          let batch = app.firestore().batch();

          batch.update(ref, {
            entries: querydata
          });
          batch
            .commit()
            .then(() => {
              console.log('Success!');
              resolve();
            })
            .catch((err) => console.error('Failed!', err));
        })
        .catch(function (error) {
          console.log('Transaction failed: ', error);
        });
    });
    return promise;
  };

  updateSpecificEntrySchool = (data) => {
    let promise = new Promise((resolve, reject) => {
      const ref = this.db.collection('schoolEntries').doc(data.docId);
      ref
        .get()
        .then(function (querySnapshot) {
          const querydata = querySnapshot.data().entries.map((entry) => {
            if (entry.id === data.entry.id) {
              entry = data.entry;
            }
            return entry;
          });

          let batch = app.firestore().batch();

          batch.update(ref, {
            entries: querydata
          });
          batch
            .commit()
            .then(() => {
              console.log('Success!');
              resolve();
            })
            .catch((err) => console.error('Failed!', err));
        })
        .catch(function (error) {
          console.log('Transaction failed: ', error);
        });
    });
    return promise;
  };

  deleteSpecificEntries = (data) => {
    const parentID = data.parentID;
    //console.log('existingEntry', data)
    let promise = new Promise((resolve, reject) => {
      const ref = this.db.collection('entries').doc(data.docId);
      ref
        .get()
        .then(function (querySnapshot) {
          const querydata = querySnapshot.data();
          const existingEntry = querydata.entries.filter(
            (entry) => entry.primaryID === parentID || entry.id === parentID
          );
          //console.log('existingEntry', existingEntry)
          let batch = app.firestore().batch();
          existingEntry.forEach((entry) => {
            batch.update(ref, {
              entries: app.firestore.FieldValue.arrayRemove(entry)
            });
            // .then(function () {
            //   resolve();
            // }).catch(function (error) {
            //   console.error("Error deleting document: ", error);
            // });
          });
          batch
            .commit()
            .then(() => {
              console.log('Success!');
              resolve();
            })
            .catch((err) => console.error('Failed!', err));
        })
        .catch(function (error) {
          console.log('Transaction failed: ', error);
        });
    });
    return promise;
  };
  deleteSpecificSchoolEntries = (data) => {
    const parentID = data.parentID;
    //console.log('existingEntry', data)
    let promise = new Promise((resolve, reject) => {
      const ref = this.db.collection('schoolEntries').doc(data.docId);

      ref
        .get()
        .then(function (querySnapshot) {
          const querydata = querySnapshot.data();
          const existingEntry = querydata.entries.filter(
            (entry) => entry.primaryID === parentID || entry.id === parentID
          );
          //console.log('existingEntry', existingEntry)
          let batch = app.firestore().batch();
          existingEntry.forEach((entry, index) => {
            batch.update(ref, {
              entries: app.firestore.FieldValue.arrayRemove(entry)
            });
            // .then(function () {

            //   if (index === (existingEntry.length - 1)) {
            //     console.log('existingEntry', index, existingEntry);

            //   }
            // }).catch(function (error) {
            //   console.error("Error deleting document: ", error);
            // });
          });
          batch
            .commit()
            .then(() => {
              console.log('Success!');
              resolve();
            })
            .catch((err) => console.error('Failed!', err));
        })
        .catch(function (error) {
          console.log('Transaction failed: ', error);
        });
    });
    return promise;
  };
  addNewEntries = (data) => {
    const parentID = data.parentID;
    const isUpdate = data.isUpdate;
    let promise = new Promise((resolve, reject) => {
      if (data.docId === 'newId') {
        // console.log('newId', data);
        this.db
          .collection('entries')
          .add({
            entries: data.entries,
            familyId: data.familyId
          })
          .then(function () {
            resolve();
          })
          .catch((error) => reject(error));
      } else {
        const ref = this.db.collection('entries').doc(data.docId);
        if (isUpdate) {
          //console.log('isUpdate', parentID);
          ref
            .get()
            .then(function (querySnapshot) {
              //var batch = app.firestore().batch();
              const querydata = querySnapshot.data();
              const existingEntry = querydata.entries.filter(
                (entry) => entry.primaryID === parentID || entry.id === parentID
              );
              //console.log('existingEntry', existingEntry);

              let batch = app.firestore().batch();
              if (existingEntry && existingEntry.length) {
                existingEntry.forEach((entry) => {
                  batch.update(ref, {
                    entries: app.firestore.FieldValue.arrayRemove(entry)
                  });
                });
              }
              data.entries.forEach((entry) => {
                batch.update(ref, {
                  entries: app.firestore.FieldValue.arrayUnion(entry)
                });
              });
              batch
                .commit()
                .then(() => {
                  console.log('Success!');
                  resolve();
                })
                .catch((err) => console.error('Firebase commit Failed!', err));
            })
            .catch(function (error) {
              console.log('Transaction failed: ', error);
            });
        } else {
          let batch = app.firestore().batch();
          data.entries.forEach((entry) => {
            batch.update(ref, {
              entries: app.firestore.FieldValue.arrayUnion(entry)
            });
          });
          batch
            .commit()
            .then(() => {
              console.log('Success!');
              resolve();
            })
            .catch((err) => console.error('Failed!', err));
        }
      }
    });
    return promise;
  };
  addEntries = (entries) => this.db.collection('entries').add(entries);
  getEntries = (familyID) =>
    this.db.collection('entries').where('familyId', '==', familyID).get();
  getEntriesByGroupEntryID = (groupEntryID) =>
    this.db
      .collection('entries')
      .get()
      .then((querySnapshot) => {
        let groupEntries = [];
        querySnapshot.forEach((doc) => {
          const entryData = doc.data();
          const entries = (entryData.entries || []).filter(
            (entry) => entry.groupEntryID === groupEntryID
          );
          if (entries.length) {
            groupEntries = [
              ...groupEntries,
              { docId: doc.id, ...entryData, entries }
            ];
          }
        });
        return groupEntries;
      });
  updateEntries = (entryData, id) =>
    this.db.collection('entries').doc(id).set(entryData);
  deleteEntries = (familyID) =>
    this.db
      .collection('entries')
      .where('familyId', '==', familyID)
      .get()
      .then(function (querySnapshot) {
        var batch = app.firestore().batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });

  // School Entries
  addNewSchoolEntries = (data) => {
    const parentID = data.parentID;
    const isUpdate = data.isUpdate;
    let promise = new Promise((resolve, reject) => {
      if (data.docId === 'newId') {
        //console.log('addNewSchoolEntries', data);
        this.db
          .collection('schoolEntries')
          .add({
            entries: data.entries,
            schoolId: data.schoolId
          })
          .then(function () {
            resolve();
          })
          .catch((error) => reject(error));
      } else {
        const ref = this.db.collection('schoolEntries').doc(data.docId);
        if (isUpdate) {
          //console.log('isUpdate', parentID);
          ref
            .get()
            .then(function (querySnapshot) {
              //var batch = app.firestore().batch();
              const querydata = querySnapshot.data();
              const existingEntry = querydata.entries.filter(
                (entry) => entry.primaryID === parentID || entry.id === parentID
              );
              //console.log('existingSchoolEntry', existingEntry);
              let batch = app.firestore().batch();

              existingEntry.forEach((entry) => {
                batch.update(ref, {
                  entries: app.firestore.FieldValue.arrayRemove(entry)
                });
              });
              data.entries.forEach((entry) => {
                batch.update(ref, {
                  entries: app.firestore.FieldValue.arrayUnion(entry)
                });
              });
              batch
                .commit()
                .then(() => {
                  console.log('Success!');
                  resolve();
                })
                .catch((err) => console.error('Failed!', err));
            })
            .catch(function (error) {
              console.log('Transaction failed: ', error);
            });
        } else {
          let batch = app.firestore().batch();
          data.entries.forEach((entry) => {
            batch.update(ref, {
              entries: app.firestore.FieldValue.arrayUnion(entry)
            });
          });
          batch
            .commit()
            .then(() => {
              console.log('Success!');
              resolve();
            })
            .catch((err) => console.error('Failed!', err));
        }
      }
    });
    return promise;
  };
  addSchoolEntries = (entries) =>
    this.db.collection('schoolEntries').add(entries);
  getSchoolEntries = (schoolId) =>
    this.db.collection('schoolEntries').where('schoolId', '==', schoolId).get();
  getSchoolsArrayEntries = (schoolArray) =>
    this.db
      .collection('schoolEntries')
      .where('schoolId', 'in', schoolArray)
      .get();
  getSchoolEntriesByGroupEntryID = (groupEntryID) =>
    this.db
      .collection('schoolEntries')
      .get()
      .then((querySnapshot) => {
        let groupEntries = [];
        querySnapshot.forEach((doc) => {
          const entryData = doc.data();
          const entries = (entryData.entries || []).filter(
            (entry) => entry.groupEntryID === groupEntryID
          );
          if (entries.length) {
            groupEntries = [
              ...groupEntries,
              { docId: doc.id, ...entryData, entries }
            ];
          }
        });
        return groupEntries;
      });
  updateSchoolEntries = (entryData, id) =>
    this.db.collection('schoolEntries').doc(id).set(entryData);

  deleteSchoolEntries = (id) =>
    this.db
      .collection('schoolEntries')
      .where('schoolId', '==', id)
      .get()
      .then(function (querySnapshot) {
        var batch = app.firestore().batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });
  // Group entries
  addNewGroupEntries = (data) => {
    let promise = new Promise((resolve, reject) => {
      if (data.docId === 'newId') {
        this.db
          .collection('groupEntries')
          .add({
            entries: data.entries,
            groupEntryID: data.groupEntryID,
            groupType: data.groupType
          })
          .then(function () {
            resolve();
          });
      }
    });
    return promise;
  };
  getGroupEntries = (groupEntryID) =>
    this.db
      .collection('groupEntries')
      .where('groupEntryID', '==', groupEntryID)
      .get()
      .then((querySnapshot) => {
        let groupEntries = [];
        let docId = '';
        querySnapshot.forEach((doc) => {
          const entryData = doc.data();
          docId = doc.id;
          groupEntries.push(entryData);
        });
        if (groupEntries && groupEntries.length) {
          groupEntries[0].docId = docId;
          return groupEntries[0];
        }
        return groupEntries;
      });
  updateGroupEntries = (entryData, id) =>
    this.db.collection('groupEntries').doc(id).set(entryData);
  deleteGroupEntries = (id) =>
    this.db.collection('groupEntries').doc(id).delete();
  deleteFamiliesEntries = (id) =>
    this.db.collection('entries').doc(id).delete();
  updateGroupMemberAndYearsInEntry = (data, docId) =>
    this.db.collection('schooldataentries').doc(docId).set(data);

  // updateGroupEntriesByFamilyId = async ({ familyId, groupIds, familyGroups }) => {
  //   try {
  //     const addIds = _.map(_.filter(groupIds, (group) => group.isAdd), 'id');
  //     const removeIds = _.map(_.filter(groupIds, (group) => !group.isAdd), 'id');

  //     let schoolEntriesData = await Promise.all(
  //       _.chunk(groupIds, firestoreLimit.inQuery).map((groups) => {
  //         return this.db
  //           .collection('schooldataentries')
  //           .where('groups', 'array-contains-any', _.map(groups, 'id'))
  //           .get();
  //       })
  //     );
  //     schoolEntriesData = _.flatten(schoolEntriesData.map((data) => data.docs));

  //     const addBatchPromises = addIds.map((groupId) => {
  //       const batch = app.firestore().batch();
  //       const filteredEntries = schoolEntriesData.filter((entry) => entry.data().groups.includes(groupId));
  //       filteredEntries.forEach((doc) => {
  //         batch.update(doc.ref, {
  //           groupMembers: _.uniq([...doc.data().groupMembers, familyId])
  //         });
  //       });
  //       return batch.commit();
  //     });
  //     const removeBatchPromises = removeIds.map((groupId) => {
  //       const batch = app.firestore().batch();
  //       const filteredEntries = schoolEntriesData.filter((entry) =>
  //         entry.data().groups.includes(groupId)
  //       );
  //       filteredEntries.forEach((doc) => {
  //         if (
  //           familyGroups.filter((group) => doc.data().groups.includes(group))
  //             .length == 0
  //         ) {
  //           batch.update(doc.ref, {
  //             groupMembers: _.filter(
  //               doc.data().groupMembers,
  //               (member) => member != familyId
  //             )
  //           });
  //         }
  //       });
  //       return batch.commit();
  //     });
  //     return await Promise.allSettled([...addBatchPromises, ...removeBatchPromises]);
  //   } catch (error) {
  //     const message = error && error.message ? error.message : 'Something went wrong';
  //     throw new Error(message);
  //   }
  // }
  // updateGroupEntriesByGroupId = ({ groupId, families }) => {
  //   this.db
  //     .collection('schooldataentries')
  //     .where('groups', 'array-contains', groupId)
  //     .get()
  //     .then(function (querySnapshot) {
  //       const addIds = _.map(
  //         _.filter(families, (family) => family.isAdd),
  //         'childId'
  //       );
  //       var batch = app.firestore().batch();
  //       querySnapshot.docs.forEach(function (doc) {
  //         const removeIds = _.map(
  //           _.filter(
  //             families,
  //             (family) =>
  //               !family.isAdd &&
  //               _.filter(family.familyGroups, (group) =>
  //                 doc.data().groups.includes(group)
  //               ).length == 0
  //           ),
  //           'childId'
  //         );
  //         let updated = _.filter(
  //           doc.data().groupMembers,
  //           (member) => !_.includes(removeIds, member)
  //         );
  //         updated = _.uniq([...updated, ...addIds]);
  //         batch.update(doc.ref, { groupMembers: updated });
  //       });
  //       return batch.commit();
  //     });
  // };

  // Data entries for paid families
  getDataEntries = (familyID) =>
    this.db.collection('dataentries').where('familyId', '==', familyID).get();
  bulkDataEntries = (bulkID) =>
    this.db.collection('dataentries').where('bulkID', '==', bulkID).get();
  addDataEntries = (entry) => this.db.collection('dataentries').add(entry);
  deleteDataEntries = (docId, id) => {
    // Delete relational entries
    this.db
      .collection('dataentries')
      .where('bulkID', '==', id)
      .get()
      .then(function (querySnapshot) {
        var batch = app.firestore().batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });
    // Delete Primaty Entry
    return this.db.collection('dataentries').doc(docId).delete();
  };
  // below function was created as a temporary, used for data migration
  deleteFamillyDataEntries = (id) => {
    this.db
      .collection('dataentries')
      .where('familyId', '==', id)
      .get()
      .then(function (querySnapshot) {
        var batch = app.firestore().batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });
  };
  // repeated primary entry update function
  deleteRepeatedEntry = (entry) =>
    this.db.collection('dataentries').doc(entry.docId).set(entry);

  //Data entries for schools
  getSchoolDataEntries = (schoolID) =>
  this.db
    .collection('schooldataentries')
    .where('schoolId', '==',schoolID )
    .get()
    .then((querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          let entry = doc.data();
          // Remove 'translated' field from the entry
          if (entry.translated) {
            delete entry.translated;
          }
          // Remove 'translated' field from repeatEntries if it exists
          if (Array.isArray(entry.repeatEntries)) {
            entry.repeatEntries = entry.repeatEntries.map((repeatEntry) => {
              if (repeatEntry.translated) {
                delete repeatEntry.translated;
              }
              return repeatEntry;
            });
          }
          data.push(entry);
        } 
      });
      return data;
    })
    .catch((error) => {
      console.error('Error getting documents: ', error);
      throw error;
    }); 
  schoolBulkDataEntries = (bulkID) =>
    this.db.collection('schooldataentries').where('bulkID', '==', bulkID).get();
  addSchoolDataEntries = (entry) =>
    this.db.collection('schooldataentries').add(entry);
  deleteSchoolDataEntries = (docId, id) => {
    // Delete relational entries
    this.db
      .collection('schooldataentries')
      .where('bulkID', '==', id)
      .get()
      .then(function (querySnapshot) {
        var batch = app.firestore().batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });
    // Delete Primaty Entry
    return this.db.collection('schooldataentries').doc(docId).delete();
  };
  // repeated primary entry update function
  deleteSchoolRepeatedEntry = (entry) =>
    this.db.collection('schooldataentries').doc(entry.docId).set(entry);

  getMultipleSchoolsDataEntries = (schoolArray) =>
    this.db
      .collection('schooldataentries')
      .where('schoolId', 'in', schoolArray)
      .get();

  // delete for a group entries after edit as a whole group
  deleteGroupDataEntries = (groupId) => {
    // Delete relational entries
    this.db
      .collection('dataentries')
      .where('groupEntryID', '==', groupId)
      .get()
      .then(function (querySnapshot) {
        var batch = app.firestore().batch();
        querySnapshot.forEach(function (doc) {
          batch.delete(doc.ref);
        });
        return batch.commit();
      });
  };
}

export default Firebase;