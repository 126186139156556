import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withFirebase } from 'src/utils/firebase';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {
  colors,
  counties,
  timeZones,
  default_PN_time,
  muiTimePickerTheme,
  default_PN_Staff_time,
  createWithRadio
} from 'src/utils/constant';
import {
  TextField,
  Typography,
  Select,
  MenuItem,
  Grid,
  InputLabel,
  FormControl,
  Button,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import AddSchoolAPISyncForm from './schoolAutomation/AddSchoolAPISyncForm';
import LoadingBar from 'src/components/schoolAutomationComp/LoadingBarStatus';
import { profileStyles } from 'src/styles/schoolStyle/profileStyles';
import { deepEqual } from 'src/utils/deepEqual';
import { setSchool } from 'src/redux/actions/schoolActions';

function Profile({ firebase, ...props }) {
  const classes = profileStyles();
  const {
    isEdit = false,
    setOpenDeleteModal,
    accountFlag,
    handleCancelDisable,
    setHandleCancelClick,
    handleCancelClick,
    setIsApiSyncForm,
    isValidationSuccessFull,
    setIsValidationSuccessFull,
    setIsSchoolCreated,
    setApiSyncSchoolData,
    isApiSyncForm,
    submitForm
  } = props;
  const prefillSchoolData = useSelector((state) => state.school.currentSchool);
  const [initialValues, setInitialValue] = useState({
    schoolName: '',
    country: '',
    timezone: '',
    state: '',
    notificationsTiming: {
      year: default_PN_time,
      class: default_PN_time,
      coCurricular: default_PN_time,
      subject: default_PN_time,
      staff: default_PN_Staff_time
    }
  });

  const [schoolAccountType, setSchoolAccountType] = useState('APISync');
  const [formKey, setFormKey] = useState(Math.random());
  const [isFormValuesUpdated, setIsFormValuesUpdated] = useState(false);
  const [formComparisonValues, setFormComparisonValues] = useState({
    schoolName: '',
    country: '',
    timezone: '',
    state: '',
    notificationsTiming: {
      year: default_PN_time,
      class: default_PN_time,
      coCurricular: default_PN_time,
      subject: default_PN_time,
      staff: default_PN_Staff_time
    }
  });
  const [buttonText, setButtonText] = useState('');

  useEffect(() => {
    if (isApiSyncForm) {
      setSchoolAccountType('APISync');
    } else {
      setSchoolAccountType('manualEntry');
    }
  }, [isApiSyncForm]);

  useEffect(() => {
    const profileValues = {
      schoolName: prefillSchoolData.schoolName || '',
      country: prefillSchoolData.country || '',
      timezone: prefillSchoolData.timezone || '',
      state: prefillSchoolData.state || '',
      notificationsTiming: {
        year: prefillSchoolData?.notificationsTiming?.year
          ? prefillSchoolData?.notificationsTiming?.year
          : default_PN_time,
        class: prefillSchoolData?.notificationsTiming?.class
          ? prefillSchoolData?.notificationsTiming?.class
          : default_PN_time,
        coCurricular: prefillSchoolData?.notificationsTiming?.coCurricular
          ? prefillSchoolData?.notificationsTiming?.coCurricular
          : default_PN_time,

        subject: prefillSchoolData?.notificationsTiming?.subject
          ? prefillSchoolData?.notificationsTiming?.subject
          : default_PN_time,

        staff: prefillSchoolData?.notificationsTiming?.staff
          ? prefillSchoolData?.notificationsTiming?.staff
          : default_PN_Staff_time
      }
    };
    setInitialValue(profileValues);
    setFormComparisonValues(profileValues);
  }, [prefillSchoolData]);

  useEffect(() => {
    const profileValues = {
      schoolName: prefillSchoolData.schoolName || '',
      country: prefillSchoolData.country || '',
      timezone: prefillSchoolData.timezone || '',
      state: prefillSchoolData.state || '',
      notificationsTiming: {
        year: prefillSchoolData?.notificationsTiming?.year
          ? prefillSchoolData?.notificationsTiming?.year
          : default_PN_time,
        class: prefillSchoolData?.notificationsTiming?.class
          ? prefillSchoolData?.notificationsTiming?.class
          : default_PN_time,
        coCurricular: prefillSchoolData?.notificationsTiming?.coCurricular
          ? prefillSchoolData?.notificationsTiming?.coCurricular
          : default_PN_time,

        subject: prefillSchoolData?.notificationsTiming?.subject
          ? prefillSchoolData?.notificationsTiming?.subject
          : default_PN_time,

        staff: prefillSchoolData?.notificationsTiming?.staff
          ? prefillSchoolData?.notificationsTiming?.staff
          : default_PN_Staff_time
      }
    };
    setFormComparisonValues(profileValues);
  }, [prefillSchoolData]);

  useEffect(() => {
    if (handleCancelClick) {
      setInitialValue({
        schoolName: prefillSchoolData.schoolName || '',
        country: prefillSchoolData.country || '',
        timezone: prefillSchoolData.timezone || '',
        state: prefillSchoolData.state || '',
        notificationsTiming: {
          year: prefillSchoolData?.notificationsTiming?.year
            ? prefillSchoolData?.notificationsTiming?.year
            : default_PN_time,
          class: prefillSchoolData?.notificationsTiming?.class
            ? prefillSchoolData?.notificationsTiming?.class
            : default_PN_time,
          coCurricular: prefillSchoolData?.notificationsTiming?.coCurricular
            ? prefillSchoolData?.notificationsTiming?.coCurricular
            : default_PN_time,

          subject: prefillSchoolData?.notificationsTiming?.subject
            ? prefillSchoolData?.notificationsTiming?.subject
            : default_PN_time,

          staff: prefillSchoolData?.notificationsTiming?.staff
            ? prefillSchoolData?.notificationsTiming?.staff
            : default_PN_Staff_time
        }
      });
      setFormKey(Math.random());
      setHandleCancelClick(false);
      handleCancelDisable(false);
    }
  }, [handleCancelClick, setHandleCancelClick]);

  // SET PREFILL DATA IF IS-EDIT TRUE
  useEffect(() => {
    if (prefillSchoolData) {
      setInitialValue({
        schoolName: prefillSchoolData.schoolName || '',
        country: prefillSchoolData.country || '',
        timezone: prefillSchoolData.timezone || '',
        state: prefillSchoolData.state || '',
        notificationsTiming: {
          year: prefillSchoolData?.notificationsTiming?.year
            ? prefillSchoolData?.notificationsTiming?.year
            : default_PN_time,
          class: prefillSchoolData?.notificationsTiming?.class
            ? prefillSchoolData?.notificationsTiming?.class
            : default_PN_time,
          coCurricular: prefillSchoolData?.notificationsTiming?.coCurricular
            ? prefillSchoolData?.notificationsTiming?.coCurricular
            : default_PN_time,

          subject: prefillSchoolData?.notificationsTiming?.subject
            ? prefillSchoolData?.notificationsTiming?.subject
            : default_PN_time,

          staff: prefillSchoolData?.notificationsTiming?.staff
            ? prefillSchoolData?.notificationsTiming?.staff
            : default_PN_Staff_time
        }
      });
    }
  }, [prefillSchoolData]);

  // SCHEMA VALIDATOR FOR
  const validationSchema = Yup.object().shape({
    schoolName: Yup.string().trim().required('School name is required'),
    country: Yup.string().trim().required('Country is required'),
    timezone: Yup.string().trim().required('Timezone is required'),
    state: Yup.string().trim()
  });

  const handleDateActionChange = (newTime, value, formData) => {
    setInitialValue(() => ({
      ...formData,
      notificationsTiming: {
        ...formData.notificationsTiming,
        [value]: moment(newTime.$d).format('LLLL')
      }
    }));
  };
  const handleSchoolAccountType = (event) => {
    if (event.target.value === 'APISync') {
      setIsApiSyncForm(true);
    } else {
      setIsApiSyncForm(false);
    }
    setSchoolAccountType(event.target.value);
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {isEdit ? (
          ''
        ) : !isValidationSuccessFull ? (
          <section style={{ marginBottom: '1rem' }}>
            <div className={classes.label}>Create with</div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={schoolAccountType}
                onChange={handleSchoolAccountType}
              >
                <FormControlLabel
                  value="APISync"
                  control={<Radio color="primary" />}
                  label="API Sync"
                />
                <FormControlLabel
                  value="manualEntry"
                  control={<Radio color="primary" />}
                  label="Manual entry"
                />
              </RadioGroup>
            </FormControl>
          </section>
        ) : (
          <>
            <LoadingBar
              setIsValidationSuccessFull={setIsValidationSuccessFull}
              setApiSyncSchoolData={setApiSyncSchoolData}
              setIsSchoolCreated={setIsSchoolCreated}
            />
          </>
        )}
      </div>
      <div style={{ display: 'inline-flex', width: '100%' }}>
        {!isValidationSuccessFull &&
          (!isEdit && schoolAccountType === 'APISync' ? (
            <AddSchoolAPISyncForm
              setIsValidationSuccessFull={setIsValidationSuccessFull}
            />
          ) : (
            <Formik
              key={formKey}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                const isSchoolFormUpdated = !deepEqual(
                  values,
                  formComparisonValues
                );
                if (props.accountFlag && buttonText === 'Save') {
                  submitForm({ ...values, isSchoolFormUpdated }, buttonText);
                } else {
                  submitForm({ ...values, isSchoolFormUpdated }, buttonText);
                  handleCancelDisable(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                initialValues: formikInitialValues
              }) => {
                if (deepEqual(
                  formikInitialValues,
                  initialValues
                )) {
                  const isFormUpdated = !deepEqual(
                    values,
                    formComparisonValues
                  );
                  
                  setIsFormValuesUpdated(isFormUpdated);
                }  

                return (
                  <form
                    noValidate
                    onSubmit={handleSubmit}
                    className={classes.contentWrapper}
                  >
                    {/* ==== */}
                    <Typography className={classes.formTitle}>
                      School Profile
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormControl
                          component="fieldset"
                          classes={{ root: classes.formControl }}
                        >
                          <TextField
                            label="School Name"
                            variant="outlined"
                            name="schoolName"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              accountFlag &&
                                isEdit &&
                                handleCancelDisable(true);
                            }}
                            error={Boolean(
                              touched.schoolName && errors.schoolName
                            )}
                            helperText={touched.schoolName && errors.schoolName}
                            value={values.schoolName}
                            className={classes.controlBox}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          classes={{ root: classes.formControl }}
                        >
                          <InputLabel id="country-select-outlined-label">
                            Country
                          </InputLabel>
                          <Select
                            labelId="country-select-outlined-label"
                            id="country-select-outlined"
                            value={values.country}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue('country', e.target.value);
                              accountFlag &&
                                isEdit &&
                                handleCancelDisable(true);
                            }}
                            className={classes.inputControlBox}
                            error={Boolean(touched.country && errors.country)}
                            name="country"
                            label="Country"
                          >
                            <MenuItem value="">
                              <em>Country</em>
                            </MenuItem>
                            {counties.length &&
                              counties.map((item) => {
                                return (
                                  <MenuItem key={item.code} value={item.name}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          variant="outlined"
                          classes={{ root: classes.formControl }}
                        >
                          <InputLabel id="timezone-select-outlined-label">
                            Timezone
                          </InputLabel>
                          <Select
                            labelId="timezone-select-outlined-label"
                            id="timezone-select-outlined"
                            value={values.timezone}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue('timezone', e.target.value);
                              accountFlag &&
                                isEdit &&
                                handleCancelDisable(true);
                            }}
                            className={classes.inputControlBox}
                            error={Boolean(touched.timezone && errors.timezone)}
                            name="timezone"
                            label="Timezone"
                          >
                            <MenuItem value="">
                              <em>Timezone</em>
                            </MenuItem>
                            {timeZones.length &&
                              timeZones.map((item) => {
                                return (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          component="fieldset"
                          classes={{ root: classes.formControl }}
                        >
                          <TextField
                            variant="outlined"
                            value={values.state}
                            onBlur={(e) => {
                              setFieldValue(
                                'state',
                                e.target.value.trim().split(/ +/).join(' ')
                              );
                              handleBlur(e);
                            }}
                            onChange={(e) => {
                              handleChange(e);
                              accountFlag &&
                                isEdit &&
                                handleCancelDisable(true);
                            }}
                            error={Boolean(touched.state && errors.state)}
                            name="state"
                            label="State / City"
                            className={classes.controlBox}
                            helperText={touched.state && errors.state}
                          />
                        </FormControl>
                      </Grid>
                      {/* ---- */}

                      <Grid item xs={12}>
                        <ThemeProvider theme={muiTimePickerTheme}>
                          <Typography className={classes.notificationTitle}>
                            Push Notification Times
                          </Typography>
                          <Box
                            sx={{
                              display: 'grid',
                              gridTemplateRows: 'repeat(5, 1fr)'
                            }}
                          >
                            <Grid className={classes.flexBoxClass}>
                              <TimePicker
                                autoOk
                                name={`yearNotification`}
                                onBlur={handleBlur}
                                onChange={(newTime) =>
                                  handleDateActionChange(
                                    newTime,
                                    'year',
                                    values
                                  )
                                }
                                value={
                                  (values &&
                                    values?.notificationsTiming?.year) ||
                                  ''
                                }
                                format="h:mma"
                                style={{ width: 80 }}
                                className={classes.formDateControlFromBordered}
                              />
                              <Box sx={{ margin: '0 5px' }}>
                                <Typography className={classes.timeLabel}>
                                  Year
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid className={classes.flexBoxClass}>
                              <TimePicker
                                autoOk
                                name={`classNotification`}
                                onBlur={handleBlur}
                                onChange={(newTime) =>
                                  handleDateActionChange(
                                    newTime,
                                    'class',
                                    values
                                  )
                                }
                                value={
                                  (values &&
                                    values.notificationsTiming?.class) ||
                                  ''
                                }
                                format="h:mma"
                                style={{ width: 80 }}
                                className={classes.formDateControlFromBordered}
                              />
                              <Typography className={classes.timeLabel}>
                                Class
                              </Typography>
                            </Grid>
                            <Grid className={classes.flexBoxClass}>
                              <TimePicker
                                autoOk
                                name={`coCurricularNotification`}
                                onBlur={handleBlur}
                                onChange={(newTime) =>
                                  handleDateActionChange(
                                    newTime,
                                    'coCurricular',
                                    values
                                  )
                                }
                                value={
                                  (values &&
                                    values.notificationsTiming?.coCurricular) ||
                                  ''
                                }
                                format="h:mma"
                                style={{ width: 80 }}
                                className={classes.formDateControlFromBordered}
                              />
                              <Typography className={classes.timeLabel}>
                                Co-Curricular
                              </Typography>
                            </Grid>
                            <Grid className={classes.flexBoxClass}>
                              <TimePicker
                                autoOk
                                name={`subjectNotification`}
                                onBlur={handleBlur}
                                onChange={(newTime) =>
                                  handleDateActionChange(
                                    newTime,
                                    'subject',
                                    values
                                  )
                                }
                                value={
                                  (values &&
                                    values.notificationsTiming?.subject) ||
                                  ''
                                }
                                format="h:mma"
                                style={{ width: 80 }}
                                className={classes.formDateControlFromBordered}
                              />
                              <Typography className={classes.timeLabel}>
                                Subject
                              </Typography>
                            </Grid>
                            <Grid className={classes.flexBoxClass}>
                              <TimePicker
                                autoOk
                                name={`staffNotification`}
                                onBlur={handleBlur}
                                onChange={(newTime) =>
                                  handleDateActionChange(
                                    newTime,
                                    'staff',
                                    values
                                  )
                                }
                                value={
                                  (values &&
                                    values.notificationsTiming?.staff) ||
                                  ''
                                }
                                format="h:mma"
                                style={{ width: 80 }}
                                className={classes.formDateControlFromBordered}
                              />
                              <Typography className={classes.timeLabel}>
                                Staff
                              </Typography>
                            </Grid>
                          </Box>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setButtonText('Next');
                            handleSubmit();
                            window.scroll(0, 0);
                          }}
                          className={classes.disabledButtonText}
                        >
                          Next
                        </Button>
                        {accountFlag && isEdit && (
                          <Button
                            variant="contained"
                            color="primary"
                            className={
                              isFormValuesUpdated ||
                              prefillSchoolData?.isSchoolFormUpdated
                                ? classes.buttonText
                                : classes.disabledButtonText
                            }
                            onClick={() => {
                              setButtonText('Save');
                              handleSubmit();
                              window.scroll(0, 0);
                            }}
                            style={{ marginLeft: '16px' }}
                            disabled={
                              isFormValuesUpdated ||
                              prefillSchoolData?.isSchoolFormUpdated
                                ? false
                                : true
                            }
                          >
                            Save
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          ))}

        {isEdit && (
          <Button
            className={classes.deleteButton}
            onClick={() => setOpenDeleteModal && setOpenDeleteModal(true)}
          >
            <div className={classes.deleteText}>Delete School</div>
          </Button>
        )}
      </div>
    </div>
  );
}

export default withFirebase(Profile);
