import React, { useState, useEffect } from 'react';
import { withFirebase, FIREBASE_API_URL } from 'src/utils/firebase';
import _ from 'lodash';
import {
  loaderStart,
  loaderStop,
  addMessage
} from 'src/redux/actions/appActions';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import {
  colors,
  accountTypes,
  counties,
  timeZones,
  routes
} from 'src/utils/constant';
import Radio from '@material-ui/core/Radio';
import Modal from 'src/components/Modal';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Loop } from '@material-ui/icons';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import { useSelector, useDispatch } from 'react-redux';
import { UserMinusIcon } from 'src/assets/Icons';
import {
  generateFileHash,
  generatePatternPassword,
  getInitials
} from 'src/utils/functions';
import { setFamily } from 'src/redux/actions/familyActions';
import { addMemberStyles } from 'src/styles/membersStyle/addMemberStyles';
import { MESSAGE_TYPES } from 'src/constants/common';


const parentObject = {
  firstName: '',
  lastName: '',
  initials: '',
  emailAddress: '',
  phoneNo: ''
};
function AddMember({
  submitForm,
  isEdit = false,
  handlePause,
  handleResume,
  firebase,
  accountFlag,
  handleCancelDisable,
  setHandleCancelClick,
  handleCancelClick
}) {
  const classes = addMemberStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.app.isLoading);
  const prefillFamilyData = useSelector((state) => state.family.currentFamily);
  const [initialValues, setInitialValue] = useState({
    planType: 'Free',
    familySurname: '',
    userName: '',
    password: '',
    country: '',
    timezone: '',
    parents: []
  });
  const [isPauseAccountModal, setPauseAccountModal] = useState(false);
  const [formKey, setFormKey] = useState(Math.random());
  const [isResumeAccountModal, setResumeAccountModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [resumeText, setResumeText] = useState('');
  const [pauseText, setPauseText] = useState('');
  const [deleteText, setDeleteText] = useState('');
  const [isSave, setSave] = useState(false);
  const [isOpenConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [isRotate, setRotate] = useState(false);
  // Existing Email check
  const [isPrimaryEmailExistCheck, setIsPrimaryEmailExistCheck] =
    useState(false);
  const [isUserExistPrimary, setIsUserExistPrimary] = useState('');
  const onTogglePasswordFieldType = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
    } else {
      setPasswordFieldType('password');
    }
  };
  // SCHEMA VALIDATOR FOR CREATE NEW PASSWORD FORM
  const validationSchema = Yup.object().shape({
    parents: Yup.array()
      .of(
        Yup.object().shape({
          firstName: Yup.string().trim().required('First name is required'),
          emailAddress: Yup.string()
            .trim()
            .email()
            .required('Valid email is required')
        })
      )
      .required(),
    familySurname: Yup.string().trim().required('Family Surname is required'),
    userName:
      prefillFamilyData.planType !== 'Free'
        ? Yup.string()
            .trim()
            .required('User Name is required')
            .matches(/^[a-zA-Z0-9]+$/, 'Invalid User Name')
        : null,
    password: !!isEdit
      ? Yup.string()
          .trim()
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
            'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.',
            { excludeEmptyString: true }
          )
      : prefillFamilyData.planType !== 'Free'
      ? Yup.string()
          .trim()
          .required('Password is required')
          .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
            'Password must contain at least 8 characters, at least one uppercase letter, at least one lowercase letter and at least one number.'
          )
      : null,
    planType: Yup.string().trim().required('Plan Type is required'),
    country: Yup.string().trim().required('Country is required'),
    timezone: Yup.string().trim().required('Timezone is required')
  });
  let { id } = useParams();
  useEffect(() => {
    dispatch(
      setFamily({
        ...prefillFamilyData,
        planType: 'Free'
      })
    );
  }, []);
  useEffect(() => {
    if (handleCancelClick) {
      setInitialValue({
        planType: prefillFamilyData.planType || '',
        familySurname: prefillFamilyData.familySurname,
        userName: prefillFamilyData.userName,
        password: prefillFamilyData.password,
        country: prefillFamilyData.country,
        timezone: prefillFamilyData.timezone,
        parents: prefillFamilyData.parents
      });
      setFormKey(Math.random());
      setHandleCancelClick(false);
      handleCancelDisable(false);
    }
  }, [handleCancelClick, setHandleCancelClick]);

  const deleteFamilyEntries = async (familyID) => {
    await firebase.deleteEntries(familyID);
  };

  const deleteAuthUsercaller = async (userId) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: userId
      })
    };
    fetch(`${FIREBASE_API_URL}/deleteUser`, requestOptions)
      .then((response) => {
        const result = response.json();
        if (!response.ok) {
          throw new Error(result?.message);
        }
        return result
      })
      .then(
        (result) => {
          if (result.message === 'Successfully deleted user') {
          } else {
          }
        },
        (error) => {
          const errorMessage = _.get(error, 'message', '');
          console.log(errorMessage);
          dispatch(addMessage(errorMessage, MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        }
      );
  };

  const deleteUserHandle = async () => {
    setDeleteText('');
    dispatch(loaderStart());
    try {
      if (prefillFamilyData.userId) {
        await deleteAuthUsercaller(prefillFamilyData.userId);
        await firebase.deleteUser(prefillFamilyData.userId);

        Array.isArray(prefillFamilyData.childs) &&
          prefillFamilyData.childs.map((childData) => {
            deleteAuthUsercaller(childData.id);
            return firebase.deleteUser(childData.id);
          });
      }

      await deleteFamilyEntries(prefillFamilyData.id);
      firebase
        .deleteFamily(prefillFamilyData.id)
        .then(
          () => {
            dispatch(loaderStop());
            setOpenDeleteModal(false);
            setOpenConfirmDeleteModal(true);
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(addMessage('Family was not deleted. Please try again', MESSAGE_TYPES.ERROR));
            dispatch(loaderStop());
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Family was not deleted. ooo Please try again', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
    } catch (error) {
      console.log(error, 'error');
      dispatch(addMessage('Family was not deleted. kkk Please try again', MESSAGE_TYPES.ERROR));
      dispatch(loaderStop());
    }
  };

  const generatePassword = (setFieldValue) => {
    setRotate(true);
    setFieldValue('password', generatePatternPassword(8));
    dispatch(addMessage('Password generated and set'));
    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };

  const checkUserExistOrNot = (userName, setErrors) => {
    const email = `${userName}@mywhanau-user.com`;
    firebase
      .isUserExists(email)
      .then((results) => {
        if (results.size > 0) {
          setErrors({
            userName: `Username is already in use by another account.`
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const idx = 0;
  return (
    <div>
      <div className={classes.contentWrapper}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors }) => {
            let formValues = {
              ...values,
              parents: [{ ...values.parents[0], id: generateFileHash(20) }],
              freeHost: true
            };
            const email = `${values.userName}@mywhanau-user.com`;
            if (
              !prefillFamilyData.userName ||
              prefillFamilyData.userName !== values.userName
            ) {
              firebase
                .isUserExists(email)
                .then((results) => {
                  if (results.size > 0) {
                    setErrors({
                      userName: `${values.userName} username is already in use by another account.`
                    });
                  } else {
                    if (accountFlag && isSave) {
                      submitForm(formValues, true);
                    } else {
                      submitForm(formValues);
                      // handleCancelDisable(false);
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              if (accountFlag && isSave) {
                submitForm(formValues, true);
              } else {
                submitForm(formValues);
                handleCancelDisable(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            setErrors
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                {values.planType ? (
                  <div style={{ marginLeft: '-2.5%' }}>
                    <div className={classes.label}>Member Profile</div>
                    <FormControl
                      component="fieldset"
                      className={clsx(classes.textFieldContainer)}
                    >
                      <TextField
                        label="First Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        name={`parents[${idx}].firstName`}
                        onChange={(e) => {
                          setFieldValue(
                            `parents[${idx}].firstName`,
                            e.target.value
                          );
                          setFieldValue(
                            `parents[${idx}].initials`,
                            getInitials(
                              `${e.target.value} ${values?.parents[idx]?.lastName}`
                            )
                          );
                        }}
                        value={
                          (values.parents[idx] &&
                            values.parents[idx].firstName) ||
                          ''
                        }
                        error={Boolean(
                          touched.parents &&
                            errors.parents &&
                            touched.parents[idx] &&
                            touched.parents[idx].firstName &&
                            errors.parents[idx] &&
                            errors.parents[idx].firstName
                        )}
                        helperText={
                          touched.parents &&
                          errors.parents &&
                          touched.parents[idx] &&
                          touched.parents[idx].firstName &&
                          errors.parents[idx] &&
                          errors.parents[idx].firstName
                        }
                        className={classes.inputControlBox}
                      />
                    </FormControl>
                    <FormControl component="fieldset" className={clsx(classes.textFieldContainer)}>
                      <TextField
                        label="Surname"
                        variant="outlined"
                        name="familySurname"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue(
                            `parents[${idx}].lastName`,
                            e.target.value
                          );
                          setFieldValue(
                            `parents[${idx}].initials`,
                            getInitials(
                              `${values?.parents[idx]?.firstName} ${e.target.value}`
                            )
                          );
                        }}
                        error={Boolean(
                          touched.familySurname && errors.familySurname
                        )}
                        value={values.familySurname}
                        className={classes.inputControlBox}
                        helperText={
                          touched.familySurname && errors.familySurname
                        }
                      />
                    </FormControl>

                    <FormControl
                      component="fieldset"
                      className={clsx(classes.textFieldContainer)}
                    >
                      <TextField
                        label="Email Address"
                        variant="outlined"
                        className={classes.inputControlBox}
                        onBlur={async (event) => {
                          if (event.target.value) {
                            const isEmailExist =
                            await firebase.isPrimaryEmailExist(
                              event.target.value
                              );
                          if (isEmailExist?.size > 0) {
                            setIsPrimaryEmailExistCheck(true);
                            const familyName =
                              isEmailExist?.docs[0].data().familySurname;
                            setIsUserExistPrimary(familyName);
                          } else {
                            setIsPrimaryEmailExistCheck(false);
                          }

                          }
                        }}
                        name={`parents[${idx}].emailAddress`}
                        onChange={(e) => {
                          setFieldValue(
                            `parents[${idx}].emailAddress`,
                            e.target.value.trim()
                          );
                        }}
                        value={
                          (values.parents[idx] &&
                            values.parents[idx].emailAddress) ||
                          ''
                        }
                        error={
                          isPrimaryEmailExistCheck ||
                          Boolean(
                            touched.parents &&
                              errors.parents &&
                              touched.parents[idx] &&
                              touched.parents[idx].emailAddress &&
                              errors.parents[idx] &&
                              errors.parents[idx].emailAddress &&
                              'Valid email is required'
                          )
                        }
                        helperText={
                          (touched.parents &&
                            errors.parents &&
                            touched.parents[idx] &&
                            touched.parents[idx].emailAddress &&
                            errors.parents[idx] &&
                            errors.parents[idx].emailAddress &&
                            'Valid email is required') ||
                          (isPrimaryEmailExistCheck &&
                            `Email already exists (${isUserExistPrimary})`)
                        }
                      />
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      className={clsx(classes.textFieldContainer)}
                    >
                      <TextField
                        label="Phone Number"
                        variant="outlined"
                        name={`parents[${idx}].phoneNo`}
                        value={
                          (values.parents[idx] &&
                            values.parents[idx].phoneNo) ||
                          ''
                        }
                        disableUnderline
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            `parents[${idx}].phoneNo`,
                            e.target.value
                          );
                        }}
                        className={classes.inputControlBox}
                        // style={{ margin: '20px 0 0 0' }}
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.inputControlBox}
                    >
                      <InputLabel id="country-select-outlined-label">
                        Country
                      </InputLabel>
                      <Select
                        labelId="country-select-outlined-label"
                        id="country-select-outlined"
                        value={values.country}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.country && errors.country)}
                        name="country"
                        label="Country"
                      >
                        <MenuItem value="">
                          <em>Country</em>
                        </MenuItem>
                        {counties.map(function (object) {
                          return (
                            <MenuItem key={object.code} value={object.name}>
                              {object.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {Boolean(touched.country && errors.country) ? (
                        <span style={{ height: 20 }} className={classes.error}>
                          {errors.country}
                        </span>
                      ) : (
                        ''
                      )}
                    </FormControl>
                    <FormControl variant="outlined" className={classes.inputControlBox}>
                      <InputLabel id="timezone-select-outlined-label">
                        Time zone
                      </InputLabel>
                      <Select
                        labelId="timezone-select-outlined-label"
                        id="timezone-select-outlined"
                        value={values.timezone}
                        onChange={(e) => {
                          handleChange(e);
                          accountFlag && isEdit && handleCancelDisable(true);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.timezone && errors.timezone)}
                        name="timezone"
                        label="Timezone"
                      >
                        <MenuItem value="">
                          <em>TimeZone</em>
                        </MenuItem>
                        {timeZones.map(function (object) {
                          return (
                            <MenuItem key={object.value} value={object.value}>
                              {object.text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {Boolean(touched.timezone && errors.timezone) ? (
                        <span style={{ height: 20 }} className={classes.error}>
                          {errors.timezone}
                        </span>
                      ) : (
                        ''
                      )}
                    </FormControl>
                    {/* Login credentials part */}
                    <div className={classes.label}>Login Credentials</div>
                    <div
                      className={classes.subtitle}
                      style={{ marginBottom: '1rem' }}
                    >
                      Only add these if you are saving the account with the
                      status of Active.
                    </div>

                    {/* login username */}
                    <FormControl component="fieldset">
                      <TextField
                        label="Login Username"
                        variant="outlined"
                        className={classes.inputControlBox}
                        name="userName"
                        onBlur={(e) => {
                          handleBlur(e);
                          if (
                            (!prefillFamilyData.userName ||
                              prefillFamilyData.userName !== values.userName) &&
                            e.target.value
                          ) {
                            checkUserExistOrNot(e.target.value, setErrors);
                          }
                        }}
                        onChange={(e) => {
                          setFieldValue(
                            'userName',
                            e.target.value
                              ? e.target.value.replace(/\s/g, '')
                              : ''
                          );
                          accountFlag && isEdit && handleCancelDisable(true);
                        }}
                        error={Boolean(touched.userName && errors.userName)}
                        value={values.userName}
                        helperText={touched.userName && errors.userName}
                      />
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      className={clsx(classes.textFieldContainer)}
                    >
                      <div style={{display:"flex",alignItems:"center"}}>
                        <TextField
                          label="Password"
                          variant="outlined"
                          className={classes.inputControlBox}
                          name="password"
                          onBlur={handleBlur}
                          // style={{ width: 400 }}
                          onChange={(e) => {
                            handleChange(e);
                            accountFlag && isEdit && handleCancelDisable(true);
                          }}
                          error={Boolean(touched.password && errors.password)}
                          value={values.password}
                          helperText={touched.password && errors.password}
                          type={passwordFieldType}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => onTogglePasswordFieldType()}
                                  tabIndex="-1"
                                >
                                  <img
                                    alt="Password Requirement"
                                    src={
                                      passwordFieldType === 'text'
                                        ? '/static/images/password-hide.png'
                                        : '/static/images/password-see.png'
                                    }
                                    width="22"
                                    height="22"
                                  />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        <span className={classes.generate}>
                          Generate
                          <Loop
                            height={24}
                            width={24}
                            style={{ cursor: 'pointer' }}
                            onClick={() => generatePassword(setFieldValue)}
                            className={isRotate && classes.closeIconRotate}
                          />
                        </span>
                      </div>
                    </FormControl>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleSubmit();
                          setSave(false);
                          window.scroll(0, 0);
                        }}
                        className={classes.buttonText}
                      >
                        Next
                      </Button>
                      {accountFlag && isEdit && (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonText}
                          onClick={() => {
                            setSave(true);
                            handleSubmit();
                          }}
                          style={{ marginLeft: '2%' }}
                        >
                          Save
                        </Button>
                      )}
                    </Grid>
                  </div>
                ) : (
                  <></>
                )}
              </form>
            );
          }}
        </Formik>
      </div>

      {/* Modals */}
      {isPauseAccountModal && (
        <Modal
          isOpen={isPauseAccountModal}
          onClose={() => {
            setPauseAccountModal(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Account Pause?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                Pausing this account will revoke access for all users associated
                with this family, until account pause is disabled. You can
                continue to manage the account from within the admin.
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “PAUSE ACCOUNT” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={pauseText}
                  onChange={(e) => setPauseText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  handlePause && handlePause();
                  setPauseAccountModal(false);
                }}
                color="primary"
                variant="contained"
                size="large"
                disabled={pauseText !== 'PAUSE ACCOUNT'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Pause'}
              </Button>
              <Button
                onClick={() => {
                  setPauseAccountModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isResumeAccountModal && (
        <Modal
          isOpen={isResumeAccountModal}
          onClose={() => {
            setPauseAccountModal(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Account Resume?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                Resume account will activate access for all users associated
                with this family. You can continue to manage the account from
                within the admin.
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “RESUME ACCOUNT” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={resumeText}
                  onChange={(e) => setResumeText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  handleResume && handleResume();
                  setResumeText('');
                  setResumeAccountModal(false);
                }}
                color="primary"
                variant="contained"
                size="large"
                disabled={resumeText !== 'RESUME ACCOUNT'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Resume'}
              </Button>
              <Button
                onClick={() => {
                  setPauseAccountModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenDeleteModal && (
        <Modal
          isOpen={isOpenDeleteModal}
          onClose={() => {
            setOpenDeleteModal(false);
          }}
        >
          <div className={classes.modalBox}>
            <Typography className={classes.modalTitle}>
              Delete Family Account?
            </Typography>
            <div className={classes.modalSubHeading}>
              <Typography className={classes.modalSubTitle}>
                <span style={{ fontWeight: 'bold' }}>
                  Warning: This action cannot be reversed.
                </span>
                <br />
                The following data will be permanently removed from the
                database.
              </Typography>
            </div>
            <div className={classes.modalContent}>
              <Typography className={classes.modalSubTitle}>
                - Family account (admin)
                <br />
                - All associated calendar entries
                <br />
                - Family account and login credentials (app)
                <br />
              </Typography>
            </div>
            <div className={classes.modalSubHeading}>
              <span
                className={classes.modalSubTitle}
                style={{ fontWeight: 500, paddingBottom: 14 }}
              >
                Type “DELETE ACCOUNT” to confirm
              </span>
              <FormControl component="fieldset" style={{ width: '100%' }}>
                <TextField
                  label=""
                  variant="outlined"
                  className={classes.inputModalControlBox}
                  style={{ marginTop: 0 }}
                  value={deleteText}
                  onChange={(e) => setDeleteText(e.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.modalActions}>
              <Button
                onClick={deleteUserHandle}
                color="primary"
                variant="contained"
                size="large"
                disabled={deleteText !== 'DELETE ACCOUNT'}
                className={classes.primaryButton}
                startIcon={
                  isLoading && <CircularProgress color="secondary" size={25} />
                }
              >
                {!isLoading && 'Delete'}
              </Button>
              <Button
                onClick={() => {
                  setOpenDeleteModal(false);
                }}
                variant="contained"
                size="large"
                className={classes.greyButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isOpenConfirmDeleteModal && (
        <Modal
          isOpen={isOpenConfirmDeleteModal}
          onClose={() => setOpenConfirmDeleteModal(false)}
        >
          <div className={classes.modalBox}>
            <Typography
              className={classes.modalTitle}
              style={{ marginBottom: 18 }}
            >
              Family Account Deleted
            </Typography>
            <Typography
              className={classes.modalSubTitle}
              style={{ marginBottom: 26 }}
            >
              All data associated with {prefillFamilyData.userName} family has
              now been removed from the database.
            </Typography>
            <div className={classes.modalActions}>
              <Button
                onClick={() => {
                  setOpenConfirmDeleteModal(false);
                  history.push(routes.families);
                }}
                color="primary"
                variant="contained"
                size="large"
                className={classes.primaryButton}
              >
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default withFirebase(AddMember);
