import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Paper,
  Button,
  Divider,
  Grid,
  Typography,
  Checkbox,
  CircularProgress,
  Avatar,
  Menu,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Select,
  Badge,
  Input
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { colors, routes, year as DefaultYear } from 'src/utils/constant';
import {
  loaderStop,
  loaderStart,
  addMessage
} from 'src/redux/actions/appActions';
import Dashboard from '../layout/Dashboard';
import CreateEditStudent from '../schools/students/CreateEditStudent';
import api_icon from 'src/assets/apiIcon.svg';
import schoolBytes from 'src/assets/schoolByte2.webp';
import { RightArrow } from 'src/assets/Icons';
import { withFirebase, FIREBASE_API_URL } from 'src/utils/firebase';
import {
  MoreVert,
  Add as AddIcon,
  CameraAlt as CameraAltIcon
} from '@material-ui/icons';
import {
  API_CHILD_UPDATE_GROUP
} from 'src/utils/api';
import makeApiRequest from 'src/utils/axios';
import { parentSchema, childSchema } from 'src/utils/schemas';
import { setFamily } from 'src/redux/actions/familyActions';
import { generateFileHash, isValidFileType } from 'src/utils/functions';
import { Link } from 'react-router-dom';
import PhoneInput from 'src/components/PhoneInput';
import ScanningUploadFileModal from 'src/components/ScanningUploadFileModal';
import CardComponent from './components/CardComponent';
import { getSchoolByID, getSchools } from 'src/services/schools';
import { getGroupBySchoolId, getGroups, updateGroup, updateGroupEntries } from 'src/services/groups';
import { membersUsersViewStyles } from 'src/styles/membersStyle/membersUsersViewStyles';
import { MESSAGE_TYPES } from 'src/constants/common';



const AccountsCardDataFormat = {
  headerLink: 'left',
  buttonTextHover: 'Manage',
  headerLinkText: 'Primary',
  linkColor: '#FFFFFF',
  displayParent: 1,
  nameToupper: 1
};

const AccountsCardDataFormatPaid = {
  headerLink: 'left',
  buttonTextHover: 'Manage',
  headerLinkText: 'Primary',
  linkColor: '#FFFFFF',
  bordered: 1,
  displayParent: 0,
  nameToupper: 1
};

function MembersUsersView({ firebase }) {
  const classes = membersUsersViewStyles();
  const history = useHistory();
  const [schools, setSchools] = useState([]);
  const [yearsData, setYearsData] = useState();
  const [groups, setGroups] = useState();
  const [isOpenAddEditModal, setIsOpenAddEditModal] = useState(false);
  const [categories, setCategories] = useState();
  const [currentStudent, setCurrentStudent] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const isLoading = useSelector((state) => state.app.isLoading);
  // const schools = useSelector((state) => state.auth.schools);
  const [schoolList, setSchoolList] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [data, setData] = useState([]);
  const [isOpenModal, setOpenModal] = useState({ open: false, values: null });
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [isNoParentDeleteModal, setNoParentDeleteModalModal] = useState(false);
  const [openDeleteMenu, setOpenDeleteMenu] = React.useState(null);
  const [isDisableButton, setDisableButton] = React.useState();
  const [deleteText, setDeleteText] = React.useState('');
  const [profileImageObject, setProfileImageObject] = React.useState(null);
  // const [years, setYears] = React.useState(null);
  const currentFamily = useSelector((state) => state.family.currentFamily);
  const [Groups, setGroupData] = useState([]);
  const [schoolCatagories, setSchoolCategories] = useState([]);
  const childVisiility = useSelector((state) => state?.auth?.user?.country);

  const [fileUploadModal, setFileUploadModal] = useState({
    isModalOpen: false,
    fileUploadState: 0
  });

  useEffect(() => {
    // PHASE2/CHANGES/SCHOOL

    getSchools()
      .then((result) => {
        const schools = [];
        result.forEach((school) => {
          const yearData = school.freeCalendars.map((calendar) => {
            const getCurrentYear = _.find(
              DefaultYear,
              (elem) => elem.value === calendar
            );
            return ({
              label: getCurrentYear.label,
              value: calendar
            })
          });
          schools.push({
            id: school.id,
            name: school.schoolName,
            years: yearData.length ? yearData : []
          });
        });
        setSchools(schools);
      })
      .catch((error) => {
        console.error(error);
        dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
      });
  }, []);

  const openRightDrawer = useCallback(
    (data) => {
      let mergeNewClassAndGroups = [];
      if(!!data){
        setIsEdit(true);
        dispatch(loaderStart());
        mergeNewClassAndGroups = _.compact([
          data.group,
          data.groups
        ]);
        mergeNewClassAndGroups = _.uniq(
          [].concat.apply([], mergeNewClassAndGroups)
        );
        fetchYearsOnSchoolChange(data.school);
      }
      let parents = currentFamily.parents.map(p => ({email: p.emailAddress, member: p.firstName + ' ' + p.lastName}));
      const student = {
        studentFirstName: data?.firstName,
        studentLastName: data?.lastName,
        school: data?.school,
        year: data?.year,
        groups: mergeNewClassAndGroups,
        parents: parents,
        id: data?.id,
        familyId: currentFamily.id,
        createdByApiSync: data?.createdByApiSync,
        planType: currentFamily.planType
      };
      setIsOpenAddEditModal(true);
      setCurrentStudent(student);
    },[setIsOpenAddEditModal, setCurrentStudent, currentFamily]
  );

  const dispatch = useDispatch();

  const familyID = useMemo(() => {
    return history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    );
  }, [history.location.pathname]);

  useEffect(() => {
    if (!currentFamily.familySurname && familyID) {
      fetchFamilyData();
    }
  }, [currentFamily, familyID, firebase, dispatch]);

  const fetchFamilyData = () => {
    dispatch(loaderStart());
      firebase
        .getFamilyByID(familyID)
        .then(
          async (doc) => {
            let familyData = doc.data();
            if (familyData) {
              dispatch(setFamily(familyData));
            }
            dispatch(loaderStop());
          },
          (error) => {
            console.log('Error: ', error);
            dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
            dispatch(loaderStop());
          }
        )
        .catch((error) => {
          console.log('Error: ', error);
          dispatch(addMessage('Error on getting family', MESSAGE_TYPES.ERROR));
          dispatch(loaderStop());
        });
  };

  const handleOpenAddEditModal = useCallback(() => {
    if(isOpenAddEditModal){
      setCurrentStudent();
      setCategories();
    }
    setIsOpenAddEditModal(!isOpenAddEditModal);
  },[setIsOpenAddEditModal]);

  const fetchYearsOnSchoolChange = async (schoolId) => {
    // PHASE2/CHANGES/SCHOOL
    try {
      const school = await getSchoolByID(schoolId);
      const yearsData = DefaultYear.filter(y => school?.freeCalendars?.includes(y.value))
      setYearsData(yearsData);
      const categories = []
      school.categories.forEach((category) => {
        categories.push(category);
      });
      setCategories(categories);
      fetchGroups(school.id);
    } catch(error) {
      dispatch(loaderStop());
      console.error(error);
    }
  };

  const fetchGroups = async (schoolId) => {
    try {
      const result = await getGroupBySchoolId(schoolId);
      let groupsList = [];
      result.data.forEach((item) => {
        groupsList.push({
          key: item.groupName,
          value: item.id,
          years: item.years,
          selected: false,
          groupMembers: item.groupMembers,
          category: item.category ? [item.category] : [],
          createdByApiSync: item.createdByApiSync | false,
          autoAssignedMembers: item?.autoAssignedMembers
        });
      });
      if(groupsList.length > 0) {
        groupsList.push({
          key: "Select All",
          value: "all",
          selected: false
        });
      }
      setGroups(groupsList);
    } catch(error) {
      console.log('Error getting documents: ', error);
      dispatch(addMessage('Server connection issue. Please refresh', MESSAGE_TYPES.ERROR));
    }
  };

  const handleUpdateChild = (formData, groupData, student) => {
    firebase.getFamilyByID(familyID)
      .then(
        async (doc) => {
          let familyData = doc.data();
          if(familyData){
            let childData = [...familyData.childs];
            let childIndex = childData && student? _.findIndex(childData, { id:student.id }) : -1;
            let selectedGroupsIds = groupData.length > 0 ? _.filter(groupData, group => group.selected).map(g => g.value) : [];
            let yearsData = _.filter(schools, school => school.id === formData.school);
            let childObject = {};
            if(childIndex === -1 && !isEdit){
              childObject = {
                year: formData.year,
                firstName: formData.firstName,
                lastName: formData.surname,
                school: formData.school,
                groups: [...selectedGroupsIds],
                group: '',
                years: yearsData[0].years,
                initials: formData.firstName?.charAt(0) + formData.surname?.charAt(0),
                id: generateFileHash(20)
              };
              childData.push(childObject);
            };

              let allGroupIds = student ? [...student.groups] : [];

              let receivedGroupIds = _.filter(selectedGroupsIds, group => !allGroupIds.includes(group));
              let removedGroupsIds = _.filter(allGroupIds, group => !selectedGroupsIds.includes(group));
              let pushRecord = {
                members: []
              };

                if(receivedGroupIds){
                  receivedGroupIds.forEach((el) => {
                    pushRecord.members.push({
                      childId: student.id || childObject.id,
                      groupId: el,
                      isAdd: true
                    });
                  });
                };

                if(removedGroupsIds){
                  removedGroupsIds.forEach((el) => {
                    pushRecord.members.push({
                      childId: student.id || childObject.id,
                      groupId: el,
                      isAdd: false
                    });
                  });
                };

                childData[childIndex] = {
                  ...childData[childIndex],
                  years:yearsData[0].years,
                  year: formData.year,
                  firstName: formData.firstName,
                  lastName: formData.surname,
                  school: formData.school,
                  groups: [...selectedGroupsIds],
                  initials: `${formData.firstName[0] || ''}${formData.surname[0] || ''}`
                }

                familyData.childs = childData;

                let schoolData = [];
                if (
                  familyData.childs.length &&
                  familyData.planType !== 'Free'
                ) {
                  schoolData = familyData.childs.reduce((v, { school }) => {
                    v.push(school);
                    return v;
                  }, []);
                }
                let finalSchools = [...familyData.schools];
                for(let i=0; i < childData.length; i++){
                  if(childData[i].id === student.id){
                    let schoolIndex = finalSchools.findIndex(school => school.schoolName === childData[i].school);
                    let school = schools.find(school => school.id === childData[i].school);
                    if(schoolIndex === -1 && (finalSchools.length <= i)){
                      finalSchools.push({
                        schoolName: childData[i].school,
                        year: childData[i].year,
                        years: [...school.years],
                        color: ""
                      })
                    }else {
                      finalSchools[i] = {
                        ...finalSchools[i],
                        schoolName: childData[i].school,
                        year: childData[i].year,
                        years: [...school.years]
                      }
                    }
                  }
                }

                if(!isEdit){
                  let school = schools.find(school => school.id === formData.school);
                  finalSchools.push({
                    schoolName: formData.school,
                    year: formData.year,
                    years: [...school.years],
                    color: ""
                  })
                }

                let schoolIds = familyData.childs.map(child => child.school)
                familyData.childsSchools = schoolData;
                familyData.schoolIds = schoolIds;
                familyData.schools = finalSchools;

                // Update family
                firebase
                  .updateFamily(familyData, familyID)
                  .then(async (result) => {
                    dispatch(
                      addMessage(
                        `Child ${isEdit ? 'updated' : 'created'} successfully`
                      )
                    );
                    if (pushRecord.members.length > 0) {
                      // PHASE2/CHANGES/GROUP
                      try {
                        await updateGroupEntries(pushRecord);
                      } catch (error) {
                        handleCloseAddEditModal();
                        dispatch(
                          addMessage(
                            `Error on ${
                              isEdit ? 'updating' : 'creating'
                            } a child`, MESSAGE_TYPES.ERROR
                          )
                        );
                        dispatch(loaderStop());
                      } finally {
                        handleCloseAddEditModal();
                        fetchFamilyData();
                        dispatch(loaderStop());
                      }
                    }else{
                      handleCloseAddEditModal();
                      fetchFamilyData();
                    }
                  });
          }
        }
      )
      .catch(err => {
        handleCloseAddEditModal();
        dispatch(loaderStop());
        dispatch(addMessage(`Error on ${isEdit ? 'updating' : 'creating'} a child`, MESSAGE_TYPES.ERROR));
      })
  };

  const handleCloseAddEditModal = () => {
    setIsOpenAddEditModal(false);
    setCurrentStudent();
    setIsEdit(false);
    setCategories();
    fetchFamilyData();
  };

  return (
    <Dashboard>
      <div className={classes.root}>
        <div>
          <Typography
            variant="body1"
            className={classes.bredthcrumbTitle}
            onClick={() => history && history.push(routes.membersActive)}
          >
            Members
          </Typography>
          <RightArrow
            viewBox="0 0 24 24"
            height={24}
            width={24}
            style={{ fontSize: 22, verticalAlign: 'middle' }}
          />
        </div>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20, marginTop: 22 }}
        >
         {currentFamily?.parents?.length > 0 && `${currentFamily.parents[0].firstName || ""} ${currentFamily?.familySurname  || ""}`}
        </Typography>
        <Paper className={classes.tabNavigations}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.memberCalendar + '/' + familyID)
                  : history.push(routes.familyCalendarPaid + '/' + familyID);
              }}
            >
              Calendar
            </Button>
            {currentFamily.planType !== 'Free' && (
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyFiles + '/' + familyID)
                    : history.push(routes.familyFilesPaid + '/' + familyID);
                }}
              >
                Files
              </Button>
            )}
            {currentFamily.planType !== 'Free' && (
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyNotes + '/' + familyID)
                    : history.push(routes.familyNotesPaid + '/' + familyID);
                }}
              >
                Notes
              </Button>
            )}
            {currentFamily.planType !== 'Free' && (
              <Button
                classes={{ root: classes.navigationButton }}
                onClick={() => {
                  currentFamily.planType === 'Free'
                    ? history.push(routes.familyLinks + '/' + familyID)
                    : history.push(routes.familyLinksPaid + '/' + familyID);
                }}
              >
                Links
              </Button>
            )}
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.membersUsers + '/' + familyID)
                  : history.push(routes.familyUsersPaid + '/' + familyID);
              }}
              className={classes.activeTab}
            >
              Children
            </Button>
            <span style={{borderRight: `1px solid ${colors.primary}`, margin: "0 8px", height: 20}}></span>
            <Button
              classes={{ root: classes.navigationButton }}
              onClick={() => {
                currentFamily.planType === 'Free'
                  ? history.push(routes.memberAccount + '/' + familyID)
                  : history.push(routes.familyAccountPaid + '/' + familyID);
              }}
            >
              Account
            </Button>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '1rem',
                marginRight: 0,
                marginLeft: 'auto'
              }}
            >
              {currentFamily.apiSource?.includes("School Bytes") &&  <img src={schoolBytes} height="38px" />}
              {currentFamily.createdByApiSync &&  <img src={api_icon} />}
            </div>
          </div>
          <Divider />
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.actionButton}
            startIcon={<AddIcon />}
            onClick={() => openRightDrawer()}
          >
            New Child
          </Button>
        </Paper>
        <Grid container spacing={3}>
          {currentFamily.childs.map((cardData, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3}>
              <CardComponent
                cardData={{
                  ...childSchema,
                  ...cardData,
                  ...{ isParent: false },
                  ...{ index }
                }}
                format={
                  currentFamily.planType === 'Free'
                    ? AccountsCardDataFormat
                    : AccountsCardDataFormatPaid
                }
                onClick={openRightDrawer}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      {isOpenAddEditModal &&
      <CreateEditStudent
        isOpen={isOpenAddEditModal}
        onClose={handleCloseAddEditModal}
        handleSchoolChange={fetchYearsOnSchoolChange}
        onUpdate={handleUpdateChild}
        schools={schools}
        currentSchoolId={""}
        years={yearsData}
        student={currentStudent}
        groups={groups}
        categories={categories}
        isEdit={isEdit}
      />}
    </Dashboard>
  );
}

export default withFirebase(MembersUsersView);
