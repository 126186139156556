import { makeStyles } from "@material-ui/core";
import { colors } from "src/utils/constant";

export const cardComponentStyles = makeStyles(() => ({
    card: {
      minHeight: 385,
      maxWidth: 385,
      boxShadow: `0 0 25px 0 rgba(76, 91, 104, 0.13)`
    },
    cardContent: {
      height: 216
    },
    cardActions: {
      height: 60,
      cursor: 'pointer',
      justifyContent: 'center'
    },
    cardHover: {
      boxShadow: `8px 16px 46px -1px rgba(76, 91, 104, 0.44)`
    },
    title: {
      fontSize: 23,
      color: colors.primaryDarkGrey,
      textAlign: 'center',
      marginTop: 64,
      fontWeight: 'bold',
      lineHeight: 1.3,
      letterSpacing: 1,
      marginBottom: 4
    },
    lightGreyText: {
      color: colors.primaryLightGrey,
      fontSize: 18,
      textAlign: 'center',
      lineHeight: 1.67,
      marginBottom: 16
    },
    postDividerDarkText: {
      height: 23,
      fontFamily: 'Roboto',
      fontSze: 18,
      lineHeight: 1.28,
      letterSpacing: 0.11,
      textAlign: 'center',
      color: colors.primaryDark,
      marginTop: 17,
      fontWeight: 500
    },
    buttonLabel: {
      color: colors.white,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 0.86,
      letterSpacing: -0.43,
      padding: '5px 12px',
      borderRadius: 2
    },
    avatar: {
      position: 'absolute',
      top: 40,
      left: 'calc(50% - 60px)',
      height: 120,
      width: 120,
      border: `solid 2px ${colors.white}`,
      fontSize: '32px',
      fontWeight: 500,
      letterSpacing: '0.2px',
      color: colors.primaryDarkGrey,
      backgroundColor: colors.primaryLightGrey
    },
    buttonLabelHover: {
      backgroundColor: colors.primary,
      color: colors.white,
      justifyContent: 'center',
      transition: 'all 500ms ease-in-out'
    },
    buttonHover: {
      color: colors.white,
      borderRadius: 0,
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.28,
      letterSpacing: 0.11
    },
    headerLinkIcon: {
      marginLeft: 9,
      paddingRight: 11,
      verticalAlign: 'middle'
    },
    divider: { marginBottom: 16 },
    dividerRoot: {
      width: 31,
      backgroundColor: colors.primaryLightGrey,
      borderRadius: 2
    },
    dividerMiddle: {
      margin: 'auto'
    },
    usersPrimaryDarkGrey: {
      color: colors.primaryDarkGrey,
      textAlign: 'center',
      marginTop: 84,
      fontWeight: 500,
      lineHeight: 0.86,
      letterSpacing: -0.43,
      marginBottom: '10%'
    },
    notesPostDividerText: {
      fontSize: 23,
      color: colors.primaryDarkGrey,
      textAlign: 'center',
      marginTop: '6%',
      fontWeight: 'bold',
      lineHeight: 1.3,
      letterSpacing: 1,
      marginBottom: 4
    },
    primaryTextClass: {
      position: 'absolute',
      zIndex: 1,
      textTransform: 'uppercase',
      fontWeight: 500,
      color: colors.white,
      paddingLeft: 15,
      fontSize: 14
    },
    portalContainer: {
      position: 'absolute',
      top: 15,
      right: 0,
      paddingRight: 15
    },
    portalText: {
      margin: '0 5px',
      fontSize: 14,
      color: colors.primaryLightGrey
    },
    boxContent: {
      width: 50,
      height: 12,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 0.86,
      letterSpacing: -0.43,
      textAlign: 'center',
      color: colors.primaryDarkGrey
    },
    childCard: {
      color: 'transparent',
      textShadow: '0 0 8px rgba(0,0,0,0.5)',
      pointerEvents: 'none',
      userSelect: 'none',
      '&&::selection': {
        color: 'transparent',
        background: 'transparent'
      }
    },
    lastnameText: {
      color: 'transparent',
      textShadow: '0 0 8px rgba(0,0,0,0.5)',
      fontSize: 18,
      textAlign: 'center',
      lineHeight: 1.67,
      marginBottom: 16,
      pointerEvents: 'none',
      userSelect: 'none',
      '&&::selection': {
        color: 'transparent',
        background: 'transparent'
      }
    },
    avatarInitial: {
      position: 'absolute',
      top: 40,
      left: 'calc(50% - 60px)',
      height: 120,
      width: 120,
      border: `solid 2px ${colors.white}`,
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '0.2px',
      color: 'transparent',
      textShadow: '0 0 10px rgba(0,0,0,0.5)',
      backgroundColor: colors.primaryLightGrey
    }
  }));
