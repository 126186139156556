import React, { useCallback, useEffect, useState } from 'react';
import {
  makeStyles,
  Typography,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Fab,
  Popover,
  List,
  ListItem,
  ListItemText,
  Link
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { colors, routes } from 'src/utils/constant';
import { withFirebase } from 'src/utils/firebase';
import Dashboard from '../layout/Dashboard';
import TableList from 'src/components/TableList';
import { EDIT_ICON } from 'src/assets';
import { CalendarIcon } from 'src/assets/Icons';
import {
  loaderStart,
  loaderStop,
  addMessage,
  setFamilyOrSchoolPreserveEntryData
} from 'src/redux/actions/appActions';
import { setFamily, resetFamily } from 'src/redux/actions/familyActions';
// import CreateGroupCalenderEntry from './CreateGroupCalenderEntry';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { OpenInNew } from '@material-ui/icons';
// import CreateSingleFamilyEntry from './CreateSingleFamilyEntry';
import { schoolSchema } from 'src/utils/schemas';
import useApiCalls from 'src/customHooks/useApiCalls';
import useGetFamilyCount from 'src/customHooks/useGetFamilyCount';
import makeApiRequest from 'src/utils/axios';
import { API_GET_ALL_FREE_SCHOOLS } from 'src/utils/api';
import api_icon from 'src/assets/apiIcon.svg';
import { membersListActiveStyles } from 'src/styles/membersStyle/membersListActiveStyles';

const columns = [
  {
    id: 'familySurname',
    label: 'Surname',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '21.5%'
  },
  {
    id: 'parents',
    label: 'First',
    minWidth: 170,
    iconPosition: 'before',
    hasSort: true,
    width: '19%'
  },
  {
    id: 'schools',
    label: 'School Name',
    // minWidth: 170,
    setLabelStyle: true,
    iconPosition: 'before',
    hasSort: true
    // width: '14%'
  },
  {
    id: 'childs',
    label: 'Year',
    align: 'left',
    format: 'icon',
    onEach: true,
    icon: EDIT_ICON,
    iconPosition: 'before',
    hasSort: true,
    width: '23.8%'
  },
  // {
  //   id: 'freeHost',
  //   label: 'Free Host?',
  //   minWidth: 170,
  //   iconPosition: 'before',
  //   hasSort: true,
  //   width: '16%'
  // },

  {
    id: 'date',
    label: 'Joined',
    minWidth: 170,
    setLabelStyle: true,
    iconPosition: 'before',
    hasSort: true
    //width: '14%'
  },
  {
    id: 'apiSync',
    label: '',
    align: 'center',
    format: 'icon',
    iconPosition: 'right',
    icon: false,
    width: '5%'
  }
];


function CellItem({ row, column, schoolData, families }) {
  const history = useHistory();
  const childVisibility = useSelector((state) => state?.auth?.user?.country);
  const dispatch = useDispatch();
  const value = row[column.id];
  const classes = membersListActiveStyles();
  if (column.id === 'familySurname') {
    return (
      <span
        className={classes.surname}
        onClick={() => {
          dispatch(setFamily(row));
          history.push({
            pathname: routes.memberCalendar + '/' + row['id'],
            openRightDrawer: true
          });
        }}
      >
        {value}
      </span>
    );
  }
  if (column.id === 'parents') {
    return value.map((p) => p.firstName).join(' | ');
  }
  if (column.id === 'childs') {
    const result = value.reduce((acc, student) => {
      const existingSchool = acc.find((item) => item.school === student.school);

      if (existingSchool) {
        // School already exists, update the year array
        if (!existingSchool.year.includes(student.year)) {
          existingSchool.year.push(student.year);
        }
      } else {
        // School doesn't exist, create a new entry
        acc.push({ school: student.school, year: [student.year] });
      }

      return acc;
    }, []);

    const childsData = result.map((p, index) => {
      return (
        <>
          <span key={index}>
            {p.year.map((data, dataIdx) => {
              return (
                <>
                  {data === '-1' ? `PK` : data === `0` ? `K` : `Y${data}`}
                  {p?.year.length !== dataIdx + 1 ? `, ` : ''}
                </>
              );
            })}
            {/* {childVisibility === 'Australia' ? (
            <span> {p.firstName}</span>
          ) : (
            <span className={classes.nonAUChild}>Redacted</span>
          )} */}
          </span>
          <br />
        </>
      );
    });
    return childsData;
  }
  if (column.id === 'schools') {
    let childSchools = row.childs.map((child) => child.school);
    const data = _.compact(_.uniq(childSchools));
    return (
      <div>
        {data.map((schools) => {
          return <div>{schools}</div>;
        })}
      </div>
    );
  }

  if (column.id === 'date') {
    return (
      <span style={{ marginRight: 8, marginBottom: 5 }}>
        {row?.childs[0]
          ? moment(row.childs[0].statusUpdatedAt)
              .tz('Australia/Sydney')
              .format('DD MMM YY')
          : row?.familyStatusUpdatedAt
          ? moment(row.familyStatusUpdatedAt)
              .tz('Australia/Sydney')
              .format('DD MMM YY')
          : null}
      </span>
    );
  }
  if (column.id === 'apiSync' && row?.createdByApiSync) {
    return (
      <img src={api_icon} style={{ paddingRight: '1rem', float: 'right' }} />
    );
  }
}

function TableRowItem({ row, index, columns, schoolData, families }) {
  const classes = membersListActiveStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      className={classes.tableRow}
      onClick={(e) => {
        if (e.target.tagName.toLowerCase() === 'td') {
          dispatch(setFamily(row));
          history.push({
            pathname: routes.memberCalendar + '/' + row['id'],
            openRightDrawer: false
          });
        }
      }}
    >
      {columns.map((column) => {
        return (
          <TableCell
            key={column.id}
            align={column.align}
            className={classes.dataTableCell}
            style={{ width: column.width }}
          >
            <CellItem row={row} column={column} schoolData={schoolData} />
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function MembersListActive({ firebase }) {
  const classes = membersListActiveStyles();
  const history = useHistory();
  const [families, setFamily] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [allFamilies, setAllFamily] = useState([]);
  const [allNewFamilies, setAllNewFamily] = useState([]);
  const [allPeningFamilies, setAllPendingFamily] = useState([]);
  const [schoolChange, setSchoolChange] = useState('');
  const [year, setYear] = useState('');
  const [showClearFilters, setShowClearFilters] = useState(false);
  const [isOpenBottom, setOpenBottom] = useState({ open: false, value: null });
  const [isSingleFamilyOpen, setSingleFamilyOpen] = useState({
    open: false,
    value: null
  });
  const [anchorElBottom, setAnchorElBottom] = React.useState(null);
  const openBottom = Boolean(anchorElBottom);
  const idBottom = openBottom ? 'simple-popover' : undefined;
  const [searchText, setSearchText] = useState('');
  const [rowsCountPerPage, setRowsCountPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(false);

  const dispatch = useDispatch();
  const { data, loading, error, fetchData } = useApiCalls('families/free', {
    accountStatus: 'Active'
  });
  const { familyCount } = useGetFamilyCount('families/free/counts');

  useEffect(() => {
    dispatch(resetFamily());
  }, []);

  const handleFamiliesChange = (school, year) => {
    setShowClearFilters(school !== '' || year !== '');
    return allFamilies.filter((family) => {
      const childSchools = family.childs.map((child) => child.school);
      const childYears = family.childs.map((child) => child.year);
      const validateSchool = school === '' || childSchools.includes(school);
      const validateYear = year === '' || childYears.includes(year);
      return validateSchool && validateYear;
    });
  };

  // School change function
  const schoolChangeHandler = (e) => {
    setCurrentPage((prev) => !prev);
    setYear('');
    const schoolID = e.target.value;
    setSchoolChange(schoolID);
    fetchData({
      schoolId: schoolID,
      searchText: searchText,
      pageSize: rowsCountPerPage,
      nextPageRef: null,
      prevPageRef: null
    });
    setFamily(handleFamiliesChange(e.target.value, year));
  };

  // Year change function
  const yearChangeHandler = (e) => {
    setCurrentPage((prev) => !prev);
    setSchoolChange('');
    const yearValue = e.target.value;
    setYear(yearValue);
    fetchData({
      year: yearValue,
      searchText: searchText,
      pageSize: rowsCountPerPage,
      nextPageRef: null,
      prevPageRef: null
    });
    setFamily(handleFamiliesChange(schoolChange, e.target.value));
  };

  // Search text change function
  const searchChangeHandler = useCallback(
    (e) => {
      setCurrentPage((prev) => !prev);
      const { value } = e.target;
      setSearchText(value.toLowerCase().trim());
    },
    [searchText]
  );

  useEffect(() => {
    const getData = setTimeout(() => {
      fetchData({
        searchText: searchText,
        pageSize: rowsCountPerPage,
        schoolId: schoolChange,
        year: year,
        nextPageRef: null,
        prevPageRef: null
      });
    }, 300);
    return () => clearTimeout(getData);
  }, [searchText]);

  // Clear all filters
  const filterClearHandler = () => {
    setCurrentPage((prev) => !prev);
    setYear('');
    setSchoolChange('');
    setShowClearFilters(false);
    setFamily(allFamilies);
    fetchData({ pageSize: rowsCountPerPage });
  };

  // Page change next/prev function
  const handlePageChange = (currentPage, newPage, rowsPerPage) => {
    fetchData({
      ...(currentPage < newPage
        ? { nextPageRef: data?.nextPageRef }
        : { prevPageRef: data?.prevPageRef }),
      pageSize: rowsPerPage,
      searchText: searchText,
      schoolId: schoolChange,
      year: year
    });
  };

  // Page size change function
  const handleRowsPerPageChange = (rowsPerPage) => {
    fetchData({
      pageSize: rowsPerPage,
      searchText: searchText,
      schoolId: schoolChange,
      year: year
    });
  };
  if (loading) {
    dispatch(loaderStart());
  } else {
    dispatch(loaderStop());
  }
  return (
    <Dashboard>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ marginBottom: 20 }}
        >
          Members
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={classes.actionButton}
            startIcon={<AddIcon />}
            classes={{ startIcon: classes.startIcon }}
            onClick={() => history.push(routes.memberCreate)}
          >
            New Member
          </Button>
        </Typography>
        <TableList
          currentPage={currentPage}
          columns={columns}
          rows={!error ? data?.data : []}
          totalCount={!error ? data?.totalCount : 0}
          totalFamilies={familyCount}
          searchPlaceholderText={'Search by surname or email'}
          searchChangeHandler={searchChangeHandler}
          schoolChangeHandlerFamilyFree={schoolChangeHandler}
          yearChangeHandlerFree={yearChangeHandler}
          filterClearHandler={filterClearHandler}
          selectedSchool={schoolChange}
          showClearFilters={showClearFilters}
          year={year}
          // pageTitle={'Families'}
          tableRow={(row, index) => (
            <>
              <TableRowItem
                key={index}
                columns={columns}
                row={row}
                schoolData={schoolData}
              />
            </>
          )}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          setRowsCountPerPage={setRowsCountPerPage}
        />
        {/* {isOpenBottom.open && (
          <CreateGroupCalenderEntry
            isOpen={isOpenBottom.open}
            type={isOpenBottom.value}
            onClose={() => {
              setOpenBottom({ open: false, value: null });
            }}
          />
        )}
        {isSingleFamilyOpen.open && (
          <CreateSingleFamilyEntry
            isOpen={isSingleFamilyOpen.open}
            type={isSingleFamilyOpen.value}
            onClose={() => {
              setSingleFamilyOpen({ open: false, value: null });
            }}
          />
        )} */}
      </MuiPickersUtilsProvider>
    </Dashboard>
  );
}

export default withFirebase(MembersListActive);
