const schoolSchema = {
  id: '',
  schoolName: '',
  country: '',
  timezone: '',
  state: '',
  notificationsTiming: {
    year: '',
    class: '',
    coCurricular: '',
    subject: '',
    staff: ''
  },
  freeCalendars: [],
  categories: [],
  links: [],
  notes: '',
  freeUser: 1,
  groups: 4,
  createdByApiSync: false,
  apiSource: '',
  autoAssignedYears: [],
  formData: {
    category: [],
    firstName: '',
    email: '',
    urlPath: '',
    schoolShortName: '',
    schoolColor: '',
    isPublished: false,
    schoolLogo: {
      storage_path: '',
      url: ''
    },
    schoolSupportPerson: {
      storage_path: '',
      url: ''
    }
  },
  createdAt: '',
  updatedAt: '',
  studentReport: {
    apiStudent: 0,
    inActive: 0,
    nonRegistered: 0,
    active: 0
  }
};

const familySchema = {
  planType: '',
  familySurname: '',
  country: '',
  timezone: '',
  userName: '',
  password: '',
  childs: [],
  childsSchools: [],
  parents: [],
  freeHost: false,
  myWhanauEmail: '',
  schools: '',
  planStatus: '',
  createdDate: '',
  id: ''
};

const parentSchema = {
  isPrimary: '',
  firstName: '',
  lastName: '',
  initials: '',
  emailAddress: '',
  profileImage: '',
  id: ''
};

const childSchema = {
  userId: '',
  firstName: '',
  lastName: '',
  initials: '',
  gender: '',
  userName: '',
  password: '',
  school: '',
  year: '',
  schoolPortal: '',
  schoolPortalLogin: '',
  schoolPortalPassword: '',
  group: '',
  groups: [],
  notes: '',
  color: ''
};

const agentSchema = {
  firstName: '',
  lastName: '',
  initials: '',
  profileImage: '',
  email: '',
  password: '',
  role: '',
  country: ''
};

const groupSchema = {
  groupName: '',
  school: '',
  groupMembers: [],
  years: []
};

const groupModalDataSchema = {
  schoolId: '',
  groupMembers: [],
  groupYears: [],
  groupCategory: [],
}

const paidGroupModalDataSchema = {
  schoolId: '',
  groupMembers: [],
  groupYears: [],
}

export {
  schoolSchema,
  familySchema,
  parentSchema,
  childSchema,
  agentSchema,
  groupSchema,
  groupModalDataSchema,
  paidGroupModalDataSchema,
};
